import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IconButton, Slide, Typography} from "@material-ui/core";
import {ComparisonContext} from "../ComparisonContext";
import {getCompanyLogo, getMedigapCompanyLogo} from "../../../../shared/utils";
import {MedigapPlanOutput} from "../../../../medigap-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px dashed #029094',
      marginRight: 30,
      height: 90,
      width: 242,
      borderRadius: 10,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 15,
      },
    },
    card: {
      background: 'white',
      height: '100%',
      width: '100%',
      padding: '4px 7px 7px 11px',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      position: 'relative',
      overflow: 'hidden'
    },
    medium: {
      fontWeight: 500,
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 167,
    },
    logo: {
      width: 99,
      height: 35,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 210,
    }
  }),
);

type ComparisonCardProps = {
  plan?: MedigapPlanOutput
}

export default function ComparisonCard(props: ComparisonCardProps) {
  const classes = useStyles();

  return <div className={classes.container}>
    {props.plan && <Card plan={props.plan} /> }
  </div>
}

function Card(props: {plan: MedigapPlanOutput}) {
  const classes = useStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    setShow(true);
  }, [props.plan])

  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(props.plan.parentOrgName, props.plan.orgName);
    if (url) {
      return <div className={classes.logo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = props.plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : props.plan.title?.split(' - Plan')[0];
      return <Typography className={classes.title} color={'textPrimary'}>{text}</Typography>
    }
  }, [props.plan.parentOrgName, props.plan.orgName, props.plan.title]);

  return <Slide direction={'up'} in={show}><div className={classes.card}>
    <IconButton onClick={() => {
      setShow(false);
      removeFromCompare(props.plan.key)
    }} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>

    {Logo}
    <Typography color={'textPrimary'} className={'fs-14 bold'}>Plan {props.plan.planName}</Typography>
    <Typography color={'textPrimary'} className={'fs-14 medium mt-4'}>{props.plan.monthlyPremium} / mo</Typography>
  </div></Slide>
}
