import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

export default function useGetDrugsRequest(onCompleted?: (data: {drugsWithPackage: DrugInfoWithPack[]}) => void) {
  return useQuery<{drugsWithPackage: DrugInfoWithPack[]}, {pairs: ProductPackagePairInput[]}>(
    gql(getDrugRequest),
    {
      onCompleted,
      fetchPolicy: 'no-cache',
      skip: true
    }
  );
}

export type ProductPackagePairInput = {
  packageRxcui: string
  productRxcui: string
}

export type DrugInfoWithPack = {
  genericName: string
  genericRxcui: string
  isGeneric: boolean
  name: string
  packName: string
  packRxcui: string
  rxcui: string
}


const getDrugRequest = `
query ($pairs: [ProductPackagePairInput!]!) {
  drugsWithPackage(pairs: $pairs) {
    rxcui
    packRxcui
    packName
    name
    isGeneric
    genericRxcui
    genericName
  }
}
`;
