import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import AscendStepper from "../../../shared/SidePanel/AscendStepper";
import Terms from "../../../shared/SidePanel/Terms";
import {IStepperState} from "../../MedicareAdvantage/hooks/useStepperState";

type QuoteStepperProps = RouteComponentProps<{routeName: string}> & {stepperState: IStepperState, title: string | JSX.Element};

export default function QuoteStepper({stepperState, title, ...props}: QuoteStepperProps) {
  return (
    <>
      <Box marginTop={'24px'} maxWidth={245}>
        <Typography className={'fs-16'} color={'textPrimary'}>{title}</Typography>
      </Box>
      <AscendStepper {...props} state={stepperState} />
      <Terms />
      {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
    </>
  )
}
