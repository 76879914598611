import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step9";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import {withTitle} from "../../../shared/components/withTitle";
import useUrlParams from "../../../shared/hooks/useUrlParams";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";

function Step9(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(QuoteContext);
  const params = useUrlParams(['zip', 'county', 'planType', 'maxOutOfPocketRangeNames']);

  React.useEffect(() => {
      if (params.zip && params.county) {
        dispatch({
          type: QuoteActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2', form: 'STEP_2', zip: params.zip, countyName: params.county}
        })
      }
  }, [params]);

  return <Shared quote={quote} dispatch={dispatch}
                 onNextStep={() => props.history.push(paths.MAquestionnaire + "/" + questionsRoutes.step9a + document.location.search)}
                 {...props} />;
}

export default withTitle(
  Step9,
  'One last step before we can show your quotes.',
  'Provide your details so we can reach you. We never sell your data.',
  undefined,
  undefined,
  true
)
