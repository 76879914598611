import React from "react";
import {Box} from "@material-ui/core";
import Layout from "../../shared/Layout";
import {Route, RouteComponentProps, Switch} from "react-router";
import paths from "../config/router-paths";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {getQuoteId} from "../../shared/QuoteId";
import QuoteStepper from "../shared/components/QuoteStepper";
import {QuoteActionTypes, QuoteContext, withQuoteContextProvider} from "./QuoteContextProvider";
import useQuestionnaireRequest from "../../shared/hooks/useQuestionnaireRequest";
import QuoteBlankLeftSide from "../shared/components/QuoteBlankLeftSide";
import LastPageHelpModal from "../shared/components/LastPageHelpModal";
import {ProfileContext} from "../shared/ProfileContextProvider";
import VideoCategories from "../shared/components/VideoCategories";
import useStepperState, {questionsRoutes, setCompletedByLabel, steps} from "./hooks/useStepperState";
import MedicareQuoteStepper from "./MedicareQuoteStepper";

function Quote(props: RouteComponentProps<{routeName: string}>) {
  const [{pin}, dispatch] = React.useContext(QuoteContext);
  const profile = React.useContext(ProfileContext);
  const {state, setState} = useStepperState();
  const [quoteId, setQuoteId] = React.useState();


  React.useEffect(() => {
    if (profile?.quoteId) {
      setQuoteId(profile?.quoteId)
    }
  }, [profile])

  const [getRequest, requestData] = useQuestionnaireRequest(data => {
    dispatch({
      type: QuoteActionTypes.INIT_STATE,
      payload: data,
    })
  })

  React.useEffect(() => {
    if (quoteId) {
      getRequest({
        variables: {
          id: quoteId
        }
      })
    } else {
      dispatch({
        type: QuoteActionTypes.INIT_STATE,
      })
    }
  }, [quoteId])

  React.useEffect(() => {
    setState({...state, steps: steps});
    for (const step of state.steps) {
      let completed = step.questions?.some(q => {
        return q.link === (props.match.params.routeName || '')
      });
      setCompletedByLabel(step.label, !completed);
      if (!completed && step.children?.length) {
        for (const child of step.children) {
          let childCompleted = child.questions?.some(qu => qu.link === props.match.params.routeName)
          setCompletedByLabel(child.label, !childCompleted);
          if (childCompleted) {
            completed = true;
            break;
          }
        }
      }
      if (completed) {
        break;
      }
    }
    setTimeout(() => setQuoteId(getQuoteId()), 500);
    window.scrollTo(0, 0)
  }, [props.match.params.routeName])

  const isQuiz = React.useMemo(() => {
      return [questionsRoutes.quiz1a, questionsRoutes.quiz2, questionsRoutes.quiz3].indexOf(props.match.params.routeName) >= 0;
  }, [props.match.params.routeName]);

  const HelpModal = React.useMemo(() => {
    return props.match.params.routeName === questionsRoutes.step9 ? LastPageHelpModal : undefined;
  }, [props.match.params.routeName]);

  const SideComponent = React.useMemo(() => {
    if (props.match.params.routeName === questionsRoutes.quiz1) {
      return VideoCategories;
    }
    return isQuiz ? QuoteBlankLeftSide : MedicareQuoteStepper;
  }, [isQuiz, props.match.params.routeName]);


  return <Layout pin={pin} helpModalComponent={HelpModal} withoutDrawer={isQuiz} SideComponent={SideComponent} {...props}>
    <Box mt={'8px'} minHeight={'700px'} id={'wrapper'} position={'relative'}>
      <TransitionGroup>
        <CSSTransition
          key={props.location.key}
          classNames="question"
          timeout={300}
          onEnter={() => {
            document.body.scrollIntoView({behavior: "auto"});
            document.body.style.overflowY = 'hidden';
          }}
          onExited={() => {
            document.body.style.overflowY = 'auto';
          }}
        >
          <Switch location={props.location}>
            {state.questions.map((route: any) => <Route exact key={route.link} path={paths.MAquestionnaire + '/' + route.link} component={route.component} />)}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Box>
  </Layout>;
}

export default React.memo(withQuoteContextProvider(Quote));
