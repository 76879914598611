import React from "react";
import {
  Box,
  CircularProgress,
  createStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import FormTextInput, {TickAdornment} from "../../../shared/components/FormTextInput";
import AscendButton from "../../../shared/components/AscendButton";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import FormSelectInput from "../../../shared/components/FormSelectInput";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import {withTitle} from "../../../shared/components/withTitle";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import {KeyboardDatePicker} from "@material-ui/pickers";
import NotServedModal from "../../../shared/components/NotServedModal";
import paths from "../../config/router-paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '320px',
      width: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 40,
    },
  }),
);

const Schema = yup.object().shape({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
});

interface PersonalDetailsFormState {
  zip: string;
}

const formName = 'STEP_2';

type Step2Props = RouteComponentProps<any> & {quote: any, dispatch: any};

function Step2({quote, dispatch, ...props}: Step2Props) {
  const classes = useStyles();
  const [getCity, data] = useLazyQuery<ICityResponse, {zip: string}>(gql(cityRequest), {
    onCompleted: () => {
      if (!licensedStates.includes(data.data?.plans_zip_county_fips[0]?.state || '')) {
        setOpen(true);
      }
    },
    fetchPolicy: 'no-cache',
  });
  const [county, setCounty] = React.useState<string | undefined>(quote.STEP_2?.countyName || getStoredUser()?.countyName);
  const [cities, setCities] = React.useState<ICity[]>([]);
  const [open, setOpen] = React.useState(false);
  const [countyState, setCountyState] = React.useState<string>();
  const ref = React.useRef(null);
  const {register, handleSubmit, errors, formState, watch, getValues, setValue, trigger} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
      zip: quote.STEP_2?.zip || getStoredUser()?.zip,
    }
  });
  watch()

  React.useEffect(() => {
    const zip = getValues().zip;
    if (zip && zip.length === 5) {
      setCounty(undefined)
      getCity({variables: {zip}})
    }
  }, [getValues().zip])

  React.useEffect(() => {
    setCities(data.data?.plans_zip_county_fips || []);
  }, [data.data, data.called])

  React.useEffect(() => {
      if (data.data?.plans_zip_county_fips.length === 1) {
        setCounty(data.data?.plans_zip_county_fips[0].countyname as string);
        setCountyState(data.data?.plans_zip_county_fips[0].state)
      }
  }, [data.data]);

  React.useEffect(() => {
      if (quote.STEP_2?.zip) {
        setValue('zip', quote.STEP_2?.zip)
        trigger()
      }
    if (quote.STEP_2?.countyName) {
      setCounty(quote.STEP_2?.countyName)
      setCountyState(quote.STEP_2?.state)
    }
  }, [quote.STEP_2?.zip]);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    if ((window as any).hj) {
      (window as any).hj('event', 'started_userflow')
    }
  }, [])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (values) {
      const route = quote?.QUIZ_3?.medicarePlanType?.value === 'MA' ? paths.MAquestionnaire : paths.MGquestionnaire;
      dispatch({
        type: QuoteActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'STEP_2', form: formName, zip: values.zip, countyName: county, state: countyState}
      })
      setStoredUser({...getStoredUser(), zip: values.zip, countyName: county})
      props.history.push(route + '/' + questionsRoutes.step3);
    }
  };

  const notServedClose = () => {
    setOpen(false);
    setValue('zip', '');
    setCities([]);
    setCounty(undefined);
    setCountyState(undefined);
  }

  return <Box display={'flex'} justifyContent={'center'}>
    <NotServedModal onClose={notServedClose} open={open} zip={getValues().zip}/>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} className={classes.form}>
      <Box className={classes.container}>
        <Box display={'flex'}>
          <FormTextInput error={formState.isSubmitted && !!errors.zip}
                         fullWidth
                         helperText={formState.isSubmitted && errors?.zip?.message}
                         defaultValue={quote.STEP_2?.zip}
                         autoFocus={true}
                         inputRef={(e) => {
                           register(e);
                           ref.current = e;
                         }}
                         onKeyPress={event => {
                           if (event.key === 'Enter' && formState.isValid && (cities.length === 1 || cities.length > 1 && county)) {
                             onSubmit(getValues());
                           }
                         }}
                         InputProps={{
                           endAdornment: (formState.isValid && (cities.length === 1 || cities.length > 1 && county)) ?
                             <TickAdornment/> : null
                         }}
                         label={'Zip Code'}
                         name={'zip'}
                         inputProps={{
                           maxLength: 5
                         }}
                         type={'tel'}
                         placeholder={'Enter 5 digit zip code'} />
        </Box>
        <Box display={'flex'}>
          {data.loading && <Box ml={'20px'} mt={'40px'}><CircularProgress size={30} /></Box>}
          {(!data.loading && data.called) && <>
            {cities.length === 0 && <Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>We can't find your county</Typography></Box>}
            {cities.length === 1 && <Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>{cities[0].countyname + ' County, ' + cities[0].state}</Typography></Box>}
            {cities.length > 1 && <FormSelectInput
              fullWidth
              label={'Select county'}
              value={county}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
                setCountyState(cities.find(c => c.countyname === event.target.value)?.state)
              }}
            >
              {
                cities.map(city => <MenuItem key={city.countyname} value={city.countyname}>{city.countyname}</MenuItem>)
              }
            </FormSelectInput>}
          </>}
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'}>
        <AscendButton className={'gtm-step-event'} id={formName}
                      disabled={!licensedStates.includes(countyState || '') || !formState.isValid || !cities.length || cities.length > 1 && !county}
                      variant={'contained'} type="submit">Continue</AscendButton>
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <AscendButton variant={'contained'} onClick={() => {
            props.history.goBack();
          }} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
    </form>
  </Box>
}

const licensedStates = [
  'NY',
  'AZ',
  'FL',
  'GA',
  'IL',
  'IN',
  'MO',
  'NC',
  'NJ',
  'OH',
  'PA',
  'SC',
  'TN',
  'TX',
  'VA',
  'CA',
  'NV',
  'KS',
  'KY',
  'LA',
  'ME',
  'MI',
  'UT',
  'CO',
  'NM',
  'WV',
  'MD',
  'OR',
  'CT',
  'MS',
  'AL',
  'WA',
  'IA',
]

export default withTitle(
  Step2,
  'Welcome, let’s help you find \na new Medicare plan today',
  'First, let’s confirm your zip code.',
  undefined,
  undefined,
  true
)


interface ICityResponse {
  plans_zip_county_fips: ICity[]
}

interface ICity {
  city: string,
  state: string,
  countyname: string
}

const cityRequest: string = `
  query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`
