import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import paths from "./config/router-paths";
import { useHistory } from "react-router-dom";
import useUserProfile from "./hooks/useUserProfile";
import useDrugsByRequestId from "../Maketplace/MedicareAdvantage/PlanList/hooks/useDrugsByRequestId";
import {DrugOutput, PharmacyLocationDistanceOutput} from "../types";
import {makeStyles} from "@material-ui/core/styles";
import usePharmaciesByRequestId from "../Maketplace/MedicareAdvantage/PlanList/hooks/usePharmaciesByRequestId";
import {colors} from "../shared/AppTheme";
import clsx from "clsx";


export default function Benefits() {
  const history = useHistory();
  const {userProfile} = useUserProfile();
  const {drugs, called: drugsCalled, loading: drugsLoading} = useDrugsByRequestId(userProfile?.quoteId);
  const {pharmacies, called: pharmaciesCalled, loading: pharmaciesLoading} = usePharmaciesByRequestId(userProfile?.quoteId);

  const noData = pharmaciesCalled && drugsCalled && !drugsLoading && !pharmaciesLoading && !drugs.length && !pharmacies?.length;

  return <Box display={'flex'} justifyContent={'center'}>
    <Box maxWidth={800}>
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>My Offers</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>We’re excited to bring you offers from our partners to give you more  healthcare options and keep your costs down.</Typography>
      <div className={'h-32'} />
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your drug savings:</Typography>
      <div className={'h-24'} />
      <AscendButton variant={'contained'} onClick={() => history.push(paths.myHealthcare)}>Update drugs and pharmacy</AscendButton>
      <div className={'h-24'} />
      {noData && <Typography className={'medium fs-18'}>You haven’t added any drugs or a pharmacy yet. Click on ‘Update drugs and pharmacy’ above to add.</Typography>}
      {drugs.map(drug => <DrugCard drug={drug} />)}
      <div className={'h-24'} />
      {pharmacies?.map(pharmacy => <PharmacyCard pharmacy={pharmacy} />)}
      <div className={'h-100'} />
    </Box>
  </Box>
}

const usePharmacyStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'rgba(94, 203, 158, 0.1)',
      borderRadius: 10,
      border: '1px solid #5ECB9E',
      padding: 16,
      display: 'flex',
      marginBottom: 8
    },
    link: {
      color: colors.custom.green.variant2,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: 14
    },
  }),
);

const PharmacyCard = ({pharmacy}: {pharmacy: PharmacyLocationDistanceOutput}) => {
  const classes = usePharmacyStyles();
  const history = useHistory();

  return <div className={classes.card}>
    <Box display={'flex'} flexDirection={'column'} flex={1} justifyContent={'space-between'} alignItems={'flex-start'}>
      <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} mb={'12px'}>
        <img src={'/img/green2-check.svg'} width={22} height={22} />
        <Typography className={'bold fs-14 ml-20'} color={'textSecondary'}>Covered by your plan</Typography>
      </Box>
      <Typography className={'bold fs-14'} color={'textPrimary'}>Retail and coupon prices above are dependent<br/>on the pharmacy you select</Typography>
    </Box>
    <Box display={'flex'} flexDirection={'column'} flex={1} justifyContent={'space-between'} alignItems={'flex-end'}>
      <Typography className={'fs-16'} color={'textPrimary'}>{pharmacy.name}</Typography>
      <Typography className={'fs-16'} color={'textSecondary'}>{pharmacy.address}</Typography>
      <Typography className={classes.link} onClick={() => history.push(paths.myHealthcare)}>Change pharmacy</Typography>
    </Box>
  </div>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: 16,
      display: 'flex',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      marginBottom: 8
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: 14
    },
  }),
);

const DrugCard = ({drug}: {drug: DrugOutput}) => {
  const classes = useStyles();
  const history = useHistory();

  return <div className={classes.card}>
    <Box display={'flex'} flexDirection={'column'} width={120} justifyContent={'space-between'}>
      <Typography className={'medium fs-16'} color={'textPrimary'}>{drug.name}</Typography>
      <Typography className={'fs-14'} color={'textSecondary'}>{drug.isGeneric ? 'Generic' : 'Branded'}</Typography>
    </Box>
    <Box display={'flex'} flexDirection={'column'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography className={'fs-14 text-align-centered'} color={'textSecondary'}>{drug.packName}</Typography>
        <Typography className={'fs-14 text-align-centered'} color={'textSecondary'}>{drug.quantity} per {drug.frequency}</Typography>
      </Box>
      <Typography className={classes.link} onClick={() => history.push(paths.myHealthcare)}>Edit drug</Typography>
    </Box>
    <Box display={'flex'} flex={2} alignItems={'flex-start'} justifyContent={'center'}>
      <img src={'/img/green2-check.svg'} width={22} height={22} />
      <Typography className={'bold fs-14 ml-20'} color={'textSecondary'}>Covered by<br/>your plan</Typography>
    </Box>
    <Box display={'flex'} flex={2} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'}>
      <Typography className={'fs-14 text-align-centered'} color={'textPrimary'}>Est. monthly <br/> costs: <br/> <strong> $10.87/mo</strong></Typography>
    </Box>
    <Box display={'flex'} flex={3} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'}>
      <Typography className={'fs-14 text-align-centered'} color={'textPrimary'}><strong>No discounts available.</strong><br/>Looks like you have the<br/>best price available already!</Typography>
    </Box>
    <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'}>
      <IconButton onClick={() => {}}>
        <img src={'/img/dark-close.svg'} />
      </IconButton>
    </Box>
  </div>
}
