import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step3";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import SearchingPlanModal from "../components/SearchingPlanModal";

function Step3(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);
  const [open, setOpen] = React.useState(false);

  if ((window as any).track) {
    (window as any).track(`Page View - ${(window as any).product} - Birthdate`);
  }

  return <>
    <SearchingPlanModal
      onlyMg={(window as any).product === 'short-medigap'}
      countyName={quote.STEP_2?.countyName + ' County, ' + quote.STEP_2?.state}
      zip={quote.STEP_2?.zip}
      onClose={() => {
        setOpen(false)
        props.history.push(paths.MGquestionnaire + '/' + questionsRoutes.step9);
      }}
      open={open} />
    <Shared quote={quote} dispatch={dispatch} onNextStep={() => setOpen(true)}  {...props} />
  </>;
}

export default Step3 as any;
