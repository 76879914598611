import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationCreateMedigapQuoteFromMedicareQuoteArgs} from "../../../../medigap-types";


export default function useCreateMedigapQuoteFromMedicareQuote(options?: MutationHookOptions<{createMedigapQuoteFromMedicareQuote: string}, MutationCreateMedigapQuoteFromMedicareQuoteArgs>) {
  return useMutation<{createMedigapQuoteFromMedicareQuote: string}, MutationCreateMedigapQuoteFromMedicareQuoteArgs>(
    gql(request),
    {
      ...options,
    }
  );
}


const request = `
mutation ($medicareQuoteId: UUID!) {
  createMedigapQuoteFromMedicareQuote(medicareQuoteId: $medicareQuoteId)
}
`;
