const paths = {
  home: '/',
  plans: '/plans/',
  planDetails: '/plan/',
  favorites: '/favorites/',
  comparison: '/compare/',
  category: '/category/',
};

export default paths;
