
import {config} from "../config/config";
import {PlanFilesOutput} from "../../../types";

export default function planFiles(files?: PlanFilesOutput | null) {
  if (files) {
    const prefix = config.filesUrl as string + '/';

    const result: any[] = [];

    if (files.summaryOfBenefitsUrl) {
      result.push({
        label: 'Summary of Benefits',
        url: prefix + files.summaryOfBenefitsUrl
      });
    }

    if (files.evidenceOfCoverageDocUrl) {
      result.push({
        label: 'Evidence of Coverage',
        url: prefix + files.evidenceOfCoverageDocUrl
      });
    }

    if (files.starRatingDescDocUrl) {
      result.push({
        label: 'CMS star ratings information',
        url: prefix + files.starRatingDescDocUrl
      });
    }

    if (files.nonDiscriminationNoticeDocUrl) {
      result.push({
        label: 'Language Assistance / Non-discrimination Notice',
        url: prefix + files.nonDiscriminationNoticeDocUrl
      });
    }

    return result;
  }
  return [];
}
