import React from "react";
import {
  Box,
  createStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import {withTitle} from "../../../shared/components/withTitle";
import Benefit from "../../../shared/components/Benefit";
import AscendDivider from "../../../shared/components/AscendDivider";
import {getStoredUser} from "../../../shared/StoredUser";
import {QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import usePlanSummary from "../hooks/usePlanSummary";
import {ExtraBenefit, PlansSummaryOutput} from "../../../types";
import LastPageHelpModal from "../components/LastPageHelpModal";
import {getQuoteId} from "../../../shared/QuoteId";
import {config} from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    medium: {
      fontWeight: 500,
    },
    panelColumn: {
      borderBottom: '1px solid #E8ECED',
      paddingBottom: 11,
      marginBottom: 16,
      [theme.breakpoints.up('md')]: {
        borderRight: '1px solid #E8ECED',
        borderBottom: 'none',
        paddingBottom: 0,
        marginBottom: 0,
      },
    },
    columnsWrapper: {
      display: 'flex',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    label: {
      fontSize: 12,
      color: colors.text.primary,
      fontWeight: 400,
      lineHeight: '16px',
    },
    image: {
      width: 14,
      height: 14
    },
    green: {
      color: '#029094',
    },
    info: {
      background: '#1E95A0',
      borderRadius: 4,
      borderLeft: '16px solid #51E0A5',
      padding: '12px 21px',
      display: 'flex',
      alignItems: 'center',
      '& *': {
        color: 'white!important'
      },
      boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.04)',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    paper: {
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      position: 'relative',
      animationName: 'card-animation-right',
      animationDuration: '0.5s',
    },
    lh1: {
      lineHeight: 1
    },
    plus: {
      position: 'absolute',
      left: -11,
      top: -12,
    },
    phone: {
      color: colors.custom.green.variant1,
      display: 'flex',
      borderRadius: 4,
      fontSize: 20,
      alignItems: 'center',
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 5,
        width: 16,
        height: 16,
      }
    },
    plans: {
      minHeight: 35,
      background: '#FF785A',
      boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.04)',
      borderRadius: 4,
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18,
      textAlign: 'center',
      paddingTop: 5,
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        padding: '5px 20px'
      },
    },
    mobileImage: {
      float: 'right',
      marginLeft: 10,
    }
  }),
);

const formName = 'STEP_10';

type Step10Props = RouteComponentProps<any> & {quote: any, dispatch: any, onNextStep: () => void};

function Step10({quote, dispatch, ...props}: Step10Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

/*  const {data, loading} = usePlanSummary({
    variables: {
      zip: quote?.STEP_2?.zip,
      countyName: quote?.STEP_2?.countyName,
      quoteId: getQuoteId(),
      extraBenefits: quote?.STEP_6?.benefits?.value
    }
  });

  const summary: PlansSummaryOutput = React.useMemo(() => {
    if (data?.plansSummary) {
      return data.plansSummary
    }
    return {} as PlansSummaryOutput;
  }, [data]);
*/
  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    if ((window as any).gtag) {
      (window as any).gtag('event', 'WRONG_ZIP', {
        'zip': ':999999'
      });
    }
  }, [])

  return <Box display={'flex'} flexDirection={'column'} mt={'16px'}>
    <LastPageHelpModal open={open} onClose={() => setOpen(false)} />
    <img src='https://forms.smartfinancial.com/p/2c26b46b68ffc68ff99b4_GZ.gif?e=1&u=37214' width='1' height='1' />
    <Paper elevation={0}>
      <Box className={classes.info}>
        {!phone && <>
          <img src={'/img/contact-method-email.svg'} className={'mr-30'} />
          <Typography className={`fs-22 bold`}>
            {quote?.QUIZ_3?.medicarePlanType?.value !== 'MA' && <>Keep an eye on your email — a licensed agent will reach out soon with your personalized Medicare Supplement quotes.</>}
            {quote?.QUIZ_3?.medicarePlanType?.value === 'MA' && <>Keep an eye on your phone — a licensed agent will reach out soon to discuss your personalized Medicare Advantage quotes.</>}
          </Typography>
        </>}
        {phone && <>
          <div className={'flex'}>
            <Typography className={'fs-20 bold'}>
              {quote?.QUIZ_3?.medicarePlanType?.value !== 'MA' && <>Keep an eye on your email — a licensed agent will reach out soon with your personalized Medicare Supplement quotes.</>}
              {quote?.QUIZ_3?.medicarePlanType?.value === 'MA' && <>Keep an eye on your phone — a licensed agent will reach out soon to discuss your personalized Medicare Advantage quotes.</>}
            </Typography>
          </div>
        </>}
      </Box>
    </Paper>
    <div className={'h-12'} />
    {quote?.QUIZ_3?.medicarePlanType?.value === 'MA' && <div className={classes.plans}
                                                             onClick={() => {
                                                               // document.location.href = (config.resultsUrl as string) + '?quote=' + getQuoteId()
                                                             }}>
      We found plans that match your preferences
    </div>}
    <Typography className={'fs-20 bold'} color={'textPrimary'}>
      {quote?.QUIZ_3?.medicarePlanType?.value === 'MA' ?
        'We can’t show you 2022 plans yet, but our licensed agents are able to discuss your options with you make sure you’re ready to enroll on October 15th.' :
        'Your dedicated agent is reviewing options from 10+ top Medicare Supplement companies to find the plans with the benefits you need.'
      }
    </Typography>
    <div className={'h-8'} />

    {quote?.QUIZ_3?.medicarePlanType?.value === 'MG' ? <MGContent state={quote} onClick={() => setOpen(true)} /> : <MAContent state={quote} loading={false} summary={{}} />}


    <AscendDivider mt={'32px'} mb={'26px'} />

    <Footer />

  </Box>
}

function MAContent({loading, summary, state}: {loading: boolean, summary: any, state: any}) {
  const [counter, setCounter] = React.useState(0);
  const [interval, setIntervalValue] = React.useState();
  const classes = useStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    setIntervalValue(setInterval(() => {
      setCounter(prev => prev + 1)
      if (counter > 4 && interval) {
        clearInterval(interval)
      }
    }, 300))
  }, [])

  return <>
    {/*<Box display={'flex'} justifyContent={'center'}>
      <img src={`/img/logos/partners${phone ? '2' : ''}.svg`} style={{maxWidth: 'fit-content'}} />
    </Box>*/}
    <AscendDivider mt={'16px'} mb={'16px'} />
    <Typography className={'bold fs-20 mb-16'} color={'textPrimary'}>Here’s a summary of popular plans in <span className={'fs-26 dark-green'}>{state?.STEP_2?.countyName + ' County, ' + state?.STEP_2?.state}:</span></Typography>
    {counter >= 1 && <Paper elevation={0} className={classes.paper}>
        {phone && <Box padding={'10px 10px 15px 16px'}>
          <div>
            <Box display={'flex'} alignItems={'center'} mb={'10px'}>
              <Typography className={'bold dark-green fs-40 mr-20 w-55 text-align-centered'}>{!loading && summary.hmoCount}</Typography>
              <Typography className={'bold fs-20 lh-25'} color={'textPrimary'}>Medicare Advantage HMO plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>
              <img src={'/img/man.svg'} className={classes.mobileImage} />
              HMO (or Health Maintenance Organization) plans uses a designated network of doctors and hospitals. This often keeps your costs down, however, you typically must use in-network providers and get referrals before seeing specialists.
            </Typography>
          </div>
        </Box>}
        {!phone && <Box padding={'20px 40px 21px 16px'} display={'flex'} justifyContent={'space-between'}>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              {/*<Typography className={'bold dark-green fs-40 mr-15'}>{!loading && summary.hmoCount}</Typography>*/}
              <Typography className={'bold fs-26'} color={'textPrimary'}>Medicare Advantage HMO plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>HMO (or Health Maintenance Organization) plans uses a designated network of doctors and hospitals. This often keeps your costs down, however, you typically must use in-network providers and get referrals before seeing specialists.</Typography>
          </div>
          <img src={'/img/man.svg'} className={'ml-35'}/>
        </Box>}
      </Paper>}
    <div className={'h-10'} />

    {counter >= 2 && <Paper elevation={0} className={classes.paper}>
        {phone && <Box padding={'10px 10px 15px 16px'}>
          <div>
            <Box display={'flex'} alignItems={'center'} mb={'10px'}>
              {/*<Typography className={'bold dark-green fs-40 mr-20 w-55 text-align-centered'}>{!loading && summary.ppoCount}</Typography>*/}
              <Typography className={'bold fs-20 lh-25'} color={'textPrimary'}>Medicare Advantage PPO plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>
              <img src={'/img/hospital-1.svg'} className={classes.mobileImage} />
              PPO (or Preferred Provider Organization) plans give you the flexibility to seek healthcare services out of this network, typically for a higher cost.  You do not need referrals to see specialists.
            </Typography>
          </div>
        </Box>}
        {!phone && <Box padding={'20px 40px 21px 16px'} display={'flex'} justifyContent={'space-between'}>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              {/*<Typography className={'bold dark-green fs-40 mr-15'}>{!loading && summary.ppoCount}</Typography>*/}
              <Typography className={'bold fs-26'} color={'textPrimary'}>Medicare Advantage PPO plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>PPO (or Preferred Provider Organization) plans give you the flexibility to seek healthcare services out of this network, typically for a higher cost.  You do not need referrals to see specialists. </Typography>
          </div>
          <img src={'/img/hospital-1.svg'} className={'ml-35'}/>
        </Box>}
      </Paper>}

    <div className={'h-10'} />

    {counter >= 3 && <Paper elevation={0} className={classes.paper}>
        {phone && <Box padding={'10px 10px 15px 16px'}>
          <div>
            <Box display={'flex'} alignItems={'center'} mb={'10px'}>
              {/*<GreenPercent value={!loading && summary.drugsCoveredPercent} />*/}
              <Typography className={'bold fs-20 lh-25'} color={'textPrimary'}>Included Prescription Coverage</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>
              <img src={'/img/pills-2.svg'} className={classes.mobileImage} />
              Many Medicare Advantage plans that bundle prescription drug coverage are known as Medicare Advantage Part D (MA-PD) plans. With MA-PD plans, you don’t need to worry about getting a secondary plan for drug coverage.
            </Typography>
          </div>
        </Box>}
        {!phone && <Box padding={'20px 40px 21px 16px'} display={'flex'} justifyContent={'space-between'}>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              {/*<GreenPercent value={!loading && summary.drugsCoveredPercent} />*/}
              <Typography className={'bold fs-26'} color={'textPrimary'}>Included Prescription Coverage</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>Many Medicare Advantage plans that bundle prescription drug coverage are known as Medicare Advantage Part D (MA-PD) plans. With MA-PD plans, you don’t need to worry about getting a secondary plan for drug coverage.</Typography>
          </div>
          <img src={'/img/pills-2.svg'} className={'ml-35'}/>
        </Box>}
      </Paper>}

    <div className={'h-10'} />

    {counter >= 4 && <Paper elevation={0} className={classes.paper}>
        {phone && <Box padding={'10px 10px 15px 16px'}>
          <div>
            <Box display={'flex'} alignItems={'center'} mb={'10px'}>
              {/*<GreenPercent value={!loading && summary.zeroPremiumPercent} />*/}
              <Typography className={'bold fs-20 lh-25'} color={'textPrimary'}>$0 premium plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>
              <Box display={'flex'} justifyContent={'center'} minWidth={'72px'} className={`ml-35 ${classes.mobileImage}`}>
                <img src={'/img/dollar.svg'} />
              </Box>
              Medicare Advantage plans often have a low or $0 monthly premium. You only pay for healthcare services when you need them, with an annual limit on your maximum out-of-pocket costs.
            </Typography>
          </div>
        </Box>}
        {!phone && <Box padding={'20px 40px 21px 16px'} display={'flex'} justifyContent={'space-between'}>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              {/*<GreenPercent value={!loading && summary.zeroPremiumPercent} />*/}
              <Typography className={'bold fs-26'} color={'textPrimary'}>$0 premium plans</Typography>
            </Box>
            <Typography className={'fs-14'} color={'textPrimary'}>Medicare Advantage plans often have a low or $0 monthly premium. You only pay for healthcare services when you need them, with an annual limit on your maximum out-of-pocket costs. </Typography>
          </div>
          <Box display={'flex'} justifyContent={'center'} minWidth={'72px'} className={'ml-35'}>
            <img src={'/img/dollar.svg'}/>
          </Box>
        </Box>}
      </Paper>}

    <AscendDivider mt={'28px'} mb={'16px'} />

    <Typography className={'fs-24 bold text-align-centered mb-10'} color={'textPrimary'}>Get the benefits you deserve.</Typography>
    <Typography className={`${phone ? 'fs-14' : 'fs-16'} bold dark-green text-align-centered`}>Medicare Advantage benefits are always changing, your new plan could cover the following at no extra cost:</Typography>

    <div className={'h-20'} />

    {!phone && <Box display={'flex'} justifyContent={'space-between'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={'/img/Dental1.svg'}/>
        <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Dental</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={'/img/Vision1.svg'}/>
        <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Vision</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={'/img/Hearing1.svg'}/>
        <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Hearing</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={'/img/Fitness1.svg'}/>
        <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Fitness</Typography>
      </Box>
    </Box>}

    {phone && <>
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img src={'/img/Dental1.svg'}/>
          <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Dental</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img src={'/img/Vision1.svg'}/>
          <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Vision</Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img src={'/img/Hearing1.svg'}/>
          <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Hearing</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img src={'/img/Fitness1.svg'}/>
          <Typography className={'bold fs-20 mt-5'} color={'textPrimary'}>Fitness</Typography>
        </Box>
      </Box>
    </>}

    <Typography className={'bold fs-16 mt-20 text-align-centered'} color={'textSecondary'}>and others including OTC drug coverage, expanded telehealth, transportation and discounted Insulin costs.
    </Typography>
  </>
}

const GreenPercent = ({value}: {value?: number}) => {
  if (!value) {
    return null;
  }
  return <div className={'mr-15'}>
    <Box display={'flex'} alignItems={'flex-start'}>
      <Typography className={'bold dark-green fs-40 lh-44'}>{value}</Typography>
      <Typography className={'bold dark-green fs-20'}>%</Typography>
    </Box>
    <Box mt={'-6px'}>
      <Typography className={'bold dark-green fs-14'}>of plans</Typography>
    </Box>
  </div>
}

function MGContent({onClick, state}: {onClick: () => void, state: any}) {
  const [counter, setCounter] = React.useState(0);
  const [interval, setIntervalValue] = React.useState();
  const classes = useStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const userState = React.useMemo(() => {
      return state?.STEP_2?.state;
  }, [state]);

  React.useEffect(() => {
    setIntervalValue(setInterval(() => {
      setCounter(prev => prev + 1)
      if (counter > 3 && interval) {
        clearInterval(interval)
      }
    }, 300))
  }, [])

  const firstCol = React.useCallback((price: string, hideSubtitle?: boolean) => {
      if (phone) {
        return <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} minWidth={'180px'}>
          <Box display={'flex'}>
            <div className={'w-130'}>
              <Typography variant={'body2'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
              <div className={'h-5'} />
              <Typography color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>${price}</strong></Typography>
            </div>
            <Typography className={'fs-12 ml-32'} color={'textPrimary'}>Final premium will depends on your age, sex and health status</Typography>
          </Box>
          <div className={'h-8'} />
          {!hideSubtitle && <Typography className={'fs-12'} color={'textPrimary'}>Does not include standard Part B premium ($148.50)</Typography>}
        </Box>
      }
      return <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} minWidth={'180px'}>
        <Typography variant={'body2'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
        <div className={'h-5'} />
        <Typography color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>${price}</strong></Typography>
        <Typography className={'fs-12'} color={'textPrimary'}>Final premium will depends on<br />your age, sex and health status</Typography>
        <div className={'h-8'} />
        {!hideSubtitle && <Typography className={'fs-12'} color={'textPrimary'}>Does not include standard<br />Part B premium ($148.50)</Typography>}
      </Box>
  }, [phone]);

  const secondCol = (partBValue: number, copaysText?: JSX.Element) => <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
    <Typography variant={'body2'} color={'textPrimary'}><strong>Copays/coinsurance:</strong></Typography>
    <div className={'h-8'} />
    <Typography variant={'h5'} color={'textPrimary'}>{copaysText ? copaysText : <><strong>$0</strong> for your cost for approved Part B services</>}</Typography>
    <div className={'h-16'} />
    <Typography variant={'body2'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
    <div className={'h-8'} />
    <Typography variant={'h5'} color={'textPrimary'}><strong>$0</strong> (Hospital) Part A deductible</Typography>
    <Typography variant={'h5'} color={'textPrimary'}><strong>${partBValue}</strong> (Medical) Part B deductible</Typography>
  </Box>;

  const thirdCol = React.useCallback((values: boolean[]) => {
    if (phone) {
      return <Box display={'flex'} flexDirection={'column'} minWidth={'200px'} alignItems={'flex-start'}>
        <Typography variant={'body2'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
        <div className={'h-8'} />
        <Box display={'flex'}>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Benefit label={'Skilled nursing facility'} value={values[0]} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'$0 Part A Deductible'} value={values[1]} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Part B Deductible'} value={values[2]} labelClass={classes.label} imageClass={classes.image} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} ml={'12px'}>
            <Benefit label={'Covers Part B excess charges'} value={values[3]} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Foreign travel emergency coverage'} value={values[4]} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    }
    return <Box display={'flex'} flexDirection={'column'} minWidth={'200px'} alignItems={'flex-start'}>
      <Typography variant={'body2'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
      <div className={'h-8'} />
      <Benefit label={'Skilled nursing facility'} value={values[0]} labelClass={classes.label} imageClass={classes.image} />
      <div className={'h-8'} />
      <Benefit label={'$0 Part A Deductible'} value={values[1]} labelClass={classes.label} imageClass={classes.image} />
      <div className={'h-8'} />
      <Benefit label={'Part B Deductible'} value={values[2]} labelClass={classes.label} imageClass={classes.image} />
      <div className={'h-8'} />
      <Benefit label={'Covers Part B excess charges'} value={values[3]} labelClass={classes.label} imageClass={classes.image} />
      <div className={'h-8'} />
      <Benefit label={'Foreign travel emergency coverage'} value={values[4]} labelClass={classes.label} imageClass={classes.image} />
    </Box>;
  }, [phone]);

  return <>

    {/*<Box display={'flex'} justifyContent={'center'}>
      <img src={`/img/logos/partners-medigap${phone ? '2' : ''}.svg`} style={{maxWidth: 'fit-content'}} />
    </Box>*/}
    <AscendDivider mt={'16px'} mb={'16px'} />
    <Typography className={'bold fs-20 mb-16'} color={'textPrimary'}>Here’s a summary of popular plans in <span className={'fs-26 dark-green'}>{state?.STEP_2?.countyName + ' County, ' + state?.STEP_2?.state}:</span></Typography>
    <div className={'h-10'} />
    {counter >= 1 && <Paper elevation={0} className={classes.paper}>
        <Box padding={'12px 15px 24px'}>
          <Box display={'flex'} justifyContent={'space-between'} flexDirection={phone ? 'column': undefined}>
            <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan G</Typography>
            <PricesButton variant={'contained'}
                          onClick={onClick}>
                <Box display={'flex'}><p>Most Popular</p>
                <img className={'ml-5'} src={'/img/ribbon.svg'} /></Box>
            </PricesButton>
          </Box>
          <div className={'h-20'} />
          <Box className={classes.columnsWrapper}>
            {firstCol(Math.round(prices[userState]?.G[0]) + ' - $' + Math.round(prices[userState]?.G[1]))}
            {secondCol(203)}
            {thirdCol([true, true, false, true, true])}
          </Box>
        </Box>
      </Paper>}
    <div className={'h-16'} />

    {counter >= 2 && <Paper elevation={0} className={classes.paper}>
        <Box padding={'12px 15px 24px'}>
          <Box display={'flex'} justifyContent={'space-between'} flexDirection={phone ? 'column': undefined}>
            <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan N</Typography>
            <PricesButton variant={'contained'}
                          onClick={onClick}>
                <Box display={'flex'}><p>Best for Affordability</p>
                <img className={'ml-5'} src={'/img/ribbon.svg'} /></Box>
            </PricesButton>
          </Box>
          <div className={'h-20'} />
          <Box className={classes.columnsWrapper}>
            {firstCol(Math.round(prices[userState]?.N[0]) + ' - $' + Math.round(prices[userState]?.N[1]))}
            {secondCol(203, <><strong>$0</strong> for your cost for approved Part B services, with some $20 and $50 copays</>)}
            {thirdCol([true, true, false, false, true])}
          </Box>
        </Box>
      </Paper>}

    <div className={'h-16'} />

    {counter >= 3 && <Paper elevation={0} className={classes.paper}>
        <Box padding={'12px 15px 24px'}>
          <Box display={'flex'} justifyContent={'space-between'} flexDirection={phone ? 'column': undefined}>
            <div>
              <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan F*</Typography>
              {!phone && <Typography className={'fs-10'} color={'textPrimary'}>* Medigap Plan F is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020</Typography>}
            </div>
            <PricesButton variant={'contained'}
                          onClick={onClick}>
                <Box display={'flex'}><p>Most Comprehensive</p>
                <img className={'ml-5'} src={'/img/ribbon.svg'} /></Box>
            </PricesButton>
          </Box>
          {phone && <Typography className={'fs-10'} color={'textPrimary'}>* Medigap Plan F is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020</Typography>}
          <div className={'h-20'} />
          <Box className={classes.columnsWrapper}>
            {firstCol(Math.round(prices[userState]?.F[0]) + ' - $' + Math.round(prices[userState]?.F[1]))}
            {secondCol(0)}
            {thirdCol([true, true, true, true, true])}
          </Box>
        </Box>
      </Paper>}

    <AscendDivider mt={'32px'} mb={'16px'} />

    <Typography className={'fs-20 bold'} color={'textPrimary'}>Did you know we can also help you find a Prescription Drug Plan?</Typography>
    <Typography className={`fs-14 medium ${classes.green}`}>Medigap plans don't cover the costs of prescription drugs — for that, you need to get a Medicare Prescription Drug Plan (Part D).</Typography>

    <div className={'h-20'} />

    <Paper elevation={0} className={classes.paper}>
      <img src={'/img/big-plus-green.svg'} className={classes.plus}/>
      <Box padding={'12px 15px 24px'}>
        <Box display={'flex'} justifyContent={'space-between'} flexDirection={phone ? 'column': undefined}>
          <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Prescription Drug Plan (PDP)</Typography>
          <PricesButton variant={'contained'}
                        onClick={onClick}>Ask about available plans</PricesButton>
        </Box>
        <div className={'h-20'} />
        <Box className={classes.columnsWrapper}>
          {firstCol('7 - $89', true)}
          <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
            <Typography variant={'body2'} color={'textPrimary'}><strong>Copays:</strong></Typography>
            <Typography variant={'h5'} color={'textPrimary'}><strong>$0-$10</strong> for Preferred Generic and Generic drugs (Tier 1 and Tier 2)</Typography>
            <Typography variant={'h5'} color={'textPrimary'}><strong>$24-$47</strong> for Preferred Branded drugs (Tier 3)</Typography>
            <div className={'h-8'} />
            <Typography variant={'body2'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
            <Typography variant={'h5'} color={'textPrimary'}>Average <strong>$345</strong> (many plans apply no deductible for generic drugs). Maximum deductible is $445</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} width={'200px'} alignItems={'flex-start'}>
            <Typography variant={'body2'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
            <div className={'h-8'} />
            <Benefit label={'Coverage for prescription drugs not covered under Original Medicare'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Some plans include Insulin savings ($35 max copay)'} value={true} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    </Paper>
  </>
}

const prices: any = {
  NY: {
    G: [296.92, 408.74],
    F: [304.49, 441.46],
    N: [191.29, 228.03]
  },
  AZ: {
    G: [110.46, 484.73],
    F: [136.35, 526.91],
    N: [88.96, 371.51]
  },
  CA: {
    G: [121.37, 275.23],
    F: [160.37, 350.06],
    N: [111.07, 235.66]
  },
  FL: {
    G: [169.23, 235.68],
    N: [132.28, 190.59],
    F: [197.67, 287.60]
  },
  GA: {
    F: [136.66, 376.67],
    G: [118.83, 360.77],
    N: [92.56, 230.37]
  },
  IL: {
    F: [138.78, 462.47],
    G: [115.12, 436.19],
    N: [83.97, 415.20]
  },
  IN: {
    F: [124.03, 462.88],
    G: [104.14, 403.32],
    N: [81.97, 388.52]
  },
  MO: {
    F: [167.02, 360.86],
    G: [122.64, 326.39],
    N: [105.04, 273.01]
  },
  NC: {
    F: [120.72, 459.31],
    G: [96.71, 416.19],
    N: [76.22, 329.16]
  },
  NJ: {
    F: [162.16, 484.33],
    G: [127.58, 471.59],
    N: [96.46, 355.87]
  },
  OH: {
    F: [131.71, 443.08],
    G: [108.57, 426.36],
    N: [86.85, 378.95]
  },
  PA: {
    F: [144.82, 471.04],
    G: [113.18, 452.68],
    N: [85.48, 386.42]
  },
  SC: {
    F: [122.75, 437.55],
    G: [101.49, 418.19],
    N: [74.90, 350.17]
  },
  TN: {
    F: [124.37, 460.00],
    G: [104.13, 422.79],
    N: [80.22, 390.08]
  },
  TX: {
    F: [134.90, 480.21],
    G: [110.99, 454.55],
    N: [87.87, 363.86]
  },
  VA: {
    F: [134.79, 419.15],
    G: [100.00, 401.27],
    N: [88.17, 342.17]
  },
}

const PricesButton = withStyles((theme: Theme) =>
  createStyles({
    contained: {
      borderRadius: 4,
      padding: '8px',
      background: '#0A2E86',
      maxHeight: 30,
      width: 197,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: 10,
      },
      '&:hover': {
        backgroundColor: '#0A2E86',
        boxShadow: 'none',
        opacity: 0.5,
      },
      '&:active': {
        backgroundColor: '#0A2E86',
        boxShadow: 'none',
        opacity: 0.5,
      },
      '& .MuiButton-endIcon': {
        position: 'absolute',
        right: 5
      },
    },
    label: {
      color: 'white',
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'none',
      fontWeight: 700,
    },
}))(AscendButton)


export default withTitle(
  Step10,
  () => `Thanks, ${getStoredUser()?.firstName}! An expert will reach out shortly.`,
  ``,
  undefined,
  undefined,
  true,
  700
)

function Footer() {
  return <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1}>

    <Box display={'flex'} flexDirection={'column'} bgcolor={'white'} boxShadow={'0px 2px 6px 2px rgba(0, 0, 0, 0.25)'} borderRadius={4} maxWidth={600} width={'100&'}>
      <Box display={'flex'} width={'100%'} minHeight={'40px'} p={'0 10px'} alignItems={'center'} justifyContent={'center'} bgcolor={colors.custom.green.variant2} color={'white'}>
        <Typography color={'inherit'} className={'fs-20 bold'}>Why people choose CoverRight</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} padding={'24px 20px 14px 25px'}>
        <Box display={'flex'} alignItems={'flex-start'}>
          <img src={'/img/green-big-check-1.svg'} />
          <div className={'w-25'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Typography className={'fs-18 bold'} color={'textPrimary'}>Completely free. No obligation to enroll</Typography>
              <Typography variant={'body2'} color={'textPrimary'}>You’ll never be charged for using CoverRight to find a new plan, and we won’t pressure you into enrolling into a plan.</Typography>
            </Box>
          </Box>
        </Box>

        <div className={'h-15'} />

        <Box display={'flex'} alignItems={'flex-start'}>
          <img src={'/img/green-big-check-1.svg'} />
          <div className={'w-25'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Typography className={'fs-18 bold'} color={'textPrimary'}>Guidance from licensed, independent experts</Typography>
              <Typography variant={'body2'} color={'textPrimary'}>Our licensed agents have a wealth of experience with plans from many different carriers, so we know we can find the right plan for you.</Typography>
            </Box>
          </Box>
        </Box>

        <div className={'h-15'} />

        <Box display={'flex'} alignItems={'flex-start'}>
          <img src={'/img/green-big-check-1.svg'} />
          <div className={'w-25'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Typography className={'fs-18 bold'} color={'textPrimary'}>Friendly, personalized advice</Typography>
              <Typography variant={'body2'} color={'textPrimary'}>Speak to a real human who knows how hard it can be to find the right Medicare plan for your changing needs.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

    <Box mt={'34px'} textAlign={"center"}>
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}><strong>Need to speak to someone now?</strong> Call us at the number below to speak <br /> with a licensed CoverRight sales agent now.<br/><br/>


        <a href="tel:8889697667" className={'mt-15 no-decoration'}>
          <Box display={'flex'} justifyContent={'center'}>
            <img src={'/img/phone.svg'} width={20} />
            <Typography className={'green bold fs-25 ml-5 no-decoration'}>+1 (888) 969-7667</Typography>
          </Box>
        </a><br/>
        (TTY: 711)<br/>
        Mon-Sun 8AM-11PM (ET)</Typography>
    </Box>
    <div className={'h-200'}/>
  </Box>
}
