import React from "react";
import {Box, CircularProgress, createStyles, Fade, makeStyles, Theme} from "@material-ui/core";
import {FadeProps} from "@material-ui/core/Fade/Fade";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      zIndex: 1000,
      background: '#fff',
      borderRadius: 10,
      position: 'absolute',
      height: '100%',
      maxHeight: '100vh',
      width: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }),
);

export default function Preloader({className, ...props}: FadeProps & {children?: React.ReactNode, className?: string}) {
  const classes = useStyles();

  return (
    <Fade {...props} appear={false}>
      <div className={clsx(classes.wrapper, className)}>
        {props.children}
        <CircularProgress/>
      </div>
    </Fade>
  );
}

const useProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      zIndex: 1000,
      background: '#fff',
      position: 'fixed',
      marginLeft: 70,
      height: '100%',
      maxHeight: '100vh',
      width: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
);

export function ProfilePreloader(props: FadeProps) {
  const classes = useProfileStyles();

  return (
    <Fade {...props} appear={false}>
      <Box className={classes.wrapper}>
        <CircularProgress/>
      </Box>
    </Fade>
  );
}


