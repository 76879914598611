import React from "react";
import {VideoCategoryWithVideos} from "../../../types";

interface VideoCategoriesState {
  active?: string | null,
  categories: VideoCategoryWithVideos[]
}

let state: VideoCategoriesState = {
  active: sessionStorage.getItem('activeVideoCategory'),
  categories: []
};

const setState = (newState: {active?: string, categories?: any} | Function) => {
  if (typeof newState === "function") {
    state = newState(state);
  } else {
    state = {...state, ...newState};
  }
  listeners.forEach((listener) => {
    listener(state);
  });
};

let listeners: any[] = [];

const useVideoCategories = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {state, setState};
};

export default useVideoCategories;
