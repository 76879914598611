import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { from, split } from 'apollo-link';
import {setContext} from "apollo-link-context";
import {getToken} from "./ApolloAuthClient";
import {WebSocketLink} from "apollo-link-ws";
import { getMainDefinition } from 'apollo-utilities';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENROLLMENT_GRAPHQL,
  headers: {
    "content-type": "application/json",
  }
});

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(() => {});
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_ENROLLMENT_SUBSCRIPTIONS as string,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await getToken();
      return {
        Authorization: token ? token : "",
      }
    },
  },
})

const elink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

const requestLink =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  requestLink
);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
