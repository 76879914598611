import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import useStepperState from "../../Questionnaire/hooks/useStepperState";
import {RouteComponentProps} from "react-router";
import AscendStepper from "../../shared/SidePanel/AscendStepper";
import Terms from "../../shared/SidePanel/Terms";


export default function QuestionnaireStepper(props: RouteComponentProps<{routeName: string}>) {
  const {state} = useStepperState();

  return (
    <>
      <Box marginTop={'24px'} maxWidth={245}>
        <Typography variant={'body1'} color={'textPrimary'}>Complete our easy 4 step form and we'll help find the best Medicare coverage for you.</Typography>
      </Box>
      <AscendStepper {...props} state={state} />
      <Terms />
    </>
  )
}
