import React from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {Box, createStyles, Fade, Hidden, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import {RouteComponentProps} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import paths from "../../config/router-paths";

const formName = 'STEP_1';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: '11px 24px',
      display: 'flex',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      marginBottom: 10,
      animationName: 'card-animation-right',
      animationDuration: '0.5s',
      alignItems: 'center',
      minHeight: 101,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  }),
);

function Step1(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuoteContext);
  const classes = useStyles();
  const [counter, setCounter] = React.useState(0);
  const [interval, setIntervalValue] = React.useState();

  React.useEffect(() => {
    setIntervalValue(setInterval(() => {
      setCounter(prev => prev + 1)
      if (counter > 4 && interval) {
        clearInterval(interval)
      }
    }, 300))
  }, [])

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        form: formName
      }
    })
    const route = state?.QUIZ_3?.medicarePlanType?.value === 'MA' ? paths.MAquestionnaire : paths.MGquestionnaire;
    props.history.push(route + '/' + questionsRoutes.step2);
  };

  return <Box alignItems={"center"} justifyContent={"center"} display={'flex'} flexDirection={'column'}>
    <div className={'mt-32'} style={{maxWidth: 560}}>

      <Hidden xsDown implementation="css">
        {counter >= 1 && <div className={classes.card}>
          <img className={'mr-30'} src={'/img/clock.svg'} />
          <div>
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Complete our short 2-minute form.</Typography>
           <Typography color={'textPrimary'} className={'fs-16'}>We’ll gather some basic information from you.</Typography>
          </div>
        </div>}
        {counter >= 2 && <div className={classes.card}>
          <img style={{minWidth: 51}} className={'mr-30'} src={'/img/medical-record-2.svg'} />
          <div>
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Tell us about your preferences.</Typography>
            <Typography color={'textPrimary'} className={'fs-16'}>Our algorithms will search plans across top carriers in your area to match your preferences.</Typography>
          </div>
        </div>}
        {counter >= 3 && <div className={classes.card}>
          <img width={51} src={'/img/shop.svg'} />
          <div className={'ml-30'}>
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Receive quotes and enroll.</Typography>
            <Typography color={'textPrimary'} className={'fs-16'}>We compare top carriers to find the best plan that saves you money.  Enroll online after speaking to an agent.</Typography>
          </div>
        </div>}
      </Hidden>
      <Hidden smUp implementation="css">
        {counter >= 1 && <div className={classes.card}>
          <Box display={'flex'} alignItems={'center'}>
            <img className={'mr-20'} src={'/img/clock.svg'} />
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Complete our short 2-minute form.</Typography>
          </Box>
          <Typography color={'textPrimary'} className={'fs-16 mt-10'}>We’ll gather some basic information from you.</Typography>
        </div>}
        {counter >= 2 && <div className={classes.card}>
          <Box display={'flex'} alignItems={'center'}>
            <img style={{minWidth: 51}} className={'mr-20'} src={'/img/medical-record-2.svg'} />
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Tell us about your preferences.</Typography>
          </Box>
          <Typography color={'textPrimary'} className={'fs-16 mt-10'}>Our algorithms will search plans across top carriers in your area to match your preferences.</Typography>
        </div>}
        {counter >= 3 && <div className={classes.card}>
          <Box display={'flex'} alignItems={'center'}>
            <img className={'mr-20'} src={'/img/shop.svg'} />
            <Typography color={'textPrimary'} className={'bold fs-24 lh-30'}>Receive quotes and enroll.</Typography>
          </Box>
          <Typography color={'textPrimary'} className={'fs-16 mt-10'}>We compare top carriers to find the best plan that saves you money.  Enroll online after speaking to an agent.</Typography>
        </div>}
      </Hidden>



      <Fade in={counter >= 4}>
        <Box mt={'30px'} display={'flex'} justifyContent={'center'}>
          <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={onSubmit}>Let’s get started</AscendButton>
        </Box>
      </Fade>
    </div>
  </Box>;
}

export default withTitle(
  Step1,
  (state, isMobile) => {
    if (isMobile) {
      return `Welcome to CoverRight! Let’s help you find a new Medicare ${state?.QUIZ_3?.medicarePlanType?.value === 'MG' ? '' : 'Advantage'} plan today. `;
    } else {
      return `Welcome to CoverRight! I’m Amy. I’m here to help\nyou find a ${state?.QUIZ_3?.medicarePlanType?.value === 'MG' ? 'Medicare Supplement' : 'Medicare Advantage'} plan today.`
    }
  },
  'HOW IT WORKS',
  undefined,
  undefined,
  isMobile => isMobile,
);
