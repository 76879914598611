import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useDeleteUser() {
  return useMutation(gql(deleteUserMutation), {
    client: client
  })
}

const deleteUserMutation = `
mutation {
  deleteUser
}
`;
