import React from "react";
import {Box, Collapse, createStyles, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import {getQuoteId} from "../../shared/QuoteId";
import {
  CoverageEntryOutput,
  DrugCalculationStage,
  DrugCostOutput,
  DrugOutput,
  FrequencyPeriod,
  Plan
} from "../../types";
import moment from "moment";
import clsx from "clsx";
import * as _ from "lodash";
import {colors} from "../AppTheme";
import {usePlanLazy} from "../../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import {AscendSubtitle} from "../../Maketplace/MedicareAdvantage/DetailedPlan/components/AscendSubtitle";
import StyledRating from "../../Maketplace/MedicareAdvantage/PlanList/components/StyledRating";
import {getCompanyLogo} from "../utils";
import AscendPreloader from "./AscendPreloader";
import PlanSummaryChart from "./PlanSummaryChart";
import {PlanTitle} from "../../Maketplace/MedicareAdvantage/PlanList/components/PlanTitle";
import {FavoritesContext} from "../../Maketplace/MedicareAdvantage/shared/FavoritesContext";
import AscendDivider from "./AscendDivider";
import AscendTooltip from "./AscendTooltip";
import Benefit from "./Benefit";
import {mapDeliveryType} from "../../Maketplace/MedicareAdvantage/PlanList/PlanCard";
import {QuoteContext} from "../../Maketplace/MedicareAdvantage/PlanList/QuoteContext";
import {GetPlansYear} from "../../Maketplace/shared/hooks/GetPlansYear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    icon: {
      transform: 'rotate(0)',
      transition: 'all 0.3s',
      marginRight: 15,
      marginTop: 1
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    subtitle: {
      fontSize: 16,
      color: colors.text.primary,
    },
    titleWrapper: {
      width: '100%',
      background: 'rgba(2, 144, 148, 0.05)',
      borderRadius: 4,
      marginTop: '15px',
      marginBottom: '15px',
    },
    medium: {
      fontWeight: 500,
    },
    bold: {
      fontWeight: 700,
    },
    green: {
      color: colors.custom.green.variant2,
    },
    benefit: {
      justifyContent: 'flex-end',
    },
    table: {
      tableLayout: 'fixed',
      width: '100%',
      '& td': {
        paddingTop: 16,
        paddingBottom: 16,
        fontSize: 12,
        color: colors.text.primary,
        paddingLeft: 15,
      },
      '& tr td:last-child': {
        paddingRight: 15,
      },
      '& td:not(:first-child)': {
        textAlign: 'right'
      }
    },
    borderRow: {
      '& td': {
        borderBottom: '1px solid #E8ECED',
      }
    },
    subtitleRow: {
      background: 'rgba(164, 180, 185, 0.1)',
      '& td': {
        verticalAlign: 'top',
        paddingTop: 9,
        paddingBottom: 9,
      }
    },
    titleRow: {
      background: 'rgba(2, 144, 148, 0.05)',
      '& td': {
        paddingTop: 13,
        paddingBottom: 13,
        fontWeight: 700,
      }
    },
    pharmacyLabelClass: {
      fontWeight: 800,
      fontSize: 12,
      color: colors.text.primary
    },
    monthDetailsRow: {
      '& td': {
        fontStyle: 'italic',
      },
      '& td:first-child': {
        paddingLeft: 50,
      }
    },
    pointer: {
      cursor: 'pointer'
    },
    planTitle: {
      fontSize: '20px',
      fontWeight: 700
    },
    planLogo: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      width: '183px',
      height: '50px',
    },
    roundedCorners: {
      borderRadius: '4px'
    },
    summaryByMonthTable: {
      '& td:first-child': {
        paddingLeft: 15,
      },
      '& td:not(:first-child)': {
        paddingLeft: 9,
      },
    },
    summaryByMonthTitleRow: {
      '& td': {
        paddingTop: 14,
        paddingBottom: 14,
      }
    },
  })
);

type PlanSummaryProps = {
  zip: string,
  countyName: string,
  planId: string
}

export default function PlanSummary(props: PlanSummaryProps) {
  const classes = useStyles();
  let {zip, planId, countyName} = props;
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(true);
  const [expandedState, setExpandedState] = React.useState<{[key: string]: boolean}>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const {preferredDrugs} = React.useContext(QuoteContext);

  const [getPlan, {data}] = usePlanLazy();

  React.useEffect(() => {
    if (zip && planId) {
      getPlan({variables: {bidId: planId, zip, countyName, quoteId: getQuoteId()}})
    }
  }, [zip, planId])

  const plan = React.useMemo(() => {
    if (data) {
      return data.plan;
    }
    return null;
  }, [data])

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === 'undefined' ? false : expandedState[key];
  const toggleExpanded = (key: string) => {
    const newValue = expandedState[key] === undefined ? false : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue
    })
  }

  const groupedCosts = React.useMemo(() => {
    if (plan) {
      return Object.values(_.groupBy(plan.drugCostMonthlyBreakdown?.costs, 'month'))
        .map(g => g.map(c => {
          const qty = plan.drugPrices.find(d => d.rxcui === c.rxcui)?.quoteQuantity || '';
          const drug = preferredDrugs?.preferredDrugs?.find((d: DrugOutput) => d.packRxcui === c.rxcui);
          let purchaseFrequency = drug ? (drug.purchaseFrequency === FrequencyPeriod.Monthly ? 'Every month' : 'Every 3 month') : '';
          return {
            ...c,
            qty,
            frequency: purchaseFrequency,
          }
        }))
    }
  }, [plan, preferredDrugs]);

  if (!plan) {
    return <AscendPreloader />;
  }

  const toTable = (costs: (DrugCostOutput & {qty: string, frequency: string})[]) => {
    const month = costs[0].month;
    const monthName = moment(month.toString(), 'M').format('MMMM')
    const monthlyCost = plan?.drugCostMonthlyBreakdown?.monthlyCosts?.find(mc => mc.month == month)
    return <>
      <table className={classes.table} cellSpacing={0}>
        <tbody>
        <tr className={clsx(classes.borderRow, classes.pointer)}
            onClick={
              () => setExpandedState(prevState => ({...prevState, [monthName] : typeof prevState[monthName] === 'undefined' ? true : !prevState[monthName]}))
            }
        >
          <td colSpan={2} className={classes.medium}>
            {!phone && <img src={'/img/arrow.svg'} className={clsx(classes.icon, getExpandedValue(monthName) && classes.iconOpen)} />}
            {monthName}
          </td>
          <td className={classes.medium}></td>
          <td className={classes.medium}></td>
          <td className={classes.medium}>
            {monthlyCost?.amountString}
          </td>
          <td className={classes.medium}>
            {!phone && <>{_.upperFirst(_.lowerCase(monthlyCost?.stage)) || ''}</>}
            {phone && <img src={'/img/arrow.svg'} className={clsx(classes.icon, getExpandedValue(monthName) && classes.iconOpen)} />}
          </td>
        </tr>
        </tbody>
      </table>
      <Collapse in={getExpandedValue(monthName)}>
        <table className={classes.table} cellSpacing={0}>
          <tbody>
          {costs.map(cost => <tr key={`${cost.month}-${cost.name}`} className={clsx(classes.borderRow, classes.monthDetailsRow)}>
            <td colSpan={2}>{cost.name}</td>
            <td>{cost.qty}</td>
            <td>{cost.frequency}</td>
            <td>{cost.cost}</td>
            <td></td>
          </tr>)}

          </tbody>
        </table>
      </Collapse>
    </>;
  }

  const List = ({data}: {data: {title: string, data: {label: string, value: string}[]}[]}) => {
    const HeaderRow = ({title, onClick, open}: {title: string, onClick: () => void, open: boolean}) => {
      return <>
        <Box className={'flex-space-between flex-align-center'} py={'9px'}>
          <Typography className={'bold fs-12'} color={'textPrimary'}>{title}</Typography>
          <img src={'/img/arrow.svg'} onClick={onClick} className={clsx(classes.icon, open && classes.iconOpen)} />
        </Box>
        <AscendDivider />
      </>
    }
    const Row = (props: {label: string, value: string}) => {
      return <>
        <Box className={'flex flex-align-center'} py={'9px'}>
          <Box display={'flex'} flex={1}><Typography className={'fs-12'} color={'textPrimary'}>{props.label}</Typography></Box>
          <Box display={'flex'} flex={1} ml={1}><Typography className={'fs-12'} color={'textPrimary'}><i>{props.value}</i></Typography></Box>
        </Box>
        <AscendDivider />
      </>
    }
    const Section = ({data}: {data: {title: string, data: {label: string, value: string}[]}}) => {
      const [open, setOpen] = React.useState(false);
      return <>
        <HeaderRow title={data.title} onClick={() => setOpen(!open)} open={!open} />
        <Collapse in={!open}>
          {data.data.map(item => <Row key={item.value} value={item.value} label={item.label} />)}
        </Collapse>
      </>
    }

    return <>
      {data.map(item => <Section data={item} />)}
    </>
  }

  const hitIC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.InitialCoverage);
  const hitCG= plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.CoverageGap);
  const hitCC= plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.Catastrophic);

  return <Box>
      <div onClick={() => history.push(`/plan/${zip}/${countyName}/${planId}`)}>
        {!phone && <>
          <PlanRating plan={plan}/>
          <div className={'flex-space-between flex-align-center'}>
            <div>
              <Typography variant={'h3'} color={'textPrimary'} className={classes.planTitle}>{plan.planName}</Typography>
            </div>
            <div>
              <div className={classes.planLogo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
            </div>
          </div>
        </>}
        {phone && <div className={phone ? 'mh-10 mb-16' : ''}>
          <PlanTitle plan={plan}
                     inFavorites={favorites.indexOf(plan.bidId) >= 0}
                     favoritesClick={() => toggleFavorite(plan.bidId, zip)}/>
        </div>}
      </div>
      <AscendSubtitle title={"Estimated Monthly Cost Summary"}
                      wrapperClassName={phone ? 'ph-10' : ''}
                      expanded={phone ? getExpandedValue('monthlyCostSummary') : undefined}
                      onToggleClick={() => toggleExpanded('monthlyCostSummary')}/>
      <Box>
        {!phone && <div className={'mb-15'}>
          <PlanSummaryChart {...props} />
        </div>}
        <InitialCoverageSection className={phone ? 'mh-10' : ''} plan={plan} entry={hitIC}/>
        <CoverageGapSection className={phone ? 'mh-10' : ''} plan={plan} entry={hitCG}/>
        <CatastrophicSection className={phone ? 'mh-10' : ''} plan={plan} entry={hitCC}/>

        <Box mt={'7px'} mb={'12px'}>
          <AscendSubtitle wrapperClassName={phone ? 'ph-10' : `ph-15 ${classes.roundedCorners}`}
                          title={'See detailed cost calculations'}
                          expanded={expanded}
                          onToggleClick={() => setExpanded(!expanded)}/>
        </Box>

        <Collapse in={expanded}>
          {plan?.quoteDrugsTiers?.pharmacyName && <div>
            <Benefit label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/>
            <div className={'h-10'} />
            <Typography variant={"body2"} className={'fs-12'} color={'textPrimary'}>({mapDeliveryType(plan?.quoteDrugsTiers?.deliveryType)})</Typography>
          </div>}
          {phone ? <>
            <Box mt={'7px'} mb={'12px'}>
              <AscendSubtitle title={'Your drugs'}
                              wrapperClassName={phone ? 'ph-10' : ''}
                              titleClassName={classes.subtitle}
                              expanded={!getExpandedValue('yourDrugs')}
                              onToggleClick={() => toggleExpanded('yourDrugs')}/>
            </Box>
            <Collapse in={!getExpandedValue('yourDrugs')} className={phone ? 'mh-10' : ''}>
              <List data={plan.drugPrices.map(price => ({
                title: price.name,
                data: [
                  {
                    label: 'Quantity',
                    value: price.quoteQuantity
                  },
                  {
                    label: 'Package',
                    value: price.packageDescription
                  },
                  {
                    label: 'Covered under this plan',
                    value: price.isCoveredByPlan ? 'Yes' : 'No'
                  },
                  {
                    label: 'Quantity limits under this plan?',
                    value: price.quantityLimit || '-'
                  },
                  {
                    label: 'Branded or generic?',
                    value: price.isGeneric ? 'Generic' : 'Branded'
                  },
                ]
              }))} />
            </Collapse>
            <Box mt={'7px'} mb={'12px'}>
              <AscendSubtitle title={'By coverage phase'}
                              wrapperClassName={phone ? 'ph-10' : ''}
                              titleClassName={classes.subtitle}
                              expanded={!getExpandedValue('byCoveragePhase')}
                              onToggleClick={() => toggleExpanded('byCoveragePhase')}/>
            </Box>
            <Collapse in={!getExpandedValue('byCoveragePhase')}  className={phone ? 'mh-10' : ''}>
              <List data={plan.drugPrices.map(price => ({
                title: price.name,
                data: [
                  {
                    label: 'Tier',
                    value: price.tier?.toString() || ''
                  },
                  {
                    label: 'Deductible',
                    value: `${price.price} / ${price.unitPrice} ${price.isAggregatedPrice ? '*' : ""}`
                  },
                  {
                    label: 'Initial coverage',
                    value: price.initialCoveragePrice
                  },
                  {
                    label: 'Coverage gap ("Donut hole")',
                    value: price.coverageGapPrice
                  },
                  {
                    label: 'Catastrophic coverage',
                    value: price.catastrophicPrice
                  },
                ]
              }))} />
            </Collapse>
        </>: <table className={classes.table} cellSpacing={0}>
            <thead>
            <tr className={classes.titleRow}>
              <td>Your drugs</td>
              <td>Covered under this plan?</td>
              <td>Qty</td>
              <td>Package</td>
              <td>Quantity limits under this plan?</td>
              <td>Branded or generic?</td>
            </tr>
            </thead>
            <tbody>
            {plan.drugPrices.map((item, i, arr) => <tr key={item.rxcui} className={i != (arr.length - 1) ? classes.borderRow : undefined}>
              <td>{item.name}</td>
              <td>
                <Benefit label={item.isCoveredByPlan ? 'Covered' : 'Not covered'}
                         value={item.isCoveredByPlan}
                         className={classes.benefit}/>
              </td>
              <td><i>{item.quoteQuantity}</i></td>
              <td><i>{item.packageDescription}</i></td>
              <td><i>{item.quantityLimit || '-'}</i></td>
              <td>{item.isGeneric ? 'Generic' : 'Branded'}</td>
            </tr>)}
            </tbody>
            <thead>
            <tr className={clsx(classes.titleRow, classes.borderRow)}>
              <td>By coverage phase</td>
              <td>Tier</td>
              <td>Deductible</td>
              <td>Initial coverage</td>
              <td>Coverage gap ("Donut hole")</td>
              <td>Catastrophic coverage</td>
            </tr>
            <tr className={classes.subtitleRow}>
              <td>What you pay</td>
              <td></td>
              <td>
                <AscendTooltip placement={'top'} arrow title={'This is an estimate of the regular cash retail price you would pay at your selected pharmacy. You pay this price until you meet your plan\'s deductible (if there is one). This price is also used to determine the coinsurance in the Initial Coverage phase (if applicable), Coverage Gap phase and Catastrophic Coverage phase.'}>
                  <Typography align={'right'} color={'textPrimary'} className={'pointer underline fs-12'}>Estimated full retail price</Typography>
                </AscendTooltip>
              </td>
              <td>
                <AscendTooltip placement={'top'} arrow title={'This is the copay or coinsurance that you will pay under this plan\'s Initial Coverage phase. This amount is dependent on the quantity and days\' supply you purchase, the drug tier, and also whether you purchase from a preferred or standard retail pharmacy or mail order pharmacy. If the retail price is less than the copay or coinsurance, you pay the retail price instead.'}>
                  <Typography align={'right'} color={'textPrimary'} className={'pointer underline fs-12'}>Minimum of retail price and plan copay/
                    coinsurance (based on tier)</Typography>
                </AscendTooltip>
              </td>
              <td>
                <AscendTooltip placement={'top'} arrow title={'This is the amount you pay in this plan\'s Coverage Gap phase. Typically, this price is 25% of the retail price, for both generic and branded drugs. However, some plans have lower copays or coinsurance in this phase for some drug tiers.'}>
                  <Typography align={'right'} color={'textPrimary'} className={'pointer underline fs-12'}>Typically 25% of retail (both branded and generic)</Typography>
                </AscendTooltip>
              </td>
              <td>
                <AscendTooltip placement={'top'} arrow title={'This is the amount you pay in this plan\'s Catastrophic Coverage phase. The price in this phase is always the greater of 5% of retail or $3.70 for generic drugs, and the greater of 5% of retail or $9.20 for branded drugs.'}>
                  <Typography align={'right'} color={'textPrimary'} className={'pointer underline fs-12'}>Greater of 5% or $3.70 (generics) /
                    $9.20 (branded)</Typography>
                </AscendTooltip>
              </td>
            </tr>
            </thead>
            <tbody>
            {plan.drugPrices.map((item, i, arr) => <tr key={item.rxcui} className={i != (arr.length - 1) ? classes.borderRow : undefined}>
              <td>{item.name}{item.isAggregatedPrice ? <sup>*</sup>: ""}</td>
              <td>{item.tier}</td>
              <td>{item.price} {item.isAggregatedPrice ? <sup>*</sup>: ""}</td>
              <td>{item.initialCoveragePrice}</td>
              <td>{item.coverageGapPrice}</td>
              <td>{item.catastrophicPrice}</td>
            </tr>)}
            </tbody>
          </table>}
          {!phone && <>
            <table className={clsx(classes.table, classes.summaryByMonthTable)} cellSpacing={0}>
              <tbody>
                <tr className={clsx(classes.titleRow, classes.summaryByMonthTitleRow)}>
                  <td colSpan={2} className={classes.medium}>Your summary by month</td>
                  <td className={classes.medium}>Qty</td>
                  <td className={classes.medium}>Purchase frequency</td>
                  <td className={classes.medium}>Monthly cost</td>
                  <td className={classes.medium}>Coverage level</td>
                </tr>
              </tbody>
            </table>
            {!!plan && groupedCosts?.map((monthlyCosts, i) =>
              <div key={i}>
                {toTable(monthlyCosts)}
              </div>)}
            <table className={clsx(classes.table, classes.summaryByMonthTable)} cellSpacing={0}>
              <tbody>
              <tr className={clsx(classes.titleRow, classes.summaryByMonthTitleRow)}>
                <td colSpan={2} className={classes.medium}>Total Annual Cost</td>
                <td className={classes.medium}></td>
                <td className={classes.medium}></td>
                <td className={classes.medium}>{plan?.drugCostMonthlyBreakdown?.totalClientCost}</td>
                <td className={classes.medium}></td>
              </tr>
              </tbody>
            </table>
          </>}
          {phone && <>
            <Box mt={'7px'} mb={'12px'}>
              <AscendSubtitle title={'Your summary by month'}
                              wrapperClassName={phone ? 'ph-10' : ''}
                              titleClassName={classes.subtitle}
                              expanded={!getExpandedValue('yourSummary')}
                              onToggleClick={() => toggleExpanded('yourSummary')}/>
            </Box>
            <Collapse in={!getExpandedValue('yourSummary')}>
              {!!plan && groupedCosts?.map((monthlyCosts, i) =>
                <div key={i}>
                  {toTable(monthlyCosts)}
                </div>)}
            </Collapse>
          </>
          }
        </Collapse>
      </Box>
    </Box>
}

function InitialCoverageSection(props: {plan: Plan, entry?: CoverageEntryOutput, className?: string}) {
  const classes = useStyles();

  return <div className={props.className}>
    <Typography variant={'body1'} className={classes.bold} color={'textPrimary'}>
      {!!props.entry && <>You most likely will enter the <span className={'underline'}>Initial Coverage Phase</span> this year under this plan based on the information you provided on your prescription drugs.</>}
      {!props.entry && <>You most likely won't enter the <span className={'underline'}>Initial Coverage Phase</span> this year under this plan based on the information you provided on your prescription drugs.</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      {props.plan?.drugDetails?.mrxAltDedAmount ? `This plan has a drug deductible of ${props.plan?.drugDetails?.mrxAltDedAmount}. That means you'll pay the retail drug price for your covered drugs up until that point, before your insurance starts paying.` : `This plan doesn't have a drug deductible. That means you'll pay a copay or co-insurance for all of your covered prescription drugs until you reach the Coverage Gap.` }
    </Typography>

  </div>
}

function CoverageGapSection(props: {plan: Plan, entry?: CoverageEntryOutput, className?: string}) {
  const classes = useStyles();

  return <div className={props.className}>
    <Typography variant={'body1'} className={clsx(classes.bold, 'mt-15')} color={'textPrimary'}>
      {!!props.entry && <>You most likely will enter the <span className={'underline'}>Coverage Gap Phase in {intToMonth(props.entry.month)}</span> this year under this plan based on the information you provided on your prescription drugs.</>}
      {!props.entry && <>You most likely won't enter the <span className={'underline'}>Coverage Gap Phase</span> this year under this plan based on the information you provided on your prescription drugs.</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      After you pay your deductible (if applicable), you’ll pay a copay or co-insurance on your drugs up until the sum of your portion of costs and what the plan has paid is {props.plan?.drugDetails?.initialCoverageLimit} (in 2022) per year, and then you’ll enter the Coverage Gap (or “donut hole”) phase, where you will typically pay 25% of retail cost for your drugs.
    </Typography>
  </div>
}

function CatastrophicSection(props: {plan: Plan, entry?: CoverageEntryOutput, className?: string}) {
  const classes = useStyles();

  return <div className={props.className}>
    <Typography variant={'body1'} className={clsx(classes.bold, 'mt-15')} color={'textPrimary'}>
      {!!props.entry && <>You most likely will enter the <span className={'underline'}>Catastrophic Coverage Phase in {intToMonth(props.entry.month)}</span> this year under this plan based on the information you provided on your prescription drugs.</>}
      {!props.entry && <>You most likely won't enter the <span className={'underline'}>Catastrophic Coverage Phase</span> this year under this plan based on the information you provided on your prescription drugs.</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      You will enter the Catastrophic Coverage phase once your total out-of-pocket costs reaches {props.plan?.drugDetails?.catastrophicThreshold} (in 2022). Under this phase, you’ll pay the maximum of {props.plan?.drugDetails?.catastrophicCopayGeneric} or 5% of retail price for generic drugs, and {props.plan?.drugDetails?.catastrophicCopayBranded} or 5% of retail price for branded drugs.
    </Typography>
  </div>
}

function intToMonth(month: number): string {
  switch (month) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
}

function PlanRating({plan}: {plan: Plan}) {
  return !plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
    : <StyledRating className={'mt-0'} precision={0.5} readOnly defaultValue={plan.rating || 0}/>;
}
