import {PreferredDrug} from "../../Maketplace/MedicareAdvantage/PlanList/hooks/useDrugsByRequestId";
import React, {Dispatch, SetStateAction} from "react";
import useGetDrugsRequest, {DrugInfoWithPack} from "../../Questionnaire/hooks/useGetDrugsRequest";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {useDebouncedEffect} from "../useDebouncedEffect";
import DrugDosageModal, {DrugDosage} from "../../Questionnaire/components/modals/DrugDosageModal";
import ConfirmBrandedDrugModal from "../../Questionnaire/components/modals/ConfirmBrandedDrugModal";
import {Box, CircularProgress, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import * as _ from "lodash";
import AscendAutocomplete, {ScrollbarList} from "./AscendAutocomplete";
import FormTextInput from "./FormTextInput";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {DrugInfoWithGeneric, DrugOutput, FrequencyPeriod, PackInfoOuput} from "../../types";
import useGetPackagesRequest from "../../Questionnaire/hooks/useGetPackagesRequest";
import useDrugPackagesInfoRequest from "../../Questionnaire/hooks/useDrugPackagesInfoRequest";
import NoPackagesModal from "../../Questionnaire/components/modals/NoPackagesModal";

type DrugSelectionProps = {
  selectedPrescriptions: DrugOutput[],
  setSelectedPrescriptions: Dispatch<SetStateAction<DrugOutput[]>>,
  showAutocomplete: boolean,
  setShowAutocomplete: Dispatch<SetStateAction<boolean>>,
}

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 90,
    paddingTop: 10,
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 8,
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
  },
  addMedicationButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: `1px dashed ${colors.custom.green.variant2}`,
    background: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 8,
    width: '100%',
    paddingTop: 17,
    paddingBottom: 17,
    color: colors.custom.green.variant2,
    fontWeight: 700,
    fontSize: 16,
  }
});

export const DrugSelection = ({selectedPrescriptions, setSelectedPrescriptions, showAutocomplete, setShowAutocomplete}: DrugSelectionProps) => {
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [selectedValue, setSelectedValue] = React.useState<DrugInfoWithPack>();
  const [brandedDrugModalOpen, setBrandedDrugModalOpen] = React.useState(false);
  const [drugDosageModalOpen, setDrugDosageModalOpen] = React.useState(false);
  const [noPackagesModalOpen, setNoPackagesModalOpen] = React.useState(false);
  const [selectedDrug, setSelectedDrug] = React.useState<DrugName>();
  const [selectedDosage, setSelectedDosage] = React.useState<PreferredDrug>();
  const [packages, setPackages] = React.useState<PackInfoOuput[]>();
  const [open, setOpen] = React.useState(true);
  const inputRef = React.useRef(null);

  const {refetch: getDrugs} = useGetDrugsRequest();
  const [getDrugInfo, {data: packagesInfoData}] = useDrugPackagesInfoRequest(data => {
    if (selectedValue) {
      if ((!selectedValue.isGeneric && !data.drugPackagesInfo.hasBrandedPackages) ||
        (selectedValue.isGeneric && !data.drugPackagesInfo.hasGenericPackages)) {
        setNoPackagesModalOpen(true);
      } else {
        if (!selectedValue.isGeneric && selectedValue.genericName && data.drugPackagesInfo.hasGenericPackages) {
          setBrandedDrugModalOpen(true);
        } else {
          setSelectedDrug({name: selectedValue.name, rxcui: selectedValue.rxcui})
          getPackages({
            variables: {
              rxcui: selectedValue.rxcui as string
            }
          })
        }
      }
    }
  });

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  React.useEffect(() => {
    if (selectedValue) {
      getDrugInfo({variables: {rxcui: selectedValue.rxcui}})
    }
  }, [selectedValue])

  React.useEffect(() => {
    if (selectedDrug) {
      getPackages({
        variables: {
          rxcui: selectedDrug.rxcui
        }
      })
    }
  }, [selectedDrug])

  const [getPrescriptions, data] = useLazyQuery<IPrescriptionsResponse>(gql(prescriptionsRequest));

  const [getPackages, packagesData] = useGetPackagesRequest((data) => {
    if (!!data?.packages.length) {
      setPackages(data.packages);
      setDrugDosageModalOpen(true);
    } else {
      console.error('There is no drugs with this name')
    }
  });

  useDebouncedEffect(() => {
    if (!!autocompleteValue) {
      getPrescriptions({
        variables: {
          searchTerm: autocompleteValue
        }
      })
    }
  }, 300, [autocompleteValue])


  const autocompleteOpen = React.useMemo(() => {
    return !!autocompleteValue && !data.loading && data.data?.drugs && open
  }, [autocompleteValue, data, open])

  const onBrandedDrugModalClose = (useGeneric?: boolean) => {
    setBrandedDrugModalOpen(false);
    if (typeof useGeneric !== 'undefined') {
      if (selectedValue) {
        setSelectedDrug(useGeneric ?
          {name: selectedValue.genericName, rxcui: selectedValue.genericRxcui} :
          {name: selectedValue.name, rxcui: selectedValue.rxcui}
        )
      }
    } else {
      setSelectedValue(undefined);
    }
  }

  const onDrugDosageModalClose = async (result?: DrugDosage | boolean) => {
    setDrugDosageModalOpen(false);
    setSelectedDosage(undefined);

    if (typeof result === 'undefined') {
      setSelectedValue(undefined);
    }

    if (result === false) {
      if ((!selectedValue?.isGeneric && !packagesInfoData?.drugPackagesInfo?.hasBrandedPackages) ||
        (selectedValue?.isGeneric && !packagesInfoData?.drugPackagesInfo?.hasGenericPackages)) {
        setNoPackagesModalOpen(true);
      } else {
        if (!selectedValue?.isGeneric) {
          setBrandedDrugModalOpen(true);
          return;
        }
      }
    }


    if (typeof result !== 'boolean' && !!result?.dosage && selectedDrug) {
      const {data} = await getDrugs({pairs: [{packageRxcui: result.dosage, productRxcui: selectedDrug.rxcui}]})
      setSelectedPrescriptions(prevState => {
        const prevDrug = prevState.find(drug => drug.rxcui === selectedDrug.rxcui);
        if (prevDrug) {
          return [...prevState.reduce((prev, cur) => {
            if (cur.rxcui === selectedDrug.rxcui) {
              return [...prev, {
                ...data.drugsWithPackage[0],
                frequency: result.frequency,
                quantity: result.quantity as number,
                dosage: result.dosage,
                purchaseFrequency: result.purchaseFrequency
              }];
            }
            return [...prev, cur];
          }, [] as any)]
        } else {
          return [...prevState, {
            ...data.drugsWithPackage[0],
            frequency: result.frequency,
            quantity: result.quantity as number,
            dosage: result.dosage,
            purchaseFrequency: result.purchaseFrequency
          }]
        }
      })
      setSelectedValue(undefined);
      setShowAutocomplete(false);
      setAutocompleteValue('');
    }
  }

  const onNoPackagesModalClose = (switchTo?: {name: string, rxcui: string}) => {
    if (switchTo) {
      setSelectedDrug(switchTo);
    } else {
      setSelectedDosage(undefined);
      setSelectedValue(undefined);
    }
    setNoPackagesModalOpen(false);
  }

  return <>
    <NoPackagesModal info={packagesInfoData?.drugPackagesInfo} open={!!noPackagesModalOpen} onClose={onNoPackagesModalClose}/>
    <ConfirmBrandedDrugModal drug={selectedValue} open={!!brandedDrugModalOpen} onClose={onBrandedDrugModalClose}/>
    <DrugDosageModal packages={packages} drug={selectedDrug} dosage={selectedDosage} open={!!drugDosageModalOpen} onClose={onDrugDosageModalClose}/>
    {selectedPrescriptions.map((item: DrugOutput) => <div key={item.rxcui}><Box className={classes.lineWrapper}>
      <DrugLine drug={item} />
      <Box ml={3} mr={'-12px'} display={'flex'}>
        <Typography className={classes.link} variant={'body2'}
                                                   onClick={() => {
                                                     setSelectedDrug({rxcui: item.rxcui || '', name: item.name || ''});
                                                     setSelectedDosage({
                                                       dosage: item.packName || '',
                                                       dosageRxcui: item.packRxcui || '',
                                                       frequency: item.frequency || '',
                                                       ndc: item.frequency || '',
                                                       productId: item.name || '',
                                                       productRxcui: item.rxcui || '',
                                                       quantity: item.quantity || 0,
                                                       purchaseFrequency: item.purchaseFrequency || FrequencyPeriod.Monthly
                                                     });
                                                     setDrugDosageModalOpen(true);
                                                   }}
                                                   color={'textSecondary'}>Edit drug</Typography>
        <IconButton onClick={() => setSelectedPrescriptions(prescriptions => {
          const result = _.without(prescriptions, item);
          if (!result.length) {
            setShowAutocomplete(true);
          }
          return result;
        })}>
          <img height={24} width={24} src={'/img/remove_round_green_2.svg'} />
        </IconButton>
      </Box>
    </Box></div>)}
    {!!selectedPrescriptions.length && <div className={'h-16'} />}
    {showAutocomplete && <AscendAutocomplete fullWidth
                                             open={autocompleteOpen}
                                             onClose={() => {
                                               setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                                             }}
                                             onFocus={() => setOpen(true)}
                                             options={data.data?.drugs || []}
                                             value={autocompleteValue}
                                             onInputChange={(event, newInputValue) => {
                                               setAutocompleteValue(newInputValue);
                                             }}
                                             filterOptions={(options) => options}
                                             popupIcon={data.loading ? <CircularProgress className={classes.loadingIcon}/> : <img src={'/img/popupIcon.svg'} />}
                                             onChange={(e: any, prescription: any | null) => {
                                               if (prescription) {
                                                 setSelectedValue(prescription)
                                               }
                                             }}
                                             ListboxComponent={ScrollbarList as any}
                                             renderOption={(drug: any, state) => <DrugOption drug={drug} />}
                                             renderInput={params => <FormTextInput inputRef={inputRef}
                                                                                   name={'search-drug-input'}
                                                                                   label={'Search drug name:'}
                                                                                   {...params} />} />}
    {!showAutocomplete && <Box id={'add-drugs-button'} className={classes.addMedicationButton} onClick={() => setShowAutocomplete(true)}>
      <Box mr={'10px'} mt={'11px'}>
        <img src={'/img/plus_green_2.svg'} />
      </Box>
      Add more drugs
    </Box>}
  </>
}

export type DrugName = {
  name: string
  rxcui: string
}

interface IPrescriptionsResponse {
  drugs: DrugInfoWithGeneric[]
}

const prescriptionsRequest = `
query ($searchTerm: String!) {
  drugs(name: $searchTerm) {
    genericName
    genericRxcui
    isGeneric
    name
    rxcui
  }
}`;

const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    title: {
      fontWeight: 500,
    },
    dosage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        alignItems: 'flex-start',
      },
      textAlign: 'right',
    }
  })
);


const DrugOption = (props: {drug: DrugInfoWithGeneric}) => {
  const classes = useLineStyles(props);

  return <Box className={classes.root}>
    <Box>
      <Box mb={'10px'}>
        <Typography variant={'h4'} color={'textPrimary'} className={`${classes.title} drug-name`}>
          {props.drug.name}
        </Typography>
      </Box>
      <Typography variant={'body2'} color={'textSecondary'}>
        {props.drug.isGeneric ? 'Generic' : props.drug.genericName || 'Branded'}
      </Typography>
    </Box>
  </Box>
}


const DrugLine = (props: {drug: DrugOutput}) => {
  const classes = useLineStyles(props);

  return <Box className={classes.root}>
    <Box>
      <Box mb={'10px'}>
        <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>
          {props.drug.name}
        </Typography>
      </Box>
      <Typography variant={'body2'} color={'textSecondary'}>
        {props.drug.packName}
      </Typography>
    </Box>
    <Box className={classes.dosage}>
      <Typography variant={'body2'} color={'textSecondary'}>{props.drug.isGeneric ? 'Generic' : props.drug.genericName || 'Branded'}</Typography>
      <Typography variant={'body2'} color={'textSecondary'}>{props.drug.quantity} {_.upperFirst(_.lowerCase(props.drug.frequency))}</Typography>
    </Box>
  </Box>
}
