import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import moment from "moment";
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import useUserProfile, {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import AscendRadio from "../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    }
  })
);
const formName = 'EnrollmentForm';


const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  medicareNumber: yup.string().required("Please enter a valid Medicare number"),
  medicareNumberConfirm: yup.string().required("Medicare number must match"),
});

interface PersonalDetailsFormState {
  firstName: string;
  lastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  mailZip: string;
  mailEmail: string;
  mailAptNumber: string;
  mailCity: string;
  mailStreet: string;
  medicareNumber: string;
  medicareNumberConfirm: string;
  phoneNumber: string;
}

function EnrollmentForm1(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [birthDate, setBirthDate] = React.useState<Date | null>(null);
  const [medicarePartAStartedAt, setMedicarePartAStartedAt] = React.useState<Date | null>(null);
  const [medicarePartBStartedAt, setMedicarePartBStartedAt] = React.useState<Date | null>(null);
  const [gender, setGender] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>();
  const [details, setDetails] = React.useState<string>();
  const history = useHistory();
  const [save] = useSaveEnrollment();
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (values.medicareNumber !== values.medicareNumberConfirm) {
      setError("medicareNumberConfirm", {message: "Medicare number must match", shouldFocus: true})
    } else if (formState.isValid && gender && moment(birthDate).isValid()) {
      clearErrors()
      /*save({variables: {data: values}}).then(data => {
        setStoredUser({
          ...getStoredUser(),
          enrollmentId: data?.data?.saveEnrollment,
        })
      });*/
      props.history.push(paths.personalDetails2 + "/" + planId + "/" + zip + "/" + countyName)
    }
  };

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setGender(userInfoData.data.userProfile.gender || '');
      setBirthDate(userInfoData.data.userProfile.birthDate ? new Date(userInfoData.data.userProfile.birthDate) : null);
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography className={'bold fs-26'} color={'textPrimary'}>Personal details</Typography>
    <Typography className={'medium fs-18'} color={'textPrimary'}>We need to collect some additional details to confirm your eligibility for enrollment and complete your application.<br /><br /> <i>Fields marked with an asterisk (*) are required.</i></Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color={'textPrimary'} className={'bold fs-24'}>Personal information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           defaultValue={userInfo?.firstName}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           placeholder={'First name'}
                           label={'First name*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           defaultValue={userInfoData.data?.userProfile?.lastName}
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Last name'}
                           label={'Last name*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              error={formState.isSubmitted && !gender}
              helperText={formState.isSubmitted ? "Select a valid sex" : undefined}
              label={'Sex*'}
              value={gender}
              placeholder={'Select sex'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setGender(event.target.value as string);
              }}
            >
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Date of birth*'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                error={formState.isSubmitted && !moment(birthDate).isValid()}
                helperText={(formState.isSubmitted && !moment(birthDate).isValid()) && 'Please enter a valid date'}
                placeholder={'MM / DD / YYYY'}
                value={birthDate}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setBirthDate(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'32px'} />
            <Typography color={'textPrimary'} className={'bold fs-24 mb-5'}>Medicare details</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput name={'medicareNumber'}
                           InputProps={{
                             inputComponent: MedicareNumberInput as any
                           }}
                           defaultValue={userInfoData.data?.userProfile?.medicareNumber}
                           error={formState.isSubmitted && !!errors.medicareNumber}
                           helperText={formState.isSubmitted && errors?.medicareNumber?.message}
                           inputRef={register}
                           placeholder={'_ _ _ _ - _ _ _ - _ _ _ _'}
                           label={'Medicare Number*'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput name={'medicareNumberConfirm'}
                           InputProps={{
                             inputComponent: MedicareNumberInput as any
                           }}
                           defaultValue={userInfoData.data?.userProfile?.medicareNumber}
                           error={formState.isSubmitted && !!errors.medicareNumberConfirm}
                           helperText={formState.isSubmitted && errors?.medicareNumberConfirm?.message}
                           inputRef={register}
                           placeholder={'_ _ _ _ - _ _ _ - _ _ _ _'}
                           label={'Re-enter Medicare Number*'} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Hospital (Part A) effective date*'}
                error={formState.isSubmitted && !medicarePartAStartedAt}
                helperText={formState.isSubmitted && 'Please enter a valid date'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartAStartedAt}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartAStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Medical (Part B) effective date*'}
                error={formState.isSubmitted && !medicarePartBStartedAt}
                helperText={formState.isSubmitted && 'Please enter a valid date'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartBStartedAt}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartBStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'32px'} />
            <Typography color={'textPrimary'} className={'bold fs-24 mb-15'}>Eligibility attestation</Typography>
            <Typography color={'textPrimary'} className={'fs-16 mb-5'}>Typically, you may enroll in a Medicare Advantage plan during the Annual Election Period (AEP) between October 15 and December 7 of each year. In addition, you can choose to change your Medicare Advantage plan once during the annual Open Enrollment Period (OEP) between January 1 and March 31 of each year, or immediately after enrolling in a plan during your IEP/ICEP (OEP NEW). Limitations on allowed plan changes during OEP apply.<br/><br/><b>There are exceptions that may allow you to enroll outside of this period</b>. Please read the following statements carefully and mark the circle to the left of any statement that applies to you. By marking any of the following circles you are certifying that, to the best of your knowledge, the text is a true statement about you. If we later determine that this information is incorrect, you may be disenrolled.<br/><br/>You must select one of the options below:</Typography>
            <RadioGroup>
              <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={status === 'new'}
                           onChange={(e, checked) => {setStatus('new')}} value="yes" label="I am new to Medicare." />
              <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={status === 'enrolled'}
                           onChange={(e, checked) => {setStatus('enrolled')}} value="yes" label="I am enrolled in a Medicare Advantage plan and want to make a change during the Medicare Advantage Open Enrollment Period (MA OEP)." />
              <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={status === 'moved'}
                           onChange={(e, checked) => {setStatus('moved')}} value="yes" label="I recently moved outside of the service area for my current plan or I recently moved and this plan is a new option for me. Provide date below:" />
              <CollapseDate in={status === 'moved'} value={null} onChange={() => {}} />
              <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={status === 'qualify'}
                           onChange={(e, checked) => {setStatus('qualify')}} value="yes" label="I qualify for a Special Enrollment Period. Expand to see list and add details below" />
            </RadioGroup>
            <Collapse in={status === 'qualify'} className={'pl-20'}>
              <RadioGroup>
                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'incarceration'}
                             onChange={(e, checked) => {setDetails('incarceration')}} value="yes" label="I recently was released from incarceration. Provide date of release below: " />
                <CollapseDate in={details === 'incarceration'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'returned'}
                             onChange={(e, checked) => {setDetails('returned')}} value="yes" label="I recently returned to the United States after living permanently outside of the U.S. I returned to the U.S. on:" />
                <CollapseDate in={details === 'returned'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'lawful'}
                             onChange={(e, checked) => {setDetails('lawful')}} value="yes" label="I recently obtained lawful presence status in the United States. I got this status on:" />
                <CollapseDate in={details === 'lawful'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'change'}
                             onChange={(e, checked) => {setDetails('change')}} value="yes" label="I recently had a change in my Medicaid (newly got Medicaid, had a change in level of Medicaid assistance, or lost Medicaid) on (insert date)." />
                <CollapseDate in={details === 'change'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'extra'}
                             onChange={(e, checked) => {setDetails('extra')}} value="yes" label="I recently had a change in my Extra Help paying for Medicare prescription drug coverage (newly got Extra Help, had a change in the level of Extra Help, or lost Extra Help) on:" />
                <CollapseDate in={details === 'extra'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'both'}
                             onChange={(e, checked) => {setDetails('both')}} value="yes" label="I have both Medicare and Medicaid (or my state helps pay for my Medicare premiums) or I get Extra Help paying for my Medicare prescription drug coverage, but I haven’t had a change." />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'LongTerm'}
                             onChange={(e, checked) => {setDetails('LongTerm')}} value="yes" label="I am moving into, live in, or recently moved out of a Long-Term Care Facility (for example, a nursing home or long term care facility). I moved/will move into/out of the facility on: " />
                <CollapseDate in={details === 'LongTerm'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'left'}
                             onChange={(e, checked) => {setDetails('left')}} value="yes" label="I recently left a PACE program on:" />
                <CollapseDate in={details === 'LongTerm'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'leaving'}
                             onChange={(e, checked) => {setDetails('leaving')}} value="yes" label="I am leaving employer or union coverage on:" />
                <CollapseDate in={details === 'leaving'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'assistance'}
                             onChange={(e, checked) => {setDetails('assistance')}} value="yes" label="I belong to a pharmacy assistance program provided by my state." />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'ending'}
                             onChange={(e, checked) => {setDetails('ending')}} value="yes" label="My plan is ending its contract with Medicare, or Medicare is ending its contract with my plan." />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'enrolled'}
                             onChange={(e, checked) => {setDetails('enrolled')}} value="yes" label="I was enrolled in a plan by Medicare (or my state) and I want to choose a different plan. My enrollment in that plan started on:" />
                <CollapseDate in={details === 'enrolled'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'SNP'}
                             onChange={(e, checked) => {setDetails('SNP')}} value="yes" label="I was enrolled in a Special Needs Plan (SNP) but I have lost the special needs qualification required to be in that plan. I was disenrolled from the SNP on:" />
                <CollapseDate in={details === 'SNP'} value={null} onChange={() => {}} />

                <AscendRadio variant={'standart'} labelClassName={'fs-16'} checked={details === 'affected'}
                             onChange={(e, checked) => {setDetails('affected')}} value="yes" label="I was affected by an emergency of major disaster (as declared by the Federal Emergency Management Agency (FEMA) or by a Federal, state or local government entity. One of the other statements here applied to me, but I was unable to make my enrollment request because of the disaster" />
              </RadioGroup>
            </Collapse>
            <Typography color={'textPrimary'} className={'fs-16'}>If none of these statements apply to you or you’re not sure, please reach out to a CoverRight agent at +1 (888) 123-4567 (TTY: 711) to see if you’re currently eligible to enroll in this plan. Agents are available on Mon-Sun 8AM-11PM (ET).</Typography>
          </Grid>
        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Save and continue</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm1;

const CollapseDate = (props: {in: boolean, value: Date | null, onChange: (value: Date | null) => void}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  return <Collapse in={props.in}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        disableFuture
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        InputAdornmentProps={{
          classes: {
            root: commonClasses.hidden
          }
        }}
        InputProps={{
          classes: {
            root: classes.input
          }
        }}
        value={props.value}
        placeholder={'MM / DD / YYYY'}
        invalidDateMessage={'Please enter a valid date'}
        onChange={date => {
          props.onChange(date)
        }}
        TextFieldComponent={FormTextInput as any}
      />
    </MuiPickersUtilsProvider>
  </Collapse>
}
