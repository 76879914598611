import React from "react";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../Private/hooks/useUserProfile";
import {AdminContext} from "./AdminContext";
import {listenMedigapQuoteChange, setMedigapQuoteId, setQuoteId} from "../../shared/QuoteId";
import {MedigapQuoteOutput} from "../../medigap-types";
import useMedigapQuote from "../../Quote/Medigap/hooks/useMedigapQuote";
import {PreloaderContext} from "./PreloaderContext";
import {keycloak, KeycloakRoles} from "../../shared/keycloak";

export const MedigapQuoteContext = React.createContext<MedigapQuoteOutput | undefined>({} as any);

export function MedigapQuoteProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const adminContext = React.useContext(AdminContext);
  const {setPreloader} = React.useContext(PreloaderContext)

  React.useEffect(() => {
    if (adminContext?.clientView?.medigapQuote) {
      setMedigapQuoteId(adminContext?.clientView?.medigapQuote.id)
    }
  }, [adminContext?.clientView?.medigapQuote]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.medigapQuote?.id || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.medigapQuote]);

  const [quote, setQuote] = React.useState<MedigapQuoteOutput>();

  const {authenticated} = React.useContext(KeycloakContext);
  const [getProfile, {data: profileData}] = useUserProfileLazy();

  const [getRequest, data] = useMedigapQuote({
    onCompleted: data => {
      setQuote(data.medigapQuote);
      setPreloader('quote', false);
    },
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    listenMedigapQuoteChange(id => getRequest({variables: {id}}))
  }, []);

  React.useEffect(() => {
    if (adminContext) {
      getRequest({variables: {id: quoteId}});
      setPreloader('quote', true);
    } else {
      const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN);
      if ((authenticated && !isAdmin) || props.private) {
        getProfile()
        setPreloader('quote', true);
      }
      if (quoteId) {
        getRequest({variables: {id: quoteId}});
        setPreloader('quote', true);
      }
    }
  }, [quoteId, authenticated, adminContext]);

  React.useEffect(() => {
    if (profileData?.userProfile?.quoteId) {
      setMedigapQuoteId(profileData?.userProfile?.quoteId);
      getRequest({variables: {id: profileData.userProfile.quoteId}});
    }
  }, [profileData])

  return <MedigapQuoteContext.Provider value={quote}>
    {props.children}
  </MedigapQuoteContext.Provider>

}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}
