import React, { useState } from 'react'
import Tour, {Arrow, Controls, CustomHelperProps, Dot, Navigation} from "reactour"
import {Backdrop, Box, createStyles, MenuItem, Modal, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../../shared/AppTheme";
import AscendButton from "../../../../shared/components/AscendButton";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const steps = [
  {
    selector: '.plan-top-filters',
    content: () => <Typography color={'textPrimary'} className={'fs-16'}>
      <strong>1/5:</strong> Update your <strong>maximum annual out-of-pocket</strong> and <strong>estimated average total cost per month</strong> filters here. These are important for refining your plan options.
    </Typography>
  },
  {
    selector: '.healthcare-preferences',
    content: () => <Typography color={'textPrimary'} className={'fs-16'}>
      <strong>2/5:</strong> You have the option to add / edit any <strong>doctors</strong> and any prescription <strong>drugs</strong> you would like to see covered by the plan, and make sure only plans that cover them are shown.
    </Typography>
  },
  {
    selector: '.drug-calculation',
    content: () => <Typography color={'textPrimary'} className={'fs-16'}>
      <strong>3/5:</strong> We calculate your <strong>estimated average monthly costs</strong> based on your <strong>expected doctor visits</strong> and <strong>prescriptions</strong>, including the <strong>estimated costs of your prescription drugs and PCP and specialist visit co-pays or co-insurance</strong>. You can also see your <strong>estimated monthly drug cost breakdown</strong> as you add or remove drugs.
    </Typography>
  },
  {
    selector: '.monthly-cost-calculator',
    content: () => <Typography color={'textPrimary'} className={'fs-16'}>
      <strong>4/5:</strong> Update your expected number of annual <strong>primary care</strong> and <strong>specialist visits</strong> to estimate the copay costs under each plan.
    </Typography>
  },
  {
    selector: '.plan-filters',
    content: () => <Typography color={'textPrimary'} className={'fs-16'}>
      <strong>5/5:</strong> You can refine your results based on the plan's <strong>CMS star rating, Plan type, Drug Coverage, Part B Giveback, Company</strong> any <strong>Extra Benefits</strong> that are important to you.
    </Typography>
  },
];


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingTop: 145,
      paddingLeft: 320,
    },
    paper: {
      position: 'relative',
      backgroundColor: '#f7fcfa',
      borderRadius: 4,
      border: '1px solid #5ECB9E',
      boxShadow: 'none',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxHeight: '90vh',
      overflowY: 'auto',
      width: 656,
      height: 194,
      padding: '15px 22px',
    },
    mask: {
      color: '#1C434F',
      opacity: 0.5,
    }
  })
);

interface PlanTourProps {
  open: boolean,
  onClose: () => void
}

const PlanTour = (props: PlanTourProps) => {
  const classes = useStyles();
  const [openEntry, setOpenEntry] = React.useState(false);
  const [openTour, setOpenTour] = React.useState(false);

  React.useEffect(() => {
    if (props.open) {
      setOpenEntry(true);
      setOpenTour(false);
    } else {
      setOpenEntry(false);
      setOpenTour(false);
    }
  }, [props.open])

  return <>
    <Modal
      className={classes.modal}
      open={openEntry}
      onClose={props.onClose}
      BackdropComponent={Backdrop}
      disableBackdropClick

    >
      <div className={classes.paper}>
        <div>
          <Typography color={'textPrimary'} className={'bold fs-24'}>CoverRight</Typography>
          <Typography className={'bold fs-18 mt-10'} style={{color: colors.custom.green.variant2}}>Welcome to our Medicare Advantage marketplace dashboard!</Typography>
          <Typography className={'fs-16 mt-5'} color={'textPrimary'}>Let’s walk through how you can use our platform to find a plan that’s right for you.</Typography>
        </div>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography color={'textPrimary'} className={'bold fs-18 pointer'} onClick={props.onClose}>Skip tour</Typography>
          <StyledButton variant={'contained'} onClick={() => {
            setOpenEntry(false);
            setTimeout(() => setOpenTour(true))
          }}>Start tour</StyledButton>
        </Box>
      </div>
    </Modal>
    <Tour
      onAfterOpen={(target: any) => disableBodyScroll(target)}
      onBeforeClose={(target: any) => enableBodyScroll(target)}
      steps={steps}
      isOpen={openTour}
      onRequestClose={props.onClose}
      CustomHelper={Helper}
      maskClassName={classes.mask}
      closeWithMask={false}
      disableInteraction
    />
  </>
}

export default PlanTour;


const useHelperStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: '#f7fcfa',
      borderRadius: 4,
      border: '1px solid #5ECB9E',
      boxShadow: 'none',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 332,
      overflowY: 'auto',
      padding: '10px 13px',
    },
    lightIcon: {
      height: 17,
      width: 16,
      marginRight: 7,
      maxWidth: 'inherit',
      marginTop: 2,
    },
    nextButton: {
      cursor: 'pointer',
      fontSize: '14px',
      padding: 3,
      '&:hover': {
        backgroundColor: '#D9F3E8',
        borderRadius: 2,
        color: '#1E4952'
      }
    }
  })
);
function Helper({ current, content, totalSteps, gotoStep, close }: CustomHelperProps) {
  const classes = useHelperStyles();
  return <div className={classes.paper}>
    <Box display={'flex'}>
      <img className={classes.lightIcon} src={'/img/light.png'} />
      {content}
    </Box>
    <Box display={'flex'} className={'mt-16'} justifyContent={'space-between'}>
      <Typography color={'textPrimary'} className={`${classes.nextButton} tour-exit-button`} onClick={close}>Exit</Typography>
      <Box display={'flex'}>
        {current > 0 && <Typography color={'textPrimary'} className={`mr-10 ${classes.nextButton}`} onClick={() => gotoStep(current - 1)}>&lt; Previous</Typography>}
        {current < totalSteps - 1 && <Typography color={'textPrimary'} className={classes.nextButton} onClick={() => gotoStep(current + 1)}>Next &gt;</Typography>}
        {current === totalSteps - 1 && <Typography color={'textPrimary'} className={classes.nextButton} onClick={close}>Finish</Typography>}
      </Box>
    </Box>
  </div>
}

const StyledButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 16px 6px',
    width: 130,
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
