import React from "react";
import {
  Box,
  createStyles, IconButton,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme, Typography, useMediaQuery, useTheme,
  withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps} from "react-router";
import paths from "../../config/router-paths";
import {withTitle} from "../../../shared/components/withTitle";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import {colors} from "../../../shared/AppTheme";
import {Check} from "@material-ui/icons";
import clsx from 'clsx';
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import {QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import AscendModal from "../../../shared/components/AscendModal";
import usePlanSummary from "../hooks/usePlanSummary";
import * as _ from "lodash";
import {maxCostRanges} from "./Step6";
import {getQuoteId} from "../../../shared/QuoteId";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 40,
      maxWidth: 320,
    },
    control: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    checkbox: {
      padding: 4
    },
    icon: {
      backgroundColor: 'transparent',
    },
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 500,
      '& span': {
        color: colors.custom.green.variant1,
      }
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 400,
    },
  })
);
const formName = 'STEP_7';

function Step7(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [showTotalPlans, setShowTotalPlans] = React.useState(false);
  const [timeout, setTimeoutValue] = React.useState();

  const [getSummary, {data, loading}] = usePlanSummary();

  const totalPlans = React.useMemo(() => {
      return data?.plansSummary ? data.plansSummary.totalCount : 0
  }, [data]);

  React.useEffect(() => {
      if (state.STEP_2?.zip) {
        getSummary({variables: {
          zip: state.STEP_2?.zip,
          countyName: state.STEP_2?.countyName,
          quoteId: getQuoteId(),
          extraBenefits: state?.STEP_6?.benefits?.value
        }})
      }
  }, [state]);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    clearTimeout(timeout)
    setTimeoutValue(setTimeout(() => {
      clearTimeout(timeout)
      setShowTotalPlans(true);
      setTimeoutValue(setTimeout(() => {
        const route = state?.QUIZ_3?.medicarePlanType?.value === 'MA' ? paths.MAquestionnaire : paths.MGquestionnaire;
        props.history.push(route + '/' + questionsRoutes.step9)
      }, 3000))
    }, 3000))

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, []);


  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <AscendModal
      width={584}
      open={showTotalPlans}
      disableBackdropClick
      paperPadding={'32px 0'}
    >
      <>
        {(!loading && totalPlans) && <>
          {state?.QUIZ_3?.medicarePlanType?.value === 'MA' && <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Congratulations! We’ve found <br /><span>{totalPlans}</span> Medicare Advantage plans for you.</Typography>}
          {state?.QUIZ_3?.medicarePlanType?.value === 'MG' && <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Congratulations! We’ve found over <br /><span>10</span> Medicare plans for you.</Typography>}
          <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
            <img src={'/img/congratulations.svg'} />
          </Box>
        </>}
        <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>{state.STEP_2?.countyName + ' County, ' + state.STEP_2?.state}</Typography>
      </>
    </AscendModal>
    <Box mt={'50px'} height={'100px'} position={'relative'}>
      <div className="load">
        <hr/>
        <hr/>
        <hr/>
        <hr/>
      </div>
    </Box>
    <div className={'h-40'}/>
    <LoadingStepper />
    <div className={'h-50'}/>
    <Typography color={'textSecondary'} className={'fs-18 bold'}>That match your preferences:</Typography>
    <div className={'h-16'}/>
    <div>
      {state?.QUIZ_3?.medicarePlanType?.value === 'MG' && <AscendCheckbox readOnly
                                                                          controlClassName={classes.control}
                                                                          className={classes.checkbox}
                                                                          iconClassName={classes.icon}
                                                                          variant={'standart'}
                                                                          label={`Medicare Supplement plans in ${state.STEP_2?.countyName} Сounty`}
                                                                          checked={true} />}

      {maxCostRanges.filter(i => state.STEP_6?.monthlyCost?.value?.includes(i.key)).map(item => <AscendCheckbox readOnly
                                          key={item.key}
                                          controlClassName={classes.control}
                                          className={classes.checkbox}
                                          iconClassName={classes.icon}
                                          variant={'standart'}
                                          label={item.name + ' monthly premium'} checked={true} />)}
      {state.STEP_6?.benefits?.value.map((item: string) => <AscendCheckbox readOnly
                                                                 key={item}
                                                                 controlClassName={classes.control}
                                                                 className={classes.checkbox}
                                                                 iconClassName={classes.icon}
                                                                 variant={'standart'}
                                                                 label={(_.startCase(item.toLowerCase())  + ' benefits').replace('Over The Counter benefits', 'Over-the-counter Allowance')} checked={true} />)}

    </div>
  </Box>
}

export default withTitle(
  Step7,
  'We’re looking for the best Medicare\n options for you now',
  `Searching for your plans now...`,
  undefined,
  undefined,
  true
)

const LoadingStepLabel = withStyles({
  label: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.text.secondary,
    '&.MuiStepLabel-completed': {
      fontWeight: 700,
      fontSize: 18,
      color: `${colors.text.secondary}`
    },
    '&.MuiStepLabel-active': {
      fontSize: 18,
      fontWeight: 700,
      color: `${colors.text.secondary}`,
    },
  },
})(StepLabel);


const AscendConnector = withStyles({
  vertical: {
    padding: 0,
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: colors.custom.green.variant1,
    },
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: colors.custom.green.variant1,
    },
  },
  lineHorizontal: {
    marginTop: 9,
    marginLeft: -5,
    border: '3px solid #CCD8DC',
  }
})(StepConnector);

const useAscendStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: 'white',
    width: 48,
    height: 48,
    fontSize: 24,
    fontWeight: 700,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    backgroundColor: '#CCD8DC',
  },
  active: {
    backgroundColor: colors.custom.green.variant1,
    color: 'white',
  },
  completed: {
    backgroundColor: `${colors.custom.green.variant1}!important`,
    color: 'white',
    fontSize: 24,
    fontWeight: 700,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function StepIcon(props: StepIconProps) {
  const classes = useAscendStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Check className={clsx({[classes.completed]: completed})} />
    </div>
  );
}

const useStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      backgroundColor: 'transparent',
      minWidth: 567,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    step: {
      // cursor: 'pointer'
      pointerEvents: 'none'
    },
    stepper: {
      marginTop: 30,
    },
    green: {
      '& .MuiStepLabel-label': {
        color: `${colors.custom.green.variant1}!important`,
      },
    }
  }),
);

function LoadingStepper() {
  const classes = useStepperStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const carriersText = React.useMemo(() => {
      return phone ? 'Searching all carriers in your area' : <>Searching all<br />carriers in<br />your area</>
  }, [phone]);

  return (<Box className={classes.stepper}>
      <Stepper alternativeLabel classes={{root: classes.root}} connector={<AscendConnector />}>
        <Step completed={true}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                        completed={true}/>}>Quote<br />requested</LoadingStepLabel>
        </Step>
        <Step completed={true}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                        completed={true}/>}>{carriersText}</LoadingStepLabel>
        </Step>
        <Step completed={false}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                        completed={false}/>}>Compare<br />quotes</LoadingStepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}
