import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: (props: any) => props.padding ? props.padding : 40,
      [theme.breakpoints.down('sm')]: {
        padding: (props: any) => props.padding ? props.padding : '40px 20px',
      },
      borderRadius: 10,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export default function AscendCard(props: {className?: string, padding?: string} & PropsWithChildren<any>) {
  const classes = useStyles(props);
  return <div className={clsx(classes.root, props.className)}>
    {props.children}
    </div>
}
