import _ from 'lodash';
import {Rating} from "../../types";

export function handleCollectionChange<T>(element: T, collection: T[]) {

  let out: T[];

  if (!!collection) {

    if (collection.includes(element)) {

      out = _.without(collection, element);

    } else {

      out = [...collection, element]
    }
  } else {

    out = [element];
  }

  return out
}

export function handleRatingChange(element: Rating, collection: Rating[]) {

  let out: Rating[] = [];

  function addHalfStarRating(r: Rating) {
    switch (r) {
      case Rating.R1:
        return [Rating.R1, Rating.R1_5];
      case Rating.R2:
        return [Rating.R2, Rating.R2_5];
      case Rating.R3:
        return [Rating.R3, Rating.R3_5];

      case Rating.R4:
        return [Rating.R4, Rating.R4_5];
      default:
      case Rating.R5:
        return [Rating.R5];
    }
  }

  if (!!collection) {

    if (collection.includes(element)) {

      out = [...collection]
      addHalfStarRating(element).forEach((r) => {

        out = _.without(out, r);
      })

    } else {

      out = _.concat([...collection], addHalfStarRating(element))
    }
  } else {
    out = addHalfStarRating(element);
  }

  return out
}
