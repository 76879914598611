import React, {FunctionComponent} from "react";
import {
  Box,
  createStyles,
  Step,
  StepConnector,
  StepIconProps, StepLabel, Stepper,
  Theme,
  Typography,
  withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import {colors} from "../AppTheme";
import useStepperState, {IStepperState} from "../../Questionnaire/hooks/useStepperState";
import {RouteComponentProps} from "react-router";
import paths from "../../Questionnaire/config/router-paths";
import {navHistory} from "../../Questionnaire/QuestionnaireContextProvider";
import Terms from "./Terms";


const AscendStepLabel = withStyles({
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: '#1c434f66',
    '&.MuiStepLabel-completed': {
      fontWeight: 700,
      fontSize: 16,
      color: `${colors.custom.green.variant1}`
    },
    '&.MuiStepLabel-active': {
      fontSize: 16,
      fontWeight: 700,
      color: `${colors.text.primary}`,
    },
  },
})(StepLabel);

const AscendSubStepLabel = withStyles({
  root: {
    marginTop: -5,
    marginBottom: -5,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: '#1c434f66',
    '&.MuiStepLabel-completed': {
      fontSize: 14,
      fontWeight: 500,
      color: `${colors.custom.green.variant1}!important`
    },
    '&.MuiStepLabel-active': {
      fontSize: 14,
      fontWeight: 500,
      color: `${colors.text.primary}!important`,
    },
  },
})(StepLabel);

const AscendConnector = withStyles({
  vertical: {
    padding: 0,
    marginLeft: 11,
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: colors.custom.green.variant1,
    },
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: colors.custom.green.variant1,
    },
  },
  lineVertical: {
    minHeight: 24,
    borderColor: 'rgba(28, 67, 79, 0.12)',
  }
})(StepConnector);

const useAscendStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: 'rgba(28,67,79,0.4)',
    width: 23,
    height: 23,
    fontSize: 10,
    fontWeight: 600,
    display: 'flex',
    border: '1px solid rgba(28, 67, 79, 0.1)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  active: {
    backgroundColor: colors.custom.green.variant1,
    color: 'white',
    fontSize: '10px!important',
    fontWeight: 600,
  },
  completed: {
    backgroundColor: `${colors.custom.green.variant1}!important`,
    color: 'white',
    fontSize: 15,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function AscendStepIcon(props: StepIconProps) {
  const classes = useAscendStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed && !active ? <Check className={classes.completed} /> : <Box mt={'3px'}>{props.icon}</Box>}
    </div>
  );
}


const useAscendSubStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: 'rgba(28,67,79,0.4)',
    width: 11,
    height: 11,
    fontSize: 10,
    fontWeight: 500,
    display: 'flex',
    border: '1px solid rgba(28, 67, 79, 0.1)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 13,
    marginLeft: 6,
  },
  active: {
    border: `1px solid ${colors.custom.green.variant1}`,
  },
  completed: {
    backgroundColor: `${colors.custom.green.variant1}!important`,
    color: 'white',
    fontSize: 7,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 5,
    height: 5,
    backgroundColor: '#5ECB9E',
    borderRadius: '50%',
  }
});

function AscendSubStepIcon(props: StepIconProps) {
  const classes = useAscendSubStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {!!active && <div className={classes.dot} />}
      {!!completed && <Check className={classes.completed} />}
    </div>
  );
}


export interface IStep {
  label: string,
  questions?: IQuestion[],
  firstStep: string,
  completed?: boolean,
  children?: IStep[],
  active?: boolean,
  isChild?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    step: {
      // cursor: 'pointer'
      pointerEvents: 'none'
    },
    stepper: {
      marginTop: 30,
    },
    green: {
      '& .MuiStepLabel-label': {
        color: `${colors.custom.green.variant1}!important`,
      },
    }
  }),
);

export default function AscendStepper(props: RouteComponentProps<{routeName: string}> & {state: IStepperState}) {
  const classes = useStyles();
  const {state} = props;

  const flattenSteps = React.useMemo(() => {
    return state.steps.reduce((previousValue, currentValue) => {
      const result = [...previousValue, {...currentValue, isChild: false}];
      if (currentValue.children?.length) {
        result.push(...currentValue.children.map(ch => ({...ch, isChild: true})));
      }
      return result;
    }, [] as any[]);
  }, [state])

  const steps = React.useMemo(() => {
    const flatten = state.steps.reduce((previousValue, currentValue) => {
      const result = [...previousValue, {...currentValue, isChild: false}];
      if (currentValue.children?.length) {
        result.push(...currentValue.children.map(ch => ({...ch, isChild: true})));
      }
      return result;
    }, [] as any[]);


    const canNavigate = (index: number): boolean => {
      return flatten.slice(0, index).map((s: IStep) => !!s.completed || (s.children && s.children.some(c => !!c.completed)))
        .every((value?: boolean) => value === true)
    }

    const onClick = (link: string) => {
      /*const currentIndex = navHistory.findFirstIndexInHistory(props.match.params.routeName || '');
      const targetIndex = navHistory.findFirstIndexInHistory(link);
      if (!!targetIndex) {
        props.history.go(currentIndex - targetIndex);
      } else {
        navHistory.save(link);
        props.history.push(paths.questionnaire + '/' + link);
      }*/
    }

    const isActive = (step: IStep): boolean => {
      let result: boolean = false;
      if (step.questions) {
        result = step.questions.some(q => q.link === (props.match.params.routeName || ''))
      }
      if (!result && step.children) {
        result = step.children.some(isActive);
      }
      if (!result && step.firstStep) {
        result = step.firstStep === props.match.params.routeName;
      }
      return result
    }

    return flatten.map((step: IStep, index: number) => {
      if (step.isChild) {
        return <Step disabled={!canNavigate(index)}
                     completed={step.completed}
                     active={isActive(step)}
                     key={step.label}
                     onClick={() => canNavigate(index) && onClick(step.firstStep)} className={classes.step}>
          <AscendSubStepLabel StepIconComponent={() => <AscendSubStepIcon icon={index}
                                                                          active={isActive(step)}
                                                                          completed={!!step.completed}/>}>{step.label}</AscendSubStepLabel>
        </Step>
      } else {
        return  <Step disabled={!canNavigate(index)}
                      completed={step.completed}
                      key={step.label}
                      active={isActive(step)}
                      onClick={() => canNavigate(index) && onClick(step.firstStep)} className={classes.step}>
          <AscendStepLabel className={clsx({[classes.green]: step.children && (step.children.some(isActive) || step.children.some(s => s.completed))})}
                           StepIconComponent={() => <AscendStepIcon icon={1 + flatten.filter(s => !s.isChild)
                                                                      .findIndex((value: IStep) => value.label === step.label)
                                                                    }
                                                                    active={isActive(step)}
                                                                    completed={step.completed}/>}>{step.label}</AscendStepLabel>
        </Step>
      }
    })
  }, [flattenSteps, props.match.params.routeName])

  return (<Box className={classes.stepper}>
      <Stepper classes={{root: classes.root}} orientation="vertical" connector={<AscendConnector />}>
        {steps}
      </Stepper>
    </Box>
  )
}

export interface IQuestion {
  link: string,
  component: FunctionComponent,
}
