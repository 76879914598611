import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, createStyles, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";
import AscendTitle from "../../shared/components/AscendTitle";
import {colors} from "../../shared/AppTheme";
import {useLazyQuery} from "@apollo/react-hooks";
import {FilterItem, QueryPlanTypesArgs} from "../../types";
import {gql} from "apollo-boost";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import * as _ from 'lodash';
import {makeStyles} from "@material-ui/core/styles";
import {storeFilters} from "../../shared/utils";

const formName = 'STEP_13a';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: '8px 20px',
      display: 'flex',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    medium: {
      fontWeight: 500
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    },
  }),
);
function Step13b(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();
  const [getFilters, {data}] = useLazyQuery<IPlanFilterResponse, QueryPlanTypesArgs>(gql(planFilterQuery))
  const [outOfPocketCost, setOutOfPocketCost] = React.useState<string[]>(state?.STEP_13a?.outOfPocketCost?.value || []);
  const [monthlyCost, setMonthlyCost] = React.useState<string[]>(state?.STEP_13a?.monthlyCost?.value || []);


  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    getFilters({variables: {filter: {zip: state.personalDetails?.zip as string, countyName: state.personalDetails?.countyName as string}}})
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName, outOfPocketCost: {
          questionText: 'Maximum annual out-of-pocket costs (in-network):',
          answerText: outOfPocketCost.map(_.lowerCase).map(_.startCase).join(', '),
          value: outOfPocketCost
        }, monthlyCost: {
          questionText: 'Monthly cost (including premium, drug and doctor costs):',
          answerText: monthlyCost.map(_.lowerCase).map(_.startCase).join(', '),
          value: monthlyCost
        }}
    })

    storeFilters({
      maxOutOfPocketRangeNames: outOfPocketCost,
      maxCostRangeNames: monthlyCost,
    }, props.match.params.zip);
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step13b1)
  };

  return <>
    <div className={'h-32'} />
    <AscendTitle title={'Maximum annual out-of-pocket costs (in-network):'} />
    <div className={'h-16'} />
    <div className={classes.card}>
      {data?.maxOutOfPocketRanges.filter(i => i.count > 0).map(item => <AscendCheckbox variant={'standart'}
                                                              labelClassName={classes.checkboxLabel}
                                                              controlClassName={classes.checkbox}
                                                           key={item.key}
                                                           label={item.name}
                                                           checked={!!outOfPocketCost && outOfPocketCost.includes(item.key)}
                                                           onChange={() => {
                                                             if (outOfPocketCost.includes(item.key)) {
                                                               setOutOfPocketCost(_.without(outOfPocketCost, item.key))
                                                             } else {
                                                               setOutOfPocketCost([...outOfPocketCost, item.key])
                                                             }
                                                           }}

      />)}
    </div>
    <div className={'h-20'} />
    <AscendTitle title={'Monthly cost (including premium, drug and doctor costs):'} />
    <div className={'h-16'} />
    <div className={classes.card}>
      {data?.maxCostRanges.map(item => <AscendCheckbox variant={'standart'}
                                                                                       labelClassName={classes.checkboxLabel}
                                                                                       controlClassName={classes.checkbox}
                                                                                       key={item.key}
                                                                                       label={item.name}
                                                                                       checked={!!monthlyCost && monthlyCost.includes(item.key)}
                                                                                       onChange={() => {
                                                                                         if (monthlyCost.includes(item.key)) {
                                                                                           setMonthlyCost(_.without(monthlyCost, item.key))
                                                                                         } else {
                                                                                           setMonthlyCost([...monthlyCost, item.key])
                                                                                         }
                                                                                       }}

      />)}
    </div>

    <Box display={'flex'} mt={'50px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'} disabled={typeof outOfPocketCost === 'undefined' || typeof monthlyCost === 'undefined'}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'30px'} justifyContent={'center'}>
      <Typography variant={'h4'} style={{
        color: colors.custom.green.variant1,
        cursor: 'pointer',
        fontWeight: 500,
        textDecoration: 'underline',
      }} onClick={() => {
        props.history.push(paths.questionnaire  + '/' + questionsRoutes.step13b1)
      }}>Click here to skip this step</Typography>
    </Box>
  </>;
}

export default withTitle(
  Step13b,
  'Do you have a budget in mind?',
  'We’ll prioritize your plan options according to your maximum out of pocket cost preferences and total monthly cost.'
);


export interface IPlanFilterResponse {
  maxOutOfPocketRanges: FilterItem[],
  maxCostRanges: FilterItem[],
}

const planFilterQuery = `
query($filter: PlansFilterInput!) {
    maxOutOfPocketRanges(filter: $filter) {
      key
      name
      count
    }
    maxCostRanges(filter: $filter) {
      key
      name
      count
    }
}
`;
