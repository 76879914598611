import React from "react";
import {withStyles} from "@material-ui/core";
import AscendButton from "./AscendButton";
import { useHistory } from "react-router-dom";
import paths from "../../Maketplace/MedicareAdvantage/config/router-paths";
import {keycloak} from "../keycloak";

const LogoutButton = () => {

  return <StyledLoginButton variant={'contained'} onClick={() => {
    keycloak.logout({redirectUri: process.env.REACT_APP_RESULTS_URL})
  }}>Sign out</StyledLoginButton>
}

export default LogoutButton;

const StyledLoginButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 16px 6px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
