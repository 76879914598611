import React from "react";

export interface IMedigapPlanData {
  [name: string]: IMedigapPlanDetails
}

export interface IMedigapPlanDetails {
  name: string,
  note?: string,
  popularity: string,
  premium?: string,
  highDeductibleOption?: boolean,
  partADeductible: string,
  partACoinsurance: string,
  hospiceCareCopay: string | boolean,
  partBDeductible: string | boolean,
  partBCoinsurance: string | JSX.Element,
  partBServices: string | JSX.Element,
  skilledNursingFacility: string | boolean,
  partBExcessCharges: boolean,
  foreignTravelEmergency: string | boolean,
  blood: string | boolean,
  outOfPocketLimits: string,
  inpatientHospitalCoverage: string | JSX.Element,
  skilledNursingFacilities: string | JSX.Element,
  bloodTransfusionDuring: string | JSX.Element,
  coveredPartBServices: string,
  preventiveCare: string,
  bloodTransfusionOutside: string | JSX.Element,
  testsForDiagnosticServices: string,
  homeHealthCare: string,
  durableMedicalEquipment: string,
  deductibles: {
    partA: string,
    partB: string,
  },
  features: {
    partADeductible: boolean,
    partBDeductible: boolean,
    partBExcessCharges: boolean,
    skilledNursingFacility: boolean,
    foreignTravelEmergency: boolean,
  }
}

const PlanData: IMedigapPlanData = {
  'C': {
    name: 'C',
    note: 'Not available if you\'re\neligible for Medicare\nafter Jan 1st, 2020',
    popularity: "5%",
    highDeductibleOption: true,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: true,
    partBCoinsurance: "$0",
    partBServices: <strong>$0</strong>,
    skilledNursingFacility: true,
    partBExcessCharges: false,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0',
    deductibles: {
      partA: '$0',
      partB: '$0',
    },
    features: {
      partADeductible: true,
      partBDeductible: true,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'D': {
    name: 'D',
    popularity: "1%",
    highDeductibleOption: false,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after deductible</>,
    skilledNursingFacility: true,
    partBExcessCharges: false,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'F': {
    name: 'F',
    note: 'Not available if you\'re\neligible for Medicare\nafter Jan 1st, 2020',
    popularity: "58%",
    highDeductibleOption: true,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: true,
    partBCoinsurance: "$0",
    partBServices: <strong>$0</strong>,
    skilledNursingFacility: true,
    partBExcessCharges: true,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0',
    deductibles: {
      partA: '$0',
      partB: '$0',
    },
    features: {
      partADeductible: true,
      partBDeductible: true,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'G': {
    name: 'G',
    popularity: "17%",
    highDeductibleOption: true,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after deductible</>,
    skilledNursingFacility: true,
    partBExcessCharges: true,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'A': {
    name: 'A',
    popularity: "1%",
    highDeductibleOption: false,
    partADeductible: "$1,484",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after deductible</>,
    skilledNursingFacility: false,
    partBExcessCharges: false,
    foreignTravelEmergency: false,
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>Up to $185.50 a day for days 21-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$1,484',
      partB: '$203',
    },
    features: {
      partADeductible: false,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false,
    }
  },
  'B': {
    name: 'B',
    popularity: "2%",
    highDeductibleOption: false,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after deductible</>,
    skilledNursingFacility: false,
    partBExcessCharges: false,
    foreignTravelEmergency: false,
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>Up to $185.50 a day for days 21-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false,
    }
  },
  'K': {
    name: 'K',
    popularity: "1%",
    highDeductibleOption: false,
    partADeductible: "$742",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: "Your plan pays\n50% of costs",
    partBDeductible: "$203",
    partBCoinsurance: "Your plan pays\n50% of costs",
    partBServices: <><strong>Generally 10%</strong> after Part B deductible</>,
    skilledNursingFacility: "Your plan pays\n50% of costs",
    partBExcessCharges: false,
    foreignTravelEmergency: false,
    blood: "Your plan pays\n50% of costs",
    outOfPocketLimits: "$6,220",
    inpatientHospitalCoverage: <ul>
      <li><span>$742 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>Up to $92.75 a day for days 21-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      After you pay the Plan deductible, you pay:
      <li><span>First 3 pints of blood: 50%</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: 50%</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then generally 10%</span></li>
    </ul>,
    testsForDiagnosticServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: '$742',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false,
    }
  },
  'L': {
    name: 'L',
    popularity: "< 0.5%",
    highDeductibleOption: false,
    partADeductible: "$371",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: "Your plan pays\n75% of costs",
    partBDeductible: "$203",
    partBCoinsurance: "Your plan pays\n75% of costs",
    partBServices: <><strong>Generally 5%</strong> after Part B deductible</>,
    skilledNursingFacility: "Your plan pays\n75% of costs",
    partBExcessCharges: false,
    foreignTravelEmergency: false,
    blood: "Your plan pays\n75% of costs",
    outOfPocketLimits: "$3,110",
    inpatientHospitalCoverage: <ul>
      <li><span>$371 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>Up to $46.38 a day for days 21-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: 25%</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: 25%</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then generally 5%</span></li>
    </ul>,
    testsForDiagnosticServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: '$371',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false,
    }
  },
  'M': {
    name: 'M',
    popularity: "< 0.5%",
    highDeductibleOption: false,
    partADeductible: "$371",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: "0%",
    partBServices: <><strong>$0</strong> after deductible</>,
    skilledNursingFacility: true,
    partBExcessCharges: false,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>50% after Part A deductible for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$371',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'N': {
    name: 'N',
    popularity: "10%",
    highDeductibleOption: false,
    partADeductible: "$0",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: "$203",
    partBCoinsurance: <div className={'text-align-centered fs-14 text-primary'}>
      Your plan <br /> typically pays 100% <br /><span className="fs-12">Some services<br />have a $20 or<br />$50 copay</span>
    </div>,
    partBServices: <><strong>$0</strong> after deductible (some copays for doctor visits)</>,
    skilledNursingFacility: true,
    partBExcessCharges: false,
    foreignTravelEmergency: "Your plan pays\n80% of costs",
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: 'Up to $20 for doctor visits and up to $50 for emergency care visits. You don\'t have to pay the $50 emergency care copay if you are admitted within the first 24 hrs. $0 otherwise',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $203 (Part B deductible), then $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: '$203',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'HDF': {
    name: 'HDF',
    popularity: "",
    partADeductible: "$2,370 for both Part A and B",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: '$2,370 for both Part A and B',
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after Part B deductible</>,
    skilledNursingFacility: true,
    partBExcessCharges: true,
    foreignTravelEmergency: 'Your plan pays\n80% of costs',
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 after Plan deductible for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 after Plan deductible for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      After you pay the Plan deductible, you pay:
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Plan deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <ul>
      After you pay the Plan deductible, you pay:
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $0</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Plan deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Plan deductible',
    deductibles: {
      partA: '$2,370',
      partB: '',
    },
    features: {
      partADeductible: true,
      partBDeductible: true,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
  'HDG': {
    name: 'HDG',
    popularity: "",
    partADeductible: "$2,370 for both Part A and B",
    partACoinsurance: "Up to an additional 365\ndays after Medicare\nbenefits are used up",
    hospiceCareCopay: true,
    partBDeductible: '$2,370 for both Part A and B',
    partBCoinsurance: "$0",
    partBServices: <><strong>$0</strong> after Part B deductible</>,
    skilledNursingFacility: true,
    partBExcessCharges: true,
    foreignTravelEmergency: 'Your plan pays\n80% of costs',
    blood: true,
    outOfPocketLimits: "Not applicable",
    inpatientHospitalCoverage: <ul>
      <li><span>$0 after Plan deductible for Days 1-60;</span></li>
      <li><span>$0 for Days 61-90;</span></li>
      <li><span>$0 for Days 91-150 (Lifetime Reserve);</span></li>
      <li><span>$0 for Additional 365 days after reserve;</span></li>
      <li><span>You pay all costs beyond the additional 365</span></li>
    </ul>,
    skilledNursingFacilities: <ul>
      <li><span>$0 after Plan deductible for days 1-100;</span></li>
      <li><span>all costs for the 101st day and after.</span></li>
    </ul>,
    bloodTransfusionDuring: <ul>
      After you pay the Plan deductible, you pay:
      <li><span>First 3 pints of blood: $0</span></li>
      <li><span>After 3 pints of blood: $0</span></li>
    </ul>,
    coveredPartBServices: '$0 after Plan deductible if Part B deductible has been met',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside:<ul>
      After you pay the Plan deductible, you pay:
      <li><span>First 3 pints: $0</span></li>
      <li><span>After 3 pints: $0 after Part B deductible</span></li>
    </ul>,
    testsForDiagnosticServices: '$0 after Plan deductible if Part B deductible has been met',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Plan deductible if Part B deductible has been met',
    deductibles: {
      partA: '$2,370',
      partB: '',
    },
    features: {
      partADeductible: true,
      partBDeductible: false,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true,
    }
  },
}

export default PlanData;
