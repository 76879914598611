import React from "react";
import {Box, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import HelpModal from "../../../shared/components/HelpModal";
import MedicareDisclaimersModal from "../../shared/components/MedicareDisclaimersModal";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);

  return <Box maxWidth={800}>
    <HelpModal open={open} onClose={() => setOpen(false)} />
    <MedicareDisclaimersModal open={openDisclaimer} onClose={() => setOpenDisclaimer(false)}/>
    <Box mt={'34px'} textAlign={"center"} mb={'40px'}>
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}><strong>Need some help?</strong> Call us at the number below to speak with a licensed<br/> CoverRight sales agent or schedule a time through the link below.</Typography>
      <img src={'/img/support.svg'} className={'mv-30'} />
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}>
        <strong className={'fs-20 lh-25'}>+1 (888) 969-7667</strong><br/>(TTY: 711)
      </Typography>
      <Box mt={'21px'} mb={'30px'}>
        <RoundedButton className={'gtm-button-event'} data-gtm-button-name={'BOOK_A_CALL_BUTTON'} variant={'contained'} onClick={() => setOpen(true)}>
          Book a call
        </RoundedButton>
      </Box>
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}>Mon-Sun 8AM-11PM (ET)</Typography>
    </Box>
    <Typography variant={'body2'} color={'textPrimary'} className={'bold'}
                style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setOpenDisclaimer(true)}>
      View Medicare Disclaimers
    </Typography>
    <Box my={'10px'}>
      <Typography variant={'h5'} color={'textPrimary'}>
        If you need help, please call +1 (888) 969-7667 (TTY: 711) Mon-Sun 8AM-11PM (ET) for licensed CoverRight sales
        agents who can assist with finding information on available Medicare Advantage, Medicare Supplement (Medigap) Insurance
        and Prescription Drug Plans.
      </Typography>
      <Typography variant={'h5'} color={'textPrimary'}>
        We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to get information on all of your options.
      </Typography>
    </Box>
    <Typography variant={'h5'} color={'textPrimary'}>
      Last Updated Date: 10/15/22<br/>MULTIPLAN_COVERRIGHT_2023_M<br />MULTIPLAN_COVERRIGHT_2023U2_M
    </Typography>
    <div className={'h-200'}/>
  </Box>
}

const RoundedButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 22px',
  },
  label: {
    color: 'white',
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
