import React from 'react';
import {Collapse, withStyles} from "@material-ui/core";
import {colors} from "../AppTheme";
import clsx from "clsx";

interface CollapsableCardProps {
  className?: string,
  title: string | JSX.Element,
  description: string | JSX.Element,
  classes: any,
  open?: boolean
}

const CollapsableCard = withStyles({
  container: {
    width: 600,
    borderRadius: 4,
    background: 'white',
    border: '1px solid #B0DAE1',
    marginBottom: 15,
  },
  titleWrapper: {
    background: '#C8E8EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 22px',
  },
  content: {
    padding: '0 12px',
    fontSize: 12,
    color: colors.text.primary,
  },
})(({classes, title, description, className, open: outerOpen}: CollapsableCardProps) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (typeof outerOpen !== 'undefined') {
      setOpen(outerOpen);
    }
  }, [outerOpen])

  return <div className={clsx(classes.container, className)}>
    <div className={classes.titleWrapper}>
      <div className={'bold fs-14'}>{title}</div>
      <CollapseTrigger onClick={() => setOpen(prev => !prev)} open={open} />
    </div>
    <Collapse in={open}>
      <div className={classes.content}>
        {description}
      </div>
    </Collapse>
  </div>
})

export default CollapsableCard;

export const CollapseTrigger = withStyles({
  toggle: {
    cursor: 'pointer',
    width: 29,
    height: 29,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#1C434F',
    borderRadius: 50,
    transform: 'rotate(0)',
    transition: 'all 0.3s'
  },
  iconOpen: {
    transform: 'rotate(-180deg) !important',
  },
})(({onClick, open, classes}: {onClick: () => void, open: boolean, classes: any}) => {
  return <div className={clsx({[classes.toggle]: true, [classes.iconOpen]: !open})}
       onClick={onClick}>
    <img src={'/img/arrow-white.svg'} />
  </div>
})
