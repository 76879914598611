import React from "react";
import {Box, Divider, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import TooltipedTitle from "../../../shared/components/TooltipedTitle";

type SimplePlanDetailProps = {
  title: string | JSX.Element,
  tooltip?: string,
  content?: string | null | JSX.Element,
  contentClassName?: string,
  emptyString?: string,
  hideDidider?: boolean,
  thin?: boolean
}

function PlanDetail({title, content, tooltip, emptyString, thin, hideDidider, contentClassName}: SimplePlanDetailProps) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} my={"10px"}>
        <Box width={'50%'} minWidth={'50%'} className={'pl-20'}>
          {!tooltip && <Typography variant={"h5"} color={"textPrimary"}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <TooltipedTitle title={title} className={!thin ? 'bold' : 'thin'} tooltip={tooltip} />}
        </Box>
        <Box display={"flex"} className={'pr-20'}>
          <Box display={"flex"} flexGrow={2} ml={phone ? 2 : undefined}>
            {typeof content !== "string" && content}
            {typeof content === "string" &&
            <Typography variant={"h5"} color={"textPrimary"} className={contentClassName}>{content || emptyString}</Typography>}
          </Box>
        </Box>
      </Box>
      {!hideDidider && <Divider/>}
    </>
  )
}

export const SimplePlanDetail = React.memo(PlanDetail);
