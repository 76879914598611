import React from "react";
import {Box, createStyles, Theme, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendCard from "../../../shared/components/AscendCard";
import TooltipedTitle from "../../shared/components/TooltipedTitle";
import {colors} from "../../../shared/AppTheme";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import AscendButton from "../../../shared/components/AscendButton";
import {PlanTitle} from "./components/PlanTitle";
import {Link} from "react-router-dom";
import {PlanParam} from "../DetailedPlan/components/PlanParam";
import Benefit from "../../../shared/components/Benefit";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import AscendDivider from "../../../shared/components/AscendDivider";
import {getMedigapQuoteId, getQuoteId} from "../../../shared/QuoteId";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {FavoritesContext} from "../../shared/FavoritesContext";
import {QuoteContext} from "../../shared/QuoteContext";
import {config} from "../config/config";
import {MedigapPlanBenefit, MedigapPlanName, MedigapPlanOutput} from "../../../medigap-types";
import {MedigapPlanOutputWithDetails} from "../DetailedPlan/hooks/usePlansByBid";
import {PlanFilterContext} from "./PlanFilterContext";
import moment from "moment";
import {getTooltipByBenefit} from "./PlanFilter";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700,
    },
    wrapper: {
      minHeight: 515,
      [theme.breakpoints.up('sm')]: {
        minHeight: 260,
      },
    },
    logo: {
      height: 33,
      minHeight: 33,
      width: 119,
      minWidth: 119,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right'
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '10px 0',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    mobileBenefitImg: {
      marginTop: 3,
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 6px 10px',
      [theme.breakpoints.up('sm')]: {
        margin: '0 14px 10px 16px',
      },
    },
    mobileBenefitLabel: {
      width: '50%',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    enrollSection: {
      padding: '16px 20px 11px 15px',
      margin: '16px -6px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 218,
        width: 218,
        padding: '12px 17px 12px 16px',
        margin: 0,
      },
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      position: 'relative',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      '& .MuiButton-label': {
        fontSize: 14,
      },
      width: '100%',
      padding: 9,
    },
    planEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap'
    },
    excl: {
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    green: {
      color: '#029094',
      opacity: 1,
    },
    red: {
      color: '#DD3D3D',
      opacity: 1,
    },
  }),
);

type PlanCardProps = {
  plan: MedigapPlanOutputWithDetails,
  zip: string,
  countyName: string,
  hideEnroll?: boolean,
  hideButtons?: boolean,
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function PlanCardOld({plan, zip, countyName, hideEnroll, hideButtons} : PlanCardProps) {
  const classes = planStyles();
  const {preferredDrugs} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const ref = React.useRef(null);
  const {values: filterValues} = React.useContext(PlanFilterContext)

  const onEnrollClick = () => {
    const quoteId = getMedigapQuoteId();
    document.location.href = `${config.enrollUrl}enroll/${plan.key}/${zip}/${countyName}${quoteId ? '/' + quoteId : ''}?medigap&age=${filterValues?.age}&tobacco=${filterValues?.tobacco}&gender=${filterValues?.gender}&effectiveDate=${filterValues?.effectiveDate || '2021-07-12'}`;
  }

  const EnrollSection = () => {
    if (!phone) {
      return <div className={classes.enrollSection}>
        <div className={'flex-column drug-calculation'}>
          <AscendTooltip placement={'top'} arrow title={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}>
            <div>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'body2'} color={'textPrimary'} className={'bold mr-8'}>Monthly plan premium</Typography>
                <img className={'w-15 h-15'} src={'/img/info-green.svg'} />
              </Box>
              <Typography className={`mr-20 ${classes.price}`}>{plan.monthlyPremium || 'N/A'}</Typography>
            </div>
          </AscendTooltip>
          <AscendTooltip placement={'top'} arrow title={`The standard Part B premium in 2022 is $170.10. Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your tax return from two years ago), you may pay an extra charge on top of the regular Part B premium.`}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography className={'fs-11'} color={'textPrimary'}>plus standard Part B premium</Typography>
              <img className={'w-15 h-15'} src={'/img/info.svg'} />
            </Box>
          </AscendTooltip>
          {!hideButtons && <>
            <CompareCheckbox plan={plan} />

            <div className={'mb-8'}>
              <Link to={`/plan/${zip}/${countyName}/${plan.key}`} style={{textDecoration: 'none'}}>
                <Typography className={'fs-12 green underline pointer'} align={'center'}>See plan details</Typography>
              </Link>
            </div>
            {!hideEnroll && <AscendButton className={classes.enrollButton}
                                          onClick={onEnrollClick}
                                          variant={'contained'}>Enroll Now</AscendButton>}
          </>}
        </div>
      </div>
    } else {
      return <div className={classes.enrollSection}>
        <div className={'flex-column drug-calculation'}>
          <AscendTooltip placement={'top'} arrow title={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}>
            <Box display={'flex'} alignItems={'flex-start'}>
              <Typography className={`mr-15 ${classes.price}`}>{plan.monthlyPremium || 'N/A'}</Typography>
                <img className={'w-15 h-15 mt-10'} src={'/img/info-green.svg'} />
            </Box>
          </AscendTooltip>
          <Typography color={'textPrimary'} className={'bold fs-14'}>Monthly plan premium</Typography>
          <div className={'mt-5'}>
            <AscendTooltip placement={'top'} arrow title={`The standard Part B premium in 2022 is $170.10. Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your tax return from two years ago), you may pay an extra charge on top of the regular Part B premium.`}>
              <Typography variant={'h5'} align={!!preferredDrugs?.preferredDrugs?.length ? 'right' : 'left'} className={classes.excl}>plus standard Part B premium</Typography>
            </AscendTooltip>
          </div>
        </div>
      </div>
    }

  }

  const DetailSection = () => {
    if (!phone) {
      return <div className={'flex'}>
        <div className={'flex-column'}>
          <TooltipedTitle title={'Copays / Coinsurance'}
                          tooltip={'These are the amounts that you may be required to pay as your share for medical care once you have paid your full deductible. A copay is usually a dollar value (for example, $25), and coinsurance is usually a percentage of the regular cost of the service (for example, 20%).'}
                          className={'bold'}
                          imgProps={tooltipImgProps}/>
          <PlanParam label={'Part B services'} value={plan?.partBServices || 'n.a.'}/>
          <div className={'h-20'}/>
          <TooltipedTitle title={'Deductible'}
                          tooltip={'The amount you must pay out-of-pocket for Part A and Part B covered services before the plan\'s coverage starts.'}
                          className={'bold'}
                          imgProps={tooltipImgProps}/>
          <div className={'flex'}>
            <PlanParam label={[MedigapPlanName.Hdg, MedigapPlanName.Hdf].includes(plan?.planName as any) ? 'Annual' : 'Part A'}
                       className={'flex-column'}
                       value={plan?.deductibles.partA || 'n.a.'}/>
            {plan?.deductibles.partB && <PlanParam label={'Part B'} className={'flex-column'} value={plan?.deductibles.partB || 'n.a.'}/>}
          </div>
        </div>
        <div className={'flex-column ml-12'}>
          <TooltipedTitle title={'Plan features'}
                          tooltip={'Some Medigap plans may offer additional extra features, like a reduction of Part B Excess charges, Foreign Travel Emergency coverage, or additional coverage for Skilled Nursing Facilities'}
                          className={'bold'}
                          imgProps={tooltipImgProps}/>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartADeductible)}>
              <div>
                <Benefit inactive={false}
                       labelClass={`thin ${!!plan?.features.partADeductible ? classes.green : classes.red}`} label={'Part A deductible'}
                       value={!!plan?.features.partADeductible}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBDeductible)}>
              <div>
                <Benefit inactive={false}
                       labelClass={`thin ${!!plan?.features.partBDeductible ? classes.green : classes.red}`} label={'Part B deductible'}
                       value={!!plan?.features.partBDeductible}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBExcessCharges)}>
              <div>
                <Benefit inactive={false}
                       labelClass={`thin ${!!plan?.features.partBExcessCharges ? classes.green : classes.red}`} label={'Part B excess charges'}
                       value={!!plan?.features.partBExcessCharges}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.SkilledNursingFacilities)}>
              <div>
                <Benefit inactive={false}
                       labelClass={`thin ${!!plan?.features.skilledNursingFacility ? classes.green : classes.red}`} label={'Skilled nursing facility'}
                       value={!!plan?.features.skilledNursingFacility}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.ForeignTravelEmergency)}>
              <div>
                <Benefit inactive={false}
                       labelClass={`thin ${!!plan?.features.foreignTravelEmergency ? classes.green : classes.red}`} label={'Foreign travel emergency'}
                       value={!!plan?.features.foreignTravelEmergency}/>
              </div>
            </AscendTooltip>
          </div>
        </div>
        <div className={'flex-column ml-12'}>
          <AscendTooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'flex flex-align-center flex-space-between'}>
              <Typography className={'fs-12 bold'} color={'textPrimary'}>AM Best rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography className={'fs-12 medium dark-green'}>{plan.amBestRating}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </div>
          </AscendTooltip>
          <AscendTooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'mt-8 flex flex-align-center flex-space-between'}>
              <Typography className={'fs-12 bold'} color={'textPrimary'}>S&P rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography className={'fs-12 medium dark-green'}>{plan.spRating}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </div>
          </AscendTooltip>
          <div className={'h-20'}/>
          <AscendTooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
            <div>
              <div className={'mt-8 flex flex-align-start'}>
                <Typography className={'fs-12 bold'} color={'textPrimary'}>Household discount:</Typography>
                <img className={'w-12 h-12 ml-10 mt-5'} src={'/img/info.svg'}></img>
              </div>
              <Typography className={'fs-12 medium dark-green'}>{plan.householdDiscount || 'n.a.'}</Typography>
            </div>
          </AscendTooltip>
        </div>
      </div>
    } else {
      return <div className={'flex-column'}>
        <div className={'flex'}>
          <div className={'flex-column mr-15'}>
            <div className={'mb-4'}>
              <TooltipedTitle title={'Copays / Coinsurance'}
                              tooltip={'These are the amounts that you may be required to pay as your share for medical care once you have paid your full deductible. A copay is usually a dollar value (for example, $25), and coinsurance is usually a percentage of the regular cost of the service (for example, 20%).'}
                              imgProps={tooltipImgProps}/>
            </div>
            <PlanParam label={'Your typical cost for approved Part B services'}
                       className={'flex'}
                       value={plan?.partBCoinsurance || 'n.a.'}/>
          </div>
          <div className={'flex-column'}>
            <TooltipedTitle title={'Deductibles'}
                            tooltip={'The amount you must pay out-of-pocket for Part A and Part B covered services before the plan\'s coverage starts.'}
                            imgProps={tooltipImgProps}/>
            <div className={'flex-space-between'}>
              <PlanParam label={[MedigapPlanName.Hdg, MedigapPlanName.Hdf].includes(plan?.planName as any) ? 'Annual' : 'Part A'}
                         className={'flex-column'}
                         value={plan?.deductibles.partA || 'n.a.'}/>
              {plan?.deductibles.partB && <PlanParam label={'Part B'} className={'flex-column'} value={plan?.deductibles.partB || 'n.a.'}/>}
            </div>
          </div>
        </div>
        <AscendDivider my={'12px'} />
        <TooltipedTitle title={'Plan features'}
                        tooltip={'Some Medigap plans may offer additional extra features, like a reduction of Part B Excess charges, Foreign Travel Emergency coverage, or additional coverage for Skilled Nursing Facilities'}
                        imgProps={tooltipImgProps}/>
        <div className={'flex'}>
          <div className={'flex-column mr-15'}>
            <div className={'mt-5'}>
              <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartADeductible)}>
                <div>
                  <Benefit inactive={false}
                           labelClass={`thin ${!!plan?.features.partADeductible ? classes.green : classes.red}`} label={'Part A deductible'}
                           value={!!plan?.features.partADeductible}/>
                </div>
              </AscendTooltip>
            </div>
            <div className={'mt-5'}>
              <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBDeductible)}>
                <div>
                  <Benefit inactive={false}
                           labelClass={`thin ${!!plan?.features.partBDeductible ? classes.green : classes.red}`} label={'Part B deductible'}
                           value={!!plan?.features.partBDeductible}/>
                </div>
              </AscendTooltip>
            </div>
            <div className={'mt-5'}>
              <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBExcessCharges)}>
                <div>
                  <Benefit inactive={false}
                           className={'flex-align-start'}
                           imageClass={classes.mobileBenefitImg}
                           labelClass={`thin ${!!plan?.features.partBExcessCharges ? classes.green : classes.red}`} label={'Part B excess charges'}
                           value={!!plan?.features.partBExcessCharges}/>
                </div>
              </AscendTooltip>
            </div>
          </div>
          <div className={'flex-column'}>
            <div className={'mt-8'}>
              <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.SkilledNursingFacilities)}>
                <div>
                  <Benefit inactive={false}
                           labelClass={`thin ${!!plan?.features.skilledNursingFacility ? classes.green : classes.red}`} label={'Skilled nursing facility'}
                           value={!!plan?.features.skilledNursingFacility}/>
                </div>
              </AscendTooltip>
            </div>
            <div className={'mt-8'}>
              <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.ForeignTravelEmergency)}>
                <div>
                  <Benefit inactive={false}
                           className={'flex-align-start'}
                           imageClass={classes.mobileBenefitImg}
                           labelClass={`thin ${!!plan?.features.foreignTravelEmergency ? classes.green : classes.red}`} label={'Foreign travel emergency'}
                           value={!!plan?.features.foreignTravelEmergency}/>
                </div>
              </AscendTooltip>
            </div>
          </div>
        </div>
        <AscendDivider my={'12px'} />
        <div className={'flex'}>
          <div className={'flex-column mr-15'}>
            <AscendTooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
              <div className={'mt-8 flex flex-align-center'}>
                <Typography className={'fs-12 medium w-100'} color={'textPrimary'}>AM Best rating:</Typography>
                <Typography className={'fs-12 medium dark-green'}>{plan.amBestRating}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </AscendTooltip>
            <AscendTooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
              <div className={'mt-8 flex flex-align-center'}>
                <Typography className={'fs-12 medium w-100'} color={'textPrimary'}>S&P rating:</Typography>
                <Typography className={'fs-12 medium dark-green'}>{plan.spRating}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </AscendTooltip>
          </div>
          <div  className={'flex-column'}>
            <AscendTooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
              <div className={'mt-8 flex flex-align-start'}>
                <Typography className={'fs-12 medium w-75'} color={'textPrimary'}>Household discount:</Typography>
                <Typography className={'fs-12 medium dark-green'}>{plan.householdDiscount || 'n.a.'}</Typography>
                <img className={'w-12 h-12 ml-10 mt-5'} src={'/img/info.svg'}></img>
              </div>
            </AscendTooltip>
          </div>
        </div>
        <AscendDivider my={'12px'} />
        {!hideButtons && <div>
          <div className={'mb-8'}>
            <CompareCheckbox plan={plan} />
          </div>
          <div className={'flex-space-between'}>
            <div className={'flex-column'}>
              <Link to={`/plan/${zip}/${countyName}/${plan.key}`} style={{textDecoration: 'none'}}>
                <AscendButton className={classes.enrollButton} variant={'outlined'}>Plan details</AscendButton>
              </Link>
            </div>
            <div className={'w-30'} />
            <div className={'flex-column'}>
              {!hideEnroll && <AscendButton className={classes.enrollButton}
                                        onClick={onEnrollClick}
                                        variant={'contained'}>Enroll Now</AscendButton>}
            </div>
          </div>
        </div>}
      </div>
    }
  }

  return <div className={`mt-24 ${classes.wrapper}`} ref={ref}>
    {<>
      <AscendCard className={classes.card}>
        <div className={'flex mh-10'}>
          <div className={classes.infoWrapper}>
            <PlanTitle hideFavorites
                       plan={{...plan, note: hideButtons ? undefined : plan.note }}
                       inFavorites={favorites.indexOf(plan.key) >= 0}
                       favoritesClick={() => toggleFavorite(plan.key, zip)} />
            {phone ? <EnrollSection /> : <AscendDivider my={1} />}
            <DetailSection />
          </div>
          {!phone && <EnrollSection />}
        </div>
      </AscendCard>
    </>}
  </div>;
}

export default React.memo(PlanCardOld);

const CompareCheckbox = ({plan}: {plan: MedigapPlanOutput}) => {
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const classes = planStyles();
  return <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox}
                         labelClassName={classes.checkboxLabel}
                         label={'Add to compare'} checked={isInCompare(plan.key)}
                         onChange={(e, checked) => {checked ? addToCompare(plan.key) : removeFromCompare(plan.key)}} />
}

export const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  contained: {
    borderRadius: 4,
    background: 'rgba(2, 144, 148, 0.05)',
    border: '1px solid #029094',
    padding: '4px 21px',
  },
  disabled: {},
  label: {
    color: 'rgba(2, 144, 148, 1)',
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'none',
  },
})(AscendButton);
