export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: any;
  /** A type representing local date */
  LocalDate: any;
  /** Long type */
  Long: any;
  /** A type representing a string identifier */
  UUID: any;
};

export type Address = {
  __typename?: 'Address';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressDto = {
  __typename?: 'AddressDto';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type AgentViewOutput = {
  __typename?: 'AgentViewOutput';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
};

export type AttemptedLeadOutput = {
  __typename?: 'AttemptedLeadOutput';
  birthDate?: Maybe<Scalars['Instant']>;
  callsCount: Scalars['Int'];
  clientId: Scalars['UUID'];
  firstName?: Maybe<Scalars['String']>;
  iepFlag?: Maybe<Scalars['Boolean']>;
  lastCallDate?: Maybe<Scalars['Instant']>;
  lastName?: Maybe<Scalars['String']>;
  leadDate: Scalars['Instant'];
  leadGroup: AttemptedLeadGroupDto;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status: Status;
};

export type CallHistoryOutput = {
  __typename?: 'CallHistoryOutput';
  agent?: Maybe<AgentViewOutput>;
  callType?: Maybe<CallHistoryCallType>;
  clientId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Instant']>;
  id?: Maybe<Scalars['UUID']>;
  phoneNumber: Scalars['String'];
  result?: Maybe<CallHistoryResult>;
};

export type ClientViewOutput = {
  __typename?: 'ClientViewOutput';
  agent?: Maybe<AgentViewOutput>;
  agentMedicareQuote?: Maybe<MedicareQuoteOutput>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctors: Array<DoctorViewOutput>;
  drugs: Array<DrugViewOutput>;
  email?: Maybe<Scalars['String']>;
  enrollment?: Maybe<EnrollmentViewOutput>;
  filters?: Maybe<FiltersOutput>;
  firstName?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  leadSources?: Maybe<Array<LeadSourceOutput>>;
  medigapEnrollment?: Maybe<MedigapEnrollmentViewOutput>;
  medigapQuote?: Maybe<MedigapQuoteViewOutput>;
  note?: Maybe<Scalars['String']>;
  originalClientId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
  profileSource?: Maybe<ProfileSource>;
  purchasedProducts: Array<PurchasedProductOutput>;
  quoteId?: Maybe<Scalars['UUID']>;
  scopeOfAppointmentStatus?: Maybe<ScopeOfAppointmentStatusDto>;
  state?: Maybe<Scalars['String']>;
  status: StatusDto;
  tags: Array<TagViewOutput>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};

export type DoctorAddressViewOutput = {
  __typename?: 'DoctorAddressViewOutput';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  id: Scalars['String'];
  isPcp: Scalars['Boolean'];
  specialty: Scalars['String'];
};

export type DoctorViewOutput = {
  __typename?: 'DoctorViewOutput';
  address: DoctorAddressViewOutput;
  addressesNumber: Scalars['Int'];
  name: Scalars['String'];
  npi: Scalars['String'];
  pcpDetails: Array<PcpInfoViewOutput>;
};

export type DrugViewOutput = {
  __typename?: 'DrugViewOutput';
  frequency?: Maybe<Scalars['String']>;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['BigDecimal']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type EnrollmentAddressOutput = {
  __typename?: 'EnrollmentAddressOutput';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type EnrollmentOutput = {
  __typename?: 'EnrollmentOutput';
  approvedAt?: Maybe<Scalars['String']>;
  bidId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['UUID']>;
  communicationPreference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressDto>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<AddressDto>;
  phoneNumber?: Maybe<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
  preferredLanguage?: Maybe<PreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']>;
  status: StatusOutput;
  userId?: Maybe<Scalars['UUID']>;
};

export type EnrollmentViewOutput = {
  __typename?: 'EnrollmentViewOutput';
  approvedAt?: Maybe<Scalars['String']>;
  bidId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  communicationPreference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<EnrollmentAddressOutput>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt: Scalars['String'];
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<EnrollmentAddressOutput>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<EnrollmentPreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']>;
  status: EnrollmentStatusDto;
  userId?: Maybe<Scalars['UUID']>;
};

export type FiltersOutput = {
  __typename?: 'FiltersOutput';
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  extraBenefits: Array<ExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
};

export type KeycloakUserOutput = {
  __typename?: 'KeycloakUserOutput';
  enrollmentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type LeadSourceOutput = {
  __typename?: 'LeadSourceOutput';
  campaign: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  eventDate: Scalars['LocalDate'];
  medium: Scalars['String'];
  source: Scalars['String'];
  term?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MedicareQuoteOutput = {
  __typename?: 'MedicareQuoteOutput';
  agentOffer?: Maybe<Scalars['Boolean']>;
  cId?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctors: Array<DoctorViewOutput>;
  drugs: Array<DrugViewOutput>;
  filters?: Maybe<FiltersOutput>;
  pin?: Maybe<Scalars['String']>;
  quoteId: Scalars['UUID'];
  state?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};

export type MedigapEnrollmentAddress = {
  __typename?: 'MedigapEnrollmentAddress';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  toRegular: Address;
  zip: Scalars['String'];
};

export type MedigapEnrollmentOutput = {
  __typename?: 'MedigapEnrollmentOutput';
  accessibleFormatPreference?: Maybe<MedigapEnrollmentAccessibleFormat>;
  age: Scalars['Int'];
  approvedAt?: Maybe<Scalars['Instant']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
  clientId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Instant']>;
  effectiveDate: Scalars['LocalDate'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender: Gender;
  hospitalDate?: Maybe<Scalars['LocalDate']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<MedigapEnrollmentAddress>;
  medicalDate?: Maybe<Scalars['LocalDate']>;
  medicareNumber?: Maybe<Scalars['String']>;
  medigapQuoteId?: Maybe<Scalars['UUID']>;
  naic: Scalars['String'];
  permanentAddress?: Maybe<MedigapEnrollmentAddress>;
  phoneNumber?: Maybe<Scalars['String']>;
  planName: MedigapEnrollmentPlanName;
  preferredLanguage?: Maybe<MedigapEnrollmentPreferredLanguage>;
  status: MedigapEnrollmentStatus;
  tobacco: Scalars['Boolean'];
};

export type MedigapEnrollmentViewAddress = {
  __typename?: 'MedigapEnrollmentViewAddress';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type MedigapEnrollmentViewOutput = {
  __typename?: 'MedigapEnrollmentViewOutput';
  accessibleFormatPreference?: Maybe<MedigapEnrollmentViewAccessibleFormat>;
  age: Scalars['Int'];
  approvedAt?: Maybe<Scalars['Instant']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
  createdAt?: Maybe<Scalars['Instant']>;
  effectiveDate: Scalars['LocalDate'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender: MedigapEnrollmentViewGender;
  hospitalDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<MedigapEnrollmentViewAddress>;
  medicalDate?: Maybe<Scalars['LocalDate']>;
  medicareNumber?: Maybe<Scalars['String']>;
  naic: Scalars['String'];
  permanentAddress?: Maybe<MedigapEnrollmentViewAddress>;
  phoneNumber?: Maybe<Scalars['String']>;
  planName: Scalars['String'];
  preferredLanguage?: Maybe<MedigapEnrollmentViewPreferredLanguage>;
  quoteId?: Maybe<Scalars['UUID']>;
  status: MedigapEnrollmentViewStatus;
  tobacco: Scalars['Boolean'];
  userId?: Maybe<Scalars['UUID']>;
};

export type MedigapFilterStateViewOutput = {
  __typename?: 'MedigapFilterStateViewOutput';
  age: Scalars['Int'];
  benefits?: Maybe<Array<MedigapFilterStateBenefit>>;
  companies?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['LocalDate']>;
  gender: MedigapFilterStateGender;
  id?: Maybe<Scalars['UUID']>;
  monthlyPlanPremiumRanges?: Maybe<Array<Scalars['String']>>;
  planCategory?: Maybe<Scalars['String']>;
  planNames?: Maybe<Array<MedigapFilterStatePlanName>>;
  tobacco: Scalars['Boolean'];
  zip: Scalars['String'];
};

export type MedigapQuoteViewOutput = {
  __typename?: 'MedigapQuoteViewOutput';
  cId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  id: Scalars['UUID'];
  medigapFilterState?: Maybe<MedigapFilterStateViewOutput>;
  updatedAt?: Maybe<Scalars['Instant']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignClient: Scalars['Boolean'];
  assignClientToMe: Scalars['Boolean'];
  /** @deprecated Use assignClient instead */
  assignClientView: Scalars['Boolean'];
  /** @deprecated Use assignClientToMe instead */
  assignClientViewToMe: Scalars['Boolean'];
  changeEnrollmentStatus?: Maybe<Scalars['UUID']>;
  changeStatusForClientsWithoutPhone: Scalars['Boolean'];
  confirmScopeOfAppointment: Scalars['UUID'];
  createScopeOfAppointment: Scalars['UUID'];
  /** @deprecated Need to use saveUserProfile API */
  createUser: KeycloakUserOutput;
  deletePurchasedProduct: Scalars['Boolean'];
  deleteTag: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  emailMeMAPage: Scalars['Boolean'];
  emailMeMedigapPage: Scalars['Boolean'];
  getRefreshToken: LoginResponse;
  login: LoginResponse;
  openedScopeOfAppointment: Scalars['Boolean'];
  putTemporalData: Scalars['UUID'];
  resetPassword: Scalars['Boolean'];
  saveCallHistory: Scalars['UUID'];
  saveClientTag: Scalars['Boolean'];
  saveEnrollment: Scalars['UUID'];
  saveFollowUpDate: Scalars['Boolean'];
  saveMedigapEnrollment: Scalars['UUID'];
  saveNote: Scalars['Boolean'];
  savePurchasedProduct: Scalars['UUID'];
  saveStatus: Scalars['Boolean'];
  saveTag: Scalars['UUID'];
  saveUserProfile: UserProfileSaveOutput;
  updateSendInBlueByEmail: Scalars['String'];
  updateSources: Array<LeadSourceOutput>;
};


export type MutationAssignClientArgs = {
  agentId: Scalars['UUID'];
  clientId: Scalars['UUID'];
};


export type MutationAssignClientToMeArgs = {
  clientId: Scalars['UUID'];
};


export type MutationAssignClientViewArgs = {
  agentId: Scalars['UUID'];
  clientViewId: Scalars['UUID'];
};


export type MutationAssignClientViewToMeArgs = {
  clientViewId: Scalars['UUID'];
};


export type MutationChangeEnrollmentStatusArgs = {
  enrollmentId: Scalars['UUID'];
  status: EnrollmentStatusInput;
};


export type MutationConfirmScopeOfAppointmentArgs = {
  userInput: ScopeOfAppointmentUserInput;
};


export type MutationCreateScopeOfAppointmentArgs = {
  adminInput: ScopeOfAppointmentAdminInput;
};


export type MutationCreateUserArgs = {
  input: KeycloakUserInput;
};


export type MutationDeletePurchasedProductArgs = {
  purchasedProductId: Scalars['UUID'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['UUID'];
};


export type MutationEmailMeMaPageArgs = {
  email: Scalars['String'];
  id?: InputMaybe<Scalars['UUID']>;
  path?: InputMaybe<Scalars['String']>;
  yearEmailMeMaPage?: InputMaybe<EmailMeMaPagePlanYear>;
};


export type MutationEmailMeMedigapPageArgs = {
  email: Scalars['String'];
  id: Scalars['UUID'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationOpenedScopeOfAppointmentArgs = {
  id: Scalars['UUID'];
};


export type MutationPutTemporalDataArgs = {
  data: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationSaveCallHistoryArgs = {
  input: CallHistoryInput;
};


export type MutationSaveClientTagArgs = {
  input: ClientTagInput;
};


export type MutationSaveEnrollmentArgs = {
  input: EnrollmentInput;
};


export type MutationSaveFollowUpDateArgs = {
  followUpDate?: InputMaybe<Scalars['Instant']>;
  id: Scalars['UUID'];
};


export type MutationSaveMedigapEnrollmentArgs = {
  input: MedigapEnrollmentInput;
};


export type MutationSaveNoteArgs = {
  id: Scalars['UUID'];
  note: Scalars['String'];
};


export type MutationSavePurchasedProductArgs = {
  input: PurchasedProductInput;
};


export type MutationSaveStatusArgs = {
  id: Scalars['UUID'];
  status: StatusDto;
};


export type MutationSaveTagArgs = {
  input: TagInput;
};


export type MutationSaveUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationUpdateSendInBlueByEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateSourcesArgs = {
  clientId: Scalars['UUID'];
};

export type NetworkInfoViewOutput = {
  __typename?: 'NetworkInfoViewOutput';
  carrierName: Scalars['String'];
  name: Scalars['String'];
};

export type NewLeadsStatisticsOutput = {
  __typename?: 'NewLeadsStatisticsOutput';
  count: Scalars['Int'];
  oldestCreatedAt?: Maybe<Scalars['Instant']>;
};

export type PageableAttemptedLeadOutput = {
  __typename?: 'PageableAttemptedLeadOutput';
  data: Array<AttemptedLeadOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableCallHistoryOutput = {
  __typename?: 'PageableCallHistoryOutput';
  data: Array<CallHistoryOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableClientViewOutput = {
  __typename?: 'PageableClientViewOutput';
  data: Array<ClientViewOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableEnrollmentOutput = {
  __typename?: 'PageableEnrollmentOutput';
  data: Array<EnrollmentOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableMedigapEnrollmentOutput = {
  __typename?: 'PageableMedigapEnrollmentOutput';
  data: Array<MedigapEnrollmentOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableUserProfileOutput = {
  __typename?: 'PageableUserProfileOutput';
  data: Array<UserProfileOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PcpInfoViewOutput = {
  __typename?: 'PcpInfoViewOutput';
  id?: Maybe<Scalars['UUID']>;
  networkDetails: Array<NetworkInfoViewOutput>;
  pcpId: Scalars['String'];
};

export type PurchasedProductOutput = {
  __typename?: 'PurchasedProductOutput';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isPrimaryPerson: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  type: CoverRightProductDto;
};

export type Query = {
  __typename?: 'Query';
  agents: Array<AgentViewOutput>;
  attemptedLeads: PageableAttemptedLeadOutput;
  callHistoryItems: PageableCallHistoryOutput;
  checkUserExistenceByEmail: Scalars['Boolean'];
  clientLeadSources: Array<LeadSourceOutput>;
  clientViewByPin?: Maybe<ClientViewOutput>;
  clientViews: PageableClientViewOutput;
  enrollments: PageableEnrollmentOutput;
  getMyEnrollment?: Maybe<EnrollmentOutput>;
  getMyMedigapEnrollment?: Maybe<MedigapEnrollmentOutput>;
  medigapEnrollments: PageableMedigapEnrollmentOutput;
  pushTemporalData?: Maybe<Scalars['String']>;
  scopeOfAppointment?: Maybe<ScopeOfAppointmentOutput>;
  sendInBlueHistoryByEmail: SendInBlueContactWithHistory;
  tag: TagOutput;
  tags: Array<TagOutput>;
  userProfile?: Maybe<UserProfileOutput>;
  userProfiles: PageableUserProfileOutput;
};


export type QueryAttemptedLeadsArgs = {
  filter: AttemptedLeadFilterInput;
  pageInput: PageInput;
};


export type QueryCallHistoryItemsArgs = {
  clientId: Scalars['UUID'];
  page: PageInput;
};


export type QueryCheckUserExistenceByEmailArgs = {
  email: Scalars['String'];
};


export type QueryClientLeadSourcesArgs = {
  clientId: Scalars['UUID'];
};


export type QueryClientViewByPinArgs = {
  pin: Scalars['String'];
};


export type QueryClientViewsArgs = {
  filterInput?: InputMaybe<ClientViewFilterInput>;
  pageInput: PageInput;
  sort?: InputMaybe<ClientViewSortInput>;
};


export type QueryEnrollmentsArgs = {
  input?: InputMaybe<EnrollmentFilterInput>;
  page: PageInput;
};


export type QueryMedigapEnrollmentsArgs = {
  page: PageInput;
};


export type QueryPushTemporalDataArgs = {
  id: Scalars['UUID'];
};


export type QueryScopeOfAppointmentArgs = {
  soaId: Scalars['UUID'];
};


export type QuerySendInBlueHistoryByEmailArgs = {
  email: Scalars['String'];
};


export type QueryTagArgs = {
  id: Scalars['UUID'];
};


export type QueryUserProfilesArgs = {
  filterInput?: InputMaybe<UserProfileFilterInput>;
  page: PageInput;
};

export type ScopeOfAppointmentOutput = {
  __typename?: 'ScopeOfAppointmentOutput';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  isConfirmed: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SendInBlueContactWithHistory = {
  __typename?: 'SendInBlueContactWithHistory';
  history: Array<Maybe<SendInBlueHistoryItem>>;
};

export type SendInBlueHistoryItem = {
  __typename?: 'SendInBlueHistoryItem';
  date: Scalars['Instant'];
  fromEmail: Scalars['String'];
  status: SendInBlueHistoryStatus;
  subject: Scalars['String'];
  toEmail: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  clientCard: ClientViewOutput;
  newLeadsStatistics: NewLeadsStatisticsOutput;
};


export type SubscriptionClientCardArgs = {
  data: ClientDetailsSubscriptionInput;
};

export type TagOutput = {
  __typename?: 'TagOutput';
  color?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type TagViewOutput = {
  __typename?: 'TagViewOutput';
  color?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type UserProfileOutput = {
  __typename?: 'UserProfileOutput';
  aptNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasNoPreferredDoctors?: Maybe<Scalars['Boolean']>;
  hasNoPreferredPharmacies?: Maybe<Scalars['Boolean']>;
  hasNoPrescriptionsDrugs?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  medigapQuoteId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileId: Scalars['UUID'];
  quoteId?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserProfileSaveOutput = {
  __typename?: 'UserProfileSaveOutput';
  enrollmentId?: Maybe<Scalars['UUID']>;
  profileId: Scalars['UUID'];
  userId?: Maybe<Scalars['UUID']>;
  userToken?: Maybe<UserToken>;
};

export type UserToken = {
  __typename?: 'UserToken';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export enum AttemptedLeadGroupDto {
  GroupA = 'GROUP_A',
  GroupB = 'GROUP_B',
  GroupC = 'GROUP_C',
  GroupD = 'GROUP_D',
  NewLead = 'NEW_LEAD'
}

export enum CallHistoryCallType {
  InboundCallExistingUser = 'INBOUND_CALL_EXISTING_USER',
  InboundCallNewUser = 'INBOUND_CALL_NEW_USER',
  OutboundAttemptExistingUser = 'OUTBOUND_ATTEMPT_EXISTING_USER',
  OutboundAttemptNewUser = 'OUTBOUND_ATTEMPT_NEW_USER'
}

export enum CallHistoryResult {
  SuccessfulSeeNotesForCallDetails = 'SUCCESSFUL_SEE_NOTES_FOR_CALL_DETAILS',
  UnsuccessfulInvalidNumber = 'UNSUCCESSFUL_INVALID_NUMBER',
  UnsuccessfulLeftText = 'UNSUCCESSFUL_LEFT_TEXT',
  UnsuccessfulLeftVm = 'UNSUCCESSFUL_LEFT_VM',
  UnsuccessfulLeftVmAndText = 'UNSUCCESSFUL_LEFT_VM_AND_TEXT',
  UnsuccessfulNoVmOption = 'UNSUCCESSFUL_NO_VM_OPTION'
}

export enum CoverRightProductDto {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export enum EmailMeMaPagePlanYear {
  Year2021 = 'YEAR2021',
  Year2022 = 'YEAR2022',
  Year2023 = 'YEAR2023',
}

export enum EnrollmentPreferredLanguageDto {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum EnrollmentStatusDto {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum EnrollmentStatusInput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum Field {
  BirthDate = 'birthDate',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  FollowUpDate = 'followUpDate',
  Gender = 'gender',
  LastName = 'lastName',
  Note = 'note',
  PhoneNumber = 'phoneNumber',
  ScopeOfAppointmentStatus = 'scopeOfAppointmentStatus',
  Status = 'status',
  Zip = 'zip'
}

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum Gender {
  F = 'F',
  M = 'M'
}

export enum MedigapEnrollmentAccessibleFormat {
  AccessibleScreenReader = 'ACCESSIBLE_SCREEN_READER',
  Audio = 'AUDIO',
  Braille = 'BRAILLE',
  LargePrint = 'LARGE_PRINT',
  OralOverThePhone = 'ORAL_OVER_THE_PHONE'
}

export enum MedigapEnrollmentPlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N'
}

export enum MedigapEnrollmentPreferredLanguage {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum MedigapEnrollmentStatus {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum MedigapEnrollmentViewAccessibleFormat {
  AccessibleScreenReader = 'ACCESSIBLE_SCREEN_READER',
  Audio = 'AUDIO',
  Braille = 'BRAILLE',
  LargePrint = 'LARGE_PRINT',
  OralOverThePhone = 'ORAL_OVER_THE_PHONE'
}

export enum MedigapEnrollmentViewGender {
  F = 'F',
  M = 'M'
}

export enum MedigapEnrollmentViewPreferredLanguage {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum MedigapEnrollmentViewStatus {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum MedigapFilterStateBenefit {
  ForeignTravelEmergency = 'FOREIGN_TRAVEL_EMERGENCY',
  PartADeductible = 'PART_A_DEDUCTIBLE',
  PartBDeductible = 'PART_B_DEDUCTIBLE',
  PartBExcessCharges = 'PART_B_EXCESS_CHARGES',
  SkilledNursingFacilities = 'SKILLED_NURSING_FACILITIES'
}

export enum MedigapFilterStateGender {
  F = 'F',
  M = 'M'
}

export enum MedigapFilterStatePlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N'
}

export enum PlanYear {
  Year2021 = 'Year2021',
  Year2022 = 'Year2022',
  Year2023 = 'Year2023',
}

export enum PreferredLanguageDto {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum ProfileOption {
  CreateEnrollment = 'CREATE_ENROLLMENT',
  RegisterUser = 'REGISTER_USER',
  RequestCall = 'REQUEST_CALL',
  UnsupportedZip = 'UNSUPPORTED_ZIP'
}

export enum ProfileSource {
  BlueInk = 'BLUE_INK',
  CallRequest = 'CALL_REQUEST',
  EnrollmentForm = 'ENROLLMENT_FORM',
  OutOfService = 'OUT_OF_SERVICE',
  UserFlowV1 = 'USER_FLOW_V1',
  UserFlowV1_5 = 'USER_FLOW_V1_5',
  UserFlowV2 = 'USER_FLOW_V2'
}

export enum Rating {
  None = 'NONE',
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export enum SnpType {
  CSnp = 'C_SNP',
  DSnp = 'D_SNP',
  ISnp = 'I_SNP'
}

export enum ScopeOfAppointmentStatusDto {
  Opened = 'OPENED',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export enum SendInBlueHistoryStatus {
  Blocked = 'BLOCKED',
  Clicks = 'CLICKS',
  Delivered = 'DELIVERED',
  Invalid = 'INVALID',
  Opened = 'OPENED',
  Unsubscribed = 'UNSUBSCRIBED'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Attempted = 'ATTEMPTED',
  Contacted = 'CONTACTED',
  Disqualified = 'DISQUALIFIED',
  Draft = 'DRAFT',
  Duplicated = 'DUPLICATED',
  Enrolled = 'ENROLLED',
  FollowUp = 'FOLLOW_UP',
  NotYetEngaged = 'NOT_YET_ENGAGED',
  NoNumber = 'NO_NUMBER',
  OutOfService = 'OUT_OF_SERVICE'
}

export enum StatusDto {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Attempted = 'ATTEMPTED',
  Contacted = 'CONTACTED',
  Disqualified = 'DISQUALIFIED',
  Draft = 'DRAFT',
  Duplicated = 'DUPLICATED',
  Enrolled = 'ENROLLED',
  FollowUp = 'FOLLOW_UP',
  NotYetEngaged = 'NOT_YET_ENGAGED',
  NoNumber = 'NO_NUMBER',
  OutOfService = 'OUT_OF_SERVICE'
}

export enum StatusOutput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type AddressDtoInput = {
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type AttemptedLeadFilterInput = {
  badNumbersOnly?: InputMaybe<Scalars['Boolean']>;
  leadGroup?: InputMaybe<AttemptedLeadGroupDto>;
};

export type CallHistoryInput = {
  callType?: InputMaybe<CallHistoryCallType>;
  clientId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  phoneNumber: Scalars['String'];
  result?: InputMaybe<CallHistoryResult>;
};

export type ClientDetailsSubscriptionInput = {
  clientId: Scalars['UUID'];
};

export type ClientTagInput = {
  clientId: Scalars['UUID'];
  tagIds: Array<Scalars['UUID']>;
};

export type ClientViewFilterInput = {
  agentId?: InputMaybe<Scalars['UUID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusDto>;
  tags?: InputMaybe<Array<Scalars['UUID']>>;
};

export type ClientViewSortInput = {
  direction: SortDirection;
  field: Field;
};

export type EnrollmentFilterInput = {
  lastName?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<EnrollmentStatusInput>>;
};

export type EnrollmentInput = {
  bidId?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['String']>;
  communicationPreference?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isElectronicallyCommunications?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<AddressDtoInput>;
  medicareNumber?: InputMaybe<Scalars['String']>;
  medicarePartAStartedAt?: InputMaybe<Scalars['String']>;
  medicarePartBStartedAt?: InputMaybe<Scalars['String']>;
  permanentAddress?: InputMaybe<AddressDtoInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  planYear?: InputMaybe<PlanYear>;
  preferredLanguage?: InputMaybe<PreferredLanguageDto>;
  quoteId?: InputMaybe<Scalars['UUID']>;
};

export type KeycloakUserInput = {
  email: Scalars['String'];
  enrollmentId?: InputMaybe<Scalars['UUID']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['UUID']>;
  quoteId?: InputMaybe<Scalars['UUID']>;
};

export type MedigapEnrollmentAddressInput = {
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type MedigapEnrollmentInput = {
  accessibleFormatPreference?: InputMaybe<MedigapEnrollmentAccessibleFormat>;
  age: Scalars['Int'];
  birthDate?: InputMaybe<Scalars['LocalDate']>;
  cId?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['LocalDate'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender: Gender;
  hospitalDate?: InputMaybe<Scalars['LocalDate']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastName?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<MedigapEnrollmentAddressInput>;
  medicalDate?: InputMaybe<Scalars['LocalDate']>;
  medicareNumber?: InputMaybe<Scalars['String']>;
  medigapQuoteId?: InputMaybe<Scalars['UUID']>;
  naic: Scalars['String'];
  permanentAddress?: InputMaybe<MedigapEnrollmentAddressInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  planName: MedigapEnrollmentPlanName;
  preferredLanguage?: InputMaybe<MedigapEnrollmentPreferredLanguage>;
  tobacco: Scalars['Boolean'];
};

export type PageInput = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type PurchasedProductInput = {
  clientId: Scalars['UUID'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isPrimaryPerson: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  type: CoverRightProductDto;
};

export type ScopeOfAppointmentAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['UUID']>;
};

export type ScopeOfAppointmentUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['UUID']>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type TagInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type UserProfileFilterInput = {
  endDate?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type UserProfileInput = {
  aptNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  form?: Maybe<FormName>;
  gender?: Maybe<Scalars['String']>;
  hasNoPreferredDoctors?: Maybe<Scalars['Boolean']>;
  hasNoPreferredPharmacies?: Maybe<Scalars['Boolean']>;
  hasNoPrescriptionsDrugs?: Maybe<Scalars['Boolean']>;
  insuranceType?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  medigapQuoteId?: Maybe<Scalars['UUID']>;
  options?: Maybe<Array<ProfileOption>>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
  quoteId?: Maybe<Scalars['UUID']>;
  source?: Maybe<ProfileSource>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatusDto>;
  streetName?: Maybe<Scalars['String']>;
  tobacco?: Maybe<Scalars['Boolean']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};
