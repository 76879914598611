import React from 'react';
import PlanList from "./PlanList";
import {Box, Grid} from "@material-ui/core";
import PlanListSideContent from "./PlanListSideContent";
import LoginWidget from "./LoginWidget";
import {QuoteContext} from "./QuoteContext";
import Layout from "../../../shared/Layout";
import {withPlanFilterProvider} from "./PlanFilterContext";
import ComparisonPanel from "../PlanComparison/ComparisonPanel";
import {useParams} from "react-router";
import {config} from "../config/config";

const DashboardLayout = () => {
  const {pin} = React.useContext(QuoteContext);
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();

  return <>
    <Layout pin={pin} sideHeaderContent={<></>} SideComponent={PlanListSideContent}>
      <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
          <PlanList />
      </Box>
    </Layout>
    {zip && <ComparisonPanel countyName={countyName} zip={zip} />}
  </>
}


export default withPlanFilterProvider(DashboardLayout);
