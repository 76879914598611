import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step10";
import {QuoteContext} from "../QuoteContextProvider";

function Step10(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(QuoteContext);

  return <Shared quote={quote} dispatch={dispatch}  {...props} />;
}

export default Step10 as any;
