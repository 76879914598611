import React from "react";
import {Box, createStyles, Grid, RadioGroup, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import AscendRadio from "../../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../../shared/useCommonStyles";
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import paths from "../../config/router-paths";
import {withTitle} from "../../../shared/components/withTitle";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import {colors} from "../../../shared/AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: 'white',
      borderRadius: 4,
      boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.04)',
      marginBottom: 16,
      width: '100%',
      cursor: 'pointer',
      color: colors.text.primary,
      '& .i-white': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: colors.custom.green.variant2,
        color: 'white',
        '& .i-white': {
          display: 'block',
        },
        '& .i-green': {
          display: 'none',
        },
      }
    }
  })
);
const formName = 'STEP_8';

type ContactMethod = 'email' | 'phone';

function Step8(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [contactMethod, setContactMethod] = React.useState<ContactMethod>(state.STEP_8?.value?.value);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const submit = (value: ContactMethod) => {
    setContactMethod(value);
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, value: {
          questionText: 'How do you prefer to be contacted?',
          answerText: value,
          value
        }, form: formName}
    })
    const route = state?.QUIZ_3?.medicarePlanType?.value === 'MA' ? paths.MAquestionnaire : paths.MGquestionnaire;
    props.history.push(route + '/' + questionsRoutes.step9)
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'} mb={1}>
      <div className={classes.card} onClick={() => submit('email')}>
        <Box display={'flex'} alignItems={'center'} padding={'23px 24px 23px 0'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minWidth={'110px'} ml={'20px'}>
            <img className={'i-green'} src={'/img/big-envelope.svg'} />
            <img className={'i-white'} src={'/img/big-envelope-white.svg'} />
          </Box>
          <Typography className={'fs-24 bold'}>Contact me by email</Typography>
        </Box>
      </div>
      <div className={classes.card} onClick={() => submit('phone')}>
        <Box display={'flex'} alignItems={'center'} padding={'23px 24px 23px 0'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minWidth={'110px'} ml={'20px'}>
            <img className={'i-green'} src={'/img/big-phone.svg'} />
            <img className={'i-white'} src={'/img/big-phone-white.svg'} />
          </Box>
          <div>
            <Typography className={'fs-24 bold'}>Speak on the phone</Typography>
          </div>
        </Box>
      </div>
      <Typography color={'textSecondary'} className={'mt-20 bold text-align-centered fs-18'}>Don’t worry, we’ll never send you spam.</Typography>
    </Box>
  </>
}

export default withTitle(
  Step8,
  'How do you prefer to be contacted?',
  ``,
  undefined,
  undefined,
  isMobile => isMobile,
)
