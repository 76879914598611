import React, {PropsWithChildren} from "react";
import Layout from "../../../shared/Layout";
import {ComparisonContext} from "./ComparisonContext";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  Theme,
  Typography, useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import AscendCard from "../../../shared/components/AscendCard";
import {planStyles, RoundedButton} from "../PlanList/PlanCard";
import {makeStyles} from "@material-ui/core/styles";
import usePlansByBid from "../DetailedPlan/hooks/usePlansByBid";
import {RouteComponentProps, useHistory} from "react-router";
import PlanDetailsContent from "../DetailedPlan/PlanDetailsContent";
import CompareHeaderCard from "./components/CompareHeaderCard";
import {colors} from "../../../shared/AppTheme";
import {AscendSubtitle} from "../DetailedPlan/components/AscendSubtitle";
import {
  DrugTier,
  DrugTierCoverageOutput,
  DrugTierOutput,
  DrugTiersOutput,
  PharmacyTier,
  Plan,
  PlanYear
} from "../../../types";
import {ListPlanDetail, ListPlanDetailText} from "../DetailedPlan/components/ListPlanDetail";
import {DrugCoverageList} from "../DetailedPlan/components/DrugCoverageList";
import {getQuoteId} from "../../../shared/QuoteId";
import {DoctorCoverageList} from "../DetailedPlan/components/DoctorCoverageList";
import clsx from "clsx";
import {useScrollPosition} from "../../../shared/useScrollPosition";
import paths from "../config/router-paths";
import Benefit from "../../../shared/components/Benefit";

import * as Scroll from "react-scroll";
import {PlanWithTiers} from "../DetailedPlan/hooks/usePlan";
import Footer from "../shared/Footer";
import planFiles from "../shared/planFiles";
import TooltipedTitle from "../PlanList/components/TooltipedTitle";
import {getGapTooltipByTier, getInitialTooltipByTier, PlanView} from "../DetailedPlan/DetailedPlan";
import {QuoteContext} from "../PlanList/QuoteContext";
import PlanSummaryModal from "../PlanList/PlanSummaryModal";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import SwipeableViews from 'react-swipeable-views';
import BackButton from "../shared/BackButton";
import LoginWidget from "../PlanList/LoginWidget";
import {config} from "../config/config";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

const Link = Scroll.Link;
var Element = Scroll.Element;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: 0,
      background: "none"
    },
    bold: {
      fontWeight: 500
    },
    italic: {
      fontStyle: "italic",
    },
    header: {
      position: "sticky",
      top: 43,
      height: 275,
      zIndex: 5,
      background: "white",
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    titleTable: {
    },
    table: {
      tableLayout: "fixed",
      width: "100%",
      background: "white",
      borderCollapse: "collapse",
      "& tr": {
        borderBottom: "1px solid #E8ECED",
        borderTop: "1px solid #E8ECED",
        "& td": {
          padding: 20,
          verticalAlign: "top",
          height: 1,
          minHeight: 1,
        },
        "& td:not(:last-child)": {
          // borderRight: "1px solid #E8ECED",
        },
        "& td.border:not(:last-child)": {
          borderRight: "1px solid #E8ECED",
        },
        "& td:last-child": {
          background: "rgba(2, 144, 148, 0.05)"
        }
      }
    },
    dataTable: {
      marginLeft: 20,
      marginRight: 20,
      width: "calc(100% - 40px)",
      "& tr": {
        "& td:first-child": {
          padding: '20px 20px 20px 12px',
        },
        "& td:last-child": {
          padding: '20px 20px 20px 20px',
        },
      }

    },
    buttonsTable: {tableLayout: "fixed",
      width: "100%",
      background: "white",
      borderCollapse: "collapse",
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "25px"
    },
    iconButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-start",
      "& div": {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    summaryButton: {
      padding: "8px 21px",
      borderRadius: 8,
      "& .MuiButton-label": {
        fontSize: "12px !important",
      }
    },
    sectionTop: {
      background: "white",
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      padding: "24px 0 0px",
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    sectionBottom: {
      background: "white",
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      height: 24,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    sectionMiddle: {
      background: "white",
      padding: "16px 0",
    },
    h3Title: {
      background: "rgba(94, 203, 158, 0.1)!important"
    },
    mobileSelectorWrapper: {
      position: 'fixed',
      width: '100%',
      left: 0,
      height: 70,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: colors.custom.green.variant2,
      color: 'white',
      zIndex: 10,
    },
    icon: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(90deg) !important',
    },
    button: {
      background: 'white',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      '&:hover': {
        background: 'white',
      }
    },
    dot: {
      width: 10,
      height: 10,
      border: '1px solid #A4B4B9',
      borderRadius: 5,
      marginLeft: 7,
      marginRight: 7,
    },
    dotActive: {
      background: '#A4B4B9',
    },
    backButtonWrapper: {
      position: 'sticky',
      top: 0,
      background: '#f5f7f6',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        width: '91%',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      }
    }
  }),
);

interface ViewProps {
  zip: string,
  countyName: string,
  plans: PlanWithTiers[],
  setPlanId: (value?: string) => void
  setModalOpen: (value: boolean) => void
}

export default function PlanComparison(props: RouteComponentProps<{ zip: string, countyName: string }>) {
  let {zip, countyName} = props.match.params;
  const {list} = React.useContext(ComparisonContext);
  const {pin} = React.useContext(QuoteContext);
  const plans = usePlansByBid(zip, countyName, list, getQuoteId(), GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023);
  const [planId, setPlanId] = React.useState();
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const classes = styles();

  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('MA marketplace - view plans compare details', {source: 'V1', plans: list});
    }
  }, []);

  if (!plans) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <AscendPreloader />
      <Footer/>
    </Box>
  }

  return <Layout pin={pin} sideHeaderContent={config.enableAuth === 'true' ? <LoginWidget/> : <></>} SideComponent={() => <PlanDetailsContent plan={plans[0]}/>}>
    <Box position={'relative'}>
      <Box className={classes.backButtonWrapper}>
        <div>
          <BackButton
            startIcon={<img src={"/img/arrow-left.svg"}/>}
            onClick={() => history.push(`/plans/${zip}/${countyName}`)}>
            Back to Plans
          </BackButton>
        </div>
      </Box>
      <PlanSummaryModal zip={zip} planId={planId} countyName={countyName} open={modalOpen} onClose={() => {setPlanId(undefined); setModalOpen(false)}} />
      {!!plans && <>
      {phone ?
        <Mobile zip={zip} countyName={countyName} plans={plans} setModalOpen={setModalOpen} setPlanId={setPlanId} /> :
        <Desktop zip={zip} countyName={countyName} plans={plans} setModalOpen={setModalOpen} setPlanId={setPlanId} />}
        </>}
      <Box display={"flex"} flex={1} justifyContent={"center"}>
        <Footer/>
      </Box>
    </Box>
  </Layout>;
}

function Mobile(props: ViewProps) {
  const classes = styles();
  const [index, setIndex] = React.useState(0);

  const handleChangeIndex = (i: number) => {
    setIndex(i);
  };

  const plan = React.useMemo(() => {
      return props.plans[index];
  }, [index, props.plans]);

  return <>
    <style>
      {'#fc_frame{bottom: 70px!important}'}
    </style>
    <div className={'h-15'} />
    {!!plan && <PlanView
      key={plan.bidId}
      zip={props.zip}
      countyName={props.countyName}
      plan={plan}
      setModalOpen={v => {
        props.setPlanId(plan.bidId);
        props.setModalOpen(v);
      }}
      setEmailOpen={() => {}}
    />}
    {/*<SwipeableViews
      axis={'x'}
      index={index}
      onChangeIndex={handleChangeIndex}
    >
      {props.plans.map(plan => <PlanView
        key={plan.bidId}
        zip={props.zip}
        countyName={props.countyName}
        plan={plan}
        setModalOpen={v => {
          props.setPlanId(plan.bidId);
          props.setModalOpen(v);
        }}
        setEmailOpen={() => {}}
      />)}
    </SwipeableViews>*/}
    <div className={classes.mobileSelectorWrapper}>
      <Box display={'flex'} flex={1} alignItems={'center'}>
        {index > 0 && <Box display={'flex'} alignItems={'center'} onClick={() => setIndex(index - 1)}>
          <IconButton className={classes.button}>
            <img className={clsx(classes.icon, classes.iconOpen)} src={'/img/arrow-green-2.svg'} />
          </IconButton>
          <div className={'w-16'} />
          <Typography className={'bold fs-12'}>{props.plans[index - 1].planName}</Typography>
        </Box>}
      </Box>
      <Box display={'flex'} alignItems={'flex-end'} height={'100%'} pb={'3px'}>
        {props.plans.map((p, i) => <div className={clsx({[classes.dot]: true, [classes.dotActive]: index === i})} />)}
      </Box>
      <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'flex-end'}>
        {index < props.plans.length - 1 && <Box display={'flex'} alignItems={'center'} onClick={() => setIndex(index + 1)}>
          <Typography align={'right'} className={'bold fs-12'}>{props.plans[index + 1].planName}</Typography>
          <div className={'w-16'} />
          <IconButton className={classes.button}>
            <img className={clsx(classes.icon)} src={'/img/arrow-green-2.svg'} />
          </IconButton>
        </Box>}
      </Box>
    </div>
  </>
}


function Desktop(props: ViewProps) {
  const classes = styles();
  let {zip, countyName, plans, setPlanId, setModalOpen} = props;
  const history = useHistory();
  const planClasses = planStyles();
  const {preferredDrugs} = React.useContext(QuoteContext);
  const [expandedState, setExpandedState] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getValuesByKey = React.useCallback((key: keyof PlanWithTiers, lastValue?: any, mapFunction?: (value: any, plan: PlanWithTiers) => any) => {
    let result: any[];
    if (!plans.length) {
      result = [""]
    } else {
      result = plans.map(p => p[key]);
    }
    if (mapFunction) {
      result = result.map((v, index) => mapFunction(v, plans[index]));
    }
    if (lastValue) {
      result.push(lastValue)
    }
    return result;
  }, [plans])

  const catastrophicCoverage: DrugTier[] = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTier[], plan: Plan) => {
      if (plan?.drugDetails?.catastrophicCoverage[0].items?.length && plan?.drugDetails?.catastrophicCoverage[0].items?.length > previousValue.length) {
        return plan?.drugDetails?.catastrophicCoverage[0].items;
      }
      return previousValue;
    }, [] as DrugTier[]);
  }, [plans])

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === "undefined" ? true : expandedState[key];

  const preferred30 = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
      if ((plan?.drugsTiers30Preferred?.initialCoverageDrugTiers?.length || 0) > previousValue.length) {
        return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
      }
      return previousValue;
    }, [] as DrugTierCoverageOutput[]);
  }, [plans]);

  const preferred30Gap = React.useMemo(() => {
    return preferred30.filter((tier, i) => plans.map(p => p.drugsTiers30Preferred?.coverageGapDrugTiers[i]?.coverage).some(v => !!v && v !== '25% coinsurance'))
  }, [preferred30]);

  const standard30 = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
      if ((plan?.drugsTiers30Standard?.initialCoverageDrugTiers?.length || 0) > previousValue.length) {
        return plan?.drugsTiers30Standard?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
      }
      return previousValue;
    }, [] as DrugTierCoverageOutput[]);
  }, [plans]);

  const standard30Gap = React.useMemo(() => {
    return standard30.filter((tier, i) => plans.map(p => p.drugsTiers30Standard?.coverageGapDrugTiers[i]?.coverage).some(v => !!v && v !== '25% coinsurance'))
  }, [standard30]);

  const preferred90 = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
      if ((plan?.drugsTiers90Preferred?.initialCoverageDrugTiers?.length || 0) > previousValue.length) {
        return plan?.drugsTiers90Preferred?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
      }
      return previousValue;
    }, [] as DrugTierCoverageOutput[]);
  }, [plans]);

  const preferred90Gap = React.useMemo(() => {
    return preferred90.filter((tier, i) => plans.map(p => p.drugsTiers90Preferred?.coverageGapDrugTiers[i]?.coverage).some(v => !!v && v !== '25% coinsurance'))
  }, [preferred90]);

  const standard90 = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
      if ((plan?.drugsTiers90Standard?.initialCoverageDrugTiers?.length || 0) > previousValue.length) {
        return plan?.drugsTiers90Standard?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
      }
      return previousValue;
    }, [] as DrugTierCoverageOutput[]);
  }, [plans]);

  const standard90Gap = React.useMemo(() => {
    return standard90.filter((tier, i) => plans.map(p => p.drugsTiers90Standard?.coverageGapDrugTiers[i]?.coverage).some(v => !!v && v !== '25% coinsurance'))
  }, [standard90]);

  const gapTiers = React.useMemo(() => {
    console.log([preferred30Gap, standard90Gap, preferred90Gap, standard30Gap]);
      return [preferred30Gap, standard90Gap, preferred90Gap, standard30Gap].reduce((previousValue, currentValue) => {
        if (currentValue.length > previousValue.length) {
          return currentValue;
        }
        return previousValue;
      }, [])
  }, [preferred30Gap, standard90Gap, preferred90Gap, standard30Gap]);

  React.useEffect(() => {
      console.log(gapTiers);
  }, [gapTiers]);

  const allDSNP = React.useMemo(() => {
      return plans.every(p => p.SNPType === 'D_SNP')
  }, [plans]);

  return <Grid container justify={"center"} spacing={0} alignItems={"center"} className={'mt-15'}>
      <Grid item xs={12} xl={11}>
        <AscendCard className={classes.card}>
          <table className={clsx(classes.table, classes.header)}>
            <tbody>
            <tr>
              <td className={'border'}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} minWidth={"150px"}
                     minHeight={"175px"}>
                  <Typography color={"textPrimary"} className={classes.title}>Compare plans</Typography>
                  <div>
                    {/*<div className={classes.iconButton}>
                        <img src={'/img/printer.svg'}/>
                        <div>Print</div>
                      </div>*/}
                    <div className={"h-12"}/>
                    <div className={classes.iconButton}>
                      <img src={"/img/message.svg"}/>
                      <div>Email me this comparison</div>
                    </div>
                  </div>
                </Box>
              </td>
              {!!plans.length && plans.map(plan => <td className={'border'} key={plan.bidId}><CompareHeaderCard plan={plan}/></td>)}
              {!plans.length && <td></td>}
              <td>
                <Box p={"10px"}>
                  <Typography color={"textPrimary"} className={classes.title}>Original Medicare Part A/Part B</Typography>
                </Box>
              </td>
            </tr>
            </tbody>
          </table>

          <div className={"h-23"}/>

          <Element name={"Key Plan Benefits Overview"}>
            <BlockTitle title={"Key Plan Benefits Overview"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Estimated Monthly Cost Comparison"}
                icon={"/img/expenses.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, monthlyCost: value}))}
                expanded={getExpandedValue("monthlyCost")}
              />
            </div>
            <Collapse in={getExpandedValue("monthlyCost")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <PlanDetailRow title={"Monthly plan premium"} thin
                               tooltip={"This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium."}
                               values={getValuesByKey("monthlyPremium", <div>
                                 <Typography variant={"h5"}>Part A: Typically $0.00</Typography>
                                 <Typography variant={"h5"}>Part B: Typically {'$170.10'}</Typography></div>)}/>
                <PlanDetailRow title={"Estimated drug costs"} thin
                               tooltip={"Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information."}
                               values={getValuesByKey("drugsCost", "Not covered")}/>
                <PlanDetailRow title={"Estimated doctor costs"} thin
                               tooltip={"Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers."}
                               values={getValuesByKey("doctorsCost", "Not covered")}/>
                <PlanDetailRow className={classes.bold}
                               tooltip={"This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs."}
                               title={"Total estimated monthly cost"}
                               values={getValuesByKey("monthlyCost", "Not covered")}/>
                <PlanDetailRow tooltip={"The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you're enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income."}
                               title={<Typography className={'italic'} variant={"h5"}><b>plus Medicare Part B Premium</b><br />This is the standard Part B premium and may vary for some people.</Typography>}
                               values={getValuesByKey("monthlyCost", "Not covered", v => '$170.10')}/>
                </tbody>
              </table>
              <div className={classes.sectionMiddle}>
                {!!preferredDrugs?.preferredDrugs?.length && <table className={classes.buttonsTable}>
                  <tbody>
                    <tr>
                      <td></td>
                      {!!plans.length && plans.map(plan => <td key={plan.bidId}>
                        {!plan.missingFormularyInAep && <RoundedButton variant={"contained"} onClick={() => {
                          setPlanId(plan.bidId);
                          setModalOpen(true);
                        }}>See monthly drug cost</RoundedButton>}
                      </td>)}
                      <td></td>
                    </tr>
                  </tbody>
                </table>}
              </div>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Your Healthcare Selections"}
                icon={"/img/healthcare.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, healthcare: value}))}
                expanded={getExpandedValue("healthcare")}
              />
            </div>
            <Collapse in={getExpandedValue("healthcare")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <PlanDetailRow title={<>
                  <Typography variant={"h5"} color={"textPrimary"} className={"bold"}>Your doctors</Typography>
                  <div className={"h-10"}/>
                  <RoundedButton className={'w-130'} variant={"contained"} onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                    {getValuesByKey('doctorsCoverage', 0, val => val?.length)[0] ? 'Edit' : 'Add'} doctors
                  </RoundedButton>
                </>}
                               values={getValuesByKey("doctorsCoverage", "Varies by doctor",
                                 (v, plan) => <DoctorCoverageList isPPO={plan?.planType === 'PPO'} coverage={v}/>)}/>
                <PlanDetailRow title={<>
                  <Typography variant={"h5"} color={"textPrimary"} className={"bold"}>Your drugs</Typography>
                  <div className={"h-10"}/>
                  <RoundedButton className={'w-130'} variant={"contained"} onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                    {getValuesByKey('drugsCoverage', 0, val => val?.length)[0] ? 'Edit' : 'Add'} drugs
                  </RoundedButton>
                </>}
                               values={getValuesByKey("drugsCoverage", "Not covered",
                                 (value, plan) => plan?.missingFormularyInAep ?
                                 <Typography className={'fs-12 card-title'} color={'textPrimary'}>Speak to an Agent for 2022 drug details for this plan</Typography> :
                                 <DrugCoverageList coverage={value}/>)}/>
                <PlanDetailRow title={<>
                  <Typography variant={"h5"} color={"textPrimary"} className={"bold"}>Your pharmacy</Typography>
                  <div className={"h-10"}/>
                  <RoundedButton className={'w-130'} variant={"contained"} onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                    {getValuesByKey('quoteDrugsTiers', 0, val => val?.pharmacyName)[0] ? 'Edit' : 'Add'} pharmacy
                  </RoundedButton>
                </>}
                               values={getValuesByKey("quoteDrugsTiers", "Not covered",
                                 value => value?.pharmacyName ? <Benefit label={value?.pharmacyName} value={true}
                                                                         labelClass={planClasses.pharmacyLabelClass}/> : null)}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Plan Highlights"}
                icon={"/img/medical-record-1.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, overview: value}))}
                expanded={getExpandedValue("overview")}
              />
            </div>
            <Collapse in={getExpandedValue("overview")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <PlanDetailRow title={"Plan Type"}
                               tooltip={"Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan."}
                               values={getValuesByKey("planType", "Original Medicare")}/>
                <PlanDetailRow title={"Monthly premium"}
                               tooltip={"This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium."}
                               values={getValuesByKey("monthlyPremium", <div>
                                 <Typography variant={"h5"}>Part A: Typically $0.00</Typography>
                                 <Typography variant={"h5"}>Part B: Typically {'$170.10'}</Typography></div>)}/>
                <PlanDetailRow title={"Part B Giveback"}
                               tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                               values={getValuesByKey("partBAmount", "No", value => <Benefit labelClass={'thin'} label={!!value ? `${value} per month` : 'No'} value={!!value} />)}/>
                <PlanDetailRow title={"Annual in-network deductible"}
                               tooltip={"The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. "}
                               values={getValuesByKey(
                                 "inNetworkDeductableAmount",
                                 <div><Typography variant={"h5"}>Part A: {'$1,556'}</Typography><Typography variant={"h5"}>Part
                                   B: {'$233'}</Typography></div>,
                                 value => plans.length ? value || "This plan does not have a deductible." : "")}/>
                <MultilinePlanDetailRow title={"Out-of-Pocket Maximum"}
                                        tooltip={"The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan."}
                                        values={getValuesByKey("outOfPocketMaximum", "No Limit")}/>
                <MultilinePlanDetailRow title={"Primary Doctor Visit"}
                                        tooltip={"The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details."}
                                        values={getValuesByKey("primaryCarePhysician", "20% of the cost")}/>
                <MultilinePlanDetailRow title={"Specialist Visit"}
                                        tooltip={"The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details."}
                                        values={getValuesByKey("physicianSpecialist", "20% of the cost")}/>
                <MultilinePlanDetailRow title={"Inpatient Hospital Care"}
                                        tooltip={"Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies."}
                                        values={getValuesByKey("hospitalBenefits",
                                          <Typography variant={"h5"} color={'textPrimary'}>{'$1,556'} deductible for each benefit period<br />
                                            Days 1-60: $0 coinsurance for each benefit period<br />
                                            Days 61-90: {'$389'} coinsurance per day of each benefit period<br />
                                            Days 91 and beyond: {'$778'} coinsurance per each "lifetime reserve day" after day 90 for each benefit period (up to 60 days over your lifetime)<br />
                                            Beyond lifetime reserve days: all costs</Typography>,
                                          value => value || "N/A")}/>
                <PlanDetailRow title={"Prescription Drug Coverage"}
                               tooltip={"Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans."}
                               values={getValuesByKey("extraBenefits", "Not covered",
                                 val => !!val.drugCoverage ? 'Yes' : 'No' )}/>

                <MultilinePlanDetailRow title={"Prescription Drug Deductible"}
                                        tooltip={"The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. "}
                                        values={getValuesByKey("drugDetails", "Not applicable",
                                          val => val?.mrxAltDedAmount || val?.mrxAltNoDedTier ? [val?.mrxAltDedAmount || '', val?.mrxAltNoDedTier || ''] : "No deductible"
                                        )}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. "}
                                          title={"Dental Services"}
                                          values={getValuesByKey("dental", "Limited")}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses."}
                                          title={"Vision Services"}
                                          values={getValuesByKey("vision", "Limited")}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids."}
                                          title={"Hearing Services"}
                                          values={getValuesByKey("hearing", "Limited – covers diagnostic hearing and balance exams if doctor or other health care provider if you need medical treatment. Does not cover hearing aids or exams for fitting hearing aids.")}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs."}
                                          title={"Fitness Benefits"}
                                          values={getValuesByKey("extraBenefits", "Not covered", val => val.fitness)}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"} title={"Insulin Savings"}
                                          tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                          values={getValuesByKey("extraBenefits", "Not covered", val => val.insulinSavings)}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"} title={"Over-the-counter Allowance"}
                                          tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                          values={getValuesByKey("oTC", "Not covered")}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers."}
                                          title={"Telehealth Services"}
                                          values={getValuesByKey("extraBenefits", "20% of the cost after the Part B deductible", val => val.telehealth)}/>
                <ServiceCoverageDetailRow scrollTo={"Extra Benefits"}
                                          tooltip={"Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor's appointments, pharmacies, or fitness centers."}
                                          title={"Transportation Services"}
                                          values={getValuesByKey("transportation", "Not covered")}/>
                </tbody>
              </table>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-23"}/>

          <Element name={"Drug Coverage & Costs"}>
            <BlockTitle title={"Drug Coverage & Costs"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Prescription Drug Costs"}
                icon={"/img/pills.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, prescriptionDrugCoverage: value}))}
                expanded={getExpandedValue("prescriptionDrugCoverage")}
              />
            </div>

            <Collapse in={getExpandedValue("prescriptionDrugCoverage")}>
              <SectionTitleH2 title={"Deductible phase"}
                              tableClassName={"fill-width pb-16"}
                              subtitle={"You pay the full retail price of your drugs up until you meet the deductible level (if applicable)."}
                              onToggle={value => setExpandedState(prevState => ({
                                ...prevState,
                                prescriptionDrugDeductible: value
                              }))}
                              expanded={getExpandedValue("prescriptionDrugDeductible")}
              />
              <Collapse in={getExpandedValue("prescriptionDrugDeductible")} className={'bg-white'}>
                <table className={clsx(classes.table, classes.dataTable)}>
                  <tbody>
                  <MultilinePlanDetailRow title={"Deductible amount"}
                                          values={getValuesByKey("drugDetails", "Not applicable",
                                            val => val?.mrxAltDedAmount && val?.mrxAltNoDedTier ? [val?.mrxAltDedAmount, val?.mrxAltNoDedTier] : "$0"
                                          )}/>
                  </tbody>
                </table>
              </Collapse>
              <SectionTitleH2 title={"Initial Coverage phase"}
                              tableClassName={"fill-width pt-16"}
                              subtitle={"Initial coverage starts after your deductible (if applicable) up until total drug costs paid by you and than plan reach $4,130."}
                              onToggle={value => setExpandedState(prevState => ({
                                ...prevState,
                                coverageLimitPhase: value
                              }))}
                              expanded={getExpandedValue("coverageLimitPhase")}
              />
              <Collapse in={getExpandedValue("coverageLimitPhase")}>
                {allDSNP && <>
                  <Collapse in={true} className={'bg-white'}>
                    <table className={clsx(classes.table, classes.dataTable)}>
                      <tbody>
                      {preferred30.map((tier, i) =>
                        <MultilinePlanDetailRow title={tier.tierName}
                                                key={tier.tierName}
                                                tooltip={getInitialTooltipByTier(tier.tier)}
                                                values={getValuesByKey("drugsTiers30Preferred", "Not covered",
                                                  val => 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits')}

                        />)}
                      </tbody>
                    </table>
                  </Collapse>
                </>}
                {!allDSNP && <>
                  <SectionTitleH2 title={"Retail pharmacy"}
                                  tableClassName={"fill-width pv-16"}
                                  wrapperClassName={classes.h3Title}
                                  onToggle={value => setExpandedState(prevState => ({
                                    ...prevState,
                                    retailPharmacy: value
                                  }))}
                                  expanded={getExpandedValue("retailPharmacy")}
                  />
                  <Collapse in={getExpandedValue("retailPharmacy")}>
                    {preferred30 && <SectionTitleH3 title={"Preferred retail cost-sharing (30 day supply)"}
                                                    tableClassName={"fill-width pb-8"}
                                                    onToggle={value => setExpandedState(prevState => ({
                                                      ...prevState,
                                                      preferredRetail30: value
                                                    }))}
                                                    expanded={getExpandedValue("preferredRetail30")}
                    />}
                    <Collapse in={getExpandedValue("preferredRetail30")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {preferred30.map((tier, i) =>
                          <MultilinePlanDetailRow title={tier.tierName}
                                                  key={tier.tierName}
                                                  tooltip={getInitialTooltipByTier(tier.tier)}
                                                  values={getValuesByKey("drugsTiers30Preferred", "Not covered",
                                                    (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'))}

                          />)}
                        </tbody>
                      </table>
                    </Collapse>
                    {standard30 && <SectionTitleH3 title={"Standard retail cost-sharing (30 day supply)"}
                                                   tableClassName={"fill-width pv-8"}
                                                   onToggle={value => setExpandedState(prevState => ({
                                                     ...prevState,
                                                     standardRetail30: value
                                                   }))}
                                                   expanded={getExpandedValue("standardRetail30")}
                    />}
                    <Collapse in={getExpandedValue("standardRetail30")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {standard30.map((tier, i) =>
                          <MultilinePlanDetailRow title={tier.tierName}
                                                  key={tier.tierName}
                                                  tooltip={getInitialTooltipByTier(tier.tier)}
                                                  values={getValuesByKey("drugsTiers30Standard", "Not covered",
                                                    (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'))}

                          />)}
                        </tbody>
                      </table>
                    </Collapse>
                  </Collapse>

                  <SectionTitleH2 title={"Mail-order pharmacy"}
                                  tableClassName={"fill-width pv-16"}
                                  wrapperClassName={classes.h3Title}
                                  onToggle={value => setExpandedState(prevState => ({...prevState, mailPharmacy: value}))}
                                  expanded={getExpandedValue("mailPharmacy")}
                  />
                  <Collapse in={getExpandedValue("mailPharmacy")}>
                    {preferred90 && <SectionTitleH3 title={"Preferred mail-order cost-sharing (90 day supply)"}
                                                    tableClassName={"fill-width pb-8"}
                                                    onToggle={value => setExpandedState(prevState => ({
                                                      ...prevState,
                                                      preferredMail90: value
                                                    }))}
                                                    expanded={getExpandedValue("preferredMail90")}
                    />}
                    <Collapse in={getExpandedValue("preferredMail90")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {preferred90.map((tier, i) =>
                          <MultilinePlanDetailRow title={tier.tierName}
                                                  key={tier.tierName}
                                                  tooltip={getInitialTooltipByTier(tier.tier)}
                                                  values={getValuesByKey("drugsTiers90Preferred", "Not covered",
                                                    (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'))}

                          />)}
                        </tbody>
                      </table>
                    </Collapse>
                    {standard90 && <SectionTitleH3 title={"Standard mail-order cost-sharing (90 day supply)"}
                                                   tableClassName={"fill-width pv-8"}
                                                   onToggle={value => setExpandedState(prevState => ({
                                                     ...prevState,
                                                     standardMail90: value
                                                   }))}
                                                   expanded={getExpandedValue("standardMail90")}
                    />}
                    <Collapse in={getExpandedValue("standardMail90")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {standard90.map((tier, i) =>
                          <MultilinePlanDetailRow title={tier.tierName}
                                                  key={tier.tierName}
                                                  tooltip={getInitialTooltipByTier(tier.tier)}
                                                  values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                    (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'))}

                          />)}
                        </tbody>
                      </table>
                    </Collapse>
                  </Collapse>
                </>}
              </Collapse>
              <SectionTitleH2 title={"Coverage Gap phase"}
                              subtitle={"The Coverage Gap starts after the total drug costs paid by you and the plan reach $4,130, up to the out-of-pocket maximum of $6,550."}
                              tableClassName={"fill-width pv-16"}
                              onToggle={value => setExpandedState(prevState => ({
                                ...prevState,
                                coverageGapPhase: value
                              }))}
                              expanded={getExpandedValue("coverageGapPhase")}
              />
              <Collapse in={getExpandedValue("coverageGapPhase")} className={'bg-white'}>
                {allDSNP && <>
                  <Collapse in={true} className={'bg-white'}>
                    <table className={clsx(classes.table, classes.dataTable)}>
                      <tbody>
                      {gapTiers.map((tier, i) =>
                        <MultilinePlanDetailRow title={tier.tierName}
                                                key={tier.tierName}
                                                tooltip={getInitialTooltipByTier(tier.tier)}
                                                values={getValuesByKey("drugsTiers30Preferred", "Not covered",
                                                  val => 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits')}

                        />)}
                      <MultilinePlanDetailRow title={`All ${gapTiers.length ? 'other' : 'drug'} tiers`}
                                              tooltip={''}
                                              values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                val => 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits')}

                      />
                      </tbody>
                    </table>
                  </Collapse>
                </>}

                {!allDSNP && <>

                  <SectionTitleH2 title={"Retail pharmacy"}
                                  tableClassName={"fill-width pv-16"}
                                  wrapperClassName={classes.h3Title}
                                  onToggle={value => setExpandedState(prevState => ({
                                    ...prevState,
                                    retailPharmacyGap: value
                                  }))}
                                  expanded={getExpandedValue("retailPharmacyGap")}
                  />
                  <Collapse in={getExpandedValue("retailPharmacyGap")}>
                    {preferred30 && <SectionTitleH3 title={"Preferred retail cost-sharing (30 day supply)"}
                                                    tableClassName={"fill-width pb-8"}
                                                    onToggle={value => setExpandedState(prevState => ({
                                                      ...prevState,
                                                      preferredRetailGap30: value
                                                    }))}
                                                    expanded={getExpandedValue("preferredRetailGap30")}
                    />}
                    <Collapse in={getExpandedValue("preferredRetailGap30")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {preferred30Gap
                          .map((tier, i) =>
                            <MultilinePlanDetailRow title={tier.tierName}
                                                    key={tier.tierName}
                                                    tooltip={getInitialTooltipByTier(tier.tier)}
                                                    values={getValuesByKey("drugsTiers30Preferred", "Not covered",
                                                      (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.coverageGapDrugTiers[i]?.coverage || !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'))}

                            />)}
                        <MultilinePlanDetailRow title={`All ${preferred30Gap.length ? 'other' : 'drug'} tiers`}
                                                tooltip={''}
                                                values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                  (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance')}

                        />
                        </tbody>
                      </table>
                    </Collapse>
                    {standard30 && <SectionTitleH3 title={"Standard retail cost-sharing (30 day supply)"}
                                                   tableClassName={"fill-width pv-8"}
                                                   onToggle={value => setExpandedState(prevState => ({
                                                     ...prevState,
                                                     standardRetailGap30: value
                                                   }))}
                                                   expanded={getExpandedValue("standardRetailGap30")}
                    />}
                    <Collapse in={getExpandedValue("standardRetailGap30")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {standard30Gap
                          .map((tier, i) =>
                            <MultilinePlanDetailRow title={tier.tierName}
                                                    key={tier.tierName}
                                                    tooltip={getInitialTooltipByTier(tier.tier)}
                                                    values={getValuesByKey("drugsTiers30Standard", "Not covered",
                                                      (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.coverageGapDrugTiers[i]?.coverage || !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'))}

                            />)}
                        <MultilinePlanDetailRow title={`All ${standard30Gap.length ? 'other' : 'drug'} tiers`}
                                                tooltip={''}
                                                values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                  (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance')}

                        />
                        </tbody>
                      </table>
                    </Collapse>
                  </Collapse>
                  <SectionTitleH2 title={"Mail-order pharmacy"}
                                  tableClassName={"fill-width pv-16"}
                                  wrapperClassName={classes.h3Title}
                                  onToggle={value => setExpandedState(prevState => ({...prevState, mailPharmacyGap: value}))}
                                  expanded={getExpandedValue("mailPharmacyGap")}
                  />
                  <Collapse in={getExpandedValue("mailPharmacyGap")}>
                    {preferred90 && <SectionTitleH3 title={"Preferred mail-order cost-sharing (90 day supply)"}
                                                    tableClassName={"fill-width pb-8"}
                                                    onToggle={value => setExpandedState(prevState => ({
                                                      ...prevState,
                                                      preferredMailGap90: value
                                                    }))}
                                                    expanded={getExpandedValue("preferredMailGap90")}
                    />}
                    <Collapse in={getExpandedValue("preferredMailGap90")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {preferred90Gap
                          .map((tier, i) =>
                            <MultilinePlanDetailRow title={tier.tierName}
                                                    key={tier.tierName}
                                                    tooltip={getInitialTooltipByTier(tier.tier)}
                                                    values={getValuesByKey("drugsTiers90Preferred", "Not covered",
                                                      (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.coverageGapDrugTiers[i]?.coverage || !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'))}

                            />)}
                        <MultilinePlanDetailRow title={`All ${preferred90Gap.length ? 'other' : 'drug'} tiers`}
                                                tooltip={''}
                                                values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                  (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance')}

                        />
                        </tbody>
                      </table>
                    </Collapse>
                    {standard90 && <SectionTitleH3 title={"Standard mail-order cost-sharing (90 day supply)"}
                                                   tableClassName={"fill-width pv-8"}
                                                   onToggle={value => setExpandedState(prevState => ({
                                                     ...prevState,
                                                     standardMailGap90: value
                                                   }))}
                                                   expanded={getExpandedValue("standardMailGap90")}
                    />}
                    <Collapse in={getExpandedValue("standardMailGap90")} className={'bg-white'}>
                      <table className={clsx(classes.table, classes.dataTable)}>
                        <tbody>
                        {standard90Gap
                          .map((tier, i) =>
                            <MultilinePlanDetailRow title={tier.tierName}
                                                    key={tier.tierName}
                                                    tooltip={getInitialTooltipByTier(tier.tier)}
                                                    values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                      (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : (val?.coverageGapDrugTiers[i]?.coverage || !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'))}

                            />)}
                        <MultilinePlanDetailRow title={`All ${standard90Gap.length ? 'other' : 'drug'} tiers`}
                                                tooltip={''}
                                                values={getValuesByKey("drugsTiers90Standard", "Not covered",
                                                  (val, plan) => plan?.SNPType === 'D_SNP' ? 'Varies based on your level of Medicaid. Consult the Plan Summary of Benefits' : !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance')}

                        />
                        </tbody>
                      </table>
                    </Collapse>
                  </Collapse>
                </>}
              </Collapse>
              <SectionTitleH2 title={"Catastrophic Coverage phase"}
                              subtitle={"Catastrophic coverage starts when your annual out-of-pocket costs exceed $6,550."}
                              tableClassName={"fill-width pv-16"}
                              onToggle={value => setExpandedState(prevState => ({
                                ...prevState,
                                coverageCatastrophicPhase: value
                              }))}
                              expanded={getExpandedValue("coverageCatastrophicPhase")}
              />
              <Collapse in={getExpandedValue("coverageCatastrophicPhase")} className={'bg-white'}>
                <table className={clsx(classes.table, classes.dataTable)}>
                  <tbody>
                  {plans && catastrophicCoverage.map((item: any, index) => <MultilinePlanDetailRow key={item.title}
                                                                                                   title={item.title}
                                                                                                   tooltip={getGapTooltipByTier([DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][index])}
                                                                                                   values={getValuesByKey("drugDetails", "Not applicable", val => val && val.catastrophicCoverage[0]?.items[index]?.values)}

                  />)}
                  </tbody>
                </table>
              </Collapse>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-20"}/>

          <Element name={"Medical & Hospital Coverage"}>
            <BlockTitle title={"Medical & Hospital Coverage"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Your Doctor Cost"}
                onToggle={value => setExpandedState(prevState => ({...prevState, yourDoctors: value}))}
                expanded={getExpandedValue("yourDoctorCost")}
                icon={"/img/doctor.svg"}
              />
            </div>
            <Collapse in={getExpandedValue("yourDoctorCost")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <MultilinePlanDetailRow title={"Primary Doctor Visit"}
                                        tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'}
                                        values={getValuesByKey("primaryCarePhysician", "20% of the cost")}/>
                <MultilinePlanDetailRow title={"Specialist Visit"}
                                        tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'}
                                        values={getValuesByKey("physicianSpecialist", "20% of the cost")}/>
                <MultilinePlanDetailRow title={"Preventive Care"}
                                        tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes, including routine physicals or annual check-ups.'}
                                        values={getValuesByKey("preventiveCare", "$0 copay")}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Emergency, Hospital and Outpatient Coverage"}
                onToggle={value => setExpandedState(prevState => ({...prevState, hospitalCoverage: value}))}
                expanded={getExpandedValue("hospitalCoverage")}
                icon={"/img/healthcare.svg"}
              />
            </div>
            <Collapse in={getExpandedValue("hospitalCoverage")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <PlanDetailRow title={"Emergency Room"}
                               tooltip={'Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient\'s health at serious risk.'}
                               values={getValuesByKey("hospitalityDetails",
                                 <div>
                                   <Typography variant={"h5"} color={'textPrimary'}>You pay a copayment for each emergency department visit and a copayment for each hospital service.
                                     You also pay 20% of the Medicare-approved amount for your doctor's services, and the Part B deductible applies.
                                     If your doctor admits you to the same hospital for a related condition within 3 days of your emergency department visit, you don't pay the copayment because your visit is considered part of your inpatient stay.</Typography>
                                 </div>, v => v.emergencyRoom)}/>
                <PlanDetailRow title={"Urgently Needed Services"}
                               tooltip={'Medical care that you get outside of your Medicare health plan\'s service area for a sudden illness or injury that needs attention but isn\'t life threatening. If it\'s not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care.'}
                               values={getValuesByKey("hospitalityDetails", "20% of the cost after the Part B deductible", v => v.urgentServices)}/>
                <MultilinePlanDetailRow title={"Ambulance Services"}
                                        tooltip={'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'}
                                        values={getValuesByKey("hospitalityDetails", "20% of the cost after the Part B deductible", v => v.ambulanceServices)}/>
                <MultilinePlanDetailRow title={"Inpatient Hospital Care"}
                                        values={getValuesByKey("hospitalBenefits",
                                          <div>
                                            <Typography variant={"h5"} color={'textPrimary'}>{'$1,556'} deductible for each benefit period<br />
                                              Days 1-60: $0 coinsurance for each benefit period<br />
                                              Days 61-90: {'$389'} coinsurance per day of each benefit period<br />
                                              Days 91 and beyond: {'$778'} coinsurance per each "lifetime reserve day" after day 90 for each benefit period (up to 60 days over your lifetime)<br />
                                              Beyond lifetime reserve days: all costs</Typography>
                                          </div>, value => value || "N/A")}/>
                <MultilinePlanDetailRow title={"Skilled Nursing Facilities (SNF)"}
                                        tooltip={'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'}
                                        values={getValuesByKey("skilledNursing",
                                          <div>
                                            <Typography variant={"h5"} color={'textPrimary'}>
                                              Days 1–20: $0 for each benefit period .<br />
                                              Days 21–100: {'$194.50'} coinsurance per day of each benefit period.<br />
                                              Days 101 and beyond: All costs.</Typography>
                                          </div>)}/>
                <MultilinePlanDetailRow title={"Outpatient Diagnostic Tests and Therapeutic Services"}
                                        tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'}
                                        values={getValuesByKey("outpatientHospitality",
                                          <div>
                                            <Typography variant={"h5"} color={'textPrimary'}>Diagnostic radiology services (such as MRIs, CT
                                              scans): 20% of the cost</Typography>
                                            <Typography variant={"h5"} color={'textPrimary'}>Diagnostic tests and procedures: 20% of the
                                              cost</Typography>
                                            <Typography variant={"h5"} color={'textPrimary'}>Lab services: You pay nothing</Typography>
                                            <Typography variant={"h5"} color={'textPrimary'}>Outpatient x-rays: 20% of the cost</Typography>
                                            <Typography variant={"h5"} color={'textPrimary'}>Therapeutic radiology services (such as radiation
                                              treatment for cancer): 20% of the cost after the Part B deductible</Typography>
                                          </div>)}/>
                <MultilinePlanDetailRow title={"Outpatient Hospital and Surgery"}
                                        tooltip={'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'}
                                        values={getValuesByKey("outpatientSurgery",
                                          <div>
                                            <Typography variant={"h5"} color={'textPrimary'}>Outpatient hospital: 20% of the cost after the Part B deductible</Typography>
                                            <Typography variant={"h5"} color={'textPrimary'}>Ambulatory surgical center: 20% of the cost  after the Part B deductible</Typography>
                                          </div>)}/>
                <MultilinePlanDetailRow title={"Outpatient Rehabilitation Services"}
                                        tooltip={'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'}
                                        values={getValuesByKey("outpatientRehabilitation",
                                          <div>
                                            <Typography variant={"h5"} color={'textPrimary'}>Cardiac rehabilitation services including exercise, education, and counseling: No cost<br />
                                              Intensive cardiac rehabiliation in a doctor’s office: 20% of the cost  after the Part B deductible<br />
                                              Occupational therapy: 20% of the cost  after the Part B deductible<br />
                                              Physical therapy and speech and language therapy: 20% of the cost  after the Part B deductible</Typography>
                                          </div>)}/>
                <MultilinePlanDetailRow title={"Outpatient Mental Health Care"}
                                        tooltip={'Typically for services provided by a mental health professional in an outpatient setting.'}
                                        values={getValuesByKey("mentalHealth", <div>
                                          <Typography variant={"h5"} color={'textPrimary'}>Annual depression screening: No cost<br /> <br />
                                            Visits to your doctor or other health care provider to diagnose or treat your condition: 20% of the cost  after the Part B deductible</Typography>

                                        </div>)}/>
                <MultilinePlanDetailRow title={"Home Health Care"}
                                        tooltip={'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'}
                                        values={getValuesByKey("homeHealth", <div>
                                          <Typography variant={"h5"} color={'textPrimary'} className={'mb-5'}>You pay nothing if you meet the qualifying conditions.</Typography>
                                          <Typography variant={"h5"} color={'textPrimary'} className={'mb-5'}>Limitations and exclusions apply.<br /> Medicare doesn't pay for:</Typography>
                                          <Typography variant={"h5"} color={'textPrimary'}><span className={'list-item'}>24-hour-a-day care at your home</span></Typography>
                                          <Typography variant={"h5"} color={'textPrimary'}><span className={'list-item'}>Meals delivered to your home</span></Typography>
                                          <Typography variant={"h5"} color={'textPrimary'}><span className={'list-item'}>Homemaker services (like shopping, cleaning, and laundry) that aren’t related to your care plan</span></Typography>
                                          <Typography variant={"h5"} color={'textPrimary'}><span className={'list-item'}>Custodial or personal care that helps you with daily living activities (like bathing, dressing, or using the bathroom), when this is the only care you need</span></Typography>
                                        </div>)}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-20"}/>

          <Element name={"Extra Benefits"}>
            <BlockTitle title={"Extra Benefits"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Additional Benefits"}
                onToggle={value => setExpandedState(prevState => ({...prevState, additionalCoverage: value}))}
                expanded={getExpandedValue("additionalCoverage")}
                icon={"/img/teeth.svg"}
              />
            </div>

            <Collapse in={getExpandedValue("additionalCoverage")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <MultilinePlanDetailRow title={"Optional supplemental benefits"}
                                        tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'}
                                        values={getValuesByKey("optionalSupplementalBenefitsItems", "Not applicable")}/>
                <MultilinePlanDetailRow title={"Dental Services"}
                                        tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                                        values={getValuesByKey("dental", "Limited.  Medicare Part A (Hospital Insurance) will pay for certain dental services that you get when you're in a hospital.")}/>
                <MultilinePlanDetailRow title={"Vision Services"}
                                        tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                                        values={getValuesByKey("vision", "Limited. Exam to diagnose and treat diseases and conditions of the eye (including yearly glaucoma screening): 20% of the cost after the Part B deductible Eyeglasses or contact lenses after cataract surgery: 20% of the cost after the Part B deductible")}/>
                <MultilinePlanDetailRow title={"Hearing Services"}
                                        tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                                        values={getValuesByKey("hearing", "Exam to diagnose and treat hearing and balance issues: 20% of the cost")}/>
                <MultilinePlanDetailRow title={"Transportation Services"}
                                        tooltip={'Limited. Diagnostic hearing and balance exams: 20% of the cost after the Part B deductible'}
                                        values={getValuesByKey("transportation", "Not applicable")}/>
                <MultilinePlanDetailRow title={"Fitness Benefits"}
                                        tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'}
                                        values={getValuesByKey("otherDefinedSupplemental", "No", v => v.fitnessBenefit)}/>
                <MultilinePlanDetailRow title={"Insulin Savings"}
                                        tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                        values={getValuesByKey("extraBenefits", "No", v => <Benefit label={''} value={!!v.insulinSavings} />)}/>
                <MultilinePlanDetailRow title={"Meals After Hospital Stay"}
                                        tooltip={'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'}
                                        values={getValuesByKey("meal", "No")}/>
                <MultilinePlanDetailRow title={"Over-the-Counter Allowance"}
                                        tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                        values={getValuesByKey("oTC", "No")}/>
                <MultilinePlanDetailRow title={"Telehealth Services"}
                                        tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'}
                                        values={getValuesByKey("telehealth", "20% of the cost  after the Part B deductible")}/>
                </tbody>
              </table>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-20"}/>

          <Element name={"Plan Brochures"}>
            <BlockTitle title={"Plan Brochures"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Plan and Medicare Brochures"}
                onToggle={value => setExpandedState(prevState => ({...prevState, brochures: value}))}
                expanded={getExpandedValue("brochures")}
                icon={"/img/brochures.svg"}
              />
            </div>

            <Collapse in={getExpandedValue("brochures")} className={'bg-white'}>
              <table className={clsx(classes.table, classes.dataTable)}>
                <tbody>
                <MultilinePlanDetailRow title={"Plan Brochures"}
                                        values={getValuesByKey("planFiles", "No",
                                          value => planFiles(value).map(item => <a className={planClasses.link}
                                                                                   target={"_blank"}
                                                                                   href={item.url}>{item.label}</a>))
                                        }
                />
                <PlanDetailRow title={"Medicare Brochures"}
                               values={getValuesByKey("planFiles", "No",
                                 v => <a className={planClasses.link}
                                         target={"_blank"}
                                         href={'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'}>Medicare & You
                                   Handbook (2022)</a>)
                               }
                />
                </tbody>
              </table>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>
        </AscendCard>
      </Grid>
    </Grid>
}

type TitleRowProps = {
  title: string,
  subtitle?: string,
  icon?: string,
  classes: any,
  expanded: boolean,
  onToggle: (value: boolean) => void,
  tableClassName?: string,
  wrapperClassName?: string,
}

const BlockTitle = withStyles({
  wrapper: {
    paddingTop: 12,
    backgroundColor: '#f5f7f6',
    zIndex: 1,
    position: 'sticky',
    top: 312,
    marginBottom: '16px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 0',
    borderRadius: 10,
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
  }
})(({title, classes}: { title: string, classes: any }) => <Box className={classes.wrapper}>
  <Box className={classes.title}>
    <Typography variant={"h1"} color={"textPrimary"}><strong>{title}</strong></Typography>
  </Box>
</Box>)

const SectionTitle = withStyles({
  cell: {
    background: "white!important",
    padding: "0!important"
  },
  subtitle: {
    borderRadius: 0,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 21,
    width: '100%',
  },
  row: {
    border: 'none!important',
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={tableClassName}>
    <tbody>
    <tr className={classes.row}>
      <td colSpan={5} className={classes.cell}>
        <AscendSubtitle wrapperClassName={classes.subtitle} title={props.title} icon={props.icon}
                        expanded={props.expanded} onToggleClick={props.onToggle}/>
      </td>
    </tr>
    </tbody>
  </table>
))

const SectionTitleH2 = withStyles({
  cell: {
    padding: "0 11px!important"
  },
  table: {
    background: "white!important",
    borderSpacing: 0,
  },
  wrapper: {
    background: "rgba(232,236,237,0.5)",
    borderRadius: "4px"
  },
  medium: {
    fontWeight: 500,
  },
  icon: {
    transform: "rotate(-180deg)",
    transition: "all 0.3s"
  },
  iconOpen: {
    transform: "rotate(0) !important",
  },
  button: {
    background: "rgba(2, 144, 148, 0.5)",
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: 10,
    marginTop: 5,
    "&:hover": {
      background: "rgba(2, 144, 148, 0.5)",
    }
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={clsx(classes.table, tableClassName)}>
    <tbody>
    <tr>
      <td colSpan={5} className={classes.cell}>
        <Box p={"16px 10px 14px 20px"}
             className={clsx(classes.wrapper, props.wrapperClassName)}
             display={"flex"}
             flexDirection={"row"}
             alignContent={"center"}
             justifyContent={"space-between"}
             width={1}>

          <Box display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"start"}>
            <Typography variant={"h3"} color={"textPrimary"} className={classes.medium}>{props.title}</Typography>
            {props.subtitle &&
            <Typography variant={"h5"} color={"textPrimary"} className={classes.medium}>{props.subtitle}</Typography>}
          </Box>
          <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
            <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={"/img/arrow-white.svg"}/>
          </IconButton>
        </Box>
      </td>
    </tr>
    </tbody>
  </table>
))

const SectionTitleH3 = withStyles({
  cell: {
    padding: "0 11px!important"
  },
  table: {
    background: "white!important",
    borderSpacing: 0,
  },
  wrapper: {
    background: "#FFF2EF!important",
    borderRadius: "4px"
  },
  medium: {
    fontWeight: 500,
  },
  icon: {
    transform: "rotate(0)",
    transition: "all 0.3s"
  },
  iconOpen: {
    transform: "rotate(-180deg) !important",
  },
  button: {
    background: "#CCDFDD",
    width: 24,
    maxWidth: 24,
    height: 24,
    padding: 7,
    "&:hover": {
      background: "#CCDFDD",
    }
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={clsx(classes.table, tableClassName)}>
    <tbody>
    <tr>
      <td colSpan={5} className={classes.cell}>
        <Box p={"3px 14px 2px 20px"}
             className={clsx(classes.wrapper, props.wrapperClassName)}
             display={"flex"}
             flexDirection={"row"}
             alignContent={"center"}
             justifyContent={"space-between"}
             width={1}>

          <Box display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"start"}>
            <Typography color={"textPrimary"} className={'fs-14 medium'}>{props.title}</Typography>
          </Box>
          <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
            <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={"/img/arrow.svg"}/>
          </IconButton>
        </Box>
      </td>
    </tr>
    </tbody>
  </table>
))

const PlanDetailRow = (props: { title: string | JSX.Element, values: any[], className?: string, tooltip?: string, thin?: boolean }) =>
  <tr>
    <td>
      {!props.tooltip && <>
        {typeof props.title === "string" && <Typography variant={"h5"} color={"textPrimary"}
                                                        className={`${props.thin ? 'thin' : 'bold'} ${props.className}`}>{props.title}</Typography>}
        {typeof props.title !== "string" && props.title}
      </>}
      {props.tooltip && <TooltipedTitle title={props.title} className={`${props.thin ? 'thin' : 'bold'} ${props.className}`} tooltip={props.tooltip}/>}
    </td>
    {props.values.map((value, index) => <td key={index}>
      {typeof value === "string" &&
      <Typography variant={"h5"} color={"textPrimary"} className={props.className}>{value}</Typography>}
      {typeof value !== "string" && value}
    </td>)}
  </tr>

const MultilinePlanDetailRow = (props: { title: string, values: any[], tooltip?: string }) =>
  <PlanDetailRow title={props.title} tooltip={props.tooltip}
                 values={props.values.map(val => Array.isArray(val) ? <ListPlanDetailText content={val}/> : val)}/>


const ServiceCoverageDetailRow = withStyles({
  label: {
    color: colors.custom.green.variant1,
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 400,
  }
})(({classes, ...props}: { title: string, values: any[], scrollTo: string, classes?: any, tooltip?: string }) =>
  <PlanDetailRow title={props.title} tooltip={props.tooltip}
                 values={props.values.map(val =>
                   Array.isArray(val) && !!val.filter(v => !!v).length ?
                     <Link to={props.scrollTo} smooth={true} spy={true} duration={200}>
                       <Benefit labelClass={classes.label} value={true} label={"View details"}/>
                     </Link> :
                     typeof val === "string" ? val : <Benefit labelClass={classes.label} value={val} label={""}/>)}/>)


