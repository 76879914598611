import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import {useLazyQuery} from "@apollo/react-hooks";
import {Field, PageablePlanOutput, QueryPlansArgs, Sort} from "../../../types";
import {gql} from "apollo-boost";
import AscendPreloader from "../../../shared/components/AscendPreloader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 500,
      '& span': {
        color: colors.custom.green.variant1,
      }
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 400,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

type FindPlanModalProps = {
  onClose: () => void,
  open: boolean,
  countyName?: string,
  zip?: string,
}

export default function SearchingPlanModal(props: FindPlanModalProps) {
  const classes = useStyles();
  const [totalPlans, setTotalPlans] = React.useState<number>(0);
  const [timeout, setTimeoutValue] = React.useState();
  const [showTotalPlans, setShowTotalPlans] = React.useState();

  const [getPlans, {loading}] = useLazyQuery<IPlansOutput, QueryPlansArgs>(gql(planRequest), {
    onCompleted: data => {
      setTotalPlans(data.plans.totalElements);
    },
    errorPolicy: "ignore"
  });

  React.useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  React.useEffect(() => {
    if (props.zip && props.countyName && props.open) {
      setShowTotalPlans(false);
      getPlans({
        variables: {
          filter: {
            zip: props.zip, countyName: props.countyName
          },
          page: {page: 0, size: 1},
          sort: [{direction: Sort.Asc, field: Field.Rating}]
        }
      })
      clearTimeout(timeout)
      setTimeoutValue(setTimeout(() => {
        clearTimeout(timeout)
        setTimeoutValue(setTimeout(() => {
          clearTimeout(timeout)
          props.onClose()
        }, 3000))
        setShowTotalPlans(true);
      }, 3000))
    }
  }, [props])

  return <AscendModal
    width={584}
    open={props.open}
    disableBackdropClick
  >
    <>
      {(!loading && totalPlans && showTotalPlans) && <>
        <IconButton className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>
          Congratulations! We’ve found over <span>{totalPlans}</span> Medicare plans in your area.
        </Typography>
        <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
          <img src={'/img/congratulations.svg'} />
        </Box>
      </>}
      {(loading || !showTotalPlans) && <>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Searching for plans in your area...</Typography>
        <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
          <AscendPreloader />
        </Box>
      </>}
      <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>{props.countyName}</Typography>
    </>
  </AscendModal>
}


interface IPlansOutput {
  plans: PageablePlanOutput
}

const planRequest = `
  query ($filter: PlansFilterInput!, $page: PageInput!, $sort: [PlansSortInput!]!) {
    plans(filter: $filter, page: $page, sort: $sort) {
      totalElements
    }
  }
`;
