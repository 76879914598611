import React from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {PackInfoOuput} from "../../types";

export default function useGetPackagesRequest(onCompleted?: (data: {packages: PackInfoOuput[]}) => void) {
  return useLazyQuery<{packages: PackInfoOuput[]}, {rxcui: string}>(
    gql(getDrugRequest),
    {
      onCompleted,
      fetchPolicy: 'no-cache'
    }
  );
}

const getDrugRequest = `
query ($rxcui: String!) {
  packages(rxcui: $rxcui) {
    name
    rxcui
  }
}
`;
