import * as _ from "lodash";

export function getCId(): string | undefined {
  return (window as any).gaGlobal?.vid || getUId();
}

export function getUId(): string | undefined {
  return getCookie('__opix_uid');
}

function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const formatPharmacyName = (name: string) => {
  return _.startCase(name.toLowerCase())
    .replace('Cvs', 'CVS')
    .replace('Ahf', 'AHF')
    .replace('Svrf', 'S.V.R.H.');
}

export const storeFilters = (data: any, zip: string) => {
  const storedFilters = localStorage.getItem('filters');
  let result: any = {zip, companies: [], planTypes: [], rating: [], SNPTypes: [], extraBenefits: []}
  if (storedFilters) {
    result = JSON.parse(storedFilters);
  }
  Object.keys(data).forEach(key => {
    result[key] = data[key];
  })
  localStorage.setItem('filters', JSON.stringify(result));
}

export function convertMedium(text: string) {
  return replaceAll(replaceAll(text, '[b]', '<span class="medium">'), '[/b]', '</span>');
}

export function convertBold(text: string) {
  return replaceAll(replaceAll(text, '[b]', '<strong>'), '[/b]', '</strong>');
}

export function convertList(text: string) {
  return replaceAll(replaceAll(text, '[li]', '<div class="list-item">'), '[/li]', '</div>');
}
function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const getCompanyLogo = (parentOrgName?: string | null, orgName?: string | null): string => {
  if (!parentOrgName) {
    return '';
  }

  if (!orgName) {
    orgName = '';
  }

  if ([
    'Aetna Better Health of New Jersey',
    'Aetna Better Health of Ohio (HMO SNP)',
    'Aetna Better Health of Virginia',
    'Aetna Medicare',
    'Allina Health Aetna Medicare'
  ].includes(parentOrgName)) {
    if ([
      'COVENTRY HEALTH CARE OF VIRGINIA, INC.',
      'COVENTRY HEALTH AND LIFE INSURANCE COMPANY',
      'COVENTRY HEALTH CARE OF ILLINOIS, INC.',
      'COVENTRY HEALTH CARE OF KANSAS, INC.',
      'COVENTRY HEALTH CARE OF MISSOURI, INC',
      'COVENTRY HEALTH CARE OF NEBRASKA, INC.',
      'COVENTRY HEALTH CARE OF WEST VIRGINIA, INC.',
      'FIRST HEALTH LIFE & HEALTH INSURANCE COMPANY',
      'HEALTHASSURANCE PENNSYLVANIA, INC.',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/aetna.png'
  }

  if ([
    'Amerigroup',
    'AMERIGROUP',
    'Amerigroup Community Care',
    'AMERIGROUP Community Care',
    'Amerigroup Community Care of New Mexico',
    'Amerigroup Insurance Company',
    'Amerigroup Iowa',
    'Amerigroup Ohio',
    'AMH Health',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/amerigroup.png'
  }

  if ([
    'AMH Health',
    'Anthem Blue Cross',
    'Anthem Blue Cross and Blue Shield',
    'Anthem Blue Cross Life and Health Insurance Co.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/anthem.png'
  }

  if ([
    'Bright Health',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/bright.png'
  }

  if ([
    'CarePlus Health Plans, Inc.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/careplus.png'
  }


  if ([
    'Empire BlueCross BlueShield',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/empire_bcbs.png'
  }

  if ([
    'Cigna',
  ].includes(parentOrgName)) {
    if ([
      'HEALTHSPRING LIFE & HEALTH INSURANCE COMPANY, INC.',
      'HEALTHSPRING OF FLORIDA, INC.',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/cigna.png'
  }

  if ([
    'Clover Health',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/clover.png'
  }

  if ([
    'Freedom Health, Inc.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/freedom.png'
  }

  if ([
    'HealthSun Health Plans, Inc.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/healthsun.png'
  }

  if ([
    'Humana',
  ].includes(parentOrgName)) {
    if ([
      'COMPBENEFITS INSURANCE COMPANY',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/humana.png'
  }

  if ([
    'Optimum HealthCare, Inc.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/optimum.png'
  }

  if ([
    'Simply Healthcare Plans, Inc.',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/simply.png'
  }

  if ([
    'UnitedHealthcare',
  ].includes(parentOrgName)) {
    if ([
      'ARIZONA PHYSICIANS IPA, INC.',
      'CARE IMPROVEMENT PLUS OF TEXAS INSURANCE COMPANY',
      'CARE IMPROVEMENT PLUS SOUTH CENTRAL INSURANCE CO',
      'CARE IMPROVEMENT PLUS SOUTH CENTRAL INSURANCE CO.',
      'CARE IMPROVEMENT PLUS WISCONSIN INSURANCE COMPANY',
      'HARKEN HEALTH INSURANCE COMPANY',
      'HEALTH PLAN OF NEVADA, INC.',
      'MAMSI LIFE AND HEALTH INSURANCE COMPANY',
      'MEDICA HEALTHCARE PLANS, INC.',
      'OPTIMUM CHOICE, INC.',
      'OXFORD HEALTH PLANS (CT), INC.',
      'OXFORD HEALTH PLANS (NJ), INC.',
      'OXFORD HEALTH PLANS (NY), INC.',
      'PACIFICARE LIFE ASSURANCE COMPANY',
      'PACIFICARE OF COLORADO, INC.',
      'PHYSICIANS HEALTH CHOICE OF TEXAS, LLC',
      'PREFERRED CARE PARTNERS, INC.',
      'ROCKY MOUNTAIN HEALTH MAINTENANCE ORGANIZATION',
      'SIERRA HEALTH AND LIFE INSURANCE COMPANY, INC.',
      'SYMPHONIX HEALTH INSURANCE, INC.',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/uhc.png'
  }

  if ([
    'WellCare',
    'Wellcare',
  ].includes(parentOrgName)) {
    if ([
      'MERIDIAN HEALTH PLAN OF ILLINOIS, INC.',
      'MERIDIAN HEALTH PLAN OF MICHIGAN, INC.',
      'SELECTCARE HEALTH PLANS, INC.',
      'SELECTCARE OF TEXAS, INC.',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/wellcare.png'
  }

  return  '';
}


export const getMedigapCompanyLogo = (parentOrgName?: string | null, orgName?: string | null): string => {
  if (!parentOrgName) {
    return '';
  }

  if (!orgName) {
    orgName = '';
  }

  if ([
    'Anthem Inc Grp',
  ].includes(parentOrgName)) {
    if ([
      'Amerigroup Ins Co (Anthem)',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/amerigroup.png';
    }
    if ([
      'Empire Healthchoice Assur Inc (Anthem)',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/empirebcbs.png';
    }
    return 'https://logo.plans.coverrightmedicare.com/anthem.png'
  }

  if ([
    'BlueCross BlueShield of South Carolina',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/bcbcsc.png'
  }

  if ([
    'CENTENE CORP GRP',
  ].includes(parentOrgName)) {
    if ([
      'AMERICAN PROGRESSIVE LIFE & HEALTH INSURANCE COMPANY OF NEW YORK',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/wellcare.png'
    }
    if ([
      'HEALTH NET LIFE INSURANCE COMPANY',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/healthnet.png'
    }
    if ([
      'QualChoice Life and Health Insurance Company, Inc.',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/qualchoice.png'
    }
    return ''
  }

  if ([
    'CIGNA HLTH GRP',
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/cigna.png'
  }

  if ([
    'AETNA GRP',
    'CVS GRP'
  ].includes(parentOrgName)) {
    if ([
      'American Continental Insurance Company (Aetna)',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/aetnabrentwood.png';
    }
    if ([
      'Continental Life Insurance Company of Brentwood, Tennessee (Aetna)',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/aetnabrentwood2.png';
    }
    if ([
      'Accendo Ins Co',
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/aetna.png'
  }

  if ([
    'GUARANTEE TRUST GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/gtl.png'
  }

  if ([
    'HUMANA GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/humana.png'
  }

  if ([
    'MUTUAL OF OMAHA GRP'
  ].includes(parentOrgName)) {
    if ([
      'UNITED OF OMAHA LIFE INSURANCE COMPANY',
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/unitedofomaha.png';
    }
    return 'https://logo.plans.coverrightmedicare.com/moo.png'
  }

  if ([
    'SWISS RE GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/Lumico_NFC.png'
  }

  if ([
    'UNITEDHEALTH GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/aarpuhc.png'
  }

  return  '';
}
