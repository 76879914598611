import React from "react";
import {IQuestion, IStep} from "../../shared/SidePanel/AscendStepper";
import * as _ from "lodash";
import {IStepperState} from "../../Questionnaire/hooks/useStepperState";
import paths from "../config/router-paths";
import Enroll from "../Enroll";
import CreateAccount from "../CreateAccount";


let steps: IStep[] = [
  {
    label: "Start application",
    firstStep: paths.enroll.replace('/', ''),
    questions: [{
      link: paths.enroll.replace('/', ''),
      component: Enroll
    }, {
      link: paths.createAccount.replace('/', ''),
      component: CreateAccount
    }],
  },
  {
    label: "Personal details",
    firstStep: paths.personalDetails.replace('/', ''),
    questions: [],
  },
  {
    label: "Review and submit",
    firstStep: paths.receive.replace('/', ''),
    questions: [],
  },
];;

const getQuestions = (steps: IStep[]) => {
  return steps.reduce((previousValue, currentValue) => {
    const result = [...previousValue];
    if (currentValue.questions) {
      result.push(...currentValue.questions);
    }
    if (currentValue.children?.length) {
      result.push(..._.flatten(currentValue.children.map((ch: any) => ch.questions)));
    }
    return result;
  }, [] as any[]);
}

let listeners: any[] = [];

export function setCompletedByLabel(label: string, completed: boolean) {
  let st = [...state.steps.map(step => ({
    ...step,
    completed: step.label === label ? completed : step.completed,
    children: step.children ? step.children.map(s => ({
      ...s,
      completed: s.label === label ? completed : s.completed
    })) : step.children,
  }))]
  setState({...state, steps: st, questions: getQuestions(st)})
}


let state: IStepperState = {
  steps,
  questions: getQuestions(steps)
};

const setState = (newState: IStepperState | Function) => {
  if (typeof newState === "function") {
    state = newState(state);
  } else {
    state = {...state, ...newState};
  }
  listeners.forEach((listener) => {
    listener(state);
  });
};


const useEnrollmentStepperState = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {state, setState};
};

export default useEnrollmentStepperState;


