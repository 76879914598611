import React from "react";
import AscendDivider from "../../../shared/components/AscendDivider";
import Terms from "../../../shared/SidePanel/Terms";
import MonthlyCostCalculator from "../PlanList/MonthlyCostCalculator";
import HealthcarePreferences from "../PlanList/HealthcarePreferences";

const FavoritesSideContent = () => {
  return <>
    <HealthcarePreferences />
    <AscendDivider mb={'16px'} />
    <MonthlyCostCalculator />
    <Terms />
    {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
  </>
}

export default FavoritesSideContent;
