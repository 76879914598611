import {withStyles} from "@material-ui/core";
import {Rating} from "@material-ui/lab";

const StyledRating = withStyles({
  root: {
    marginTop: 7,
  },
  iconFilled: {
    color: 'rgba(94, 203, 158, 1)!important',
  },
  icon: {
    color: 'rgba(212, 212, 212, 1)',
  },
})(Rating);

export default StyledRating;
