import React from "react";
import {Box, createStyles, Divider, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import {RouteComponentProps} from "react-router";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import AscendDivider from "../../shared/components/AscendDivider";
import clsx from 'clsx';
import useDoctorsByRequestId from "../../Maketplace/MedicareAdvantage/PlanList/hooks/useDoctorsByRequestId";
import useDrugsByRequestId from "../../Maketplace/MedicareAdvantage/PlanList/hooks/useDrugsByRequestId";
import {getQuoteId} from "../../shared/QuoteId";
import {DrugOutput, PharmacyLocationDistanceOutput, ProviderOutput} from "../../types";
import * as _ from 'lodash';
import {config} from "../config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      marginBottom: 16,
      fontWeight: 700,
    },
    label: {
      fontSize: 18,
      textAlign: 'center',
    },
    wrapper: {
      maxWidth: 760,
      background: '#FFFFFF',
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: 10,
    },
    block: {
      paddingTop: 26,
      paddingBottom: 26,
      backgroundColor: '#F5F7F6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    divider: {
      color: '#1c434f14',
    },
    blockContent: {
      paddingLeft: '10px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '20%'
      },
    },
    control: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    checkbox: {
      padding: 4
    },
    icon: {
      backgroundColor: 'transparent',
    }
  }),
);

function Step14(props: RouteComponentProps<{tempId: string}>) {
  const {tempId} = props.match.params;
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);

  const [timeout, setTimeoutValue] = React.useState();

  const [selectedDoctors, setSelectedDoctors] = React.useState<ProviderOutput[]>([]);

  const [selectedPrescriptions, setSelectedPrescriptions] = React.useState<DrugOutput[]>([]);

  const {doctors, loading: doctorsLoading} = useDoctorsByRequestId(getQuoteId());
  const {drugs, loading: drugsLoading} = useDrugsByRequestId(getQuoteId());

  React.useEffect(() => {
    if (drugs.length) {
      setSelectedPrescriptions(drugs);
    }
  }, [drugs])

  React.useEffect(() => {
    if (doctors.length) {
      setSelectedDoctors(doctors);
    }
  }, [doctors])


  React.useEffect(() => {
    setTimeoutValue(setTimeout(() => {
      document.location.href = (config.resultsUrl as string) + 'plans/' + state.personalDetails?.zip + '/' + tempId
    }, 5000))
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const benefits = JSON.parse(localStorage.getItem('filters') || '{}').extraBenefits || [];

  return <Box display={'flex'} justifyContent={'center'}>
    <Box display={'flex'} flex={1} flexDirection={'column'} alignItems={'center'} className={classes.wrapper}>
      <Box mt={'22px'} width={'100%'}>
        <Typography className={classes.title} variant={'h1'} color={'textPrimary'}>You will now be transferred to our<br />Medicare Advantage marketplace</Typography>
        <Box mt={'18px'}>
          <Typography className={clsx(classes.label, 'bold')}  color={'textSecondary'}>Searching for your plans now...</Typography>
        </Box>
        <Box mt={'39px'} height={'100px'} position={'relative'}>
          <div className="load">
            <hr/>
            <hr/>
            <hr/>
            <hr/>
          </div>
        </Box>
        <Box mt={'30px'} mb={'16px'}>
          <Typography className={clsx(classes.label, 'medium')} color={'textPrimary'}>Finding plans that match your needs:</Typography>
        </Box>
        <Box className={classes.block}>
          <Box className={classes.blockContent}>
            <Typography className={'fs-18 medium'} color={'textPrimary'}>Your doctors:</Typography>
            {doctorsLoading && <Typography variant={'body2'} color={'textPrimary'}>Loading...</Typography>}
            {(!doctorsLoading && !selectedDoctors.length) && <AscendCheckbox readOnly
                                                                             controlClassName={classes.control}
                                                                             className={classes.checkbox}
                                                                             iconClassName={classes.icon}
                                                                             variant={'standart'}
                                                                             label={'No doctors selected'} checked={false} />}
            {(!doctorsLoading && !!selectedDoctors.length) && selectedDoctors.map((doctor) =>
                <AscendCheckbox key={doctor.npi}
                                readOnly
                                variant={'standart'}
                                controlClassName={classes.control}
                                className={classes.checkbox}
                                label={`${doctor.name} (${doctor.addresses[0].specialty})`} checked={true} />
              )}
          </Box>
        </Box>
        <AscendDivider />
        <Box className={classes.block}>
          <Box className={classes.blockContent}>
            <Typography className={'fs-18 medium'} color={'textPrimary'}>Your prescription drugs:</Typography>
            {drugsLoading && <Typography variant={'body2'} color={'textPrimary'}>Loading...</Typography>}
            {(!drugsLoading && !selectedPrescriptions.length) && <AscendCheckbox readOnly
                                                                             controlClassName={classes.control}
                                                                             className={classes.checkbox}
                                                                             iconClassName={classes.icon}
                                                                             variant={'standart'}
                                                                             label={'No drugs selected'} checked={false} />}
            {(!drugsLoading && !!selectedPrescriptions.length) && selectedPrescriptions.map((drug) =>
              <AscendCheckbox key={drug.rxcui}
                              readOnly
                              variant={'standart'}
                              controlClassName={classes.control}
                              className={classes.checkbox}
                              label={`${drug.name} (${drug.packName}, ${drug.quantity} per ${drug.frequency})`} checked={true} />
            )}
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.block}>
          <Box className={classes.blockContent}>
            <Typography className={'fs-18 medium'} color={'textPrimary'}>Extra benefits:</Typography>
            {!benefits.length && <AscendCheckbox readOnly
                                                 controlClassName={classes.control}
                                                 className={classes.checkbox}
                                                 iconClassName={classes.icon}
                                                 variant={'standart'}
                                                 label={'No benefits selected'} checked={false} />}
            {!!benefits.length && benefits.map((benefit: string) =>
              <AscendCheckbox key={benefit}
                              readOnly
                              variant={'standart'}
                              controlClassName={classes.control}
                              className={classes.checkbox}
                              label={_.startCase(_.lowerCase(benefit))} checked={true} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
}

export default Step14;

/*



  const [selectedPharmacies, setSelectedPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>([]);

  const {pharmacies, loading: pharmaciesLoading} = usePharmaciesByRequestId(getQuoteId());

  React.useEffect(() => {
    if (pharmacies?.length) {
      setSelectedPharmacies(pharmacies);
    }
  }, [pharmacies])

<Box className={classes.block}>
          <Box className={classes.blockContent}>
            {pharmaciesLoading && <Typography variant={'body2'} color={'textPrimary'}>Loading...</Typography>}
            {(!pharmaciesLoading && !selectedPharmacies.length) && <AscendCheckbox readOnly
                                                                                 controlClassName={classes.control}
                                                                                 className={classes.checkbox}
                                                                                 iconClassName={classes.icon}
                                                                                 variant={'standart'}
                                                                                 label={'No pharmacy selected'} checked={false} />}
            {(!pharmaciesLoading && !!selectedPharmacies.length) && selectedPharmacies.map((pharmacy) =>
              <AscendCheckbox key={pharmacy.npi}
                              readOnly
                              variant={'standart'}
                              controlClassName={classes.control}
                              className={classes.checkbox}
                              label={`${pharmacy.name} (${pharmacy.address})`} checked={true} />
            )}
          </Box>
        </Box>
 */
