import React from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {Box, RadioGroup} from "@material-ui/core";
import AscendRadio from "../../../shared/components/AscendRadio";
import AscendButton from "../../../shared/components/AscendButton";
import paths from "../../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import useCommonStyles from "../../../shared/useCommonStyles";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import AscendInfo from "../../../shared/components/AscendInfo";
import {PlanYear} from "../../../types";

const formName = 'STEP_7';

function Step7(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [planYear, setPlanYear] = React.useState<PlanYear>(state.STEP_7?.planYear);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: 'STEP_7', form: formName, planYear}
    })
    props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.step9)
  };

  return <div className={'flex flex-justify-center'}>
    <div className={'w-300'} >
      <div className={'h-32'} />
      <RadioGroup>
        <AscendRadio checked={planYear === PlanYear.Year2021}
                     onChange={() => {setPlanYear(PlanYear.Year2021)}}
                     label={'2021 plans'}
        />
        <div className={'h-8'} />
        <AscendRadio checked={planYear === PlanYear.Year2022}
                     onChange={() => {setPlanYear(PlanYear.Year2022)}}
                     label={'2022 plans'}
        />
      </RadioGroup>
      <AscendInfo open={true}
                  wrapperClassName={'mv-20'}
                  description={<><strong>Not sure?</strong> If you are looking for coverage to start before Dec-31 select 2021. If you are shopping for new plans for the next calendar year select 2022.</>}/>
      <Box className={'fill-width'}>
        <AscendButton className={'gtm-step-event fill-width mb-20'} id={formName} disabled={typeof planYear === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
        {/*<AscendButton variant={'contained'} className={'fill-width'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>*/}
      </Box>
    </div>
  </div>;
}

export default withTitle(
  Step7,
  () => 'Are you searching Medicare Advantage plan options for 2021 or 2022?',
  '',
  undefined,
  undefined,
  true
);
