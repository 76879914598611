import React from "react";
import {IQuestion, IStep} from "../../shared/SidePanel/AscendStepper";
import * as _ from "lodash";
import Step1 from "../questions/Step1";
import Step2a from "../questions/Step2a";
import Step2b from "../questions/Step2b";
import Step2c from "../questions/Step2c";
import Step2a1 from "../questions/Step2a1";
import Step2b1 from "../questions/Step2b1";
import Step5a1 from "../questions/Step5a1";
import Step5a4 from "../questions/Step5a4";
import Step5a3 from "../questions/Step5a3";
import Step5a2 from "../questions/Step5a2";
import Step3 from "../questions/Step3";
import Step4 from "../questions/Step4";
import Step6a from "../questions/Step6a";
import Step6b from "../questions/Step6b";
import Step7 from "../questions/Step7";
import Step8 from "../questions/Step8";
import Step9a from "../questions/Step9a";
import Step9b from "../questions/Step9b";
import Step9a1 from "../questions/Step9a1";
import Step9b1 from "../questions/Step9b1";
import Step10 from "../questions/Step10";
import LoadingPage from "../questions/AnnualEnrollment/LoadingPage";
import Step11 from "../questions/Step11";
import Step12 from "../questions/Step12";
import Step13 from "../questions/Step13";
import Step14 from "../questions/Step14";
import Step15 from "../questions/Step15";
import Step16 from "../questions/Step16";
import Step13a from "../questions/Step13a";
import Step13b from "../questions/Step13b";
import Step13b1 from "../questions/Step13b1";

export const questionsRoutes = {
  step1: "",
  step2a: "step2a",
  step2a1: "step2a1",
  step2b: "step2b",
  step2b1: "step2b1",
  step2c: "step2c",
  step3: "step3",
  step4: "step4",
  step5a1: "step5a1",
  step5a2: "step5a2",
  step5a3: "step5a3",
  step5a4: "step5a4",
  step5b: "step5b",
  step6a: "step6a",
  step6b: "step6b",
  step7: "step7",
  step8: "step8",
  step9a: "step9a",
  step9a1: "step9a1",
  step9b: "step9b",
  step9b1: "step9b1",
  step10: "step10",
  step11: "step11",
  step12: "step12",
  step13: "step13", // benefits
  step13a: "step13a", // budget slider
  step13b: "step13b", // budget checkboxes
  step13b1: "step13b1", // personal details
  step14: "step14/:tempId", // loading
  step15: "step15", // calendly
  step16: "step16", // thank you
}

let steps: IStep[] = [
  {
    label: "Basic details",
    firstStep: questionsRoutes.step1,
    questions: [{
      link: questionsRoutes.step1,
      component: Step1
    }, {
      link: questionsRoutes.step2a,
      component: Step2a
    }, {
      link: questionsRoutes.step2a1,
      component: Step2a1
    }, {
      link: questionsRoutes.step2b,
      component: Step2b
    }, {
      link: questionsRoutes.step2b1,
      component: Step2b1
    }, {
      link: questionsRoutes.step2c,
      component: Step2c
    }, {
      link: questionsRoutes.step3,
      component: Step3
    }, {
      link: questionsRoutes.step4,
      component: Step4
    }, {
      link: questionsRoutes.step5a1,
      component: Step5a1
    }, {
      link: questionsRoutes.step5a2,
      component: Step5a2
    }, {
      link: questionsRoutes.step5a3,
      component: Step5a3
    }, {
      link: questionsRoutes.step5a4,
      component: Step5a4
    }, {
      link: questionsRoutes.step6a,
      component: Step6a
    }],
  },
  {
    label: "Medicare Options",
    firstStep: questionsRoutes.step8,
    questions: [{
      link: questionsRoutes.step8,
      component: Step8
    }, {
      link: questionsRoutes.step9a,
      component: Step9a
    }, {
      link: questionsRoutes.step9a1,
      component: Step9a1
    }],
  },
  {
    label: "Healthcare preferences",
    firstStep: questionsRoutes.step10,
    children: [{
      label: "Doctors and drugs",
      firstStep: questionsRoutes.step10,
      questions: [{
        link: questionsRoutes.step10,
        component: Step10
      }, {
        link: questionsRoutes.step11,
        component: Step11
      }, {
        link: questionsRoutes.step12,
        component: Step12
      }]
    }, {
      label: "Extra benefits",
      firstStep: questionsRoutes.step13,
      questions: [{
        link: questionsRoutes.step13,
        component: Step13
      }]
    }, {
      label: "Budget",
      firstStep: questionsRoutes.step13b,
      questions: [{
        link: questionsRoutes.step13b,
        component: Step13b
      }]
    }],
  },
  {
    label: "Review your options",
    firstStep: questionsRoutes.step13b1,
    questions: [{
      link: questionsRoutes.step13b1,
      component: Step13b1
    }, {
      link: questionsRoutes.step14,
      component: Step14 as any
    },
      {
        link: questionsRoutes.step15,
        component: Step15
      },
      {
        link: questionsRoutes.step16,
        component: Step16
      }],
  },
];


let medigapSteps: IStep[] = [
  {
    label: "Basic details",
    firstStep: questionsRoutes.step1,
    questions: [],
  },
  {
    label: "Medicare Options",
    firstStep: questionsRoutes.step8,
    questions: [],
  },
  {
    label: "Review your options",
    firstStep: questionsRoutes.step13b1,
    questions: [{
      link: questionsRoutes.step9b,
      component: Step9b
    }, {
      link: questionsRoutes.step9b1,
      component: Step9b1
    },
      {
        link: questionsRoutes.step15,
        component: Step15
      },
      {
        link: questionsRoutes.step16,
        component: Step16
      }],
  },
];

const isMedigapStep = (route: string): boolean =>
   getQuestions(medigapSteps).some(q => q.link === route)

export const getQuestions = (steps: IStep[]) => {
  return steps.reduce((previousValue, currentValue) => {
    const result = [...previousValue];
    if (currentValue.questions) {
      result.push(...currentValue.questions);
    }
    if (currentValue.children?.length) {
      result.push(..._.flatten(currentValue.children.map((ch: any) => ch.questions)));
    }
    return result;
  }, [] as any[]);
}

let listeners: any[] = [];

export function setCompletedByLabel(label: string, completed: boolean) {
  let st = [...state.steps.map(step => ({
    ...step,
    completed: step.label === label ? completed : step.completed,
    children: step.children ? step.children.map(s => ({
      ...s,
      completed: s.label === label ? completed : s.completed
    })) : step.children,
  }))]
  setState({...state, steps: st, questions: getQuestions(st)})
}


let state: IStepperState = {
  steps,
  questions: getQuestions(steps)
};

const setState = (newState: IStepperState | Function) => {
  if (typeof newState === "function") {
    state = newState(state);
  } else {
    state = {...state, ...newState};
  }
  listeners.forEach((listener) => {
    listener(state);
  });
};

export interface IStepperState {
  steps: IStep[],
  questions: IQuestion[],
}

const useStepperState = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {state, setState};
};

export default useStepperState;

export {
  steps,
  medigapSteps,
  isMedigapStep
}


