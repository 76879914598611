import React from 'react';
import {ClientViewOutput} from "../../../enrollment-types";
import {useSubscription} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../../shared/EnrollmentApolloClient";

export default function useClientView(id: string) {
  const {data} = useSubscription<{clientCard: ClientViewOutput}>(gql(query), {
    client: client,
    variables: {
      id
    }
  });

  return data?.clientCard;
}

const clientFields = `
    id
    customAnswers
    email
    firstName
    lastName
    phoneNumber
    pin
    profileId
    quoteId
    status
    birthDate
    note
    zip
    scopeOfAppointmentStatus
    medigapQuote {
      id
    }
`;

const query = `
subscription($id: UUID!) {
  clientCard (data:{
    clientId: $id
  }) {
    ${clientFields}
  }
}
`;

