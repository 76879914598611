import React from "react";
import {Box, Button, createStyles, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {colors} from "../../../shared/AppTheme";
import PlanCard, {RoundedButton} from "./PlanCard";
import usePlans from "./hooks/usePlans";
import {PlanFilterContext} from "./PlanFilterContext";
import UpdateZipModal from "../../shared/components/UpdateZipModal";
import {getMedigapQuoteId, setMedigapQuoteId, setQuoteId} from "../../../shared/QuoteId";
import paths from "../config/router-paths";
import Footer from "../Footer";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import withErrorBoundary from "../../../shared/withErrorBoundary";
import {useSnackbar} from "notistack";
import PlanListSort from "./components/PlanListSort";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import {useParams} from "react-router";
import PlanTopFilter from "./PlanTopFilter";
import {
  addDetailsToPlanList,
  MedigapPlanOutputWithDetails
} from "../DetailedPlan/hooks/usePlansByBid";
import {MedigapPlansField, Sort} from "../../../medigap-types";
import {MedigapQuoteContext} from "../../shared/MedigapQuoteContext";
import PlanListEmailModal from "../../shared/components/PlanListEmailModal";
import * as _ from 'lodash';
import useSaveMedigapQuote from "../../../Quote/Medigap/hooks/useSaveMedigapQuote";
import useSaveMedigapFilterState from "./hooks/useSaveMedigapFilterState";
import useCreateMedicareQuoteFromMedigapQuote from "./hooks/useCreateMedicareQuoteFromMedigapQuote";
import {config} from "../config/config";
import * as QueryString from "query-string";
import PraiseCardModal from "../../shared/components/PraiseCardModal";

;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    card: {
      padding: '24px 30px',
      maxWidth: 1000
    },
    disclosure: {
      fontSize: 20,
      fontWeight: 500,
      marginRight: 8,
      marginTop: 1,
    },
    underline: {
      borderBottom: '1px dashed #1C434F',
      cursor: 'pointer'
    },
    county: {
      cursor: 'pointer'
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
    hugeText: {
      fontSize: 32,
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
    },
    thin: {
      fontWeight: 400,
    }
  }),
);

interface PlansDictionary {
  [type: string]: MedigapPlanOutputWithDetails[]
}

function PlanList() {
  const pageSize = 1000;
  let {zip, countyName: county} = useParams();

  const classes = useStyles();
  const [sortBy, setSortBy] = React.useState<MedigapPlansField>(MedigapPlansField.MonthlyPremium);
  const [plans, setPlans] = React.useState<PlansDictionary>({});
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const quote = React.useContext(MedigapQuoteContext);
  const [save] = useSaveMedigapQuote();
  const { enqueueSnackbar } = useSnackbar();
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [filterStateId, setFilterStateId] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [total, setTotal] = React.useState<number>();
  const [request, results] = usePlans();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const [updateZipModalOpen, setUpdateZipModalOpen] = React.useState(false);

  const [createMAQuote] = useCreateMedicareQuoteFromMedigapQuote({
    onCompleted: data => document.location.href = `${config.resultsUrl}?quote=${data.createMedicareQuoteFromMedigapQuote}`
  });

  const {values: filterValues, switchZip} = React.useContext(PlanFilterContext)

  const [praiseOpen, setPraiseOpen] = React.useState(false);

  const [saveFilters] = useSaveMedigapFilterState({
    onCompleted: result => {
      setFilterStateId(result.saveMedigapFilterState);
      setEmailOpen(true);
    }
  })

  React.useEffect(() => {
    window.scrollTo(0, 0)
    const parsedQuery = QueryString.parse(document.location.search);
    if (parsedQuery.skipCreateAccount && parsedQuery.medigapQuote && !localStorage.getItem('praiseCardShown')) {
      setPraiseOpen(true);
      localStorage.setItem('praiseCardShown', 'true');
    }
  }, []);

  zip = zip || filterValues?.zip;
  county = county || filterValues?.county;

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  useDebouncedEffect(() => {
    if (zip) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setPlans({});
      setLoading(true);
      setPage({
        page: 0,
        size: pageSize
      });
    }
  }, 700, [filterValues, sortBy]);

  const quoteId = React.useMemo(() => {
    return quote?.id || getMedigapQuoteId();
  }, [quote?.id])

  React.useEffect(() => {
    if (filterValues && page) {
      const filters = {...filterValues};
      delete filters.selectedPlanCategory;
      request({
        variables: {
          filter: filters,
          page,
          sort: [{
            direction: Sort.Asc,
            field: sortBy
          }]
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !!total && _.flatten(Object.values(plans)).length < total;
  }, [total, Object.keys(plans).length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data.medigapPlans.totalElements)
      setPlans(() => {
        if(results.data) {
          const result = _.groupBy(addDetailsToPlanList(results.data.medigapPlans.data), 'planName')
          setLoading(false);
          return result;
        }
        return {} as PlansDictionary
      });
    }
  }, [results.data]);

  React.useEffect( () => {
    if(results.error) {
      enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'}); // todo add text
    }
  }, [results.error]);

  const onUpdateZipModalClose = React.useCallback((newZip?: string, newCounty?: string) => {
    if (newZip && newCounty && (newCounty !== county || newZip !== zip)) {
      switchZip(newZip, newCounty).then(() => {
        document.location.href = paths.plans + newZip + '/' + newCounty;
      })
    }
    setUpdateZipModalOpen(false);
  }, [zip, county, quoteId, filterValues])

  const openEmailPopup = React.useCallback(() => {
    if (filterValues) {
      saveFilters({
        variables: {
          input: {
            age: filterValues.age,
            benefits: filterValues.benefits,
            companies: filterValues.companies,
            county,
            zip,
            gender: filterValues.gender,
            monthlyPlanPremiumRanges: filterValues.monthlyPlanPremiumRanges,
            planCategory: filterValues.selectedPlanCategory,
            planNames: filterValues.planNames,
            tobacco: filterValues.tobacco,
          }
        }
      })
    }
  }, [filterValues, county, zip]);

  const goToMA = React.useCallback(() => {
    createMAQuote({variables: {medigapQuoteId: quoteId}});
  }, [quoteId]);

  return <>
    <PlanListEmailModal zip={zip} county={county} medigap open={emailOpen && filterStateId} id={filterStateId} onClose={() => {
      setEmailOpen(false);
      setFilterStateId(undefined);
    }} />
    <UpdateZipModal open={updateZipModalOpen}
                    title={'Update your zip code'}
                    subtitle={'Your plan options will update automatically.'}
                    onClose={onUpdateZipModalClose}
                    zip={zip}
                    county={county} />
    {/*<PraiseCardModal
      onClose={() => {
        setPraiseOpen(false);
      }}
      open={praiseOpen}
    />*/}
    <Box flexDirection={'column'} my={'15px'} maxWidth={'800px'} alignItems={'center'}>
      {!phone && <>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>We’ve found {total} Medigap plans for you.</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} mb={3}>
          <Typography className={'fs-18 mt-16'} color={'textPrimary'}>
            Interested in <span className={'pointer'} onClick={goToMA} style={{borderBottom: '1px dotted ' + colors.text.primary}}>Medicare Advantage</span> plans instead?
          </Typography>
        </Box>
        <Box position={'sticky'} top={0} zIndex={2} bgcolor={'#f5f7f6'} pt={1}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} className={'pb-20'}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Showing plans for:</Typography>
              <Box ml={1} className={classes.county} display={'flex'} alignItems={'center'} onClick={() => setUpdateZipModalOpen(true)}>
                <Typography variant={'h4'} color={'textPrimary'} className={classes.bold}>{zip} {filterValues?.county}</Typography>
                <Box ml={1} pt={'5px'}>
                  <img src={'/img/edit.svg'}/>
                </Box>
              </Box>
            </Box>
            <RoundedButton variant={"contained"}
                           classes={{
                             contained: 'ph-16 pv-5'
                           }}
                           onClick={openEmailPopup}>
              <img src={'/img/envelope.svg'} className={'mr-10'} />
              Email me this page</RoundedButton>
          </Box>
        </Box>
      </>}
      <PlanTopFilter />
      <Box mt={'16px'}>
        <PlanListSort onChange={setSortBy} value={sortBy} zip={zip} county={county} />

        {!!filterValues && <>
          {!!Object.keys(plans).length && Object.keys(plans).map(key => <PlanCard plans={plans[key]}
                                                                     zip={zip}
                                                                     countyName={county}
                                                                     key={key} />)}
          {(!Object.keys(plans).length && !loading && results.called) && <Typography color={'textPrimary'}
                                                                                align={'center'}
                                                                                className={'fs-18 bold mt-50 mb-110'}>
            There are no plans available based on your search criteria. Try adjusting your filter settings to see more plans.
          </Typography>}
        </>}

        {loading &&
          <Box textAlign={"center"} my={2}>
            <AscendPreloader />
          </Box>
        }
        {(hasMorePlans && !loading) && <Box textAlign={"center"} mt={'32px'}>
          <Button disabled={loading} className={classes.seeMoreButton} onClick={loadMore}
                  endIcon={<ExpandMoreIcon />}><div style={{textDecoration: 'underline'}}>See more plans</div></Button>
        </Box>}
      </Box>
      <Footer />
    </Box>
  </>
}

export default withErrorBoundary(PlanList);
