import React from "react";
import {
  Box,
  Collapse,
  createStyles,
  IconButton,
  Slide,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendButton from "../../../shared/components/AscendButton";
import {useHistory} from "react-router";
import {ComparisonContext} from "./ComparisonContext";
import ComparisonCard from "./components/ComparisonCard";
import usePlansByBid from "../DetailedPlan/hooks/usePlansByBid";
import {getQuoteId} from "../../../shared/QuoteId";
import paths from "../config/router-paths";
import clsx from 'clsx';
import * as _ from 'lodash';
import {colors} from "../../../shared/AppTheme";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";
import {PlanYear} from "../../../types";

type ComparisonPanelProps = {
  zip: string,
  countyName: string,
  forceHide?: boolean
}
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '7px 30px 13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#F5F7F6',
      boxShadow: '0px -10px 30px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column!important'
      },
    },
    buttonWrapper: {
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 7,
      width: '100%',
      background: colors.custom.green.variant2,
      zIndex: 2000,
    },
    button: {
      width: 378
    }
  }),
);

export default function ComparisonPanel(props: ComparisonPanelProps) {
  const classes = styles();
  const history = useHistory();
  const {list, removeFromCompare} = React.useContext(ComparisonContext);
  const plans = usePlansByBid(props.zip, props.countyName, list, getQuoteId(), GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023, data => {
    Object.values(data).forEach((p, i) => {
      if (!p) {
        removeFromCompare(list[i])
      }
    })
  });
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [showPanel, setShowPanel] = React.useState(true);

  const [plansOpen, setPlansOpen] = React.useState(false);

  const panelToggle = () => setShowPanel(!showPanel);

  const open = React.useMemo(() => {
    return !!list.length && !props.forceHide;
  }, [list])

  return <>
    {(!showPanel && open) && <div className={classes.buttonWrapper} onClick={panelToggle}>
      <Typography className={'fs-18 underline bold white mr-10'}>Show plans selected for comparison</Typography>
      <img src={'/img/arrow-white.svg'} />
    </div>}
    <Slide in={showPanel && open} direction={'up'} style={{position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1200}}>
      <Box className={classes.container}>
        {!phone && <>
          <Typography className={'bold fs-18 mr-20'} color={'textPrimary'}>Select up to 3 plans to<br />compare at one time</Typography>
          {[0,1,2].map(index => <ComparisonCard key={index} plan={plans[index]} />)}
        </>}
        {(phone && !plansOpen) && <AscendButton variant={'outlined'}
                                                className={'fill-width mb-15 mt-10'}
                                                onClick={() => setPlansOpen(true)}>View {list.length} selected plan{list.length > 1 ? 's' : ''}</AscendButton>}
        {(phone && plansOpen) && <Collapse in={plansOpen}>
          <AscendButton variant={'text'}
                        className={'fill-width mb-15 mt-10 green'}
                        onClick={() => setPlansOpen(false)}>Hide selected plans</AscendButton>
          {_.fill(Array(list.length), 0).map((i, index) => <ComparisonCard key={index} plan={plans[index]} />)}
        </Collapse>}
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <AscendButton className={clsx({'fill-width': phone})}
                        variant={'contained'}
                        onClick={() => history.push(paths.comparison + props.zip + '/' + props.countyName)}>Compare</AscendButton>
          <Typography className={'green fs-14 pointer underline mt-5'} onClick={panelToggle}>Minimize</Typography>
        </Box>
      </Box>
    </Slide>
  </>
}
