import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {PropsWithChildren} from "react";
import {Box, Collapse, IconButton} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    button: {
      backgroundColor: 'rgba(164, 180, 185, 0.1)',
      padding: 10,
    }
  }),
);

const CollapseSection = (props: {title: JSX.Element, defaultOpen?: boolean} & PropsWithChildren<any>) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(typeof props.defaultOpen !== 'undefined' ? props.defaultOpen : true);
  return <>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
      {props.title}
      <IconButton className={classes.button} onClick={() => setExpanded(!expanded)}>
        <img src={'/img/arrow.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
      </IconButton>
    </Box>
    <Collapse in={expanded}>
      <Box mt={'12px'}>
        {props.children}
      </Box>
    </Collapse>
  </>
}

export default CollapseSection;
