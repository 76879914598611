import React from "react";
import { usePlanFilterData} from "./hooks/usePlanFilterData";
import {QueryLazyOptions} from "@apollo/react-hooks";
import {MedigapFilterItem} from "../../../medigap-types";
import {PreloaderContext} from "../../shared/PreloaderContext";

interface PlanFilterDataContextState {
  getPlanFilterData: (data?: QueryLazyOptions<{zip: string}>) => void,
  planFilterData: PlanFilterData | undefined,
}

export const PlanFilterDataContext = React.createContext<PlanFilterDataContextState>({
  getPlanFilterData: () => {},
  planFilterData: undefined
});

export function PlanFilterDataProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const [planFilterData, setPlanFilterData] = React.useState<PlanFilterData>();

  const [request, data] = usePlanFilterData();
  const {setPreloader} = React.useContext(PreloaderContext);

  React.useEffect(() => {
    if (data.data) {
      setPlanFilterData({
        companies: transformListItem(data.data.medigapCompanies),
        planNames: transformListItem(data.data.medigapPlanNames),
        monthlyPlanPremium: transformListItem(data.data.medigapMonthlyPlanPremium),
        benefits: transformListItem(data.data.medigapBenefits),
      })
    }
  }, [data]);

  React.useEffect(() => {
    setPreloader('planFilterData', data.loading)
  }, [data.loading]);

  return <PlanFilterDataContext.Provider value={{getPlanFilterData: request, planFilterData}}>
    {props.children}
  </PlanFilterDataContext.Provider>

}

export type ListItem = {
  value: any,
  label: any,
  disabled?: boolean,
  tooltip?: string
}

interface PlanFilterData {
  companies: ListItem[],
  planNames: ListItem[],
  monthlyPlanPremium: ListItem[],
  benefits: ListItem[],
}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}




function transformListItem(collection: MedigapFilterItem[]): ListItem[] {
  return collection.map((item: any) => ({
      value: item.key,
      label: item.name,
      disabled: false
    })
  )
}
