import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Collapse, IconButton, Typography} from "@material-ui/core";
import useVideoCategories from "../hooks/useVideoCategories";
import useVideoCategoriesData from "../hooks/useVideoCategoriesData";
import clsx from "clsx";
import {colors} from "../../../shared/AppTheme";
import {getQuestions} from "../../../Questionnaire/hooks/useStepperState";
import VideoStepper, {IStep, IStepperState} from "./VideoStepper";
import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      transform: "rotate(-180deg)",
      transition: "all 0.3s"
    },
    iconOpen: {
      transform: "rotate(0) !important",
    },
    button: {
      background: colors.text.primary,
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      "&:hover": {
        background: colors.text.primary,
      }
    },
  }),
);

const VideoCategories = (props: RouteComponentProps<any>) => {
  const classes = useStyles();
  const {state, setState} = useVideoCategories();
  const {data} = useVideoCategoriesData();

  let steps: IStep[] = [
    {
      label: "Learn about Medicare",
      firstStep: '',
      active: true
    },
    {
      label: "Plan recommendations",
      firstStep: '',
      labelClassName: 'pointer',
      onClick: () => props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.quiz1a)
    },
    {
      label: "Get your quotes",
      firstStep: '',
      completed: true,
    },
  ];
  const initialStepperState: IStepperState = {
    steps,
    questions: getQuestions(steps)
  };

  const [stepperState, setStepperState] = React.useState(initialStepperState);

  React.useEffect(() => {
      if (data?.videoCategories) {
        const categories = _.sortBy(data?.videoCategories.map(c => ({...c, videos: _.sortBy(c.videos, 'sortingOrder')})), 'sortingOrder');
        setState({categories})
        setStepperState(prevState => {
          const result = [...steps];
          result[0].children = categories.map((c, i) => ({
            label: c.title,
            firstStep: '',
            active: !i,
            id: c.id,
            onClick: () => onClick(c.id)
          }));
          return {
            steps: result,
            questions: getQuestions(result)
          }
        })
      }
  }, [data]);

  React.useEffect(() => {
    if (state.active) {
      onClick(state.active, true)
    }
  }, [state.active]);

  const onClick = (id: string, skipUpdate?: boolean) => {
    if (!skipUpdate) {
      setState({active: id});
    }
    setStepperState(prevState => {
      const result = {...prevState};
      result.steps[0].children = result.steps[0]?.children?.map(c => ({
        ...c,
        active: c.id === id
      }));
      return result
    })
  }

  return <div>
    <Typography className={'fs-16 mv-20'} color={'textPrimary'}>These short videos will help explain the different parts of Medicare, and how they’re relevant to you.</Typography>
    <VideoStepper state={stepperState} />
  </div>;
}

export default VideoCategories;
