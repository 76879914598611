import React from "react";
import {ComparisonContext} from "../../PlanComparison/ComparisonContext";
import {QuoteContext} from "../../../shared/QuoteContext";
import {FavoritesContext} from "../../../shared/FavoritesContext";
import useTemporalData from "../../../../shared/hooks/useTemporalData";
import {AdminContext} from "../../../shared/AdminContext";
import {Box, createStyles, Divider, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {useScrollPosition} from "../../../../shared/useScrollPosition";
import useGetEHealthLinkRequest from "../../../../shared/hooks/useGetEHealthLinkRequest";
import {useHistory} from "react-router";
import {planStyles, RoundedButton} from "../../PlanList/PlanCard";
import {config} from "../../config/config";
import AscendButton from "../../../../shared/components/AscendButton";
import AscendTooltip from "../../../../shared/components/AscendTooltip";
import AscendCard from "../../../../shared/components/AscendCard";
import AscendCheckbox from "../../../../shared/components/AscendCheckbox";
import clsx from "clsx";
import {PlanViewProps} from "../DetailedPlan";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../../shared/AppTheme";
import {getCompanyLogo, getMedigapCompanyLogo} from "../../../../shared/utils";
import TooltipedTitle from "../../../shared/components/TooltipedTitle";
import StyledRating from "../../../shared/components/StyledRating";
import {getQuoteId} from "../../../../shared/QuoteId";
import {PlanTitle} from "../../PlanList/components/PlanTitle";
import {PlanParam} from "./PlanParam";
import PlanData from "../../config/PlanData";
import AscendDivider from "../../../../shared/components/AscendDivider";
import moment from "moment";
import {PlanFilterContext} from "../../PlanList/PlanFilterContext";


const styles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {
      display: 'flex',
      position: 'sticky',
      top: 43,
      zIndex: 2,
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    withoutShadow: {
      boxShadow: 'none!important',
    },
    headerCardFixed: {
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      top: 0,
      height: 117,
      width: 'calc(100% - 40px)',
      padding: '8px 14px 36px 8px',
      zIndex: 2,
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '12px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
  })
);

const HeaderCard = (props: PlanViewProps & {hideFixed?: boolean, hideButtons?: boolean}) => {
  let {zip, countyName, plan, setModalOpen, setEmailOpen} = props;
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const {preferredDrugs, pin, id} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const adminContext = React.useContext(AdminContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const {put} = useTemporalData();
  const scrollPosition = useScrollPosition();
  const [collapsed, setCollapsed] = React.useState(false);
  const {values: filterValues} = React.useContext(PlanFilterContext)

  React.useEffect(() => {
    if (scrollPosition > 450 && !collapsed) {
      setCollapsed(true)
    } else {
      if (scrollPosition < 50) {
        setCollapsed(false)
      }
    }
  }, [scrollPosition]);

  const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
    onCompleted: data => {
      if (data?.generateEhealthPlanLink) {
        navigator.clipboard.writeText(data?.generateEhealthPlanLink)
      }
    }
  })

  const history = useHistory();
  const ownClasses = styles();
  const classes = planStyles();

  const copyEHealthLink = () => {
    generateEhealthPlanLink({variables: {bidId: plan.key, countyName, zip}})
  }

  const onEnrollClick = () => {
    const quoteId = getQuoteId();
    document.location.href = `${config.enrollUrl}enroll/${plan.key}/${zip}/${countyName}${id ? '/' + id : ''}?medigap&age=${filterValues?.age}&tobacco=${filterValues?.tobacco}&gender=${filterValues?.gender}&effectiveDate=${filterValues?.effectiveDate || '2021-07-12'}`
  }

  const EnrollSection = () => {
    if (!phone) {
      return <div className={ownClasses.enrollSection}>
        {!!adminContext && <Box position={'absolute'} right={5} top={5}
                                onClick={copyEHealthLink}
                                className={`${classes.excl} fs-12 pointer underline`}>E Health</Box>}
        <Box display={"flex"} flexDirection={"column"} flex={1}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography className={classes.price}>{plan?.monthlyPremium || "N/A"}</Typography>
            <Box mb={"3px"}>
              <img src={"/img/info-green.svg"}/>
            </Box>
          </Box>
          <Typography variant={"body2"} className={classes.bold} color={'textPrimary'}>Monthly plan premium</Typography>
        </Box>
        {!props.hideButtons && <Box mt={"14px"}>
          <AscendButton className={classes.enrollButton}
                        onClick={onEnrollClick}
                        variant={"contained"}>
            Enroll Now
          </AscendButton>
        </Box>}
      </div>
    } else {
      return <div className={ownClasses.enrollSection}>
        <div className={'flex-column drug-calculation'}>
          <AscendTooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
            <Box display={'flex'} alignItems={'flex-start'}>
              <Typography className={`mr-15 ${classes.price}`}>{plan.monthlyPremium || 'N/A'}</Typography>
              <img className={'w-15 h-15 mt-10'} src={'/img/info-green.svg'} />
            </Box>
          </AscendTooltip>
          <Typography color={'textPrimary'} className={'bold fs-14'}>Monthly plan premium</Typography>
          <div className={'mt-5'}>
            <AscendTooltip placement={'top'} arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}>
              <Typography variant={'h5'} className={classes.excl}>plus standard Part B premium</Typography>
            </AscendTooltip>
          </div>
        </div>
      </div>
    }

  }

  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <div className={classes.bigLogo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title;
      return <Typography variant={'h2'} className={classes.bold} color={'textPrimary'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);

  return <AscendCard className={`${ownClasses.headerCard} ${collapsed && ownClasses.withoutShadow}`}>
    {(phone && !props.hideFixed && collapsed) && <AscendCard className={ownClasses.headerCardFixed}>
      <div className={'flex-column'}>
        {Logo}
        <Box maxHeight={'48px'} overflow={'hidden'} mt={'11px'}>
          <Typography color={'textPrimary'} className={'bold fs-20 lh-24'}>Plan {plan.planName}</Typography>
        </Box>
      </div>
      <div className={'flex-column'}>
        <Box mt={'-8px'}>
          <Typography className={`${classes.price} mr-0`} align={'right'}>{plan?.monthlyPremium || 'N/A'}</Typography>
        </Box>
        <Box mt={'-8px'}>
          <Typography className={'bold fs-12 lh-13'} align={'right'} color={'textPrimary'}>plus standard Part B<br />premium</Typography>
        </Box>
      </div>
    </AscendCard>}
    <Box display={"flex"} flexDirection={phone ? 'column' : 'row'} p={phone ? '0 12px' : '7px 15px 7px 0'}>
      <div className={classes.infoWrapper}>
        <Box width={"100%"}>
          {!phone && <>
            <PlanTitle plan={plan} />
            <AscendDivider my={1} />
            <div className={'flex'}>
              <div className={'flex-column'}>
                <TooltipedTitle title={'Annual deductible'}
                                tooltip={''}/>
                <div className={'flex'}>
                  <PlanParam label={'Part A:'} value={plan?.partADeductible || 'n.a.'}/>
                  <div className={'w-20'} />
                  <PlanParam label={'Part B:'} value={plan?.partBDeductible || 'n.a.'}/>
                </div>
              </div>
              <Box flex={2} className={'flex-column ml-20'}>
                <div className={'mb-4'}>
                  <TooltipedTitle title={'Copays / Coinsurance'}
                                  tooltip={''}/>
                </div>
                <PlanParam label={'Your typical cost for approved Part B services:'} value={plan?.partBCoinsurance || 'n.a.'}/>
              </Box>
            </div>
            {!props.hideButtons && <>
              <AscendDivider my={1} />
              <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'} mt={"20px"}>
                <Box display={'flex'}>
                  <div className={ownClasses.iconButton} onClick={() => setEmailOpen(true)}>
                    <img src={'/img/message.svg'}/>
                    <div>Email me this plan</div>
                  </div>
                </Box>
                <AscendCheckbox variant={"standart"} controlClassName={clsx(classes.checkbox, ownClasses.checkbox)}
                                labelClassName={classes.checkboxLabel}
                                label={"Add to compare"} checked={isInCompare(plan.key)}
                                onChange={(e, checked) => {
                                  checked ? addToCompare(plan.key) : removeFromCompare(plan.key)
                                }}/>
              </Box>
            </>}
          </>}
          {(phone && !collapsed) && <>
            <PlanTitle plan={plan} />
          </>}
        </Box>
      </div>
      {!collapsed && <EnrollSection/>}
      {(phone && !props.hideButtons && !collapsed) && <Box mt={"14px"}>
        <AscendButton className={classes.enrollButton}
                      onClick={onEnrollClick}
                      variant={"contained"}>
          Enroll Now
        </AscendButton>
      </Box>}
    </Box>
  </AscendCard>
}

export default React.memo(HeaderCard);
