import React from "react";
import {Box} from "@material-ui/core";
import * as _ from 'lodash';
import AscendStepper, {IStep} from "../shared/SidePanel/AscendStepper";
import {v4 as uuidv4} from "uuid";
import Preloader from "../shared/Preloader";
import {
  navHistory,
  QuestionnaireActionTypes,
  QuestionnaireContext,
  withQuestionnaireContextProvider
} from "./QuestionnaireContextProvider";
import useStepperState, {isMedigapStep, medigapSteps, setCompletedByLabel, steps} from "./hooks/useStepperState";
import Layout from "../shared/Layout";
import {Route, RouteComponentProps, Switch} from "react-router";
import paths from "./config/router-paths";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import useQuestionnaireRequest from "../shared/hooks/useQuestionnaireRequest";
import {getQuoteId} from "../shared/QuoteId";
import QuestionnaireStepper from "./components/QuestionnaireStepper";

function Questionnaire(props: RouteComponentProps<{routeName: string}>) {
  const [showQuestionnaire, setShowQuestionnaire] = React.useState<boolean>(false);
  const [{pin}, dispatch] = React.useContext(QuestionnaireContext);
  const {state, setState} = useStepperState();
  const quoteId = getQuoteId();

  const [getRequest, requestData] = useQuestionnaireRequest(data => {
    dispatch({
      type: QuestionnaireActionTypes.INIT_STATE,
      payload: data,
    })
    setShowQuestionnaire(true)
  })

  React.useEffect(() => {
    if (quoteId) {
      setShowQuestionnaire(false)
      getRequest({
        variables: {
          id: quoteId
        }
      })
    } else {
      setShowQuestionnaire(true)
    }
  }, [quoteId])

  React.useEffect(() => {
    navHistory.save(props.match.params.routeName || '');
    if (isMedigapStep(props.match.params.routeName)) {
      setState({...state, steps: medigapSteps});
    } else {
      setState({...state, steps: steps});
    }
    for (const step of state.steps) {
      let completed = step.questions?.some(q => {
        return q.link === (props.match.params.routeName || '')
      });
      setCompletedByLabel(step.label, !completed);
      if (!completed && step.children?.length) {
        for (const child of step.children) {
          let childCompleted = child.questions?.some(qu => qu.link === props.match.params.routeName)
          setCompletedByLabel(child.label, !childCompleted);
          if (childCompleted) {
            completed = true;
            break;
          }
        }
      }
      if (completed) {
        break;
      }
    }
    window.scrollTo(0, 0)
  }, [props.match.params.routeName])


  return <Layout pin={pin} SideComponent={QuestionnaireStepper} {...props}>
    <Box mt={'8px'} minHeight={'700px'} id={'wrapper'} position={'relative'}>
      <Preloader in={requestData.loading} />
      {showQuestionnaire && <TransitionGroup>
        <CSSTransition
          key={props.location.key}
          classNames="question"
          timeout={300}
          onEnter={() => {
            document.body.scrollIntoView({behavior: "auto"});
            document.body.style.overflowY = 'hidden';
          }}
          onExited={() => {
            document.body.style.overflowY = 'auto';
          }}
        >
          <Switch location={props.location}>
            {state.questions.map((route: any) => <Route exact key={route.link} path={paths.questionnaire + '/' + route.link} component={route.component} />)}
          </Switch>
        </CSSTransition>
      </TransitionGroup>}
    </Box>
  </Layout>;
}

export default React.memo(withQuestionnaireContextProvider(Questionnaire));
