import React from "react";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import '../../Medigap/PlanList/components/styles.scss';

const TooltipedTitle = React.memo((props: {
  title: string | JSX.Element,
  tooltip: string,
  className?: string,
  imgSrc?: string,
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}) => <div className={'flex'}>
  <AscendTooltip placement={'top'} arrow title={props.tooltip}>
    <div className={'flex'}>
      <div className={`card-title ${props.className || ''}`} >{props.title}</div>
      <img {...props.imgProps} className={`mt-4 ${props.imgProps?.className || ''}`} src={props.imgSrc || '/img/info.svg'}></img>
    </div>
  </AscendTooltip>
</div>)

export default TooltipedTitle;
