import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedigapFilterStateOutput, QueryMedigapFilterStateArgs} from "../../../../medigap-types";

export function useMedigapFilterState() {
  return useLazyQuery<{medigapFilterState: MedigapFilterStateOutput}, QueryMedigapFilterStateArgs>(gql(filtersQuery))
}

const filtersQuery = `
  query($id: UUID!) {
    medigapFilterState(id: $id) {
      age
      benefits
      companies
      county
      effectiveDate
      gender
      id
      monthlyPlanPremiumRanges
      planCategory
      planNames
      tobacco
      zip
    }
  }
`;
