import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootRadioGroup: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column!important' ,
      },
    },
    radioGroup: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row!important'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column!important' ,
      },
    },
    radio: {
      marginLeft: 16,
      display: 'flex',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: 16
      },
    },
    inputContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column!important'
      },
    },
    input: {
      marginLeft: 16,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: 8
      },
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        padding: '0 40px',
        '& > button': {
          '&:last-child': {
            marginBottom: 10,
          },
          minWidth: 200
        }
      },
    },
    hidden: {
      display: 'none!important'
    }
  }));

export default useCommonStyles;
