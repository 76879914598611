import React from 'react';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {UserProfileOutput} from "../../enrollment-types";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useUserProfile() {
  const [userProfile, setUserProfile] = React.useState<UserProfileOutput>();
  const request = useQuery<{userProfile: UserProfileOutput}>(gql(query), {
    client: client,
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    setUserProfile(request?.data?.userProfile)
  }, [request.data])

  return {userProfile, request};
}

export function useUserProfileLazy() {
  return useLazyQuery<{userProfile: UserProfileOutput}, {profileId?: string}>(gql(query), {
    client: client,
    fetchPolicy: "no-cache"
  });
}

const query = `
{
  userProfile {
    aptNumber
    birthDate
    city
    email
    firstName
    gender
    isEmailVerified
    lastName
    medicareNumber
    medicarePartAStartedAt
    medicarePartBStartedAt
    phoneNumber
    quoteId
    medigapQuoteId
    state
    streetName
    unit
    userId
    zip
    countyName
    hasNoPreferredDoctors
    hasNoPreferredPharmacies
    hasNoPrescriptionsDrugs
  }
}
`
