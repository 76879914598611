import React from "react";
import {RouteComponentProps, useHistory} from "react-router";
import Shared from "../../shared/questions/Step9a";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import {config} from "../../config/config";

function Step9a(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);

  return <>
    <Shared quote={quote} dispatch={dispatch}
                 onNextStep={() => {
                   if ((window as any).product === 'short-medigap') {
                     document.location.href = config.medigapUrl as string
                   } else {
                     document.location.href = config.userFlowUrl as string
                   }
                 }}
                 {...props} />
  </>;
}

export default Step9a as any;
