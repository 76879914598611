import React from "react";
import {Box, createStyles, RadioGroup, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendRadio from "../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {withTitle} from "../../shared/components/withTitle";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import AscendInfo from "../../shared/components/AscendInfo";
import {questionsRoutes} from "../hooks/useStepperState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    radio: {
      alignItems: 'flex-start',
      height: 218,
      display: 'flex',
      flex: 1,
      width: 320,
      maxWidth: 320,
    },
    img: {
      paddingBottom: '15px'
    },
    centered: {
      justifyContent: 'center',
    },
    textCentered: {
      textAlign: 'center'
    }
  })
);
const formName = 'STEP_5a4';

function Step5a4(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [enrolled, setEnrolled] = React.useState<boolean>(state.STEP_5a4?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  React.useEffect(() => {
    if (typeof state.STEP_5a4?.value !== 'undefined') {
      setEnrolled(!!state.STEP_5a4?.value)
    }
  }, [state.STEP_5a4?.value])

  const notEnrolled = <Box p={'27px 0 12px 24px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <img src={'/img/no-enroll.svg'} className={classes.img} />
    <div>No, not yet</div>
    <Box mt={1} textAlign={'center'}>
      <Typography variant={'body2'} color={'textSecondary'}>Pick this if you are not sure</Typography>
    </Box>
  </Box>

  const isEnrolled = <Box p={'27px 0 0'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <img src={'/img/enroll.png'} className={classes.img} />
    <div>Yes, I’m enrolled</div>
    <Box mt={1} textAlign={'center'}>
      <Typography variant={'body2'} color={'textSecondary'} className={classes.textCentered}>If you are enrolled, you will have a red, white and blue card.</Typography>
    </Box>
  </Box>

  const submit = () => {
    if (!enrolled) {
      dispatch({
        type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: formName, value: enrolled, form: formName}
      })
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step6a)
    } else {
      dispatch({
        type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: formName, value: enrolled, form: formName}
      })
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step8);
    }
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'}>
      <RadioGroup defaultValue="female" className={clsx(commonClasses.radioGroup, classes.centered)}>
        <AscendRadio checked={enrolled === false} controlClassName={classes.radio}
                     onChange={(e: any, checked: boolean) => setEnrolled(checked ? false : true)} value="female" label={notEnrolled} />
        <Box className={commonClasses.radio}>
          <AscendRadio checked={enrolled} controlClassName={classes.radio}
                       onChange={(e: any, checked: boolean) => setEnrolled(checked ? true : false)} value="male" label={isEnrolled} />
        </Box>
      </RadioGroup>
    </Box>
    <AscendInfo open={true}
                description={'You must be enrolled in both Medicare Part A and B before you can enroll in a Medicare Advantage or Medicare Supplement plan.'}/>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack()
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'} disabled={typeof enrolled === 'undefined'} variant={'contained'} onClick={submit}>Continue</AscendButton>
    </Box>
  </>
}

export default withTitle(
  Step5a4,
  (state: IQuestionnaireState) => `Thanks, ${state.basicInfo?.firstName}! Are you currently already\n enrolled in any parts of Medicare?`,
  ``
)
