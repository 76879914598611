import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";
import {MutationSaveMedigapEnrollmentArgs} from "../../enrollment-types";

export default function useSaveMedigapEnrollment(onCompleted?: (data: any) => void) {
  return useMutation<{saveMedigapEnrollment: string}, MutationSaveMedigapEnrollmentArgs>(
    gql(saveEnrollmentMutation),
    {
      onCompleted,
      client: client
    }
  );
}

const saveEnrollmentMutation = `
mutation ($input: MedigapEnrollmentInput!) {
  saveMedigapEnrollment(input: $input)
}
`;
