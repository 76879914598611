import React, {PropsWithChildren} from "react";
import {Box, Grid} from "@material-ui/core";
import EnrollmentStepper from "./component/EnrollmentStepper";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import paths from "./config/router-paths";
import Enroll from "./Enroll";
import EnrollmentForm from "./EnrollmentForm";
import Receive from "./Receive";
import Layout from "../shared/Layout";
import CreateAccount from "./CreateAccount";
import * as QueryString from "query-string"
import MedigapEnrollmentForm from "./MedigapEnrollmentForm";

export default (props: RouteComponentProps<{ countyName?: string }>) => {
  const params = QueryString.parse(document.location.search);
  const [isMedigap, setIsMedigap] = React.useState(typeof params.medigap !== "undefined");

  return <Layout SideComponent={EnrollmentStepper} {...props}>
    <div className={'h-8'} />
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Switch>
          <Route exact path={paths.receive} component={(props: RouteComponentProps<any>) => <Receive isMedigap={isMedigap} {...props} />} />
          <Route exact path={paths.enroll + '/:planId/:zip/:countyName/:quoteId?'}
                 component={(props: RouteComponentProps<any>) => <Enroll year={params.year as string || '2022'} isMedigap={isMedigap} {...props} />} />
          <Route exact path={paths.personalDetails + '/:planId/:zip/:countyName/:quoteId?'}
                 component={(props: RouteComponentProps<any>) => <EnrollmentForm isMedigap={isMedigap}
                                                                                 effectiveDate={params.effectiveDate as string}
                                                                                 age={params.age as string}
                                                                                 gender={params.gender as string}
                                                                                 tobacco={params.tobacco as string}
                                                                                 year={params.year as string || '2022'}
                                                                                 {...props} />} />
          <Route exact path={paths.personalDetailsExtended + '/:planId/:zip/:countyName/:quoteId?'}
                 component={(props: RouteComponentProps<any>) => <MedigapEnrollmentForm effectiveDate={params.effectiveDate as string}
                                                                                 age={params.age as string}
                                                                                 gender={params.gender as string}
                                                                                 tobacco={params.tobacco as string}
                                                                                 {...props} />} />
          <Route exact path={paths.createAccount + '/:planId/:zip/:countyName/:quoteId?'} component={CreateAccount} />
      </Switch>
    </Box>
  </Layout>
}

