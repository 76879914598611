import React from 'react';
import './App.css';
import '../../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {client, setErrorHandler} from "../../shared/ApolloClient2022";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import paths from "./config/router-paths";
import DetailedPlan from "./DetailedPlan/DetailedPlan";
import {ComparisonProvider} from "./PlanComparison/ComparisonContext";
import PlanComparison from "./PlanComparison/PlanComparison";
import EditPreferences from "./PlanList/EditPreferences";
import {FavoritesProvider} from "./shared/FavoritesContext";
import ZipSelection from "./PlanList/ZipSelection";
import {QuoteProvider} from "./PlanList/QuoteContext";
import {getQuoteId} from "../../shared/QuoteId";
import {KeycloakProvider} from "../../shared/KeycloakContext";
import {keycloak} from "../../shared/keycloak";
import {SnackbarProvider} from "notistack";
import Favorites from "./Favorites/Favorites";
import LayoutWithAdminNotification from "./PlanList/LayoutWithAdminNotification";
import {AdminContextProvider} from "./PlanList/AdminContext";
import {getClientId} from "../../shared/ClienId";
import DashboardLayout from "./PlanList/DashboardLayout";
import * as QueryString from "query-string";
import {getStoredUser} from "../../shared/StoredUser";
import Completed from "./Completed/Completed";
import {getCId, getUId} from "../../shared/utils";

function App() {

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  React.useEffect(() => {
    if ((window as any).hj) {
      (window as any).hj('identify', getCId(), {
        email: getStoredUser()?.email,
        c_id: getCId(),
        uid: getUId(),
      })
    } else {
      console.warn('No hotjar on the page')
    }
  }, []);

  const params = QueryString.parse(document.location.search);

  if (params.flow) {
    localStorage.setItem('flow', params.flow as string);
  }

  /*if (params.complianceReview === 'true' && !localStorage.getItem('allowAccess')) {
    localStorage.setItem('allowAccess', 'true');
  }

  if (!localStorage.getItem('allowAccess')) {
    return null;
  }*/

  return (
    <ComparisonProvider>
      <SnackbarProvider preventDuplicate classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
        <KeycloakProvider keycloak={keycloak}>
          <ApolloProvider client={client}>
            <AdminContextProvider clientId={getClientId()}>
              <QuoteProvider quoteId={getQuoteId()}>
                <FavoritesProvider>
                  <LayoutWithAdminNotification>
                    <BrowserRouter>
                      <Switch>
                        <Route exact path={paths.plans} component={ZipSelection} />
                        <Route path={paths.plans + ':zip/:countyName'} component={DashboardLayout} />
                        <Route exact path={paths.completed + ':zip/:countyName/:planId'} component={Completed} />
                        <Route exact path={paths.planDetails + ':zip/:countyName/:planId'} component={DetailedPlan} />
                        <Route exact path={paths.comparison + ':zip/:countyName'} component={PlanComparison} />
                        <Route exact path={paths.favorites + ':zip/:countyName'} component={Favorites} />
                        <Route exact path={paths.editPreferences + ':zip/:countyName'} component={EditPreferences} />
                        <Route exact path={paths.home}>
                          <Redirect to={paths.plans + document.location.search} />}
                        </Route>
                      </Switch>
                    </BrowserRouter>
                  </LayoutWithAdminNotification>
                </FavoritesProvider>
              </QuoteProvider>
            </AdminContextProvider>
          </ApolloProvider>
        </KeycloakProvider>
      </SnackbarProvider>
    </ComparisonProvider>
  );
}

export default App;
