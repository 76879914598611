import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, Grid, RadioGroup} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";

const formName = 'STEP_2a';

function Step2a(props: RouteComponentProps<any>) {
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [value, setValue] = React.useState<Step2aAnswers>(state?.STEP_2a?.value?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        value: {
          questionText: 'Which best describes your situation?',
          answerText: answers[value],
          value
        },
        form: formName
      }
    })
    if (value === Step2aAnswers.None) {
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step2b1)
    } else {
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step2a1)
    }
  };

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-32'} style={{maxWidth: 500}}>
      <RadioGroup>
        <AscendRadio checked={value === Step2aAnswers.Turning}
                     onChange={() => {setValue(Step2aAnswers.Turning)}}
                     label={answers[0]} />
        <AscendInfo open={value === Step2aAnswers.Turning}
                    description={<p style={{margin: '0'}}>To avoid penalties, it’s usually best to enroll in Medicare during your <strong>Initial Enrollment Period (IEP)</strong>. Your IEP is 7 months long, and it starts 3 months before the month you turn 65.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2aAnswers.Over}
                     onChange={() => {setValue(Step2aAnswers.Over)}}
                     label={answers[1]} />
        <AscendInfo open={value === Step2aAnswers.Over}
                    description={<p style={{margin: '0'}}>If you previously had coverage through your employer, it’s best to enroll in Medicare as soon as you can. After losing your employer coverage, you have 8 months to enroll in Original Medicare and 2 months to enroll in Medicare Advantage or a Prescription Drug Plan.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2aAnswers.Under}
                     onChange={() => {setValue(Step2aAnswers.Under)}}
                     label={answers[2]} />
        <AscendInfo open={value === Step2aAnswers.Under}
                    description={<p style={{margin: '0'}}>You're eligible to enroll in Original Medicare if you're under 65 and have been receiving Social Security Disability Insurance (SSDI) benefits for at least 24 months. You also have a 7-month window to enroll in a Medicare Advantage or Prescription Drug Plan, which starts 3 months before your 25th month of receiving disability benefits. </p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2aAnswers.None}
                     onChange={() => {setValue(Step2aAnswers.None)}}
                     label={answers[3]} />
        <AscendInfo open={value === Step2aAnswers.None}
                    description={<p style={{margin: '0'}}>No problems!  You can still continue and browse our marketplace. Keep in mind that you can only enroll in Medicare during specific enrollment periods. If you’re not sure when your enrollment period starts, you can always call us for help.</p>}/>

      </RadioGroup>
    </div>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} disabled={typeof value === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </Grid>;
}

const answers = [
  "I am turning or recently turned 65",
  "I am over 65 and losing employer coverage",
  "I am under 65 and receiving Social Security Disability Insurance (SSDI) benefits",
  "None of the above"
]

export default withTitle(
  Step2a,
  () => 'We can definitely help you with that. To better\n help you, which best describes your situation?',
  ''
);

export enum Step2aAnswers {
  Turning,
  Over,
  Under,
  None
}
