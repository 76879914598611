import React from 'react';
import {Box} from "@material-ui/core";

export default function AscendPreloader() {
    return <Box textAlign={"center"} my={2}>
      <Box mt={'39px'} height={'100px'} position={'relative'}>
        <div className="load">
          <hr/>
          <hr/>
          <hr/>
          <hr/>
        </div>
      </Box>
    </Box>
}
