import React from 'react';
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";
import {EnrollmentOutput} from "../../enrollment-types";

export default function useMyEnrollment(): EnrollmentOutput | undefined {
  const [enrollment, setEnrollment] = React.useState<EnrollmentOutput>();
  useQuery<{getMyEnrollment: EnrollmentOutput}>(gql(request), {
    onCompleted: data => setEnrollment(data.getMyEnrollment),
    client: client
  });
  return enrollment;
}

const request = `{
    getMyEnrollment {
      bidId
      birthDate
      communicationPreference
      email
      firstName
      createdAt
      approvedAt
      gender
      id
      isElectronicallyCommunications
      lastName
      mailingAddress {
        aptNumber
        city
        state
        streetName
        zip
      }
      medicareNumber
      medicarePartAStartedAt
      medicarePartBStartedAt
      permanentAddress {
        aptNumber
        city
        state
        streetName
        zip
      }
      phoneNumber
      preferredLanguage
      quoteId
      status
      userId
    }
  }`
