import React from "react";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  Theme,
  Typography
} from "@material-ui/core";
import {Route, RouteComponentProps, Switch} from "react-router";
import Layout from "../shared/Layout";
import FormTextInput from "../shared/components/FormTextInput";
import AscendButton from "../shared/components/AscendButton";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {makeStyles} from "@material-ui/core/styles";
import AscendCheckbox from "../shared/components/AscendCheckbox";
import CollapsableCard from "../shared/components/CollapsableCard";
import useScopeOfAppointment from "./hooks/useScopeOfAppointment";
import AscendPreloader from "../shared/components/AscendPreloader";
import useSaveScopeOfAppointment from "./hooks/useSaveScopeOfAppointment";
import AscendModal from "../shared/components/AscendModal";
import {useSnackbar} from "notistack";
import ContactsSection from "../shared/components/ContactsSection";
import useOpenedScopeOfAppointment from "./hooks/useOpenedScopeOfAppointment";
import useConfirmScopeOfAppointment from "./hooks/useConfirmScopeOfAppointment";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";

const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  phoneNumber: yup.string(),
  email: yup.string().email('Enter a valid email address'),
});

interface ScopeOfAppointmentFromState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxWrapper: {
      alignItems: 'flex-start',
      background: 'white!important'
    },
    ul: {
      paddingInlineStart: '18px'
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
    }
  })
);

export default function Soa(props: RouteComponentProps<{ id?: string }>) {
  const classes = useStyles();
  const {id} = props.match.params;
  const { enqueueSnackbar } = useSnackbar();

  const [agreement, setAgreement] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const {register, handleSubmit, errors, formState, watch, control, setError, clearErrors} = useForm<ScopeOfAppointmentFromState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  const [getSoa, {data}] = useScopeOfAppointment();
  const [save] = useConfirmScopeOfAppointment();
  const [openSoa] = useOpenedScopeOfAppointment();

  React.useEffect(() => {
    if (id) {
      getSoa({variables: {soaId: id}});
      openSoa({variables: {id: id}})
    }
  }, [id]);

  React.useEffect(() => {
    if (agreement) {
      setOpen(true)
    }
  }, [agreement])

  const userInfo = React.useMemo(() => {
    if (data?.scopeOfAppointment) {
      return data.scopeOfAppointment;
    }
  }, [data])

  const onSubmit = React.useCallback((values: ScopeOfAppointmentFromState) => {
    if (userInfo?.id) {
      save({
        variables: {
          userInput: {
            email: values.email,
            firstName: values.firstName,
            id: userInfo.id,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber
          }
        }
      }).then(() => {
        setModalOpen(true)
      }).catch(() => {
        enqueueSnackbar('Error', {variant: 'error'}); // todo add text
      });
    }
  }, [userInfo]);

  if (!userInfo) {
    return <div className={'relative full-height fill-width'}><AscendPreloader/></div>
  }


  return <div className={'fill-width mv-16 mh-10'}>
    <div className={'flex-space-between fill-width'}>
      <img src={'/img/Logo.svg'} className={'ml-30'} width={140} height={31} />
      <ContactsSection />
    </div>
    <ThanksModal open={modalOpen} onClose={() => setModalOpen(false)} />
    <div className={'h-8'} />
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Box maxWidth={600}>
        <Typography variant={'h1'} className={'bold mb-16 text-align-centered'} color={'textPrimary'}>Scope of Appointment</Typography>
        <Typography variant={'h3'} className={'bold mb-32 text-align-centered'} color={'textSecondary'}>This form confirms the types of Medicare plans that you’ll discuss<br /> with your licensed CoverRight sales agent.</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextInput fullWidth
                             name={'firstName'}
                             defaultValue={userInfo.firstName}
                             error={formState.isSubmitted && !!errors.firstName}
                             helperText={formState.isSubmitted && errors?.firstName?.message}
                             inputRef={register}
                             placeholder={'First name'}
                             label={'First name'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextInput fullWidth
                             defaultValue={userInfo.lastName}
                             name={'lastName'}
                             error={formState.isSubmitted && !!errors.lastName}
                             helperText={formState.isSubmitted && errors?.lastName?.message}
                             inputRef={register}
                             placeholder={'Last name'}
                             label={'Last name'} />
            </Grid>

            <Grid item xs={12}>
              <FormTextInput fullWidth
                             label={'Email'}
                             name={'email'}
                             defaultValue={userInfo.email}
                             inputProps={{
                               autoCapitalize: 'none'
                             }}
                             placeholder={'Enter your email address (optional)'}
                             error={!!errors.email && formState.isSubmitted}
                             helperText={formState.isSubmitted && errors?.email?.message}
                             inputRef={register} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={'phoneNumber'}
                render={({ onChange }) => <PhoneNumberInput label={'Phone number (optional)'} name={'phoneNumber'} onChange={onChange} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display={'flex'} flex={1} alignItems={'center'} height={'100%'} className={'mt-5'}>
                <img src={'/img/padlock.svg'} className={'mr-5'} />
                <Typography className={'fs-14 bold'} color={'textPrimary'}>Your data is 100% secure.</Typography>
              </Box>
            </Grid>
          </Grid>
          <div className={'h-32'}/>
          <AscendCheckbox variant={'standart'}
                          controlClassName={classes.checkboxWrapper}
                          labelClassName={'fs-14'}
                          label={'By checking this box, I confirm that I have read and understand the contents of the below scope of appointment, and I confirm that the information I have provided is accurate. If submitted by an authorized individual, this submission certifies that 1) this person is authorized under State law to complete the Scope of Appointment form, and 2) documentation of this authority is available upon request by Medicare.'}
                          checked={agreement}
                          id={'agreement-checkbox'}
                          onChange={() => setAgreement(!agreement)}
          />
          <div className={'h-16'}/>
          <CollapsableCard
            open={open}
            title={'Expand to read details of your appointment before you can hit Submit'}
            description={<div className={'mv-10'}>
              <Typography color={'textPrimary'} variant={'body1'}>The Centers for Medicare and Medicaid Services requires all agents/agencies to document a scope of a marketing appointment (SOA) prior to any Medicare sales meeting. All information provided on this form is confidential and should be completed by each person with Medicare or his/her authorized representative.<br/><br/>This SOA covers:<br/></Typography>
              <ul className={classes.ul}>
                <li>Medicare Advantage (Part C) and Cost Plans;</li>
                <li>Standalone Medicare Prescription Drug Plans (Part D);</li>
                <li>Medicare Supplement (Medigap) Plans;</li>
                <li>Hospital indemnity; and</li>
                <li>Dental, vision and hearing plans.</li>
              </ul>
              <Typography color={'textPrimary'} variant={'body1'} className={'mt-16'}><strong>Beneficiary or Authorized Representative Information</strong><br/><br/>
                By agreeing to this disclaimer, you agree to a meeting with a licensed CoverRight sales agent to discuss the types of products outlined above. Please note, the person who will discuss the products is either employed or contracted by a Medicare plan. They do not work directly for the Federal government. This individual may also be paid based on your enrollment in a plan. <strong>Signing this form does NOT obligate you to enroll in a plan, affect your current or future enrollment, or automatically enroll you in a Medicare plan.</strong>
              </Typography>
            </div>}
          />
          <Box display={'flex'} justifyContent={'center'} mt={'40px'}>
            <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'} disabled={!formState.isValid || !agreement}>Submit</AscendButton>
          </Box>

          <Typography color={'textSecondary'} variant={'body1'} className={'mt-35 mb-100'}>
            By submitting my email and phone number and clicking ’Submit’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
            This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />
            I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
          </Typography>
        </form>
      </Box>
    </Box>
  </div>;
}

const ThanksModal = ({onClose, open}: {open: boolean, onClose: () => void}) => {
  const classes = useStyles();
  return <AscendModal
    width={590}
    open={open}
    onClose={onClose}
  >
    <>
      <IconButton onClick={onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography variant={'h1'} color={'textPrimary'} className={'text-align-centered'}>Thanks! We’ve received your<br />completed form.</Typography>
        </Box>
        <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
          <Typography variant={'h3'} color={'textSecondary'} className={'text-align-centered'}>Please inform your licensed CoverRight sales agent that you have completed the form. You should see a copy for your records in your email soon.</Typography>
        </Box>
        <Box display={'flex'} mt={'23px'} justifyContent={'center'}>
          <AscendButton variant={'contained'} onClick={onClose}>Close</AscendButton>
        </Box>
      </Box>
    </>
  </AscendModal>
}
