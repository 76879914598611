import {Box, Typography, withStyles} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const SeeMore = withStyles({
  icon: {
    marginLeft: 10,
    transform: 'rotate(0)',
    transition: 'all 0.3s'
  },
  iconOpen: {
    transform: 'rotate(-180deg) !important',
  },
})(({classes, expanded, onToggle}: {classes: any, expanded: boolean, onToggle: (expanded: boolean) => void}) =>
  <Box className={'flex flex-align-center pointer'} justifyContent={'center'} mt={'6px'} onClick={() => onToggle(expanded)}>
    <Typography variant={'h5'} color={'textSecondary'}>See {!expanded ? 'more' : 'less'}</Typography>
    <img src={'/img/arrow_green.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
  </Box>)

export default SeeMore;
