import React from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import {IQuestionnaireRequestResponse} from "../../shared/hooks/useQuestionnaireRequest";
import {gql} from "apollo-boost";
import * as _ from "lodash";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import {getQuoteId, setQuoteId} from "../../shared/QuoteId";
import {QuoteContext} from "./QuoteContext";

interface FavoritesContextState {
  toggleFavorite: (bidId: string, zip: string) => void,
  favorites: string[]
}

const defaultState: FavoritesContextState = {
  toggleFavorite: () => {},
  favorites: [],
};

export const FavoritesContext = React.createContext<FavoritesContextState>(defaultState);

export function FavoritesProvider(props: React.PropsWithChildren<FavoritesProviderProps>) {

  const [favorites, setFavorites] = React.useState<string[]>([]);
  const {id, favorites: quoteFavorites} = React.useContext(QuoteContext);

  React.useEffect(() => {
    if (quoteFavorites) {
      setFavorites(quoteFavorites || []);
    }
  }, [quoteFavorites])

  const [save] = useSaveRequest((data) => {
    setQuoteId(data.saveMedicareQuote.maQuoteID)
  })

  const toggleFavorite = (bidId: string, zip: string) => {
    setFavorites(prev => {
      const res = prev.indexOf(bidId) >= 0 ? _.without(prev, bidId) : [...prev, bidId];
      save({
        variables: {
          data: {
            zip,
            favorites: res,
            form: 'SEARCH_RESULTS',
            id
          }
        },
      })
      return res;
    })
  }

  return <FavoritesContext.Provider value={{favorites, toggleFavorite}}>
    {props.children}
  </FavoritesContext.Provider>

}

type FavoritesProviderProps = {}
