import React from "react";
import {Box, Divider, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import TooltipedTitle from "../../PlanList/components/TooltipedTitle";
import AscendTooltip from "../../../../shared/components/AscendTooltip";

type SimplePlanDetailProps = {
  title: string | JSX.Element,
  tooltip?: string,
  content?: string | null | JSX.Element,
  emptyString?: string,
  hideDidider?: boolean,
  thin?: boolean
}

function PlanDetail({title, content, tooltip, emptyString, thin, hideDidider}: SimplePlanDetailProps) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} my={"10px"}>
        <Box minWidth={'50%'} width={'50%'} className={phone ? '' : 'pl-20'}>
          {!tooltip && <Typography variant={"h5"} color={"textPrimary"}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <div className={`flex`}>
            <AscendTooltip placement={'top'} arrow title={tooltip}>
              <div className={'flex flex-align-start'}>
                <img  className={`mt-5 mr-8`} src={'/img/info.svg'}></img>
                <div className={`card-title ${!thin ? 'bold' : 'thin'}`} >{title}</div>
              </div>
            </AscendTooltip>
          </div>}
        </Box>
        <Box display={"flex"} className={phone ? '' : 'pr-20'}>
          <Box display={"flex"} flexGrow={2} ml={phone ? 2 : undefined}>
            {typeof content !== "string" && content}
            {typeof content === "string" &&
            <Typography variant={"h5"} color={"textPrimary"}>{content || emptyString}</Typography>}
          </Box>
        </Box>
      </Box>
      {!hideDidider && <Divider/>}
    </>
  )
}

export const SimplePlanDetail = React.memo(PlanDetail);
