import React from "react";
import {Box, createStyles, Grid, MenuItem, Theme, Typography} from "@material-ui/core";
import FormTextInput from "../shared/components/FormTextInput";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import useCommonStyles from "../shared/useCommonStyles";
import {makeStyles} from "@material-ui/core/styles";
import FormSelectInput from "../shared/components/FormSelectInput";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import * as _ from "lodash";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import useUserProfile from "./hooks/useUserProfile";
import useSaveUserProfile from "./hooks/useSaveUserProfile";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import AscendPreloader from "../shared/components/AscendPreloader";
import {CallButton} from "../shared/components/HelpModal";
import {useSnackbar} from "notistack";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import {ProfileSource} from "../enrollment-types";
import {getCId} from "../shared/utils";

const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  city: yup.string().required("Enter a valid city"),
  aptNumber: yup.string(),
  streetName: yup.string().required("Enter a valid street"),
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
  medicareNumber: yup.string().required("Please enter a valid Medicare number"),
  phoneNumber: yup.string().required("Please enter a valid phone number").min(10, "Please enter a valid phone"),
});

interface PersonalDetailsFormState {
  firstName: string;
  lastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  medicareNumber: string;
  streetName: string;
  phoneNumber: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    }
  }),
);

export default function PersonalDetailsForm() {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [birthDate, setBirthDate] = React.useState<Date | null>(null);
  const [medicarePartAStartedAt, setMedicarePartAStartedAt] = React.useState<Date | null>(null);
  const [medicarePartBStartedAt, setMedicarePartBStartedAt] = React.useState<Date | null>(null);
  const [gender, setGender] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [getCounty, countyData] = useGetCounty();
  const {userProfile, request} = useUserProfile();
  const [county, setCounty] = React.useState<string | undefined>('');
  const { enqueueSnackbar } = useSnackbar();
  const [save] = useSaveUserProfile();
  const {register, handleSubmit, errors, formState, watch, getValues, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch()

  React.useEffect(() => {
    if (userProfile) {
      setGender(userProfile.gender || '');
      setState(userProfile.state || '');
      setCounty(userProfile.countyName || '');
      setBirthDate(userProfile.birthDate ? new Date(userProfile.birthDate) : null);
    }
  }, [userProfile])

  React.useEffect(() => {
    const zip = getValues().zip;
    if (zip && zip.length === 5) {
      getCounty({variables: {zip}})
    }
  }, [getValues().zip])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (values) {
      setLoading(true);
      save({variables: {
        data: {
          ...values,
          countyName: county,
          state,
          birthDate: birthDate ? moment(birthDate).format('MM/DD/YYYY') : undefined,
          gender,
          source: ProfileSource.UserFlowV1,
          cId: getCId(),
          insuranceType: 'MA'
        }
      }}).then((res) => {
        if ((window as any).track) {
          (window as any).track('profile-saved', {'profile_id': res.data?.saveUserProfile.profileId});
        }
        enqueueSnackbar('Changes saved!', {variant: 'success'});
        return request.refetch();
      })
      .finally(() => setLoading(false))
    }
  };

  React.useEffect(() => {
      if (countyData.data?.plans_zip_county_fips && countyData.data?.plans_zip_county_fips.length === 1) {
        setCounty(countyData.data?.plans_zip_county_fips[0].countyname)
      }
  }, [countyData.data]);

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])


  if (typeof userProfile === 'undefined') {
    return null;
  }

  if (loading || request.loading) {
    return <AscendPreloader/>
  }

  return <Box display={'flex'} justifyContent={'center'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box maxWidth={656}>
        <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your personal details</Typography>
        <div className={'h-10'} />
        <Typography color={'textPrimary'} className={'medium fs-18'}>Completing the optional details below to help us complete your application quickly and efficiently.</Typography>
        <div className={'h-16'} />
        <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Basic details</Typography>
        <div className={'h-10'} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           defaultValue={userProfile?.firstName}
                           placeholder={'First name'}
                           label={'First name'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           defaultValue={userProfile?.lastName}
                           inputRef={register}
                           placeholder={'Last name'}
                           label={'Last name'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           label={'Email'}
                           name={'email'}
                           autoFocus={true}
                           defaultValue={userProfile?.email}
                           inputProps={{
                             autoCapitalize: 'none'
                           }}
                           placeholder={'Enter your email address'}
                           error={!!errors.email && formState.isSubmitted}
                           helperText={!!errors.email && formState.isSubmitted && errors?.email?.message}
                           inputRef={register} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Date of Birth'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={birthDate}
                error={formState.isSubmitted && !birthDate}
                helperText={formState.isSubmitted && !birthDate && 'Please enter a valid date'}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setBirthDate(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              error={formState.isSubmitted && !gender}
              helperText={formState.isSubmitted ? "Select a valid gender" : undefined}
              label={'Gender'}
              value={gender}
              placeholder={'Select gender'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setGender(event.target.value as string);
              }}
            >
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ onChange }) => <PhoneNumberInput label={'Phone number'} defaultValue={userProfile?.phoneNumber} name={'phoneNumber'} onChange={onChange} />}
            />
          </Grid>
        </Grid>
        <div className={'h-20'} />
        <AscendDivider />
        <div className={'h-30'} />
        <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Address</Typography>
        <div className={'h-10'} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <FormTextInput fullWidth
                           name={'streetName'}
                           error={formState.isSubmitted && !!errors.streetName}
                           helperText={formState.isSubmitted && errors?.streetName?.message}
                           inputRef={register}
                           defaultValue={userProfile?.streetName}
                           placeholder={'Street address'}
                           label={'Street address'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormTextInput fullWidth
                           name={'aptNumber'}
                           error={formState.isSubmitted && !!errors.aptNumber}
                           helperText={formState.isSubmitted && errors?.aptNumber?.message}
                           inputRef={register}
                           defaultValue={userProfile?.aptNumber}
                           placeholder={'Apt. / Unit'}
                           label={'Apt. / Unit'} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormSelectInput
              fullWidth
              label={'State'}
              value={state}
              error={formState.isSubmitted && !state}
              helperText={formState.isSubmitted ? "Select a valid state" : undefined}
              placeholder={'Select state'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setState(event.target.value as string);
              }}
            >
              {_.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state)).map(state => <MenuItem value={state}>{state}</MenuItem>)}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextInput fullWidth
                           name={'city'}
                           error={formState.isSubmitted && !!errors.city}
                           helperText={formState.isSubmitted && errors?.city?.message}
                           inputRef={register}
                           defaultValue={userProfile?.city}
                           placeholder={'City'}
                           label={'City'} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           fullWidth
                           inputRef={register}
                           label={'Zip Code'}
                           name={'zip'}
                           defaultValue={userProfile?.zip}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
            {counties.length > 1 && <FormSelectInput
              fullWidth
              label={'Select county'}
              value={county}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
              }}
            >
              {
                counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
              }
            </FormSelectInput>}
          </Grid>
        </Grid>
        <div className={'h-20'} />
        <AscendDivider />
        <div className={'h-30'} />
        <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Medicare details</Typography>
        <div className={'h-10'} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextInput name={'medicareNumber'}
                           error={formState.isSubmitted && !!errors.medicareNumber}
                           helperText={formState.isSubmitted && errors?.medicareNumber?.message}
                           inputRef={register}
                           defaultValue={userProfile?.medicareNumber}
                           InputProps={{
                             inputComponent: MedicareNumberInput as any
                           }}
                           placeholder={'_ _ _ _ - _ _ _ - _ _ _ _'}
                           label={'Medicare Number'} />
          </Grid>
          {/*<Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Medicare Part A starts:'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartAStartedAt}
                error={formState.isSubmitted && !medicarePartAStartedAt}
                helperText={formState.isSubmitted && 'Please enter a valid date'}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartAStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Medicare Part B starts:'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartBStartedAt}
                error={formState.isSubmitted && !medicarePartBStartedAt}
                helperText={formState.isSubmitted && 'Please enter a valid date'}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartBStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>*/}
        </Grid>
        <div className={'h-10'} />
        <AscendButton className={'gtm-button-event'} data-gtm-button-name={'CREATE_PROFILE_BUTTON'} variant={'contained'} type={'submit'}>Save changes</AscendButton>
        <div className={'h-100'} />
      </Box>
    </form>
  </Box>
}
