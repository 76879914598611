import React from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../EnrollmentApolloClient";
import {MutationPutTemporalDataArgs, QueryPushTemporalDataArgs} from "../../enrollment-types";

export default function useTemporalData() {
  const [put, putData] = useMutation<{putTemporalData: string}, MutationPutTemporalDataArgs>(
    gql(putMutation),
    {
      client: client,
    }
  );
  const [push, pushData] = useLazyQuery<{pushTemporalData?: string}, QueryPushTemporalDataArgs>(
    gql(pushQuery),
    {
      client: client,
    }
  );

  return {put, push, data: pushData, loading: putData.loading || pushData.loading};
}

const putMutation = `
mutation ($data: String!) {
  putTemporalData(data: $data)
}
`;

const pushQuery = `
query ($id: UUID!) {
  pushTemporalData(id: $id)
}
`;
