import React from "react";

export default function useOnScreen(ref: any) {
  let options = {
    rootMargin: '200px',
  }

  const [isIntersecting, setIntersecting] = React.useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    options
  )

  React.useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}
