import React from "react";
import {Box, Collapse, createStyles, IconButton, MenuItem, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import FormSelectInput from "../../../shared/components/FormSelectInput";
import FormTextInput from "../../../shared/components/FormTextInput";
import useCommonStyles from "../../../shared/useCommonStyles";
import {CalcDrugFrequencyPeriod, FrequencyPeriod, PackInfoOuput} from "../../../types";
import {PreferredDrug} from "../../../Maketplace/MedicareAdvantage/PlanList/hooks/useDrugsByRequestId";
import {DrugName} from "../../../shared/components/DrugSelection";
import useGetPackagesDescription from "../../hooks/useGetPackagesDescription";
import * as _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
    },
    delimeter: {
      margin: '10px 20px 0',
      textAlign: 'center',
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    menuItem: {
      whiteSpace: 'unset'
    }
  })
);

export interface DrugDosage {
  dosage: string,
  quantity?: number,
  frequency: string,
  purchaseFrequency: FrequencyPeriod,
}

interface DrugDosageModalModalProps {
  open: boolean,
  drug?: DrugName,
  dosage?: PreferredDrug,
  packages?: PackInfoOuput[],
  onClose: (result?: DrugDosage | boolean) => void,
}

const initialDosage: DrugDosage = {
  dosage: '',
  quantity: undefined,
  frequency: CalcDrugFrequencyPeriod.Monthly,
  purchaseFrequency: FrequencyPeriod.Monthly
};

export default function DrugDosageModal(props: DrugDosageModalModalProps) {
  const classes = useStyles();
  const [dosage, setDosage] = React.useState<DrugDosage>(initialDosage);
  const commonClasses = useCommonStyles();
  const [getDescription, {data}] = useGetPackagesDescription();
  const [description, setDescription] = React.useState();

  React.useEffect(() => {
    if (props.open) {
      setDosage(props.dosage ? {
        frequency: props.dosage?.frequency || CalcDrugFrequencyPeriod.Daily,
        quantity: props.dosage?.quantity,
        dosage: props.dosage?.dosageRxcui || '',
        purchaseFrequency: props.dosage?.purchaseFrequency || FrequencyPeriod.Monthly
      } : initialDosage);
    } else {
      setDescription(undefined)
    }
  }, [props.open])

  React.useEffect(() => {
      setDescription(data?.packageDescriptionByRxcui || '')
  }, [data]);

  const frequencies = [CalcDrugFrequencyPeriod.Daily, CalcDrugFrequencyPeriod.Weekly, CalcDrugFrequencyPeriod.Monthly];

  return <AscendModal
    width={660}
    open={props.open}
    onClose={() => props.onClose()}
    paperPadding={'24px 24px 40px'}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Typography color={'textPrimary'} className={'bold fs-26 text-align-centered'}>{props.drug?.name}</Typography>
      <Typography color={'textSecondary'} className={'bold fs-18 text-align-centered'}>Enter dosage and frequency</Typography>
      <Box mt={'24px'}>
        <FormSelectInput label={'What dosage do you take?'}
                         placeholder={'Please select'}
                         value={dosage?.dosage}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setDosage({
                             ...dosage,
                             dosage: event.target.value as string,
                           });
                           getDescription({variables: {rxcui: event.target.value as string}})
                         }}
        >
          {props.packages?.map(p => <MenuItem key={p.rxcui} value={p.rxcui as string} className={classes.menuItem}>{p.name}</MenuItem>)}
        </FormSelectInput>
        <Collapse in={!!description}>
          <Typography className={'fs-16 medium'} color={'textPrimary'}>Package contains:</Typography>
          <Typography className={'fs-14 ml-16 mt-8 mb-16'} color={'textSecondary'}>{description}</Typography>
        </Collapse>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'}>
        <FormTextInput type={'number'}
                       label={'Unit quantity'}
                       placeholder={'Enter quantity'}
                       value={dosage?.quantity}
                       onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                         setDosage({
                           ...dosage,
                           quantity: parseFloat(event.target.value as string),
                         });
                       }}
        />
        {/*<FormTextInput type={'number'}
                       label={'Quantity'}
                       placeholder={'Enter quantity'}
                       value={dosage?.quantity}
                       onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                         setDosage({
                           ...dosage,
                           quantity: parseFloat(event.target.value as string),
                         });
                       }}
        />*/}
        {/*<Box display={'flex'} minWidth={'50%'}>
          <Typography variant={'h4'} color={'textSecondary'} className={classes.delimeter}>per month</Typography>
        </Box>*/}
        <FormSelectInput label={'Frequency'}
                         className={'ml-20'}
                         placeholder={'Please select'}
                         value={dosage?.frequency || 'month'}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setDosage({
                             ...dosage,
                             frequency: event.target.value as string,
                           });
                         }}
        >
          {frequencies.map(f => <MenuItem key={f} value={f}>{_.upperFirst(_.lowerCase(f))}</MenuItem>)}
        </FormSelectInput>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'}>
        <FormSelectInput label={'Purchase frequency'}
                         placeholder={'Please select'}
                         value={dosage?.purchaseFrequency || FrequencyPeriod.Monthly}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setDosage({
                             ...dosage,
                             purchaseFrequency: event.target.value as FrequencyPeriod,
                           });
                         }}
        >
          <MenuItem value={FrequencyPeriod.Monthly}>Monthly</MenuItem>)
          <MenuItem value={FrequencyPeriod.Quarterly}>Quarterly</MenuItem>)
        </FormSelectInput>
      </Box>

      <Box display={'flex'} mt={'28px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
        <AscendButton variant={'contained'} onClick={() => {props.onClose(false)}} color={'secondary'}>Back</AscendButton>
        <AscendButton variant={'contained'} onClick={() => props.onClose(dosage)} disabled={!dosage.dosage || !dosage.quantity}>Add Drug</AscendButton>
      </Box>
    </>
  </AscendModal>
}
