import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import moment from "moment";
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import AscendRadio from "../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    group: {
      background: '#F5F6F7',
      padding: 16,
    }
  })
);
const formName = 'EnrollmentForm';


const Schema = yup.object().shape({
  lastName: yup.string(),
  phoneNumber: yup.string(),
});

interface PersonalDetailsFormState {
  lastName: string;
  phoneNumber: string;
}

function EnrollmentForm3(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [effectiveDate, setEffectiveDate] = React.useState<Date | null>(null);
  const [terminationDate, setTerminationDate] = React.useState<Date | null>(null);
  const [primaryCarePhysician, setPrimaryCarePhysician] = React.useState<string>('');
  const [establishedPatient, setEstablishedPatient] = React.useState();
  const [provideDrugCoverage, setProvideDrugCoverage] = React.useState();
  const [coverageSubscriber, setCoverageSubscriber] = React.useState();
  const [spouseWork, setSpouseWork] = React.useState();
  const [otherDrugs, setOtherDrugs] = React.useState();
  const history = useHistory();
  const [save] = useSaveEnrollment();

  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (formState.isValid) {
      clearErrors()
      props.history.push(paths.personalDetails4 + "/" + planId + "/" + zip + "/" + countyName)
    }
  };

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography className={'bold fs-26'} color={'textPrimary'}>Current healthcare information</Typography>
    <Typography className={'medium fs-18'} color={'textPrimary'}>You may have other drug coverage, including other private insurance, TRICARE,
      Federal employee health benefits coverage, VA benefits, or other programs.</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'fs-16'} color={'textPrimary'}><span className={'bold fs-24'}>Current Primary Care Physician</span> (Optional, unless enrolling in HMO)</Typography>
            <Typography className={'fs-16 mt-8'} color={'textPrimary'}>We strongly recommend that all applicants confirm the details of their Primary Care Physician (PCP). If you are applying for an HMO plan, then you must complete this section. Please see your Summary of Benefits to determine if your plan requires a PCP.</Typography>
            <Typography className={'fs-16 mt-16'} color={'textPrimary'}>Select your current Primary Care Physician from the list of doctors you provided:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              value={primaryCarePhysician}
              placeholder={'Select your Primary Care Physician'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setPrimaryCarePhysician(event.target.value as string);
              }}
            >
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'pcpNumber'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'{PCP number}'} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={'fs-16'} color={'textPrimary'}>Or you can enter the name and NPI of your PCP:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Enter First and Last name of your PCP'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Enter PCP number'} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={'fs-16'} color={'textPrimary'}>Are you an established patient of this Primary Care Physician?</Typography>
            <div className={'h-8'} />
            <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
              <Box display={'flex'} flex={1}>
                <AscendRadio checked={establishedPatient} onChange={(e:any, checked: boolean) => {
                  setEstablishedPatient(checked)
                }} value="yes" label="Yes" />
              </Box>
              <Box className={commonClasses.radio} display={'flex'} flex={1}>
                <AscendRadio checked={establishedPatient === false} onChange={(e:any, checked: boolean) => {
                  setEstablishedPatient(!checked)
                }} value="no" label="No" />
              </Box>
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'24px'} />
            <Typography className={'bold fs-24'}>Current healthcare coverage</Typography>
            <Typography className={'fs-16'} color={'textPrimary'}>Will you have other prescription drug coverage in addition to this plan for which you are applying?*</Typography>
            <div className={'h-8'} />
            <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
              <Box display={'flex'} flex={1}>
                <AscendRadio checked={otherDrugs} onChange={(e:any, checked: boolean) => {
                  setOtherDrugs(checked)
                }} value="yes" label="Yes" />
              </Box>
              <Box className={commonClasses.radio} display={'flex'} flex={1}>
                <AscendRadio checked={otherDrugs === false} onChange={(e:any, checked: boolean) => {
                  setOtherDrugs(!checked)
                }} value="no" label="No" />
              </Box>
            </RadioGroup>
            <div className={'h-16'} />
            <Collapse in={!!otherDrugs} className={`${classes.group} ${!!otherDrugs && 'mt-16'}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'Name of prescription drug coverage'}
                                 placeholder={'Enter name of coverage'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name={'phoneNumber'}
                    render={({ onChange }) => <PhoneNumberInput label={'Telephone number'}
                                                                placeholder={'+1 (123) 456-7890'}
                                                                name={'phoneNumber'}
                                                                onChange={onChange} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'ID number for this coverage'}
                                 placeholder={'Enter ID number'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'Group number for this coverage'}
                                 placeholder={'Enter Group number'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disableFuture
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="none"
                      label={'Effective date of this coverage'}
                      InputAdornmentProps={{
                        classes: {
                          root: commonClasses.hidden
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input
                        }
                      }}
                      error={formState.isSubmitted && !moment(effectiveDate).isValid()}
                      helperText={(formState.isSubmitted && !moment(effectiveDate).isValid()) && 'Please enter a valid date'}
                      placeholder={'MM / DD / YYYY'}
                      value={effectiveDate}
                      invalidDateMessage={'Please enter a valid date'}
                      onChange={date => {
                        setEffectiveDate(date)
                      }}
                      TextFieldComponent={FormTextInput as any}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disableFuture
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="none"
                      label={'Termination date of coverage'}
                      InputAdornmentProps={{
                        classes: {
                          root: commonClasses.hidden
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input
                        }
                      }}
                      error={formState.isSubmitted && !moment(terminationDate).isValid()}
                      helperText={(formState.isSubmitted && !moment(terminationDate).isValid()) && 'Please enter a valid date'}
                      placeholder={'MM / DD / YYYY'}
                      value={terminationDate}
                      invalidDateMessage={'Please enter a valid date'}
                      onChange={date => {
                        setTerminationDate(date)
                      }}
                      TextFieldComponent={FormTextInput as any}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Typography className={'fs-16'} color={'textPrimary'}>Once enrolled, will you or your spouse work?</Typography>
            <div className={'h-8'} />
            <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
              <Box display={'flex'} flex={1}>
                <AscendRadio checked={spouseWork} onChange={(e:any, checked: boolean) => {
                  setSpouseWork(checked)
                }} value="yes" label="Yes" />
              </Box>
              <Box className={commonClasses.radio} display={'flex'} flex={1}>
                <AscendRadio checked={spouseWork === false} onChange={(e:any, checked: boolean) => {
                  setSpouseWork(!checked)
                }} value="no" label="No" />
              </Box>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography className={'fs-16'} color={'textPrimary'}>Once enrolled, will you have coverage where you are the subscriber or are covered as a spouse/dependent?</Typography>
            <div className={'h-8'} />
            <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
              <Box display={'flex'} flex={1}>
                <AscendRadio checked={coverageSubscriber} onChange={(e:any, checked: boolean) => {
                  setCoverageSubscriber(checked)
                }} value="yes" label="Yes" />
              </Box>
              <Box className={commonClasses.radio} display={'flex'} flex={1}>
                <AscendRadio checked={coverageSubscriber === false} onChange={(e:any, checked: boolean) => {
                  setCoverageSubscriber(!checked)
                }} value="no" label="No" />
              </Box>
            </RadioGroup>
            <Collapse in={!!coverageSubscriber} className={`${classes.group} ${!!coverageSubscriber && 'mt-16'}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'Carrier name'}
                                 placeholder={'Enter name of carrier'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'ID number for this coverage'}
                                 placeholder={'Enter ID number'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'lastName'}
                                 error={formState.isSubmitted && !!errors.lastName}
                                 helperText={formState.isSubmitted && errors?.lastName?.message}
                                 inputRef={register}
                                 label={'Group number for this coverage'}
                                 placeholder={'Enter Group number'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color={'textPrimary'} className={'fs-16 medium lh-20'}>Does this coverage provide drug coverage?</Typography>
                  <Box display={'flex'} width={200}>
                    <AscendRadio variant={'standart'} label={'Yes'} checked={provideDrugCoverage === true} onChange={() => setProvideDrugCoverage(true)}/>
                    <AscendRadio variant={'standart'} label={'No'} checked={provideDrugCoverage === false} onChange={() => setProvideDrugCoverage(false)}/>
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Save and continue</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm3;
