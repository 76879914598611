import React from 'react';
import {LazyQueryHookOptions, useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {
  QueryGenerateEhealthPlanLinkArgs,
} from "../../types";

export default function useGetEHealthLinkRequest(options?: LazyQueryHookOptions<IEHealthResponse, QueryGenerateEhealthPlanLinkArgs>) {
  return useLazyQuery<IEHealthResponse, QueryGenerateEhealthPlanLinkArgs>(
    gql(eHealthQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  )
}

export interface IEHealthResponse {
  generateEhealthPlanLink: string
}


const eHealthQuery = `
query($bidId: String!, $countyName: String!, $zip: String!) {
  generateEhealthPlanLink(bidId: $bidId, countyName: $countyName, zip: $zip)
}
`
