import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationEmailMeMaPageArgs} from "../../../../enrollment-types";
import {client} from "../../../../shared/EnrollmentApolloClient";

export default function useEmailMeMAPage(options?: MutationHookOptions<{emailMeMAPage: boolean}, MutationEmailMeMaPageArgs>) {
  return useMutation<{emailMeMAPage: boolean}, MutationEmailMeMaPageArgs>(gql(mutation), {
    ...options,
    client
  })
}

const mutation = `
mutation ($email: String!, $id: UUID, $yearEmailMeMaPage: EmailMeMaPagePlanYear, $path: String) {
  emailMeMAPage(email: $email, id: $id, yearEmailMeMaPage: $yearEmailMeMaPage, path: $path)
}
`;

