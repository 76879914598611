import React from "react";
import {Box, createStyles, Grid, MenuItem, Snackbar, Theme, Typography} from "@material-ui/core";
import FormTextInput from "../shared/components/FormTextInput";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {makeStyles} from "@material-ui/core/styles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import DeleteAccountConfirmationModal from "./components/DeleteAccountConfirmationModal";
import useDeleteUser from "./hooks/useDeleteUser";
import {keycloak} from "../shared/keycloak";
import paths from "../Maketplace/MedicareAdvantage/config/router-paths";
import {useHistory} from "react-router";
import {resetQuoteId} from "../shared/QuoteId";
import useResetPassword from "./hooks/useResetPassword";
import AscendPreloader from "../shared/components/AscendPreloader";
import {Alert} from "@material-ui/lab";
import {colors} from "../shared/AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    snack: {
      background: colors.custom.green.variant2
    }
  }),
);

export default function AccountSettings() {
  const classes = useStyles();
  let history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const [resetPassword, {loading: resetLoading}] = useResetPassword();
  const [deleteUser, {loading: deleteLoading}] = useDeleteUser();
  const [open, setOpen] = React.useState(false);

  const onDeleteModalClose = (result: boolean) => {
    if (result) {
      deleteUser().then(() => {
        history.push(paths.plans);
        resetQuoteId();
        keycloak.logout();
      });
    }
    setDeleteModalOpen(false);
  };

  const onUpdatePassword = () => {
    resetPassword({variables: {newPassword: password}}).then(() => {
      setPassword('');
      setConfirm('');
      setOpen(true);
    })
  }

  if (deleteLoading || resetLoading) {
    return <AscendPreloader/>
  }

  return <Box display={'flex'} justifyContent={'center'}>
    <Snackbar open={open} autoHideDuration={4000} onClose={() => setOpen(false)}>
      <Alert severity="success" variant={'filled'} elevation={6} className={classes.snack}>
        Password changed!
      </Alert>
    </Snackbar>
    <DeleteAccountConfirmationModal open={deleteModalOpen} onClose={onDeleteModalClose} />
    <Box maxWidth={656}>
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Account settings</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>Change or update your password or delete your account below.</Typography>
      <div className={'h-16'} />
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Security</Typography>
      <div className={'h-10'} />
      <Grid container spacing={2} xs={12} sm={6}>
        <Grid item>
          <FormTextInput fullWidth
                         name={'password'}
                         type={'password'}
                         value={password}
                         onChange={event => setPassword(event.target.value)}
                         placeholder={'Enter new password'}
                         label={'Enter new password'} />
        </Grid>
        <Grid item>
          <FormTextInput fullWidth
                         name={'confirm'}
                         value={confirm}
                         onChange={event => setConfirm(event.target.value)}
                         error={!!password.length && !!confirm.length && password !== confirm}
                         helperText={(!!password.length && !!confirm.length && password !== confirm) && 'Passwords must match'}
                         type={'password'}
                         placeholder={'Reconfirm new password'}
                         label={'Reconfirm new password'} />
        </Grid>

      </Grid>
      <div className={'h-32'} />
      <AscendButton variant={'contained'} disabled={!password.length || password !== confirm} onClick={onUpdatePassword}>Update password</AscendButton>

      <div className={'h-40'} />
      <AscendDivider />
      <div className={'h-25'} />

      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Delete account</Typography>
      <div className={'h-16'} />
      <Typography color={'textPrimary'} variant={'h4'} className={'medium'}>If you delete your account, you will lose all of your saved data, like your doctors, drugs and pharmacy information.</Typography>
      <div className={'h-50'} />
      <AscendButton variant={'contained'} onClick={() => setDeleteModalOpen(true)}>Delete account</AscendButton>
      <div className={'h-100'} />
    </Box>
  </Box>
}
