import React, {FunctionComponent} from "react";
import {
  Box,
  createStyles,
  Step,
  StepConnector,
  StepIconProps, StepLabel, Stepper,
  Theme,
  Typography,
  withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import {colors} from "../../../shared/AppTheme";


const VideoStepLabel = withStyles({
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: '#1c434f66',
    '&.MuiStepLabel-completed': {
      fontWeight: 700,
      fontSize: 16,
      color: `rgba(28,67,79,0.4)`
    },
    '&.MuiStepLabel-active': {
      fontSize: 16,
      fontWeight: 700,
      color: `${colors.custom.green.variant1}`,
    },
  },
})(StepLabel);

const VideoSubStepLabel = withStyles({
  root: {
    marginTop: -5,
    marginBottom: -5,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: '#1c434f66',
    cursor: 'pointer',
    '&.MuiStepLabel-completed': {
      fontSize: 14,
      fontWeight: 500,
      color: `${colors.custom.green.variant1}!important`
    },
    '&.MuiStepLabel-active': {
      fontSize: 14,
      fontWeight: 500,
      color: `${colors.custom.green.variant1}!important`,
    },
  },
})(StepLabel);

const VideoConnector = withStyles({
  vertical: {
    padding: 0,
    marginLeft: 11,
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: 'rgba(28, 67, 79, 0.12)',
    },
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: 'rgba(28, 67, 79, 0.12)',
    },
  },
  lineVertical: {
    minHeight: 24,
    borderColor: 'rgba(28, 67, 79, 0.12)',
  }
})(StepConnector);

const useVideoStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: 'rgba(28,67,79,0.4)',
    width: 23,
    height: 23,
    fontSize: 10,
    fontWeight: 600,
    display: 'flex',
    border: '1px solid rgba(28, 67, 79, 0.1)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  active: {
    backgroundColor: colors.custom.green.variant1,
    color: 'white',
    fontSize: '10px!important',
    fontWeight: 600,
  },
  completed: {
    backgroundColor: `white`,
    color: 'backgroundColor',
    fontSize: 15,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function VideoStepIcon(props: StepIconProps) {
  const classes = useVideoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed && !active ? <Check className={classes.completed} /> : <Box mt={'3px'}>{props.icon}</Box>}
    </div>
  );
}


const useVideoSubStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: 'rgba(28,67,79,0.4)',
    width: 11,
    height: 11,
    fontSize: 10,
    fontWeight: 500,
    display: 'flex',
    border: '1px solid rgba(28, 67, 79, 0.1)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 13,
    marginLeft: 6,
  },
  active: {
    border: `1px solid ${colors.custom.green.variant1}`,
  },
  completed: {
    backgroundColor: 'white',
    color: 'rgba(28,67,79,0.4)',
    fontSize: 7,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 5,
    height: 5,
    backgroundColor: '#5ECB9E',
    borderRadius: '50%',
  }
});

function VideoSubStepIcon(props: StepIconProps) {
  const classes = useVideoSubStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {!!active && <div className={classes.dot} />}
      {!!completed && <Check className={classes.completed} />}
    </div>
  );
}

export interface IStepperState {
  steps: IStep[],
  questions: IQuestion[],
}


export interface IStep {
  label: string,
  labelClassName?: string,
  id?: string,
  onClick?: () => void,
  questions?: IQuestion[],
  firstStep: string,
  completed?: boolean,
  children?: IStep[],
  active?: boolean,
  isChild?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    step: {
      // cursor: 'pointer'
      // pointerEvents: 'none'
    },
    stepper: {
      marginTop: 30,
    },
    green: {
      '& .MuiStepLabel-label': {
        color: `${colors.custom.green.variant1}!important`,
      },
    }
  }),
);

export default function VideoStepper(props: {state: IStepperState}) {
  const classes = useStyles();
  const {state} = props;

  const flattenSteps = React.useMemo(() => {
    return state.steps.reduce((previousValue, currentValue) => {
      const result = [...previousValue, {...currentValue, isChild: false}];
      if (currentValue.children?.length) {
        result.push(...currentValue.children.map(ch => ({...ch, isChild: true})));
      }
      return result;
    }, [] as any[]);
  }, [state])

  const steps = React.useMemo(() => {
    const flatten = state.steps.reduce((previousValue, currentValue) => {
      const result = [...previousValue, {...currentValue, isChild: false}];
      if (currentValue.children?.length) {
        result.push(...currentValue.children.map(ch => ({...ch, isChild: true})));
      }
      return result;
    }, [] as any[]);

    const isActive = (step: IStep): boolean => {
      if (step.active) {
        return step.active;
      }
      return false
    }

    return flatten.map((step: IStep, index: number) => {
      if (step.isChild) {
        return <Step completed={step.completed}
                     active={isActive(step)}
                     key={step.label}
                     onClick={step.onClick}
                     className={classes.step}>
          <VideoSubStepLabel StepIconComponent={() => <VideoSubStepIcon icon={index}
                                                                          active={isActive(step)}
                                                                          completed={!!step.completed}/>}>{step.label}</VideoSubStepLabel>
        </Step>
      } else {
        return  <Step completed={step.completed}
                      key={step.label}
                      active={isActive(step)}
                      onClick={step.onClick} className={classes.step}>
          <VideoStepLabel  className={clsx({
            [classes.green]: step.children && (step.children.some(isActive) || step.children.some(s => s.completed)),
            [step.labelClassName as string]: true
          })}
                           StepIconComponent={() => <VideoStepIcon icon={1 + flatten.filter(s => !s.isChild)
                                                                      .findIndex((value: IStep) => value.label === step.label)
                                                                    }
                                                                    active={isActive(step)}
                                                                    completed={step.completed}/>}>{step.label}</VideoStepLabel>
        </Step>
      }
    })
  }, [flattenSteps])

  return (<Box className={classes.stepper}>
      <Stepper classes={{root: classes.root}} orientation="vertical" connector={<VideoConnector />}>
        {steps}
      </Stepper>
    </Box>
  )
}

export interface IQuestion {
  link: string,
  component: FunctionComponent,
}
