import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import {Box, FormControlLabel, FormControlLabelProps, Radio, RadioProps} from "@material-ui/core";
import {colors} from "../AppTheme";
import Check from '@material-ui/icons/Check';

const useStyles = makeStyles({
  root: {
    border: (props: AscendRadioProps) => props.variant === 'standart' ? undefined : '1px solid #A4B4B9',
    padding: '9px 18px 9px 6px',
    backgroundColor: (props: AscendRadioProps) => props.variant === 'standart' ? undefined : 'white',
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: 16,
    color: colors.text.primary,
    paddingTop: 4,
  },
  checkedRoot: {
    borderColor: `rgba(94, 203, 158, 1)!important`,
    backgroundColor: 'rgba(94, 203, 158, 0.1)!important',
  },
  icon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    backgroundColor: 'white',
    border: '1px solid #A4B4B9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      // backgroundColor: '#ACCBC8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: `${colors.custom.green.variant1}!important`,
    color: 'white',
    fontSize: 15,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 22,
    height: 22,
    padding: 3,
  },
  subLabel: {
    color: colors.text.secondary,
    fontSize: 14,
    lineHeight: '15px',
  },
});

type AscendRadioProps = RadioProps & {label: React.ReactNode, subLabel?: React.ReactNode, labelClassName?: string, controlClassName?: string, variant?: 'outlined' | 'standart'};

export default function AscendRadio(props: AscendRadioProps) {
  const classes = useStyles(props);
  const {controlClassName, labelClassName, subLabel, ...rest} = props;
  return (
    <FormControlLabel className={clsx({[classes.root]: true, [classes.checkedRoot]: props.checked, [controlClassName || ""]: !!controlClassName})}
                      control={<Radio
                          color="default"
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<span className={classes.icon} />}
                          {...rest}
                        />}
                      classes={{label: classes.label}}
                      label={<Box ml={'5px'} className={labelClassName}>
                        {props.label}
                        {subLabel && <Box className={classes.subLabel}>{subLabel}</Box>}
                      </Box>}
    />

  );
}
