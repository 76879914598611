import * as QueryString from "query-string"
import {getStoredUser, setStoredUser} from "./StoredUser";
import { validate } from 'uuid';

export function getQuoteId(): string | undefined {
  const params = QueryString.parse(document.location.search);
  let user = getStoredUser();

  if (params.quote && validate(params.quote as string)) {
    user = {...user, quoteId: params.quote as string};
    setStoredUser(user)
  }

  return user?.quoteId || undefined;
}

const medicareListeners: any[] = [];


export function listenQuoteChange(callback: (newId?: string) => void) {
  medicareListeners.push(callback);
}

export function setQuoteId(quoteId: string) {
  if (medicareListeners.length) {
    const prevValue = getQuoteId();
    if (prevValue !== quoteId) {
      medicareListeners.forEach(c => c(quoteId));
    }
  }
  setStoredUser({...getStoredUser(), quoteId})
}

export function resetQuoteId(): void {
  setStoredUser({...getStoredUser(), quoteId: undefined})
}

const medigapListeners: any[] = [];

export function getMedigapQuoteId(): string | undefined {
  const params = QueryString.parse(document.location.search);
  let user = getStoredUser();

  if (params.medigapQuote && validate(params.medigapQuote as string)) {
    user = {...user, medigapQuoteId: params.medigapQuote as string};
    setStoredUser(user)
  }

  return user?.medigapQuoteId || undefined;
}

export function setMedigapQuoteId(quoteId: string) {
  if (medigapListeners.length) {
    const prevValue = getMedigapQuoteId();
    if (prevValue !== quoteId) {
      medigapListeners.forEach(c => c(quoteId));
    }
  }
  setStoredUser({...getStoredUser(), medigapQuoteId: quoteId})
}

export function listenMedigapQuoteChange(callback: (newId?: string) => void) {
  medigapListeners.push(callback);
}

export function resetMedigapQuoteId(): void {
  setStoredUser({...getStoredUser(), medigapQuoteId: undefined})
}


