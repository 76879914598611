import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Paper, Typography} from "@material-ui/core";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";

export interface DashboardCardProps {
  icon: string,
  isDone: boolean,
  title: string,
  description: string,
  onClick: () => void,
  checkboxLabel?: string,
  onCheckboxClick?: () => void,
  checkboxValue?: boolean,
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    paper: {
      borderRadius: 10,
    },
    checkboxArea: {
      paddingLeft: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      background: '#1C434F',
      '& .MuiFormControlLabel-root': {
        padding: 0,
        background: 'transparent'
      },
      '& .MuiFormControlLabel-label': {
        color: 'white',
        fontSize: 13,
        marginLeft: 0
      }
    }
  }),
);

export default function DashboardCard(props: DashboardCardProps) {
  const classes = useStyles();
  return <Paper elevation={0} className={classes.paper}>
    <Box height={'150px'} p={'10px 10px 10px 20px'} className={'pointer'} onClick={props.onClick}>
      <Box display={'flex'} justifyContent={'space-between'} mb={'5px'}>
        <Box pt={'10px'}>
          <img src={props.icon} />
        </Box>
        {props.isDone && <img src={'/img/green2-check.svg'} width={40} height={40} />}
        {!props.isDone && <img src={'/img/red-close.svg'} width={40} height={40} />}
      </Box>
      <Typography variant={'h2'} color={'textPrimary'} className={classes.bold}>{props.title}</Typography>
      <Typography variant={'body1'} color={'textPrimary'}>{props.description}</Typography>
    </Box>
    {props.checkboxLabel && <div className={classes.checkboxArea}>
      <AscendCheckbox variant={"standart"} label={props.checkboxLabel} checked={props.checkboxValue} onChange={props.onCheckboxClick}/>
    </div>}
  </Paper>;
}
