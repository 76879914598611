import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, Grid, RadioGroup, withStyles} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";

const formName = 'STEP_2b';

function Step2b(props: RouteComponentProps<any>) {
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [value, setValue] = React.useState<Step2bAnswers>(state?.STEP_2b?.value?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        value: {
          questionText: 'Do you fall into any of these categories?',
          answerText: answers[value],
          value
        },
        form: formName
      }
    })
    switch (value) {
      case Step2bAnswers.DualEligible:
      case Step2bAnswers.MedicareAdvantage: props.history.push(paths.questionnaire + '/' + questionsRoutes.step3); break
      case Step2bAnswers.None: props.history.push(paths.questionnaire + '/' + questionsRoutes.step2b1); break
      case Step2bAnswers.Experienced: props.history.push(paths.questionnaire + '/' + questionsRoutes.step2c); break
    }

  };

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-32'} style={{maxWidth: 500}}>
      <RadioGroup>
        <GreyRadio checked={value === Step2bAnswers.MedicareAdvantage}
                     onChange={() => {setValue(Step2bAnswers.MedicareAdvantage)}}
                     label={answers[0]} />
        <AscendInfo open={value === Step2bAnswers.MedicareAdvantage}
                    description={<>
                      <p style={{margin: '0'}}>If you are enrolled in an MA plan as of January 1, you may change to a different MA plan or disenroll from MA and return to Original Medicare between January 1 - March 31.</p></>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2bAnswers.DualEligible}
                     onChange={() => {setValue(Step2bAnswers.DualEligible)}}
                     label={answers[1]} />
        <AscendInfo open={value === Step2bAnswers.DualEligible}
                    description={<>
                      <p style={{margin: '0'}}>If you have both Medicaid and Medicare (also known as ‘dual-eligible’), you are eligible to switch plans once every calendar quarter from January to September of each year.</p></>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2bAnswers.Experienced}
                     onChange={() => {setValue(Step2bAnswers.Experienced)}}
                     label={answers[2]} />
        <AscendInfo open={value === Step2bAnswers.Experienced}
                    description={<>
                      <p style={{margin: '0'}}>You may be eligible to enroll in a new plan if you experience a ‘qualifying life event’, such as a change in where you live, if you gain or lose Medicaid, or if you become disabled or have another serious condition.</p></>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2bAnswers.None}
                     onChange={() => {setValue(Step2bAnswers.None)}}
                     label={answers[3]} />
        <AscendInfo open={value === Step2bAnswers.None}
                    description={<>
                      <p style={{margin: '0'}}>No problems!  You can still continue and browse our marketplace. Keep in mind that you can only enroll in Medicare during specific enrollment periods. If you’re not sure when your enrollment period starts, you can always call us for help.</p></>}/>
      </RadioGroup>
    </div>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName}
                    disabled={typeof value === 'undefined' || value === Step2bAnswers.MedicareAdvantage}
                    variant={'contained'}
                    onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </Grid>;
}

const answers = [
  "I have a Medicare Advantage (MA) plan and want to switch during the MA open enrollment period",
  "I have both Medicaid and Medicare and want to switch plans",
  "I have experienced a special circumstance that qualifies me for a Special Enrollment Period",
  "None of the above"
]

export default withTitle(
  Step2b,
  () => 'We are currently outside the\nAnnual Enrollment Period (AEP). Do you fall\ninto any of these categories?',
  'AEP is between Oct 15 - Dec 7 each year.  You are generally not able to\nswitch plans outside of AEP, with some exceptions.'
);

export enum Step2bAnswers {
  MedicareAdvantage,
  DualEligible,
  Experienced,
  None
}

const GreyRadio = withStyles({
  root: {
    padding: '9px 18px 9px 8px',
    backgroundColor: '#E4E8EA',
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: '#E4E8EA',
    },
  },
  label: {
    fontSize: 16,
    color: colors.text.secondary,
    paddingTop: 4,
  },
  checkedRoot: {
    borderColor: `#778E95!important`,
    backgroundColor: '#E4E8EA!important',
  },
  icon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    backgroundColor: '#B9B9B9',
    border: '1px solid #B9B9B9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      // backgroundColor: '#ACCBC8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: `#B9B9B9!important`,
    color: '#B9B9B9',
    fontSize: 15,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 22,
    height: 22,
    padding: 3,
  },
})(AscendRadio)
