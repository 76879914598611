import {QueryHookOptions, useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedigapQuoteOutput, QueryMedigapQuoteArgs} from "../../../medigap-types";

export default function useMedigapQuote(options?: QueryHookOptions<{ medigapQuote: MedigapQuoteOutput }, QueryMedigapQuoteArgs>) {
  return useLazyQuery<{ medigapQuote: MedigapQuoteOutput }, QueryMedigapQuoteArgs>(
    gql(summaryQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const summaryQuery = `
query ($id: UUID!) {
  medigapQuote(id: $id) {
    cId
    id
    medigapFilterState {
      age
      benefits
      companies
      county
      effectiveDate
      gender
      id
      monthlyPlanPremiumRanges
      planCategory
      planNames
      tobacco
      zip
    }
    userId
  }
}
`;
