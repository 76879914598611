import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import withErrorBoundary from "../shared/withErrorBoundary";
import {Box, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import Terms from "../shared/SidePanel/Terms";
import ContactsSection from "../shared/components/ContactsSection";
import {useHistory} from "react-router-dom";
import {config} from "./config/config";
import paths from "./config/router-paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
    },
    title: {
      fontSize: 32,
      textAlign: 'center'
    },
    image: {
      position: 'absolute',
      left: 40,
      top: 32,
    },
    terms: {
      position: 'absolute',
      left: 40,
      bottom: 32,
    },
    colored: {
      backgroundColor: 'rgba(2, 144, 148, 0.1)',
    },
  }),
);

function Home() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box display={'flex'} flex={1} height={'100vh'}>
      <Box display={'flex'} flex={1} position={'relative'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <img src={'/img/Logo.svg'} width={140} height={31} className={classes.image} />
        <Typography className={classes.title} color={'textPrimary'}><strong>I would like to learn<br />about my options before<br />seeing plans</strong></Typography>
        <div className={'h-30'} />
        <AscendButton variant={'contained'} onClick={() => history.push(paths.questionnaire)}>Get started</AscendButton>
        <div className={classes.terms}><Terms /></div>
      </Box>
      <Box display={'flex'} flex={1} position={'relative'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} className={classes.colored}>
        <Typography className={classes.title} color={'textPrimary'}><strong>I know exactly what I’m<br />looking for — take me<br />straight to plans</strong></Typography>
        <div className={'h-30'} />
        <AscendButton variant={'contained'} onClick={() => history.push(paths.resultsType)}>Get started</AscendButton>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(Home);
