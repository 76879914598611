import React from 'react';
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {client, setErrorHandler} from "../shared/ApolloClient";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {authClient} from "../shared/ApolloAuthClient";
import LogoutButton from "../shared/components/LogoutButton";
import NavigationPanel from "./NavigationPanel";
import {Grid} from "@material-ui/core";
import UserDashboard from "./Dashboard/UserDashboard";
import PersonalDetailsForm from "./PersonalDetailsForm";
import EditPreferencesForm from "./EditPreferencesForm";
import AccountSettings from "./AccountSettings";
import Coverage from "./Coverage";
import Benefits from "./Benefits";
import Layout from "../shared/Layout";
import {FavoritesProvider} from "../Maketplace/MedicareAdvantage/shared/FavoritesContext";
import {withKeycloak} from "../shared/keycloak";
import paths from "./config/router-paths";
import {QuoteProvider} from "../Maketplace/MedicareAdvantage/PlanList/QuoteContext";
import {getQuoteId} from "../shared/QuoteId";
import {SnackbarProvider} from "notistack";
import {ComparisonProvider} from "../Maketplace/MedicareAdvantage/PlanComparison/ComparisonContext";
import DrugCostBreakdown from "./DrugCostBreakdown";

function App() {
  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ApolloProvider client={authClient}>
      <SnackbarProvider preventDuplicate classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
        <QuoteProvider private>
          <FavoritesProvider>
            <BrowserRouter>
              <Layout sideHeaderContent={<LogoutButton/>} SideComponent={NavigationPanel}>
                <Grid container alignItems={"center"} justify={"center"}>
                  <Grid md={10} xs={12} item>
                    <Switch>
                      <Route exact path={paths.home}>
                        <Redirect to={paths.dashboard} />}
                      </Route>
                      <Route path={paths.dashboard} component={UserDashboard} />
                      <Route path={paths.personalDetails} component={PersonalDetailsForm} />
                      <Route path={paths.myHealthcare} component={EditPreferencesForm} />
                      <Route path={paths.accountSettings} component={AccountSettings} />
                      <Route path={paths.myCoverage} component={Coverage} />
                      <Route path={paths.myBenefits} component={Benefits} />
                      <Route path={paths.drugCostBreakdown + '/:planId'} component={DrugCostBreakdown} />
                    </Switch>
                  </Grid>
                </Grid>
              </Layout>
            </BrowserRouter>
          </FavoritesProvider>
        </QuoteProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

export default withKeycloak(App);
