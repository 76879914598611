import React from 'react';
import {LazyQueryHookOptions, useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {DrugTiersOutput, Maybe, Plan, QueryPlanArgs} from "../../../../types";
import {client} from "../../../../shared/ApolloClient";

export default function usePlan(input:  QueryPlanArgs) {
  return useQuery<IPlanOutput>(gql(getPlan), {
    variables: input,
    client,
    errorPolicy: 'all',
    fetchPolicy: "no-cache"
  });
}

export function usePlanLazy(options?: LazyQueryHookOptions<IPlanOutput, QueryPlanArgs>) {
  return useLazyQuery<IPlanOutput, QueryPlanArgs>(gql(getPlan), {
    client,
    ...options,
    errorPolicy: 'all',
    fetchPolicy: "no-cache"
  });
}

interface IPlanOutput{
  plan: PlanWithTiers
}

export const planFields = `
    bidId
    planYear
    monthlyCost
    primaryCarePhysician
    physicianSpecialist
    outOfPocketMaximum
    inNetworkDeductableAmount
    maxEnrollmentAmount
    monthlyPremium
    orgName
    parentOrgName
    outOfPocketAmount
    planName
    planType
    planTypeCode
    alternativeMedicine
    annualPhysicalExam
    chiropractic
    dental
    ratingNote
    partBGiveBack
    missingFormularyInAep
    partBAmount
    SNPType
    optionalSupplementalBenefits
    optionalSupplementalBenefitsItems
    doctorsCoverage {
      npi
      coverage
      name
    }
    drugsCoverage{
      rxcui
      coverage
      name
    }
    drugPrices {
      catastrophicPrice
      coverageGapPrice
      initialCoveragePrice
      name
      price
      unitPrice
      rxcui
      tier
      quoteQuantity
      isCoveredByPlan
      packageDescription
      quantityLimit
      isAggregatedPrice
      isGeneric
      isInsulinDrug
    }
    extraBenefits {
      drugCoverage
      dental
      vision
      hearing
      transportation
      fitness
      telehealth      
      worldwideEmergency
      insulinSavings
    }
    drugDetails {
      catastrophicCoverage {
        items {
          title
          values
        }
        title
      }
      catastrophicCoverageDescription
      coverageGap {
        items {
          title
          values
        }
        title
      }
      coverageGapDescription
      initialCoverage {
         items {
          title
          values
        }
        title
      }
      initialCoverageDescription
      initialCoverageLimit
      catastrophicThreshold
      catastrophicCopayGeneric
      catastrophicCopayBranded
      mrxAltDedAmount
      mrxAltNoDedTier
    }
    hearing
    homeHealth
    hospitalBenefits
    hospitalityDetails {
      ambulanceServices
      emergencyRoom
      urgentServices
    }
    meal
    mentalHealth
    oTC
    otherDefinedSupplemental {
      counselingServices
      enhancedDiseaseManagement
      fitnessBenefit
      healthEducation
      homeAndBathroomSafety
      inHomeSafetyAssessment
      medicalNutritionTherapy
      nutritionalDietaryBenefit
      personalEmergencyResponseSystem
      postDischargeInHome
      remoteAccessTechnologies
      smokingAndTobaccoCessationCounseling
      telemonitoringServices
    }
    preventiveCare
    outpatientHospitality
    outpatientRehabilitation
    physicianSpecialistShort
    primaryCarePhysicianShort
    outpatientSurgery
    skilledNursing
    transportation
    telehealth
    vision
    drugsCost
    doctorsCost
    rating
    drugCostMonthlyBreakdown {
      totalClientCost
      costs {
        name
        month
        cost
        rxcui
        stage
      }
      coverageEntry {
        stage
        month
      }
      monthlyCosts {
        amount
        amountString
        month
        stage
      }
    }
    quoteDrugsTiers(supplyDuration: ONE_MONTH) {
      deliveryType
      pharmacyName
      initialCoverageDrugTiers {
        coverage
        tierName
        tier
      }
    }
    drugsTiers30Preferred: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: PREFERRED_RETAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers30Standard: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: STANDARD_RETAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers90Preferred: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: PREFERRED_MAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers90Standard: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: STANDARD_MAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      planFiles {
        evidenceOfCoverageDocUrl
        nonDiscriminationNoticeDocUrl
        preEnrollmentChecklistDocUrl
        starRatingDescDocUrl
        summaryOfBenefitsUrl
      }
`

const getPlan = `
query($bidId: String!, $zip: String!, $countyName: String!, $quoteId: UUID, $planYear: PlanYear) {
  plan(bidId: $bidId, zip: $zip, quoteId: $quoteId, countyName: $countyName, planYear: $planYear) {
    ${planFields}
  }
}
`;


export interface PlanWithTiers extends Plan {
  drugsTiers30Preferred: Maybe<DrugTiersOutput>,
  drugsTiers30Standard: Maybe<DrugTiersOutput>,
  drugsTiers90Preferred: Maybe<DrugTiersOutput>,
  drugsTiers90Standard: Maybe<DrugTiersOutput>,
}

