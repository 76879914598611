import React from "react";
import {Box, createStyles, Paper, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendCard from "./AscendCard";
import {QuestionnaireContext} from "../../Questionnaire/QuestionnaireContextProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 736
    },
    titleText: {
      marginTop: 28,
      textAlign: 'center',
      whiteSpace: 'pre-line',
      fontWeight: 500,
    },
    secondaryText: {
      marginTop: 14,
      textAlign: 'center'
    },
    footerText: {
      marginTop: 40,
      fontSize: 14,
    },
    amy: {
      minHeight: 84,
      minWidth: 80,
      maxHeight: 84,
      maxWidth: 80,
    }
  }),
);

export const withCard = (WrappedComponent: any,
                         title: ((props: any) => string | JSX.Element) | string | JSX.Element,
                         subtitle: ((props: any) => string | JSX.Element | JSX.Element) | string | JSX.Element,
                         hideFooter?: boolean,
                         hideHeader?: boolean
) => (props: any) => {
  const classes = useStyles();
  const [state] = React.useContext(QuestionnaireContext);
  const getTitle = () => typeof title === 'function' ? title(state) : title;
  const getSubtitle = () => typeof subtitle === 'function' ? subtitle(state) : subtitle;


  return <Box width={'100%'}>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <AscendCard className={classes.root}>
        {!hideHeader && <>
          <Box display={'flex'} justifyContent={'center'}>
            <img src={'/img/Amy.png'} width={80} height={84} className={classes.amy} />
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Typography variant={'h1'} color={'textPrimary'} className={classes.titleText}>{getTitle()}</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'} maxWidth={'800px'}>
            <Typography variant={'h3'} color={'textSecondary'} className={classes.secondaryText}>{getSubtitle()}</Typography>
          </Box>
        </>}

        <WrappedComponent {...props} />
      </AscendCard>
      {!hideFooter && <Typography color={'textPrimary'} className={classes.footerText}>Don’t hesitate to call us if you get stuck!</Typography>}
    </Box>
  </Box>
}
