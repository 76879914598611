import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './Maketplace/MedicareAdvantage/App';
import Medigap from './Maketplace/Medigap/App';
import * as Questionnaire from './Questionnaire/App';
import * as Enrollment from './Enrollment/App';
import * as EnrollmentLong from './EnrollmentLong/App';
import * as Private from './Private/App';
import * as Quote from './Quote/App';
import * as serviceWorker from './serviceWorker';
import {Box, MuiThemeProvider} from "@material-ui/core";
import AppTheme from "./shared/AppTheme";
import { SnackbarProvider } from 'notistack';
import Preloader from "./shared/Preloader";
import {Helmet} from "react-helmet";
import {getCId} from "./shared/utils";

const QuestionnaireApp = Questionnaire.default;
const EnrollmentApp = Enrollment.default;
const EnrollmentLongApp = EnrollmentLong.default;
const PrivateApp = Private.default;
const QuoteApp = Quote.default;
if (window) {
  const urlParams = new URLSearchParams(window.location.search);
  let flow = 'Standalone flow';
  if (urlParams.has('product')) {
    flow = urlParams.get('product') as string;
  }
  Object.assign(window, {
    c_id: getCId(),
    product: flow,
    version: `2.8.10`
  })
}

const getApp = () => {
  if (process.env.REACT_APP_SITE === 'Medigap') {
    return <Medigap/>
  }
  if (process.env.REACT_APP_SITE === 'Quote') {
    return <QuoteApp/>
  }
  if (process.env.REACT_APP_SITE === 'Questionnaire') {
    return <QuestionnaireApp/>
  }
  if (process.env.REACT_APP_SITE === 'Enrollment') {
    return <EnrollmentApp/>
  }
  if (process.env.REACT_APP_SITE === 'EnrollmentLong') {
    return <EnrollmentLongApp/>
  }
  if (process.env.REACT_APP_SITE === 'Private') {
    return <PrivateApp/>
  }
  return <App/>
}

ReactDOM.render(
  <MuiThemeProvider theme={AppTheme}>
    <Helmet>
      <title>CoverRight</title>
      <meta
        name="description"
        content="Find the best Medicare plan for you"
      />
    </Helmet>
    <SnackbarProvider maxSnack={3} preventDuplicate={true}>
      <Suspense fallback={<Preloader />}>
        {getApp()}
      </Suspense>
    </SnackbarProvider>
  </MuiThemeProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

