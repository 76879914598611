import {PlanParam} from "./PlanParam";
import Benefit from "../../../../shared/components/Benefit";
import React from "react";
import {DoctorCoverage} from "../../../../types";
import {Collapse, Typography} from "@material-ui/core";
import SeeMore from "../../../../shared/components/SeeMore";

function DoctorCoverageList({coverage, isPPO}: { coverage: DoctorCoverage[], isPPO: boolean }) {
  const [expanded, setExpanded] = React.useState(false);

  if(coverage.length === 0) {
    return <Typography className={'fs-12 card-title'} color={'textPrimary'}>You haven’t added any doctors</Typography>;
  }

  return <>
    {coverage.slice(0, 3).map((doctor, index) => <PlanParam key={doctor.npi}
                                                            value={<Benefit inactive={doctor.coverage === null}
                                                                            overrideCrossIcon={isPPO ? '/img/yellow-check.svg' : undefined}
                                                                            value={doctor.coverage || false}
                                                                            label={doctor.name} />} />)}
    <Collapse in={expanded}>
      {coverage.slice(3).map((doctor, index) => <PlanParam key={doctor.npi}
                                                           value={<Benefit inactive={doctor.coverage === null}
                                                                           value={doctor.coverage || false}
                                                                           label={doctor.name} />} />)}
    </Collapse>
    {coverage.length > 3 && <SeeMore expanded={expanded} onToggle={value => setExpanded(!value)} />}
    </>
}

export {DoctorCoverageList}
