import useQuestionnaireRequest from "../../shared/hooks/useQuestionnaireRequest";
import React from "react";
import {IQuestionnaireState} from "../../Questionnaire/QuestionnaireContextProvider";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../Private/hooks/useUserProfile";
import paths from "../Medigap/config/router-paths";
import {AdminContext} from "./AdminContext";
import {setQuoteId} from "../../shared/QuoteId";
import {keycloak, KeycloakRoles} from "../../shared/keycloak";

export const QuoteContext = React.createContext<IQuestionnaireState>({});

export function QuoteProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const adminContext = React.useContext(AdminContext);

  React.useEffect(() => {
    if (adminContext?.clientView?.quoteId) {
      setQuoteId(adminContext?.clientView?.quoteId)
    }
  }, [adminContext?.clientView?.quoteId]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.quoteId || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.quoteId]);

  const [questionnaire, setQuestionnaire] = React.useState<IQuestionnaireState>({id: quoteId});

  const {authenticated} = React.useContext(KeycloakContext);
  const [getProfile, {data: profileData}] = useUserProfileLazy();

  const [getRequest] = useQuestionnaireRequest(setQuestionnaire);

  React.useEffect(() => {
    if (adminContext) {
      getRequest({variables: {id: quoteId}});
    } else {
      if (authenticated || props.private) {
        getProfile()
      }
      if (!authenticated && quoteId) {
        getRequest({variables: {id: quoteId}});
      }
    }
  }, [quoteId, authenticated, adminContext]);

  React.useEffect(() => {
    if (profileData?.userProfile?.quoteId) {
      setQuoteId(profileData?.userProfile?.quoteId);
      getRequest({variables: {id: profileData.userProfile.quoteId}});
    }
  }, [profileData])

  return <QuoteContext.Provider value={questionnaire}>
    {props.children}
  </QuoteContext.Provider>

}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}
