import {QueryHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import { MutationSaveMedigapQuoteArgs} from "../../../medigap-types";

export default function useSaveMedigapQuote(options?: QueryHookOptions<{ saveMedigapQuote: string }, MutationSaveMedigapQuoteArgs>) {
  return useMutation<{ saveMedigapQuote: string }, MutationSaveMedigapQuoteArgs>(
    gql(summaryQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const summaryQuery = `
mutation ($input: MedigapQuoteInput!) {
  saveMedigapQuote(input: $input)
}
`;
