import React from "react";
import {
  Box,
  ClickAwayListener,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {colors} from "../AppTheme";
import AscendTooltip from "./AscendTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: colors.text.primary,
      fontWeight: 500,
    },
    img: {
      marginTop: -4,
      padding: 10,
    }
  }),
);

const AscendTitle = (props: {title: string, tooltip?: string | JSX.Element}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Typography variant={'h3'} className={classes.root}>{props.title}</Typography>
      {props.tooltip && <>
        {typeof props.tooltip === 'string' && <>
          {phone && <ClickAwayListener onClickAway={handleTooltipClose}>
            <AscendTooltip disableFocusListener
                           onClose={handleTooltipClose}
                           open={open}
                           disableHoverListener
                           disableTouchListener
                           placement={'top'} arrow title={props.tooltip}>
              <img className={classes.img} onClick={handleTooltipOpen} src={'/img/info.svg'}></img>
            </AscendTooltip>
          </ClickAwayListener>}
          {!phone && <AscendTooltip placement={'top'} arrow title={props.tooltip}>
            <img className={classes.img} src={'/img/info.svg'}></img>
          </AscendTooltip>}
        </>}
        {typeof props.tooltip !== 'string' && <>{props.tooltip}</>}
      </>}
    </Box>
  );
}

export default AscendTitle;
