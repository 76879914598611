import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedigapPremiumRange} from "../../../../medigap-types";

export function useMedigapPremiumRanges() {
  return useLazyQuery<IMedigapPremiumRangeResponse, {zip: string}>(gql(premiumRangesQuery))
}

export interface IMedigapPremiumRangeResponse {
  medigapPremiumRanges: MedigapPremiumRange[],
}

const premiumRangesQuery = `
  query($zip: String!) {
    medigapPremiumRanges(zip: $zip) {
      planName
      rangeDescription
    }
  }
`;
