import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, IconButton, Typography} from "@material-ui/core";
import AscendModal from "./AscendModal";
import AscendButton from "./AscendButton";
import {config} from "../../Quote/config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    img: {
      width: 72,
      height: 72
    }
  }),
);

interface ReceivedRequestModalProps {
  open: boolean,
  onClose: () => void
}

const ReceivedRequestModal = (props: ReceivedRequestModalProps) => {
  const classes = useStyles();

  return <AscendModal
    width={590}
    open={props.open}
    onClose={props.onClose}
    modalScroll
  >
    <Box display={'flex'} flexDirection={'column'} flex={1}>
      <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Box display={'flex'} justifyContent={'center'} mb={3}>
        <Typography variant={'h1'} color={'textPrimary'} className={'text-align-centered'}>We’ve received your request!</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <img src={'/img/big-green-check.svg'} className={classes.img} />
      </Box>
      <Box display={'flex'} mt={'16px'} mb={'55px'} justifyContent={'center'}>
        <Typography variant={'h3'} color={'textSecondary'} className={'text-align-centered'}>A licensed CoverRight sales agent will be in touch soon. If it’s outside our business hours of 8am-11pm (ET) Mon-Sun, an agent will call you back during our next business hours.</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <AscendButton variant={'contained'} color={'primary'} onClick={props.onClose}>Return to plans</AscendButton>
      </Box>
    </Box>
  </AscendModal>;
}

export default ReceivedRequestModal;
