import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {VideoCategoryWithVideos} from "../../../types";


export default function useVideoCategoriesData() {
  return useQuery<{videoCategories: VideoCategoryWithVideos[]}>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
    }
  );
}

const request = `
query {
  videoCategories {
    id
    title
    sortingOrder
    videos {
      sortingOrder
      categoryId
      description
      duration
      id
      title
      url
    }
  }
}
`;
