import React from "react";
import {QuoteContext} from "../../shared/QuoteContext";
import Layout from "../../../shared/Layout";
import {Box} from "@material-ui/core";
import {withPlanFilterProvider} from "../PlanList/PlanFilterContext";
import Category from "./Category";
import CategoryStepper from "./CategoryStepper";
import {RouteComponentProps} from "react-router";

const CategoryLayout = (props: RouteComponentProps<{zip: string, countyName?: string }>) => {
  const {pin} = React.useContext(QuoteContext);

  return <Layout pin={pin} SideComponent={CategoryStepper} {...props}>
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Category />
    </Box>
  </Layout>
}


export default withPlanFilterProvider(CategoryLayout);
