import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, Grid, RadioGroup} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";
import {colors} from "../../shared/AppTheme";

const formName = 'STEP_2c';

function Step2c(props: RouteComponentProps<any>) {
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [value, setValue] = React.useState<Step2cAnswers>(state?.STEP_2c?.value?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        value: {
          questionText: 'Do any of these categories apply to you?',
          answerText: answers[value],
          value
        },
        form: formName
      }
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step3)
  };

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-32'} style={{maxWidth: 500}}>
      <RadioGroup>
        <AscendRadio checked={value === Step2cAnswers.LeftEmployer}
                     onChange={() => {setValue(Step2cAnswers.LeftEmployer)}}
                     label={answers[0]} />
        <AscendInfo open={value === Step2cAnswers.LeftEmployer}
                    description={<p style={{margin: '0'}}>If you have recently stopped receiving employer healthcare coverage, you have a 2-month window to enroll in a Medicare Advantage or Prescription Drug Plan.  If it’s your first time enrolling in Medicare Part B, you have a 6-month window to purchase a Medicare Supplement plan without a medical history check.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2cAnswers.NewAddress}
                     onChange={() => {setValue(Step2cAnswers.NewAddress)}}
                     label={answers[1]} />
        <AscendInfo open={value === Step2cAnswers.NewAddress}
                    description={<p style={{margin: '0'}}>If you have recently moved (or expect to move soon) and you're already enrolled in a Medicare Advantage or Prescription Drug Plan, you have a 3-month window to switch to a new plan when you let your insurance company know that you've moved.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2cAnswers.Eligibilty}
                     onChange={() => {setValue(Step2cAnswers.Eligibilty)}}
                     label={answers[2]} />
        <AscendInfo open={value === Step2cAnswers.Eligibilty}
                    description={<p style={{margin: '0'}}>If you become eligible for both Medicaid and Medicare, you can enroll or switch Medicare Advantage or Prescription Drug plans once every quarter between January and September. Between October 15 - December 7 (the Annual Enrollment Period), you can freely switch plans with an effective start date of January 1.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2cAnswers.LostEligibility}
                     onChange={() => {setValue(Step2cAnswers.LostEligibility)}}
                     label={answers[3]} />
        <AscendInfo open={value === Step2cAnswers.LostEligibility}
                    description={<p style={{margin: '0'}}>If you recently lost Medicaid benefits or were in a Dual-SNP or other type of SNP (such as Chronic Care-SNP or Institutional-SNP plan) and no longer qualify, you typically have a 3-month window from the date you are notified to switch plans.</p>}/>
        <div className={'h-8'} />
        <AscendRadio checked={value === Step2cAnswers.Other}
                     onChange={() => {setValue(Step2cAnswers.Other)}}
                     label={answers[4]} />
        <AscendInfo open={value === Step2cAnswers.Other}
                    description={<p style={{margin: '0'}}>If you don't fall into any of the categories above, you may still be able to make changes to your Medicare Advantage or Prescription Drug coverage if other special circumstances happen in your life. You can find the full list of special circumstances at <a target={'_blank'} style={{color: colors.custom.green.variant1}} href={'https://www.medicare.gov/sign-up-change-plans/when-can-i-join-a-health-or-drug-plan/special-circumstances-special-enrollment-periods'}>Medicare.gov</a>, or feel free to give us a call.</p>}/>
      </RadioGroup>
    </div>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} disabled={typeof value === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </Grid>;
}

const answers = [
  "I recently left employer insurance coverage",
  "I moved or am moving to a new address",
  "I gained eligibilty for Medicaid",
  "I lost Medicaid eligibility and/or am enrolled in a  Special Needs Plan (SNP) and no longer qualify",
  "Other special circumstance"
]

export default withTitle(
  Step2c,
  () => 'No problem, we can help with that.  Do any of \nthese categories apply to you?',
  ''
);

export enum Step2cAnswers {
  LeftEmployer,
  NewAddress,
  Eligibilty,
  LostEligibility,
  Other
}
