import React from "react";
import {Box, Typography,} from "@material-ui/core";
import {colors} from "../../../shared/AppTheme";
import {makeStyles} from "@material-ui/core/styles";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import useCommonStyles from "../../../shared/useCommonStyles";
import {DrugDeliveryTypeDto, PharmacyLocationDistanceOutput} from "../../../types";
import { RouteComponentProps } from "react-router-dom";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import AscendTitle from "../../../shared/components/AscendTitle";
import {PharmaciesSelection} from "../../../shared/components/PharmaciesSelection";
import {withTitle} from "../../../shared/components/withTitle";
import AscendButton from "../../../shared/components/AscendButton";
import AscendInfo from "../../../shared/components/AscendInfo";
import usePharmaciesByRequestId from "../../../Maketplace/MedicareAdvantage/PlanList/hooks/usePharmaciesByRequestId";

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  linkGray: {
    color: colors.text.secondary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 22,
    marginBottom: 20
  },
  addPharmacyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px dashed #D6DFDA',
    background: '#F5F7F6',
    borderRadius: 8,
    width: '100%',
    paddingTop: 27,
    paddingBottom: 23,
    color: colors.custom.green.variant1,
    fontWeight: 700,
    fontSize: 16,
  }
});

const formName = 'STEP_5a';

function Step5a(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const {pharmacies, setPharmacies, filled} = usePharmaciesByRequestId(state.id);

  const [orderType, setOrderType] = React.useState<DrugDeliveryTypeDto>();

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {
        preferredPharmacies: orderType === DrugDeliveryTypeDto.Pharmacy ? pharmacies : [],
        drugDeliveryType: orderType,
        form: formName,
        preferredPharmaciesFilled: true,
        stateKey: 'preferredPharmacies',}
    })
    props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.step6)
  };

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'40px'}>
      <AscendTitle title={'Where do you go to fill your prescriptions?'} />
      <div className={'h-8'} />
      <PharmaciesSelection zip={state.STEP_2?.zip}
                           selectedPharmacies={pharmacies}
                           filled={filled}
                           orderType={state?.preferredPharmacies?.drugDeliveryType}
                           onOrderTypeChange={setOrderType}
                           setSelectedPharmacies={setPharmacies} />
    </Box>
    <AscendInfo open={true}
                description={<>
                  <p style={{marginTop: 0, marginBottom: 0}}><strong>Choosing a pharmacy helps us more accurately estimate drug costs.</strong> We’ll find your drug cost-shares depending on your pharmacy type selection.</p></>}/>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    disabled={typeof orderType === 'undefined' || (orderType === DrugDeliveryTypeDto.Pharmacy && !pharmacies?.length)}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'30px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        props.history.push(paths.MAquestionnaire  + '/' + questionsRoutes.step6)
      }}>Click here to skip this step</Typography>
    </Box>
  </>
}

export default withTitle(
  Step5a,
  'Where do you normally fill your prescriptions?',
  'Selecting a pharmacy helps us more accurately estimate your total drug costs and find the lowest cost plan for you.',
  undefined,
  undefined,
  true
)
