import React from "react";
import {ComparisonContext} from "../../PlanComparison/ComparisonContext";
import {QuoteContext} from "../../PlanList/QuoteContext";
import {FavoritesContext} from "../../shared/FavoritesContext";
import useTemporalData from "../../../../shared/hooks/useTemporalData";
import {AdminContext} from "../../PlanList/AdminContext";
import {Box, createStyles, Divider, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {useScrollPosition} from "../../../../shared/useScrollPosition";
import useGetEHealthLinkRequest from "../../../../shared/hooks/useGetEHealthLinkRequest";
import {useHistory} from "react-router";
import {planStyles, RoundedButton} from "../../PlanList/PlanCard";
import planFiles from "../../shared/planFiles";
import {getStoredUser} from "../../../../shared/StoredUser";
import {config} from "../../config/config";
import AscendButton from "../../../../shared/components/AscendButton";
import AscendTooltip from "../../../../shared/components/AscendTooltip";
import AscendCard from "../../../../shared/components/AscendCard";
import {PlanTitle} from "../../PlanList/components/PlanTitle";
import AscendCheckbox from "../../../../shared/components/AscendCheckbox";
import clsx from "clsx";
import {PlanViewProps} from "../DetailedPlan";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../../shared/AppTheme";
import {getCompanyLogo} from "../../../../shared/utils";
import TooltipedTitle from "../../PlanList/components/TooltipedTitle";
import StyledRating from "../../PlanList/components/StyledRating";
import {getQuoteId} from "../../../../shared/QuoteId";
import {GetPlansYear} from "../../../shared/hooks/GetPlansYear";


const styles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {
      display: 'flex',
      position: 'sticky',
      top: 43,
      zIndex: 2,
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    withoutShadow: {
      boxShadow: 'none!important',
    },
    headerCardFixed: {
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      top: 31,
      height: 117,
      width: 'calc(100% - 40px)',
      padding: '7px 15px 15px',
      zIndex: 5,
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '12px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
  })
);

const HeaderCard = (props: PlanViewProps & {hideFixed?: boolean, hideSelectButton?: boolean}) => {
  let {zip, countyName, plan, setModalOpen, setEmailOpen} = props;
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const {preferredDrugs, pin} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const adminContext = React.useContext(AdminContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const {put} = useTemporalData();
  const scrollPosition = useScrollPosition();
  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
    if (scrollPosition > 450 && !collapsed) {
      setCollapsed(true)
    } else {
      if (scrollPosition < 50) {
        setCollapsed(false)
      }
    }
  }, [scrollPosition]);

  const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
    onCompleted: data => {
      if (data?.generateEhealthPlanLink) {
        navigator.clipboard.writeText(data?.generateEhealthPlanLink)
      }
    }
  })

  const history = useHistory();
  const ownClasses = styles();
  const classes = planStyles();

  const copyEHealthLink = () => {
    generateEhealthPlanLink({variables: {bidId: plan.bidId, countyName, zip}})
  }


  const onEnrollClick = () => {
    if (localStorage.getItem('flow') === 'v2') {
      history.push(`/completed/${zip}/${countyName}/${plan.bidId}`)
    } else {
      const quoteId = getQuoteId();
      document.location.href = config.enrollUrl + 'enroll/' + plan.bidId + '/' + zip + '/' + countyName + (quoteId ? '/' + quoteId : '') + '?year=' + GetPlansYear();
    }
  }

  const EnrollSection = () => {
    if (!phone) {
      return <div className={ownClasses.enrollSection}>
        {!!adminContext && <Box position={'absolute'} right={5} top={5}
                                onClick={copyEHealthLink}
                                className={`${classes.excl} fs-12 pointer underline`}>E Health</Box>}
        <Box display={"flex"} flexDirection={"column"} flex={1}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography className={classes.price}>{plan?.monthlyCost || "N/A"}</Typography>
            <Box mb={"3px"}>
              <img className={'w-24 h-24'} src={"/img/info-green.svg"}/>
            </Box>
          </Box>
          <Typography variant={"body2"} className={classes.bold} color={'textPrimary'}>estimated average total cost per
            month</Typography>
        </Box>
        {!collapsed && <>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.monthlyPremium}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={classes.bold}>{plan?.drugsCost || 'N/A'}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.doctorsCost}</Typography>
          </div>
        </>}
        {!props.hideSelectButton && <Box mt={"14px"}>
          <AscendButton className={classes.enrollButton}
                        onClick={onEnrollClick}
                        variant={"contained"}>
            Select this plan
          </AscendButton>
        </Box>}
      </div>
    } else {
      return <div className={ownClasses.enrollSection}>
        <div className={'flex-column drug-calculation'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className={classes.price}>{plan?.monthlyCost || 'N/A'}</Typography>
            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-end'} flex={1}>
              <Typography variant={'body2'} color={'textPrimary'} className={classes.bold} align={'right'}>average total
                cost per month</Typography>
              <AscendTooltip placement={'top'} arrow
                             title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
                <img className={'w-15 h-15 ml-10'} src={'/img/info-green.svg'}/>
              </AscendTooltip>
            </Box>
          </Box>
          <div className={'mt-8 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={classes.bold}>{plan?.monthlyPremium}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={classes.bold}>{plan?.drugsCost || 'N/A'}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.doctorsCost}</Typography>
          </div>
          {!!preferredDrugs?.preferredDrugs?.length && <Box mt={'12px'}>
            <AscendButton className={classes.summaryButton} onClick={() => setModalOpen(true)} variant={'outlined'}>Drug
              cost breakdown</AscendButton>
          </Box>
          }
        </div>
      </div>
    }

  }

  return <AscendCard className={`${ownClasses.headerCard} ${collapsed && ownClasses.withoutShadow}`}>
    {(phone && !props.hideFixed && collapsed) && <AscendCard className={ownClasses.headerCardFixed}>
      <div className={'flex-column'}>
        <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(props.plan.parentOrgName, props.plan.orgName)}")`}}  />
        <Box maxHeight={'48px'} overflow={'hidden'}>
          <Typography color={'textPrimary'} className={'bold fs-20 lh-24'}>{plan.planName}</Typography>
        </Box>
      </div>
      <div className={'flex-column'}>
        <TooltipedTitle title={!plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
          : <StyledRating precision={0.5} readOnly defaultValue={plan.rating || 0}/>}
                        tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
                        imgProps={{
                          width: 12,
                          height: 12
                        }}/>
        <Box mt={'-8px'}>
          <Typography className={`${classes.price} mr-0`} align={'right'}>{plan?.monthlyCost || 'N/A'}</Typography>
        </Box>
        <Box mt={'-8px'}>
          <Typography className={'bold fs-12 lh-13'} align={'right'} color={'textPrimary'}>estimated average total cost per month</Typography>
        </Box>
      </div>
    </AscendCard>}
    <Box display={(phone && !props.hideFixed && collapsed) ? 'none' : "flex"} flexDirection={phone ? 'column' : 'row'} p={phone ? '0 12px' : '7px 15px 7px 0'}>
      <div className={classes.infoWrapper}>
        <PlanTitle showBid={!phone && !collapsed} plan={plan} className={ownClasses.title}
                   inFavorites={favorites.indexOf(plan.bidId) >= 0}
                   favoritesClick={() => toggleFavorite(plan.bidId, zip)}/>
        {(!!preferredDrugs?.preferredDrugs?.length && !phone && !collapsed && !plan.missingFormularyInAep) && (
          <Box width={"100%"} textAlign={'right'} paddingBottom={'18px'}>
              <RoundedButton variant={'contained'} onClick={() => setModalOpen(true)}>See monthly drug cost</RoundedButton>
          </Box>
        )}
        <Box width={"100%"}>
          {!phone && <>
            <Divider className={classes.divider}/>
            <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'} mt={"20px"}>
              <Box display={'flex'}>
                <div className={ownClasses.iconButton} onClick={() => setEmailOpen(true)}>
                  <img src={'/img/message.svg'}/>
                  <div>Email me this plan</div>
                </div>
              </Box>
              <AscendCheckbox variant={"standart"} controlClassName={clsx(classes.checkbox, ownClasses.checkbox)}
                              labelClassName={classes.checkboxLabel}
                              label={"Add to compare"} checked={isInCompare(plan.bidId)}
                              onChange={(e, checked) => {
                                checked ? addToCompare(plan.bidId) : removeFromCompare(plan.bidId)
                              }}/>
            </Box>
          </>}
        </Box>
      </div>
      <EnrollSection/>
      {(phone && !props.hideSelectButton) && <Box mt={"14px"}>
        <AscendButton className={classes.enrollButton}
                      onClick={onEnrollClick}
                      variant={"contained"}>
          Select this plan
        </AscendButton>
      </Box>}
    </Box>
  </AscendCard>
}

export default React.memo(HeaderCard);
