import React from "react";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {MenuItem, Typography, useMediaQuery} from "@material-ui/core";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendCard from "../../../shared/components/AscendCard";
import FormSelectInput from "../../../shared/components/FormSelectInput";
import * as _ from 'lodash';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import FormTextInput, {TickAdornment} from "../../../shared/components/FormTextInput";
import {colors} from "../../../shared/AppTheme";
import {Gender} from "../../../medigap-types";
import {getLabelForCategory, planCategories, PlanCategory} from "../Category/Category";
import paths from "../config/router-paths";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 10,
      padding: '12px 18px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    list: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingInlineStart: 0,
      listStyle: 'inside',
      '& li': {
        color: colors.text.primary,
        lineHeight: '20px',
        fontSize: 14
      }
    },
    info: {
      background: `rgba(94, 203, 158, 0.05)`,
      border: `1px solid #5ECB9E`,
      borderRadius: 4,
      padding: 10,
      marginBottom: 5,
    },
    datepickerRoot: {
      '& > div:first-child': {
        marginBottom: 3
      }
    },
    datepicker: {
      paddingRight: '0px!important',
      '& > input' : {
        padding: '9px 0 9px 15px!important',
        fontSize: 12,
      }
    },
    categoryInfo: {
      background: 'rgba(2, 144, 148, 0.05)',
      borderRadius: '0px 0px 10px 10px',
      padding: '11px 20px',
    },
    select: {
      '& .MuiOutlinedInput-input': {
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 15px 10px!important',
        zIndex: 1,
        lineHeight: 'normal',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: 4,
        height: 36,
      },
    },
    paper: {
      '& li.MuiListItem-root': {
        fontSize: 12,
      }
    }
  }),
);

export default function PlanTopFilter() {
  let {zip, countyName} = useParams();
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(moment(filter.values?.effectiveDate).toDate());
  const history = useHistory();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  zip = zip || filter?.values?.zip;
  countyName = countyName || filter?.values?.county;

  React.useEffect(() => {
    if (moment(selectedDate).isValid()) {
      filter.switchEffectiveDate(moment(selectedDate).format('YYYY-MM-DD'));
    } else if (selectedDate === null) {
      filter.switchEffectiveDate(undefined);
    }
  }, [selectedDate]);

  if (!filter.values) {
    return null;
  }

  const Desktop = <>
    <AscendCard className={classes.card}>
      <div className={'flex-space-between'}>
        <div className={'flex flex-align-center mr-20'}>
          <Typography color={'textPrimary'} className={'medium fs-14 mr-10'}>Age</Typography>
          <FormSelectInput label={''}
                           className={`mb-0 w-95 ${classes.select}`}
                           paperClassName={classes.paper}
                           placeholder={'Select your age'}
                           value={filter.values.age}
                           onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                             filter.switchAge(event.target.value as number)
                           }}
          >
            <MenuItem key={64} value={64}>&lt;64</MenuItem>
            {_.fill(Array(50), 2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
          </FormSelectInput>
        </div>
        <div className={'flex flex-align-center mr-20'}>
          <Typography color={'textPrimary'} className={'medium fs-14 mr-10'}>Gender</Typography>
          <FormSelectInput label={''}
                           className={`mb-0 w-95 ${classes.select}`}
                           paperClassName={classes.paper}
                           placeholder={'Select your gender'}
                           value={filter.values.gender}
                           onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                             filter.switchGender(event.target.value as Gender)
                           }}
          >
            <MenuItem value={'M'}>Male</MenuItem>
            <MenuItem value={'F'}>Female</MenuItem>
          </FormSelectInput>
        </div>
        <div className={'flex flex-align-center mr-20'}>
          <Typography color={'textPrimary'} className={'medium fs-14 mr-10'}>Tobacco?</Typography>
          <FormSelectInput label={''}
                           paperClassName={classes.paper}
                           className={`mb-0 w-95 ${classes.select}`}
                           value={filter.values.tobacco?.toString()}
                           onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                             filter.toggleTobacco(event.target.value === 'true' ? true : false)
                           }}
          >
            <MenuItem value={'false'}>No</MenuItem>
            <MenuItem value={'true'}>Yes</MenuItem>
          </FormSelectInput>
        </div>
        <div className={'flex flex-align-center'}>
          <Typography color={'textPrimary'} className={'medium fs-14 mr-10 no-wrap'}>Effective date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoFocus={true}
              variant="inline"
              format="MM/dd/yyyy"
              className={'mv-0'}
              autoOk
              disablePast
              onKeyPress={event => {
                if (event.key === 'Enter' && moment(selectedDate).isValid()) {
                  filter.switchEffectiveDate(moment(selectedDate).format('YYYY-MM-DD'));
                }
              }}
              InputProps={{
                classes: {
                  root: classes.datepicker
                }
              }}
              placeholder={'MM / DD / YYYY'}
              value={selectedDate}
              invalidDateMessage={'Please enter a valid date'}
              maxDateMessage={'Please enter a valid date'}
              minDateMessage={'Please enter a valid date'}
              onChange={setSelectedDate}
              TextFieldComponent={FormTextInput as any}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

    </AscendCard>
    <AscendCard className={`${classes.card} mt-16`}>
      <div className={'flex flex-space-between flex-align-center'}>
        <Typography color={'textPrimary'} className={'bold fs-14'}>Select Medigap plan category</Typography>
        <Typography className={'medium fs-12 dark-green pointer underline'}
                    onClick={() => history.push(paths.category + zip + '/' + countyName)}>
          Not sure what plan category is right for you? <br /> <strong>Click here to find out.</strong>
        </Typography>
        {filter.values.selectedPlanCategory && <FormSelectInput label={''}
                         className={`mb-0 w-210 ${classes.select}`}
                         value={filter.values.selectedPlanCategory}
                         paperClassName={classes.paper}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           filter.switchCategory(event.target.value as PlanCategory);
                         }}
        >
          {planCategories.map(i => i.name).map(name => <MenuItem key={name} value={name}>{name}</MenuItem>)}
        </FormSelectInput>}
      </div>
    </AscendCard>
    <div className={classes.categoryInfo}>
      {getLabelForCategory(filter.values.selectedPlanCategory, classes.list, false, 'fs-14 bold')}
    </div>
  </>;

  const Mobile = <>
    <AscendCard className={classes.card}>
      <Typography color={'textPrimary'} className={'medium fs-14'}>Enter your details so we can provide more accurate monthly premium prices</Typography>
      <div className={'flex-space-between mt-10'}>
        <FormSelectInput label={'Age'}
                         labelClassName={'mb-0'}
                         className={`mb-10 mr-5 ${classes.select}`}
                         placeholder={'Select your age'}
                         value={filter.values.age}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           filter.switchAge(event.target.value as number)
                         }}
        >
          <MenuItem key={64} value={64}>&lt;64</MenuItem>
          {_.fill(Array(50), 2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
        </FormSelectInput>
        <FormSelectInput label={'Gender'}
                         labelClassName={'mb-0'}
                         className={`mb-10 ml-5 ${classes.select}`}
                         placeholder={'Select your gender'}
                         value={filter.values.gender}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           filter.switchGender(event.target.value as Gender)
                         }}
        >
          <MenuItem value={'M'}>Male</MenuItem>
          <MenuItem value={'F'}>Female</MenuItem>
        </FormSelectInput>
      </div>
      <div className={'flex-space-between'}>
        <FormSelectInput label={'Tobacco?'}
                         labelClassName={'mb-0'}
                         className={`mb-10 mr-5 ${classes.select}`}
                         value={filter.values.tobacco?.toString()}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           filter.toggleTobacco(event.target.value === 'true' ? true : false)
                         }}
        >
          <MenuItem value={'false'}>No</MenuItem>
          <MenuItem value={'true'}>Yes</MenuItem>
        </FormSelectInput>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoFocus={true}
            variant="inline"
            format="MM/dd/yyyy"
            className={`mv-0 ml-5 ${classes.datepickerRoot}`}
            label={'Effective date'}
            onKeyPress={event => {
              if (event.key === 'Enter' && moment(selectedDate).isValid()) {
                filter.switchEffectiveDate(moment(selectedDate).format('YYYY-MM-DD'));
              }
            }}
            InputProps={{
              classes: {
                root: classes.datepicker
              }
            }}
            placeholder={'MM / DD / YYYY'}
            value={selectedDate}
            invalidDateMessage={'Please enter a valid date'}
            maxDateMessage={'Please enter a valid date'}
            minDateMessage={'Please enter a valid date'}
            onChange={setSelectedDate}
            TextFieldComponent={FormTextInput as any}
          />
        </MuiPickersUtilsProvider>
      </div>
    </AscendCard>
    <AscendCard className={`${classes.card} mt-16`}>
      <div className={'flex flex-space-between'}>
        <Typography color={'textPrimary'} className={'medium fs-14 mb-10'}>Select Medigap plan category</Typography>
        {filter.values.selectedPlanCategory && <FormSelectInput label={''}
                         className={`mb-16 ml-5 ${classes.select}`}
                         value={filter.values.selectedPlanCategory}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           filter.switchCategory(event.target.value as PlanCategory);
                         }}
        >
          {planCategories.map(i => i.name).map(name => <MenuItem key={name} value={name}>{name}</MenuItem>)}
        </FormSelectInput>}
      </div>
      <div className={classes.info}>
        {getLabelForCategory(filter.values.selectedPlanCategory, classes.list)}
      </div>
      <Typography className={'medium fs-12 dark-green pointer underline'}
                  onClick={() => history.push(paths.category + zip + '/' + countyName)}>
        Not sure what plan category is right for you? <br /> <strong>Click here to find out.</strong>
      </Typography>
    </AscendCard>
  </>

  return phone ? Mobile : Desktop;
}
