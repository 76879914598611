import React from "react";
import {Plan} from "../../../../types";
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import StyledRating from "./StyledRating";
import {planStyles} from "../PlanCard";
import {getCompanyLogo} from "../../../../shared/utils";
import clsx from 'clsx';
import TooltipedTitle from "./TooltipedTitle";
import moment from "moment";
import {GetPlansYear} from "../../../shared/hooks/GetPlansYear";

interface PlanTitleProps {
  plan: Plan,
  showBid?: boolean,
  className?: string,
  inFavorites?: boolean,
  hideFavorites?: boolean,
  favoritesClick?: () => void,
  titleClick?: () => void,
}

export function PlanTitle({plan, className, showBid, inFavorites, favoritesClick, hideFavorites, titleClick}: PlanTitleProps) {
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const rating = !plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
    : <StyledRating size={phone ? 'small' : 'medium'} precision={0.5} readOnly defaultValue={plan.rating || 0}/>;

  const Desktop = () => <Box flex={1} className={clsx('flex', className)}>
    <div className={'flex-column'}>
      <div className={'flex-space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <TooltipedTitle title={rating}
            tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
            imgProps={{
              className: `w-12 h-12 ${!plan.rating ? 'mt-5' : 'mt-16'}`,
            }}/>
          {!!plan.rating && <Typography className={'medium fs-12 mt-5 ml-8'} color={'textPrimary'}>({plan.planYear.replace('Year', '')})</Typography>}
        </Box>
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart-fill.svg'}/>
          </>}
        </div>}
      </div>
      <div className={'flex-space-between'}>
        <div className={'mt-8'}>
          <Typography variant={'h2'} color={'textPrimary'} className={'bold pointer'} onClick={titleClick}>{plan.planName}</Typography>
          {showBid && <>
            <div className={'h-20'} />
            <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>
          </>}
        </div>
        <div>
          <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
        </div>
      </div>
    </div>
  </Box>

  const Mobile = () => <div className={'flex-column'}>
    <div className={'flex-space-between'}>
      <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
      <div>
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart-fill.svg'}/>
          </>}
        </div>}
        <TooltipedTitle title={rating}
                        containerClassName={'flex-justify-end'}
                        tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
                        imgProps={{
                          width: 12,
                          height: 12
                        }}/>
        {!!plan.rating && <Typography className={'medium fs-11'} align={'right'} color={'textPrimary'}>(2022)</Typography>}
      </div>
    </div>
    <div className={'flex-space-between'}>
      <Box display={'flex'} flex={1} className={'mt-8'}>
        <Typography variant={'h2'} color={'textPrimary'} className={'bold fs-20 lh-24'} onClick={titleClick}>{plan.planName}</Typography>
        {showBid && <>
          <div className={'h-20'} />
          <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>
        </>}
      </Box>
      <div>

      </div>
    </div>
  </div>

  return <div className={clsx('flex', className)}>
    {phone ? <Mobile /> : <Desktop/>}
  </div>
}
