import React from 'react';
import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../EnrollmentApolloClient";
import {KeycloakUserInput, KeycloakUserOutput} from "../../enrollment-types";

export default function useCreateUser(options?: MutationHookOptions<CreateUserOutput, {data: KeycloakUserInput}>) {
  return useMutation<CreateUserOutput, {data: KeycloakUserInput}>(
    gql(createUserMutation),
    {
      client: client,
      ...options
    }
  );
}

interface CreateUserOutput {
  createUser: KeycloakUserOutput
}


const createUserMutation = `
mutation ($data: KeycloakUserInput!) {
  createUser(input: $data) {
    enrollmentId
    userId
  }
}
`;
