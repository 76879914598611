import React from "react";
import {Box, Typography, withStyles} from "@material-ui/core";
import moment from "moment";
import MedicareDisclaimersModal from "../shared/components/MedicareDisclaimersModal";
import HelpModal from "../../shared/components/HelpModal";
import AscendButton from "../../shared/components/AscendButton";

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);

  return <Box maxWidth={800}>
    <HelpModal open={open} onClose={() => setOpen(false)} />
    <MedicareDisclaimersModal open={openDisclaimer} onClose={() => setOpenDisclaimer(false)}/>
    <Box mt={'34px'} textAlign={"center"} mb={'40px'}>
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}><strong>Need some help?</strong> Call us at the number below to speak with a licensed<br/> insurance agent or schedule a time through the link below.</Typography>
      <img src={'/img/support.svg'} className={'mv-30'} />
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}>
        <strong className={'fs-20 lh-25'}>+1 (888) 969-7667</strong><br/>(TTY: 711)
      </Typography>
      <Box mt={'21px'} mb={'30px'}>
        <RoundedButton className={'gtm-button-event'} data-gtm-button-name={'BOOK_A_CALL_BUTTON'} variant={'contained'} onClick={() => setOpen(true)}>
          Book a call
        </RoundedButton>
      </Box>
      <Typography className={'fs-16 text-align-centered lh-20'} color={'textPrimary'}>Mon-Sun 8AM-11PM (ET)</Typography>
    </Box>
    <Typography color={'textPrimary'} className={'mt-75 fs-12'}>
      PLEASE NOTE: Medicare Supplement insurance is available to those age 65 and older enrolled in Medicare Parts A and B and, in some states, to those under age 65 eligible for Medicare due to disability or End-Stage Renal disease.<br /><br />
      The purpose of this communication is the solicitation of insurance. Contact will be made by an insurance agent/producer or insurance company.<br /><br />
      Medicare Supplement insurance plans are not connected with or endorsed by the U.S. government or the federal Medicare program.
    </Typography>
    <Box mt={'30px'} mb={'10px'}>
      <Typography variant={'h5'} color={'textPrimary'}>
        If you need help, please call +1 (888) 969-7667 (TTY: 711) Mon-Sun 8AM-11PM (ET) for licensed insurance
        agents who can assist with finding information on available Medicare Advantage, Medicare Supplement Insurance
        and Prescription Drug Plans.
      </Typography>
    </Box>
    <Typography variant={'h5'} color={'textPrimary'}>
      Last Updated Date: {moment().format('M/D/YYYY')}
    </Typography>
    <div className={'h-200'}/>
  </Box>
}

const RoundedButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 22px',
  },
  label: {
    color: 'white',
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
