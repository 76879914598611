import React from "react";
import {Box, Collapse, createStyles, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import AscendModal from "../../../shared/components/AscendModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      textAlign: 'center',
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer'
    },
    phone: {
      color: colors.custom.green.variant1,
      display: 'flex',
      borderRadius: 4,
      fontSize: 36,
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 10
      }
    },
    checkboxWrapper: {
      alignItems: 'flex-start',
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    textLink: {
      color: colors.text.primary,
    }
  })
);

type EmployedModalProps = {
  onClose: () => void,
  open: boolean
}

export default function LastPageHelpModal(props: EmployedModalProps) {
  const classes = useStyles();

  return <AscendModal
    width={590}
    disableSidePadding
    open={props.open}
    onClose={props.onClose}
  >
    <>
      <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.centered}>Want to speak to someone now?</Typography>
        </Box>
        <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
          <Typography variant={'h3'} color={'textSecondary'} className={classes.centered}>Call the below number to be connected with the next available licensed agent.</Typography>
        </Box>
        <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
          <a href="tel:8889697667" className={classes.phone}>
            <img src={'/img/phone.svg'} width={36} height={36} />
            +1 (888) 969-7667
          </a>
        </Box>
        <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
          <img src={'/img/support.svg'} />
        </Box>

      </Box>
    </>
  </AscendModal>
}
