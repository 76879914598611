import React from 'react';
import './App.css';
import '../../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {client, setErrorHandler} from "../../shared/ApolloClient";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import paths from "./config/router-paths";
import DetailedPlan from "./DetailedPlan/DetailedPlan";
import {ComparisonProvider} from "./PlanComparison/ComparisonContext";
import PlanComparison from "./PlanComparison/PlanComparison";
import {FavoritesProvider} from "../shared/FavoritesContext";
import ZipSelection from "./ZipSelection";
import {getMedigapQuoteId} from "../../shared/QuoteId";
import {KeycloakProvider} from "../../shared/KeycloakContext";
import {keycloak} from "../../shared/keycloak";
import {SnackbarProvider} from "notistack";
import Favorites from "./Favorites/Favorites";
import LayoutWithAdminNotification from "./LayoutWithAdminNotification";
import {AdminContextProvider} from "../shared/AdminContext";
import {getClientId} from "../../shared/ClienId";
import DashboardLayout from "./PlanList/DashboardLayout";
import CategoryLayout from "./Category/CategoryLayout";
import * as QueryString from "querystring";
import {MedigapQuoteProvider} from "../shared/MedigapQuoteContext";
import {getStoredUser} from "../../shared/StoredUser";
import {getCId, getUId} from "../../shared/utils";

function App() {

  React.useEffect(() => {
    if ((window as any).hj) {
      (window as any).hj('identify', getCId(), {
        email: getStoredUser()?.email,
        c_id: getCId(),
        uid: getUId(),
      })
    }
  }, []);

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ComparisonProvider>
      <SnackbarProvider preventDuplicate classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
        <KeycloakProvider keycloak={keycloak}>
          <ApolloProvider client={client}>
            <AdminContextProvider clientId={getClientId()}>
              <LayoutWithAdminNotification>
                <MedigapQuoteProvider quoteId={getMedigapQuoteId()}>
                  <FavoritesProvider>
                      <BrowserRouter>
                        <Switch>
                          <Route exact path={paths.plans} component={ZipSelection} />
                          <Route path={paths.category + ':zip?/:countyName?'} component={CategoryLayout} />
                          <Route path={paths.plans + ':zip/:countyName'} component={DashboardLayout} />
                          <Route exact path={paths.planDetails + ':zip/:countyName/:planId'} component={DetailedPlan} />
                          <Route exact path={paths.comparison + ':zip/:countyName'} component={PlanComparison} />
                          <Route exact path={paths.favorites + ':zip/:countyName'} component={Favorites} />
                          <Route exact path={paths.home}>
                            <Redirect to={paths.plans + document.location.search} />
                          </Route>
                        </Switch>
                      </BrowserRouter>
                  </FavoritesProvider>
                </MedigapQuoteProvider>
              </LayoutWithAdminNotification>
            </AdminContextProvider>
          </ApolloProvider>
        </KeycloakProvider>
      </SnackbarProvider>
    </ComparisonProvider>
  );
}

export default App;
