import React from "react";
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {GraphQLErrorType} from "../../../shared/ApolloClient";
import useSaveUserProfile from "../../../Private/hooks/useSaveUserProfile";
import {makeStyles} from "@material-ui/core/styles";
import CreateProfileForm from "../components/CreateProfileForm";
import {ProfileOption, ProfileSource} from "../../../enrollment-types";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {config} from "../../config/config";
import {getCId} from "../../../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      flexDirection: "column-reverse",
      alignItems: "center",
      "& > *": {
        maxWidth: 410,
        minWidth: 300,
        width: "100%"
      }
    },
  })
);

const formName = "STEP_9";

type Step9Props = RouteComponentProps<any> & {quote: any, dispatch: any, onNextStep: () => void};

let promise = (res: any) => {
  return new Promise((resolve, reject) => {
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://trkdis.com/l/con?cbiframe=1&oid=77108");
    ifrm.style.width = "1px";
    ifrm.style.height = "1px";
    document.body.appendChild(ifrm);
    setTimeout(() => {
      resolve(res)
    }, 3000);
  })
};

function Step9({quote, dispatch, ...props}: Step9Props) {
  const classes = useStyles();
  const [saveError, setSaveError] = React.useState();
  const [emailError, setEmailError] = React.useState();
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {authenticated, keycloak} = React.useContext(KeycloakContext);

  const [create, data] = useSaveUserProfile()

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  React.useEffect(() => {
    if (authenticated && quote?.QUIZ_3?.medicarePlanType?.value === 'MA') {
      const route = quote?.QUIZ_3?.medicarePlanType?.value === 'MA' ? paths.MAquestionnaire : paths.MGquestionnaire;
      config.fullFlow ? props.history.push(route + "/" + questionsRoutes.step9a) : props.history.push(route + "/" + questionsRoutes.step10)
    }
  }, [authenticated])

  const onSubmit = (values: any) => {
    if (values?.email) {
      setDisabled(true);
      setLoading(true);
      setStoredUser({...getStoredUser(), firstName: values.firstName, lastName: values.lastName, email: values.email, phoneNumber: values.phoneNumber})
      create({
        variables: {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            birthDate: getStoredUser()?.birthDate,
            zip: getStoredUser()?.zip,
            countyName: getStoredUser()?.countyName,
            quoteId: getStoredUser()?.quoteId,
            medigapQuoteId: getStoredUser()?.medigapQuoteId,
            tobacco: typeof getStoredUser()?.tobacco !== 'undefined' ? getStoredUser()?.tobacco === 'true' : undefined,
            gender: getStoredUser()?.gender,
            cId: getCId(),
            source: ProfileSource.UserFlowV1,
            options: [ProfileOption.RegisterUser],
            insuranceType: 'MG'
          }
        }
      }).then(promise)
      .then((res: any) => {

        (window as any).gtm_profile_id = res.data?.saveUserProfile?.profileId;
        setStoredUser({...getStoredUser(), profileId: res.data?.saveUserProfile.profileId})

        if ((window as any).track) {
          (window as any).track('profile-saved', {'profile_id': res.data?.saveUserProfile?.profileId, source: ProfileSource.UserFlowV1});
        } else {
          console.warn('No track on the page')
        }

        if ((window as any).hj) {
          (window as any).hj('identify', getCId(), {
            email: getStoredUser()?.email,
            c_id: getCId(),
          })
        } else {
          console.warn('No hotjar on the page')
        }
        /*const storage = new CrossStorageClient(config.enrollUrl + 'hub.html', {});
        return storage.onConnect().then(function() {
          return storage.set('USER', JSON.stringify(getStoredUser()));
        }).catch(props.onNextStep)*/
        if (res.data?.saveUserProfile?.userToken && keycloak) {
          keycloak.init({
            onLoad: 'check-sso',
            checkLoginIframe: false,
            idToken: res.data.saveUserProfile.userToken.idToken,
            token: res.data.saveUserProfile.userToken.accessToken,
            refreshToken: res.data.saveUserProfile.userToken.refreshToken,
          }).then((authenticated: boolean) => {
            console.log(keycloak);
            const req = new XMLHttpRequest();
            req.open('GET', `${config.keycloak.url}realms/${config.keycloak.realm}/set-cookie-by-token`, true);
            req.setRequestHeader('authorization', 'Bearer ' + keycloak?.token);
            req.withCredentials = true;
            req.onreadystatechange = function () {
              if (req.readyState === 4 && req.status === 200) {
                props.onNextStep()
              }
            };
            req.send();
          })
        } else if (res.data?.saveUserProfile?.profileId) {
          props.onNextStep()
        }
      }).catch(error => {
        if (error?.graphQLErrors) {
          switch (error.graphQLErrors[0]?.extensions?.type) {
            case "INVALID_EMAIL":
            case GraphQLErrorType.ALREADY_EXISTS:
              setEmailError(<>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
                keycloak.login({redirectUri: document.location.href})
              }} style={{color: "red"}}>sign in</a></>);
              break;
            case GraphQLErrorType.NO_CONNECTION:
              setSaveError("Service is not available");
              break;
            default:
              setSaveError("Unknown error")
          }
        } else if (error?.message) {
          setSaveError(error?.message)
        }
      }).finally(() => {
        setDisabled(false);
        setLoading(false);
      })
    }
  };

  return <>
    <Box display={"flex"} justifyContent={"center"} flex={1}>
      <Box>
        <Typography className={'red fs-14 mt-16'} align={'center'}>{saveError}</Typography>
        <div className={"h-16"}/>
        <CreateProfileForm onSubmit={onSubmit}
                           loading={loading}
                           emailError={emailError}
                           disabled={disabled}
                           buttonWrapperClassName={classes.buttonWrapper}
                           submitButtonLabel={(window as any).product === 'short-medigap' ? 'See Your Personalized Rates' : 'Go to Medicare Plan Finder Dashboard'}
                           backButtonLabel={"Back"}
                           onCancelClick={() => props.history.goBack()}/>
      </Box>
    </Box>
  </>
}

export default Step9;
