import React from "react";
import {Plan} from "../../../../types";
import AscendButton from "../../../../shared/components/AscendButton";
import {planStyles} from "../../PlanList/PlanCard";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {ComparisonContext} from "../ComparisonContext";
import {makeStyles} from "@material-ui/core/styles";
import {getCompanyLogo, getMedigapCompanyLogo} from "../../../../shared/utils";
import {useScrollPosition} from "../../../../shared/useScrollPosition";
import {colors} from "../../../../shared/AppTheme";
import StyledRating from "../../../shared/components/StyledRating";
import {config} from "../../config/config";
import {getQuoteId} from "../../../../shared/QuoteId";
import {MedigapPlanOutput} from "../../../../medigap-types";
import {PlanFilterContext} from "../../PlanList/PlanFilterContext";
import moment from "moment";


const styles = makeStyles((theme: Theme) =>
  createStyles({
    removeButton: {
      background: 'rgba(164, 180, 185, 0.1)',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 7,
    },
    planName: {
      fontWeight: 500,
      color: colors.custom.green.variant2
    },
    premium: {
      fontWeight: 500,
      fontSize: 16,
    },
    logo: {
      height: 35,
      width: 99,
      minWidth: 99,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    ratingIcon: {
      width: 20,
      height: 20,
      marginRight: 4
    }
  }),
);

type CompareHeaderCardProps = {
  plan: MedigapPlanOutput,
  zip: string,
  countyName: string
}

export default function CompareHeaderCard({plan, countyName, zip}: CompareHeaderCardProps) {
  const classes = styles();
  const planClasses = planStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const scrollPosition = useScrollPosition();
  const [collapsed, setCollapsed] = React.useState(false);
  const {values: filterValues} = React.useContext(PlanFilterContext)

  React.useEffect(() => {
      if (scrollPosition > 150 && !collapsed) {
        setCollapsed(true)
      } else {
        if (scrollPosition < 50) {
          setCollapsed(false)
        }
      }
  }, [scrollPosition]);

  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <div className={classes.logo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title;
      return <Typography className={'medium fs-16'} color={'textPrimary'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);

  return <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'} minHeight={collapsed ? '175px' : '175px'}>
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        {Logo}
        <IconButton onClick={() => removeFromCompare(plan.key)} className={classes.removeButton}><img src={'/img/icon-close.svg'} /></IconButton>
      </Box>
      <Typography color={'textPrimary'} className={'medium fs-14 mt-10'}>Monthly plan premium from:</Typography>
      <Typography color={'textPrimary'} className={'medium fs-36'}>{plan.monthlyPremium}</Typography>
    </Box>
    <div>
      <AscendButton className={planClasses.enrollButton}
                    onClick={() => document.location.href = `${config.enrollUrl}enroll/${plan.key}/${zip}/${countyName}${getQuoteId() ? '/' + getQuoteId() : ''}?medigap&age=${filterValues?.age}&tobacco=${filterValues?.tobacco}&gender=${filterValues?.gender}&effectiveDate=${filterValues?.effectiveDate || '2021-07-12'}`}
                    variant={'contained'}>Enroll now</AscendButton>
    </div>
  </Box>
}
