import React from "react";
import useDoctorsByRequestId from "./hooks/useDoctorsByRequestId";
import useDrugsByRequestId from "./hooks/useDrugsByRequestId";
import {
  Box,
  createStyles,
  Grid,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import AscendButton from "../../../shared/components/AscendButton";
import {DoctorSelection} from "../../../shared/components/DoctorSelection";
import {DrugSelection} from "../../../shared/components/DrugSelection";
import {PharmaciesSelection} from "../../../shared/components/PharmaciesSelection";
import usePharmaciesByRequestId from "./hooks/usePharmaciesByRequestId";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {getQuoteId, setQuoteId} from "../../../shared/QuoteId";
import PlanListSideContent from "./PlanListSideContent";
import Layout from "../../../shared/Layout";
import paths from "../config/router-paths";
import {DrugDeliveryTypeDto, DrugInput, DrugOutput, FormName, PlanYear} from "../../../types";
import {QuoteContext} from "./QuoteContext";
import LoginWidget from "./LoginWidget";
import AscendInfo from "../../../shared/components/AscendInfo";
import BackButton from "../shared/BackButton";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {config} from "../config/config";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";
import {PlanFilterContext} from "./PlanFilterContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 40,
      marginTop: 32,
      maxWidth: 1000,
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    printButton: {
      width: 'auto',
      padding: '14px 73px',
    },
    medium: {
      fontWeight: 500,
    },
  }),
);

const EditPreferences = () => {
  const classes = useStyles();
  const params = useParams<{zip: string, countyName: string}>();
  const history = useHistory();
  const quote = React.useContext(QuoteContext);
  const {authenticated} = React.useContext(KeycloakContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const {doctors, setDoctors} = useDoctorsByRequestId(quote.id);
  const [showDoctorsAutocomplete, setShowDoctorsAutocomplete] = React.useState(false); // todo replace this in component

  const {drugs, setDrugs} = useDrugsByRequestId(quote.id);
  const [showDrugsAutocomplete, setShowDrugsAutocomplete] = React.useState(false); // todo replace this in component

  const [preferredPharmaciesFilled, setPreferredPharmaciesFilled] = React.useState(false); // todo replace this in component

  const {pharmacies, setPharmacies, filled} = usePharmaciesByRequestId(quote.id);
  const [orderType, setOrderType] = React.useState<DrugDeliveryTypeDto>();

  const [isBackButtonClicked, setBackbuttonPress] = React.useState(true)
  const {reset} = React.useContext(PlanFilterContext)

  React.useEffect(() => {

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, [isBackButtonClicked]);
  const onBackButtonEvent = (e: any) => {
    if (isBackButtonClicked) {
      e.preventDefault();
      if (window.confirm("Are you sure? Your changes can be lost")) {
        history.goBack()
      } else {
        setBackbuttonPress(true)
      }
    } else {
      history.goBack();
    }
  }

  const save = () => {
    setBackbuttonPress(false);
    const exec = (clientId: string) => {
      quote.saveClientInfo({
        variables: {
          clientId,
          preferredDrugs: drugs.map(convertPreferredDrugInput),
          preferredPharmacies: orderType === DrugDeliveryTypeDto.Pharmacy ? pharmacies?.map(p => p.npi) : [],
          drugDeliveryType: orderType as unknown as any,
        }}).then(() => {
        return  quote.save({
          variables: {
            data: {
              preferredDoctors: doctors.map(d => ({
                npi: d.npi,
                addresses: d.addresses.map(a => ({addressId: a.id}))
              })),
              planYear: GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023,
              county: params.countyName,
              zip: params.zip,
              id: getQuoteId()
            }
          },
        })
      }).then(() => {
        if (!authenticated && !localStorage.getItem('createAccountShown')) {
          localStorage.setItem('showCreateAccountModal', 'true');
        }
      }).finally(goToPlans)
    }

    if (quote?.clientId) {
      exec(quote?.clientId)
    } else {
      quote.save({
        variables: {
          data: {
            county: params.countyName,
            zip: params.zip,
            id: getQuoteId()
          }
        }
      }).then((res: any) => exec(res.data?.saveMedicareQuote.clientId))
    }
  }

  const goToPlans = () => {
    history.goBack();
  }

  return <Layout pin={quote.pin} sideHeaderContent={config.enableAuth === 'true' ? <LoginWidget/> : <></>} SideComponent={PlanListSideContent}>
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid md={10} xs={12} item>
          <Box display={'flex'} justifyContent={'center'}>
            <Box maxWidth={656} position={'relative'} mb={'200px'}>
              <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
                <BackButton
                  startIcon={<img src={"/img/arrow-left.svg"}/>}
                  onClick={goToPlans}>
                  Back to Plans
                </BackButton>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={'20px'}>
                <Typography className={classes.medium} variant={'h1'} color={'textPrimary'}>You can edit and/or update your doctor, drug and pharmacy preferences below.</Typography>
              </Box>
              <Box position={'sticky'} top={'43px'} bgcolor={'#f5f7f6'} zIndex={3} pb={'10px'}>
                <AscendButton variant={'contained'}
                              className={phone ? 'mb-10 mt-16' : 'mt-16'}
                              onClick={save}>Update and return to plans</AscendButton>
              </Box>
              <Box mt={'14px'}>
                <Title title={'Your doctors'} />
                {!phone && <AscendInfo open={true}
                            description={<><strong>Check if your doctor is in-network.</strong> Copays or coinsurance for your primary care physician and specialist doctor visits can vary depending on whether your doctor is in or out-of-network for any particular plan. </>}/>}
                <div className={'h-16'} />
                <DoctorSelection selectedDoctors={doctors}
                                 zip={params.zip}
                                 setSelectedDoctors={v => {
                                   if ((window as any).track) {
                                     (window as any).track('MA marketplace - add doctor', {source: 'V1', doctors: v}, true);
                                   }
                                   setDoctors(v)
                                 }}
                                 showAutocomplete={showDoctorsAutocomplete}
                                 setShowAutocomplete={setShowDoctorsAutocomplete}/>
              </Box>
              <Box mt={'32px'}>
                <Title title={'Your prescription drugs'} />
                {!phone && <AscendInfo open={true}
                            description={<><strong>We may help you save.</strong> If you add a branded drug, we’ll suggest a generic alternative and give you the option to choose it instead, which may save your money.</>}/>}
                <div className={'h-16'} />
                <DrugSelection selectedPrescriptions={drugs}
                               setSelectedPrescriptions={v => {
                                 if ((window as any).track) {
                                   (window as any).track('MA marketplace - add drug', {source: 'V1', drugs: v}, true);
                                 }
                                 setDrugs(v)
                               }}
                               showAutocomplete={showDrugsAutocomplete}
                               setShowAutocomplete={setShowDrugsAutocomplete}
                />
              </Box>
              <Box mt={'32px'}>
                <Title title={'Your pharmacies'} />
                {!phone && <AscendInfo open={true}
                            description={<><strong>Choosing a pharmacy helps us more accurately estimate drug costs.</strong> We’ll find your drug cost-shares depending on your pharmacy type selection.</>}/>}
                <div className={'h-16'} />
                <PharmaciesSelection zip={params.zip}
                                     selectedPharmacies={pharmacies}
                                     filled={filled}
                                     orderType={quote.drugDeliveryType}
                                     onOrderTypeChange={value => {
                                       setPreferredPharmaciesFilled(true)
                                       setOrderType(value)
                                     }}
                                     setSelectedPharmacies={setPharmacies} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
}

export default EditPreferences;

const Title = withStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: colors.text.primary,
    fontSize: 24,
    fontWeight: 700,
  }
})(({title, classes}: any) => {
  return <div className={classes.container}>
    <Typography className={classes.title}>{title}</Typography>
  </div>
})

export function convertPreferredDrugInput(drugInfo: DrugOutput): DrugInput {

  return {
    dosageRxcui: drugInfo.packRxcui,
    productRxcui: drugInfo.rxcui,
    quantity: drugInfo.quantity,
    frequency: drugInfo.frequency,
    purchaseFrequency: drugInfo.purchaseFrequency,
  }
}
