import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, InputAdornment, Typography} from "@material-ui/core";
import clsx from 'clsx';
import CollapseSection from "../../../shared/components/CollapseSection";
import TooltipedTitle from "../../shared/components/TooltipedTitle";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendDivider from "../../../shared/components/AscendDivider";
import {colors} from "../../../shared/AppTheme";
import {ListItem, PlanFilterDataContext} from "./PlanFilterDataContext";
import {useHistory, useParams} from "react-router";
import {planCategories} from "../Category/Category";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {MedigapPlanBenefit} from "../../../medigap-types";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import paths from "../config/router-paths";
import {PreloaderContext} from "../../shared/PreloaderContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    inputLabel: {
      fontSize: 14
    },
    input: {
      '& input': {
        padding: '18px 0 14px!important'
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    filterTitle: {
      fontSize: 14,
    },
    checkboxLabel: {
      fontSize: 12,
    },
    radio: {
      padding: '3px 0'
    }
  }),
);

export default function PlanFilter() {
  const classes = useStyles();
  let {zip, countyName} = useParams();
  const filter = React.useContext(PlanFilterContext)
  const [companies, setCompanies] = React.useState<ListItem[]>([]);
  const [planNames, setPlanNames] = React.useState<ListItem[]>([]);
  const [monthlyPlanPremium, setMonthlyPlanPremium] = React.useState<ListItem[]>([]);
  const [benefits, setBenefits] = React.useState<ListItem[]>([]);
  const history = useHistory();
  zip = zip || filter.values?.zip;
  countyName = countyName || filter?.values?.county;

  const {getPlanFilterData, planFilterData} = React.useContext(PlanFilterDataContext);

  React.useEffect(() => {
    if (planFilterData) {
      setCompanies(planFilterData.companies);
      setPlanNames(planFilterData.planNames);
      setMonthlyPlanPremium(planFilterData.monthlyPlanPremium);
      setBenefits(planFilterData.benefits);
      window.scrollTo(0, 0)
    }
  }, [planFilterData]);

  React.useEffect(() => {
    if (zip) {
      getPlanFilterData({variables: {zip}})
    }
  }, [zip]);

  if (!filter.values) {
    return null;
  }

  const Category = () => <div>
    <Typography color={'textPrimary'} className={'fs-14 medium'}>Category selected: <b>{filter?.values?.selectedPlanCategory}</b></Typography>
  </div>

  return <Box width={'100%'} style={{background: "#fff"}} className={'plan-filters'}>
    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography className={clsx(classes.title, classes.bold)} color={'textPrimary'}>Filter your results</Typography>
      <Typography variant={'body2'} className={clsx(classes.link)} onClick={filter.reset}>Reset filters</Typography>
    </Box>
    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={monthlyPlanPremium}
                              selectedKeys={filter.values.monthlyPlanPremiumRanges || []}
                              onChange={val => filter.switchMonthlyPremium(val)}
                              tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}
                              title={'Monthly plan premium'}/>
    </Box>
    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={benefits.map(addTooltipToBenefit)}
                              selectedKeys={filter.values.benefits || []}
                              onChange={val => filter.switchBenefit(val)}
                              tooltip={'Some Medigap plans may offer additional extra features, like a reduction of Part B Excess charges, Foreign Travel Emergency coverage, or additional coverage for Skilled Nursing Facilities'}
                              title={'Plan features'}/>
    </Box>
    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={planNames.filter(i => planCategories.find(c => c.name === filter.values?.selectedPlanCategory)?.plans.includes(i.value))}
                              selectedKeys={filter.values.planNames || []}
                              onChange={val => filter.switchPlan(val)}
                              tooltip={'There are 10 standardized Medigap plans. Each plan offers different levels for how much of your costs they’ll pay for, and as such they also have slightly different cost levels. They’re named by letter, like Plan A, and Plan B. Not all plans are offered in every state. If you live in Massachusetts, Minnesota, or Wisconsin, your state offers different standardized plans.'}
                              additionalContent={<div>
                                <Category />
                                <Typography className={'medium fs-12 dark-green pointer underline mv-10'}
                                            onClick={() => history.push(paths.category + zip + '/' + countyName)}>
                                  Not sure what plan category is right for you?<br /> <strong>Click here to find out.</strong>
                                </Typography>
                              </div>}
                              title={'Plan type'}/>
    </Box>
    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={companies}
                              selectedKeys={filter.values.companies || []}
                              onChange={val => filter.switchCompanies(val)}
                              tooltip={'The name of the parent company issuing the plan.'}
                              title={'Company'}/>
    </Box>
    <AscendDivider mt={'16px'}/>

  </Box>
}

function addTooltipToBenefit(item: ListItem): ListItem {
  return {...item, tooltip: getTooltipByBenefit(item.value as MedigapPlanBenefit)};
}

export function getTooltipByBenefit(benefit: MedigapPlanBenefit): string {
  switch (benefit) {
    case MedigapPlanBenefit.ForeignTravelEmergency: return 'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover the care.';
    case MedigapPlanBenefit.SkilledNursingFacilities: return 'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.';
    case MedigapPlanBenefit.PartBExcessCharges: return 'An amount a provider can charge over the Medicare-approved amount. Providers can charge up to 15% over the standard Medicare rates.';
    case MedigapPlanBenefit.PartBDeductible: return 'The amount you must pay out of pocket for your Part B (Medical) costs before your plan coverage begins.';
    case MedigapPlanBenefit.PartADeductible: return 'The amount you must pay out of pocket for your Part A (Hospital) costs before your plan coverage begins.';
  }
}

interface FilterItemWithCheckboxProps {
  items?: ListItem[],
  selectedKeys: string[],
  onChange: (key: any) => void,
  title: string,
  additionalContent?: JSX.Element,
  tooltip?: string
}

function FilterItemWithCheckbox({items, selectedKeys, onChange, title, tooltip, additionalContent}: FilterItemWithCheckboxProps) {
  const classes = useStyles();

  return <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={title} tooltip={tooltip || ''}/>}>

    {additionalContent}

    {!!items && items.map(item => <AscendTooltip key={item.value} placement={'top'} arrow title={item.tooltip || ''}>
      <div>
        <AscendCheckbox variant={'standart'}
                        key={item.value}
                        name={item.value}
                        labelClassName={classes.checkboxLabel}
                        controlClassName={classes.checkbox}
                        label={item.label}
                        checked={!!selectedKeys && selectedKeys.includes(item.value)}
                        disabled={item.disabled && !selectedKeys.includes(item.value)}
                        onChange={() => {
                          onChange(item.value)
                        }}
        />
      </div>
    </AscendTooltip>)
    }
  </CollapseSection>
}
