import React from "react";
import {Box, Typography} from "@material-ui/core";
import {withCard} from "../../shared/components/withCard";
import AscendButton from "../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import {resetQuoteId} from "../../shared/QuoteId";

const useStyles = makeStyles({
  info: {
    borderColor: `rgba(94, 203, 158, 1)`,
    backgroundColor: 'rgba(94, 203, 158, 0.05)',
    border: '1px solid',
    borderRadius: 4,
    padding: '17px 16px 30px',
    fontWeight: 500,
    color: colors.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 303,
  },
  label: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  }
});

function Step16() {
  const classes = useStyles();

  return <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <Box mt={'40px'} className={classes.info}>
      <Box textAlign={'center'}>Please have your red, white and blue Medicare card with you for the consultation (if you have one).</Box>
      <Box mt={'10px'}>
        <img src={'/img/enroll.png'} />
      </Box>
    </Box>
    <Box mt={'40px'}>
      <Typography className={classes.label} variant={'body2'} color={'textSecondary'}>Feel free to browse our free Medicare Guide while we finalize your quotes.</Typography>
    </Box>
    <Box mt={2}>
      <AscendButton onClick={() => {
        resetQuoteId();
        document.location.href = 'https://coverright.com/blog/';
      }} variant={'contained'}>Browse Medicare Guide</AscendButton>
    </Box>
  </Box>
}

export default withCard(
  Step16,
  `Thanks for choosing CoverRight.`,
  `We’re excited to help you enroll in a plan that’s right for you.`,
  true
)
