import React from "react";
import {Plan} from "../../../../types";
import AscendButton from "../../../../shared/components/AscendButton";
import {planStyles} from "../../PlanList/PlanCard";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {ComparisonContext} from "../ComparisonContext";
import {makeStyles} from "@material-ui/core/styles";
import {getCompanyLogo} from "../../../../shared/utils";
import {useScrollPosition} from "../../../../shared/useScrollPosition";
import {colors} from "../../../../shared/AppTheme";
import StyledRating from "../../PlanList/components/StyledRating";
import {config} from "../../config/config";
import {getQuoteId} from "../../../../shared/QuoteId";
import '../styles.scss'
import {QuoteContext} from "../../PlanList/QuoteContext";
import { useParams, useHistory } from "react-router-dom";
import {GetPlansYear} from "../../../shared/hooks/GetPlansYear";


const styles = makeStyles((theme: Theme) =>
  createStyles({
    removeButton: {
      background: 'rgba(164, 180, 185, 0.1)',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 7,
    },
    planName: {
      fontWeight: 500,
      color: colors.custom.green.variant2
    },
    premium: {
      fontWeight: 500,
    },
    logo: {
      height: 35,
      width: 99,
      minWidth: 99,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    ratingIcon: {
      width: 20,
      height: 20,
      marginRight: 4
    }
  }),
);

type CompareHeaderCardProps = {
  plan: Plan
}

export default function CompareHeaderCard({plan}: CompareHeaderCardProps) {
  let {zip, countyName} = useParams();
  const history = useHistory();
  const classes = styles();
  const planClasses = planStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const scrollPosition = useScrollPosition();
  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
      if (scrollPosition > 150 && !collapsed) {
        setCollapsed(true)
      } else {
        if (scrollPosition < 50) {
          setCollapsed(false)
        }
      }
  }, [scrollPosition]);

  return <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'} minHeight={collapsed ? '175px' : '257px'}>
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box width={'99px'} height={'35px'}>
          <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
        </Box>
        <IconButton onClick={() => removeFromCompare(plan.bidId)} className={classes.removeButton}><img src={'/img/icon-close.svg'} /></IconButton>
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'space-between'} mt={'10px'} mb={'15px'}>
        <div>
          <Typography variant={'h3'} className={'medium comparison-header-title'} color={'textPrimary'}>{plan.planName}</Typography>
          {!collapsed && <>
            {!plan.rating && <><div className={'h-10'} /><Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography></>}
            {!!plan.rating && <StyledRating precision={0.5} classes={{icon: classes.ratingIcon}} readOnly defaultValue={plan.rating || 0}/>}
          </>}
        </div>
        <div>
          <Typography variant={'body2'} color={'textPrimary'} className={classes.premium}>{plan.monthlyCost}</Typography>
          <Typography variant={'body2'} color={'textPrimary'} className={classes.premium}>Estimated monthly cost</Typography>
        </div>
      </Box>
    </Box>
    <div>
      <AscendButton className={planClasses.enrollButton}
                    onClick={() => {
                      if (localStorage.getItem('flow') === 'v2') {
                        history.push(`/completed/${zip}/${countyName}/${plan.bidId}`)
                      } else {
                        document.location.href = config.enrollUrl + 'enroll/' + plan.bidId + '/' + zip + '/' + countyName + '/' + (getQuoteId() ? `/${getQuoteId()}` : '') + '?year=' + GetPlansYear()
                      }
                    }}
                    variant={'contained'}>Select this plan</AscendButton>
    </div>
  </Box>
}
