import React from "react";
import {Box, Collapse, Divider, Paper, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import clsx from 'clsx';
import useCommonStyles from "../../shared/useCommonStyles";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {withTitle} from "../../shared/components/withTitle";
import Benefit from "../../shared/components/Benefit";
import AscendDivider from "../../shared/components/AscendDivider";

const useStyles = makeStyles({
  medium: {
    fontWeight: 500,
  },
  panelColumn: {
    borderRight: '1px solid #E8ECED'
  },
  label: {
    fontSize: 10,
    color: colors.text.primary,
    fontWeight: 400,
  },
  image: {
    width: 14,
    height: 14
  },
  green: {
    color: '#029094',
  },
  info: {
    background: '#1E95A0',
    '& *': {
      color: 'white!important'
    }
  },
  paper: {
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    position: 'relative',
  },
  lh1: {
    lineHeight: 1
  },
  plus: {
    position: 'absolute',
    left: -11,
    top: -12,
  },
  phone: {
    color: colors.custom.green.variant1,
    display: 'flex',
    borderRadius: 4,
    fontSize: 20,
    alignItems: 'center',
    fontWeight: 700,
    margin: '0 0 0 8px',
    textDecoration: 'none',
    '& img': {
      marginRight: 5,
      width: 16,
      height: 16,
    }
  },
});

const formName = 'STEP_9b';

function Step9b(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step15)
  };

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'}>
    <Paper elevation={0} style={{border: '1px solid #5ECB93'}}>
      <Box padding={'12px 12px 18px 16px'} className={classes.info}>
        <Typography variant={'h3'} color={'textPrimary'}><strong>Here’s what to do next:</strong></Typography>
        <Box display={'flex'} flex={1} mt={1}>
          <Box display={'flex'} flex={1} alignItems={'flex-start'}>
            <img src={'/img/green2-check.svg'} />
            <div className={'w-8'} />
            <Typography variant={'body2'} color={'textPrimary'} className={'medium'}>Book a call with a licensed CoverRight agent.</Typography>
          </Box>
          <Box display={'flex'} flex={1} alignItems={'flex-start'}>
            <img src={'/img/green2-check.svg'} />
            <div className={'w-8'} />
            <Typography variant={'body2'} color={'textPrimary'} className={'medium'}>Review the options provided to find the right plan for you.</Typography>
          </Box>
          <Box display={'flex'} flex={1} alignItems={'flex-start'}>
            <img src={'/img/green2-check.svg'} />
            <div className={'w-8'} />
            <Typography variant={'body2'} color={'textPrimary'} className={'medium'}>Complete your enrollment directly online.</Typography>
          </Box>
          <BookButton className={'gtm-step-event'} variant={'contained'} onClick={onSubmit}>Book a call</BookButton>
        </Box>
      </Box>
    </Paper>
    <div className={'h-10'} />
    <Typography className={'fs-20 bold text-align-centered'} color={'textPrimary'}>CoverRight can provide quotes from top rated carriers in your area</Typography>
    <div className={'h-8'} />
    <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} gridGap={'1rem'}>
      <img width={99} height={62} src={'/img/logos/aetna-medicare.png'} />
      <img width={99} height={62} src={'/img/logos/humana.png'} />
      <img width={99} height={62} src={'/img/logos/wellcare.png'} />
      <img width={99} height={62} src={'/img/logos/cigna.png'} />
      <img width={99} height={62} src={'/img/logos/unitedhealthcare.png'} />
    </Box>
    <div className={'h-10'} />
    <Paper elevation={0} className={classes.paper}>
      <Box padding={'12px 15px 24px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan G</Typography>
          <PricesButton variant={'contained'}>Get more accurate prices</PricesButton>
        </Box>
        <div className={'h-20'} />
        <Box display={'flex'} flex={1}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} minWidth={'180px'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
            <div className={'h-5'} />
            <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>$XX - XXX</strong></Typography>
            <Typography className={'fs-10'} color={'textPrimary'}>Final premium will depends on<br />your age, sex and health status</Typography>
            <div className={'h-24'} />
            <Typography variant={'h6'} color={'textPrimary'}>Does not include standard<br />Part B premium ($148.50)</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Copays/coinsurance:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> for your cost for approved Part B services</Typography>
            <div className={'h-25'} />
            <Typography variant={'h5'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> (Hospital) Part A deductible</Typography>
            <Typography variant={'h6'} color={'textPrimary'}><strong>$203</strong> (Medical) Part B deductible</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} minWidth={'200px'} alignItems={'flex-start'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
            <div className={'h-8'} />
            <Benefit label={'Skilled nursing facility'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'$0 Part A Deductible'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Part B Deductible'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Covers Part B excess charges'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Foreign travel emergency coverage'} value={true} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    </Paper>

    <div className={'h-16'} />

    <Paper elevation={0} className={classes.paper}>
      <Box padding={'12px 15px 24px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan N</Typography>
          <PricesButton variant={'contained'}>Get more accurate prices</PricesButton>
        </Box>
        <div className={'h-20'} />
        <Box display={'flex'} flex={1}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} minWidth={'180px'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
            <div className={'h-5'} />
            <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>$XX - XXX</strong></Typography>
            <Typography className={'fs-10'} color={'textPrimary'}>Final premium will depends on<br />your age, sex and health status</Typography>
            <div className={'h-24'} />
            <Typography variant={'h6'} color={'textPrimary'}>Does not include standard<br />Part B premium ($148.50)</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Copays/coinsurance:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> for your cost for approved Part B services</Typography>
            <div className={'h-25'} />
            <Typography variant={'h5'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> (Hospital) Part A deductible</Typography>
            <Typography variant={'h6'} color={'textPrimary'}><strong>$203</strong> (Medical) Part B deductible</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} minWidth={'200px'} alignItems={'flex-start'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
            <div className={'h-8'} />
            <Benefit label={'Skilled nursing facility'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'$0 Part A Deductible'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Part B Deductible'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Covers Part B excess charges'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Foreign travel emergency coverage'} value={true} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    </Paper>

    <div className={'h-16'} />

    <Paper elevation={0} className={classes.paper}>
      <Box padding={'12px 15px 24px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Medigap Plan B</Typography>
          <PricesButton variant={'contained'}>Get more accurate prices</PricesButton>
        </Box>
        <div className={'h-20'} />
        <Box display={'flex'} flex={1}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} minWidth={'180px'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
            <div className={'h-5'} />
            <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>$XX - XXX</strong></Typography>
            <Typography className={'fs-10'} color={'textPrimary'}>Final premium will depends on<br />your age, sex and health status</Typography>
            <div className={'h-24'} />
            <Typography variant={'h6'} color={'textPrimary'}>Does not include standard<br />Part B premium ($148.50)</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Copays/coinsurance:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> for your cost for approved Part B services</Typography>
            <div className={'h-25'} />
            <Typography variant={'h5'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
            <div className={'h-8'} />
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0</strong> (Hospital) Part A deductible</Typography>
            <Typography variant={'h6'} color={'textPrimary'}><strong>$203</strong> (Medical) Part B deductible</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} minWidth={'200px'} alignItems={'flex-start'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
            <div className={'h-8'} />
            <Benefit label={'Skilled nursing facility'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'$0 Part A Deductible'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Part B Deductible'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Covers Part B excess charges'} value={false} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Foreign travel emergency coverage'} value={false} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    </Paper>

    <AscendDivider my={'32px'} />

    <Paper elevation={0} className={classes.paper}>
      <img src={'/img/big-plus-green.svg'} className={classes.plus}/>
      <Box padding={'12px 15px 24px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h3'} className={'bold mt-5'} color={'textPrimary'}>Prescription Drug Plan (PDP)</Typography>
          <PricesButton variant={'contained'}>Find a PDP plan now</PricesButton>
        </Box>
        <div className={'h-20'} />
        <Box display={'flex'} flex={1}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mr={3} className={classes.panelColumn} width={'180px'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Monthly premium:</strong></Typography>
            <div className={'h-5'} />
            <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.green, 'fs-20')}><strong>$7 - $89</strong></Typography>
            <Typography className={'fs-10'} color={'textPrimary'}>Final premium will depend on your selected plan and service area.</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'flex-start'} mr={3} className={classes.panelColumn}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Copays:</strong></Typography>
            <Typography variant={'h6'} color={'textPrimary'}><strong>$0-$10</strong> for Preferred Generic and Generic drugs (Tier 1 and Tier 2)</Typography>
            <Typography variant={'h6'} color={'textPrimary'}><strong>$24-$47</strong> for Preferred Branded drugs (Tier 3)</Typography>
            <div className={'h-8'} />
            <Typography variant={'h5'} color={'textPrimary'}><strong>Deductibles:</strong></Typography>
            <Typography variant={'h6'} color={'textPrimary'}>Average <strong>$345</strong> (many plans apply no deductible for generic drugs). Maximum deductible is $445</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} width={'200px'} alignItems={'flex-start'}>
            <Typography variant={'h5'} color={'textPrimary'}><strong>Plan benefits:</strong></Typography>
            <div className={'h-8'} />
            <Benefit label={'Coverage for prescription drugs not covered under Original Medicare'} value={true} labelClass={classes.label} imageClass={classes.image} />
            <div className={'h-8'} />
            <Benefit label={'Some plans include Insulin savings ($35 max copay)'} value={true} labelClass={classes.label} imageClass={classes.image} />
          </Box>
        </Box>
      </Box>
    </Paper>

    <div className={'h-32'} />

    <Typography className={'fs-20 bold lh-26'} color={'textPrimary'}>Book a call with one of our licensed agents and we’ll walk you through the following steps to make sure you’re ready for enrollment:</Typography>

    <div className={'h-24'} />

    <Box display={'flex'}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'}>
          <Typography className={clsx(classes.green, classes.lh1, 'fs-40', 'bold')} color={'textPrimary'}>1</Typography>
          <div className={'w-30'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flex={1}>
              <Typography variant={'body2'} color={'textPrimary'}><strong>Confirm your eligibility</strong><br/>
                You may be eligible to enroll in a Medigap plan now without a medical history check.  Our licensed agents will confirm based on your situation.</Typography>
            </Box>
          </Box>
        </Box>

        <div className={'h-15'} />

        <Box display={'flex'}>
          <Typography className={clsx(classes.green, classes.lh1, 'fs-40', 'bold')} color={'textPrimary'}>2</Typography>
          <div className={'w-24'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flex={1}>
              <Typography variant={'body2'} color={'textPrimary'}><strong>Understand your options</strong><br/>
                There are 8 different standardized plan types (A, B, D, G, K, L, M, and N) available to newly eligible for Medicare beneficiaries.  Our agents will walk you through how to select one.</Typography>
            </Box>
          </Box>
        </Box>

        <div className={'h-15'} />

        <Box display={'flex'}>
          <Typography className={clsx(classes.green, classes.lh1, 'fs-40', 'bold')} color={'textPrimary'}>3</Typography>
          <div className={'w-24'} />
          <Box display={'flex'} flex={1} alignItems={'center'}>
            <Box display={'flex'} flex={1}>
              <Typography variant={'body2'} color={'textPrimary'}><strong>Get your quotes and sign up</strong><br/>
                Once you’ve selected a plan type, we’ll help you shop quotes.  If you require a Prescription Drug Plan we can help with that too!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} minWidth={'180px'}>
        <img src={'/img/support.svg'} />
        <div className={'h-16'} />
        <BookButton className={'gtm-step-event'} variant={'contained'} onClick={onSubmit}>Book a call</BookButton>
        <div className={'h-15'} />
        <Typography variant={'body2'} color={'textPrimary'} className={'bold'}>or call us today at:</Typography>
        <a href="tel:8889697667" className={classes.phone}>
          <img src={'/img/phone.svg'} width={12} />
          +1 (888) 969-7667
        </a>
      </Box>
    </Box>

    <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'200px'} width={'100%'}>
      <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={onSubmit}>Book a call with a licensed agent</AscendButton>
    </Box>
  </Box>
}

const BookButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '4px 22px',
    background: 'rgba(255, 120, 90, 1)',
    height: 38,
    '&:hover': {
      backgroundColor: '#FFB8A8',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FFB8A8',
      boxShadow: 'none',
    },
  },
  label: {
    color: 'white',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

const PricesButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 22px',
    background: '#0A2E86',
    maxHeight: 38,
    minWidth: 162,
    '&:hover': {
      backgroundColor: '#0A2E86',
      boxShadow: 'none',
      opacity: 0.5,
    },
    '&:active': {
      backgroundColor: '#0A2E86',
      boxShadow: 'none',
      opacity: 0.5,
    },
  },
  label: {
    color: 'white',
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)


export default withTitle(
  Step9b,
  `Here are your Medicare Supplement plan options.`,
  `Call us now, or book a call with a licensed CoverRight agent to enroll. Our agents can also help match you with a Part D prescription drug plan.`,
  undefined,
  undefined,
  true,
  700
)
