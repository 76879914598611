import React from "react";
import PlanFilter from "./PlanFilter";
import HealthcarePreferences from "./HealthcarePreferences";
import MonthlyCostCalculator from "./MonthlyCostCalculator";
import AscendDivider from "../../../shared/components/AscendDivider";
import Terms from "../../../shared/SidePanel/Terms";

const PlanListSideContent = () => {
  return <>
    <HealthcarePreferences />
    <AscendDivider mb={'16px'} />
    <MonthlyCostCalculator />
    <AscendDivider mt={'16px'} />
    <PlanFilter />
    <Terms />
    {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
  </>
}

export default PlanListSideContent;
