import React from "react";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../Private/hooks/useUserProfile";
import {UserProfileOutput} from "../../enrollment-types";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";

export const ProfileContext = React.createContext<UserProfileOutput | undefined>(undefined);

export const ProfileContextProvider = (props: any) => {

  const [profile, setProfile] = React.useState<UserProfileOutput>();

  const {authenticated} = React.useContext(KeycloakContext);
  const [getProfile, {data}] = useUserProfileLazy();

  React.useEffect(() => {
      if (data?.userProfile) {
        setProfile(data.userProfile);
        setStoredUser({
          ...getStoredUser(),
          firstName: data.userProfile.firstName || undefined,
          lastName: data.userProfile.lastName || undefined,
          birthDate: data.userProfile.birthDate || undefined,
        })
      }
  }, [data]);

  React.useEffect(() => {
    if (authenticated) {
      getProfile()
    }
  }, [authenticated])

  return (
    <ProfileContext.Provider value={profile}>
      {props.children}
    </ProfileContext.Provider>
  );
};
