import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";
import {ScopeOfAppointmentUserInput} from "../../enrollment-types";

export default function useConfirmScopeOfAppointment() {
  return useMutation<{data: any}, {userInput: ScopeOfAppointmentUserInput}>(
    gql(saveScopeOfAppointmentMutation),
    {
      client: client
    }
  );
}

const saveScopeOfAppointmentMutation = `
mutation($userInput: ScopeOfAppointmentUserInput!) {
  confirmScopeOfAppointment(userInput: $userInput)
}
`;
