import {withStyles} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {Scrollbar} from "react-scrollbars-custom";
import React, {useRef} from "react";
import clsx from 'clsx';


const AscendAutocomplete = withStyles({
  inputRoot: {
    padding: '0!important',
  },
  popupIndicator: {
    marginRight: 11
  },
  popper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    marginTop: 10,
  },
  paper: {
    boxShadow: 'none',
    borderRadius: 2,
    margin: 0
  },
  listbox: {

  },
  endAdornment: {
    display: 'none',
  },
  option: {
    padding: '18px 12px 18px 18px',
  }
})(Autocomplete)

export default AscendAutocomplete;



const useListStyles = makeStyles({
  scrollbar: {
    height: (props: ScrollbarProps) => typeof props.height === 'undefined' ?
      '250px!important' :
      typeof props.height === 'number' ? props.height + 'px!important' : props.height + '!important',
    '& .ScrollbarsCustom-Wrapper': {
      right: '8px!important'
    },
    '& .ScrollbarsCustom-Track': {
      top: '0px!important',
      height: '100%!important',
      width: '4px!important',
      background: 'rgba(196,196,196,.2)!important',
    },
    '& .ScrollbarsCustom-TrackX': {
      display: 'none!important'
    },
    '& .ScrollbarsCustom-Thumb': {
      background: colors.custom.green.variant1 + '!important',
    },
    '& .ScrollbarsCustom-Content li:hover': {
      background: '#F5F7F6!important',
    }
  }
});

type ScrollbarProps = {
  scrollTo?: number,
  children: any,
  className?: string,
  height?: number | string,
}

export const ScrollbarList = (props: ScrollbarProps) => {
  const classes = useListStyles(props);
  const ref = useRef<any>(null);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(undefined, props.scrollTo);
    }
  }, [ref, props.scrollTo])

  return <Scrollbar ref={ref} className={clsx(classes.scrollbar, props.className)}>
    {props.children}
  </Scrollbar>
}
