import React from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {planStyles} from "../PlanCard";
import {getCompanyLogo, getMedigapCompanyLogo} from "../../../../shared/utils";
import clsx from 'clsx';
import AscendTooltip from "../../../../shared/components/AscendTooltip";
import {MedigapPlanOutput} from "../../../../medigap-types";
import {MedigapPlanOutputWithDetails} from "../../DetailedPlan/hooks/usePlansByBid";

interface PlanTitleProps {
  plan: MedigapPlanOutputWithDetails,
  showBid?: boolean,
  className?: string,
  inFavorites?: boolean,
  hideFavorites?: boolean,
  favoritesClick?: () => void,
}

export function PlanTitle({plan, className, showBid, inFavorites, favoritesClick, hideFavorites}: PlanTitleProps) {
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <div className={classes.bigLogo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title;
      return <Typography variant={'h2'} className={classes.bold} color={'textPrimary'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);

  const Desktop = () => <Box flex={1} className={clsx('flex', className)}>
    <div className={'flex-column'}>
      <div className={'flex-space-between'}>
        {Logo}
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart-fill.svg'}/>
          </>}
        </div>}
      </div>
      <div className={'flex-space-between flex-align-center'}>
        <div>
          <Typography className={'fs-12 bold mb-4'} color={'textPrimary'}>{plan.subTitle}</Typography>
          <Typography className={'fs-10 mb-4'} color={'textPrimary'}>{plan.ratingClass}</Typography>
          <Typography className={'fs-10'} color={'textPrimary'}>{plan.note}</Typography>
        </div>
        <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
      </div>
    </div>
  </Box>

  const Mobile = () => <div className={'flex-column'}>
    <div className={'flex-space-between'}>
      {Logo}
      {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
        {!inFavorites && <>
          <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
          <div className={'w-5'} />
          <img width={20} height={20} src={'/img/heart.svg'}/>
        </>}
        {inFavorites && <>
          <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
          <div className={'w-5'} />
          <img width={20} height={20} src={'/img/heart-fill.svg'}/>
        </>}
      </div>}
    </div>
    <div className={'mt-8'}>
      <Typography className={'fs-12 bold mb-4'} color={'textPrimary'}>{plan.subTitle}</Typography>
      <Typography className={'fs-10'} color={'textPrimary'}>{plan.note}</Typography>
    </div>
  </div>

  return <div className={clsx('flex', className)}>
    {phone ? <Mobile /> : <Desktop/>}
  </div>
}
