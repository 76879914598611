import React from "react";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {Badge, Box, List, ListItem, ListItemText, Popover, Typography, useMediaQuery} from "@material-ui/core";
import clsx from "clsx";
import {Field} from "../../../../types";
import paths from "../../config/router-paths";
import {colors} from "../../../../shared/AppTheme";
import {FavoritesContext} from "../../shared/FavoritesContext";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sort: {
      padding: '4px 14px 2px',
      fontSize: 12,
      color: colors.text.primary,
      cursor: 'pointer',
      borderRadius: 4,
      fontWeight: 700
    },
    sortActive: {
      color: colors.custom.green.variant1,
    },
  }),
);

interface PlanListSortProps {
  onChange: (value: Field) => void,
  value: Field,
  zip?: string
  county?: string
}

const PlanListSort = (props: PlanListSortProps) => {
  const classes = useStyles();
  const history = useHistory();
  const {favorites} = React.useContext(FavoritesContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (value: Field) => {
    props.onChange(value);
    handleClose();
  }

  const open = Boolean(anchorEl);

  return <>
    {!phone && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'-16px'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'h5'} color={'textSecondary'} className={'bold'}>Sort by:</Typography>
        <Box ml={'13px'}>
          <div className={clsx({[classes.sort]: true, [classes.sortActive]: props.value === Field.MonthlyCost})}
               onClick={() => props.onChange(Field.MonthlyCost)}>Estimated lowest cost
          </div>
        </Box>
        <Box ml={'10px'}>
          <div className={clsx({[classes.sort]: true, [classes.sortActive]: props.value === Field.Rating})}
               onClick={() => props.onChange(Field.Rating)}>CMS star rating
          </div>
        </Box>
      </Box>
      <div className={'flex flex-align-center pointer'} onClick={() => history.push(paths.favorites + props.zip + '/' + props.county)}>
        <Typography className={'fs-12 medium'} color={'textPrimary'}>See your favorites</Typography>
        <div className={'w-5'} />
        <Badge color="primary" overlap="circle" badgeContent={favorites.length} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
          <img width={30} height={30} src={'/img/heart.svg'}/>
        </Badge>
      </div>
    </Box>}
    {phone && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'-16px'}>
      <Typography color={'textPrimary'} className={'medium fs-14'}>Sort by:</Typography>
      <Typography className={'medium fs-14 green pointer'} onClick={handleClick}>
        {props.value === Field.MonthlyCost ? 'Lowest Cost' : 'CMS star rating'}<img className={'ml-5 mt-5'} src={'/img/arrow_green.svg'}/>
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem button onClick={() => onChange(Field.MonthlyCost)}>
            <ListItemText primary="Lowest Cost" />
          </ListItem>
          <ListItem button onClick={() => onChange(Field.Rating)}>
            <ListItemText primary="CMS star rating" />
          </ListItem>
        </List>
      </Popover>
    </Box>}
  </>;
}

export default PlanListSort;
