import React from "react";
import {Box, Typography,} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendTitle from "../../shared/components/AscendTitle";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {DrugDeliveryTypeDto, PharmacyLocationDistanceOutput} from "../../types";
import {PharmaciesSelection} from "../../shared/components/PharmaciesSelection";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {questionsRoutes} from "../hooks/useStepperState";
import {withTitle} from "../../shared/components/withTitle";
import AscendInfo from "../../shared/components/AscendInfo";

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  linkGray: {
    color: colors.text.secondary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 22,
    marginBottom: 20
  },
  addPharmacyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px dashed #D6DFDA',
    background: '#F5F7F6',
    borderRadius: 8,
    width: '100%',
    paddingTop: 27,
    paddingBottom: 23,
    color: colors.custom.green.variant1,
    fontWeight: 700,
    fontSize: 16,
  }
});

const formName = 'PHARMACIES';

function Step12(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();
  const [selectedPharmacies, setSelectedPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>();
  const [orderType, setOrderType] = React.useState<DrugDeliveryTypeDto | undefined>();

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {preferredPharmacies: selectedPharmacies?.map(p => p.npi),
        form: formName,
        preferredPharmaciesFilled: true,
        stateKey: 'preferredPharmacies',}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step13)
  };

  const filled = state.preferredPharmacies?.preferredPharmaciesFilled;

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'40px'}>
      <AscendTitle title={'Where do you go to fill your prescriptions?'} />
      <div className={'h-8'} />
      <PharmaciesSelection zip={state.personalDetails?.zip}
                           selectedPharmacies={selectedPharmacies}
                           filled={filled}
                           onOrderTypeChange={setOrderType}
                           setSelectedPharmacies={setSelectedPharmacies} />
    </Box>
    <AscendInfo open={true}
                description={<>
                  <p style={{marginTop: 0, marginBottom: 0}}><strong>Choosing a pharmacy helps us more accurately estimate drug costs.</strong> We’ll find your drug cost-shares depending on your pharmacy type selection.</p></>}/>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    disabled={typeof orderType === 'undefined' || (orderType === DrugDeliveryTypeDto.Pharmacy && !selectedPharmacies?.length)}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'30px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        props.history.push(paths.questionnaire  + '/' + questionsRoutes.step13)
      }}>Click here to skip this step</Typography>
    </Box>
  </>
}

export default withTitle(
  Step12,
  'Where do you normally fill your prescriptions?',
  'Selecting a pharmacy helps us more accurately estimate your total drug costs and find the lowest cost plan for you.'
)
