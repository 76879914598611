import React from "react";
import {
  Box, Collapse,
  createStyles,
  Grid,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import FormTextInput, {TickAdornment} from "../shared/components/FormTextInput";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import {Gender, MedigapEnrollmentAccessibleFormat, MedigapEnrollmentPreferredLanguage} from "../enrollment-types";
import * as _ from 'lodash';
import {GraphQLErrorType} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import BackButton from "../Maketplace/MedicareAdvantage/shared/BackButton";
import MedigapPlanCard from "../Maketplace/Medigap/PlanList/PlanCardOld";
import {addDetailsToPlan} from "../Maketplace/Medigap/DetailedPlan/hooks/usePlansByBid";
import {useMedigapPlanLazy} from "../Maketplace/Medigap/DetailedPlan/hooks/usePlan";
import useSaveMedigapEnrollment from "./hooks/useSaveMedigapEnrollment";
import {keycloak} from "../shared/keycloak";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AscendCheckbox from "../shared/components/AscendCheckbox";
import moment from "moment";
import useSaveRequest from "../Questionnaire/hooks/useSaveRequest";
import useSaveMedigapQuote from "../Quote/Medigap/hooks/useSaveMedigapQuote";
import {getMedigapQuoteId} from "../shared/QuoteId";
import { getCId } from "../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    checkbox: {
      marginLeft: -15,
      alignItems:'flex-start'
    },
    checkboxLeft: {
      marginLeft: -15,
    },
    radioLeft: {
      marginLeft: -8,
    }
  })
);
const formName = 'EnrollmentForm';

const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  city: yup.string(),
  aptNumber: yup.string(),
  street: yup.string(),
  mailStreet: yup.string(),
  mailCity: yup.string(),
  mailZip: yup.string().matches(/^(\s*|[0-9]{5})$/, 'Please enter a valid zip code'),
  mailAptNumber: yup.string(),
  phoneNumber: yup.string().required("Enter a valid phone number"),
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
  email: yup.string().required("Enter a valid email address").email('Enter a valid email address'),
  medicareNumber: yup.string().required("Please enter a valid Medicare number"),
  medicareNumberConfirm: yup.string().required("Medicare number must match"),
});

interface MedigapEnrollmentFormState {
  firstName: string;
  lastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  phoneNumber: string;
  medicareNumber: string;
  medicareNumberConfirm: string;
  mailZip: string;
  mailEmail: string;
  mailAptNumber: string;
  mailCity: string;
  mailStreet: string;
}

type MedigapEnrollmentFormProps = RouteComponentProps<{
  planId: string,
  zip: string,
  quoteId: string,
  countyName: string,
}> & {
  age?: string,
  effectiveDate?: string,
  tobacco?: string,
  gender?: string,
}

function MedigapEnrollmentForm({age, effectiveDate, tobacco, gender, ...props}: MedigapEnrollmentFormProps) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, setState] = React.useState<string>('');
  const history = useHistory();
  const [saveMedigap] = useSaveMedigapEnrollment();
  const [getCounty, countyData] = useGetCounty();
  const [county, setCounty] = React.useState<string | undefined>('');
  const [medicarePartAStartedAt, setMedicarePartAStartedAt] = React.useState<Date | null>(null);
  const [medicarePartBStartedAt, setMedicarePartBStartedAt] = React.useState<Date | null>(null);
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated, isAdmin} = React.useContext(KeycloakContext);
  const [isElectronicallyCommunications, setIsElectronicallyCommunications] = React.useState(false);
  const [preferredLanguage, setPreferredLanguage] = React.useState<MedigapEnrollmentPreferredLanguage>(MedigapEnrollmentPreferredLanguage.English);
  const [communicationPreference, setCommunicationPreference] = React.useState<MedigapEnrollmentAccessibleFormat>();
  const theme = useTheme();
  const [getMailCounty, mailCountyData] = useGetCounty();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [getMedigapPlan, {data: medigapData}] = useMedigapPlanLazy();
  const [saveError, setSaveError] = React.useState();
  const [mailCounty, setMailCounty] = React.useState<string | undefined>('');
  const [mailingDifferent, setMailingDifferent] = React.useState(false);
  const [mailState, setMailState] = React.useState<string>('');
  const [sex, setSex] = React.useState('');
  const [birthDate, setBirthDate] = React.useState(getStoredUser()?.birthDate ? moment(getStoredUser()?.birthDate).toDate() : null);
  const [saveQuote] = useSaveMedigapQuote();

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<MedigapEnrollmentFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  React.useEffect(() => {
    if (zip) {
      getCounty({variables: {zip}})
    }
  }, [zip])

  React.useEffect(() => {
    if (getValues().mailZip) {
      getMailCounty({variables: {zip: getValues().mailZip}})
    }
  }, [getValues().mailZip])

  React.useEffect(() => {
      if (getValues().medicareNumber.length && getValues().medicareNumber !== getValues().medicareNumberConfirm) {
        setError("medicareNumberConfirm", {message: 'Medicare number must match'})
      } else {
        clearErrors("medicareNumberConfirm");
      }
  }, [getValues().medicareNumber, getValues().medicareNumberConfirm]);

  React.useEffect(() => {
    if (countyData.data?.plans_zip_county_fips && countyData.data?.plans_zip_county_fips.length === 1) {
      setCounty(countyData.data?.plans_zip_county_fips[0].countyname)
    }
  }, [countyData.data]);

  const mailStates = React.useMemo(() => {
    return _.uniq(mailCountyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [mailCountyData.data?.plans_zip_county_fips])

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])

  const permanentStates = React.useMemo(() => {
    return _.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [countyData.data?.plans_zip_county_fips])

  React.useEffect(() => {
    if (permanentStates?.length === 1) {
      setState(permanentStates[0]);
    }
  }, [permanentStates])

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getMedigapPlan({
        variables: {
          key: planId
        }
      })
    }
  }, [planId, zip, countyName])

  const values = React.useMemo(() => {
    return {
      medigapQuoteId: authenticated && !isAdmin ? userInfoData?.data?.userProfile.medigapQuoteId : quoteId,
      bidId: undefined,
      email: getValues().email,
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      permanentAddress: {
        aptNumber: getValues().aptNumber,
        city: getValues().city,
        state: state || '',
        streetName: getValues().street,
        zip: getValues().zip,
      },
      phoneNumber: getValues().phoneNumber,
      birthDate: moment(birthDate).format('YYYY-MM-DD'),
    }
  }, [getValues(), state, isAdmin, authenticated, birthDate])

  const saveErrorHandler = (error: any) => {
    let text;
    switch (error.graphQLErrors[0]?.extensions?.type) {
      case "INVALID_EMAIL":
      case GraphQLErrorType.ALREADY_EXISTS:
        text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
          keycloak.login({redirectUri: document.location.href})
        }} style={{color: "red"}}>sign in</a></>
        break;
      case GraphQLErrorType.NO_CONNECTION:
        text = "Service is not available";
        break;
      default:
        text = "Unknown error"
    }
    setSaveError(text)
  }

  const onSubmit = () => {
    if (formState.isValid && state) {
      if (age && medigapPlan?.planName && medigapPlan?.naic && effectiveDate) {
        if (!values.medigapQuoteId) {
          saveQuote({variables: {
              input: {
                cId: getCId(),
                id: getMedigapQuoteId(),
                medigapFilterState : {
                  county: countyName,
                  zip: zip,
                  age: parseFloat(age),
                  gender: gender as Gender,
                  tobacco: tobacco === 'true',
                },
              }
            }}).then(res => saveMedigap({variables: {
              input: {
                ...values,
                permanentAddress: {
                  ...values.permanentAddress,
                  county: countyName
                },
                mailingAddress: mailingDifferent ? {
                  aptNumber: getValues().mailAptNumber,
                  city: getValues().mailCity,
                  county: mailCounty as string,
                  state: mailState,
                  zip: getValues().mailZip,
                  streetName: getValues().mailStreet
                } : undefined,
                medicareNumber: getValues().medicareNumber,
                accessibleFormatPreference: communicationPreference,
                preferredLanguage,
                medicalDate: moment(medicarePartBStartedAt).format('YYYY-MM-DD'),
                hospitalDate: moment(medicarePartAStartedAt).format('YYYY-MM-DD'),
                naic: medigapPlan.naic,
                planName: medigapPlan.planName as any,
                medigapQuoteId: res.data?.saveMedigapQuote,
                age: parseFloat(age),
                effectiveDate,
                tobacco: tobacco === 'true',
                gender: gender as Gender,
                cId: getCId(),
              }
            }}))
            .then(data => {
              setStoredUser({
                ...getStoredUser(),
                enrollmentId: data?.data?.saveMedigapEnrollment,
              })
              props.history.push(paths.receive + document.location.search)
            }).catch(saveErrorHandler);
        } else {
          saveMedigap({variables: {
              input: {
                ...values,
                permanentAddress: {
                  ...values.permanentAddress,
                  county: countyName
                },
                mailingAddress: mailingDifferent ? {
                  aptNumber: getValues().mailAptNumber,
                  city: getValues().mailCity,
                  county: mailCounty as string,
                  state: mailState,
                  zip: getValues().mailZip,
                  streetName: getValues().mailStreet
                } : undefined,
                medicareNumber: getValues().medicareNumber,
                accessibleFormatPreference: communicationPreference,
                preferredLanguage,
                medigapQuoteId: values.medigapQuoteId,
                medicalDate: moment(medicarePartBStartedAt).format('YYYY-MM-DD'),
                hospitalDate: moment(medicarePartAStartedAt).format('YYYY-MM-DD'),
                naic: medigapPlan.naic,
                planName: medigapPlan.planName as any,
                age: parseFloat(age),
                effectiveDate,
                tobacco: tobacco === 'true',
                gender: gender as Gender,
                cId: getCId(),
              }
            }}).then(data => {
            setStoredUser({
              ...getStoredUser(),
              enrollmentId: data?.data?.saveMedigapEnrollment,
            })
            props.history.push(paths.receive + document.location.search)
          }).catch(saveErrorHandler);
        }
      }
    }
  };
  const mailCounties = React.useMemo(() => {
    return mailCountyData.data?.plans_zip_county_fips || [];
  }, [mailCountyData.data, mailCountyData.called])

  const medigapPlan = React.useMemo(() => {
    return addDetailsToPlan(medigapData?.medigapPlan);
  }, [medigapData])

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setState(userInfoData.data.userProfile.state || '');
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  const disabled = React.useMemo(() => {
      return !formState.isValid || !moment(medicarePartAStartedAt).isValid() || !moment(medicarePartBStartedAt).isValid() || !sex
  }, [formState.isValid, medicarePartAStartedAt, medicarePartBStartedAt, sex]);

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800} position={'relative'}>
    <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
      <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={() => history.goBack()}>
        Back
      </BackButton>
    </Box>
    {!phone && <>
      {medigapPlan && <MedigapPlanCard countyName={countyName}
                                                      zip={zip}
                                                      hideButtons
                                                      plan={medigapPlan}/>}
    </>}
    <div className={'h-40'} />
    <Typography color={'textPrimary'} className={'bold fs-26'}>Personal details</Typography>
    <div>
      <Typography color={'textPrimary'} className={'medium fs-18 mb-5'}>Please provide your personal details below to begin your enrollment.</Typography>
      <Box display={'flex'}>
        <img src={'/img/padlock.svg'} className={'mr-5'} />
        <Typography className={'fs-14 bold'} color={'textPrimary'}>Your information is stored securely.</Typography>
      </Box>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Personal information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           defaultValue={userInfo?.firstName}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           placeholder={'First name'}
                           label={'First name*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           defaultValue={userInfo?.lastName}
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Last name'}
                           label={'Last name*'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'Sex*'}
              defaultValue={gender as Gender}
              value={sex}
              placeholder={'Select sex'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSex(event.target.value as string);
              }}
            >
              <MenuItem value={'M'}>Male</MenuItem>
              <MenuItem value={'F'}>Female</MenuItem>
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color={'textPrimary'} className={'fs-16 mb-5 medium'}>Date of birth</Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                autoFocus={true}
                margin="none"
                variant="inline"
                format="MM/dd/yyyy"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSubmit();
                  }
                }}
                InputAdornmentProps={{
                  classes: {
                    root: moment(birthDate).isValid() && moment(birthDate).year() >= 1900 ? undefined : commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                keyboardIcon={<TickAdornment className={'mr-15'}/>}
                placeholder={'MM / DD / YYYY'}
                value={birthDate}
                invalidDateMessage={'Please enter a valid date of birth'}
                maxDateMessage={'Please enter a valid date of birth'}
                minDateMessage={'Please enter a valid date of birth'}
                onChange={setBirthDate}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <Typography className={'bold fs-24'} color={'textPrimary'}>Permanent address <span className={'fs-16 thin'}>(Optional)</span></Typography>
            <Typography className={'fs-16 mb-15'} color={'textPrimary'}>Please enter your permanent address (this is usually where you’re registered vote).</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'street'}
                           defaultValue={userInfoData.data?.userProfile?.streetName}
                           error={formState.isSubmitted && !!errors.street}
                           helperText={formState.isSubmitted && errors?.street?.message}
                           inputRef={register}
                           placeholder={'Enter street address'}
                           label={'Street Address (P.O. box not allowed)'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'aptNumber'}
                           defaultValue={userInfoData.data?.userProfile?.aptNumber}
                           error={formState.isSubmitted && !!errors.aptNumber}
                           helperText={formState.isSubmitted && errors?.aptNumber?.message}
                           inputRef={register}
                           placeholder={'Enter apt. / unit'}
                           label={'Apt. / Unit'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'city'}
                           defaultValue={userInfoData.data?.userProfile?.city}
                           error={formState.isSubmitted && !!errors.city}
                           helperText={formState.isSubmitted && errors?.city?.message}
                           inputRef={register}
                           placeholder={'Enter city'}
                           label={'City'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'State'}
              value={state}
              placeholder={'Select state'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setState(event.target.value as string);
              }}
            >
              {permanentStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'County'}
              value={county}
              disabled={!counties.length}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
              }}
            >
              {
                counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
              }
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           fullWidth
                           inputRef={register}
                           label={'Zip'}
                           name={'zip'}
                           value={zip}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
          </Grid>

          <Grid item xs={12}>
            <Box ml={'-14px'} mt={'-25px'}>
              <AscendCheckbox variant={'standart'}
                              label={'My mailing address is different to my permanent address.'}
                              checked={mailingDifferent}
                              onChange={() => setMailingDifferent(!mailingDifferent)}

              />
            </Box>
            <Collapse in={mailingDifferent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AscendDivider className={classes.divider} mb={'23px'} />
                  <Typography className={'bold fs-24 mb-5'} color={'textPrimary'}>Mailing address</Typography>
                  <Typography className={'fs-16 mb-15'} color={'textPrimary'}>Add your mailing address or P.O. box here, if applicable</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailStreet'}
                                 error={formState.isSubmitted && !!errors.mailStreet}
                                 helperText={formState.isSubmitted && errors?.mailStreet?.message}
                                 inputRef={register}
                                 placeholder={'Enter street address'}
                                 label={'Street Address (or P.O. box)'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailAptNumber'}
                                 error={formState.isSubmitted && !!errors.mailAptNumber}
                                 helperText={formState.isSubmitted && errors?.mailAptNumber?.message}
                                 inputRef={register}
                                 placeholder={'Enter apt / unit'}
                                 label={'Apt. / Unit'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailCity'}
                                 error={formState.isSubmitted && !!errors.mailCity}
                                 helperText={formState.isSubmitted && errors?.mailCity?.message}
                                 inputRef={register}
                                 placeholder={'Enter city'}
                                 label={'City'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'State'}
                    value={mailState}
                    placeholder={'Select state'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setMailState(event.target.value as string);
                    }}
                  >
                    {mailStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'County'}
                    value={mailCounty}
                    disabled={!counties.length}
                    placeholder={'Select county'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setMailCounty(event.target.value as string);
                    }}
                  >
                    {
                      mailCounties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
                    }
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput error={formState.isSubmitted && !!errors.mailZip}
                                 helperText={formState.isSubmitted && errors?.mailZip?.message}
                                 fullWidth
                                 inputRef={register}
                                 label={'Zip'}
                                 name={'mailZip'}
                                 inputProps={{
                                   maxLength: 5
                                 }}
                                 type={'tel'}
                                 placeholder={'Enter zip'} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'23px'} />
            <Typography className={'bold fs-24 mb-5'} color={'textPrimary'}>Contact information</Typography>
            <Typography className={'fs-12'} color={'textPrimary'}>It is important that we can reach you to help you stay informed and take care of your health. Please provide your telephone number and email address.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           label={'Email address*'}
                           name={'email'}
                           defaultValue={userInfo?.email}
                           inputProps={{
                             autoCapitalize: 'none'
                           }}
                           placeholder={'Enter your email address'}
                           error={(!!errors.email || saveError) && formState.isSubmitted}
                           helperText={formState.isSubmitted && (errors?.email?.message || saveError)}
                           inputRef={register} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ onChange }) => <PhoneNumberInput label={'Phone number*'}
                                                          defaultValue={userInfo?.phoneNumber}
                                                          isValid={() => formState.isSubmitted ? !errors?.phoneNumber?.message : true}
                                                          placeholder={'+1 (123) 456-7890'} name={'phoneNumber'} onChange={onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Box ml={'-14px'} mt={'-25px'}>
              <AscendCheckbox variant={'standart'}
                              label={'Go digital! I would like to receive all plan communications electronically.'}
                              checked={isElectronicallyCommunications}
                              onChange={() => setIsElectronicallyCommunications(!isElectronicallyCommunications)}

              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box mt={'20px'}>
              <FormSelectInput
                fullWidth
                label={'Preferred language'}
                error={formState.isSubmitted && !preferredLanguage}
                value={preferredLanguage}
                placeholder={'English'}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setPreferredLanguage(event.target.value as MedigapEnrollmentPreferredLanguage);
                }}
              >
                <MenuItem value={MedigapEnrollmentPreferredLanguage.English}>English</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.Spanish}>Spanish</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.Chinese}>Chinese</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.French}>French</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.FrenchCreole}>French Creole</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.Korean}>Korean</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.Polish}>Polish</MenuItem>
                <MenuItem value={MedigapEnrollmentPreferredLanguage.Vietnamese}>Vietnamese</MenuItem>
              </FormSelectInput>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'Accessible communication preference (optional)'}
              value={communicationPreference}
              placeholder={'Select'}
              helperText={'Please call a licensed CoverRight sales agent at +1 (888) 969-7667 (TTY: 711) if you need information in another format or language.'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCommunicationPreference(event.target.value as MedigapEnrollmentAccessibleFormat);
              }}
            >
              <MenuItem value={MedigapEnrollmentAccessibleFormat.Audio}>Audio</MenuItem>
              <MenuItem value={MedigapEnrollmentAccessibleFormat.LargePrint}>Large print</MenuItem>
              <MenuItem value={MedigapEnrollmentAccessibleFormat.AccessibleScreenReader}>Accessible screen reader</MenuItem>
              <MenuItem value={MedigapEnrollmentAccessibleFormat.OralOverThePhone}>Oral over the phone</MenuItem>
              <MenuItem value={MedigapEnrollmentAccessibleFormat.Braille}>Braille</MenuItem>
            </FormSelectInput>
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'32px'} />
            <Typography color={'textPrimary'} className={'bold fs-24 mb-5'}>Medicare details</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput name={'medicareNumber'}
                           InputProps={{
                             inputComponent: MedicareNumberInput as any
                           }}
                           defaultValue={userInfoData.data?.userProfile?.medicareNumber}
                           error={formState.isSubmitted && !!errors.medicareNumber}
                           helperText={formState.isSubmitted && errors?.medicareNumber?.message}
                           inputRef={register}
                           placeholder={'_ _ _ _ - _ _ _ - _ _ _ _'}
                           label={'Medicare Number*'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput name={'medicareNumberConfirm'}
                           InputProps={{
                             inputComponent: MedicareNumberInput as any
                           }}
                           defaultValue={userInfoData.data?.userProfile?.medicareNumber}
                           error={!!errors.medicareNumberConfirm}
                           helperText={errors?.medicareNumberConfirm?.message}
                           inputRef={register}
                           placeholder={'_ _ _ _ - _ _ _ - _ _ _ _'}
                           label={'Re-enter Medicare Number*'} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Hospital (Part A) effective date*'}
                error={formState.isSubmitted && !moment(medicarePartAStartedAt).isValid()}
                helperText={(formState.isSubmitted && !moment(medicarePartAStartedAt).isValid()) && 'Please enter a valid date'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartAStartedAt}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartAStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label={'Medical (Part B) effective date*'}
                error={formState.isSubmitted && !moment(medicarePartBStartedAt).isValid()}
                helperText={(formState.isSubmitted && !moment(medicarePartBStartedAt).isValid()) && 'Please enter a valid date'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                placeholder={'MM / DD / YYYY'}
                value={medicarePartBStartedAt}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setMedicarePartBStartedAt(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>

        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'24px'} mb={'40px'}>
        <AscendButton className={'gtm-step-event'} variant={'contained'}
                      disabled={disabled}
                      type={'submit'}>Confirm and submit</AscendButton>
      </Box>
      <Typography className={'fs-13'} color={'textSecondary'}>
        By submitting my email and phone number and clicking ‘Confirm and submit’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
        This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />
        I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
      </Typography>
      <div className={'h-100'} />
    </form>
  </Box>
}

export default MedigapEnrollmentForm;
