import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../shared/useCommonStyles";
import useSaveRequest from "../hooks/useSaveRequest";
import { RouteComponentProps } from 'react-router-dom';
import {getQuoteId} from "../../shared/QuoteId";
import {Typography} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import paths from "../config/router-paths";
import {annualQuestionsRoutes} from "./AnnualEnrollment/useAnnualStepperState";
import {withCard} from "../../shared/components/withCard";
import clsx from 'clsx';
import {questionsRoutes} from "../hooks/useStepperState";

const useStyles = makeStyles({
  label: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  bold: {
    fontWeight: 700,
  }
});

const formName = 'CALENDAR';

function isCalendlyEvent(e: any) {
  return e.data.event &&
    e.data.event.indexOf('calendly') === 0;
};

function Step15(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const commonClasses = useCommonStyles();
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [save] = useSaveRequest();

  const handler = (e: any) => {
    if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
      setNextButtonDisabled(false);
      save({
        variables: {
          data: {
            status: 'COMPLETE',
            form: formName,
            id: getQuoteId()
          }
        },
      })
    }
  }

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    }
  }, [])

  React.useEffect(() => {
    if (showCalendar) {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, [showCalendar])

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      {!showCalendar && <>
        <Box mt={'20px'}>
          <Typography className={clsx(classes.label, classes.bold)} variant={'h3'} color={'textSecondary'}>Please book a time for your free consultation.</Typography>
        </Box>
        <Box mt={'10px'}>
          <img src={'/img/calendar.svg'} />
        </Box>
        <Box mt={'18px'}>
          <AscendButton onClick={() => setShowCalendar(true)} variant={'contained'}>Book a time to discuss options</AscendButton>
        </Box>
      </>}
      {showCalendar && <div className="calendly-inline-widget" data-url="https://calendly.com/ascendsupport/30min"
                            style={{width: '100%', minWidth: "320px", height: "750px"}}
      ></div>}
      <Box mt={'23px'}>
        <Typography className={classes.label} variant={'body2'} color={'textSecondary'}>You do not need to provide a phone number.  We will email you a calendar invite with video conference and dial-in details.</Typography>
      </Box>

      <Box className={commonClasses.buttonWrapper} mt={'10px'} width={'100%'}>
        <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
          props.history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton disabled={nextButtonDisabled} tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={() => {
          props.history.push(paths.questionnaire + '/' + questionsRoutes.step16)
        }}>Continue</AscendButton>
      </Box>
    </Box>
  )
}

export default withCard(
  Step15,
  `We’ve received your request!`,
  `Give us a few minutes to finish up our research and a licensed agent will send you the best options via email.`,
  true
)
