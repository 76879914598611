import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormTextInput from "../../../shared/components/FormTextInput";
import AscendModal from "../../../shared/components/AscendModal";
import AscendButton from "../../../shared/components/AscendButton";
import useEmailMeMedigapPage from "../../Medigap/PlanList/hooks/useEmailMeMedigapPage";
import useEmailMeMAPage from "../../MedicareAdvantage/PlanList/hooks/useEmailMeMAPage";
import {GetPlansYear} from "../hooks/GetPlansYear";
import {EmailMeMaPagePlanYear} from "../../../enrollment-types";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    pointer: {
      cursor: 'pointer'
    }
  })
);
const Schema = yup.object().shape({
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
});

interface FormState {
  email: string;
}

type PlanListEmailModalProps = {
  onClose: () => void,
  open: boolean,
  id: string,
  zip: string,
  county: string,
  medigap?: boolean
}
export default function PlanListEmailModal(props: PlanListEmailModalProps) {
  const classes = useStyles();
  const {register, handleSubmit, errors, getValues, watch, formState} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()
  const [sendEmail] = useEmailMeMedigapPage();
  const [sendMAEmail] = useEmailMeMAPage();

  const sendMG = (email: string) => sendEmail({
    variables: {
      email,
      id: props.id
    }
  })

  const sendMA = (email: string) => sendMAEmail({
    variables: {
      email,
      id: props.id,
      path: `/plans/${props.zip}/${props.county}`,
      yearEmailMeMaPage: GetPlansYear() === '2022' ? EmailMeMaPagePlanYear.Year2022 : EmailMeMaPagePlanYear.Year2023
    }
  })

  const onSubmit = React.useCallback((values: FormState) => {
    if (props.medigap) {
      sendMG(values.email).finally(props.onClose);
    } else {
      sendMA(values.email).finally(props.onClose);
    }
  }, [props.medigap, sendMA, sendMG])

  return <AscendModal
    width={700}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'45px 55px 40px'}
  >
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <IconButton onClick={props.onClose} className={classes.closeIcon}><img src={'/img/close.svg'} /></IconButton>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography className={'fs-26 bold text-align-centered'} color={'textPrimary'}>Enter your email below to send your<br />plan options to yourself. </Typography>
        <div className={'h-16'} />
        <img src={'/img/mail.svg'} />
      </Box>
      <Box mt={'16px'}>
        <FormTextInput fullWidth
                       name={'email'}
                       autoFocus={true}
                       inputProps={{
                         autoCapitalize: 'none'
                       }}
                       placeholder={'Enter your email address'}
                       error={!!errors.email && formState.isSubmitted}
                       helperText={formState.isSubmitted && errors?.email?.message}
                       inputRef={register} />
        <Box display={'flex'}>
          <img src={'/img/padlock.svg'} className={'mr-5'} />
          <Typography className={'fs-14 bold'} color={'textPrimary'}>100% secure. We’ll never sell your details.</Typography>
        </Box>
      </Box>

      <Box display={'flex'} mt={'20px'} justifyContent={'center'}>
        <AscendButton variant={'contained'} disabled={!formState.isValid} type={'submit'}>Send plans now</AscendButton>
      </Box>
      <div className={'h-30'} />
      <Typography className={'fs-13'} color={'textSecondary'}>
        By submitting my email and clicking ’Send plans now’ above, I confirm that the email provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
        This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />
        I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
      </Typography>
    </form>
  </AscendModal>
}
