import React from "react";
import GoogleMapReact, {Bounds} from "google-map-react";
import Marker from "./Marker";
import { fitBounds } from 'google-map-react';
import useDimensions from "../hooks/useDimensions";
import {LocationOutput, PharmacyLocationDistanceOutput} from "../../types";
import * as _ from 'lodash';

type GoogleMapProps = {
  pharmacies: PharmacyLocationDistanceOutput[],
  onHoverKeyChange?: (key: string | null) => void
  onChildClick?: (key: string) => void,
  onChange: (bounds: Bounds) => void,
  selected?: string,
  fallbackLocation?: LocationOutput,
  page: number
}

type MapProps = {
  zoom: number,
  center: {
    lat: number,
    lng: number
  }
}

const GoogleMap = (props: GoogleMapProps) => {
  const [defaultProps, setDefaultProps] = React.useState<MapProps>();
  const [blockOnChange, setBlockOnChange] = React.useState(true);
  const [ref, {width, height}] = useDimensions();

  React.useEffect(() => {
    setBlockOnChange(true);
    const padding = props.pharmacies.length > 1 ? 0 : 0.01;
    const locations = props.pharmacies.length > 1 ? props.pharmacies : props.fallbackLocation ? [props.fallbackLocation] : [];
    const boundingBox = findBoundingBoxForGivenLocations(locations, padding);
    const bounds = {
      ne: {
        lat: boundingBox.north,
        lng: boundingBox.east
      },
      sw: {
        lat: boundingBox.south,
        lng: boundingBox.west
      }
    };
    const size = {
      width,
      height
    };

    const {center, zoom} = fitBounds(bounds, size);

    setDefaultProps({
      zoom,
      center
    })
  }, [props.pharmacies, width, height, props.fallbackLocation])

  const onChildClick = (key: string) => {
    props.onChildClick && props.onChildClick(key);
  }

  const onChildMouseEnter = (key: string) => {
    props.onHoverKeyChange && props.onHoverKeyChange(key);
  }

  const onChildMouseLeave = () => {
    props.onHoverKeyChange && props.onHoverKeyChange(null);
  }
  return <div style={{width: '100%'}} ref={ref}>
    {defaultProps && <div className={'full-height fill-width'} onWheel={() => setBlockOnChange(false)} onMouseDown={() => setBlockOnChange(false)}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDdj6X25-H3JUQZQ7IHjrp10RMA6_UdUUc' }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        defaultCenter={{
          lat: 40.687905,
          lng: -73.9818045
        }}
        defaultZoom={11}
        onChildClick={onChildClick}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        onChange={e => {
          !blockOnChange && props.onChange(e.bounds)
        }}
      >
        {!!props.pharmacies.length && props.pharmacies.map((p: PharmacyLocationDistanceOutput, i: number) => <Marker
          active={props.selected === p.npi}
          key={p.npi}
          lat={p.latitude || undefined}
          lng={p.longitude || undefined}
          index={props.page * 10 + i + 1}
          clickable
        />)}
      </GoogleMapReact>
    </div>}
  </div>
}

export default GoogleMap;

type BoundingBox = {
  north: number,
  east: number,
  south: number,
  west: number
}

const findBoundingBoxForGivenLocations = (pharmacies: (PharmacyLocationDistanceOutput | LocationOutput)[], padding: number = 0): BoundingBox => {
  let west: any = 0;
  let east: any = 0;
  let north: any = 0;
  let south: any = 0;

  for (let i = 0; i < pharmacies.length; i++) {
    const pharmacy = pharmacies[i];

    if (i === 0) {
      north = pharmacy.latitude;
      south = pharmacy.latitude;
      west = pharmacy.longitude;
      east = pharmacy.longitude;
    } else {
      if (pharmacy.latitude && pharmacy.latitude > north)
      {
        north = pharmacy.latitude;
      }
      else if (pharmacy.latitude && pharmacy.latitude < south)
      {
        south = pharmacy.latitude;
      }
      if (pharmacy.longitude && pharmacy.longitude < west)
      {
        west = pharmacy.longitude;
      }
      else if (pharmacy.longitude && pharmacy.longitude > east)
      {
        east = pharmacy.longitude;
      }
    }
  }

  north = north + padding;
  south = south - padding;
  west = west - padding;
  east = east + padding;

  return {north, east, south, west};
}
