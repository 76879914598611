import React from "react";
import Catch from "./FunctionalErrorBoundary"
import {Button} from "@material-ui/core";

type Props = {
  children: React.ReactNode,
  showLogoutButton: boolean
}

const ErrorBoundary = Catch((props: Props, error?: Error) => {
  if (error) {
    return (
      <div className="error-screen">
        <h2>An error has occured</h2>
        <h4>{error.message}</h4>
      </div>
    )
  } else {
    return <>{props.children}</>
  }
})

const withErrorBoundary = (WrappedComponent: any, showLogoutButton = false) => (props: any) => {
  return (
    <ErrorBoundary showLogoutButton={showLogoutButton}>
      <WrappedComponent {...{...props}} />
    </ErrorBoundary>
  )
}

export default withErrorBoundary;
