import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step10";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";

function Step10(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);

  return <Shared quote={quote} dispatch={dispatch}  {...props} />;
}

export default Step10 as any;
