import React from "react";
import {IQuestion, IStep} from "../../../shared/SidePanel/AscendStepper";
import * as _ from "lodash";
import Quiz1 from "../../shared/questions/Quiz1";
import Quiz1a from "../../shared/questions/Quiz1a";
import Quiz2 from "../../shared/questions/Quiz2";
import Quiz3 from "../../shared/questions/Quiz3";
import Step1 from "../../shared/questions/Step1";
import Step4 from "../../shared/questions/Step4";
import Step5 from "../../shared/questions/Step5";
import Step6 from "../../shared/questions/Step6";
import Step7 from "../../shared/questions/Step7";
import Step8 from "../../shared/questions/Step8";
import Step9 from "../questions/Step9";
import Step9a from "../questions/Step9a";
import Step10 from "../questions/Step10";
import {IStepperState} from "../../../Questionnaire/hooks/useStepperState";
import Step2 from "../questions/Step2";
import Step3 from "../questions/Step3";

export const questionsRoutes = {
  quiz1: "quiz-1",
  quiz1a: "quiz-1a",
  quiz2: "quiz-2",
  quiz3: "quiz-3",
  step1: "",
  step2: "step2",
  step3: "step3",
  step4: "step4",
  step5: "step5",
  step6: "step6",
  step7: "step7",
  step6a: "step6a",
  step8: "step8",
  step9: "step9",
  step9a: "step9a",
  step10: "plans",
}

let medigapSteps: IStep[] = [
  {
    label: "Create your account",
    firstStep: questionsRoutes.step1,
    questions: [{
      link: questionsRoutes.quiz1,
      component: Quiz1
    }, {
      link: questionsRoutes.quiz1a,
      component: Quiz1a
    }, {
      link: questionsRoutes.quiz2,
      component: Quiz2
    },  {
      link: questionsRoutes.quiz3,
      component: Quiz3
    }, {
      link: questionsRoutes.step1,
      component: Step1
    }, {
      link: questionsRoutes.step2,
      component: Step2
    }, {
      link: questionsRoutes.step3,
      component: Step3
    }, {
      link: questionsRoutes.step4,
      component: Step4
    }, {
      link: questionsRoutes.step5,
      component: Step5
    }, {
      link: questionsRoutes.step6,
      component: Step6
    }, {
      link: questionsRoutes.step7,
      component: Step7
    }, {
      link: questionsRoutes.step8,
      component: Step8
    }, {
      link: questionsRoutes.step9,
      component: Step9
    }],
  },
  {
    label: "Access your plan finder dashboard",
    firstStep: questionsRoutes.step10,
    questions: [{
      link: questionsRoutes.step9a,
      component: Step9a
    }],
  },
  {
    label: "Speak to an advisor/enroll",
    firstStep: questionsRoutes.step10,
    questions: [{
      link: questionsRoutes.step10,
      component: Step10
    }],
  }
];


const getQuestions = (steps: IStep[]) => {
  return steps.reduce((previousValue, currentValue) => {
    const result = [...previousValue];
    if (currentValue.questions) {
      result.push(...currentValue.questions);
    }
    if (currentValue.children?.length) {
      result.push(..._.flatten(currentValue.children.map((ch: any) => ch.questions)));
    }
    return result;
  }, [] as any[]);
}

let listeners: any[] = [];

export function setCompletedByLabel(label: string, completed: boolean) {
  let mst = [...medigapState.steps.map(step => ({
    ...step,
    completed: step.label === label ? completed : step.completed,
    children: step.children ? step.children.map(s => ({
      ...s,
      completed: s.label === label ? completed : s.completed
    })) : step.children,
  }))]
  setMedigapState({...medigapState, steps: mst, questions: getQuestions(mst)})
}

let medigapState: IStepperState = {
  steps: medigapSteps,
  questions: getQuestions(medigapSteps)
};

const setMedigapState = (newState: IStepperState | Function) => {
  if (typeof newState === "function") {
    medigapState = newState(medigapState);
  } else {
    medigapState = {...medigapState, ...newState};
  }
  listeners.forEach((listener) => {
    listener(medigapState);
  });
};

const useStepperState = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {medigapState, setMedigapState};
};

export default useStepperState;

export {
  medigapSteps,
}


