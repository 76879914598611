import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, Grid, RadioGroup} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";

const formName = 'STEP_1';

function Step1(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [value, setValue] = React.useState<Step1Answers>(state?.STEP_1?.value?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        value: {
          questionText: 'First, tell me a little bit about yourself.',
          answerText: answers[value],
          value
        },
        form: formName
      }
    })
    switch (value) {
      case Step1Answers.FirstTime:
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2b', value: undefined, form: formName}
        })
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2c', value: undefined, form: formName}
        })
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step2a);
        break;
      case Step1Answers.BetterCoverage:
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2a', value: undefined, form: formName}
        })
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2c', value: undefined, form: formName}
        })
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step2b);
        break;
      case Step1Answers.SpecialEvent:
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2a', value: undefined, form: formName}
        })
        dispatch({
          type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
          payload: {stateKey: 'STEP_2b', value: undefined, form: formName}
        })
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step2c);
        break;
    }

  };

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-32'} style={{maxWidth: 500}}>
    <RadioGroup>
      <AscendRadio checked={value === Step1Answers.FirstTime}
                   onChange={() => {setValue(Step1Answers.FirstTime)}}
                   label={answers[0]} />
      <AscendInfo open={value === Step1Answers.FirstTime}
                  description={'We know that Medicare can be confusing. We’re here to help you find the best coverage for your needs.'}/>
      <div className={'h-8'} />
      <AscendRadio checked={value === Step1Answers.BetterCoverage}
                   onChange={() => {setValue(Step1Answers.BetterCoverage)}}
                   label={answers[1]} />
      <AscendInfo open={value === Step1Answers.BetterCoverage}
                  description={'Great! We’ll help you compare all of your options so you get better coverage for less.'}/>
      <div className={'h-8'} />
      <AscendRadio checked={value === Step1Answers.SpecialEvent}
                   onChange={() => {setValue(Step1Answers.SpecialEvent)}}
                   label={answers[2]} />
      <AscendInfo open={value === Step1Answers.SpecialEvent}
                  description={'You can make changes to your Medicare coverage if you qualify for a Special Enrollment Period due to a life event, like losing other coverage or moving home permanently.'}/>

    </RadioGroup>
    <Box mt={'30px'} display={'flex'} justifyContent={'center'}>
      <AscendButton className={'gtm-step-event'} id={formName} disabled={typeof value === 'undefined'} variant={'contained'} onClick={onSubmit}>Let’s get started</AscendButton>
    </Box>
    </div>
  </Grid>;
}

const answers = [
  "I’m new to Medicare.",
  "I’m already enrolled in a Medicare plan, but I’m looking for a better option.",
  "I qualify for a Special Enrollment Period, so I’m looking for a new plan."
]

export default withTitle(
  Step1,
  () => 'Welcome! I’m Amy. I understand you’re\ninterested in learning about Medicare options.',
  'First, tell me a little bit about yourself.'
);

export enum Step1Answers {
  FirstTime,
  BetterCoverage,
  SpecialEvent
}
