export const config = {
  graphQL: process.env.REACT_APP_GRAPHQL,
  keycloak: {
    url: process.env.REACT_APP_KEYCLOAK_SERVER,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    protocol: 'openid-connect',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  },
  filesUrl: process.env.REACT_APP_FILES_URL,
  resultsUrl: process.env.REACT_APP_RESULTS_URL,
  medigapUrl: process.env.REACT_APP_MEDIGAP_URL,
  enrollUrl: process.env.REACT_APP_ENROLL_URL,
}
