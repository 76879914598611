import React from "react";
import {addToCompare, getCompareList, removeFromCompare} from "./utils";

interface ComparisonContextState {
  isInCompare: (bidId: string) => boolean,
  removeFromCompare: (bidId: string) => void,
  addToCompare: (bidId: string) => void,
  clear: () => void,
  list: string[]
}

const defaultState: ComparisonContextState = {
  isInCompare: () => false,
  removeFromCompare: () => {},
  addToCompare: () => {},
  clear: () => {},
  list: [],
};

export const ComparisonContext = React.createContext<ComparisonContextState>(defaultState);

export function ComparisonProvider(props: React.PropsWithChildren<ComparisonProviderProps>) {

  const [list, setList] = React.useState<string[]>(getCompareList());

  const remove = (bidId: string) => {
    removeFromCompare(bidId);
    setList(getCompareList())
  }

  const add = (bidId: string) => {
    addToCompare(bidId);
    setList(getCompareList())
  }

  const isInCompare = (bidId: string) => list.includes(bidId);

  const clear = () => {
    for (const id of list) {
      remove(id);
    }
  };

  return <ComparisonContext.Provider value={{clear, removeFromCompare: remove, addToCompare: add, isInCompare, list}}>
    {props.children}
  </ComparisonContext.Provider>

}

type ComparisonProviderProps = {}
