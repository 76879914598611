import React from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import {config} from "../Maketplace/MedicareAdvantage/config/config";
import Preloader from "./Preloader";

declare let Keycloak: any;

export const keycloak = Keycloak(config.keycloak);

export enum KeycloakRoles {
  ROLE_PUBLIC_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export function useUserId(): string | undefined {
  return keycloak?.tokenParsed?.sub;
}

export const withKeycloak = (WrappedComponent: any) => (props: any) => {
  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const [authtorized, setAuthtorized] = React.useState<boolean>();

  React.useEffect(() => {
    keycloak.init({onLoad: 'login-required'}).success((authenticated: boolean) => {
    //  console.log(keycloak);
      setAuthenticated(authenticated);
    })
  }, []);

  React.useEffect(() => {
    const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
    setAuthtorized(roles.includes('ROLE_USER'));
  }, [keycloak?.tokenParsed?.realm_access?.roles, authenticated])

  let component = null;

  if (keycloak) {
    if (authenticated && authtorized) {
      component = <WrappedComponent {...{...props, keycloak}}/>;
    } else if (authenticated && !authtorized) {
      component = <Logout />;
    } else {
      component = <Preloader/>;
    }
  } else {
    component = <Preloader/>;
  }

  return component;
}


const Logout = () => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1} flexDirection={'column'}>
      <Box mb={2}>
        <Typography variant={'h3'}>You are not authorized</Typography>
      </Box>
      <Button size={'medium'} variant={'contained'} color={'primary'} onClick={() => keycloak.logout({redirectUri: document.location.origin})}>Logout</Button>
    </Box>
  )
}
