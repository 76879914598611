import React from "react";
import {
  ClickAwayListener,
  Theme,
  Tooltip, TooltipProps, useMediaQuery, useTheme,
  withStyles
} from "@material-ui/core";
import {colors} from "../AppTheme";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  tooltip: {
    border: ({variant}: {variant?: 'standart' | 'white'}) => `1px solid ${variant === 'white' ? '#CCD8DC' : colors.custom.green.variant2}!important`,
    backgroundColor: ({variant}: {variant?: 'standart' | 'white'}) => variant === 'white' ? 'white' : '#f2f9fa',
    borderRadius: 4,
    color: colors.text.primary,
    fontSize: 13,
    textAlign: 'left',
    padding: '15px 20px 11px',
    fontWeight: 400,
    '& .MuiTooltip-arrow': {
      color: ({variant}: {variant?: 'standart' | 'white'}) => variant === 'white' ? 'white' : '#f2f9fa',
    },
    '& .MuiTooltip-arrow:before': {
      border: ({variant}: {variant?: 'standart' | 'white'}) => `1px solid ${variant === 'white' ? '#CCD8DC' : colors.custom.green.variant2}!important`,
    },
  },
})

const AscendTooltip = (props: TooltipProps & {variant?: 'standart' | 'white'}) => {
  const {children, variant, ...rest} = props;
  const classes = useStyles({variant});
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickAway = () => setOpen(false)

  if (!phone) {
    return <Tooltip {...rest} classes={classes}>{children}</Tooltip>
  } else {
    return <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip {...rest} open={open} onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(true)
      }} classes={classes}>{children}</Tooltip>
    </ClickAwayListener>
  }
};

export default React.memo(AscendTooltip);
