import React from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {QueryPackageDescriptionByRxcuiArgs} from "../../types";

export default function useGetPackagesDescription() {
  return useLazyQuery<{packageDescriptionByRxcui: string}, QueryPackageDescriptionByRxcuiArgs>(
    gql(getPackageRequest),
    {
      fetchPolicy: 'no-cache'
    }
  );
}

const getPackageRequest = `
query ($rxcui: String!) {
  packageDescriptionByRxcui(rxcui: $rxcui)
}
`;
