import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendModal from "../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

interface DeleteAccountConfirmationModalProps {
  open: boolean,
  onClose: (result: boolean) => void,
}

export default function DeleteAccountConfirmationModal(props: DeleteAccountConfirmationModalProps) {
  const classes = useStyles();

  return <AscendModal
    width={590}
    open={props.open}
    onClose={() => props.onClose(false)}
    paperPadding={'25px 45px 40px'}
  >
    <>
      <IconButton onClick={() => props.onClose(false)} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Are you sure you want to delete your account?</Typography>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'54px'}>
        <AscendButton variant={'contained'} className={'fill-width'} onClick={() => props.onClose(true)}>Yes, permanently delete my account</AscendButton>
        <div className={'h-20'} />
        <AscendButton variant={'contained'} className={'fill-width'} color={'secondary'} onClick={() => props.onClose(false)}>Go back</AscendButton>
      </Box>
    </>
  </AscendModal>
}
