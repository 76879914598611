import React from "react";
import {createStyles, makeStyles, Theme, useTheme, withStyles} from "@material-ui/core/styles";
import {Box, Collapse, IconButton, Typography, useMediaQuery} from "@material-ui/core";
import Footer from "../Footer";
import {useHistory, useParams} from "react-router";
import paths from "../config/router-paths";
import AscendButton from "../../../shared/components/AscendButton";
import BackButton from "../../MedicareAdvantage/shared/BackButton";
import {colors} from "../../../shared/AppTheme";
import clsx from "clsx";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {MedigapPlanName} from "../../../medigap-types";
import PlanData from "../config/PlanData";
import Icon from "../../shared/components/Icon";
import CollapseSection from "../../../shared/components/CollapseSection";
import {PlanFilterContext, withPlanFilterProvider} from "../PlanList/PlanFilterContext";
import {useMedigapPremiumRanges} from "./hooks/useMedigapPremiumRanges";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      color: 'white',
      backgroundColor: colors.custom.green.variant1,
      borderRadius: '50%',
      width: 36,
      height: 36,
      minWidth: 36,
      minHeight: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      fontWeight: 700,
      marginRight: 13,
    },
    ribbon: {
      position: 'absolute',
      right: -8,
      top: -6
    },
    card: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      flexDirection: 'column',
      borderRadius: '10px',
      backgroundColor: 'white',
      padding: '10px 11px',
      lineHeight: '20px',
      cursor: 'pointer',
      minWidth: 213,
      '& ul': {
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
        listStyle: 'inside',
        '& li': {
          fontSize: 12,
          color: colors.text.primary,
          lineHeight: '20px',
        }
      }
    },
    cardActive: {
      border: '1px solid ' + colors.custom.green.variant1,
    },
    totalLine: {
      '& p': {
        fontWeight: 700,
        fontSize: 16
      },
      backgroundColor: '#C8E8EB',
      borderRadius: 10,
    },
    denseLine: {
      padding: '8px 8px 8px 30px!important'
    },
    whiteLine: {
      backgroundColor: 'white'
    },
    greenLine: {
      backgroundColor: 'rgba(2, 144, 148, 0.05)',
      borderRadius: 10,
    },
    select: {
      position: 'absolute',
      border: '3px solid #5ECB9E',
      borderRadius: 10,
      top: 0,
      right: 0,
      height: '100%',
      '& > div': {
        color: 'white',
        fontWeight: 700,
        fontSize: 16,
        width: '100%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 5,
        backgroundColor: colors.custom.green.variant2,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        whiteSpace: 'nowrap',
      }
    },
    icon: {
      transform: "rotate(-180deg)",
      transition: "all 0.3s"
    },
    iconOpen: {
      transform: "rotate(0) !important",
    },
    button: {
      background: "rgba(2, 144, 148, 0.5)",
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      "&:hover": {
        background: "rgba(2, 144, 148, 0.5)",
      }
    },
    label: {
      whiteSpace: 'pre'
    },
    radio: {
      width: 22,
      height: 22,
      border: '1px solid #A4B4B9',
      borderRadius: 11,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 2,
    },
    radioActive: {
      backgroundColor: colors.custom.green.variant1,
      border: '1px solid ' + colors.custom.green.variant1,
    },
    buttonArrow: {
      position: 'absolute',
      top: 'calc(50% - 15px)',
      backgroundColor: '#FF785A',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: 30,
      height: 30,
    },
    buttonArrowLeft: {
      left: -40,
      '& img': {
        transform: 'rotate(-90deg)',
      }
    },
    buttonArrowRight: {
      right: -40,
      '& img': {
        transform: 'rotate(90deg)',
      }
    },
    arrowLeft: {
      position: 'absolute',
      left: 4,
      top: 2,
      '& img': {
        transform: 'rotate(-180deg)',
      }
    },
    arrowRight: {
      position: 'absolute',
      right: 4,
      top: 2,
    },
    inactive: {
      opacity: 0.4
    },
    cardsWrapper: {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'space-between',
      gridGap: 16,
      transition: 'all 0.3s',
      paddingBottom: 5,
    },
    cardsWrapperShifted: {
      marginLeft: -229,
    },
  }),
);

const Category = () => {
  const classes = useStyles();
  let {zip, countyName} = useParams();
  const filter = React.useContext(PlanFilterContext);
  const history = useHistory();
  const [selectedType, setSelectedType] = React.useState(0);
  const [detailsExpanded, setDetailsExpanded] = React.useState(true);
  const [benefitsExpanded, setBenefitsExpanded] = React.useState(true);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileCurrentPlanIdx, setMobileCurrentPlanIdx] = React.useState(0);
  const [shifted, setShifted] = React.useState(false);
  const [getPremiumRanges, {data}] = useMedigapPremiumRanges();

  zip = zip || filter?.values?.zip;
  countyName = countyName || filter?.values?.county;

  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('MG marketplace - compare categories ', {source: 'V1'});
    }
  }, []);

  const plans = React.useMemo(() => {
    const result = planCategories[selectedType].plans
      .map(name => PlanData[name])
      .map(item =>
        data?.medigapPremiumRanges ?
          {
            ...item,
            premium: <div className={'text-align-centered fs-14 text-primary'}>
              {data?.medigapPremiumRanges.find(r => r.planName === item.name)?.rangeDescription} <br /><span className="fs-12">plus Standard<br />Part B premium</span>
            </div>
          } :
          item
      )
    ;
    if (phone) {
      return result[mobileCurrentPlanIdx] ? [result[mobileCurrentPlanIdx]] : [];
    }
    return result;
  }, [selectedType, phone, mobileCurrentPlanIdx, data]);

  React.useEffect(() => {
      if (zip) {
        getPremiumRanges({variables: {zip}})
      }
  }, [zip]);

  React.useEffect(() => {
    filter.switchCategory(planCategories[selectedType].name);
    setMobileCurrentPlanIdx(0);
  }, [selectedType]);

  const width = React.useMemo(() => {
      switch (plans.length) {
        case 1: return '39%';
        case 2: return '57%';
        case 3: return '65%';
        case 4: return '71%';
      }
  }, [plans]);

  const Table = React.useMemo(() => <div className={'mt-20 relative'}>
    <Box className={classes.select} width={width}>
      <div>
        {planCategories[selectedType].name}
        {(!phone && selectedType === 0) && <img className={'ml-10'} src={'/img/ribbon.svg'} />}
      </div>
    </Box>
    <div className={'h-40'} />
    <TableLine title={''}
               className={'flex-align-start'}
               values={plans.map(plan => <div key={plan.name} className={'relative fill-width'}>
                 {phone && <Box className={clsx({[classes.arrowLeft]: true, [classes.inactive]: mobileCurrentPlanIdx === 0})}
                                onClick={() => {
                                  if (mobileCurrentPlanIdx > 0) {
                                    setMobileCurrentPlanIdx(prev => prev - 1)
                                  }
                                }}
                 >
                   <img src={'/img/arrow-big.svg'} />
                 </Box>}
                 <Typography className={'fs-16 bold'} color={'textPrimary'} align={'center'}>Plan {plan.name}</Typography>
                 {plan.note && <Typography className={'fs-12 lh-15 pre'} color={'textPrimary'} align={'center'}>{plan.note}</Typography>}
                 {phone && <Box className={clsx({[classes.arrowRight]: true, [classes.inactive]: mobileCurrentPlanIdx === planCategories[selectedType].plans.length - 1})}
                                onClick={() => {
                                  if (mobileCurrentPlanIdx < planCategories[selectedType].plans.length - 1) {
                                    setMobileCurrentPlanIdx(prev => prev + 1)
                                  }
                                }}
                 >
                   <img src={'/img/arrow-big.svg'} />
                 </Box>}
               </div>)}
    />
    <TableLine title={'% of Medigap enrollees'}
               subtitle={'Based on 2018 AHIP data'}
               tooltip={'Based on the total number of enrollees in each plan as reported by America\'s Health Insurance Plans in 2018\t\t\t'}
               className={classes.whiteLine}
               values={plans.map(plan => plan.popularity)} />

    <TableLine title={<strong>Coverage summary</strong>}
               className={clsx('fs-16', 'bold', classes.greenLine)}
               values={plans.map(plan => <></>)} />

    <TableLine title={'Premium'}
               tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}
               values={plans.map(plan => plan.premium || '')}
    />

    <TableLine title={<strong>Additional coverage details</strong>}
               className={clsx('fs-16', 'bold', classes.greenLine)}
               values={plans.map((plan, i) => i === plans.length - 1 && !phone ? <Box display={'flex'} flex={1} justifyContent={'flex-end'} pr={'10px'}>
                 <IconButton onClick={() => setDetailsExpanded(!detailsExpanded)} className={classes.button}>
                   <img className={clsx(classes.icon, detailsExpanded && classes.iconOpen)} src={"/img/arrow-white.svg"}/>
                 </IconButton>
               </Box> : <></>)}
    />
    <Collapse in={detailsExpanded}>
      <TableLine title={'Part A: Hospital Deductible'}
                 subtitle={'Covers first 60 days'}
                 tooltip={'The amount you must pay out of pocket for your Part A (Hospital) costs before your plan coverage begins.'}
                 className={classes.whiteLine}
                 values={plans.map(plan => plan.partADeductible) }/>


      <TableLine title={'Part A: Hospital Costs'}
                 subtitle={'After first 60 days'}
                 tooltip={'Coverage for costs of services covered under Part A, including inpatient hospital stays, hospice care and skilled nursing facilities.'}
                 values={plans.map(plan => <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Icon value={true} />
                   <Typography className={`fs-12 ${classes.label}`} color={'textPrimary'} align={'center'}>
                     {phone ? "Up to an additional\n365 days after\nMedicare benefits\nare used up" : plan.partACoinsurance}
                   </Typography>
                 </Box>)} />


      <TableLine title={'Part B: Medical Deductible'}
                 tooltip={'The amount you must pay out of pocket for your Part B (Medical) costs before your plan coverage begins.'}
                 className={classes.whiteLine}
                 values={plans.map(plan => plan.partBDeductible) }/>

      <TableLine title={'Part B: Copays / Coinsurance'}
                 tooltip={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}
                 values={plans.map(plan => plan.partBCoinsurance) }/>

      <TableLine title={'Hospice care copay/coinsurance'}
                 tooltip={'Coverage for hospice care, which is generally comprehensive care for people who are terminally ill. Hospice care includes pain management, counseling, respite care, prescription drugs, inpatient care and outpatient care, and services to support the terminally ill person\'s family.'}
                 className={classes.whiteLine}
                 values={plans.map(plan => plan.hospiceCareCopay) }/>

    </Collapse>

    <TableLine title={<strong>Other benefits</strong>}
               className={clsx('fs-16', 'bold', classes.greenLine)}
               values={plans.map((plan, i) => i === plans.length - 1 && !phone ? <Box display={'flex'} flex={1} justifyContent={'flex-end'} pr={'10px'}>
                 <IconButton onClick={() => setBenefitsExpanded(!benefitsExpanded)} className={classes.button}>
                   <img className={clsx(classes.icon, benefitsExpanded && classes.iconOpen)} src={"/img/arrow-white.svg"}/>
                 </IconButton>
               </Box> : <></>)}
    />

    <Collapse in={benefitsExpanded}>
      <TableLine title={'Skilled Nursing facility costs'}
                 tooltip={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}
                 values={plans.map(plan => plan.skilledNursingFacility) }/>
      <TableLine title={'Part B ‘Excess Charges’'}
                 tooltip={'An amount a provider can charge over the Medicare-approved amount. Providers can charge up to 15% over the standard Medicare rates.'}
                 className={classes.whiteLine}
                 values={plans.map(plan => <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Icon value={plan.partBExcessCharges} />
                   <Typography className={'fs-12'} color={'textPrimary'} align={'center'}>{plan.partBExcessCharges ? 'Covered' : 'Not covered'}</Typography>
                 </Box>)} />

      <TableLine title={'Foreign travel emergency'}
                 tooltip={'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover the care.'}
                 values={plans.map(plan => plan.foreignTravelEmergency) }/>

      <TableLine title={'Blood transfusion'}
                 tooltip={'Blood received as part of a procedure in an inpatient or outpatient hospital setting. Original Medicare does not cover blood transfusions, unless the blood is donated.'}
                 className={classes.whiteLine}
                 values={plans.map(plan => plan.blood) }/>

      <TableLine title={'Out-of-pocket limit'}
                 tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                 values={plans.map(plan => plan.outOfPocketLimits) }/>

    </Collapse>
  </div>, [plans, detailsExpanded, benefitsExpanded]);

  const Desktop = <Box mt={'16px'}>
    <Box mt={'25px'} display={'flex'} alignItems={'center'}>
      <div className={classes.number}>1</div>
      <Typography color={'textPrimary'} className={'fs-18 medium'}>Select the type of coverage you are interested in</Typography>
    </Box>

    <Box position={'relative'}>
      {shifted && <div className={`${classes.buttonArrow} ${classes.buttonArrowLeft}`} onClick={() => setShifted(false)}><img src={'/img/arrow-white.svg'} /></div>}
      {!shifted && <div className={`${classes.buttonArrow} ${classes.buttonArrowRight}`} onClick={() => setShifted(true)}><img src={'/img/arrow-white.svg'} /></div>}
      <Box overflow={'hidden'}>
        <div className={clsx({[classes.cardsWrapper]: true, [classes.cardsWrapperShifted]: shifted})}>
        <div className={clsx({[classes.card]: true, [classes.cardActive]: selectedType === 0})}
             onClick={() => setSelectedType(0)}
        >
          <img className={classes.ribbon} src={'/img/ribbon.svg'} />
          <Typography color={'textPrimary'} className={'fs-16 bold mb-10'} align={'center'}>{PlanCategory.MostPopular}</Typography>
          {getLabelForCategory(PlanCategory.MostPopular)}
        </div>
        <div className={clsx({[classes.card]: true, [classes.cardActive]: selectedType === 1})}
             onClick={() => setSelectedType(1)}
        >
          <Typography color={'textPrimary'} className={'fs-16 bold mb-10'} align={'center'}>{PlanCategory.CoreBenefits}</Typography>
          {getLabelForCategory(PlanCategory.CoreBenefits)}
        </div>
        <div className={clsx({[classes.card]: true, [classes.cardActive]: selectedType === 2})}
             onClick={() => setSelectedType(2)}
        >
          <Typography color={'textPrimary'} className={'fs-16 bold mb-10'} align={'center'}>{PlanCategory.Comprehensive}</Typography>
          {getLabelForCategory(PlanCategory.Comprehensive)}
        </div>
        <div className={clsx({[classes.card]: true, [classes.cardActive]: selectedType === 3})}
             onClick={() => setSelectedType(3)}
        >
          <Typography color={'textPrimary'} className={'fs-16 bold mb-10'} align={'center'}>{PlanCategory.CostShare}</Typography>
          {getLabelForCategory(PlanCategory.CostShare)}
        </div>
        <div className={clsx({[classes.card]: true, [classes.cardActive]: selectedType === 4})}
             onClick={() => setSelectedType(4)}
        >
          <Typography color={'textPrimary'} className={'fs-16 bold mb-10'} align={'center'}>{PlanCategory.HighDeductible}</Typography>
          {getLabelForCategory(PlanCategory.HighDeductible)}
        </div>
      </div>
      </Box>
    </Box>

    <Box mt={'20px'} display={'flex'} alignItems={'center'}>
      <div className={classes.number}>2</div>
      <Typography color={'textPrimary'} className={'fs-18 medium'}>Review plan options and click "See Plans" to access instant quotes</Typography>
    </Box>

    <Box textAlign={"center"} my={'24px'}>
      <AscendButton variant={'contained'} onClick={() => history.push(paths.plans + zip + '/' + countyName)}>
        See {planCategories[selectedType].name} plans
      </AscendButton>
    </Box>

    {Table}
  </Box>

  const Mobile = <div>
    <Box mt={'25px'} display={'flex'} alignItems={'flex-start'}>
      <div className={classes.number}>1</div>
      <Typography color={'textPrimary'} className={'fs-18 medium'}>Select the type of coverage you are interested in</Typography>
    </Box>

    <Box mt={'30px'}>
      <div className={clsx({[classes.card]: true, 'mb-10': true, [classes.cardActive]: selectedType === 0})}>
          <CollapseSection defaultOpen={false} title={<div className={'flex'} onClick={() => setSelectedType(0)}>
            <div className={clsx({[classes.radio]: true, [classes.radioActive]: selectedType === 0})}><img src={'/img/tick-white.svg'} /> </div>
            <div className={'ml-15'}>
              <Typography color={'textPrimary'} className={'fs-16'}>Most Popular</Typography>
              <Typography color={'textPrimary'} className={'fs-12 bold op-7'}>Plan F, G and N</Typography>
            </div>
            <img className={'ml-10'} src={'/img/ribbon.svg'} />
          </div>}>
            {getLabelForCategory(PlanCategory.MostPopular, undefined, true)}
          </CollapseSection>
      </div>
      <div className={clsx({[classes.card]: true, 'mb-10': true, [classes.cardActive]: selectedType === 1})}>
        <CollapseSection defaultOpen={false} title={<div className={'flex'} onClick={() => setSelectedType(1)}>
          <div className={clsx({[classes.radio]: true, [classes.radioActive]: selectedType === 1})}><img src={'/img/tick-white.svg'} /> </div>
          <div className={'ml-15'}>
            <Typography color={'textPrimary'} className={'fs-16'}>Core benefits</Typography>
            <Typography color={'textPrimary'} className={'fs-12 bold op-7'}>Plan A and B</Typography>
          </div>
        </div>}>
          {getLabelForCategory(PlanCategory.CoreBenefits, undefined, true)}
        </CollapseSection>
      </div>
      <div className={clsx({[classes.card]: true, 'mb-10': true, [classes.cardActive]: selectedType === 2})}>
        <CollapseSection defaultOpen={false} title={<div className={'flex'} onClick={() => setSelectedType(2)}>
          <div className={clsx({[classes.radio]: true, [classes.radioActive]: selectedType === 2})}><img src={'/img/tick-white.svg'} /> </div>
          <div className={'ml-15'}>
            <Typography color={'textPrimary'} className={'fs-16'}>Comprehensive</Typography>
            <Typography color={'textPrimary'} className={'fs-12 bold op-7'}>Plan C, D, F and G</Typography>
          </div>
        </div>}>
          {getLabelForCategory(PlanCategory.Comprehensive, undefined, true)}
        </CollapseSection>
      </div>
      <div className={clsx({[classes.card]: true, 'mb-10': true, [classes.cardActive]: selectedType === 3})}>
        <CollapseSection defaultOpen={false} title={<div className={'flex'} onClick={() => setSelectedType(3)}>
          <div className={clsx({[classes.radio]: true, [classes.radioActive]: selectedType === 3})}><img src={'/img/tick-white.svg'} /> </div>
          <div className={'ml-15'}>
            <Typography color={'textPrimary'} className={'fs-16'}>Cost-share plans</Typography>
            <Typography color={'textPrimary'} className={'fs-12 bold op-7'}>Plan K, L, M and N (and high deductible F and G)</Typography>
          </div>
        </div>}>
          {getLabelForCategory(PlanCategory.CostShare, undefined, true)}
        </CollapseSection>
      </div>
      <div className={clsx({[classes.card]: true, 'mb-10': true, [classes.cardActive]: selectedType === 4})}>
        <CollapseSection defaultOpen={false} title={<div className={'flex'} onClick={() => setSelectedType(4)}>
          <div className={clsx({[classes.radio]: true, [classes.radioActive]: selectedType === 4})}><img src={'/img/tick-white.svg'} /> </div>
          <div className={'ml-15'}>
            <Typography color={'textPrimary'} className={'fs-16'}>{PlanCategory.HighDeductible}</Typography>
            <Typography color={'textPrimary'} className={'fs-12 bold op-7'}>Plan F and G (high deductible)</Typography>
          </div>
        </div>}>
          {getLabelForCategory(PlanCategory.HighDeductible, undefined, true)}
        </CollapseSection>
      </div>
    </Box>

    <Box mt={'25px'} display={'flex'} alignItems={'flex-start'}>
      <div className={classes.number}>2</div>
      <Typography color={'textPrimary'} className={'fs-18 medium'}>Review plan options and click "See Plans" to access instant quotes</Typography>
    </Box>

    {Table}

  </div>


  return <Box flexDirection={'column'} my={'15px'} maxWidth={'900px'} alignItems={'center'} position={'relative'}>
        <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
          <BackButton
            startIcon={<img src={"/img/arrow-left.svg"}/>}
            onClick={() => history.goBack()}>
            Back
          </BackButton>
        </Box>
        {phone ? Mobile : Desktop}
        <Box textAlign={"center"} mt={'32px'}>
          <AscendButton variant={'contained'} onClick={() => history.push(paths.plans + zip + '/' + countyName)}>
            See {planCategories[selectedType].name} plans
          </AscendButton>
        </Box>
        <Footer />
      </Box>;
}

export default Category;

type TableLineProps = {
  title: string | JSX.Element,
  subtitle?: string,
  tooltip?: string | JSX.Element,
  values: (boolean | JSX.Element | string)[],
  classes: any,
  className?: string,
  opaque?: boolean
}

const TableLine = withStyles({
  wrapper: {
    display: 'flex',
    padding: '16px 8px 16px 30px'
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '15px',
  },
  label: {
    whiteSpace: 'pre',
    fontSize: 14,
  },
  transparent: {
    opacity: 0.6,
  }
})((props: TableLineProps) => <Box className={clsx(props.classes.wrapper, props.className)}>
  <Box display={'flex'} flex={3} pt={'3px'} alignItems={'center'} pr={{xs: '15px', sm: '0px'}}>
    <AscendTooltip placement={'top'} arrow title={props.tooltip || ''}>
      <div className={`flex cursor-default`}>
        {props.tooltip && <img src={'/img/info.svg'} className={'mr-8'}/>}
        <div>
          <Typography variant={"body2"} color={'textPrimary'}>{props.title}</Typography>
          {props.subtitle && <Typography className={props.classes.subTitle} color={'textSecondary'}>{props.subtitle}</Typography>}
        </div>
      </div>
    </AscendTooltip>

  </Box>
  {props.values.map((value, i) => <Box key={i} display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'}>
    {typeof value === 'boolean' ? <Icon value={value} /> :
      typeof value === 'string' ? <Typography className={`${props.classes.label}`} color={'textPrimary'} align={'center'}>{value}</Typography> : <>{value}</>}
  </Box>)}
</Box>)


export enum PlanCategory {
  MostPopular = 'Most Popular',
  CoreBenefits = 'Core benefits',
  Comprehensive = 'Comprehensive',
  CostShare = 'Cost-share',
  HighDeductible = 'High deductible',
  Custom = 'Custom',
}

export const planCategories = [
  {name: PlanCategory.MostPopular, plans: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N]},
  {name: PlanCategory.CoreBenefits, plans: [MedigapPlanName.A, MedigapPlanName.B]},
  {name: PlanCategory.Comprehensive, plans: [MedigapPlanName.C, MedigapPlanName.D, MedigapPlanName.F, MedigapPlanName.G]},
  {name: PlanCategory.CostShare, plans: [MedigapPlanName.K, MedigapPlanName.L, MedigapPlanName.M, MedigapPlanName.N]},
  {name: PlanCategory.HighDeductible, plans: [MedigapPlanName.Hdf, MedigapPlanName.Hdg]},
];

export const getLabelForCategory = (cat: PlanCategory, className?: string, skipTitle?: boolean, titleClassName?: string) => {
  switch (cat) {
    case PlanCategory.MostPopular: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan F, G and N</Typography>}
      <ul className={className}>
        <li>These plans are selected by over 80% of all Medigap enrollees</li>
        <li>Plan F is no longer available to newly eligible Medicare beneficiaries who became eligible after January 1, 2020</li>
      </ul>
    </>;
    case PlanCategory.CoreBenefits: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan A and B</Typography>}
      <ul className={className}>
        <li>Basic benefits such as coverage for your 20% coinsurance for Part B and hospital costs after first 60 days</li>
        <li>Plan B includes coverage for the Part A hospital deductible of $1,484 which covers first 60 days of each stay in hospital</li>
      </ul>
    </>;
    case PlanCategory.Comprehensive: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan C, D, F and G</Typography>}
      <ul className={className}>
        <li>Medium-to-high supplemental coverage for out-of-pocket costs</li>
        <li>You pay a higher monthly premium in exchange for more comprehensive healthcare coverage and lower out-of-pocket costs</li>
      </ul>
    </>;
    case PlanCategory.CostShare: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan K, L, M and N</Typography>}
      <ul className={className}>
        <li>Cost-sharing plans offering lower monthly premiums</li>
        <li>For Plans K, L and M, you pay 25-50% of costs.  Once an annual out-of-pocket limit is reached, these plans pay 100% of costs</li>
        <li>For Plan N, you pay $0  except for some copays for doctor visits and emergency room visits</li>
      </ul>
    </>;
    case PlanCategory.HighDeductible: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan F and G (high deductible)</Typography>}
      <ul className={className}>
        <li>You pay a deductible of for $2,370 before the plan begins to pay.</li>
        <li>Once the deductible is met, the plan covers 100% of the costs like a regular Plan F and G</li>
      </ul>
    </>;
    default: return <></>;
  }
}
