import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, createStyles, Grid, RadioGroup, Theme, Typography} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: '14px 20px',
      border: '1px solid #CCD8DC',
      height: 454
    },
    medium: {
      fontWeight: 500,
    },
    centered: {
      textAlign: 'center'
    },
    list: {
      marginBlockStart: 0,
      paddingInlineStart: '20px',
      '& li:last-child': {
        paddingTop: 6
      }
    }
  }),
);

const formName = 'STEP_2a1';

function Step2a1(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [, dispatch] = React.useContext(QuestionnaireContext);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step3)
  };

  return <>
    <div className={'h-32'} />
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <div className={classes.card}>
          <Typography variant={'h3'} color={'textPrimary'} className={clsx(classes.medium, classes.centered)}>Original Medicare</Typography>
          <Box mt={'7px'} mb={'18px'}>
            <Typography variant={'body2'} color={'textSecondary'} className={classes.centered}>Default Medicare coverage that <br /> includes Medicare Part A and B.</Typography>
          </Box>
          <Param icon={'/img/green-check.svg'}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part A (Hospital insurance)</Typography>} />
          <Param icon={'/img/green-check.svg'}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part B (Medical insurance)</Typography>} />
          <Param icon={'/img/remove-round.svg'}
                 subtitle={<Typography variant={'h5'} color={'textPrimary'}>You can buy a <strong>separate</strong> drug plan</Typography>}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part D (Prescription Drug)</Typography>} />
          <Param icon={'/img/remove-round.svg'}
                 subtitle={<Typography variant={'h5'} color={'textPrimary'}>Like vision, dental, hearing</Typography>}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Extra benefits</Typography>} />
          <Box mt={'7px'}>
            <Typography variant={'body2'} color={'textPrimary'}><strong>Other details:</strong></Typography>
            <ul className={classes.list}>
              <li>
                <Typography variant={'body2'} color={'textPrimary'}>
                  You can purchase a separate <strong>Medicare Supplement (Medigap)</strong> policy to help pay for out-of-pocket costs under Original Medicare.
                </Typography>
              </li>
              <li>
                <Typography variant={'body2'} color={'textPrimary'}>
                  You can use any doctor or hospital that accepts Medicare in the U.S.
                </Typography>
              </li>
            </ul>
          </Box>
        </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <div className={classes.card}>
          <Typography variant={'h3'} color={'textPrimary'} className={clsx(classes.medium, classes.centered)}>Medicare Advantage</Typography>
          <Box mt={'7px'} mb={'18px'}>
            <Typography variant={'body2'} color={'textSecondary'} className={classes.centered}>All-in-one “bundled” alternative to <br />Original Medicare.</Typography>
          </Box>
          <Param icon={'/img/green-check.svg'}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part A (Hospital insurance</Typography>} />
          <Param icon={'/img/green-check.svg'}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part B (Medical insurance)</Typography>} />
          <Param icon={'/img/green-check.svg'}
                 subtitle={<Typography variant={'h5'} color={'textPrimary'}>Typically <strong>included</strong> with plan</Typography>}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Part D (Prescription Drug)</Typography>} />
          <Param icon={'/img/green-check.svg'}
                 subtitle={<Typography variant={'h5'} color={'textPrimary'}>Like vision, dental, hearing</Typography>}
                 title={<Typography variant={'body2'} color={'textPrimary'}>Extra benefits</Typography>} />
          <Box mt={'7px'}>
            <Typography variant={'body2'} color={'textPrimary'}><strong>Other details:</strong></Typography>
            <ul className={classes.list}>
              <li>
                <Typography variant={'body2'} color={'textPrimary'}>
                  You typically pay for healthcare services “as you go” — all plans have a maximum out-of-pocket cost cap.
                </Typography>
              </li>
              <li>
                <Typography variant={'body2'} color={'textPrimary'}>
                  In many cases, you’ll need to use doctors in a plan’s network (HMO).  Some plans cover out-of-network (PPO).
                </Typography>
              </li>
            </ul>
          </Box>
        </div>
      </Grid>
    </Grid>
    <Box className={commonClasses.buttonWrapper} mt={'40px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={onSubmit}>Got it. Let’s continue!</AscendButton>
    </Box>
  </>;
}

export default withTitle(
  Step2a1,
  () => 'Since you’re new to Medicare, let’s go over\nsome of the basics.',
  'There are two ways to receive your Medicare coverage.'
);

const Param = (props: {title: JSX.Element, subtitle?: JSX.Element, icon: string}) => <Box display={'flex'} alignItems={'center'} mb={'15px'}>
  <img width={20} height={20} src={props.icon}/>
  <Box ml={'5px'}>
    {props.title}
    {props.subtitle}
  </Box>
</Box>
