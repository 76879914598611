import React from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  createStyles,
  IconButton,
  MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {Cookies} from "react-cookie";
import {colors} from "../../../../shared/AppTheme";
import {QuestionnaireContext} from "../../../../Questionnaire/QuestionnaireContextProvider";
import AscendModal from "../../../../shared/components/AscendModal";
import FormTextInput from "../../../../shared/components/FormTextInput";
import FormSelectInput from "../../../../shared/components/FormSelectInput";
import clsx from "clsx";
import useGetCounty from "../../../../Questionnaire/hooks/useGetCounty";
import AscendButton from "../../../../shared/components/AscendButton";
const cookie = new Cookies();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 700,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      marginTop: 8,
    },
    lastName: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginLeft: 16,
      },
    },
    email: {
      marginTop: 17,
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      },
    },
    zipContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    content: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 40,
    }
  })
);

const Schema = yup.object().shape({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
});

interface FormState {
  zip: string;
}

type UpdateZipModalProps = {
  title: string,
  subtitle?: string,
  buttonLabel?: string,
  onClose: (zip?: string, county?: string) => void,
  open: boolean,
  disableBackdropClick?: boolean,
  disableSidePadding?: boolean,
  county?: string,
  zip?: string
}

export default function UpdateZipModal(props: UpdateZipModalProps) {
  const classes = useStyles();
  const {register, handleSubmit, errors, formState, watch, getValues} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
      zip: props.zip,
    }
  });
  watch()
  const [getCounty, data] = useGetCounty();
  const [prevSearchZip, setPrevSearchZip] = React.useState<string | undefined>(props.zip);
  const [county, setCounty] = React.useState<string | undefined>(props.county);
  const inputRef = React.useRef(null);

  const counties = React.useMemo(() => {
    return data.data?.plans_zip_county_fips || [];
  }, [data.data, data.called])

  React.useEffect(() => {
    if (data.data?.plans_zip_county_fips && data.data?.plans_zip_county_fips.length === 1) {
      setCounty(data.data?.plans_zip_county_fips[0].countyname)
    }
  }, [data.data, data.called]);


  React.useEffect(() => {
    const zip = getValues().zip;
    if (zip && zip.length === 5 && zip != prevSearchZip) {
      setCounty(undefined)
      getCounty({variables: {zip}})
      setPrevSearchZip(zip)
    }
  }, [getValues().zip])

  React.useEffect(() => {
    if (props.open && props.zip && props.zip.length === 5) {
      getCounty({variables: {zip: props.zip}})
    }
  }, [props.open])

  const onSubmit = (values: FormState) => {
    props.onClose(values.zip, county)
  };

  React.useEffect(() => {
    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  }, [inputRef.current])

  return <AscendModal
    width={584}
    open={props.open}
    onClose={() => props.onClose()}
    disableBackdropClick={props.disableBackdropClick}
    disableSidePadding={props.disableSidePadding}
  >
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} className={classes.form}>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>{props.title}</Typography>
      <div className={'h-16'} />
      {props.subtitle && <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>{props.subtitle}</Typography>}
      <Box className={classes.container}>
        <Box className={classes.zipContainer}>
          <Box display={'flex'}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           fullWidth
                           autoFocus
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           defaultValue={props.zip}
                           inputRef={(e) => {
                             register(e);
                             inputRef.current = e;
                           }}
                           name={'zip'}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter your zip code'} />
          </Box>
          <Box display={'flex'}>
            {data.loading && <Box ml={'20px'} mt={'40px'}><CircularProgress size={30} /></Box>}
            {(!data.loading && data.called) && <>
              {counties.length === 0 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>We can't find your county</Typography></Box></Box>}
              {counties.length === 1 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>{counties[0].countyname + ' County, ' + counties[0].state}</Typography></Box></Box>}
              {counties.length > 1 && <FormSelectInput
                fullWidth
                label={'Select county'}
                value={county}
                placeholder={'Select county'}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCounty(event.target.value as string);
                }}
              >
                {
                  counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
                }
              </FormSelectInput>}
            </>}
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} mt={'10px'} flexDirection={'column'} alignItems={'center'}>
        <AscendButton className={clsx({'gtm-step-event': true, buttonDisabled: !formState.isValid || !counties.length || counties.length > 1 && !county})}
                       variant={'contained'}
                       type="submit">{props.buttonLabel || 'Update'}</AscendButton>
      </Box>
    </form>
  </AscendModal>
}
