import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import clsx from "clsx";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import CollapseSection from "../../../shared/components/CollapseSection";
import TooltipedTitle from "./components/TooltipedTitle";
import StyledRating from "./components/StyledRating";
import {ExtraBenefit, MedicareQuoteFilterExtraBenefit, PlanYear, Rating, SnpType} from "../../../types";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendDivider from "../../../shared/components/AscendDivider";
import {colors} from "../../../shared/AppTheme";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import {ListItem, PlanFilterDataContext} from "./PlanFilterDataContext";
import FilterItems from "../shared/FilterItems";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";
import ErrorModal from "./components/ErrorModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    inputLabel: {
      fontSize: 14
    },
    input: {
      '& input': {
        padding: '18px 0 14px!important'
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    filterTitle: {
      fontSize: 14,
    },
    checkboxLabel: {
      fontSize: 12,
    }
  }),
);

export default function PlanFilter() {
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [drugErrorOpen, setDrugErrorOpen] = React.useState(false);
  const [dsnpOnErrorOpen, setDsnpOnErrorOpen] = React.useState(false);
  const [dsnpOffErrorOpen, setDsnpOffErrorOpen] = React.useState(false);
  const [maxPremium, setMaxPremium] = React.useState('');
  const [maxOutOfPocket, setMaxOutOfPocket] = React.useState('');
  const [planTypes, setPlanTypes] = React.useState<ListItem[]>([]);
  const [snpTypes, setSnpTypes] = React.useState<ListItem[]>([]);
  const [companies, setCompanies] = React.useState<ListItem[]>([]);
  const [extraBenefits, setExtraBenefits] = React.useState<ListItem[]>([]);
  const [maxOutOfPocketRanges, setMaxOutOfPocketRanges] = React.useState<ListItem[]>([]);
  const [maxCostRanges, setMaxCostRanges] = React.useState<ListItem[]>([]);
  const [partBPremiumReductionCount, setPartBPremiumReductionCount] = React.useState<number>(0);

  const {getPlanFilterData, planFilterData} = React.useContext(PlanFilterDataContext);

  useDebouncedEffect(() => {
    if (filter.values) {
      getPlanFilterData({
        variables: {
          filter: {...filter.values, planYear: PlanYear.Year2023}
        }
      });
    }
  }, 800, [filter.values]);

  React.useEffect(() => {
    if (planFilterData) {
      setCompanies(planFilterData.companies);
      setPlanTypes(planFilterData.planTypes);
      setSnpTypes(planFilterData.SNPTypes);
      setExtraBenefits(planFilterData.extraBenefits);
      setMaxCostRanges(planFilterData.maxCostRanges);
      setMaxOutOfPocketRanges(planFilterData.maxOutOfPocketRanges);
      setPartBPremiumReductionCount(planFilterData.partBPremiumReductionCount);
      window.scrollTo(0, 0)
    }
  }, [planFilterData]);

  if (!filter.values) {
    return null;
  }

  return <Box width={'100%'} style={{background: "#fff"}} className={'plan-filters'}>
    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography className={clsx(classes.title, classes.bold)} color={'textPrimary'}>Filter your results</Typography>
      <Typography variant={'body2'} className={clsx(classes.link)} onClick={filter.reset}>Reset filters</Typography>
    </Box>

    <DrugErrorModal open={drugErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
      }
      setDrugErrorOpen(false);
    }} />

    <DsnpOnErrorModal open={dsnpOnErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchSNPType(SnpType.DSnp)
      }
      setDsnpOnErrorOpen(false);
    }} />

    <DsnpOffErrorModal open={dsnpOffErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchSNPType(SnpType.DSnp)
      }
      setDsnpOffErrorOpen(false);
    }} />

   {/* <Box marginTop={'29px'}>
      <CollapseSection title={<TooltipedTitle title={'Monthly Budget'} tooltip={''}/>}>
        <FormTextInput label={'Maximum cost per month'}
                       inputLabelClass={classes.inputLabel}
                       value={maxPremium}
                       onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                         setMaxPremium(event.target?.value as string)
                       }}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">$</InputAdornment>,
                         endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                         classes: {root: classes.input}
                       }}
        />
        <Box mt={'32px'}>
          <Box mb={'16px'}>
            <TooltipedTitle title={'Max Out-of-Pocket'} tooltip={''}/>
          </Box>
          <FormTextInput label={'Total cap per year'}
                         inputLabelClass={classes.inputLabel}
                         value={maxOutOfPocket}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setMaxOutOfPocket(event.target?.value as string)
                         }}
                         InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                           classes: {root: classes.input}
                         }}
          />
        </Box>
      </CollapseSection>
    </Box>
    <Box marginTop={'24px'}>
      {!!maxCostRanges &&
      <FilterItemWithCheckbox items={maxCostRanges}
                              tooltip={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}
                              selectedKeys={filter.values.maxCostRangeNames || []} onChange={filter.switchMaxCostRanges}
                              title={'Estimated monthly cost'}/>
      }
    </Box>
    <Box marginTop={'24px'}>
      {!!maxOutOfPocketRanges &&
      <FilterItemWithCheckbox items={maxOutOfPocketRanges}
                              tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                              selectedKeys={filter.values.maxOutOfPocketRangeNames || []} onChange={filter.switchMaxOutOfPocketRanges}
                              title={'Max Out-of-Pocket'}/>
      }
    </Box>*/}
    <Box marginTop={'16px'}>
      <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={`CMS star rating (${GetPlansYear()})`} tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}/>}>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R5}
                        label={<StyledRating readOnly defaultValue={5}/>}
                        checked={filter.values.rating?.includes(Rating.R5)}
                        onChange={() => filter.switchRating(Rating.R5)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R4}
                        label={<StyledRating readOnly defaultValue={4}/>}
                        checked={filter.values.rating?.includes(Rating.R4)}
                        onChange={() => filter.switchRating(Rating.R4)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R3}
                        label={<StyledRating readOnly defaultValue={3}/>}
                        checked={filter.values.rating?.includes(Rating.R3)}
                        onChange={() => filter.switchRating(Rating.R3)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R2}
                        label={<StyledRating readOnly defaultValue={2}/>}
                        checked={filter.values.rating?.includes(Rating.R2)}
                        onChange={() => filter.switchRating(Rating.R2)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R1}
                        label={<StyledRating readOnly defaultValue={1}/>}
                        checked={filter.values.rating?.includes(Rating.R1)}
                        onChange={() => filter.switchRating(Rating.R1)}/>
      </CollapseSection>
    </Box>
    {/*<AscendDivider mt={'32px'}/>
    <Box marginTop={'24px'}>
      <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={'Referral Needed?'} tooltip={'A referral is a written order from your doctor (usually your primary care physician) for you to see a specialist doctor. Some Medicare Advantage plans (in particular, Health Maintenance Organization (HMO) plans) require you to receive a referral from your primary care physician before seeing a specialist. If you don\'t get a referral, you can be charged out of pocket for the specialist services.'}/>}>
        <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox} label="Yes" checked={true}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
        <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox} label="No" checked={false}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
      </CollapseSection>
    </Box>*/}
    <AscendDivider mt={'16px'}/>
    <Box marginTop={'16px'}>
      {!!planTypes &&
      <FilterItemWithCheckbox items={planTypes} selectedKeys={filter.values.planTypes || []}
                              onChange={type => filter.switchPlanType(type)}
                              tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'}
                              title={'Plan type'}/>
      }
    </Box>

    <Box marginTop={'16px'}>
      {!!snpTypes &&
      <FilterItemWithCheckbox items={snpTypes} selectedKeys={filter.values.SNPTypes || []}
                              onChange={type => {
                                /*if (filter.values?.SNPTypes.includes(SnpType.DSnp)) {
                                  setDsnpOffErrorOpen(true);
                                } else {
                                  setDsnpOnErrorOpen(true);
                                }*/
                                filter.switchSNPType(SnpType.DSnp)
                              }}
                              tooltip={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'}
                              title={'Special Needs Plans'}/>
      }
    </Box>

    <AscendDivider mt={'16px'}/>

    <Box marginTop={'16px'}>
      {!!extraBenefits &&
      <FilterItemWithCheckbox items={extraBenefits
                                      .filter(b => b.value === MedicareQuoteFilterExtraBenefit.DrugCoverage)
                                      .map(b => ({...b, label: 'Part D Drug Coverage'}))}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => {
                                filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
                                /*if (filter.values?.extraBenefits.includes(ExtraBenefit.DrugCoverage)) {
                                  setDrugErrorOpen(true)
                                } else {
                                  filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
                                }*/
                              }}
                              tooltip={'Indicates whether this plan also includes bundled prescription drug coverage.'}
                              title={'Prescription Drug Coverage'}/>
      }
    </Box>

    <AscendDivider mt={'16px'}/>

    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={[{label: 'Part B Premium Reduction available', value: MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback, disabled: !partBPremiumReductionCount}]}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => filter.switchExtraBenefits(type)}
                              tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                              title={'Part B Premium Reduction'}/>
    </Box>

    <AscendDivider mt={'16px'}/>
    <Box marginTop={'16px'}>
      {!!companies &&
      <FilterItemWithCheckbox items={companies} selectedKeys={filter.values.companies || []}
                              onChange={type => filter.switchCompany(type)}
                              tooltip={'The name of the parent company issuing the plan.'}
                              title={'Company'}/>
      }
    </Box>

    <AscendDivider mt={'16px'}/>

    <Box marginTop={'16px'}>
      {!!extraBenefits &&
      <FilterItemWithCheckbox items={extraBenefits.filter(b => b.value !== MedicareQuoteFilterExtraBenefit.DrugCoverage && b.value !== MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback)}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => filter.switchExtraBenefits(type)}
                              tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                              title={'Extra benefits'}/>
      }
    </Box>
  </Box>
}


interface FilterItemWithCheckboxProps {
  items?: ListItem[],
  selectedKeys: string[],
  onChange: (key: any) => void,
  title: string,
  tooltip?: string
}

const FilterItemWithCheckbox = ({items, selectedKeys, onChange, title, tooltip}: FilterItemWithCheckboxProps) => {
  const classes = useStyles();

  return <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={title} tooltip={tooltip || ''}/>}>
    <FilterItems onChange={onChange} selectedKeys={selectedKeys} items={items}/>
  </CollapseSection>
}

const DrugErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see plans with no Prescription Drug coverage?'}
                     subtitle={'You may incur Late Enrollment Penalties'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>Penalties can apply if you do not have Medicare drug coverage (Part D) after you first become eligible for Medicare.  The only exception is if you have other qualifying (or 'creditable') coverage that provides drug coverage as good as Medicare's (such as from an employer, TRICARE or VA).
                       <br /><br />You must have drug coverage to avoid penalties even if you are not currently taking any prescription drugs.</Typography>}
                     buttonLabel={'Yes - I have other qualifying coverage '}
                     onClose={props.onClose}
                     open={props.open}/>
}

const DsnpOnErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see D-SNP plans?'}
                     subtitle={'You might not be eligible to enroll'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>Dual-eligible Special Needs Plans (D-SNP) are specials plan designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries. <br /><br /> You can not enroll in a D-SNP plan unless you are eligible for both Medicare and Medicaid.</Typography>}
                     buttonLabel={'Yes - I want to see D-SNP plans  '}
                     onClose={props.onClose}
                     open={props.open}/>
}

const DsnpOffErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see regular Medicare Advantage plans?'}
                     subtitle={'This will unselect the Dual-Eligible Special Needs Plans (D-SNP) filter'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>D-SNP plans are special plans designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries. <br/><br />
                       These plans provide you convenience by coordinating all your Medicare and Medicaid benefits into one simple health plan (unlike regular Medicare Advantage plans).</Typography>}
                     buttonLabel={'Yes - I want to see regular MA plans'}
                     onClose={props.onClose}
                     open={props.open}/>
}
