import React from 'react';
import LoginButton from "../../../shared/components/LoginButton";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {
  Box,
  ClickAwayListener,
  createStyles,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {config} from "../../../Enrollment/config/config";
import {keycloak} from "../../../shared/keycloak";
import {useUserProfileLazy} from "../../../Private/hooks/useUserProfile";
import {resetQuoteId} from "../../../shared/QuoteId";
import {AdminContext} from "./AdminContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      top: -6,
      right: -10,
      padding: 8,
      cursor: 'pointer',
    },
  }),
);


const LoginWidget = () => {
  const classes = useStyles();
  const {authenticated} = React.useContext(KeycloakContext);
  const [open, setOpen] = React.useState(false);
  const [getProfile, {data}] = useUserProfileLazy();

  const adminContext = React.useContext(AdminContext);

  React.useEffect(() => {
    if (authenticated && !adminContext) {
      getProfile()
    }
  }, [authenticated, adminContext])

  const userName = React.useMemo(() => {
    return data?.userProfile?.firstName;
  }, [data?.userProfile?.firstName])

  const TooltipContent = () => {

    return <Box position={'relative'} p={'12px 60px 12px 21px'}>
      <IconButton onClick={() => setOpen(false)} className={classes.icon}><img width={25} height={25} src={'/img/close.svg'}/></IconButton>
      <Typography color={'textPrimary'} className={'bold fs-16'}>Welcome, {userName}!</Typography>
      <div className={'h-12'} />
      {/*<Typography color={'textPrimary'}
                  onClick={() => {
                    setOpen(false);
                    document.location.href = config.privateUrl as string;
                  }}
                  className={'pointer fs-16'}>My Dashboard</Typography>*/}
      <Typography color={'textPrimary'}
                  onClick={() => {
                    setOpen(false);
                    keycloak.logout();
                    resetQuoteId();
                  }}
                  className={'pointer fs-16'}>Log out</Typography>
    </Box>
  }

  return <>
    {authenticated && <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <LightTooltip
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<TooltipContent />}
          arrow
          interactive
          placement="right"
        >
          <StyledButton variant={'contained'} onClick={() => setOpen(true)}>My Account</StyledButton>
        </LightTooltip>
      </div>
    </ClickAwayListener>}
    {!authenticated && <LoginButton/>}
  </>
}

export default LoginWidget;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.white,
    },
  },
}))(Tooltip);

const StyledButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '5px 7px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

