import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";
import React from "react";
import {IQuestionnaireState} from "../../../Questionnaire/QuestionnaireContextProvider";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../../Private/hooks/useUserProfile";
import {AdminContext} from "./AdminContext";
import {getQuoteId, listenQuoteChange, setQuoteId} from "../../../shared/QuoteId";
import useSaveRequest from "../../../Questionnaire/hooks/useSaveRequest";
import * as _ from 'lodash';
import {keycloak, KeycloakRoles} from "../../../shared/keycloak";
import {MutationHookOptions} from "@apollo/react-hooks";
import {usePatchClientPersonalInfo} from "./hooks/usePatchClientPersonalInfo";

export const QuoteContext = React.createContext<IQuestionnaireState &
  {save: (data: any) => any,
  saveClientInfo: (data: MutationHookOptions<{ patchClientPersonalInfo: boolean }, any>) => any,
  }>({save: () => {}, saveClientInfo: () => null,});

export function QuoteProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const adminContext = React.useContext(AdminContext);

  const [save] = useSaveRequest((data: any) => {
    setQuoteId(data.saveMedicareQuote.maQuoteID)
    return getRequest({variables: {id: data.saveMedicareQuote.maQuoteID}});
  });

  const [saveClientInfo, {loading}] = usePatchClientPersonalInfo({
    onCompleted: () => {
      const quoteId = getQuoteId();
      if (quoteId) {
        getRequest({ variables: { id: quoteId } })
      }
    }
  })

  React.useEffect(() => {
    if (adminContext?.clientView?.quoteId) {
      setQuoteId(adminContext?.clientView?.quoteId)
    }
  }, [adminContext?.clientView?.quoteId]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.quoteId || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.quoteId]);

  const [questionnaire, setQuestionnaire] = React.useState<IQuestionnaireState>({id: quoteId});

  const {authenticated} = React.useContext(KeycloakContext);
  const [getProfile, {data: profileData}] = useUserProfileLazy();

  const [getRequest] = useQuestionnaireRequest(data => {
    if (!_.isEqual(questionnaire, data)) {
      setQuestionnaire(data);
    }
  });

  React.useEffect(() => {
    listenQuoteChange(callback)
  }, []);

  React.useEffect(() => {
    callback(quoteId);
  }, [quoteId, authenticated]);

  const callback = React.useCallback((quoteId?: string) => {
    if (adminContext && quoteId) {
      getRequest({variables: {id: quoteId}});
    } else {
      const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN);
      if ((authenticated && !isAdmin) || props.private) {
        getProfile()
      }
      if (!authenticated && quoteId) {
        getRequest({variables: {id: quoteId}});
      }
    }
  }, [authenticated, adminContext]);

  React.useEffect(() => {
    if (profileData?.userProfile?.quoteId) {
      setQuoteId(profileData?.userProfile?.quoteId);
      getRequest({variables: {id: profileData.userProfile.quoteId}});
    }
  }, [profileData])

  const result = React.useMemo(() => {
      return {...questionnaire, save, saveClientInfo}
  }, [save, questionnaire, saveClientInfo]);

  return <QuoteContext.Provider value={result}>
    {props.children}
  </QuoteContext.Provider>

}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}
