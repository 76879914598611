import React from 'react';
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {client, setErrorHandler} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import Questionnaire from "./Questionnaire";
import Home from "./Home";
import ResultsTypeSelection from "./ResultsTypeSelection";
import ZipCode from "./ZipCode";

function App() {
  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route exact path={paths.questionnaire} component={Questionnaire} />
          <Route exact path={paths.zip} component={ZipCode} />
          <Route exact path={paths.resultsType} component={ResultsTypeSelection} />
          <Route path={paths.questionnaire + '/:routeName'} component={Questionnaire} />
          <Route exact path={paths.home} component={Home} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
