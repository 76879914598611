import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationSaveMedigapFilterStateArgs} from "../../../../medigap-types";

export default function useSaveMedigapFilterState(options?: MutationHookOptions<{saveMedigapFilterState: string}, MutationSaveMedigapFilterStateArgs>) {
  return useMutation<{saveMedigapFilterState: string}, MutationSaveMedigapFilterStateArgs>(
    gql(request),
    {
      ...options
    }
  );
}


const request = `
mutation ($input: MedigapFilterStateInput!) {
  saveMedigapFilterState(input: $input)
}
`;
