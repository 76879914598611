import React from "react";
import {Typography} from "@material-ui/core";

const PlanParam = React.memo((props: PlanParamProps) => <div className={`mt-5 ${props.className}`}>
  {props.label && <Typography className={`fs-11 ${props.labelClassName}`} color={'textSecondary'}>{props.label}:</Typography>}
  <Typography variant={'h5'} className={`medium dark-green ${props.valueClassName}`}>{props.value}</Typography>
</div>);

interface PlanParamProps {
  value: string | JSX.Element
  label?: string,
  className?: string,
  valueClassName?: string,
  labelClassName?: string,
}

export {PlanParam};
