import React from "react";
import {Typography} from "@material-ui/core";
import Icon from "../../../shared/components/Icon";

const PlanParam = React.memo((props: PlanParamProps) => <div className={`mt-5 ${props.className}`}>
  {props.label && <Typography className={`fs-11 ${props.labelClassName}`} color={'textSecondary'}>{props.label}:</Typography>}
  {typeof props.value === 'string' && <Typography variant={'h5'} className={`medium dark-green ${props.valueClassName}`}>{props.value}</Typography>}
  {typeof props.value === 'boolean' && <Icon value={props.value} />}
</div>);

interface PlanParamProps {
  value: string | JSX.Element | boolean,
  label?: string,
  className?: string,
  valueClassName?: string,
  labelClassName?: string,
}

export {PlanParam};
