import React from "react";
import {Box, createStyles, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps} from "react-router";
import useCommonStyles from "../shared/useCommonStyles";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {useHistory} from "react-router-dom";
import AscendDivider from "../shared/components/AscendDivider";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import paths from "./config/router-paths";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser} from "../shared/StoredUser";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import AscendCard from "../shared/components/AscendCard";
import AscendCheckbox from "../shared/components/AscendCheckbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    }
  })
);

function Enroll(props: RouteComponentProps<{planId: string, zip: string, countyName: string}>) {
  const {planId, zip, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const {authenticated} = React.useContext(KeycloakContext);

  const [getPlan, {data}] = usePlanLazy();

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])

  const quoteId = React.useMemo(() => {
    const user = getStoredUser();
    if (user) {
      return user.quoteId;
    }
    return undefined
  }, [getStoredUser()])

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          countyName,
          zip,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, quoteId])

  const startApplication = () => {
    const url = '/' + planId + '/' + zip + '/' + countyName + (quoteId ? '/' + quoteId : '');
    console.log(url);
    if (authenticated || getStoredUser()?.email) {
      history.push(paths.personalDetails + url);
    } else {
      history.push(paths.createAccount + url);
    }
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'}>
      {plan && <>
        <Typography className={'fs-18 bold'} color={'textSecondary'}>Here’s the plan you selected:</Typography>
        <div className={'h-16'} />
        <ShortPlanCard plan={plan}/>
        <div className={'h-40'} />
      </>}
      <Typography className={'fs-18 bold'} color={'textSecondary'}>This plan has Optional Supplemental Benefits. Would you like to add these to your application?</Typography>
      <BenefitCard active={false} title={'MyOption Dental High PPO'} premium={'$23.20'}/>
      <BenefitCard active={true} title={'MyOption Platinum Dental'} premium={'$30.50'}/>
      <div className={'h-24'} />

      <div className={'text-align-centered'}>
        <Typography className={'fs-18 bold'} color={'textSecondary'}>We need to collect some more information to complete your secure enrollment. You’ll also need some details from your red, white and blue Medicare card.</Typography>
        <div className={'h-40'} />
        <AscendButton variant={'contained'} onClick={startApplication}>Start application</AscendButton>
      </div>
      <AscendDivider my={'32px'} className={classes.divider} />
      <Box display={'flex'} alignItems={'center'} className={'mb-24'}>
        <img src={'/img/shield-green.svg'} className={'w-45 h-45'} />
        <div className={'ml-16'}>
          <Typography className={'fs-26 bold'} color={'textPrimary'}>Secure application</Typography>
          <Typography className={'fs-16'} color={'textPrimary'}>Your information is secure on our HIPAA-compliant data servers with bank-level data encryption.</Typography>
        </div>
      </Box>
      <Box display={'flex'} alignItems={'center'} className={'mb-24'}>
        <img src={'/img/computer.svg'} className={'w-45 h-45'} />
        <div className={'ml-16'}>
          <Typography className={'fs-26 bold'} color={'textPrimary'}>Apply digitally</Typography>
          <Typography className={'fs-16'} color={'textPrimary'}>Complete the whole application from your tablet or laptop, no need to print for a signature.</Typography>
        </div>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <img src={'/img/support-green.svg'} className={'w-45 h-45'} />
        <div className={'ml-16'}>
          <Typography className={'fs-26 bold'} color={'textPrimary'}>Support available</Typography>
          <Typography className={'fs-16'} color={'textPrimary'}>Licensed CoverRight agents will help you complete your application.</Typography>
        </div>
      </Box>
      <AscendDivider mt={'32px'} className={classes.divider} />
    </Box>
    <Box display={'flex'} justifyContent={'space-between'} mt={'40px'} mb={'100px'}>
      <AscendButton variant={'contained'} onClick={() => {
        history.goBack()
      }} color={'secondary'}>Back to plans</AscendButton>
      <AscendButton className={'gtm-step-event'} variant={'contained'} onClick={startApplication}>Start application</AscendButton>
    </Box>
  </>
}

interface BenefitCardProps {
  title: string;
  premium: string;
  active: boolean;
}

const BenefitCard = (props: BenefitCardProps) => {
  return <AscendCard className={'mt-16'} padding={'17px 0 17px 32px'}>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'center'} flex={1} justifyContent={'space-between'}>
        <Typography className={'fs-16 bold'} color={'textPrimary'}>{props.title}</Typography>
        <Box width={'100%'} maxWidth={'200px'} mr={1}>
          <Typography className={'fs-16 bold'} color={'textPrimary'}>Premium {props.premium}</Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16 medium underline pointer'} color={'textPrimary'}>See details</Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} width={'130px'} className={'pointer'}>
          <Typography className={'fs-16 bold underline green'}>{props.active ? 'Remove' : 'Add?'}</Typography>
          <AscendCheckbox variant={'standart'} label={''} checked={props.active} readOnly controlClassName={'p-0 fl-0'} />
        </Box>
      </Box>
    </Box>
  </AscendCard>
}

export default withTitle(
  Enroll,
  `Congratulations on selecting a new Medicare Advantage plan!`,
  ``,
  undefined,
  undefined,
  undefined,
  800
)
