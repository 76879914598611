import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Collapse, IconButton, Typography} from "@material-ui/core";
import {colors} from "../AppTheme";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    background: 'rgba(200, 232, 235, 1)',
    borderRadius: 4,
    borderLeft: '11px solid #029094',
    padding: '10px 17px 10px 7px',
    marginTop: 8,
    marginBottom: 8,
  },
  title: {
    fontWeight: 500,
  },
  description: {
    fontSize: 14,
    color: colors.text.primary,
  },
  lightIcon: {
    height: 17,
    width: 16,
    marginRight: 7,
    maxWidth: 'inherit'
  },
}));

type AscendInfoProps = {
  open: boolean,
  title?: string,
  wrapperClassName?: string,
  descriptionClassName?: string,
  description: string | JSX.Element
}

export default function AscendInfo(props: AscendInfoProps) {
  const classes = useStyles();
  return (
    <Collapse in={props.open}>
      <Box className={clsx(classes.wrapper, props.wrapperClassName)}>
          <Box>
            <img className={classes.lightIcon} src={'/img/light.png'} />
          </Box>
        <div className={clsx(classes.description, props.descriptionClassName)}>{props.description}</div>
      </Box>
    </Collapse>
  );
}
