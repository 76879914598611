import React from "react";
import {
  Box,
  Collapse,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendTitle from "../../shared/components/AscendTitle";
import AscendRadio from "../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import AscendInfo from "../../shared/components/AscendInfo";
import clsx from "clsx";
import useCommonStyles from "../../shared/useCommonStyles";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {ProviderOutput} from "../../types";
import {DoctorSelection} from "../../shared/components/DoctorSelection";
import useDoctorsByRequestId from "../../Maketplace/MedicareAdvantage/PlanList/hooks/useDoctorsByRequestId";
import {getQuoteId} from "../../shared/QuoteId";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {questionsRoutes} from "../hooks/useStepperState";
import {withTitle} from "../../shared/components/withTitle";

const useStyles = makeStyles({
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 22,
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 20
  },
  addDoctorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px dashed #D6DFDA',
    background: '#F5F7F6',
    borderRadius: 8,
    width: '100%',
    paddingTop: 27,
    paddingBottom: 23,
    color: colors.custom.green.variant1,
    fontWeight: 700,
    fontSize: 16,
  }
});

const formName = 'STEP_10';

function Step10(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();
  const [selectedDoctors, setSelectedDoctors] = React.useState<ProviderOutput[]>([]);
  const [showAutocomplete, setShowAutocomplete] = React.useState(true); // todo replace this in component

  const {doctors} = useDoctorsByRequestId(getQuoteId());

  React.useEffect(() => {
    if (doctors.length) {
      setSelectedDoctors(doctors);
      setShowAutocomplete(false);
    }
  }, [doctors])

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {preferredDoctors: selectedDoctors, stateKey: 'preferredDoctors', form: formName, preferredDoctorsFilled: true}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step11)
  };

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'40px'}>
      <Box display={'flex'} flexDirection={'column'} flex={1} mt={'30px'}>
        {!!selectedDoctors.length && <Box mb={'23px'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Your selected doctors:</Typography>
        </Box>}
        <DoctorSelection selectedDoctors={selectedDoctors}
                         zip={state.personalDetails?.zip as string}
                         setSelectedDoctors={setSelectedDoctors}
                         showAutocomplete={showAutocomplete}
                         setShowAutocomplete={setShowAutocomplete}
        />
      </Box>
      {showAutocomplete && <Box mt={'-10px'}>
        <Typography variant={'h4'} color={'textSecondary'} className={classes.title}>Can’t find your doctor? Call us and we can help.</Typography>
      </Box>}
      <div className={'h-10'} />
      <AscendInfo open={true}
                  description={
                    <p style={{ marginTop: 0, marginBottom: 0, color: colors.text.primary}}><strong>Doctor networks change all the time.</strong> We update our information regularly, but changes sometimes happen faster than insurers can update us.  We will aim to make sure to double check your doctors are covered before we enroll you.</p>}/>
    </Box>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    disabled={!selectedDoctors.length}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step11)
      }}>Click here to skip this step</Typography>
    </Box>
  </>
}

export default withTitle(
  Step10,
  'Are there any doctors that you would like\ncovered by your new plan?',
  'Doctors don’t work with every plan. We’ll show you plans that include them.'
)
