import React from "react";
import {
  Box,
  CircularProgress,
  createStyles,
  MenuItem,
  Theme,
  Typography, useMediaQuery, useTheme,
} from "@material-ui/core";
import FormTextInput from "../../shared/components/FormTextInput";
import AscendButton from "../../shared/components/AscendButton";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import FormSelectInput from "../../shared/components/FormSelectInput";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {withTitle} from "../../shared/components/withTitle";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";
import AscendTitle from "../../shared/components/AscendTitle";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import SearchingPlanModal from "../components/modals/SearchingPlanModal";
import {Step2aAnswers} from "./Step2a";
import {Step1Answers} from "./Step1";
import {Step2cAnswers} from "./Step2c";
import {Step2bAnswers} from "./Step2b";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      marginTop: 40,
    },
    lastName: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginLeft: 16,
      },
    },
    email: {
      marginTop: 17,
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      },
    },
    title: {
      fontWeight: 500,
      marginTop: 20,
      textAlign: 'center',
      color: colors.custom.green.variant1
    },
    zipContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    content: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 40,
    },
    input: {
      paddingRight: 0
    }
  }),
);

const Schema = yup.object().shape({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
});

interface PersonalDetailsFormState {
  zip: string;
}


const formName = 'PERSONAL_DETAILS';

function Step3(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();
  const [getCity, data] = useLazyQuery<ICityResponse, {zip: string}>(gql(cityRequest));
  const [county, setCounty] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const ref = React.useRef(null);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    getStoredUser()?.birthDate ? moment(getStoredUser()?.birthDate).toDate() : null
  );
  const {register, handleSubmit, errors, formState, watch, getValues} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
      zip: state.personalDetails?.zip,
    }
  });
  watch()

  React.useEffect(() => {
    const zip = getValues().zip;
    if (zip && zip.length === 5) {
      setCounty(undefined)
      getCity({variables: {zip}})
    }
  }, [getValues().zip])

  const cities = React.useMemo(() => {
    return data.data?.plans_zip_county_fips || [];
  }, [data.data, data.called])

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  React.useEffect(() => {
    if (isDesktop && ref) {
      (ref.current as any).focus();
    }
  }, [isDesktop])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (values) {
      dispatch({
        type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'personalDetails', form: formName, ...values}
      })
      setStoredUser({...getStoredUser(), birthDate: moment(selectedDate).format('MM/DD/YYYY'), zip: values.zip, countyName: county})
      setOpen(true);
    }
  };

  const onModalClose = () => {
    setOpen(false);
    const ageInMounths = Math.floor(moment.duration(moment().diff(moment(selectedDate))).asMonths())
    if (state?.STEP_1?.value === Step1Answers.FirstTime || state?.STEP_2c?.value === Step2cAnswers.LeftEmployer) {
      if (state?.STEP_2c?.value === Step2cAnswers.LeftEmployer) {
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a1)
      } else if (state?.STEP_2a?.value !== Step2aAnswers.None && state?.STEP_2b?.value !== Step2bAnswers.None) {
        if (ageInMounths < 777) { // less 64y9m
          props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a2)
        } else if (ageInMounths >= 777 && ageInMounths < 784) { // over 64y9m, less 65y3m
          props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a1)
        } else { // over 65y3m
          props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a3)
        }
      } else {
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step8)
      }
    } else {
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step8)
    }
  }

  return <>
    <SearchingPlanModal countyName={cities[0]?.countyname + ' County, ' + cities[0]?.state} zip={getValues().zip} open={open} onClose={onModalClose} />
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} className={classes.form}>
      <Box className={classes.container}>
        <Box className={classes.zipContainer}>
          <Box display={'flex'}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           fullWidth
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           defaultValue={state.personalDetails?.zip}
                           inputRef={(e) => {
                             register(e);
                             ref.current = e;
                           }}
                           label={'Zip Code'}
                           name={'zip'}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
          </Box>
          <Box display={'flex'}>
            {data.loading && <Box ml={'20px'} mt={'40px'}><CircularProgress size={30} /></Box>}
            {(!data.loading && data.called) && <>
              {cities.length === 0 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>We can't find your county</Typography></Box></Box>}
              {cities.length === 1 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>{cities[0].countyname + ' County, ' + cities[0].state}</Typography></Box></Box>}
              {cities.length > 1 && <FormSelectInput
                fullWidth
                label={'Select county'}
                value={county}
                placeholder={'Select county'}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCounty(event.target.value as string);
                }}
              >
                {
                  cities.map(city => <MenuItem key={city.countyname} value={city.countyname}>{city.countyname}</MenuItem>)
                }
              </FormSelectInput>}
            </>}
          </Box>
          <AscendTitle title={'Date of birth'} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              InputAdornmentProps={{
                classes: {
                  root: commonClasses.hidden
                }
              }}
              InputProps={{
                classes: {
                  root: classes.input
                }
              }}
              placeholder={'MM / DD / YYYY'}
              value={selectedDate}
              invalidDateMessage={'Please enter a valid date'}
              onChange={setSelectedDate}
              TextFieldComponent={FormTextInput as any}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box className={commonClasses.buttonWrapper} width={'100%'} mt={'30px'}>
        <AscendButton variant={'contained'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} id={formName}
                      disabled={!formState.isValid || !moment(selectedDate).isValid() || !cities.length || cities.length > 1 && !county}
                      variant={'contained'} type="submit">Continue</AscendButton>
      </Box>
    </form>
  </>
}

export default withTitle(
  Step3,
  () => 'Great! Now let’s first confirm your zip code\n and county.',
  'Plans can vary by zip code so we’ll check to see how many plans are\n available to you.'
)


interface ICityResponse {
  plans_zip_county_fips: {
    city: string,
    state: string,
    countyname: string
  }[]
}

const cityRequest: string = `
  query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`
