import React from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedicareQuoteOutput} from "../../types";
import {IQuestionnaireState} from "../../Questionnaire/QuestionnaireContextProvider";

export default function useQuestionnaireRequest(onCompleted?: (data: IQuestionnaireState) => void) {
  return useLazyQuery<IQuestionnaireRequestResponse, {id: string}>(
    gql(query),
    {
      onCompleted: (data: IQuestionnaireRequestResponse) => {
        if (onCompleted) {
          onCompleted(toState(data.medicareQuote));
        }
      },
      fetchPolicy: 'no-cache'
    }
  );
}

export interface IQuestionnaireRequestResponse {
  medicareQuote: MedicareQuoteOutput
}

const toState = (data?: MedicareQuoteOutput): IQuestionnaireState => {
  let state = {
    id: data?.id,
    pin: data?.pin,
    clientId: data?.clientId,
    favorites: data?.favorites,
    drugDeliveryType: data?.drugDeliveryType || undefined,
    personalDetails: {
      zip: data?.zip || undefined,
      countyName: data?.county || undefined,
    },
    filters: data?.filters || undefined,
    enrolled: {
      enrolled: data?.enrolled || undefined,
      enrolledFilled: data?.enrolledFilled || undefined,
      coverageFilled: data?.coverageFilled || undefined,
      coverage: data?.coverage || undefined,
    },
    governmentAssistant: {
      governmentAssistant: data?.governmentAssistant || undefined,
    },
    workInfo: {
      employed: typeof data?.employed === 'undefined' ? undefined : data?.employed,
      spouseEmployed: typeof data?.spouseEmployed === 'undefined' ? undefined : data?.spouseEmployed,
      hasMore20: typeof data?.hasMore20 === 'undefined' ? undefined : data?.hasMore20,
    },
    medicaid: {
      receivesLIS: typeof data?.receivesLIS === 'undefined' ? undefined : data?.receivesLIS,
      receivesMedicalSupport: typeof data?.receivesMedicalSupport === 'undefined' ? undefined : data?.receivesMedicalSupport,
    },
    receivedBenefits: {
      receivedBenefits: data?.receivedBenefits === null ? undefined : data?.receivedBenefits,
      chronicIllness: data?.chronicIllness === null ? undefined : data?.chronicIllness,
      prepayCoverage: data?.prepayCoverage === null ? undefined : data?.prepayCoverage,
      travelMoreThan30DaysAYear: data?.travelMoreThan30DaysAYear === null ? undefined : data?.travelMoreThan30DaysAYear,
      doctorsWithinNetwork: data?.doctorsWithinNetwork === null ? undefined : data?.doctorsWithinNetwork,
    },
    preferredPharmacies: {
      preferredPharmacies: data?.preferredPharmacies || undefined,
      preferredPharmaciesFilled: data?.preferredPharmaciesFilled || undefined,
    },
    preferredDoctors: {
      preferredDoctors: data?.preferredDoctors || undefined,
      preferredDoctorsFilled: data?.preferredDoctorsFilled || undefined,
    },
    preferredDrugs: {
      preferredDrugs: data?.preferredDrugs || undefined,
      preferredDrugsFilled: data?.preferredDrugsFilled || undefined,
    },
    medicareOptions: {
      medicarePlanType: data?.medicarePlanType || undefined,
    },
    doctorVisitsNumber: data?.doctorVisitsNumber || undefined,
    specialistVisitsNumber: data?.specialistVisitsNumber || undefined,
  }

  if (!!data?.customAnswers) {
    const customAnswers = JSON.parse(data?.customAnswers as string);
    state = Object.assign(state, customAnswers);
  }

  return state;
}

const query = `query ($id: UUID!) {
  medicareQuote(id: $id) {
    abCase
    createdAt
    county
    employed
    pin
    id
    clientId
    favorites
    restrictProviderNetwork
    travelMoreThan30DaysAYear
    doctorsWithinNetwork
    work8years
    filters {
      SNPTypes
      companies
      extraBenefits
      maxCostRangeNames
      maxOutOfPocketRangeNames
      partBPremiumReduction
      planTypes
      rating
    }
    enrolled
    coverageFilled
    enrolledFilled
    drugDeliveryType
    hasMore20
    receivesMedicalSupport
    receivesLIS
    receivedBenefits
    governmentAssistant
    spouseEmployed
    spouseHasMore20
    zip
    doctorVisitsNumber
    specialistVisitsNumber
    coverage
    chronicIllness
    preferredPharmacies {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    medicarePlanType
    prepayCoverage
    customAnswers
    preferredDrugs {
      frequency
      genericName
      genericRxcui
      purchaseFrequency
      isGeneric
      name
      packName
      packRxcui
      quantity
      rxcui
    }
    preferredDoctors {
      npi
      addresses {
        addressLine1
        addressLine2
        id
        isPcp
        specialty
      }
      name
      addressesNumber
    }
  }
}`
