import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step3";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";

function Step3(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(QuoteContext);
  const [route, setRoute] = React.useState(questionsRoutes.step6);

  /*React.useEffect(() => {
    const abCase = localStorage.getItem('abCase');
    if (abCase) {
      if (abCase === 'b') {
        setRoute(questionsRoutes.step4);
      }
    } else {
      const cas = Math.random() > 0.5 ? 'a' : 'b';
      localStorage.setItem('abCase', cas);
      if (cas === 'b') {
        setRoute(questionsRoutes.step4);
      }
    }
  }, []);*/

  return <Shared quote={quote} dispatch={(data: any) => {
    dispatch({
      ...data,
      payload: {...data.payload/*, abCase: localStorage.getItem('abCase')*/}
    })
  }} onNextStep={() => {
    props.history.push(paths.MAquestionnaire + '/' + route);
  }}  {...props} />;
}

export default Step3 as any;
