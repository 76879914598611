import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useResetPassword() {
  return useMutation<any, {newPassword: string}>(gql(resetPasswordMutation), {
    client: client
  })
}

const resetPasswordMutation = `
mutation ($newPassword: String!) {
  resetPassword(newPassword: $newPassword)
}
`;
