import React from "react";
import {Box, Collapse, createStyles, Theme, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendCard from "../../../shared/components/AscendCard";
import {colors} from "../../../shared/AppTheme";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import AscendButton from "../../../shared/components/AscendButton";
import Benefit from "../../../shared/components/Benefit";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import AscendDivider from "../../../shared/components/AscendDivider";
import {getMedigapQuoteId, getQuoteId} from "../../../shared/QuoteId";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {FavoritesContext} from "../../shared/FavoritesContext";
import {QuoteContext} from "../../shared/QuoteContext";
import {config} from "../config/config";
import {MedigapPlanBenefit, MedigapPlanName, MedigapPlanOutput} from "../../../medigap-types";
import {addDetailsToPlan, MedigapPlanOutputWithDetails} from "../DetailedPlan/hooks/usePlansByBid";
import {PlanFilterContext} from "./PlanFilterContext";
import {getTooltipByBenefit} from "./PlanFilter";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import paths from "../config/router-paths";
import {getCompanyLogo, getMedigapCompanyLogo} from "../../../shared/utils";
import {PlanCategory} from "../Category/Category";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700,
    },
    wrapper: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 260,
      },
    },
    bigLogo: {
      height: 40,
      width: 120,
      minWidth: 120,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    logo: {
      height: 27,
      width: 96,
      minWidth: 96,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: 0,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    middleSection: {
      borderRight: '1px solid #E8ECED',
      borderLeft: '1px solid #E8ECED',
    },
    mobileBenefitImg: {
      marginTop: 3,
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 6px 10px',
      [theme.breakpoints.up('sm')]: {
        margin: '0 14px 10px 16px',
      },
    },
    mobileBenefitLabel: {
      width: '50%',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    toggle: {
      cursor: 'pointer',
      backgroundColor: '#029094',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    enrollSection: {
      padding: '16px 20px 11px 15px',
      margin: '16px -6px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 218,
        width: 218,
        padding: '12px 17px 12px 16px',
        margin: 0,
      },
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      position: 'relative',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      '& .MuiButton-label': {
        fontSize: 14,
      },
      width: '100%',
      padding: 9,
    },
    planEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap'
    },
    excl: {
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(0) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    green: {
      color: '#029094',
      opacity: 1,
    },
    red: {
      color: '#DD3D3D',
      opacity: 1,
    },
  }),
);

type PlanCardProps = {
  plans: MedigapPlanOutputWithDetails[],
  zip: string,
  countyName: string,
  hideEnroll?: boolean,
  hideButtons?: boolean,
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function PlanCard({plans, zip, countyName, hideEnroll, hideButtons} : PlanCardProps) {
  const classes = planStyles();
  const {preferredDrugs} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const ref = React.useRef(null);
  const {values: filterValues} = React.useContext(PlanFilterContext);
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();

  const onEnrollClick = (key: string) => {
    if ((window as any).track) {
      (window as any).track('MG marketplace - select plan', {source: 'V1', plan: key});
    }
    const quoteId = getMedigapQuoteId();
    document.location.href = `${config.enrollUrl}enroll/${key}/${zip}/${countyName}${quoteId ? '/' + quoteId : ''}?medigap&age=${filterValues?.age}&tobacco=${filterValues?.tobacco}&gender=${filterValues?.gender}&effectiveDate=${filterValues?.effectiveDate || '2021-07-12'}`;
  }

  const prices = React.useMemo(() => {
      let minPrice;
      let maxPrice;
      for (let i = 0; i < plans.length; i++) {
        if (!!plans[i].monthlyPremium) {
          const price = parseFloat(plans[i].monthlyPremium?.replace('$', '') as string);
          if (price < (minPrice || 99999999)) {
            minPrice = price;
          }
          if (price > (maxPrice || 0)) {
            maxPrice = price;
          }
        }
      }
      if (!minPrice && !maxPrice) {
        return 'N/A'
      }
      if (minPrice === maxPrice) {
        return '$' + maxPrice?.toFixed(0);
      }
      return `$${minPrice?.toFixed(0)} - $${maxPrice?.toFixed(0)}`
  }, [plans]);

  const plan: MedigapPlanOutputWithDetails = React.useMemo(() => {
      return plans.length ? plans[0] : {} as MedigapPlanOutputWithDetails;
  }, [plans]);

  const ribbon = React.useMemo(() => {
      if (filterValues?.selectedPlanCategory !== PlanCategory.MostPopular) {
        return null;
      }
      let title = '';
      switch (plan.planName) {
        case MedigapPlanName.G: title = 'Most Popular'; break;
        case MedigapPlanName.N: title = 'Best for Affordability'; break;
        case MedigapPlanName.F: title = 'Most Comprehensive'; break;
        default: title = '';
      }
      return <PricesButton variant={'contained'}>
        <Box display={'flex'}><p>{title}</p>
          <img className={'ml-5'} src={'/img/ribbon.svg'} /></Box>
      </PricesButton>
  }, [filterValues, plan]);

  const Desktop = <AscendCard className={classes.card}>
    <div className={'flex-space-between flex-align-center mb-10 mh-16 mt-16'}>
      <Typography className={'bold fs-18 no-wrap'} color={'textPrimary'}>Medigap Plan {plan.planName}</Typography>
      {[MedigapPlanName.C, MedigapPlanName.F].includes(plan.planName as MedigapPlanName) &&
        <Typography className={'fs-10 mh-25'} color={'textPrimary'}>* Medigap Plan {plan.planName} is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020</Typography>}
      {ribbon}
    </div>
    <div className={'flex mh-16 mb-12'}>
      <div className={'flex-column flex-space-between pr-24'}>
        <AscendTooltip placement={'top'} arrow title={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}>
          <div>
            <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Monthly premium:</Typography>
            <Typography className={'bold fs-32 lh-32 dark-green'}>{prices}</Typography>
          </div>
        </AscendTooltip>
        <div>
          <Typography className={'fs-12'} color={'textPrimary'}>Does not include standard Part B premium ($148.50)</Typography>
        </div>
      </div>
      <div className={`flex-column flex-space-between ph-24 ${classes.middleSection}`}>
        <div>
          <AscendTooltip placement={'top'} arrow title={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}>
            <div>
              <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Copays/coinsurance:</Typography>
              <Typography className={'fs-12'} color={'textPrimary'}>{plan.partBServices}</Typography>
            </div>
          </AscendTooltip>
        </div>
        <div>
          <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Deductibles:</Typography>
          {[MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(plan.planName as MedigapPlanName) && <AscendTooltip placement={'top'} arrow title={'Deductible combined for both Part A (Hospital) and Part B (Medical) services. If you reach this deductible from only Part A costs, you will also owe the $203 Part B deductible'}>
            <Typography className={'fs-12'} color={'textPrimary'}><strong>{plan.deductibles.partA}</strong> Annual deductible</Typography>
          </AscendTooltip>}
          {![MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(plan.planName as MedigapPlanName) && <AscendTooltip placement={'top'} arrow title={'The amount you must pay out of pocket for your Part A (Hospital) costs before your plan coverage begins.'}>
            <Typography className={'fs-12'} color={'textPrimary'}><strong>{plan.deductibles.partA}</strong> (Hospital) Part A deductible</Typography>
          </AscendTooltip>}
          {plan.deductibles.partB && <AscendTooltip placement={'top'} arrow title={'The amount you must pay out of pocket for your Part B (Medical) costs before your plan coverage begins.'}>
            <Typography className={'fs-12'} color={'textPrimary'}><strong>{plan.deductibles.partB}</strong> (Medical) Part B deductible</Typography>
          </AscendTooltip>}
        </div>

      </div>
      <div className={'flex-column flex-space-between pl-24'}>
        <Typography className={'bold fs-14 lh-14'} color={'textPrimary'}>Plan benefits:</Typography>
        <div className={'mt-8'}>
          <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.SkilledNursingFacilities)}>
            <div>
              <Benefit inactive={false}
                       labelClass={`thin color-primary`} label={'Skilled nursing facility'}
                       value={!!plan?.features.skilledNursingFacility}/>
            </div>
          </AscendTooltip>
        </div>
        <div className={'mt-8'}>
          <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartADeductible)}>
            <div>
              <Benefit inactive={false}
                       labelClass={`thin color-primary`} label={'$0 Part A Deductible'}
                       value={!!plan?.features.partADeductible}/>
            </div>
          </AscendTooltip>
        </div>
        <div className={'mt-8'}>
          <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBDeductible)}>
            <div>
              <Benefit inactive={false}
                       labelClass={`thin color-primary`} label={'Part B deductible'}
                       value={!!plan?.features.partBDeductible}/>
            </div>
          </AscendTooltip>
        </div>
        <div className={'mt-8'}>
          <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBExcessCharges)}>
            <div>
              <Benefit inactive={false}
                       labelClass={`thin color-primary`} label={'Covers Part B excess charges'}
                       value={!!plan?.features.partBExcessCharges}/>
            </div>
          </AscendTooltip>
        </div>
        <div className={'mt-8'}>
          <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.ForeignTravelEmergency)}>
            <div>
              <Benefit inactive={false}
                       labelClass={`thin color-primary`} label={'Foreign travel emergency coverage'}
                       value={!!plan?.features.foreignTravelEmergency}/>
            </div>
          </AscendTooltip>
        </div>
      </div>
    </div>
    <Collapse in={expanded}>
      {plans.map((d: MedigapPlanOutputWithDetails, i) => <PlanRow key={d.key}
                                                                  hideDivider={i === plans.length - 1}
                                                                  plan={d}
                                                                  onDetailsClick={() => history.push(paths.planDetails + `${zip}/${countyName}/${d.key}`)}
                                                                  onEnrollClick={() => onEnrollClick(d.key)} />)}
      <Typography className={'fs-12 mt-8 mb-16 mh-16'} color={'textPrimary'}>Final premium will depend on your age, sex and health status</Typography>
    </Collapse>
    <div className={classes.toggle} onClick={() => setExpanded(!expanded)}>
      <Typography className={'fs-12 mv-8 mr-8 white bold underline'}>{expanded ? 'Hide' : 'Show'} Medigap plans available</Typography>
      <img src={'/img/arrow-white.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
    </div>
  </AscendCard>;

  const Mobile = <AscendCard className={classes.card}>
    <div className={'mh-16 mt-16 mb-12'}>
      <Typography className={'bold fs-18 mb-10'} color={'textPrimary'}>Medigap Plan {plan.planName}</Typography>
      <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Monthly premium:</Typography>
      <Typography className={'bold fs-32 lh-32 dark-green'}>{prices}</Typography>
      {[MedigapPlanName.C, MedigapPlanName.F].includes(plan.planName as MedigapPlanName) &&
      <Typography className={'fs-10 mb-15 mt-5'} color={'textPrimary'}>* Medigap Plan {plan.planName} is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020</Typography>}
      <Typography className={'fs-12 mt-5'} color={'textPrimary'}>Does not include standard Part B premium ($148.50)</Typography>
      <AscendDivider my={'12px'}/>
      <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Copays/coinsurance:</Typography>
      <Typography className={'fs-12 mb-15'} color={'textPrimary'}><strong>$0</strong> for your cost for approved Part B services, with some $20 and $50 copays</Typography>
      <Typography className={'bold fs-14 mb-8 lh-14'} color={'textPrimary'}>Deductibles:</Typography>
      <Typography className={'fs-12'} color={'textPrimary'}><strong>{plan.deductibles.partA}</strong> (Hospital) Part A deductible</Typography>
      <Typography className={'fs-12 mb-15'} color={'textPrimary'}><strong>{plan.deductibles.partB}</strong> (Medical) Part B deductible</Typography>
      <Typography className={'bold fs-14 lh-14'} color={'textPrimary'}>Plan benefits:</Typography>
      <div className={'flex'}>
        <div className={'flex-column'}>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.SkilledNursingFacilities)}>
              <div>
                <Benefit inactive={false}
                         labelClass={`thin color-primary`} label={'Skilled nursing facility'}
                         value={!!plan?.features.skilledNursingFacility}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartADeductible)}>
              <div>
                <Benefit inactive={false}
                         labelClass={`thin color-primary`} label={'$0 Part A Deductible'}
                         value={!!plan?.features.partADeductible}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBDeductible)}>
              <div>
                <Benefit inactive={false}
                         labelClass={`thin color-primary`} label={'Part B deductible'}
                         value={!!plan?.features.partBDeductible}/>
              </div>
            </AscendTooltip>
          </div>
        </div>
        <div className={'flex-column'}>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.PartBExcessCharges)}>
              <div>
                <Benefit inactive={false}
                         labelClass={`thin color-primary`} label={'Covers Part B excess charges'}
                         value={!!plan?.features.partBExcessCharges}/>
              </div>
            </AscendTooltip>
          </div>
          <div className={'mt-8'}>
            <AscendTooltip placement={'top'} arrow title={getTooltipByBenefit(MedigapPlanBenefit.ForeignTravelEmergency)}>
              <div>
                <Benefit inactive={false}
                         labelClass={`thin color-primary`} label={'Foreign travel emergency coverage'}
                         value={!!plan?.features.foreignTravelEmergency}/>
              </div>
            </AscendTooltip>
          </div>
        </div>
      </div>
    </div>
    <Collapse in={expanded}>
      {plans.map((d: MedigapPlanOutputWithDetails) => <PlanRow key={d.key}
                                                               hideDivider={false}
                                                               plan={d}
                                                               onDetailsClick={() => history.push(paths.planDetails + `${zip}/${countyName}/${d.key}`)}
                                                               onEnrollClick={() => onEnrollClick(d.key)} />)}
      <Typography className={'fs-12 mt-8 mb-16 mh-16'} color={'textPrimary'}>Final premium will depend on your age, sex and health status</Typography>
    </Collapse>
    <div className={classes.toggle} onClick={() => setExpanded(!expanded)}>
      <Typography className={'fs-12 mv-8 mr-8 white bold underline'}>{expanded ? 'Hide' : 'Show'} Medigap plans available</Typography>
      <img src={'/img/arrow-white.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
    </div>
  </AscendCard>

  return <div className={`mt-24 ${classes.wrapper}`} ref={ref}>
    {phone ? Mobile : Desktop}
  </div>;
}

export default React.memo(PlanCard);

const PlanRow = ({plan, onEnrollClick, onDetailsClick, hideDivider}: {plan: MedigapPlanOutputWithDetails, hideDivider: boolean, onEnrollClick: () => void, onDetailsClick: () => void}) => {
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <div className={classes.logo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title?.split(' - Plan')[0];
      return <Typography className={'bold fs-18'} color={'textPrimary'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);

  const Desktop = <div className={'flex mt-16 mb-8 mh-16'}>
    <div className={'flex-column pr-35'}>
      <div className={'flex-space-between flex-align-center mb-12'}>
        {Logo}
        <Typography className={'fs-24 lh-32 dark-green'}><strong>{plan.monthlyPremium}</strong> / mo</Typography>
      </div>
      <div className={'flex'}>
        <div className={'flex-column flex-justify-end'}>
          <Typography className={'fs-12 mb-5'} color={'textPrimary'}>{plan.ratingClass}</Typography>
          <Typography className={'fs-12 mb-5'} color={'textPrimary'}>{plan.subTitle}</Typography>
          <Typography className={'fs-12 dark-green underline pointer medium'} onClick={onDetailsClick}>See plan details</Typography>
        </div>
        <div className={'flex-column'}>
          <AscendTooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'flex flex-align-center flex-space-between'}>
              <Typography className={'fs-12 '} color={'textPrimary'}>AM Best rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography className={'fs-12 medium dark-green'}>{plan.amBestRating || 'n.a.'}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </div>
          </AscendTooltip>
          <AscendTooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'mt-8 flex flex-align-center flex-space-between'}>
              <Typography className={'fs-12 '} color={'textPrimary'}>S&P rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography className={'fs-12 medium dark-green'}>{plan.spRating || 'n.a.'}</Typography>
                <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
              </div>
            </div>
          </AscendTooltip>
        </div>
        <div className={'flex-column flex-2 pt-2'}>
          <AscendTooltip placement={'top'} arrow title={`The standard Part B premium in 2022 is $170.10. Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your tax return from two years ago), you may pay an extra charge on top of the regular Part B premium.`}>
            <div className={'flex flex-align-center flex-justify-end'}>
              <Typography className={'fs-11 '} color={'textPrimary'}>plus standard Part B premium</Typography>
              <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
            </div>
          </AscendTooltip>
          <AscendTooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
            <div className={'flex flex-align-center flex-justify-end mt-8'}>
              <Typography className={'fs-12 bold mr-10'} color={'textPrimary'}>Household discount:</Typography>
              <Typography className={'fs-12 medium dark-green'}>{plan.householdDiscount ? plan.householdDiscount + ' p.a.' : 'n.a.'}</Typography>
              <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
            </div>
          </AscendTooltip>
        </div>
      </div>
    </div>
    <div>
      <AscendButton className={classes.enrollButton}
                    onClick={onEnrollClick}
                    variant={"contained"}>
        Select plan
      </AscendButton>
      <CompareCheckbox plan={plan} />
    </div>
  </div>;

  const Mobile = <div className={'mt-16 mb-8 mh-16'}>
    <div className={'flex flex-space-between'}>
      <div className={classes.logo} style={{backgroundImage: `url("${getMedigapCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
      <Typography className={'fs-12'} color={'textPrimary'}>{plan.subTitle}</Typography>
    </div>
    <Typography className={'fs-12 mt-5'} color={'textPrimary'}>{plan.ratingClass}</Typography>

    <div className={'flex-space-between mt-16'}>
      <Typography className={'fs-24 lh-32 dark-green'}><strong>{plan.monthlyPremium}</strong> / mo</Typography>
      <AscendTooltip placement={'top'} arrow title={'The standard Part B premium in 2022 is $170.10. Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your tax return from two years ago), you may pay an extra charge on top of the regular Part B premium.'}>
        <div className={'flex flex-align-center flex-justify-end w-120'}>
          <Typography className={'fs-11 '} color={'textPrimary'} align={'right'}>plus standard<br />Part B premium</Typography>
          <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
        </div>
      </AscendTooltip>
    </div>
    <div className={'flex-space-between mt-16'}>
      <div>
        <Typography className={'fs-12 green underline pointer medium'} onClick={onDetailsClick}>See plan details</Typography>
        <CompareCheckbox plan={plan} />
      </div>
      <div className={'w-120'}>
        <AscendTooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
          <div className={'flex flex-align-center flex-space-between'}>
            <Typography className={'fs-12 '} color={'textPrimary'}>AM Best rating:</Typography>
            <div className={'flex flex-align-center'}>
              <Typography className={'fs-12 medium dark-green'}>{plan.amBestRating}</Typography>
            </div>
          </div>
        </AscendTooltip>
        <AscendTooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
          <div className={'mt-8 flex flex-align-centered flex-space-between'}>
            <Typography className={'fs-12 '} color={'textPrimary'}>S&P rating:</Typography>
            <div className={'flex flex-align-center'}>
              <Typography className={'fs-12 medium dark-green'}>{plan.spRating}</Typography>
            </div>
          </div>
        </AscendTooltip>
      </div>
    </div>
    <AscendTooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
      <div className={'mt-16 p-5 mb-10 flex flex-align-center flex-justify-center bg-light-green'}>
        <Typography className={'fs-12 bold mr-10'} color={'textPrimary'}>Household discount:</Typography>
        <Typography className={'fs-12 medium dark-green'}>{plan.householdDiscount ? plan.householdDiscount + ' p.a.' : 'n.a.'}</Typography>
        <img className={'w-12 h-12 ml-10'} src={'/img/info.svg'}></img>
      </div>
    </AscendTooltip>
    <AscendButton className={classes.enrollButton}
                  onClick={onEnrollClick}
                  variant={"contained"}>
      Select plan
    </AscendButton>
  </div>

  return <>
    {phone ? Mobile : Desktop}
    {!hideDivider && <AscendDivider mt={'12px'}/>}
    {hideDivider && <Box height={'13px'}/>}
  </>
}

const CompareCheckbox = ({plan}: {plan: MedigapPlanOutput}) => {
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const classes = planStyles();
  return <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox}
                         labelClassName={classes.checkboxLabel}
                         label={'Add to compare'} checked={isInCompare(plan.key)}
                         onChange={(e, checked) => {checked ? addToCompare(plan.key) : removeFromCompare(plan.key)}} />
}

export const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  contained: {
    borderRadius: 4,
    background: 'rgba(2, 144, 148, 0.05)',
    border: '1px solid #029094',
    padding: '4px 21px',
  },
  disabled: {},
  label: {
    color: 'rgba(2, 144, 148, 1)',
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'none',
  },
})(AscendButton);

const PricesButton = withStyles((theme: Theme) =>
  createStyles({
    contained: {
      borderRadius: 4,
      padding: '8px',
      background: '#0A2E86',
      pointerEvents: 'none',
      maxHeight: 30,
      width: 224,
      minWidth: 224,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: 10,
      },
      '& .MuiButton-endIcon': {
        position: 'absolute',
        right: 5
      },
    },
    label: {
      color: 'white',
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'none',
      fontWeight: 700,
    },
  }))(AscendButton)
