import React from "react";
import {Box, createStyles, RadioGroup, Theme} from "@material-ui/core";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import useCommonStyles from "../../shared/useCommonStyles";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {Field, PageablePlanOutput, QueryPlansArgs, Sort} from "../../types";
import AscendButton from "../../shared/components/AscendButton";
import {withTitle} from "../../shared/components/withTitle";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import FormTextInput from "../../shared/components/FormTextInput";
import {makeStyles} from "@material-ui/core/styles";
import AscendTitle from "../../shared/components/AscendTitle";
import * as _ from 'lodash';
import paths from "../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {Step2aAnswers} from "./Step2a";

const Schema = yup.object().shape({
  firstName: yup.string().required("Please enter a valid name").typeError('Please enter a valid name'),
  lastName: yup.string().required("Please enter a valid last name").typeError('Please enter a valid last name'),
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingRight: 0
    }
  }),
);

interface BasicInfoFormState {
  firstName: string,
  lastName: string,
}
const formName = 'BASIC_INFO';

function Step4(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    state.personalDetails?.birthDate ? moment(state.personalDetails?.birthDate).toDate() : null
  );

  const [totalPlans, setTotalPlans] = React.useState<number>(0);

  const [getPlans] = useLazyQuery<IPlansOutput, QueryPlansArgs>(gql(planRequest), {
    onCompleted: data => {
      setTotalPlans(data.plans.totalElements);
    },
    errorPolicy: "ignore"
  });

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    if (!!state.personalDetails?.zip && !!state.personalDetails?.countyName) {
      getPlans({
        variables: {
          filter: {
            zip: state.personalDetails.zip, countyName: state.personalDetails.countyName,
          },
          page: {page: 0, size: 1},
          sort: [{direction: Sort.Asc, field: Field.Rating}]
        }
      })
    }
  }, [])

  const {register, handleSubmit, errors, watch, formState, getValues, setValue} = useForm<BasicInfoFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()

  const onSubmit = (values: BasicInfoFormState) => {
    if (values) {
      dispatch({
        type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'basicInfo', form: formName, ...values, birthDate: moment(selectedDate).format('MM/DD/YYYY')}
      })
      const ageInMounths = Math.floor(moment.duration(moment().diff(moment(selectedDate))).asMonths())
      if (typeof state?.STEP_2a?.value !== "undefined") {
        if (state?.STEP_2a?.value === Step2aAnswers.Turning) {
          if (ageInMounths < 777) { // less 64y9m
            props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a2)
          } else if (ageInMounths >= 777 && ageInMounths < 784) { // over 64y9m, less 65y3m
            props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a1)
          } else { // over 65y3m
            props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a3)
          }
        } else {
          props.history.push(paths.questionnaire + '/' + questionsRoutes.step5a4)
        }
      } else {
        props.history.push(paths.questionnaire + '/' + questionsRoutes.step6b)
      }

    }
  };

  return <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} className={'t-base-info-form'}>
      <Box display={'flex'}  justifyContent={'center'} flex={1}>
        <Box maxWidth={'300px'}>
        <div className={'h-32'} />
        <AscendTitle title={'What is your name?'} />
        <div className={'h-16'} />
        <FormTextInput fullWidth
                       name={'firstName'}
                       defaultValue={state.basicInfo?.firstName}
                       error={formState.touched.firstName && !!errors.firstName}
                       helperText={formState.touched.firstName && errors?.firstName?.message}
                       inputRef={register}
                       placeholder={'First name'} />
        <FormTextInput fullWidth
                       name={'lastName'}
                       defaultValue={state.basicInfo?.lastName}
                       error={formState.touched.lastName && !!errors.lastName}
                       helperText={formState.touched.lastName && errors?.lastName?.message}
                       inputRef={register}
                       placeholder={'Last name'} />
        <AscendTitle title={'What is your date of birth?'} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            disableFuture
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            InputAdornmentProps={{
              classes: {
                root: commonClasses.hidden
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            placeholder={'MM / DD / YYYY'}
            value={selectedDate}
            invalidDateMessage={'Please enter a valid date'}
            onChange={date => {
              handleDateChange(date)
            }}
            TextFieldComponent={FormTextInput as any}
          />
        </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box className={commonClasses.buttonWrapper} mt={'10px'}>
        <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton id={formName} tabIndex={4} disabled={!formState.isValid || !moment(selectedDate).isValid()}
                      className={'gtm-step-event'} variant={'contained'} type={'submit'}>Continue</AscendButton>
      </Box>
    </form>
}

export default withTitle(
  Step4,
  (state: IQuestionnaireState) => `Good news!  We found over 20 Medicare plan\n options in your area.`,
  'Please input your details below to continue.'
)

interface IPlansOutput {
  plans: PageablePlanOutput
}

const planRequest = `
  query ($filter: PlansFilterInput!, $page: PageInput!) {
    plans(filter: $filter, page: $page) {
      totalElements
    }
  }
`;
