import React from "react";
import {Box, createStyles, Divider, Paper, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Plan} from "../../../types";
import {RoundedButton} from "../../Coverage";
import AscendButton from "../../../shared/components/AscendButton";


const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 700,
    }
  }),
);

type PlanCardProps = {
  plan: Plan,
  onRemoveClick: () => void,
  onEnrollClick: () => void,
  onTitleClick?: () => void,
  enrollDisabled?: boolean
}

function FavoritePlanCard({plan, onRemoveClick, onEnrollClick, enrollDisabled, onTitleClick} : PlanCardProps) {
  const classes = planStyles();

  return <Paper elevation={0} className={'mb-16'}>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={'16px 30px'}>
      <Typography color={'textPrimary'} variant={'h4'} className={classes.link} onClick={onTitleClick}>{plan.planName}</Typography>
      <Box display={'flex'} alignItems={'center'}>
        <Typography color={'textPrimary'} variant={'h4'} className={'no-wrap bold'}>Premium: {plan.monthlyPremium}</Typography>
        <div className={'w-30'} />
        <Typography variant={'h4'} onClick={() => onRemoveClick()} className={`${classes.link} green`}>Remove</Typography>
        <div className={'w-40'} />
        <RoundedButton variant={'contained'} disabled={enrollDisabled} onClick={() => onEnrollClick()}>Select this plan</RoundedButton>
      </Box>
    </Box>
  </Paper>
}

export default React.memo(FavoritePlanCard);

