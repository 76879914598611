import React from "react";
import {Box, Button, createStyles, InputAdornment, Theme, Typography, withStyles} from "@material-ui/core";
import FormTextInput from "../../../shared/components/FormTextInput";
import CollapseSection from "../../../shared/components/CollapseSection";
import {makeStyles} from "@material-ui/core/styles";
import AscendButton from "../../../shared/components/AscendButton";
import {QuoteContext} from "./QuoteContext";
import {getQuoteId} from "../../../shared/QuoteId";
import {PlanFilterContext} from "./PlanFilterContext";
import {useParams} from "react-router";
import TooltipedTitle from "./components/TooltipedTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    input: {
      marginBottom: 16,
      '& input': {
        padding: '10px 12px 6px 13px!important',
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    label: {
      fontSize: 11,
    },
    placeholder: {
      '& p': {
        fontSize: 12,
      }
    },
    inputLabel: {
      fontSize: 14,
    }
  }),
);

const MonthlyCostCalculator = () => {
  const classes = useStyles();
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const {doctorVisitsNumber, specialistVisitsNumber, save} = React.useContext(QuoteContext);
  const {refresh} = React.useContext(PlanFilterContext)
  const [doctorVisits, setDoctorVisits] = React.useState<string>('');
  const [specialistVisits, setSpecialistVisits] = React.useState<string>('');

  React.useEffect(() => {
    if (typeof doctorVisitsNumber !== 'undefined') {
      setDoctorVisits(doctorVisitsNumber.toString())
    }
  }, [doctorVisitsNumber])

  React.useEffect(() => {
    if (typeof specialistVisitsNumber !== 'undefined') {
      setSpecialistVisits(specialistVisitsNumber.toString())
    }
  }, [specialistVisitsNumber])

  const onClick = () => {
    save({
      variables: {
        data: {
          county: countyName,
          zip,
          doctorVisitsNumber: isNaN(parseFloat(doctorVisits)) ? undefined : parseFloat(doctorVisits),
          specialistVisitsNumber: isNaN(parseFloat(specialistVisits)) ? undefined : parseFloat(specialistVisits),
          status: 'IN_PROGRESS',
          form: 'SEARCH_RESULTS',
          id: getQuoteId()
        }
      },
    }).then(refresh);
  }

  return <div className={'monthly-cost-calculator'}>
    <CollapseSection
      title={<TooltipedTitle className={'fs-16 mt-5 bold'}
                             title={'Doctor cost calculator'}
                             tooltip={''}/>}>
      <Typography className={classes.label} color={'textSecondary'}>Your estimated monthly copay costs will change based on the amounts you enter below. We have included prescription information that you’ve already entered.</Typography>
      <Box mt={2}>
        <div className={'flex flex-align-center relative'}>
          <FormTextInput label={'Primary care visits'}
                         className={classes.input}
                         inputLabelClass={classes.inputLabel}
                         value={doctorVisits}
                         InputProps={{
                           className: 'w-55'
                         }}
                         onChange={event => setDoctorVisits(event.target.value)}
                         type={'number'}
                         name={'primary-care-visits'}
          />
          <Box position={'absolute'} left={'70px'} top={'38px'}>
            <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
          </Box>
        </div>
        <div className={'flex flex-align-center relative'}>
          <FormTextInput label={'Specialist visits'}
                         className={classes.input}
                         inputLabelClass={classes.inputLabel}
                         InputProps={{
                           className: 'w-55'
                         }}
                         value={specialistVisits}
                         onChange={event => setSpecialistVisits(event.target.value)}
                         name={'specialist-visits'}
          />
          <Box position={'absolute'} left={'70px'} top={'38px'}>
            <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
          </Box>
        </div>
        <Box mt={'4px'}>
          <RoundedButton onClick={onClick} variant={'contained'}>Update doctor costs</RoundedButton>
        </Box>
      </Box>
    </CollapseSection>
  </div>
}

export default React.memo(MonthlyCostCalculator);


const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
    },
  },
  contained: {
    borderRadius: 4,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    width: '100%',
    padding: '6px 0 5px',
    border: '2px solid #778E95',
    transition: 'none',
    '&:hover': {
      border: '2px solid #5ECB9E',
      backgroundColor: 'white',
      '& .MuiButton-label': {
        color: '#5ECB9E'
      },
    },
  },
  disabled: {},
  label: {
    color: '#778E95',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'none',
  },
})(AscendButton);
