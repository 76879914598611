import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {Box, createStyles, Grid, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../shared/AppTheme";
import {AccountType} from "./CreateAccount";
import {GetPlansYear} from "../Maketplace/shared/hooks/GetPlansYear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      padding: 0,
      marginTop: 24,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: 4
    },
    header: {
      background: colors.custom.green.variant2,
      color: 'white',
      fontWeight: 700,
      textAlign: 'center',
      padding: '7px 0',
    },
    green: {
      fontWeight: 700,
      color: colors.custom.green.variant2,
      fontSize: 40,
      marginRight: 28,
      lineHeight: 1,
      width: 25,
    }
  })
);

function Receive({isMedigap, ...props}: RouteComponentProps<any> & {isMedigap: boolean}) {
  const classes = useStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const accountType = sessionStorage.getItem('ACCOUNT_TYPE') || AccountType.SKIPPED;

  return <Grid container alignItems={"center"} justify={"center"}>
    <Box className={'mt-8'} width={phone ? '100%' : '600px'}>
      <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
        <img src={'/img/congratulations.svg'} />
      </Box>

      <div className={classes.card}>
        <div className={classes.header}>{isMedigap ? 'Here’s what to expect next:' : <>A licensed CoverRight sales agent will be in touch shortly<br />to help you with your enrollment.</>}</div>
        {!isMedigap && <Box p={'20px 25px'}>
          <Typography className={'bold fs-18 mb-8'} color={'textSecondary'}>Here’s what to expect next:</Typography>
          <Box display={'flex'}>
            <Typography className={classes.green}>1</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>We’ll be in touch — watch out for our call!</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your dedicated licensed CoverRight sales agent will review the details of the plan you have selected.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>2</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Enroll in your selected plan</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your licensed CoverRight sales agent will help you enroll in your new plan, feel free to ask any additional questions you might have during the call.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>3</Typography>
            <div>
              {(accountType === AccountType.SKIPPED || !accountType) && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
              {accountType === AccountType.CREATED && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
              {(accountType === AccountType.SIGN_IN) && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
            </div>
          </Box>
        </Box>}
        {isMedigap && <Box p={'20px 25px'}>
          <Box display={'flex'}>
            <Typography className={classes.green}>1</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>We’ll be in touch soon - watch out for our call!</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your dedicated licensed CoverRight sales agent will be in touch to confirm your contact information and any other final questions required.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>2</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Your application will be submitted to the carrier</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>We’ll submit your application to your new health insurance company - no need to worry about any paperwork! </Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>3</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Receive confirmation from your new health insurer</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>You’ll receive information from your new health insurance company within a few days to confirm successfull enrollment in your new plan.</Typography>
            </div>
          </Box>
        </Box>}
      </div>
      {!isMedigap && <Typography className={'bold fs-18  mt-32 mb-12 text-align-centered'} color={'textSecondary'}>
        {(accountType === AccountType.SKIPPED || !accountType) && <>Sign up to our newsletter for access to our latest Medicare articles!</>}
        {accountType === AccountType.CREATED && <>Check your email to activate your personalized CoverRight account!</>}
        {accountType === AccountType.SIGN_IN && <>Visit your CoverRight profile now to access your other CoverRight offers!</>}
      </Typography>}
      {isMedigap && <Typography className={'bold fs-18  mt-32 mb-12 text-align-centered'} color={'textSecondary'}>
        Join the CoverRight mailing list now!
      </Typography>}
      <Box mt={'30px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        {!isMedigap && <AscendButton variant={'contained'}
                      style={{width: 326}} onClick={() => {
                        switch (accountType) {
                          case AccountType.SKIPPED: document.location.href = 'http://coverright.com/blog'; break;
                          case AccountType.CREATED: document.location.href = 'http://coverright.com/'; break;
                          default: document.location.href = process.env.REACT_APP_PRIVATE_URL as string;
                        }
                      }}>
          {(accountType === AccountType.SKIPPED || !accountType) && <>Sign up to CoverRight newsletter</>}
          {accountType === AccountType.CREATED && <>Go to CoverRight home page</>}
          {accountType === AccountType.SIGN_IN && <>Log in to CoverRight profile</>}
        </AscendButton>}
        {isMedigap && <AscendButton variant={'contained'}
                                     style={{width: 326}} onClick={() => document.location.href = 'http://coverright.com/'}>
          Go to CoverRight home page
        </AscendButton>}
      </Box>
      <div className={'h-30'} />
      <Typography variant={'h5'} color={'textPrimary'}>
        Last Updated Date: 10/15/22<br/>MULTIPLAN_COVERRIGHT_2023_M<br />MULTIPLAN_COVERRIGHT_2023U2_M
      </Typography>
    </Box>
  </Grid>;
}

export default withTitle(
  Receive,
  (s, m, props) => props.isMedigap ? 'Congratulations!  We’ve received your enrollment application request!' : 'We’ve received your request!',
  '',
  undefined,
  undefined,
  true
);
