import React from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  createStyles,
  IconButton,
  MenuItem,
  Theme,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {Cookies} from "react-cookie";
import {colors} from "../../../../shared/AppTheme";
import {QuestionnaireContext} from "../../../../Questionnaire/QuestionnaireContextProvider";
import AscendModal from "../../../../shared/components/AscendModal";
import FormTextInput from "../../../../shared/components/FormTextInput";
import FormSelectInput from "../../../../shared/components/FormSelectInput";
import clsx from "clsx";
import useGetCounty from "../../../../Questionnaire/hooks/useGetCounty";
import AscendButton from "../../../../shared/components/AscendButton";
const cookie = new Cookies();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 700,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      marginTop: 8,
    },
    lastName: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginLeft: 16,
      },
    },
    email: {
      marginTop: 17,
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      },
    },
    zipContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    content: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 40,
    }
  })
);

const Schema = yup.object().shape({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
});

interface FormState {
  zip: string;
}

type UpdateZipModalProps = {
  title: string,
  subtitle: string,
  text: JSX.Element,
  buttonLabel: string,
  onClose: (result?: boolean) => void,
  open: boolean,
}

export default function ErrorModal(props: UpdateZipModalProps) {
  const classes = useStyles();

  return <AscendModal
    width={590}
    open={props.open}
    onClose={() => props.onClose()}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Typography color={'textPrimary'} className={'fs-26 lh-32'} align={'center'}>{props.title}</Typography>

      <Typography align={'center'} className={'mv-20'}><img className={'w-70 h-70'} src={'/img/big-green-info.svg'} /></Typography>

      <Typography color={'textPrimary'} className={'fs-20 lh-25 bold'} align={'center'}>{props.subtitle}</Typography>
      {props.text}
      <Box display={'flex'} mt={'20px'} flexDirection={'column'}>
        <AscendButton variant={'contained'} className={'bg-dark-green2'} onClick={() => props.onClose()}>No - return to plans</AscendButton>
        <AscendButton variant={'contained'} onClick={() => props.onClose(true)} className={'mt-8 bg-grey'}>{props.buttonLabel}</AscendButton>
      </Box>
    </>
  </AscendModal>
}
