import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationEmailMeMedigapPageArgs} from "../../../../enrollment-types";
import {client} from "../../../../shared/EnrollmentApolloClient";


export default function useEmailMeMedigapPage(options?: MutationHookOptions<{emailMeMedigapPage: boolean}, MutationEmailMeMedigapPageArgs>) {
  return useMutation<{emailMeMedigapPage: boolean}, MutationEmailMeMedigapPageArgs>(
    gql(request),
    {
      ...options,
      client
    }
  );
}


const request = `
mutation ($email: String!, $id: UUID!) {
  emailMeMedigapPage(email: $email, id: $id)
}
`;
