import React from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {QuoteContext} from "../QuoteContextProvider";
import {config} from "../../config/config";
import {getQuoteId} from "../../../shared/QuoteId";
import {PlanYear, Rating} from "../../../types";
import useExtraBenefitsWithMaxBenefitAmount from "../hooks/useExtraBenefitsWithMaxBenefitAmount";
import {Box, createStyles, Theme, Typography} from "@material-ui/core";
import * as _ from 'lodash';
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import {makeStyles} from "@material-ui/core/styles";
import AscendDivider from "../../../shared/components/AscendDivider";
import AscendButton from "../../../shared/components/AscendButton";
import useCommonStyles from "../../../shared/useCommonStyles";
import {convertBold, convertMedium} from "../../../shared/utils";
import {client} from "../../../shared/ApolloClient";
import {client as client2022} from "../../../shared/ApolloClient2022";
import usePlanSummary from "../../shared/hooks/usePlanSummary";
import SearchingPlanModal from "../../Medigap/components/SearchingPlanModal";
import paths from "../../config/router-paths";
import Shared from "../../shared/questions/Step9a";
import {withTitle} from "../../../shared/components/withTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      padding: 0,
      marginLeft: -8,
      alignItems: 'flex-start',
      backgroundColor: 'transparent',
      '& .MuiCheckbox-root': {
        paddingTop: 2,
      }
    },
    checkboxLabel: {
      fontSize: 18,
    }
  }),
);

function Step9aNew(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [quote, dispatch] = React.useContext(QuoteContext);
  const [getData, {data, loading}] = useExtraBenefitsWithMaxBenefitAmount({client: quote?.STEP_7?.planYear !== PlanYear.Year2022 ? client : client2022});
  const [rows, setRows] = React.useState<string[]>([]);

  const [getSummary, plansSummaryData] = usePlanSummary();

  const totalPlans = React.useMemo(() => {
    return plansSummaryData.data?.plansSummary ? plansSummaryData.data.plansSummary.totalCount : 0
  }, [plansSummaryData.data]);

  React.useEffect(() => {
      if (quote?.STEP_2?.zip) {
        getData({variables: {zip: quote?.STEP_2?.zip}})
        getSummary({variables: {
            zip: quote.STEP_2?.zip,
            countyName: quote.STEP_2?.countyName,
            quoteId: getQuoteId(),
            extraBenefits: quote?.STEP_6?.benefits?.value
          }})
      }
  }, [quote?.STEP_2?.zip]);

  React.useEffect(() => {
    if (data?.extraBenefitsWithMaxBenefitAmount) {
      const benefits = quote?.STEP_6?.benefits?.value || [];
      setRows(Object.keys(data?.extraBenefitsWithMaxBenefitAmount)
          .filter(key => benefits.includes(_.snakeCase(key).toUpperCase()))
          .map(key => (data as any).extraBenefitsWithMaxBenefitAmount[key])
      )
    }
  }, [data?.extraBenefitsWithMaxBenefitAmount, quote?.STEP_6?.benefits?.value]);

  const onNextStep = React.useCallback(() => {
    const quoteParam = !!getQuoteId() ? "&quote=" + getQuoteId() : '';
    const urlBase = quote?.STEP_7?.planYear === PlanYear.Year2022 ? config.nextYearResultsUrl : config.resultsUrl;
    if( document.location.search) {
      document.location.href =  urlBase + 'plans' + document.location.search + "&skipCreateAccount=true" + quoteParam
    } else {
      document.location.href =  urlBase + 'plans' + "?skipCreateAccount=true" + quoteParam
    }
  }, [document.location.search, quote]);

  return <div>
    <Typography align={'center'} className={'dark-green fs-22 bold mb-30 mt-10'}>Congratulations, we found {totalPlans} plans for {quote?.STEP_7?.planYear !== PlanYear.Year2022 ? '2021' : '2022'} that match your preferences!</Typography>
    <div className={'flex flex-align-center'}>
      <img src={'/img/huge-check.svg'} className={'w-250 h-250'}/>
      <div className={'ml-40'}>
        <Typography color={'textPrimary'} className={'fs-20 medium'}>We found:</Typography>
        {rows.map(row => <div className={'flex flex-align-center'}>
          <AscendCheckbox variant={'standart'}
                          labelClassName={classes.checkboxLabel}
                          controlClassName={classes.checkbox}
                          label={<div dangerouslySetInnerHTML={{__html: convertMedium(row)}}></div>}
                          checked={true}/>
        </div>)}
      </div>
    </div>
    <Typography color={'textPrimary'} align={'center'} className={'fs-22 medium mt-30 lh-27'}>{`Continue to see your options and speak with a licensed advisor.`}</Typography>
    <AscendDivider mt={'23px'}/>
    <Box display={'flex'} mt={'35px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={'STEP_9a'} className={'gtm-step-event'}
                    disabled={loading || plansSummaryData.loading}
                    variant={'contained'} onClick={onNextStep}>Great, let’s continue</AscendButton>
    </Box>
  </div>;
}
/*


export default withTitle(
  Step9a,
  "We’re looking for the best Medicare\n options for you now",
  `Searching for your plans now...`,
  undefined,
  undefined,
  true
);

export default withTitle(
  Step9a,
  "Congratulations!",
  ``,
  undefined,
  undefined,
  true,
  700
);*/

function Step9a(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(QuoteContext);

  const onNextStep = React.useCallback(() => {
    const quoteParam = !!getQuoteId() ? "&quote=" + getQuoteId() : '';
    const urlBase = quote?.STEP_7?.planYear === PlanYear.Year2022 ? config.nextYearResultsUrl : config.resultsUrl;
    if( document.location.search) {
      document.location.href =  urlBase + 'plans' + document.location.search + "&skipCreateAccount=true" + quoteParam
    } else {
      document.location.href =  urlBase + 'plans' + "?skipCreateAccount=true" + quoteParam
    }
  }, [document.location.search, quote]);

  return <Shared quote={quote} dispatch={dispatch}
                 onNextStep={onNextStep}
                 {...props} />;
}

export default Step9a as any;

