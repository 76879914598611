import React from "react";
import AscendPreloader from "../../shared/components/AscendPreloader";
import {Box, Fade, withStyles} from "@material-ui/core";

interface PreloaderContextState {
  setPreloader: (key: string, value: boolean) => void
}

const defaultState: PreloaderContextState = {
  setPreloader: (key: string, value: boolean) => {}
};

export const PreloaderContext = React.createContext<PreloaderContextState>(defaultState);

export function PreloaderContextProvider(props: React.PropsWithChildren<any>) {

  const [state, setState] = React.useState<{[key: string]: boolean}>({});

  const preloader = React.useMemo(() => {
    if (!Object.keys(state).length) {
      return false;
    } else {
      return !Object.values(state).every(i => !i);
    }
  }, [state]);


  const setPreloader = (key: string, value: boolean) => {
    setState({...state, [key]: value});
  }

  return <PreloaderContext.Provider value={{setPreloader}}>
    {preloader && <Fade appear={false} in={true}>
      <Wrapper>
        <AscendPreloader/>
      </Wrapper>
    </Fade>}
    {props.children}
  </PreloaderContext.Provider>
}

const Wrapper = withStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F7F6',
    zIndex: 99999999,
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
  }
})((props: React.PropsWithChildren<any> & {classes: any}) => <Box className={props.classes.wrapper}>{props.children}</Box>)

export const withPreloaderContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <PreloaderContextProvider>
      <WrappedComponent {...{...props}} />
    </PreloaderContextProvider>
  )
}
