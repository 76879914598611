import React, {useRef} from "react";
import {
  createStyles,
  FormControl, FormControlProps,
  FormHelperText, InputLabel,
  makeStyles, MenuItem,
  Select,
  SelectProps,
  Theme
} from "@material-ui/core";
import clsx from "clsx";
import {colors} from "../AppTheme";
import FormTextInput from "./FormTextInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '20px',
      width: '100%',
    },
    inputLabel: {
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '10px',
      color: colors.text.primary,
      fontWeight: 500,
    },
    select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
        color: colors.text.primary,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #CCD8DC',
        borderRadius: '5px',
        height: 55,
        backgroundColor: 'white',
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '15px',
        lineHeight: '21px',
        fontWeight: 500,
        padding: '18px 20px 14px!important',
        zIndex: 1,
      },
      '& .MuiSelect-iconOutlined': {
        zIndex: 1
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      }
    },
    paper: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
      '& ul': {
        padding: '10px 0',
      },
      '& li': {
        fontSize: 16,
        fontWeight: 500,
        padding: '8px 20px',
      }
    }
  }),
);

type FormSelectInputProps = FormControlProps<'select', SelectProps> & {
  helperText?: string | React.ReactElement,
  placeholder?: string,
  labelClassName?: string,
  paperClassName?: string
}

// 55385
export default function FormSelectInput(props: FormSelectInputProps) {
  const classes = useStyles();
  const {helperText, paperClassName, ...rest} = props;

  return (
    <div className={clsx(classes.root, props.className)}>
      {
        props.label && (
          <div className={clsx(classes.inputLabel, props.labelClassName)}>
            <span>
              {props.label}
            </span>
          </div>
        )
      }
      <FormControl disabled={props.disabled} fullWidth variant={'outlined'} error={props.error}>
        <Select
          {...rest}
          label={undefined}
          displayEmpty={!!props.placeholder}
          className={classes.select}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PopoverClasses: {
              paper: clsx(classes.paper, paperClassName),
            }
          }}
        >
          {!!props.placeholder && <MenuItem value="" disabled>
            {props.placeholder}
          </MenuItem>}
          {props.children}
        </Select>
        {(props.error || props.helperText) && <FormHelperText className={'ml-0'}>{props.helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}

