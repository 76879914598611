import React from "react";
import {RouteComponentProps} from "react-router";
import QuoteStepper from "../shared/components/QuoteStepper";
import useStepperState from "./hooks/useStepperState";

const MedicareQuoteStepper = (props: RouteComponentProps<{routeName: string}>) => {
  const {state} = useStepperState();
  return <QuoteStepper {...props}
                       title={<>Complete our simple form so we can find the <b>Medicare Advantage</b> coverage that’s right for you.</>}
                       stepperState={state}
  />
}

export default MedicareQuoteStepper;
