import React, {ReactNode} from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {Box, createStyles, RadioGroup, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import paths from "../../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import useCommonStyles from "../../../shared/useCommonStyles";
import AscendTitle from "../../../shared/components/AscendTitle";
import {useLazyQuery} from "@apollo/react-hooks";
import {ExtraBenefit, FilterItem, MedicareQuoteFilterExtraBenefit, QueryPlanTypesArgs} from "../../../types";
import {gql} from "apollo-boost";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import * as _ from 'lodash';
import {makeStyles} from "@material-ui/core/styles";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import {storeFilters} from "../../../shared/utils";
import {ProfileSource} from "../../../enrollment-types";
import {getQuoteId} from "../../../shared/QuoteId";

const formName = 'STEP_6';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: '8px 20px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      width: '100%',
      maxWidth: 220,
    },
    medium: {
      fontWeight: 500
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    },
  }),
);

export const maxCostRanges = [
  {key: '50-150', name: '$50 to $150'},
  {key: '150-250', name: '$150 to $250'},
  {key: '250-350', name: '$250 to $350'},
  {key: '350+', name: '$350+'},
];

function Budget(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [monthlyCost, setMonthlyCost] = React.useState<string[]>(state?.STEP_6?.monthlyCost?.value || []);


  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName,
        monthlyCost: {
          questionText: 'Do you have a monthly budget in mind?',
          answerText: monthlyCost.map(_.lowerCase).map(_.startCase).join(', '),
          value: monthlyCost
        }}
    })
    props.history.push(paths.MGquestionnaire + '/' + questionsRoutes.step9)
  };

  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <div className={'h-32'} />
    <div className={classes.card}>
      {maxCostRanges.map(item => <AscendCheckbox variant={'standart'}
                                                 labelClassName={classes.checkboxLabel}
                                                 controlClassName={classes.checkbox}
                                                 key={item.key}
                                                 label={item.name}
                                                 checked={!!monthlyCost && monthlyCost.includes(item.key)}
                                                 onChange={() => {
                                                   if (monthlyCost.includes(item.key)) {
                                                     setMonthlyCost(_.without(monthlyCost, item.key))
                                                   } else {
                                                     setMonthlyCost([...monthlyCost, item.key])
                                                   }
                                                 }}

      />)}
    </div>

    <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
      <AscendButton className={'gtm-step-event'} id={formName}
                    disabled={typeof monthlyCost === 'undefined'}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <AscendButton variant={'contained'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>
      </Box>
    </Box>
  </Box>;
}

const Step6Budget = withTitle(
  Budget,
  'Do you have a budget in mind?',
  'Let us know what your budget is for monthly premiums.',
  undefined,
  undefined,
  true,
);

function Benefits(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [values, setValues] = React.useState<string[]>(state?.STEP_6?.benefits?.value || []);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const toggleValue = (val: string) => {
    if (values?.includes(val)) {
      setValues(prev => _.without(prev, val))
    } else {
      setValues(prev => [...(prev || []), val])
    }
  }

  const disabled = React.useMemo(() => {
      return values?.length > 2;
  }, [values]);

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName, benefits: {
          questionText: 'What extra benefits would you like?',
          answerText: values.map(_.lowerCase).map(_.startCase).join(', '),
          value: values
        },
      }
    })
    if ((window as any).track) {
      (window as any).track('First time MA - choose extra benefit', {source: ProfileSource.UserFlowV1});
    }
    setTimeout(() => {
      document.location.href = process.env.REACT_APP_USER_FLOW_URL + `?source=${ProfileSource.UserFlowV1}&quote=` + getQuoteId();
    }, 500)
    // props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.step7)
  };

  return <>
    <div className={'h-32'} />
    <AscendTitle title={'Select three features that matter to you most:'} />
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" classes={{root: commonClasses.radioGroup}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Dental'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Dental) < 0}
                        tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Dental) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Dental)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/implant.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Vision'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Vision) < 0}
                        tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Vision) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Vision)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/glasses.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Hearing'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Hearing) < 0}
                        tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Hearing) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Hearing)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/hear.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Fitness'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Fitness) < 0}
                        tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Fitness) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Fitness)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/weightlifting.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Insulin savings'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.InsulinSavings) < 0}
                        tooltip={'Plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.InsulinSavings) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.InsulinSavings)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/insulin.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Over-the-counter Allowance'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.OverTheCounter) < 0}
                        tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.OverTheCounter) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.OverTheCounter)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/drugs.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Telehealth'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Telehealth) < 0}
                        tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Telehealth) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Telehealth)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/phone-call.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Transportation'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.Transportation) < 0}
                        tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.Transportation) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.Transportation)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/ambulance.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup className={`${commonClasses.radioGroup} flex-justify-center`} row={true}>
      <Box display={'flex'} width={'50%'}>
        <AscendCheckbox variant={'outlined'} label={'Part B Premium Giveback'}
                        disabled={disabled && values.indexOf(MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback) < 0}
                        tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                        checked={values && values.includes(MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback) } onChange={() => toggleValue(MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/part-b-giveback.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
        <AscendButton className={'gtm-step-event'} id={formName}
                      variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <AscendButton variant={'contained'} onClick={() => {
            props.history.goBack();
          }} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
    </Box>
  </>;
}

const Step6Benefits = withTitle(
  Benefits,
  () => 'Are there any extra benefits that are important to you?',
  'We’ll prioritize plans that meet your criteria.',
  undefined,
  undefined,
  true,
);


const Step6 = (props: any) => {
  const [state, dispatch] = React.useContext(QuoteContext);
  return state?.QUIZ_3?.medicarePlanType?.value === 'MG' ? <Step6Budget {...props} /> : <Step6Benefits {...props} />;
};

export default Step6
