import React from "react";
import {DrugTierOutput, PlanYear} from "../../../types";
import usePlan, {PlanWithTiers} from "./hooks/usePlan";
import {
  Box,
  Collapse,
  createStyles,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {planStyles, RoundedButton} from "../PlanList/PlanCard";
import AscendCard from "../../../shared/components/AscendCard";
import {SimplePlanDetail} from "./components/SimplePlanDetail";
import {ListPlanDetail} from "./components/ListPlanDetail";
import {AscendSubtitle} from "./components/AscendSubtitle";
import {PlanSectionTitle} from "./components/PlanSectionTitle";
import Layout from "../../../shared/Layout";
import PlanDetailsContent from "./PlanDetailsContent";
import * as Scroll from "react-scroll";
import {JsxPlanDetail} from "./components/JsxPlanDetail";
import {DrugCoverageList} from "./components/DrugCoverageList";
import {getQuoteId} from "../../../shared/QuoteId";
import {DoctorCoverageList} from "./components/DoctorCoverageList";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import ComparisonPanel from "../PlanComparison/ComparisonPanel";
import {makeStyles} from "@material-ui/core/styles";
import Benefit from "../../../shared/components/Benefit";
import {colors} from "../../../shared/AppTheme";
import clsx from "clsx";
import {QuoteContext} from "../PlanList/QuoteContext";
import paths from "../config/router-paths";
import PlanEmailModal from "./components/PlanEmailModal";
import Footer from "../shared/Footer";
import planFiles from "../shared/planFiles";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import PlanSummaryModal from "../PlanList/PlanSummaryModal";
import LoginWidget from "../PlanList/LoginWidget";
import HeaderCard from "./components/HeaderCard";
import {useSnackbar} from "notistack";
import BackButton from "../shared/BackButton";
import OptionalCollapse from "../../shared/components/OptionalCollapse";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {config} from "../config/config";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

var Element = Scroll.Element;
const Link = Scroll.Link;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: colors.custom.green.variant2,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 400,
    },
    headerCard: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCardFixed: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      height: 117,
      width: '100%',
      padding: 15,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '24px 0 24px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '17.5px',
      color: colors.text.primary,
      background: 'rgba(215, 229, 250, 0.2)',
      padding: '10px 15px',
    },
    tableRow: {
      '& td': {
        whiteSpace: 'nowrap',
        color: colors.text.primary,
        fontSize: 12,
        padding: 15,
        borderBottom: '1px solid #E8ECED',
        textAlign: 'left',
      },
      '& td:first-child': {
        fontWeight: 700,
      }
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '19px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
    },
    sectionWrapper: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
    drugSubtitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colors.text.primary
    },
    drugSubtitleWrapper: {
      backgroundColor: '#FFF2EF!important',
      padding: '5px 16px!important'
    },
    titleWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: '5px 16px!important'
      },
    }
  })
);

export interface PlanViewProps {
  zip: string,
  countyName: string,
  plan: PlanWithTiers;
  setModalOpen: (value: boolean) => void;
  setEmailOpen: (value: boolean) => void;
}

export const PlanView = (props: PlanViewProps) => {

  let {zip, plan, setModalOpen, countyName} = props;
  const {preferredDrugs, preferredPharmacies} = React.useContext(QuoteContext);
  const [expandedState, setExpandedState] = React.useState<{[key: string]: boolean}>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const toggleExpanded = (key: string) => {
    const newValue = expandedState[key] === undefined ? false : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue
    })
  }

  const history = useHistory();
  const ownClasses = styles();
  const classes = planStyles();

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === 'undefined' ? true : expandedState[key];

  const files = React.useMemo(() => {
    return planFiles(plan?.planFiles);
  }, [plan])

  return <Box justifyContent={"center"}>

          <HeaderCard {...props} />

          <div className={"h-30"}/>

          <Element name={"Key Plan Benefits Overview"}>
            <PlanSectionTitle title={"Key Plan Benefits Overview"}/>

            <AscendCard className={`${ownClasses.card} pb-5`}>
              <Grid container direction={"column"}>
                <Element name={"Estimated Monthly Cost Summary"}></Element>
                <AscendSubtitle title={"Estimated Monthly Cost Summary"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('monthlyCostSummary') : undefined}
                                onToggleClick={() => toggleExpanded('monthlyCostSummary')}
                                icon={"/img/expenses.svg"}/>
                <OptionalCollapse inCollapse={phone} in={getExpandedValue('monthlyCostSummary')}>
                  <Box className={ownClasses.sectionWrapper}>
                    <SimplePlanDetail title={"Monthly plan premium"} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} content={plan!!.monthlyPremium} thin hideDidider/>
                    <SimplePlanDetail title={"Estimated drug costs"} tooltip={'Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information.'} content={<Box display={'flex'} alignItems={'center'}>
                      <Typography color={'textPrimary'} className={'fs-12'}>{plan!!.drugsCost}</Typography>
                      <div className={'w-40'} />
                      {(!!preferredDrugs?.preferredDrugs?.length && !phone && !plan.missingFormularyInAep) && (
                        <RoundedButton variant={'contained'} onClick={() => setModalOpen(true)}>See monthly drug cost</RoundedButton>
                      )}
                    </Box>} thin hideDidider/>
                    <SimplePlanDetail title={"Estimated doctor costs"} tooltip={'Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers.'} content={plan!!.doctorsCost} thin/>

                    <SimplePlanDetail title={"Total estimated monthly cost"} tooltip={'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'} content={<Typography color={'textPrimary'} className={'fs-12 bold'}>{plan!!.monthlyCost}</Typography>} hideDidider/>
                    {!!plan!!.optionalSupplementalBenefitsItems?.length && <ListPlanDetail title={"Optional supplemental benefits"} tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'} content={plan!!.optionalSupplementalBenefitsItems} thin/>}
                    <SimplePlanDetail title={<i>plus Medicare Part B Premium</i>}
                                      tooltip={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}
                                      content={<i className={'fs-12'} style={{color: colors.text.primary}}>{'$170.10'}</i>}
                                      thin hideDidider/>
                    {(!!preferredDrugs?.preferredDrugs?.length && phone && !plan.missingFormularyInAep) && (
                      <RoundedButton variant={'contained'} className={'mb-15'} onClick={() => setModalOpen(true)}>See monthly drug cost</RoundedButton>
                    )}
                  </Box>
                </OptionalCollapse>

                <Element name={"Your Healthcare Selections"}></Element>
                <div className={'h-8'} />
                <AscendSubtitle title={"Your Healthcare Selections"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('healthcareSelections') : undefined}
                                onToggleClick={() => toggleExpanded('healthcareSelections')}
                                icon={"/img/healthcare.svg"}/>

                <OptionalCollapse inCollapse={phone} in={getExpandedValue('healthcareSelections')}>
                  <Box className={ownClasses.sectionWrapper}>
                    <JsxPlanDetail title={<strong className={'card-title'}>Your doctors</strong>}
                                   className={'mv-16'}
                                   content={<div>
                                     <DoctorCoverageList isPPO={plan.planType === 'PPO'} coverage={plan?.doctorsCoverage}/>
                                     <div className={`h-8`} />
                                     <RoundedButton className={'w-130'}
                                                    variant={'contained'}
                                                    onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                                       {plan?.doctorsCoverage?.length ? 'Edit' : 'Add'} doctors
                                     </RoundedButton>
                                   </div>}/>
                    <Divider/>
                    <JsxPlanDetail title={<strong className={'card-title'}>Your drugs:</strong>}
                                   className={'mv-16'}
                                   content={<div>
                                     {!plan.missingFormularyInAep && <>
                                      <DrugCoverageList coverage={plan.drugsCoverage}/>
                                       <div className={`h-8`} />
                                       <RoundedButton className={'w-130'}
                                                      variant={'contained'}
                                                      onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                                         {plan?.drugsCoverage?.length ? 'Edit' : 'Add'} drugs
                                       </RoundedButton>
                                     </>}
                                     {plan.missingFormularyInAep && <Typography className={'fs-12 card-title'} color={'textPrimary'}>Speak to an Agent for 2022 drug details for this plan</Typography>}
                                   </div>}/>
                    <Divider/>
                    <JsxPlanDetail title={<strong className={'card-title'}>Your pharmacy:</strong>}
                                   className={'mv-16'}
                                   content={<div>
                                     {preferredPharmacies?.preferredPharmacies?.length ?
                                       <Benefit label={preferredPharmacies.preferredPharmacies[0].name} value={true} labelClass={classes.pharmacyLabelClass}/> :
                                       <Typography className={'fs-12 card-title'} color={'textPrimary'}>You haven’t added a pharmacy</Typography>
                                     }
                                     <div className={'h-8'} />
                                     <RoundedButton className={'w-130'}
                                                    variant={'contained'}
                                                    onClick={() => history.push(paths.editPreferences + zip + '/' + countyName)}>
                                       {preferredPharmacies?.preferredPharmacies?.length ? 'Edit' : 'Add'} pharmacy
                                     </RoundedButton>
                                   </div>}/>
                  </Box>
                </OptionalCollapse>

                <Element name={"PlanCoverageOverview"}></Element>
                <div className={'h-8'} />
                <AscendSubtitle title={"Plan Highlights"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('planHighlights') : undefined}
                                onToggleClick={() => toggleExpanded('planHighlights')}
                                icon={"/img/medical-record-1.svg"}/>
                <OptionalCollapse inCollapse={phone} in={getExpandedValue('planHighlights')}>
                  <Box className={ownClasses.sectionWrapper}>
                    <SimplePlanDetail title={"Plan Type"} tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'} content={`Medicare Advantage (${plan!!.planType})`}/>
                    {!!plan.partBGiveBack && <SimplePlanDetail title={"Part B Giveback"}
                                                               tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                                                               content={<Benefit label={`${plan.partBAmount}`} value={!!plan.partBGiveBack} />}/>}
                    <SimplePlanDetail title={"Annual In-Network Deductible"}
                                      tooltip={'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. '}
                                      content={plan.inNetworkDeductableAmount}
                                      emptyString={"This plan does not have a deductible."}/>
                    <ListPlanDetail title={"Out-of-Pocket Maximum"} tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'} content={plan?.outOfPocketMaximum}/>



                    <ListPlanDetail title={"Primary Doctor Visit"}
                                    tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'}
                                    content={plan?.primaryCarePhysician}/>
                    <ListPlanDetail title={"Specialist Visit"} tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'} content={plan?.physicianSpecialist}/>
                    <ListPlanDetail title={"Inpatient Hospital Care"} tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'} content={plan?.hospitalBenefits}/>



                    {!!plan?.drugDetails &&
                    (<>
                        <Element name={"DrugCoverage"}></Element>

                        <SimplePlanDetail title={"Prescription Drug Coverage"}
                                          tooltip={'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'}
                                          content={<Benefit value={plan?.extraBenefits.drugCoverage} label={''} />}/>
                        {(plan?.drugDetails!!.mrxAltDedAmount || plan?.drugDetails!!.mrxAltNoDedTier) && <ListPlanDetail title={"Prescription Drug Deductible"}
                                        tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                                        content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>}
                      </>
                    )
                    }
                    <SimplePlanDetail title={"Dental Services"}
                                      tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan?.dental.length > 0} label={plan?.dental.length > 0 ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Vision Services"}
                                      tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan?.vision.length > 0} label={plan?.vision.length > 0 ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Hearing Services"}
                                      tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan?.hearing.length > 0} label={plan?.hearing.length > 0 ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Fitness Benefits"}
                                      tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.fitness} label={plan.extraBenefits.fitness ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Insulin Savings"}
                                      tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.insulinSavings} label={plan.extraBenefits.insulinSavings ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Over-the-counter Allowance"}
                                      tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan?.oTC.length > 0} label={plan?.oTC.length > 0 ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Telehealth Services"}
                                      tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.telehealth} label={plan.extraBenefits.telehealth ? 'View details' : ''} />
                                      </Link>}/>
                    <SimplePlanDetail title={"Transportation Services"}
                                      tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                                      content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                        <Benefit labelClass={ownClasses.label} value={plan?.transportation.length > 0} label={plan?.transportation.length > 0 ? 'View details' : ''} />
                                      </Link>}/>
                  </Box>
                </OptionalCollapse>

              </Grid>
            </AscendCard>
          </Element>

          {(!!plan?.drugDetails && !!plan.extraBenefits.drugCoverage) &&
          <Element name={"Drug Coverage & Costs"}>
            <div className={"h-30"}/>
            {!plan.SNPType && <DrugDetails plan={plan} getExpandedValue={getExpandedValue} setExpandedState={setExpandedState} />}
            {!!plan.SNPType && <SNPDrugDetails plan={plan} getExpandedValue={getExpandedValue} setExpandedState={setExpandedState} />}
          </Element>
          }


          <div className={"h-30"}/>

          <Element name={"Medical & Hospital Coverage"}>
            <PlanSectionTitle title={"Medical & Hospital Coverage"}/>
            <AscendCard className={ownClasses.card}>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Your Doctor Costs"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('doctorCosts') : undefined}
                                onToggleClick={() => toggleExpanded('doctorCosts')}
                                icon={"/img/doctor.svg"}/>

                <OptionalCollapse inCollapse={phone} in={getExpandedValue('doctorCosts')}>
                  <Box className={ownClasses.sectionWrapper}>
                    <ListPlanDetail title={"Primary Doctor Visit"}
                                    tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'}
                                    content={plan?.primaryCarePhysician}/>
                    <ListPlanDetail title={"Specialist Visit"}
                                    tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'}
                                    content={plan?.physicianSpecialist}/>
                    <ListPlanDetail title={"Preventive Care"}
                                    tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'}
                                    content={plan?.preventiveCare}/>
                  </Box>
                </OptionalCollapse>


                <div className={"h-20"}/>
                <AscendSubtitle title={"Medical and Hospital Coverage Overview"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('hospitalCoverage') : undefined}
                                onToggleClick={() => toggleExpanded('hospitalCoverage')}
                                icon={"/img/healthcare.svg"}/>

                <OptionalCollapse inCollapse={phone} in={getExpandedValue('hospitalCoverage')}>
                  <Box className={ownClasses.sectionWrapper}>

                    <SimplePlanDetail title={"Emergency Room"}
                                      tooltip={'Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient\'s health at serious risk.'}
                                      content={plan?.hospitalityDetails.emergencyRoom}/>

                    <SimplePlanDetail title={"Urgently Needed Services"}
                                      tooltip={'Medical care that you get outside of your Medicare health plan\'s service area for a sudden illness or injury that needs attention but isn\'t life threatening. If it\'s not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care.'}
                                      content={plan?.hospitalityDetails.urgentServices}/>

                    <ListPlanDetail title={"Ambulance Services"}
                                    tooltip={'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'}
                                    content={plan?.hospitalityDetails.ambulanceServices}/>

                    <ListPlanDetail title={"Inpatient Hospital Care"}
                                    tooltip={"Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies."}
                                    content={plan?.hospitalBenefits}/>


                    <ListPlanDetail title={"Skilled Nursing Facilities (SNF)"}
                                    tooltip={'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'}
                                    content={plan?.skilledNursing}/>

                    <ListPlanDetail title={"Outpatient Diagnostic Tests and Therapeutic Services"}
                                    titleClassName={phone ? undefined : 'w-205'}
                                    tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'}
                                    content={plan?.outpatientHospitality}/>

                    <ListPlanDetail title={"Outpatient Hospital And Surgery"}
                                    tooltip={'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'}
                                    content={plan?.outpatientSurgery}/>

                    <ListPlanDetail title={"Outpatient Rehabilitation Services"}
                                    tooltip={'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'}
                                    content={plan?.outpatientRehabilitation}/>

                    <ListPlanDetail title={"Outpatient Mental Health Care"}
                                    tooltip={'Typically for services provided by a mental health professional in an outpatient setting.'}
                                    content={plan?.mentalHealth}/>

                    <ListPlanDetail title={"Home Health Care"}
                                    tooltip={'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'}
                                    content={plan?.homeHealth}/>

                  </Box>
                </OptionalCollapse>
              </Grid>
            </AscendCard>
          </Element>

          <div className={"h-30"}/>

          <Element name={"Extra Benefits"}>
            <PlanSectionTitle title={"Extra Benefits"}/>
            <AscendCard className={ownClasses.card}>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Additional benefits"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('additionalBenefits') : undefined}
                                onToggleClick={() => toggleExpanded('additionalBenefits')}
                                icon={"/img/teeth.svg"}/>
                <OptionalCollapse inCollapse={phone} in={getExpandedValue('additionalBenefits')}>
                  <Box className={ownClasses.sectionWrapper}>
                    {!!plan!!.optionalSupplementalBenefitsItems?.length && <ListPlanDetail title={"Optional supplemental benefits"}
                                                                                           tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'}
                                                                                           content={plan!!.optionalSupplementalBenefitsItems} thin/>}
                    <ListPlanDetail title={"Dental Services"}
                                    tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                                    content={plan?.dental}/>

                    <ListPlanDetail title={"Vision Services"}
                                    tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                                    content={plan?.vision}/>

                    <ListPlanDetail title={"Hearing Services"}
                                    tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                                    content={plan?.hearing}/>

                    <ListPlanDetail title={"Fitness Benefits"}
                                    tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'}
                                    content={plan?.otherDefinedSupplemental.fitnessBenefit}/>

                    <SimplePlanDetail title={"Insulin Savings"}
                                      tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                      content={<Benefit value={plan.extraBenefits.insulinSavings} label={''} />}/>

                    <ListPlanDetail title={"Meals After Hospital Stay"}
                                    tooltip={'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'}
                                    content={plan?.meal}/>

                    <ListPlanDetail title={"Over-the-Counter Allowance"}
                                    tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                    content={plan?.oTC}/>

                    <ListPlanDetail title={"Telehealth Services"}
                                    tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'}
                                    content={plan?.telehealth}/>

                    <ListPlanDetail title={"Transportation Services"}
                                    tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                                    content={plan?.transportation}/>
                  </Box>
                </OptionalCollapse>
              </Grid>
            </AscendCard>
          </Element>


          <div className={"h-30"}/>

          <Element name={"Plan Brochures"}>
            <PlanSectionTitle title={"Plan Brochures"}/>
            <AscendCard className={ownClasses.card}>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Plan and Medicare Brochures"}
                                wrapperClassName={ownClasses.titleWrapper}
                                expanded={phone ? getExpandedValue('planBrochures') : undefined}
                                onToggleClick={() => toggleExpanded('planBrochures')}
                                icon={"/img/brochures.svg"}/>
                <OptionalCollapse inCollapse={phone} in={getExpandedValue('planBrochures')}>
                  <Box className={ownClasses.sectionWrapper} maxHeight={!phone ? 132 : undefined}>
                    <ListPlanDetail title={"Plan Brochures"}
                                    content={files.filter(i => !!i.url).map(item => <a className={`${classes.link} flex flex-align-center`} target={'_blank'} href={item.url}>
                                      <img src={'/img/download.svg'} className={'mr-8'} />
                                      {item.label}
                                    </a>)}/>
                    <SimplePlanDetail title={"Medicare Brochure(s)"}
                                    content={<a className={`${classes.link} flex flex-align-center`}
                                         onClick={() => {
                                           window.open('https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf', '_blank');
                                           return false;
                                         }}
                                         href={'#'}>
                                        <img src={'/img/download.svg'} className={'mr-8'} />
                                        Medicare & You Handbook (2022)
                                      </a>
                                    }/>
                  </Box>
                </OptionalCollapse>
              </Grid>
            </AscendCard>
          </Element>
        </Box>
}

export default (props: RouteComponentProps<{ zip: string, countyName: string, planId: string }>) => {

  let {zip, countyName, planId} = props.match.params;
  const {isInCompare} = React.useContext(ComparisonContext);
  const {pin} = React.useContext(QuoteContext);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const {data, loading, error} = usePlan({zip, bidId: planId, quoteId: getQuoteId(), countyName, planYear: GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023});
  const [plan, setPlan] = React.useState<PlanWithTiers>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setPlan(data?.plan)
    if ((window as any).track) {
      (window as any).track('MA marketplace - plan details', {source: 'V1', planId: data?.plan.bidId});
    }
  }, [data]);

  React.useEffect(() => {
      if (error) {
        enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'});
      }
  }, [error]);

  return <>
    <Layout pin={pin} sideHeaderContent={config.enableAuth === 'true' ? <LoginWidget/> : <></>} SideComponent={() => <PlanDetailsContent plan={plan}/>}>
      <style>{`
        @media (min-width: 600px) {
          .card-title {
            white-space: nowrap;
          }
        }
      `}
      </style>
      <PlanSummaryModal zip={zip} planId={planId} countyName={countyName} open={modalOpen} onClose={() => setModalOpen(false)} />
      <PlanEmailModal zip={zip} planId={planId} open={emailOpen} county={zip} onClose={() => setEmailOpen(false)} />
      <Box display={'flex'} flexDirection={'column'} alignItems={"center"} mt={'31px'}>
        {loading && <AscendPreloader />}
        {!loading && <>
          <Box maxWidth={800} position={'relative'}>
            <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
              <BackButton
                startIcon={<img src={"/img/arrow-left.svg"}/>}
                onClick={() => history.push(`/plans/${zip}/${countyName}`)}>
                Back to Plans
              </BackButton>
            </Box>
            {!!plan && <PlanView countyName={countyName} plan={plan} zip={zip} setEmailOpen={setEmailOpen} setModalOpen={setModalOpen} />}
          </Box>
        </>}

        <Footer />
      </Box>
    </Layout>
    {zip && <ComparisonPanel zip={zip} countyName={countyName} forceHide={!isInCompare(planId)}/>}
  </>;
}

const CoverageSectionTitle = ({title, description}: { title: string, description: string }) => {

  return (<Box my={6} textAlign={"center"}>
    <Box my={6} textAlign={"center"}>
      <Typography variant={"h2"}>{title}</Typography>
      <Typography variant={"body2"}>{description}</Typography>
    </Box>
  </Box>)
}

const SectionTitleH2 = withStyles((theme: Theme) =>
  createStyles({
    cell: {
      padding: '0 11px!important'
    },
    table: {
      background: 'white!important',
      borderSpacing: 0,
    },
    wrapper: {
      background: 'rgba(232,236,237,0.5)',
      borderRadius: "4px",
      marginLeft: 10,
      marginRight: 10,
      width: 'auto',
      padding: '16px 16px 14px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '16px 16px 14px 15px',
        marginLeft: 0,
        marginRight: 0,
      },
    },
    medium: {
      fontWeight: 500,
    },
    icon: {
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(0) !important',
    },
    button: {
      background: 'rgba(2, 144, 148, 0.5)',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      marginLeft: 5,
      '&:hover': {
        background: 'rgba(2, 144, 148, 0.5)',
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontWeight: 500,
        fontSize: 16,
      },
    }
}))(({classes, ...props}: TitleProps) => (
      <Box className={clsx(classes.wrapper, props.wrapperClassName)}
           display={"flex"}
           flexDirection={"row"}
           alignItems={"center"}
           justifyContent={"space-between"}
           width={1}>

        <Box display={'flex'}
             flexDirection={'column'}
             justifyContent={"center"}
             alignItems={"start"}>
          <Typography className={classes.title} color={'textPrimary'}>{props.title}</Typography>
          {props.subtitle && <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>{props.subtitle}</Typography>}
        </Box>
        <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
          <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={'/img/arrow-white.svg'} />
        </IconButton>
      </Box>
))

type TitleProps = {
  title: string,
  subtitle?: string,
  classes: any,
  expanded: boolean,
  onToggle: (value: boolean) => void,
  wrapperClassName?: string,
}

export const getInitialTooltipByTier = (tier: DrugTierOutput): string => {
  switch (tier) {
    case DrugTierOutput.PreferredGeneric: return 'Typically includes generic drugs that have been available for a long period of time. Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.Generic: return 'Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.PreferredBrand: return 'Typically the brand-name drugs that have been available for a long period of time and are widely accepted. Typically lower-cost among all brand-name drugs.';
    case DrugTierOutput.NonPreferredBrand: return 'Typically includes higher cost than Tier 3, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.Specialty: return 'Typically higher cost than Tier 4, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.SelectCareDrugs: return 'Typically highest cost, non-preferred generic and brand-name drugs.';
  }
}

export const getGapTooltipByTier = (tier: DrugTierOutput): string => {
  switch (tier) {
    case DrugTierOutput.Generic: return 'Chemically equivalent versions of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.NonPreferredBrand: return 'A drug sold by a drug company under a specific name or trademark and that is protected by a patent. ';
  }
  return '';
}

const DrugDetails = ({plan, getExpandedValue, setExpandedState}: {plan: PlanWithTiers, getExpandedValue: (key: string) => boolean, setExpandedState: any}) => {
  const ownClasses = styles();
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));


  const tiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.initialCoverageDrugTiers
    }
  }, [plan]);

  const gapTiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.coverageGapDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.coverageGapDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.coverageGapDrugTiers
    }
  }, [plan]);

  const InitialTable = () => <table className={'mh-10'}>
    <tbody>
    <tr>
      <td></td>
      <td className={ownClasses.headerCell}>Preferred Retail Pharmacy cost-sharing
        (30 day supply)</td>
      <td className={ownClasses.headerCell}>Standard Retail Pharmacy cost-sharing
        (30 day supply)</td>
      <td className={ownClasses.headerCell}>Preferred Mail Order cost-sharing
        (90 day supply) </td>
      <td className={ownClasses.headerCell}>Standard Mail Order cost-sharing
        (90 day supply)</td>
    </tr>
    {plan?.quoteDrugsTiers?.pharmacyName && <tr className={ownClasses.tableRow}>
      <td>Your pharmacy</td>
      <td colSpan={4}><Benefit label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/></td>
    </tr>}
    {tiers?.map((tier, i) => <tr key={tier.tierName} className={ownClasses.tableRow}>
      <td>
        <div className={`flex`}>
          <AscendTooltip placement={'top'} arrow title={getInitialTooltipByTier(tier.tier)}>
            <div className={'flex flex-align-start'}>
              <img  className={`mt-5 mr-8`} src={'/img/info.svg'}></img>
              <div className={`card-title bold`} >{tier.tierName}</div>
            </div>
          </AscendTooltip>
        </div>
      </td>
      {[
        plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage,
        plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage,
        plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage,
        plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage
      ].every( v => v === plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage ) ? (
        <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage}</td>
      ) : <>
        <td>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
      </>}
    </tr>)}
    </tbody>
  </table>;

  const InitialList = () => <div>
    <AscendSubtitle title={"Preferred Retail Pharmacy (30 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={ownClasses.drugSubtitleWrapper}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('preferredRetail30') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, preferredRetail30: value}))}/>
    <Collapse in={getExpandedValue('preferredRetail30')} className={'ph-16'}>
      {plan?.quoteDrugsTiers?.pharmacyName && <SimplePlanDetail title={'Your pharmacy'}
                                                                content={<Benefit label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/>}/>
      }
      {tiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                 key={tier.tierName}
                                                 content={plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getInitialTooltipByTier(tier.tier)} />
                                                 )}
    </Collapse>
    <AscendSubtitle title={"Standard Retail Pharmacy (30 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('standardRetail30') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, standardRetail30: value}))}/>
    <Collapse in={getExpandedValue('standardRetail30')} className={'ph-16'}>
      {tiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                 key={tier.tierName}
                                                 content={plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getInitialTooltipByTier(tier.tier)} />
                                                 )}
    </Collapse>
    <AscendSubtitle title={"Preferred Mail Order (90 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('preferredMail90') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, preferredMail90: value}))}/>
    <Collapse in={getExpandedValue('preferredMail90')} className={'ph-16'}>
      {tiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                 key={tier.tierName}
                                                 content={plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getInitialTooltipByTier(tier.tier)} />
                                                 )}
    </Collapse>
    <AscendSubtitle title={"Standard Mail Order (90 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('standardMail90') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, standardMail90: value}))}/>
    <Collapse in={getExpandedValue('standardMail90')} className={'ph-16'}>
      {tiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                 key={tier.tierName}
                                                 content={plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getInitialTooltipByTier(tier.tier)} />
      )}
    </Collapse>
  </div>

  const GapTable = () => <table className={'mh-10'}>
    <tbody>
    <tr>
      <td></td>
      <td className={ownClasses.headerCell}>Preferred Retail Pharmacy cost-sharing
        (30 day supply)</td>
      <td className={ownClasses.headerCell}>Standard Retail Pharmacy cost-sharing
        (30 day supply)</td>
      <td className={ownClasses.headerCell}>Preferred Mail Order cost-sharing
        (90 day supply) </td>
      <td className={ownClasses.headerCell}>Standard Mail Order cost-sharing
        (90 day supply)</td>
    </tr>
    {gapTiers?.map((tier, i) => <tr key={tier.tierName} className={ownClasses.tableRow}>
      <td>
        <div className={`flex`}>
          <AscendTooltip placement={'top'} arrow title={getGapTooltipByTier(tier.tier)}>
            <div className={'flex flex-align-start'}>
              <img  className={`mt-5 mr-8`} src={'/img/info.svg'}></img>
              <div className={`card-title bold`} >{tier.tierName}</div>
            </div>
          </AscendTooltip>
        </div>
      </td>
      {[
        plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage,
        plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage,
        plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage,
        plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage
      ].every( v => v === plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage ) ? (
        <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage}</td>
      ) : <>
        <td>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
        <td>{plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
      </>}
    </tr>)}
    <tr className={ownClasses.tableRow}>
      <td>
        <AscendTooltip placement={'top'} arrow title={''}>
          <div className={'flex flex-align-start'}>
            <img  className={`mt-5 mr-8`} src={'/img/info.svg'}></img>
            <div className={`card-title bold`} >All other tiers</div>
          </div>
        </AscendTooltip>
      </td>
      <td colSpan={4} style={{textAlign: 'center'}}>25% of retail price</td>
    </tr>
    </tbody>
  </table>

  const GapList = () => <div>
    <AscendSubtitle title={"Preferred Retail Pharmacy (30 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={ownClasses.drugSubtitleWrapper}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('preferredGapRetail30') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, preferredGapRetail30: value}))}/>
    <Collapse in={getExpandedValue('preferredGapRetail30')} className={'ph-16'}>
      {gapTiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                    key={tier.tierName}
                                                 content={plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getGapTooltipByTier(tier.tier)} />
      )}
    </Collapse>
    <AscendSubtitle title={"Standard Retail Pharmacy (30 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('standardGapRetail30') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, standardGapRetail30: value}))}/>
    <Collapse in={getExpandedValue('standardGapRetail30')} className={'ph-16'}>
      {gapTiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                    key={tier.tierName}
                                                 content={plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getGapTooltipByTier(tier.tier)} />
      )}
    </Collapse>
    <AscendSubtitle title={"Preferred Mail Order (90 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('preferredGapMail90') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, preferredGapMail90: value}))}/>
    <Collapse in={getExpandedValue('preferredGapMail90')} className={'ph-16'}>
      {gapTiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                    key={tier.tierName}
                                                 content={plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getGapTooltipByTier(tier.tier)} />
      )}
    </Collapse>
    <AscendSubtitle title={"Standard Mail Order (90 day supply)"}
                    titleClassName={ownClasses.drugSubtitle}
                    wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
                    toggleColor={'#C4C4C4'}
                    expanded={phone ? getExpandedValue('standardGapMail90') : undefined}
                    onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, standardGapMail90: value}))}/>
    <Collapse in={getExpandedValue('standardGapMail90')} className={'ph-16'}>
      {gapTiers?.map((tier, i) => <SimplePlanDetail title={tier.tierName}
                                                    key={tier.tierName}
                                                 content={plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}
                                                 tooltip={getGapTooltipByTier(tier.tier)} />
      )}
    </Collapse>

    <div className={'ph-16'}>
      <SimplePlanDetail title={'All other tiers'}
                        content={'25% of retail price'}
                        tooltip={''} />
    </div>
  </div>

  return <>
    <PlanSectionTitle title={"Drug Coverage & Costs"}/>
    <AscendCard className={ownClasses.card}>
      <Grid container direction={"column"}>
        <Grid item>
          <AscendSubtitle title={"Prescription Drug Costs"}
                          wrapperClassName={ownClasses.titleWrapper}
                          expanded={phone ? getExpandedValue('prescriptionDrugCosts') : undefined}
                          onToggleClick={value => setExpandedState((prevState: any) => ({...prevState, prescriptionDrugCosts: value}))}
                          icon={`/img/pills.svg`}/>
          <Collapse in={getExpandedValue('prescriptionDrugCosts')}>
            <div className={'h-16'} />
            <SectionTitleH2 title={'Deductible phase'}
                            subtitle={phone ? undefined : 'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'}
                            onToggle={value => setExpandedState((prevState: any) => ({...prevState, deductible: value}))}
                            expanded={getExpandedValue('deductible')}
            />
            <Collapse in={getExpandedValue('deductible')}>
              <Box className={ownClasses.sectionWrapper}>
                <ListPlanDetail title={"Deductible amount"}
                                tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                                content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>

              </Box>
            </Collapse>

            {!!plan?.drugDetails?.initialCoverage?.length && <>
              <div className={'h-24'} />
              <SectionTitleH2 title={'Initial Coverage Limit phase'}
                              subtitle={phone ? undefined : plan?.drugDetails?.initialCoverageDescription}
                              onToggle={value => setExpandedState((prevState: any) => ({...prevState, initialCoverage: value}))}
                              expanded={getExpandedValue('initialCoverage')}
              />
              <Collapse in={getExpandedValue('initialCoverage')}>
                <div className={'h-16'} />
                {phone ? <InitialList/> : <InitialTable />}
              </Collapse>
            </>}

            <div className={'h-24'} />
            <SectionTitleH2 title={'Coverage Gap phase'}
                            subtitle={phone ? undefined : plan?.drugDetails?.coverageGapDescription}
                            onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                            expanded={getExpandedValue('gapCoverage')}
            />
            <Collapse in={getExpandedValue('gapCoverage')}>
              <div className={'h-16'} />
              {phone ? <GapList/> : <GapTable />}
            </Collapse>

            <div className={'h-24'} />
            <SectionTitleH2 title={'Catastrophic Coverage phase'}
                            subtitle={phone ? undefined : plan?.drugDetails?.catastrophicCoverageDescription}
                            onToggle={value => setExpandedState((prevState: any) => ({...prevState, catastrophicCoverage: value}))}
                            expanded={getExpandedValue('catastrophicCoverage')}
            />
            <Collapse in={getExpandedValue('catastrophicCoverage')}>
              <Box className={ownClasses.sectionWrapper}>
                {plan?.drugDetails?.catastrophicCoverage[0].items.map((item, i) => <ListPlanDetail
                  key={item.title}
                  title={item.title}
                  tooltip={getGapTooltipByTier([DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][i])}
                  content={item.values}/>)}
              </Box>
            </Collapse>
          </Collapse>
        </Grid>
      </Grid>
    </AscendCard>
    </>
}


const SNPDrugDetails = ({plan, getExpandedValue, setExpandedState}: {plan: PlanWithTiers, getExpandedValue: (key: string) => boolean, setExpandedState: any}) => {
  const ownClasses = styles();
  const classes = planStyles();

  return <>
    <PlanSectionTitle title={"Drug Coverage & Costs"}/>
    <AscendCard className={ownClasses.card}>
      <Grid container direction={"column"}>
        <Grid item>
          <AscendSubtitle title={"Prescription Drug Costs"} icon={`/img/pills.svg`}/>
          <div className={'h-24'} />
          <SectionTitleH2 title={'Deductible phase'}
                          subtitle={'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, deductible: value}))}
                          expanded={getExpandedValue('deductible')}
          />
          <Collapse in={getExpandedValue('deductible')}>
            <Box className={ownClasses.sectionWrapper}>
              <ListPlanDetail title={"Deductible amount"}
                              tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                              content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>

            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Initial Coverage Limit phase'}
                          subtitle={plan?.drugDetails?.initialCoverageDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, initialCoverage: value}))}
                          expanded={getExpandedValue('initialCoverage')}
          />
          <Collapse in={getExpandedValue('initialCoverage')}>
            <Box className={ownClasses.sectionWrapper}>
              <ListPlanDetail title={"All Tiers"}
                              content={['[b]Generic drugs:[/b]', '$0 copay; or', '$1.30 copay; or', '$3.70 copay; or', '15%', <br />, '[b]Brand-name drugs:[/b]', '$0 copay; or', '$4.00 copay; or', '$9.20 copay; or', '15%', <br />, '(depending on your level of Extra Help)']}/>
            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Coverage Gap phase'}
                          subtitle={plan?.drugDetails?.coverageGapDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                          expanded={getExpandedValue('gapCoverage')}
          />
          <Collapse in={getExpandedValue('gapCoverage')}>
            <Box className={ownClasses.sectionWrapper}>
              <ListPlanDetail title={"All Tiers"}
                              content={['[b]Generic drugs:[/b]', '$0 copay; or', '$1.30 copay; or', '$3.70 copay; or', '15%', <br />, '[b]Brand-name drugs:[/b]', '$0 copay; or', '$4.00 copay; or', '$9.20 copay; or', '15%', <br />, '(depending on your level of Extra Help)']}/>
            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Catastrophic Coverage phase'}
                          subtitle={plan?.drugDetails?.catastrophicCoverageDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                          expanded={getExpandedValue('gapCoverage')}
          />
          <Box className={ownClasses.sectionWrapper}>
            <SimplePlanDetail title={"Generic drugs"}
                              content={'$0 or $3.70 copay (depending on your level of Extra Help)'}/>
            <SimplePlanDetail title={"Branded drugs"}
                              content={'$0 or $9.20 copay (depending on your level of Extra Help)'}/>
          </Box>
        </Grid>
      </Grid>
    </AscendCard>
    </>
}
