import {Box, createStyles, IconButton, Theme, withStyles} from "@material-ui/core";
import React from "react";
import * as Scroll from "react-scroll";
import clsx from "clsx";
import {colors} from "../../../../shared/AppTheme";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      background: 'rgba(2, 144, 148, 0.05)',
      marginBottom: 6,
    },
    title: {
      fontSize: '20px',
      fontWeight: 500,
      color: colors.custom.green.variant2,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(0) !important',
    },
    button: {
      background: (props: IAscendSubtitleProps) => props.toggleColor ? props.toggleColor : 'rgba(2, 144, 148, 0.5)',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      marginLeft: 5,
      '&:hover': {
        background: (props: IAscendSubtitleProps) => props.toggleColor ? props.toggleColor : 'rgba(2, 144, 148, 0.5)',
      }
    },
    img: {
      minWidth: 30,
      height: 40,
    }
  })
);

export const AscendSubtitle = (props: IAscendSubtitleProps) => {
  const classes = styles(props);
  const {title, icon, expanded, onToggleClick, wrapperClassName, titleClassName} = props;
  return (

    <Box p={'5px 30px'}
         className={clsx(classes.wrapper, wrapperClassName)}
         display={"flex"}
         flexDirection={"row"}
         alignItems={"center"}
         justifyContent={"space-between"}
         width={1}>

      <Box display={'flex'}
           alignItems={"center"}
           justifyContent={"start"}>
        {icon &&
          <Box mr={'10px'}><img className={classes.img} src={icon}/></Box>
        }
        <Box className={clsx(classes.title, titleClassName)}>{title}</Box>
      </Box>
      {typeof expanded !== 'undefined' && <IconButton onClick={() => onToggleClick && onToggleClick(!expanded)} className={classes.button}>
        <img className={clsx(classes.icon, expanded && classes.iconOpen)} src={'/img/arrow-white.svg'} />
      </IconButton>}
    </Box>

  )
}


interface IAscendSubtitleProps {
  wrapperClassName?: string,
  titleClassName?: string,
  title: string;
  icon?: string;
  toggleColor?: string;
  expanded?: boolean;
  onToggleClick?: (value: boolean) => void;
}

