import React from "react";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import {RouteComponentProps, useHistory} from "react-router-dom";
import PlanCard, {planStyles, RoundedButton} from "../PlanList/PlanCardOld";
import AscendCard from "../../../shared/components/AscendCard";
import {SimplePlanDetail} from "./components/SimplePlanDetail";
import {ListPlanDetail} from "./components/ListPlanDetail";
import {AscendSubtitle} from "./components/AscendSubtitle";
import {PlanSectionTitle} from "./components/PlanSectionTitle";
import Layout from "../../../shared/Layout";
import PlanDetailsContent from "./PlanDetailsContent";
import * as Scroll from "react-scroll";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import ComparisonPanel from "../PlanComparison/ComparisonPanel";
import {makeStyles} from "@material-ui/core/styles";
import Benefit from "../../../shared/components/Benefit";
import {colors} from "../../../shared/AppTheme";
import {QuoteContext} from "../../shared/QuoteContext";
import PlanEmailModal from "./components/PlanEmailModal";
import Footer from "../Footer";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import LoginWidget from "../../shared/components/LoginWidget";
import {useSnackbar} from "notistack";
import BackButton from "../../shared/components/BackButton";
import OptionalCollapse from "../../shared/components/OptionalCollapse";
import useMedigapPlan from "./hooks/usePlan";
import {addDetailsToPlan, MedigapPlanOutputWithDetails} from "./hooks/usePlansByBid";
import HeaderCard from "./components/HeaderCard";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

var Element = Scroll.Element;
const Link = Scroll.Link;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& ul": {
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
        listStyle: 'inside',
        fontSize: 12,
        color: colors.text.primary,
        lineHeight: '20px',
        '& li span': {
          marginLeft: -5
        }
      }
    },
    label: {
      color: colors.custom.green.variant2,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 400,
    },
    headerCard: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCardFixed: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      height: 117,
      width: '100%',
      padding: 15,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '24px 0 24px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '17.5px',
      color: colors.text.primary,
      background: 'rgba(215, 229, 250, 0.2)',
      padding: '10px 15px',
    },
    tableRow: {
      '& td': {
        whiteSpace: 'nowrap',
        color: colors.text.primary,
        fontSize: 12,
        padding: 15,
        borderBottom: '1px solid #E8ECED',
        textAlign: 'left',
      },
      '& td:first-child': {
        fontWeight: 700,
      }
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '19px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
    },
    sectionWrapper: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
    drugSubtitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colors.text.primary
    },
    list: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingInlineStart: 0,
      listStyle: 'inside',
      '& li': {
        fontSize: 12,
        color: colors.text.primary,
        lineHeight: '20px',
        fontWeight: 400,
      }
    }
  })
);

export interface PlanViewProps {
  zip: string,
  countyName: string,
  plan: MedigapPlanOutputWithDetails;
  setModalOpen: (value: boolean) => void;
  setEmailOpen: (value: boolean) => void;
}

export const PlanView = (props: PlanViewProps) => {

  let {zip, plan, setModalOpen, countyName} = props;
  const {preferredDrugs, preferredPharmacies} = React.useContext(QuoteContext);
  const [expandedState, setExpandedState] = React.useState<{[key: string]: boolean}>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const toggleExpanded = (key: string) => {
    const newValue = expandedState[key] === undefined ? false : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue
    })
  }

  const ownClasses = styles();
  const classes = planStyles();

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === 'undefined' ? true : expandedState[key];

  const convert = (value: string | boolean): string | JSX.Element => {
    return typeof value === 'string' ? value : <Benefit label={''} value={value} />;
  }

  const files = React.useMemo(() => {
    return [] as any; //planFiles(plan?.planFiles);
  }, [plan])

  return <Box justifyContent={"center"} className={ownClasses.container}>

          <Box position={'sticky'} top={43} zIndex={5}>
            {phone ? <HeaderCard {...props} /> : <PlanCard {...props} />}
          </Box>

          <div className={"h-40"}/>

          <Element name={"Key Plan Benefits Overview"}>
            <PlanSectionTitle title={"Key Plan Benefits Overview"}/>

            <AscendCard className={`${ownClasses.card}`}>
              <Element name={"Carrier Overview"}></Element>
              <AscendSubtitle title={"Carrier Overview"}
                              expanded={phone ? getExpandedValue('carrierOverview') : undefined}
                              onToggleClick={() => toggleExpanded('carrierOverview')}
                              icon={"/img/medical-insurance.svg"}/>
              <OptionalCollapse inCollapse={phone} in={getExpandedValue('carrierOverview')}>
                <Box className={ownClasses.sectionWrapper}>
                  <SimplePlanDetail title={"Plan Type"} tooltip={'There are 10 standardized Medigap plans. Each plan offers different levels for how much of your costs they’ll pay for, and as such they also have slightly different cost levels. They’re named by letter, like Plan A, and Plan B. Not all plans are offered in every state.\nIf you live in Massachusetts, Minnesota, or Wisconsin, your state offers different standardized plans."'} content={plan!!.planName} />
                  <SimplePlanDetail title={"AM Best Company Credit Rating"} tooltip={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'} content={plan!!.amBestRating} />
                  <SimplePlanDetail title={"S&P Company Credit Rating"} tooltip={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'} content={plan!!.spRating} />
                </Box>
              </OptionalCollapse>
              <div className={'h-20'} />
              <Element name={"PlanCoverageOverview"}></Element>
              <AscendSubtitle title={"Plan Highlights"}
                              expanded={phone ? getExpandedValue('planHighlights') : undefined}
                              onToggleClick={() => toggleExpanded('planHighlights')}
                              icon={"/img/medical-record-1.svg"}/>
              <OptionalCollapse inCollapse={phone} in={getExpandedValue('planHighlights')}>
                <Box className={ownClasses.sectionWrapper}>
                  <SimplePlanDetail title={"Monthly Premium"} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'} content={plan!!.monthlyPremium}/>

                  <SimplePlanDetail title={"Household Discount"} tooltip={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'} content={plan?.householdDiscount || 'n.a.'}/>

                  <SimplePlanDetail title={"Annual Deductible"}
                                    tooltip={'The amount you must pay out-of-pocket for Part A and Part B covered services before the plan\'s coverage starts.'}
                                    content={<div>
                                      <Typography variant={"h5"} color={"textPrimary"}>Part A: {plan.deductibles.partA}</Typography>
                                      <Typography variant={"h5"} color={"textPrimary"}>Part B: {plan.deductibles.partB}</Typography>
                                    </div>}/>

                  <SimplePlanDetail title={"Doctors and Hospitals"} tooltip={'With Original Medicare and a Medigap plan, you have the flexibility to use any doctor or hospital around the country that accepts Medicare. As opposed to Medicare Advantage plans that may require you to use a "network" of doctors and hospitals, there is no "network" for Medigap plans.'} content={'Any Doctor or medical provider that accepts Medicare patients'}/>

                  <SimplePlanDetail title={"Maximum out-of-pocket cost"}
                                    tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes costs for Part A and Part B services. Once you reach this limit, the health plan typically pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                                    content={plan.outOfPocketLimits}/>

                  <SimplePlanDetail title={"Part B Excess Charges"}
                                    tooltip={'An amount a provider can charge over the Medicare-approved amount. Providers can charge up to 15% over the standard Medicare rates.'}
                                    content={<Benefit labelClass={ownClasses.label}
                                                      value={plan.partBExcessCharges}
                                                      label={''} />
                                    }/>

                  <SimplePlanDetail title={"Foreign Travel Emergency"}
                                    tooltip={'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover the care.'}
                                    content={convert(plan.foreignTravelEmergency)}/>

                  <SimplePlanDetail title={"Prescription Drug Coverage"}
                                    tooltip={'Coverage for prescription drugs bundled with hospital and medical coverage.'}
                                    content={<div>
                                      <Benefit value={false} label={''}/>
                                      <Typography className={'fs-12 mt-5'} color={'textPrimary'}>Purchase a standalone Prescription Drug Plan</Typography>
                                    </div>}/>

                  <SimplePlanDetail title={<div>
                    <Typography className={'fs-12 bold'} color={"textPrimary"}>Additional Benefits, such as:</Typography>
                    <ul className={ownClasses.list}>
                      <li>
                        <AscendTooltip placement={'top'} arrow title={'Coverage for dental benefits provided at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment.'}>
                          <span>Dental coverage</span>
                        </AscendTooltip>
                      </li>
                      <li>
                        <AscendTooltip placement={'top'} arrow title={'Coverage for vision benefits provided at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}>
                          <span>Vision coverage</span>
                        </AscendTooltip>
                      </li>
                      <li>
                        <AscendTooltip placement={'top'} arrow title={'Coverage for hearing benefits provided at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}>
                          <span>Hearing coverage</span>
                        </AscendTooltip>
                      </li>
                    </ul>
                  </div>}
                                    tooltip={''}
                                    content={<div>
                                      <Benefit labelClass={ownClasses.label} value={plan.showAdditionalBenefitsDescription} label={''}/>
                                      <Typography className={'fs-12 mt-5'} color={'textPrimary'}>{plan.showAdditionalBenefitsDescription ? 'Plan includes some additional benefits. Call us to find out more.' : 'Purchase standalone dental, vision and hearing plans'}</Typography>
                                    </div>
                                    }/>

                </Box>
              </OptionalCollapse>
            </AscendCard>
          </Element>

          <div className={"h-40"}/>

          <Element name={"Coverage Details"}>
            <PlanSectionTitle title={"Coverage Details"}/>
            <AscendCard className={ownClasses.card}>
              <AscendSubtitle title={"Your costs for Hospital Coverage (Part A)"}
                              expanded={phone ? getExpandedValue('hospitalCoverage') : undefined}
                              onToggleClick={() => toggleExpanded('hospitalCoverage')}
                              icon={"/img/hospital-2.svg"}/>

              <OptionalCollapse inCollapse={phone} in={getExpandedValue('hospitalCoverage')}>
                <Box className={ownClasses.sectionWrapper}>
                  <SimplePlanDetail title={"Part A Deductible"}
                                    tooltip={'The amount you must pay out of pocket for your Part A (Hospital) costs before your coverage begins to pay.'}
                                    content={plan.partADeductible}/>
                  <SimplePlanDetail title={"Inpatient Hospital Coverage"}
                                    tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'}
                                    contentClassName={'pre'}
                                    content={plan.inpatientHospitalCoverage}/>
                  <SimplePlanDetail title={"Skilled Nursing Facilities"}
                                    tooltip={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}
                                    contentClassName={'pre'}
                                    content={plan.skilledNursingFacilities}/>
                  <SimplePlanDetail title={"Blood Transfusion (during a hospital stay)"}
                                    tooltip={'Blood received as part of a procedure during inpatient hospital treatment. Original Medicare does not cover blood transfusions, unless the blood is donated.'}
                                    contentClassName={'pre'}
                                    content={plan.bloodTransfusionDuring}/>
                  <SimplePlanDetail title={"Hospice Care"}
                                    contentClassName={'pre'}
                                    tooltip={'Hospice care is comprehensive care for people who are terminally ill. Hospice care includes pain management, counseling, respite care, prescription drugs, inpatient care and outpatient care, and services to support the terminally ill person\'s family.'}
                                    content={convert(plan.hospiceCareCopay)}/>
                </Box>
              </OptionalCollapse>


              <div className={"h-20"}/>
              <AscendSubtitle title={"Your costs for Medical Coverage (Part B)"}
                              expanded={phone ? getExpandedValue('medicalCoverage') : undefined}
                              onToggleClick={() => toggleExpanded('medicalCoverage')}
                              icon={"/img/man.svg"}/>

              <OptionalCollapse inCollapse={phone} in={getExpandedValue('medicalCoverage')}>
                <Box className={ownClasses.sectionWrapper}>

                  <SimplePlanDetail title={"Part B Deductible"}
                                    contentClassName={'pre'}
                                    tooltip={'The amount you must pay out of pocket for your Part B (Medical) costs before your coverage begins to pay.'}
                                    content={convert(plan.partBDeductible)}/>

                  <SimplePlanDetail title={<div>
                    <Typography className={'fs-12 bold'} color={"textPrimary"}>Covered Part B services, including:</Typography>
                    <ul className={ownClasses.list}>
                      <li>Primary care doctor office visits</li>
                      <li>Specialist doctor office visits</li>
                      <li>Emergency room</li>
                      <li>Ambulance services</li>
                      <li>Outpatient surgery</li>
                      <li>Outpatient rehabilitation services</li>
                    </ul>
                  </div>}
                                    contentClassName={'pre'}
                                    tooltip={'Medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'} content={plan.coveredPartBServices}/>

                  <SimplePlanDetail title={"Preventive Care"}
                                    contentClassName={'pre'}
                                    tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'}
                                    content={plan.preventiveCare}/>

                  <SimplePlanDetail title={"Blood Transfusion (outside a hospital stay)"}
                                    tooltip={"Blood received as part of a procedure in an outpatient hospital setting. Original Medicare does not cover blood transfusions, unless the blood is donated."}
                                    contentClassName={'pre'}
                                    content={plan.bloodTransfusionOutside}/>


                  <SimplePlanDetail title={"Tests for Diagnostic Services"}
                                    tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'}
                                    contentClassName={'pre'}
                                    content={plan.testsForDiagnosticServices}/>
                </Box>
              </OptionalCollapse>

              <div className={"h-20"}/>
              <AscendSubtitle title={"Your costs for services covered under Parts A and B"}
                              expanded={phone ? getExpandedValue('servicesCovered') : undefined}
                              onToggleClick={() => toggleExpanded('servicesCovered')}
                              icon={"/img/healthcare.svg"}/>

              <OptionalCollapse inCollapse={phone} in={getExpandedValue('servicesCovered')}>
                <Box className={ownClasses.sectionWrapper}>

                  <SimplePlanDetail title={"Home Health Care"}
                                    tooltip={'Health care services and supplies that a doctor orders to be received in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'}
                                    contentClassName={'pre'}
                                    content={plan.homeHealthCare}/>

                  <SimplePlanDetail title={"Durable Medical Equipment"}
                                    contentClassName={'pre'}
                                    tooltip={'Certain medical equipment, like a wheelchair, walker or special hospital bed, that is ordered by your doctor for use in the home.'}
                                    content={plan.durableMedicalEquipment}/>
                </Box>
              </OptionalCollapse>
            </AscendCard>
          </Element>

          <div className={"h-40"}/>

          <Element name={"Plan Brochures"}>
            <PlanSectionTitle title={"Plan Brochures"}/>
            <AscendCard className={ownClasses.card}>
              <AscendSubtitle title={"Plan and Medicare Brochures"}
                              expanded={phone ? getExpandedValue('planBrochures') : undefined}
                              onToggleClick={() => toggleExpanded('planBrochures')}
                              icon={"/img/brochures.svg"}/>
              <OptionalCollapse inCollapse={phone} in={getExpandedValue('planBrochures')}>
                <Box className={ownClasses.sectionWrapper} maxHeight={!phone ? 132 : undefined}>
                  {/*<ListPlanDetail title={"Plan Brochures"}
                                  content={files.filter((i: any) => !!i.url)
                                    .map((item: any) => <a className={`${classes.link} flex flex-align-center`} target={'_blank'} href={item.url}>
                                      <img src={'/img/download.svg'} className={'mr-8'} />
                                      {item.label}
                                    </a>)}/>*/}
                  <SimplePlanDetail title={"Medicare Brochure(s)"}
                                  content={<a className={`${classes.link} flex flex-align-center`}
                                       onClick={() => {
                                         window.open('https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf', '_blank');
                                         return false;
                                       }}
                                       href={'#'}>
                                      <img src={'/img/download.svg'} className={'mr-8'} />
                                      Medicare & You Handbook (2022)
                                    </a>
                                  }/>
                </Box>
              </OptionalCollapse>
            </AscendCard>
          </Element>
        </Box>
}

export default (props: RouteComponentProps<{ zip: string, countyName: string, planId: string }>) => {

  let {zip, countyName, planId} = props.match.params;
  const {isInCompare} = React.useContext(ComparisonContext);
  const {pin} = React.useContext(QuoteContext);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const {error, loading, data} = useMedigapPlan({key: planId});
  const [plan, setPlan] = React.useState<MedigapPlanOutputWithDetails>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setPlan(addDetailsToPlan(data?.medigapPlan));
  }, [data])

  React.useEffect(() => {
      if (error) {
        enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'});
      }
  }, [error]);

  if (loading) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <AscendPreloader />
      <Footer/>
    </Box>
  }

  return <>
      <Layout pin={pin} SideComponent={() => <PlanDetailsContent plan={plan}/>}>
      <PlanEmailModal zip={zip} planId={planId} open={emailOpen} county={zip} onClose={() => setEmailOpen(false)} />
      <Box display={'flex'} flexDirection={'column'} alignItems={"center"} mt={'31px'} >
        <Box maxWidth={800} width={'100%'} position={'relative'}>
          <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
            <BackButton
              startIcon={<img src={"/img/arrow-left.svg"}/>}
              onClick={() => history.push(`/plans/${zip}/${countyName}`)}>
              Back to Plans
            </BackButton>
          </Box>
          {!!plan && <PlanView countyName={countyName} plan={plan} zip={zip} setEmailOpen={setEmailOpen} setModalOpen={setModalOpen} />}
        </Box>

        <Footer />
      </Box>

    </Layout>
    {zip && <ComparisonPanel zip={zip} countyName={countyName} forceHide={!isInCompare(planId)}/>}
  </>
}
