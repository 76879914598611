import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../../../shared/EnrollmentApolloClient";


export function usePatchClientPersonalInfo(options?: MutationHookOptions<{patchClientPersonalInfo: boolean}>) {
  return useMutation<{patchClientPersonalInfo: boolean}>(
    gql(saveEnrollmentMutation),
    {
      client,
      ...options
    }
  );
}

const saveEnrollmentMutation = `
mutation ($clientId: UUID!, $preferredDrugs: [PreferredDrugInput!], $preferredPharmacies: [String!], $drugDeliveryType: ClientDrugDeliveryType) {
  patchClientPersonalInfo(clientId: $clientId, preferredDrugs: $preferredDrugs, preferredPharmacies: $preferredPharmacies, drugDeliveryType: $drugDeliveryType)
}
`;
