import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step9";
import {MedigapQuoteActionTypes, MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import {withTitle} from "../../../shared/components/withTitle";
import useUrlParams from "../../../shared/hooks/useUrlParams";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";

function Step9(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);
  const params = useUrlParams(['zip', 'county', 'age', 'gender']);

  if ((window as any).track) {
    (window as any).track(`Page View - ${(window as any).product} - Personal info`);
  }

  React.useEffect(() => {
    if (params.zip && params.county && params.age && params.gender) {
      dispatch({
        type: MedigapQuoteActionTypes.SAVE_STEP_VALUE_SKIP_SERVER_SYNC,
        payload: {stateKey: 'STEP_3', form: 'STEP_3', age: params.age, gender: params.gender, tobacco: 'false'}
      })
      dispatch({
        type: MedigapQuoteActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'STEP_2', form: 'STEP_2', zip: params.zip, countyName: params.county}
      })
    }
  }, [params]);

  return <Shared quote={quote} dispatch={dispatch}
                 onNextStep={() => {
                   props.history.push(paths.MGquestionnaire + "/" + questionsRoutes.step9a);
                 }}
                 {...props} />;
}

export default withTitle(
  Step9,
  'One last step.',
  'Create your account to access your personalized Medicare plan finder dashboard.',
  undefined,
  undefined,
  true,
)
