import React from "react";
import {handleCollectionChange} from "../../shared/filterUtils";
import * as QueryString from "query-string";
import {useParams} from "react-router";
import {PlanFilterDataProvider} from "./PlanFilterDataContext";
import {Gender, MedigapPlanBenefit, MedigapPlanName, MedigapPlansFilterInput, PageInput} from "../../../medigap-types";
import {planCategories, PlanCategory} from "../Category/Category";
import {MedigapQuoteContext} from "../../shared/MedigapQuoteContext";
import useSaveMedigapQuote from "../../../Quote/Medigap/hooks/useSaveMedigapQuote";
import {getMedigapQuoteId, setMedigapQuoteId} from "../../../shared/QuoteId";
import {useMedigapFilterState} from "./hooks/useMedigapFilterState";
import {PreloaderContext} from "../../shared/PreloaderContext";
import moment from "moment";
import {config} from "../config/config";
import {getCId} from "../../../shared/utils";

export interface IPlansFilter extends MedigapPlansFilterInput {
  age: number;
  applyDiscounts?: number;
  applyFees?: number;
  county?: string;
  effectiveDate?: string;
  gender: Gender;
  page?: PageInput;
  selectedPlanCategory: PlanCategory;
  benefits: MedigapPlanBenefit[];
  planNames: MedigapPlanName[];
  monthlyPlanPremiumRanges: string[];
  companies: string[];
  select?: boolean;
  tobacco: boolean;
  zip: string;
}

interface PlanFilterContextState {
  values?: IPlansFilter,
  switchZip: (zip: string, county: string) => Promise<any>,
  toggleFavorites: () => void,
  switchBenefit: (name: MedigapPlanBenefit) => void,
  switchPlan: (name: MedigapPlanName) => void,
  switchAge: (age: number) => void,
  switchFees: (fee: number) => void,
  switchCategory: (value: PlanCategory) => void,
  switchEffectiveDate: (date?: string) => void,
  switchCompanies: (value: string) => void,
  switchMonthlyPremium: (value: string) => void,
  switchDiscounts: (discount: number) => void,
  switchGender: (name: Gender) => void,
  toggleTobacco: (v: boolean) => void,
  toggleSelect: (v: boolean) => void,
  refresh: () => void,
  reset: () => void,
}

const defaultState: PlanFilterContextState = {
  values: {
    age: 0,
    gender: Gender.M,
    select: false,
    tobacco: false,
    zip: '',
    monthlyPlanPremiumRanges: [],
    planNames: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
    benefits: [],
    companies: [],
    selectedPlanCategory: PlanCategory.MostPopular
  },
  switchZip: (zip: string, county: string) => (zip as any),
  toggleFavorites: () => {},
  switchPlan: (name: MedigapPlanName) => {},
  switchAge: (age: number) => {},
  switchFees: (fee: number) => {},
  switchCompanies: (value: string) => {},
  switchBenefit: (value: MedigapPlanBenefit) => {},
  switchCategory: (value: PlanCategory) => {},
  switchMonthlyPremium: (value: string) => {},
  switchEffectiveDate: (date?: string) => {},
  switchDiscounts: (discount: number) => {},
  switchGender: (name: Gender) => {},
  toggleTobacco: () => {},
  toggleSelect: () => {},
  refresh: () => {},
  reset: () => {},
};

export const PlanFilterContext = React.createContext<PlanFilterContextState>(defaultState);

export function PlanFilterProvider(props: React.PropsWithChildren<PlanFilterProviderProps>) {

  const quote = React.useContext(MedigapQuoteContext);
  const {setPreloader} = React.useContext(PreloaderContext)

  const [filters, setFilters] = React.useState<IPlansFilter>();

  const [save] = useSaveMedigapQuote();
  const [getFilterState, filterStateData] = useMedigapFilterState();
  const [filterStateId, setFilterStateId] = React.useState();

  React.useEffect(() => {
    if (filters) {
      localStorage.setItem('medigapFilters', JSON.stringify(filters))
    }
  }, [filters])

  React.useEffect(() => {
    const params = QueryString.parse(document.location.search);
    if (params.zip && params.county) {
      const storedFilters = localStorage.getItem('medigapFilters');
      let data: any = props.initFilter;
      if (storedFilters) {
        data = JSON.parse(storedFilters);
      }
      data.zip = params.zip;
      data.county = params.county;
      if (params.age) {
        data.age = params.age;
      }
      if (params.gender) {
        data.gender = params.gender
      }
      localStorage.setItem('medigapFilters', JSON.stringify(data));
    }
  }, []);

  React.useEffect(() => {
      setPreloader('filterStateData', filterStateData.loading)
  }, [filterStateData.loading]);

  const getEffectiveDate = (value?: string | null): string => {
    let effectiveDate = moment(value);
    if (!effectiveDate.isValid() || effectiveDate.isBefore(moment().startOf('day'))) {
      effectiveDate = moment();
    }
    return effectiveDate.format('YYYY-MM-DD');
  }
  
  React.useEffect(() => {
      if (filterStateData.data) {
        const updated = {
          tobacco: filterStateData.data.medigapFilterState.tobacco,
          age: filterStateData.data.medigapFilterState.age,
          zip: filterStateData.data.medigapFilterState.zip,
          county: filterStateData.data.medigapFilterState.county || props.initFilter.county,
          benefits: filterStateData.data.medigapFilterState.benefits || [],
          companies: filterStateData.data.medigapFilterState.companies || [],
          gender: filterStateData.data.medigapFilterState.gender,
          monthlyPlanPremiumRanges: filterStateData.data.medigapFilterState.monthlyPlanPremiumRanges || [],
          planNames: filterStateData.data.medigapFilterState?.planNames?.length ? filterStateData.data.medigapFilterState.planNames : [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
          selectedPlanCategory: filterStateData.data.medigapFilterState.planCategory as PlanCategory || PlanCategory.MostPopular,
          effectiveDate: getEffectiveDate(filterStateData.data.medigapFilterState.effectiveDate)
        }
        setFilters(updated);
      }
  }, [filterStateData]);

  React.useEffect(() => {
    const params = QueryString.parse(document.location.search);
    const storedFilters = localStorage.getItem('medigapFilters');
    const parsed = JSON.parse(storedFilters || '{}');

    if (quote?.medigapFilterState?.id) {
      setFilterStateId(quote?.medigapFilterState?.id);
    }

    if (params.filters) {
      setFilterStateId(params.filters);
      getFilterState({variables: {id: params.filters}});
    } else {
      if (quote?.id) {
        setFilters(() => {
          let result: IPlansFilter;
          if (params.resetFilters) {
            if (quote.medigapFilterState) {
              return {
                tobacco: quote.medigapFilterState.tobacco,
                age: quote.medigapFilterState.age,
                zip: quote.medigapFilterState.zip,
                county: quote.medigapFilterState.county || props.initFilter.county,
                benefits: quote.medigapFilterState.benefits || [],
                companies: quote.medigapFilterState.companies || [],
                gender: quote.medigapFilterState.gender,
                monthlyPlanPremiumRanges: quote.medigapFilterState.monthlyPlanPremiumRanges || [],
                planNames: quote.medigapFilterState?.planNames?.length ? quote.medigapFilterState.planNames : [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
                selectedPlanCategory: quote.medigapFilterState.planCategory as PlanCategory || PlanCategory.MostPopular,
                effectiveDate:getEffectiveDate(quote.medigapFilterState.effectiveDate)
              }
            } else {
              result = props.initFilter
            }
          } else {
            if (storedFilters) {
              if (parsed.zip !== props.initFilter.zip) {
                parsed.zip = props.initFilter.zip
              }
              if (parsed.county !== props.initFilter.county) {
                parsed.county = props.initFilter.county
              }
              result = parsed;
            } else {
              result = props.initFilter
            }
          }
          return result;
        })
      } else {
        setFilters(() => {
          if (storedFilters) {
            if (parsed.zip !== props.initFilter.zip) {
              parsed.zip = props.initFilter.zip
            }
            if (parsed.county !== props.initFilter.county) {
              parsed.county = props.initFilter.county
            }
            return parsed;
          } else {
            return props.initFilter
          }
        })
      }
    }
  }, [quote])

  function switchFilter<T>(key: T, filterKey: keyof IPlansFilter) {
    setFilters(prev => {
      if (prev) {
        let newInstance: any = {...prev}
        if (Array.isArray(prev[filterKey])) {
          newInstance[filterKey] = handleCollectionChange<T>(key, prev[filterKey] as any)
        } else {
          if (filterKey === 'selectedPlanCategory') {
            prev.planNames = planCategories.find(c => c.name === key as any)?.plans || [];
          }
          newInstance = {...prev, [filterKey]: prev[filterKey] as any === key ? undefined : key};
        }
        saveQuote(newInstance);
        return newInstance;
      }
      return prev;
    })
  }

  const saveQuote = (data?: IPlansFilter) => {
    const medigapFilterState: any = data ? {...data} : {...filters};
    medigapFilterState.planCategory = medigapFilterState.selectedPlanCategory;
    delete medigapFilterState.selectedPlanCategory;
    medigapFilterState.id = filterStateId;
    return save({
      variables: {
        input: {
          cId: getCId(),
          id: getMedigapQuoteId(),
          medigapFilterState,
          userId: quote?.userId
        }
      }
    }).then(res => setMedigapQuoteId(res?.data?.saveMedigapQuote as string));
  }

  function refresh() {
    setFilters(prev => {
      return prev ? {...prev} : prev;
    })
  }

  const contextValue = React.useMemo<PlanFilterContextState>(() => (
      {
        values: filters,
        reset: () => {
          setFilters(props.initFilter)
        },
        switchZip: (zip: string, county: string) => {
          const updated = {...filters, zip, county};
          setFilters(prev => {
            return prev ? {...prev, ...updated} : prev;
          })
          return saveQuote(updated as any);
        },
        toggleFavorites: () => {
          // todo implement favorites
          /*setFilters(prev => {
            if (prev) {
              return {...prev, onlyFavorite: !prev.onlyFavorite}
            }
            return prev;
          })*/
        },
        toggleSelect: (value: boolean) => {
          switchFilter<boolean>(value,'select')
        },
        switchAge: (value: number) => {
          switchFilter<number>(value,'age')
        },
        toggleTobacco: (value: boolean) => {
          switchFilter<boolean>(value,'tobacco')
        },
        switchDiscounts: (value: number) => {
          switchFilter<number>(value,'applyDiscounts')
        },
        switchFees: (value: number) => {
          switchFilter<number>(value,'applyFees')
        },
        switchEffectiveDate: (date?: string) => {
          switchFilter<string | undefined>(date,'effectiveDate')
        },
        switchGender: (value: Gender) => {
          switchFilter<Gender>(value,'gender')
        },
        switchPlan: (value: MedigapPlanName) => {
          if ((window as any).track) {
            (window as any).track('MG marketplace - choose plan type filter', {source: 'V1', value: value});
          }
          switchFilter<MedigapPlanName>(value,'planNames')
        },
        switchCompanies: (value: string) => {
          if ((window as any).track) {
            (window as any).track('MG marketplace - choose company filter', {source: 'V2', value: value});
          }
          switchFilter<string>(value,'companies')
        },
        switchBenefit: (value: MedigapPlanBenefit) => {
          switchFilter<string>(value,'benefits')
        },
        switchMonthlyPremium: (value: string) => {
          switchFilter<string>(value,'monthlyPlanPremiumRanges')
        },
        switchCategory: (value: PlanCategory) => {
          switchFilter<string>(value,'selectedPlanCategory')
        },
        refresh
      }
    )
  , [filters]);

  return <PlanFilterContext.Provider value={contextValue}>
    {props.children}
  </PlanFilterContext.Provider>

}

export const withPlanFilterProvider = (WrappedComponent: any) => (props: any) => {
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  return (
    <PlanFilterDataProvider>
      <PlanFilterProvider initFilter={{zip, county: countyName, age: 65, gender: Gender.F, tobacco: false, companies: [],
        monthlyPlanPremiumRanges: [], benefits: [], planNames: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
        selectedPlanCategory: PlanCategory.MostPopular, effectiveDate: '2021-07-12'}}>
        <WrappedComponent {...{...props}} />
      </PlanFilterProvider>
    </PlanFilterDataProvider>
  )
}

interface PlanFilterProviderProps {
  initFilter: IPlansFilter
}
/***
 switchMaxOutOfPocketRanges: (name: string) => {},
 switchMaxCostRanges: (name: string) => {},


 maxCostRangeNames: [],
 maxOutOfPocketRangeNames: []
 */
