import React from "react";
import PlanFilter from "./PlanFilter";
import Terms from "../../../shared/SidePanel/Terms";

const PlanListSideContent = () => {
  return <>
    <PlanFilter />
    <Terms />
    {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
  </>
}

export default PlanListSideContent;
