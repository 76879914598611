import React from "react";
import {Box, createStyles, RadioGroup, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps} from "react-router";
import useCommonStyles from "../shared/useCommonStyles";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {useHistory} from "react-router-dom";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import paths from "./config/router-paths";
import {KeycloakContext} from "../shared/KeycloakContext";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import HeaderCard from "../Maketplace/MedicareAdvantage/DetailedPlan/components/HeaderCard";
import MedigapPlanCard from "../Maketplace/Medigap/PlanList/PlanCardOld";
import {useMedigapPlanLazy} from "../Maketplace/Medigap/DetailedPlan/hooks/usePlan";
import {addDetailsToPlan} from "../Maketplace/Medigap/DetailedPlan/hooks/usePlansByBid";
import {client as client2022} from "../shared/ApolloClient2022";
import {client} from "../shared/ApolloClient";
import {GetPlansYear} from "../Maketplace/shared/hooks/GetPlansYear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    }
  })
);

function Enroll({isMedigap, year, ...props}: RouteComponentProps<{planId: string, zip: string, countyName: string, quoteId?: string}> & {isMedigap: boolean, year: string}) {
  const {planId, zip, countyName, quoteId} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const {authenticated} = React.useContext(KeycloakContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const [getPlan, {data}] = usePlanLazy({client: year === '2021' ? client : client2022});
  const [getMedigapPlan, {data: medigapData}] = useMedigapPlanLazy();

  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('Enrollment - start booking', {source: 'V1', planId});
    }
  }, []);

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])


  const medigapPlan = React.useMemo(() => {
    return addDetailsToPlan(medigapData?.medigapPlan);
  }, [medigapData])

  React.useEffect(() => {
    if (planId && zip && countyName) {
      if (isMedigap) {
        getMedigapPlan({
          variables: {
            key: planId
          }
        })
      } else {
        getPlan({
          variables: {
            bidId: planId,
            countyName,
            zip,
            quoteId
          }
        })
      }
    }
  }, [planId, zip, getPlan, quoteId, isMedigap])

  const startApplication = () => {
    const url = '/' + planId + '/' + zip + '/' + countyName + (quoteId ? '/' + quoteId : '') + document.location.search;
    history.push(paths.personalDetails + url);
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'}>

      {!phone && <>
        <Typography className={'fs-18 bold'} color={'textSecondary'}>Here’s the plan you selected:</Typography>
        <div className={'h-16'} />
        {isMedigap ? <>{medigapPlan && <MedigapPlanCard countyName={countyName}
                                                        zip={zip}
                                                        hideButtons
                                                        plan={medigapPlan}/>}</> : <>{plan && <ShortPlanCard year={year} plan={plan}/>}</> }
      </>}
      {(phone && plan) && <HeaderCard hideFixed hideSelectButton plan={plan} zip={zip} countyName={countyName} setEmailOpen={() => {}} setModalOpen={() => {}} />}
      {(phone && isMedigap && medigapPlan) && <MedigapPlanCard countyName={countyName}
                                                               zip={zip}
                                                               hideButtons
                                                               plan={medigapPlan}/>}
      <div className={'h-20'} />

      <div className={'text-align-centered'}>
        {isMedigap ? <>
          <Typography className={'fs-18 text-align-centered bold mv-20'} color={'textSecondary'}>We need to collect some more information to complete your enrollment. You’ll also need some details from your red, white and blue Medicare card.</Typography>
        </> : <>
          <img className={'w-70 h-70'} src={'/img/support-green.svg'} />
          <Typography className={'fs-26 text-align-centered bold mv-20'} color={'textPrimary'}>A licensed CoverRight sales agent will need to contact you to help complete your application.</Typography>
          <Typography className={'fs-16 text-align-centered'} color={'textPrimary'}>You’ll need to have your red, white and blue Medicare card handy.</Typography>
        </>}
      </div>
    </Box>
    <Box display={'flex'} flexDirection={phone ? 'column-reverse' : 'row'} justifyContent={'space-between'} mt={'40px'} mb={'100px'}>
      <AscendButton variant={'contained'} onClick={() => {
        history.goBack()
      }} color={'secondary'}>Back to plans</AscendButton>
      <AscendButton className={`gtm-step-event ${phone ? 'mb-15' : ''}`} variant={'contained'} onClick={startApplication}>Continue</AscendButton>
    </Box>
    <Typography variant={'h5'} color={'textPrimary'}>
      Last Updated Date: 10/15/22<br/>MULTIPLAN_COVERRIGHT_2023_M<br />MULTIPLAN_COVERRIGHT_2023U2_M
    </Typography>
  </>
}

export default withTitle(
  Enroll,
  (q, m, props) => `Congratulations on selecting a new ${props?.isMedigap ? 'Medigap' : 'Medicare Advantage'} plan!`,
  ``,
  undefined,
  undefined,
  true,
  800
)
