import React from 'react';
import {LazyQueryHookOptions, useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../../../shared/ApolloClient";
import {planFields} from "../../PlanList/hooks/usePlans";
import {MedigapPlanOutput, QueryMedigapPlanArgs} from "../../../../medigap-types";

export default function useMedigapPlan(input:  QueryMedigapPlanArgs) {
  return useQuery<IPlanOutput, QueryMedigapPlanArgs>(gql(getPlan), {
    variables: input,
    client,
    errorPolicy: 'all',
    fetchPolicy: "no-cache"
  });
}

export function useMedigapPlanLazy(options?: LazyQueryHookOptions<IPlanOutput, QueryMedigapPlanArgs>) {
  return useLazyQuery<IPlanOutput, QueryMedigapPlanArgs>(gql(getPlan), {
    ...options,
    client,
  });
}

interface IPlanOutput{
  medigapPlan: MedigapPlanOutput
}



const getPlan = `
query($key: String!) {
  medigapPlan(key: $key) {
    ${planFields}
  }
}
`;

