import React from "react";
import {
  Box,
  createStyles,
  IconButton,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import FormTextInput from "../../../shared/components/FormTextInput";
import useCommonStyles from "../../../shared/useCommonStyles";
import FormSelectInput from "../../../shared/components/FormSelectInput";
import clsx from "clsx";
import {colors} from "../../../shared/AppTheme";
import {ScrollbarList} from "../../../shared/components/AscendAutocomplete";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import Preloader from "../../../shared/Preloader";
import Marker from "../Marker";
import GoogleMap from "../GoogleMap";
import {
  LocationOutput,
  PageablePharmacyLocationOutput,
  PharmacyLocationDistanceOutput,
  PharmacyWithinRectangleFilterInput
} from "../../../types";
import {Bounds} from "google-map-react";
import {formatPharmacyName} from "../../../shared/utils";
import AscendPreloader from "../../../shared/components/AscendPreloader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      marginBottom: 16,
      fontWeight: 500,
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 8,
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      '& > div:first-child > div > div': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
      },
      height: 'inherit'
    },
    contentWrapper: {
      display: 'flex',
      flex: 2,
      flexDirection: 'column',
      padding: '25px 10px 17px 56px',
      [theme.breakpoints.down('sm')]: {
        padding: '25px 10px 17px 10px',
        minWidth: 'auto',
      },
      minWidth: 486,
      height: '100%',
    },
    centered: {
      textAlign: 'center',
    },
    sort: {
      padding: '4px 14px',
      fontSize: 14,
      color: colors.text.primary,
      cursor: 'pointer',
      borderRadius: 4,
      background: '#F5F7F6',
    },
    sortActive: {
      color: 'white',
      background: colors.custom.green.variant1,
    },
    link: {
      color: colors.text.secondary,
      cursor: 'pointer'
    },
    linkDisabled: {
      cursor: 'default',
      opacity: .5,
    },
    searchAreaButton: {
      position: 'absolute',
      left: 33,
      top: 26,
      zIndex: 1,
    }
  })
);

type PharmaciesModalProps = {
  onClose: () => void,
  onSubmit: (values: PharmacyLocationDistanceOutput[]) => void,
  open: boolean,
  values?: PharmacyLocationDistanceOutput[],
  zip: string
}

const LINE_HEIGHT = 81;

export default function PharmaciesModal(props: PharmaciesModalProps) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [sort, setSort] = React.useState<'DISTANCE' | 'NAME'>('DISTANCE');
  const [address, setAddress] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [distance, setDistance] = React.useState<number>(1);
  const [scrollTo, setScrollTo] = React.useState<number>();
  const [page, setPage] = React.useState<number>(0);
  const [bounds, setBounds] = React.useState<Bounds>();
  const [selectedPharmacies, setSelectedPharmacies] = React.useState<string[]>([]);
  const distances = [1, 5, 10, 15].map(value => ({value, label: `Within ${value} miles`}))
  const theme = useTheme();
  const hideMap = useMediaQuery(theme.breakpoints.down('sm'));
  const [pharmacies, setPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>([]);
  const [showPaginator, setShowPaginator] = React.useState(true);

  const [getPharmacies, pharmaciesData] = useLazyQuery<IGetPharmaciesResponse>(gql(getPharmaciesQuery), {
    onCompleted: () => setShowPaginator(true)
  });
  const [getPharmaciesByRect, pharmaciesByRectData] =
    useLazyQuery<{findPharmaciesWithinRectangle: PageablePharmacyLocationOutput}, PharmacyWithinRectangleFilterInput>(gql(getPharmaciesByRectQuery), {
    onCompleted: () => setShowPaginator(false)
  });
  const {data: zipData} = useQuery<{findZipLocation: LocationOutput}>(gql(zipQuery), {
    variables: {
      zip: props.zip
    }
  });

  const fallbackLocation = React.useMemo(() => {
    return zipData?.findZipLocation;
  }, [zipData])

  React.useEffect(() => {
    setSelectedPharmacies(props.values?.map(p => p.npi) || []);
  }, [props.values])

  React.useEffect(() => {
    setAddress(props.zip)
  }, [props.zip])

  React.useEffect(() => {
    if (props.open) {
      getPharmacies({
        variables: {
          address,
          distance,
          page,
          sort,
          name: filter || null
        }
      })
    }
  }, [page, sort, distance, props.open])

  useDebouncedEffect(() => {
    if (props.open) {
      if (page > 0) {
        setPage(0)
      } else if (address.length >= 5) {
        getPharmacies({
          variables: {
            address,
            distance,
            page,
            sort,
            name: filter
          }
        })
      }
    }
  }, 1000, [address, filter], 1)

  const toggleValue = (val: string) => {
    setSelectedPharmacies([val])
  }

  React.useEffect(() => {
    if (pharmaciesData.data?.findPharmaciesByAddress?.data) {
      return setPharmacies(pharmaciesData.data?.findPharmaciesByAddress?.data);
    }
  }, [pharmaciesData.data?.findPharmaciesByAddress?.data]);

  React.useEffect(() => {
    if (pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data) {
      return setPharmacies(pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data);
    }
  }, [pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data]);

  const findText = React.useMemo(() => {
    if (pharmaciesData.data?.findPharmaciesByAddress?.totalElements) {
      return `There are ${pharmaciesData.data?.findPharmaciesByAddress?.totalElements} pharmacies within ${distance} miles of ${address || props.zip}.`
    } else {
      return '';
    }
  }, [pharmaciesData.data?.findPharmaciesByAddress?.totalElements])

  const onMarkerClick = (key: string) => {
    const index = pharmacies.findIndex((p: any) => p.npi === key);
    toggleValue(key);
    setScrollTo(LINE_HEIGHT * index);
  }

  const margins = React.useMemo(() => {
    return hideMap ? '100%' : 'calc(100% - 160px)'
  }, [hideMap])

  const onAreaSearchClick = () => {
    if (bounds) {
      getPharmaciesByRect({
        variables: {
          rectangle: {
            firstPoint: {
              latitude: bounds.nw.lat,
              longitude: bounds.nw.lng
            },
            diagonalPoint: {
              latitude: bounds.se.lat,
              longitude: bounds.se.lng
            }
          },
          name: filter
        }
      })
      setBounds(undefined);
    }
  }

  return <AscendModal
    width={margins}
    height={'100%'}
    disableSidePadding
    paperPadding={0}
    open={props.open}
    onClose={props.onClose}
  >
    <Box className={classes.wrapper}>
      {!hideMap && <Box display={'flex'} flex={3} position={'relative'}>
        {bounds && <RoundedButton variant={'contained'} className={classes.searchAreaButton} onClick={onAreaSearchClick}>Search this area</RoundedButton>}
        <Preloader in={pharmaciesData.loading} />
        <GoogleMap selected={selectedPharmacies[0]}
                   page={page}
                   pharmacies={pharmacies}
                   onChildClick={onMarkerClick}
                   onChange={setBounds}
                   fallbackLocation={fallbackLocation} />
      </Box>}
      <Box className={classes.contentWrapper}>
        <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <ScrollbarList height={'100%'} scrollTo={scrollTo}>
          <Box mr={{'xs': '3px', 'sm': '46px'}}>
            <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Select pharmacies near you</Typography>
            <Typography variant={'h3'} color={'textSecondary'} className={classes.centered}>Find your specific pharmacy or choose one near you. It doesn’t need to be your current pharmacy.</Typography>
            <Box mt={'30px'} className={commonClasses.inputContainer}>
              <Box width={'100%'}>
                <FormTextInput fullWidth
                               value={address}
                               onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                 setAddress(event.target.value as string);
                               }}
                               placeholder={'Zip Code'} />
              </Box>
              <Box className={commonClasses.input}>
                <FormSelectInput placeholder={'Distance'}
                                 defaultValue={1}
                                 onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                   setDistance(event.target.value as number);
                                 }}
                >
                  {distances.map(d => <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>)}
                </FormSelectInput>
              </Box>
            </Box>
            <Box width={'100%'} mt={'-10px'}>
              <Typography variant={'body1'} color={'textSecondary'}>{findText}</Typography>
            </Box>
            <Box width={'100%'} mt={'10px'}>
              <FormTextInput fullWidth
                             onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                               setFilter(event.target.value as string);
                             }}
                             value={filter}
                             placeholder={'Find your local pharmacy'} />
            </Box>
          </Box>
          <Box display={'flex'} flex={1} mt={0} flexDirection={'column'} pb={'14px'}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'body1'} color={'textSecondary'}>Sort by:</Typography>
              <Box ml={'13px'}>
                <div className={clsx({[classes.sort]: true, [classes.sortActive]: sort === 'DISTANCE'})} onClick={() => setSort('DISTANCE')}>Distance</div>
              </Box>
              <Box ml={'10px'}>
                <div className={clsx({[classes.sort]: true, [classes.sortActive]: sort === 'NAME'})} onClick={() => setSort('NAME')}>Name</div>
              </Box>
            </Box>
          </Box>
          {!!pharmacies.length && <>
            {pharmacies.map((pharmacy: PharmacyLocationDistanceOutput, i: number) => <PharmacyLine index={page * 10 + i + 1}
                                                                              key={pharmacy.npi}
                                                                              onClick={toggleValue}
                                                                              pharmacy={pharmacy}
                                                                              checked={selectedPharmacies.includes(pharmacy.npi)} />)}
          </>}
          {pharmaciesData.called && !pharmaciesData.loading && !pharmacies.length && <Typography color={'textPrimary'} className={'fs-14 medium'} align={'center'}>There are no pharmacies available with your selected criteria. Please update and try again</Typography>}
          {pharmaciesData.loading && <AscendPreloader />}
          {(!pharmaciesData.loading && !!pharmacies.length && !!pharmaciesData?.data?.findPharmaciesByAddress && showPaginator) && <Box mt={1} display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'body2'} color={'textSecondary'}>
              Page {pharmaciesData?.data?.findPharmaciesByAddress?.number + 1} of {pharmaciesData?.data?.findPharmaciesByAddress?.totalPages}
            </Typography>
            <Box display={'flex'}>
              <Typography variant={'body2'}
                          onClick={() => {
                            if (pharmaciesData?.data?.findPharmaciesByAddress?.hasPrevious) {
                              setPage(prev => prev - 1);
                              setScrollTo(260 + Math.random());
                            }
                          }}
                          className={clsx({[classes.link]: true, [classes.linkDisabled]: !pharmaciesData?.data?.findPharmaciesByAddress?.hasPrevious})} >
                {'< Previous'}
              </Typography>
              <Box ml={'29px'} mr={'54px'}>
                <Typography variant={'body2'}
                            onClick={() => {
                              if (pharmaciesData?.data?.findPharmaciesByAddress?.hasNext) {
                                setPage(prev => prev + 1);
                                setScrollTo(260 + Math.random());
                              }
                            }}
                            className={clsx({[classes.link]: true, [classes.linkDisabled]: !pharmaciesData?.data?.findPharmaciesByAddress?.hasNext})} >
                  {'Next >'}
                </Typography>
              </Box>
            </Box>
          </Box>}
        </ScrollbarList>
        <Box display={'flex'} mt={'17px'} justifyContent={'center'} pr={{'xs': '18px', 'lg': '0'}}>
          <AscendButton disabled={!selectedPharmacies?.length} variant={'contained'}
                        onClick={() => props.onSubmit(pharmacies.filter(p => selectedPharmacies.includes(p.npi)))}
          >Confirm Selection</AscendButton>
        </Box>
      </Box>
    </Box>
  </AscendModal>
}

interface IGetPharmaciesResponse {
  findPharmaciesByAddress: PageablePharmacyLocationOutput
}

const getPharmaciesQuery = `
query ($address: String!, $sort: SortType!, $name: String, $distance: Float!, $page: Int!) {
  findPharmaciesByAddress(filterInput: {address: $address, sort: $sort, name: $name, radius: $distance, lengthUnit: MILE}, page: {page: $page, size: 10}) {
     data {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    totalPages
    number
    totalElements
    hasNext
    hasPrevious
  }
}
`

const getPharmaciesByRectQuery = `
query ($name: String, $rectangle: RectangleInput!) {
  findPharmaciesWithinRectangle(filterInput: {name: $name, rectangle: $rectangle}, page: {page: 0, size: 1000}) {
     data {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    totalPages
    number
    totalElements
    hasNext
    hasPrevious
  }
}
`

const zipQuery = `
query ($zip: String!) {
  findZipLocation(zip: $zip) {
    latitude
    longitude
  }
}
`
const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '99%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 0',
      borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      }
    },
    title: {
      fontWeight: 500,
    },
    checkbox: {
      padding: 0,
      marginLeft: -6,
    }
  })
);

type PharmacyLineProps = {
  pharmacy: PharmacyLocationDistanceOutput,
  index: number,
  checked: boolean,
  onClick: (id: string) => void
}

const PharmacyLine = (props: PharmacyLineProps) => {
  const classes = useLineStyles(props);

  return <Box className={classes.root} onClick={() => props.onClick(props.pharmacy.npi)}>
    <Box>
      <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>{formatPharmacyName(props.pharmacy.name || '')}</Typography>
      <Typography variant={'h4'} color={'textSecondary'}>{props.pharmacy.address}</Typography>
    </Box>
    <Box display={'flex'} alignItems={'center'}>
      <Typography variant={'body2'} color={'textSecondary'}>{props.pharmacy.distance ? (props.pharmacy.distance || 0).toFixed(1) + ' mi' : ''}</Typography>
      <Box ml={'12px'}>
        <Marker active={props.checked} index={props.index}/>
      </Box>
    </Box>
  </Box>
}

const RoundedButton = withStyles({
  contained: {
    backgroundColor: colors.custom.green.variant2,
    padding: '5px 20px',
    boxShadow: 'none',
    borderRadius: 4,
  },
  label: {
    color: 'white',
    fontSize: 14,
    lineHeight: '15px',
    textTransform: 'none',
  },
})(AscendButton)
