import {ListItem} from "../PlanList/PlanFilterDataContext";
import React from "react";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import * as _ from "lodash";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    }
  }),
);

interface FilterItemsProps {
  items?: ListItem[],
  selectedKeys: string[],
  onChange: (key: any) => void,
}

const FilterItems = React.memo(({items, selectedKeys, onChange}: FilterItemsProps) => {
  const classes = useStyles();

  if (!items) {
    return null;
  }

  return <>
    {items.map(item => <AscendCheckbox variant={'standart'}
                                       key={item.value}
                                       name={item.value}
                                       labelClassName={classes.checkboxLabel}
                                       controlClassName={classes.checkbox}
                                       label={item.label}
                                       checked={!!selectedKeys && selectedKeys.includes(item.value)}
                                       disabled={item.disabled && !selectedKeys.includes(item.value)}
                                       onChange={() => {
                                         onChange(item.value)
                                       }}

    />)}
  </>
}, areEqual)

function areEqual(prevProps: FilterItemsProps, nextProps: FilterItemsProps): boolean {
  if (_.isEqual(prevProps.items, nextProps.items) && _.isEqual(prevProps.selectedKeys, nextProps.selectedKeys)) {
    return true;
  }
  return false;
}

export default FilterItems;
