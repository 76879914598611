import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, Grid, RadioGroup, withStyles} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from "clsx";
import CollapsableCard from "../../shared/components/CollapsableCard";

const formName = 'STEP_5a3';

function Step5a3(props: RouteComponentProps<any>) {
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step6a)
  };

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-32'} style={{maxWidth: 600}}>
      <CollapsableCard
        title={'I’m already enrolled in Original Medicare or Medicare Advantage'}
        description={<ul>
          <li>You'll need to wait until the <strong>Annual Enrollment Period (AEP)</strong> (October 15 - December 7) to switch plans, unless you have experienced a special circumstance (such as a change in where you live, if you gain or lose Medicaid, or if you become disabled or have another serious condition)</li>
          <li>
            If you choose to stay in Original Medicare:
            <ul>
              <li>You can buy a Medicare Supplement plan up to 6 months after you first enroll in Part B without a medical history check (after this you may be subject to a medical history check)</li>
              <li>You can only enroll in a standalone Prescription Drug Plan during the AEP</li>
            </ul>
          </li>
        </ul>}
      />
      <CollapsableCard
        title={<>I’m <span style={{textDecoration: 'underline'}}>not</span> already enrolled in Original Medicare</>}
        description={<ul>
          <li>If you didn’t enroll in Original Medicare when you were first eligible, you can enroll during the Medicare <strong>General Enrollment Period (GEP)</strong> between January 1 and March 31 each year, for an effective start date of July 1</li>
          <ul>
            <li>You can apply for a Medicare Advantage or Prescription Drug Plan between April 1 - June 30 of the same year (for a July 1 start date); or</li>
            <li>You can buy a Medicare Supplement plan up to 6 months after you first enroll in Part B without a medical history check (after this you may be subject to a medical history check)</li>
          </ul>
        </ul>}
      />
    </div>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </Grid>;
}

export default withTitle(
  Step5a3,
  () => 'It looks like you’re currently outside an eligible\nMedicare enrollment period.',
  'We can still help you with your Medicare options, but you’ll have to\nwait until an eligible enrollment period to enroll.'
);

