import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {DrugInfoWithPackagesFlags} from "../../types";

export default function useDrugPackagesInfoRequest(onCompleted?: (data: {drugPackagesInfo: DrugInfoWithPackagesFlags}) => void) {
  return useLazyQuery<{drugPackagesInfo: DrugInfoWithPackagesFlags}, {rxcui: string}>(
    gql(getDrugRequest),
    {
      onCompleted,
      fetchPolicy: 'no-cache'
    }
  );
}

const getDrugRequest = `
query ($rxcui: String!) {
  drugPackagesInfo(rxcui: $rxcui) {
    genericName
    genericRxcui
    brandedDetails {
      name
      rxcui
    }
    hasBrandedPackages
    hasGenericPackages
    isGeneric
    name
    rxcui
  }
}
`;
