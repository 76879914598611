import React from "react";
import {withStyles} from "@material-ui/core";
import AscendButton from "./AscendButton";
import {keycloak} from "../keycloak";

const LoginButton = () => {

  return <StyledLoginButton variant={'contained'}
                            onClick={() => {
                              if ((window as any).track) {
                                (window as any).track('Login', {source: 'V1', page: document.location.href});
                              }
                              document.location.href = keycloak.createLoginUrl()
                            }}>
    Sign in
  </StyledLoginButton>
}

export default LoginButton;

const StyledLoginButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 16px 6px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
