import React, {useState} from "react";
import {Box, Button, createStyles, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {colors} from "../../../shared/AppTheme";
import PlanCard from "./PlanCard";
import usePlans from "./hooks/usePlans";
import {Field, Plan, PlanYear, Sort} from "../../../types";
import {PlanFilterContext} from "./PlanFilterContext";
import {QuoteContext} from "./QuoteContext";
import UpdateZipModal from "./components/UpdateZipModal";
import {getQuoteId, setQuoteId} from "../../../shared/QuoteId";
import paths from "../config/router-paths";
import PlanTopFilter from "./PlanTopFilter";
import Footer from "../shared/Footer";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import CreateAccountModal from "./components/CreateAccountModal";
import withErrorBoundary from "../../../shared/withErrorBoundary";
import PlanTour from "./components/PlanTour";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {useSnackbar} from "notistack";
import PlanListSort from "./components/PlanListSort";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import {useParams} from "react-router";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import * as QueryString from "query-string";
import PlanListEmailModal from "../../shared/components/PlanListEmailModal";
import {config} from "../config/config";
import useCreateMedigapQuoteFromMedicareQuote from "./hooks/useCreateMedigapQuoteFromMedicareQuote";
import BackButton from "../shared/BackButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    card: {
      padding: '24px 30px',
      maxWidth: 1000
    },
    disclosure: {
      fontSize: 20,
      fontWeight: 500,
      marginRight: 8,
      marginTop: 1,
    },
    underline: {
      borderBottom: '1px dashed #1C434F',
      cursor: 'pointer'
    },
    county: {
      cursor: 'pointer'
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
    hugeText: {
      fontSize: 32,
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
    },
    thin: {
      fontWeight: 400,
    },
    stickyFilter: {
    }
  }),
);

function PlanList() {
  const pageSize = 20;
  const {zip, countyName: county} = useParams<{zip: string, countyName: string}>();

  const classes = useStyles();
  const [sortBy, setSortBy] = React.useState<Field>(Field.MonthlyCost);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {id, save, showAllPlans} = React.useContext(QuoteContext);
  const {authenticated} = React.useContext(KeycloakContext);
  const {clear: clearComparison} = React.useContext(ComparisonContext);
  const { enqueueSnackbar } = useSnackbar();

  const [total, setTotal] = React.useState<number>();
  const [request, results] = usePlans();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const [updateZipModalOpen, setUpdateZipModalOpen] = React.useState(false);
  const [createAccountModalOpen, setCreateAccountModalOpen] = React.useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);

  const [praiseOpen, setPraiseOpen] = React.useState(false);

  const [createMGQuote] = useCreateMedigapQuoteFromMedicareQuote({
    onCompleted: data => document.location.href = `${config.medigapUrl}?medigapQuote=${data.createMedigapQuoteFromMedicareQuote}`
  });

  React.useEffect(() => {
    window.scrollTo(0, 0)
    const parsedQuery = QueryString.parse(document.location.search);

    if (parsedQuery.skipCreateAccount && parsedQuery.quote && !localStorage.getItem('praiseCardShown')) {
      setPraiseOpen(true);
      localStorage.setItem('praiseCardShown', 'true');
    } else {
      showTour();
    }

    if (parsedQuery.skipCreateAccount) {
      localStorage.setItem('createAccountShown', 'true');
    }

    if (id && !authenticated && !localStorage.getItem('createAccountShown') && localStorage.getItem('showCreateAccountModal')) {
      setTimeout(() => {
        localStorage.setItem('createAccountShown', 'true');
        localStorage.removeItem('showCreateAccountModal');
        // setCreateAccountModalOpen(true)
      }, 7000)
    }
  }, []);

  const showTour = () => {
    if (!localStorage.getItem('tourShown') && !authenticated  && window.innerWidth > 1280) {
      localStorage.setItem('tourShown', 'true');
      // setIsTourOpen(true)
    }
  }

  const {values: filterValues, switchZip} = React.useContext(PlanFilterContext)

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  useDebouncedEffect(() => {
    if (zip) {
      setPlans([]);
      setPage({
        page: 0,
        size: pageSize
      });
      window.scrollTo(0, 0)
    }
  }, 700, [filterValues, sortBy]);

  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if (hasMorePlans && filterValues) {
      request({
        variables: {
          filter: {...filterValues, showAllPlans: !!showAllPlans, planYear: PlanYear.Year2023},
          page,
          quoteId,
          sort: sortBy === Field.Rating ?[
            { "field": Field.Rating,"direction":Sort.Desc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ] : [
            { "field": Field.MonthlyCost,"direction":Sort.Asc },
            { "field": Field.Premium,"direction":Sort.Asc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data.plans.totalElements)
      setPlans([...plans, ...results.data.plans.data])
    }
  }, [results.data]);

  React.useEffect( () => {
    if(results.error) {
      enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'}); // todo add text
    }
  }, [results.error]);

  const onUpdateZipModalClose = React.useCallback((newZip?: string, newCounty?: string) => {
    if (newZip && newCounty && newCounty !== county) {
      save({
        variables: {
          data: {
            county: newCounty,
            zip: newZip,
            form: 'SEARCH_RESULTS',
            id: quoteId,
          }
        },
      }).then((data: any) => {
        switchZip(newZip, newCounty);
        setQuoteId(data.data.saveMedicareQuote.maQuoteID)
      }).finally(() => {
        clearComparison();
        document.location.href = paths.plans + newZip + '/' + newCounty;
      })
    }
    setUpdateZipModalOpen(false);
  }, [zip, county])

  const goToMG = React.useCallback(() => {
    /*if (quoteId) {
      createMGQuote({variables: {medicareQuoteId: quoteId}});
    } else {
      document.location.href = config.medigapUrl + 'plans/' + zip + '/' + county
    }*/
  }, [quoteId, zip, county]);

  const goToDashboard = () => {
    document.location.href = config.userFlowUrl + 'dashboard'
  }

  return <>
    <PlanListEmailModal zip={zip} county={county} open={emailOpen} id={quoteId as string} onClose={() => {
      setEmailOpen(false);
    }} />
    <PlanTour open={isTourOpen} onClose={() => setIsTourOpen(false)} />
    <CreateAccountModal open={createAccountModalOpen} onClose={() => setCreateAccountModalOpen(false)} />
    <UpdateZipModal open={updateZipModalOpen}
                    title={'Update your zip code'}
                    subtitle={'Your plan options will update automatically.'}
                    onClose={onUpdateZipModalClose}
                    zip={zip}
                    county={county} />
    {/*<PraiseCardModal
      onClose={() => {
        setPraiseOpen(false);
        showTour();
      }}
      open={praiseOpen}
    />*/}
    <Box flexDirection={'column'} my={'15px'} maxWidth={'800px'} alignItems={'center'}>
      {localStorage.getItem('flow') === 'v2' && <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={goToDashboard}>
        Back to Dashboard
      </BackButton>}
      {/*<PlansYearDisclamer />*/}
      {!phone && <>
        <Box display={'flex'} alignItems={'center'} mb={4}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>We’ve found {total} Medicare Advantage plans for you.</Typography>
        </Box>
        {/*<Box display={'flex'} alignItems={'center'} mb={4}>
          <Typography className={'fs-18 mt-16'} color={'textPrimary'}>
            Interested in <span style={{borderBottom: '1px dotted ' + colors.text.primary}}>Medicare Supplement (Medigap) Insurance Plans</span> or <span style={{borderBottom: '1px dotted ' + colors.text.primary}}>Prescription Drug (PDP)</span> plans instead?
          </Typography>
        </Box>*/}
        <Box position={'sticky'} top={0} zIndex={2} bgcolor={'#f5f7f6'}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} className={'mb-10'}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Showing plans for:</Typography>
              <Box ml={1} className={classes.county} display={'flex'} alignItems={'center'} onClick={() => setUpdateZipModalOpen(true)}>
                <Typography variant={'h4'} color={'textPrimary'} className={classes.bold}>{zip} {filterValues?.countyName}</Typography>
                <Box ml={1} pt={'5px'}>
                  <img src={'/img/edit.svg'}/>
                </Box>
              </Box>
            </Box>
            {/*<RoundedButton variant={"contained"}
                           classes={{
                             contained: 'ph-16 pv-5'
                           }}
                           onClick={() => setEmailOpen(true)}>
              <img src={'/img/envelope.svg'} className={'mr-10'} />
              Email me this page</RoundedButton>*/}
          </Box>
          <PlanTopFilter />
          <div className={'h-8'} />
          <PlanListSort onChange={setSortBy} value={sortBy} zip={zip} county={filterValues?.countyName} />
        </Box>
      </>}
      <Box>
        {phone && <>
          <PlanTopFilter />
          <PlanListSort onChange={setSortBy} value={sortBy} zip={zip} county={filterValues?.countyName} />
        </>}

        {!!filterValues && <>
          {!!plans.length && plans.map(plan => <PlanCard plan={plan}
                                                         zip={filterValues.zip}
                                                         countyName={filterValues.countyName}
                                                         key={plan.bidId} />)}
          {(!plans.length && !results.loading && results.called) && <Typography color={'textPrimary'}
                                                                                align={'center'}
                                                                                className={'fs-18 bold mt-50 mb-110'}>
            There are no plans available based on your search criteria. Try adjusting your filter settings to see more plans.
          </Typography>}
        </>}
        {results.loading &&
          <Box textAlign={"center"} my={2}>
            <AscendPreloader />
          </Box>
        }
        {(hasMorePlans && !results.loading && plans.length > 0) && <Box textAlign={"center"} mt={'32px'}>
          <Button disabled={results.loading} className={classes.seeMoreButton} onClick={loadMore}
                  endIcon={<ExpandMoreIcon />}><div style={{textDecoration: 'underline'}}>See more plans</div></Button>
        </Box>}
      </Box>
      <Footer />
    </Box>
  </>
}

export default withErrorBoundary(PlanList);


