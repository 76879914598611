import React from "react";
import PhoneInput from "react-phone-input-2";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {Box, FormControl, FormHelperText, Select} from "@material-ui/core";
import {gql} from "apollo-boost";
import {client} from "../EnrollmentApolloClient";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .special-label': {
        fontFamily: 'Sailec',
        fontSize: '16px',
        lineHeight: '20px',
        marginBottom: '10px',
        color: colors.text.primary,
        fontWeight: 500,
      },
      '& .invalid-number-message': {
        display: 'none'
      }
    },
    input: {
      padding: '15px 20px 12px!important',
      fontSize: '15px',
      lineHeight: '21px',
      fontFamily: 'Sailec',
      fontWeight: 500,
      backgroundColor: 'white',
      border: '1px solid #CCD8DC!important',
      borderRadius: '5px',
      width: '100%',
      '&.invalid-number': {
        border: '1px solid rgb(244, 67, 54)!important',
        outline: 'none'
      },
      '&::placeholder': {
        color: '#9fafb4',
        opacity: 1,
      },
      '&:not(.invalid-number):focus': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
        backgroundColor: 'rgba(2, 144, 148, 0.05)',
        outline: 'none'
      }
    },
    tick: {
      position: 'absolute',
      bottom: 49,
      right: 12,
    }
  }),
);

interface PhoneNumberInputProps {
  name: string;
  onChange: (value: string) => void;
  isValid?: () => boolean;
  label: React.ReactNode,
  placeholder?: string,
  defaultValue?: string | null,
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const classes = useStyles(props);
  const [value, setValue] = React.useState<string>(props.defaultValue || '');
  const [showTick, setShowTick] = React.useState(false);

  React.useEffect(() => {
    setShowTick(/\d{11}/.test(value.toString()))
    props.onChange(value);
  }, [value]);

  return (
    <Box position={'relative'} width={'100%'}>
      {showTick && <img className={classes.tick} src={'/img/tick.svg'} />}
      <PhoneInput
        specialLabel={props.label as string}
        country={'us'}
        isValid={props.isValid}
        defaultMask={'(...) ...-....'}
        alwaysDefaultMask
        disableCountryCode={false}
        countryCodeEditable={false}
        placeholder={props.placeholder || 'Enter your phone number'}
        defaultErrorMessage={'Please enter a valid phone'}
        containerClass={classes.root}
        inputClass={classes.input}
        value={value}
        onChange={setValue}
        inputProps={{ name: props.name }}
      />
      {(props.isValid && !props.isValid()) && <FormHelperText className={'red'}>Please enter a valid phone</FormHelperText>}
      {(!props.isValid || !!props.isValid()) && <div className={'h-30'}/>}
    </Box>
  );
}

function validatePhone(value?: string) {
  if (!!value && /\d{11}/.test(value.toString())) {
    return new Promise((resolve, reject) => {
      client.query<{ isPhoneValid: boolean }>({
        query: gql(request),
        variables: { phone: value || '' }
      }).then(res => {
        resolve(res.data.isPhoneValid)
      })
    }) as any
  } else {
    return false
  }
}

const request = `
query ($phone: String!) {
  isPhoneValid(phone: $phone)
}
`;
