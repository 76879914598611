import React from "react";
import {Box, createStyles, IconButton, RadioGroup, Theme, Typography, useMediaQuery} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import {ProviderAddressOutput, ProviderOutput} from "../../../types";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {ScrollbarList} from "../../../shared/components/AscendAutocomplete";
import {handleCollectionChange} from "../../../Maketplace/shared/filterUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: 40,
      paddingBottom: 26,
    },
    title: {
      textAlign: 'center',
      lineHeight: '26px',
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 300,
    },
    radio: {
      backgroundColor: '#F5F6F7!important',
    },
    info: {
      background: '#F5F6F7',
      paddingLeft: 30,
      marginTop: 20,
      paddingTop: 16,
      paddingBottom: 16,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    scrollbar: {
      maxHeight: '400px'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

type AddressSelectionModalProps = {
  onClose: (addresses?: ProviderAddressOutput[]) => void,
  open: boolean,
  doctor?: ProviderOutput
}

export default function AddressSelectionModal(props: AddressSelectionModalProps) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [addresses, setAddresses] = React.useState<ProviderAddressOutput[]>([]);
  const matches = useMediaQuery('(min-height:600px)');

  const [getAddresses] = useLazyQuery<{findProviderAddressesByNpi: ProviderAddressOutput[]}, {npi: string}>(gql(getAddressesQuery), {
    onCompleted: data => setAddresses(data.findProviderAddressesByNpi),
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    if (props.open && props.doctor) {
      getAddresses({variables: {npi: props.doctor.npi}})
      setSelected(props.doctor.addresses.map(a => a.id));
    } else {
      setSelected([]);
      setAddresses([]);
    }
  }, [props.open, props.doctor])

  const height = React.useMemo(() => {
    if (!matches || !addresses.length) {
      return undefined
    } else {
      return addresses.length * 58 + 20;
    }
  }, [matches, addresses])

  return <AscendModal
    width={584}
    paperPadding={0}
    open={props.open}
    onClose={() => props.onClose()}
  >
    <>
      {props.doctor && <div className={classes.wrapper}>
        <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Typography variant={'h1'} color={'textPrimary'} className={`${classes.title} ph-20`}>Where do you normally see this doctor?</Typography>
        <Box mt={'27px'} mx={'30px'}>
          <Typography variant={'h3'} color={'textPrimary'}>
            <strong>{props.doctor.name}</strong>
          </Typography>
          <Typography variant={'h3'} color={'textPrimary'}>
            {props.doctor.addresses[0].specialty}
          </Typography>
        </Box>

        {!!addresses.length && <ScrollbarList height={height} className={classes.scrollbar}>
          <div className={classes.info}>
            <RadioGroup>
              {addresses.map(address => <AscendCheckbox key={address.id}
                                                        variant={'standart'}
                                                        value={address.id}
                                                        checked={selected.includes(address.id)}
                                                        onChange={() => setSelected(prev => handleCollectionChange(address.id, prev))}
                                                        label={[address.addressLine1, address.addressLine2].join(', ')} />)}
            </RadioGroup>
          </div>
        </ScrollbarList>}

        <Box display={'flex'} mt={'42px'} justifyContent={'center'}>
          <AscendButton variant={'contained'} disabled={!selected} onClick={() => props.onClose(addresses.filter(a => selected.includes(a.id)))}>Select</AscendButton>
        </Box>
      </div>
      }
    </>
  </AscendModal>
}

const getAddressesQuery = `
query ($npi: String!) {
  findProviderAddressesByNpi(npi: $npi) {
    addressLine1
    addressLine2
    id
    specialty
  }
}
`
