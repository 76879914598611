import React from 'react';
import useQuestionnaireRequest from "../../../../shared/hooks/useQuestionnaireRequest";
import useGetDrugsRequest, {DrugInfoWithPack} from "../../../../Questionnaire/hooks/useGetDrugsRequest";
import {DrugOutput, FrequencyPeriod} from "../../../../types";

export default function useDrugsByRequestId(requestId?: string) {
  const [drugs, setDrugs] = React.useState<DrugOutput[]>([]);

  const [getRequest, {loading: quoteLoading, called, refetch}] = useQuestionnaireRequest(data => {
    if (data.preferredDrugs?.preferredDrugs) {
      setDrugs(data.preferredDrugs?.preferredDrugs)
    }
  })

  React.useEffect(() => {
    if (requestId) {
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {drugs, setDrugs, loading: quoteLoading, refetch, called};
}

export interface PreferredDrug {
  ndc?: string;
  productId?: string;
  quantity: number;
  frequency: string;
  dosage: string;
  productRxcui: string;
  dosageRxcui: string;
  purchaseFrequency: FrequencyPeriod,
}
