import {QueryHookOptions, useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {ExtraBenefit, PlansSummaryOutput, QueryPlansSummaryArgs} from "../../../types";

export default function usePlanSummary(options?: QueryHookOptions<{ plansSummary: PlansSummaryOutput }, QueryPlansSummaryArgs>) {
  return useLazyQuery<{ plansSummary: PlansSummaryOutput }, QueryPlansSummaryArgs>(
    gql(summaryQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const summaryQuery = `
query ($zip: String!, $countyName: String!, $quoteId: UUID, $extraBenefits: [ExtraBenefit!]) {
  plansSummary(zip: $zip, countyName: $countyName, quoteId: $quoteId, extraBenefits: $extraBenefits) {
    drugsCoveredPercent
    hmoCount
    totalCount
    zeroPremiumPercent
    ppoCount
  }
}
`;
