import React from "react";
import {AdminContext} from "./AdminContext";
import {Box, CircularProgress, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {keycloak, KeycloakRoles} from "../../../shared/keycloak";
import useCreateMedicareQuoteForClient from "./hooks/useCreateMedicareQuoteForClient";
import {DrugDeliveryTypeDto, FormName} from "../../../types";
import {useSnackbar} from "notistack";
import {setQuoteId} from "../../../shared/QuoteId";
import {QuoteContext} from "./QuoteContext";
import useFiltersUpdating from "./hooks/useFiltersUpdating";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";
import {convertPreferredDrugInput} from "./EditPreferences";
import {config} from "../config/config";

export const AdminNotificationHeight = '50px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: AdminNotificationHeight,
      padding: '0 18px',
      color: 'white',
    },
    title: {
      fontSize: '18px',
      fontWeight: 700
    }
  }),
);

const AdminNotification = () => {
  const classes = useStyles();

  const adminContext = React.useContext(AdminContext);
  const quoteContext = React.useContext(QuoteContext);
  const [create] = useCreateMedicareQuoteForClient();
  const { enqueueSnackbar } = useSnackbar();
  const {filtersUpdating} = useFiltersUpdating();

  const createOffer = React.useCallback(() => {
      if (adminContext) {
        create({variables: {
          clientId: adminContext.clientId,
            agentOffer: true,
            data: {
              preferredDoctors: quoteContext.preferredDoctors?.preferredDoctors.map((d: any) => ({
                npi: d.npi,
                addresses: d.addresses
              })),
              preferredDrugs: quoteContext.preferredDrugs?.preferredDrugs.map(convertPreferredDrugInput),
              preferredPharmacies: quoteContext.preferredPharmacies?.preferredPharmacies?.map((p: any) => p.npi),
              drugDeliveryType: quoteContext.drugDeliveryType,
              preferredPharmaciesFilled: quoteContext.preferredPharmacies?.preferredPharmaciesFilled,
              doctorVisitsNumber: quoteContext.doctorVisitsNumber,
              specialistVisitsNumber: quoteContext.specialistVisitsNumber,
              favorites: quoteContext.favorites,
              filters: quoteContext.filters,
              zip: adminContext.clientView.zip,
              county: adminContext.clientView.countyName,
              form: FormName.Doctors
            }
        }})
          .then(res => {
            if (res.data?.createMedicareQuoteForClient) {
              setQuoteId(res.data.createMedicareQuoteForClient);
              enqueueSnackbar('Offer created', {variant: 'success'});
            }
          })
          .catch(() => {
            enqueueSnackbar('Error creating offer', {variant: 'error'});
          });
      }
  }, [adminContext, quoteContext]);

  const isOffer = React.useMemo(() => {
      return !!quoteContext.id && quoteContext.id === adminContext?.clientView?.agentMedicareQuote?.quoteId;
  }, [adminContext?.clientView?.agentMedicareQuote, quoteContext?.id]);

  const switchToQuote = (quoteId: string) => {
    setQuoteId(quoteId);
  }

  const QuoteSelector = React.useMemo(() => {
      if (!adminContext?.clientView?.agentMedicareQuote) {
        return <Typography className={`${classes.title} underline ml-20 pointer`} onClick={createOffer} variant={'h4'}>Create offer</Typography>
      } else {
        if (quoteContext.id === adminContext?.clientView?.agentMedicareQuote.quoteId) {
          return <div className={'flex flex-align-center'}>
            <LoopRoundedIcon className={`${filtersUpdating ? 'op-7 cursor-default' : 'pointer'} ml-20`}
                        onClick={() => !filtersUpdating && switchToQuote(adminContext?.clientView?.quoteId)}/>
            {filtersUpdating && <CircularProgress size={'small'} className={'white w-20 ml-10'} />}
            <Typography className={`fs-12 underline ml-20 pointer`} onClick={() => {
              navigator.clipboard.writeText(`${document.location.origin}?adminQuote=${adminContext?.clientView?.agentMedicareQuote?.quoteId}`)
              enqueueSnackbar('Copied!', {variant: "info"});
            }}>(copy link)</Typography>
          </div>
        } else {
          return <div className={'flex flex-align-center'}>
            <LoopRoundedIcon className={`${filtersUpdating ? 'op-7 cursor-default' : 'pointer'} ml-20`}
                             onClick={() => !filtersUpdating && switchToQuote(adminContext?.clientView?.agentMedicareQuote?.quoteId)}/>
            {filtersUpdating && <CircularProgress size={'small'} className={'white w-20 ml-10'} />}
            <Typography className={`fs-12 underline ml-20 pointer`} onClick={() => {
              navigator.clipboard.writeText(`${document.location.origin}?quote=${adminContext?.clientView?.quoteId}`)
              enqueueSnackbar('Copied!', {variant: "info"});
            }}>(copy link)</Typography>
          </div>
        }
      }
  }, [adminContext?.clientView?.agentMedicareQuote, quoteContext?.id, filtersUpdating]);

  if (adminContext == null) {
    const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN);
    if (isAdmin) {
      return <Box className={classes.root}></Box>
    }
    return null
  }

  const fullName = `${adminContext.clientView.firstName} ${adminContext.clientView.lastName}`.trim();

  return <Box className={classes.root} bgcolor={isOffer ? '#3f3efb' : '#FB0A0A'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
    <Box display={'flex'} alignItems={'center'}>
      <Typography className={classes.title} variant={'h4'}>{`${fullName}'s ${isOffer ? 'offer' : 'quote'}`}</Typography>
      {QuoteSelector}
    </Box>
    <IconButton onClick={adminContext.clear}><img src={'/img/white-close.svg'} /></IconButton>
  </Box>
};

export default AdminNotification;
