import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {Box, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../shared/AppTheme";
import {AccountType} from "./CreateAccount";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import {client} from "../shared/ApolloClient";
import {getCompanyLogo} from "../shared/utils";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      padding: 0,
      marginTop: 24,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: 4
    },
    header: {
      background: colors.custom.green.variant2,
      color: 'white',
      fontWeight: 700,
      textAlign: 'center',
      padding: '7px 0',
    },
    green: {
      fontWeight: 700,
      color: colors.custom.green.variant2,
      fontSize: 40,
      marginRight: 28,
      lineHeight: 1
    },
    logo: {
      height: 33,
      minHeight: 33,
      minWidth: 119,
      width: 119,
      marginTop: 9,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right'
    },
  })
);

function Receive(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const classes = useStyles();
  const {planId, zip, quoteId, countyName} = props.match.params;
  const [getPlan, {data}] = usePlanLazy({ client });
  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const accountType = sessionStorage.getItem('ACCOUNT_TYPE');

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-8'} style={{width: 600}}>
      <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
        <img src={'/img/congratulations.svg'} />
      </Box>

      {!!plan && <div className={`${classes.card} p-15`}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <div>
            <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
            <Typography color={'textPrimary'} className={'bold fs-20'}>{plan?.planName}</Typography>
          </div>
          <Box textAlign={'right'}>
            <Typography color={'textPrimary'} className={'fs-14'}>Carrier name: <b>{plan?.parentOrgName}</b></Typography>
            <Typography color={'textPrimary'} className={'fs-14'}>Customer service number:<b>+1 (888) 123-4567<br/>( TTY: 711)</b></Typography>
            <Typography color={'textPrimary'} className={'fs-14'}>Confirmation number: <b>123-ABC-456</b></Typography>
          </Box>
        </Box>
      </div>}

      <div className={classes.card}>
        <div className={classes.header}>Here’s what to expect next:</div>
        <Box p={'20px 25px'}>
          <Box display={'flex'}>
            <Typography className={classes.green}>1</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Receive a confirmation email from Ascend</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>We will send you the details of the application you have submitted for your records.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>2</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Approval from your new health insurance company</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your application will typically take up to a week to approve, and then you can start enjoying the benefits of your new plan!</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>3</Typography>
            <div>
              {accountType === AccountType.SKIPPED && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Join the CoverRight mailing list</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Keep up to date on the latest Medicare news and developments, and how these might impact you.</Typography>
              </>}
              {accountType === AccountType.CREATED && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Activate personalized account to check enrollment status</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>We’ve sent you an email with a link to activate your account. Once you log in, you’ll be able to access special offers and keep your details up to date.</Typography>
              </>}
              {(accountType === AccountType.SIGN_IN || !accountType) && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Visit your CoverRight personalized profile</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Be sure to log in to your CoverRight profile to check your enrollment status, see any special offers available to you and to keep your details up to date.</Typography>
              </>}
            </div>
          </Box>
        </Box>
      </div>
      <Typography className={'bold fs-18  mt-32 mb-12 text-align-centered'} color={'textSecondary'}>
        {accountType === AccountType.SKIPPED && <>Go to the CoverRight homepage to sign up to our newsletter!</>}
        {accountType === AccountType.CREATED && <>Check your email to activate your personalized CoverRight account!</>}
        {(accountType === AccountType.SIGN_IN || !accountType) && <>Visit your CoverRight profile now to access your special offers!</>}
      </Typography>
      <Box mt={'30px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <AscendButton variant={'contained'}
                      style={{width: 326}} onClick={() => {
                        switch (accountType) {
                          case AccountType.SKIPPED: document.location.href = 'https://ascendmedicare.com/blog'; break;
                          case AccountType.CREATED: document.location.href = 'https://ascendmedicare.com/'; break;
                          default: document.location.href = process.env.REACT_APP_PRIVATE_URL as string;
                        }
                      }}>
          {accountType === AccountType.SKIPPED && <>Sign up to CoverRight newsletter</>}
          {accountType === AccountType.CREATED && <>Go to CoverRight home page</>}
          {(accountType === AccountType.SIGN_IN || !accountType) && <>Log in to CoverRight profile</>}
        </AscendButton>
      </Box>
    </div>
  </Grid>;
}

export default withTitle(
  Receive,
  () => {
    switch (sessionStorage.getItem('ACCOUNT_TYPE')) {
      case AccountType.SKIPPED: return 'Congratulations!  We’ve received your request!';
      default: return 'Congratulations!  We’ve received your enrollment application request!';
    }
  },
  ''
);
