import React from "react";
import {Box, Collapse, createStyles, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../components/AscendButton";
import AscendModal from "../components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import FormTextInput, {TickAdornment} from "./FormTextInput";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneNumberInput from "./PhoneNumberInput";
import ReceivedRequestModal from "./ReceivedRequestModal";
import useSaveUserProfile from "../../Private/hooks/useSaveUserProfile";
import {ProfileOption, ProfileSource} from "../../enrollment-types";
import { getCId } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      textAlign: 'center',
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer'
    },
    phone: {
      color: colors.custom.green.variant1,
      display: 'flex',
      borderRadius: 4,
      fontSize: 36,
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 10
      }
    },
    checkboxWrapper: {
      alignItems: 'flex-start',
    },
    wrapper: {
      maxWidth: 300,
      margin: 'auto',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    textLink: {
      color: colors.text.secondary,
    }
  })
);

type NotServedModalProps = {
  onSubmit: (zip?: string) => void,
  onClose: () => void,
  open: boolean
}

function NotServedModal(props: NotServedModalProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [requestACall] = useSaveUserProfile();

  const {register, handleSubmit, errors, watch, formState, control, getValues} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()

  const onSubmit = (values: any) => {
    if (formState.isValid) {
      requestACall({variables: {
          data: {
            phoneNumber: values.phoneNumber,
            options: [ProfileOption.UnsupportedZip],
            cId: getCId(),
            source: ProfileSource.OutOfService,
            insuranceType: 'MA',
          }
        }}).then((res) => {
        //enqueueSnackbar('Request submitted', {variant: 'success'});
        if ((window as any).track) {
          (window as any).track('profile-saved', {'profile_id': res.data?.saveUserProfile.profileId, source: ProfileSource.OutOfService});
        }
        props.onSubmit(values?.zip);
      }).catch(() => {
        enqueueSnackbar('Error', {variant: 'error'}); // todo add text
        props.onClose();
      });
    }
  }

  return <AscendModal
    width={590}
    open={props.open}
    onClose={props.onClose}
    modalScroll
  >
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.centered}>Sorry, we’re currently not in your area!</Typography>
        </Box>
        <Box mb={2}>
          <Typography color={'textSecondary'} className={'fs-16 lh-20 mt-24'} align={'center'}>We currently aren’t licensed in your state.</Typography>
          <Typography className={'fs-16 lh-20 mt-20 mb-25 bold dark-green'} align={'center'}>If you would like one of our trusted local insurance partners to reach out to you, provide your number.</Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
          <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box className={classes.wrapper}>
              <Typography className={'medium fs-16 ml-8'} color={'textPrimary'}>Phone number</Typography>
              <Controller
                  control={control}
                  name={'phoneNumber'}
                  render={({ onChange }) => <PhoneNumberInput label={''} placeholder={'Phone'} name={'phoneNumber'} onChange={onChange} />}
              />
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <CallButton variant={'contained'}
                        className={'gtm-button-event'} data-gtm-button-name={'CREATE_PROFILE_BUTTON'}
                        disabled={!formState.isValid} type={'submit'}>Submit</CallButton>
          </Box>
        </form>
        <Box display={'flex'} alignItems={'center'} className={'mv-20'}>
          <img src={'/img/padlock.svg'} />
          <Typography className={'bold fs-14 ml-8'} color={'textPrimary'}>100% secure.  Your information will passed on securely to a trusted partner.</Typography>
        </Box>
        <Typography className={'vthin fs-10 lh-12'}>
          By submitting my email and phone number and clicking ‘Submit’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
          This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />

          I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
        </Typography>
      </Box>
  </AscendModal>
}

interface FormState {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  zip: string;
}
const Schema = yup.object().shape({
  phoneNumber: yup.string().required("Please enter a valid phone").min(10, "Please enter a valid phone"),
});

export const RoundedButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: '15px 0 11px',
  },
  label: {
    fontSize: 24,
  },
})(AscendButton);

export const CallButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: '14px 0 11px',
  },
  label: {
    fontSize: 16,
  },
})(AscendButton);

export default function (props: {open: boolean, onClose: () => void, zip: string}) {
  const [showNotServed, setShowNotServed] = React.useState(false);
  const [showReceivedRequest, setShowReceivedRequest] = React.useState(false);

  React.useEffect(() => {
    if (props.open && props.zip) {
      const sent = getSent();
      if (sent.includes(props.zip)) {
        setShowNotServed(false)
        setShowReceivedRequest(true)
      } else {
        setShowNotServed(true)
        setShowReceivedRequest(false)
      }
    } else {
      setShowNotServed(false)
      setShowReceivedRequest(false)
    }
  }, [props.open, props.zip])
  return <>
    <NotServedModal onClose={props.onClose} open={showNotServed} onSubmit={(zip?: string) => {
      setShowNotServed(false)
      setShowReceivedRequest(true)
      if (zip) {
        let sent = getSent();
        sent = [...sent, zip];
        sessionStorage.setItem('NOT_SERVED_SENT', JSON.stringify(sent));
      }
    }}/>
    <ReceivedRequestModal onClose={props.onClose} open={showReceivedRequest}/>
  </>
}

const getSent = (): string[] => {
  let sent: any = sessionStorage.getItem('NOT_SERVED_SENT');
  if (sent) {
    sent = JSON.parse(sent);
  } else {
    sent = [];
  }
  return sent;
}
