import React from 'react';
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const AdminNotificationHeight = '30px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: AdminNotificationHeight,
      background: '#FB0A0A',
      padding: '0 18px',
      color: 'white',
    },
    title: {
      fontSize: '18px',
      fontWeight: 700
    }
  }),
);

const AdminNotification = () => {
  const classes = useStyles();

  return <Box className={classes.root} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
  </Box>
};

export default AdminNotification;
