import React from "react";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {Box, Collapse, Typography, useMediaQuery} from "@material-ui/core";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendDivider from "../../../shared/components/AscendDivider";
import {useScrollPosition} from "../../../shared/useScrollPosition";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {ListItem, PlanFilterDataContext} from "./PlanFilterDataContext";
import clsx from "clsx";
import * as _ from 'lodash';
import FilterItems from "../shared/FilterItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 10,
      padding: '7px 16px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      [theme.breakpoints.up('sm')]: {
        padding: '16px 26px',
      }
    },
    medium: {
      fontWeight: 500
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    },
    icon: {
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
  }),
);

export default function PlanTopFilter() {
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [maxOutOfPocketRanges, setMaxOutOfPocketRanges] = React.useState<ListItem[]>([]);
  const [maxCostRanges, setMaxCostRanges] = React.useState<ListItem[]>([]);
  const scrollPosition = useScrollPosition();
  const [collapsed, setCollapsed] = React.useState(false);
  const [OOPExpanded, setOOPExpanded] = React.useState(true);
  const [totalCostExpanded, setTotalCostExpanded] = React.useState(true);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    if (scrollPosition > 300 && !collapsed) {
      setCollapsed(true)
    } else {
      if (scrollPosition < 150) {
        setCollapsed(false)
      }
    }
  }, [scrollPosition]);

  const {getPlanFilterData, planFilterData} = React.useContext(PlanFilterDataContext);

  useDebouncedEffect(() => {
    if (filter.values) {
      getPlanFilterData({
        variables: {
          filter: filter.values
        }
      });
    }
  }, 500, [filter.values]);

  React.useEffect(() => {

    if (planFilterData) {
      setMaxCostRanges(planFilterData.maxCostRanges);
      setMaxOutOfPocketRanges(planFilterData.maxOutOfPocketRanges);
    }
  }, [planFilterData]);

  if (!filter.values) {
    return null;
  }

  const Desktop = <div className={`${classes.card} plan-top-filters`}>
    <Collapse in={!collapsed}>
      <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>Select your maximum out-of-pocket and estimated average total cost per month preferences to find the right matching plans for your needs:</Typography>
      <div className={'h-16'}/>
    </Collapse>
    {!!maxOutOfPocketRanges &&
    <FilterItemWithCheckbox items={maxOutOfPocketRanges} selectedKeys={filter.values.maxOutOfPocketRangeNames || []} onChange={filter.switchMaxOutOfPocketRanges}
                            title={<AscendTooltip placement={'top'} arrow title={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}>
                              <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>Max Out-of-Pocket</Typography>
                            </AscendTooltip>}/>
    }
    <AscendDivider my={1}/>
    {!!maxCostRanges &&
    <FilterItemWithCheckbox items={maxCostRanges} selectedKeys={filter.values.maxCostRangeNames || []} onChange={filter.switchMaxCostRanges}
                            title={<AscendTooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
                              <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>Estimated average total cost per month</Typography>
                            </AscendTooltip>}/>
    }
  </div>

  const Mobile = <div className={`${classes.card} plan-top-filters mb-20`}>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} onClick={() => setOOPExpanded(!OOPExpanded)}>
      <Typography color={'textPrimary'} className={'fs-12 medium'}>Max out-of-pocket</Typography>
      <img src={'/img/arrow.svg'} className={clsx(classes.icon, OOPExpanded && classes.iconOpen)} />
    </Box>
    <Collapse in={OOPExpanded}>
      <FilterItemWithCheckboxMobile items={maxOutOfPocketRanges}
                                    selectedKeys={filter.values.maxOutOfPocketRangeNames || []}
                                    onChange={filter.switchMaxOutOfPocketRanges}/>
    </Collapse>
    <AscendDivider/>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} onClick={() => setTotalCostExpanded(!totalCostExpanded)}>
      <Typography color={'textPrimary'} className={'fs-12 medium'}>Estimated average total cost per month</Typography>
      <img src={'/img/arrow.svg'} className={clsx(classes.icon, totalCostExpanded && classes.iconOpen)} />
    </Box>
    <Collapse in={totalCostExpanded}>
      <FilterItemWithCheckboxMobile items={maxCostRanges}
                                    selectedKeys={filter.values.maxCostRangeNames || []}
                                    onChange={filter.switchMaxCostRanges}/>
    </Collapse>
  </div>

  return phone ? Mobile : Desktop;
}



function FilterItemWithCheckboxMobile({items, selectedKeys, onChange}: { items?: ListItem[], selectedKeys: string[], onChange: (key: any) => void}) {
  const classes = useStyles();

  return <table>
    <tbody>
    {!!items && _.chunk(items, 2).map((chunk, i) => <tr key={i}>
      {chunk.map(item => <td key={item.value}>
        <AscendCheckbox variant={'standart'}
                        name={item.value}
                        labelClassName={classes.checkboxLabel}
                        controlClassName={classes.checkbox}
                        label={item.label}
                        checked={!!selectedKeys && selectedKeys.includes(item.value)}
                        disabled={item.disabled}
                        onChange={() => {
                          onChange(item.value)
                        }}

        />
      </td>)}
    </tr>)
    }
    </tbody>
  </table>
}

function FilterItemWithCheckbox({items, selectedKeys, onChange, title}: { items?: ListItem[], selectedKeys: string[], onChange: (key: any) => void, title: JSX.Element }) {
  return <Box display={'flex'} alignItems={'center'}>
    <Box width={'180px'}>{title}</Box>
    <FilterItems onChange={onChange} selectedKeys={selectedKeys} items={items}/>
  </Box>
}
