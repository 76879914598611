import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationCreateMedicareQuoteForClientArgs} from "../../../../types";
import {client} from "../../../../shared/ApolloClient";

export default function useCreateMedicareQuoteForClient(options?: MutationHookOptions<any, MutationCreateMedicareQuoteForClientArgs>) {
  return useMutation<{createMedicareQuoteForClient: string}, MutationCreateMedicareQuoteForClientArgs>(gql(mutation), {
    ...options,
    client
  })
}

const mutation = `
mutation ($clientId: UUID!, $data: MedicareQuoteInput!, $agentOffer: Boolean) {
  createMedicareQuoteForClient(clientId: $clientId, data: $data, agentOffer: $agentOffer)
}
`;
