import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step2";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";

function Step2(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);

  if ((window as any).track) {
    (window as any).track(`Page View - ${(window as any).product} - Zip`);
  }

  return <Shared quote={quote} dispatch={dispatch}  {...props} />;
}

export default Step2 as any;
