import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useOpenedScopeOfAppointment() {
  return useMutation<any, {id: string}>(
    gql(openedScopeOfAppointmentMutation),
    {
      client: client
    }
  )
}

const openedScopeOfAppointmentMutation = `
mutation ($id: UUID!) {
  openedScopeOfAppointment(id: $id)
}
`;
