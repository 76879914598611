import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, RadioGroup, Typography, withStyles} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from "clsx";

const formName = "STEP_9a1";

function Step9a1(props: RouteComponentProps<any> & { classes: any }) {
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + "/" + questionsRoutes.step10)
  };

  const onSkip = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + "/" + questionsRoutes.step13b1)
  };

  return <>
    <div className={'h-32'} />
    <div className={'flex'}>
      <img src={"/img/doctor.svg"} className={'h-55 w-55 mt-8'}/>
      <div className={'ml-20'}>
        <Typography color={'textPrimary'} className={'bold fs-24'}>Add your preferred doctors</Typography>
        <Typography color={'textPrimary'} className={'fs-16 mt-8 lh-20'}>Not all plans cover all doctors. We’ll find plans that include your most important doctors.</Typography>
      </div>
    </div>
    <div className={'h-16'} />
    <div className={'flex'}>
      <img src={"/img/pills.svg"} className={'h-55 w-55 mt-8'}/>
      <div className={'ml-20'}>
        <Typography color={'textPrimary'} className={'bold fs-24'}>Find your prescription drugs</Typography>
        <Typography color={'textPrimary'} className={'fs-16 mt-8 lh-20'}>Drug costs can vary significantly by plan and not all drugs are covered by every plan — by adding your drugs, we can find the lowest cost option. </Typography>
      </div>
    </div>
    <div className={'h-16'} />
    <div className={'flex'}>
      <img src={"/img/pharmacy-green.svg"} className={'h-55 w-55 mt-8'}/>
      <div className={'ml-20'}>
        <Typography color={'textPrimary'} className={'bold fs-24'}>Select your pharmacy</Typography>
        <Typography color={'textPrimary'} className={'fs-16 mt-8 lh-20'}>We’ll let you know whether your pharmacy has a preferred pricing arrangement with your plan so that you can find the lowest cost.</Typography>
      </div>
    </div>
    <Box mt={"40px"} display={"flex"} justifyContent={"center"}>
      <AscendButton className={"gtm-step-event"} id={formName} variant={"contained"} onClick={onSubmit}>Continue to
        providing healthcare details</AscendButton>
    </Box>
    <Box mt={"28px"}>
      <Typography variant={"h4"} color={"textSecondary"} className={"text-align-centered medium"}>
        Feel free to skip ahead if this is not a high priority.
      </Typography>
    </Box>
    <Box mt={2}>
      <Typography variant={"h4"} color={"textSecondary"} className={clsx(props.classes.link, 'medium')} onClick={onSkip}>
        Skip to quotes
      </Typography>
    </Box>
  </>;
}

const classes: any = {
  link: {
    color: colors.custom.green.variant1,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "underline"
  }
}


export default withTitle(
  withStyles(classes)(Step9a1),
  `Great! Before we take you to our Medicare\nAdvantage marketplace...`,
  'In order to provide you the best personalized\nrecommendation, we’d like to collect a few more details. '
);
