import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

export default function useGetCounty(onCompleted?: (data: ICityResponse) => void) {
  return useLazyQuery<ICityResponse, {zip: string, stcountyfp?: string}>(
    gql(cityRequest),
    {
      onCompleted,
      fetchPolicy: 'no-cache',
    }
  );
}


interface ICityResponse {
  plans_zip_county_fips: {
    city: string,
    state: string,
    stcountyfp: string,
    countyname: string
  }[]
}


const cityRequest = `
  query ($zip: String!, $stcountyfp: String) {
    plans_zip_county_fips(where: {stcountyfp: {_like: $stcountyfp}, zip: {_eq: $zip}}) {
      city
      state
      countyname
      stcountyfp
    }
  }
`
