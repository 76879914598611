export const config = {
  graphQL: process.env.REACT_APP_GRAPHQL,
  resultsUrl: process.env.REACT_APP_RESULTS_URL,
  medigapUrl: process.env.REACT_APP_MEDIGAP_URL,
  enrollUrl: process.env.REACT_APP_ENROLL_URL,
  fullFlow: process.env.REACT_APP_FULL_FLOW,
  enableAuth: process.env.REACT_APP_ENABLE_AUTH,
  nextYearResultsUrl: process.env.REACT_APP_NEXT_YEAR_RESULTS_URL,
  userFlowUrl: process.env.REACT_APP_USER_FLOW_URL,
  keycloak: {
    url: process.env.REACT_APP_KEYCLOAK_SERVER,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    protocol: 'openid-connect',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  }
}
