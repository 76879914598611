import React from "react";
import {createStyles, makeStyles, Theme, useTheme, withStyles} from "@material-ui/core/styles";
import withErrorBoundary from "../../../shared/withErrorBoundary";
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import {RouteComponentProps, useHistory} from "react-router-dom";
import paths from "../../config/router-paths";
import {colors} from "../../../shared/AppTheme";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import QuoteBlankLeftSide from "./QuoteBlankLeftSide";
import {IStoredUser, resetStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {config} from "../../config/config";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      minHeight: '100vh',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '60px 0',
      },
    },
    title: {
      fontSize: 48,
      fontWeight: 500,
      '& strong': {
        color: colors.custom.green.variant2
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 36,
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 26,
      },
    },
    contentWrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '40px 30px',
      [theme.breakpoints.up('sm')]: {
        padding: '20px',
      },
    },
    colored: {
      backgroundColor: 'rgba(2, 144, 148, 0.1)',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 190,
      },
    },
    quiz: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        right: 0,
        top: 'auto',
        left: 0,
        bottom: 0,
        width: '100%',
        borderRadius: 0,
        zIndex: 2,
        '& > div': {
          flexDirection: 'column',
        },
        '& p': {
          flexDirection: 'column',
          textAlign: 'center',
        },
        '& button': {
          marginTop: 12,
        },
        padding: '11px 30px 18px 30px',
      },
      position: 'absolute',
      right: 21,
      top: 16,
      background: colors.custom.green.variant2,
      padding: '11px 17px 10px 12px',
      color: 'white',
      boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.04)',
      borderRadius: 4,
      width: 433,
    },
    quizButton: {
      borderRadius: 4,
    },
    quizTitle: {
      fontSize: 20,
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    image: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        width: 76,
        height: 76
      },
    },
    phoneButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      right: 40,
      top: 22,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
  }),
);

function Home(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down('xs'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    localStorage.removeItem('quote')
    localStorage.removeItem('medigapQuote')
    resetStoredUser();
    setStoredUser(getUserDataFromUrl());
  }, []);

  const onSubmit = (planType: 'MG' | 'MA') => {
    localStorage.setItem(planType === 'MA' ? 'quote' : 'medigapQuote', JSON.stringify({
      QUIZ_3: {
        medicarePlanType: {
          questionText: 'Medicare plan type',
          answerText: planType,
          value: planType
        }
      }
    }))
    if (planType === 'MA') {
      const showFlow = sessionStorage.getItem('showFlow')
      if (showFlow) {
        history.push(paths.MAquestionnaire + '/' + questionsRoutes.step2)
      } else {
        document.location.href = (config.nextYearResultsUrl as string)
      }
    } else {
      history.push(paths.MGquestionnaire + '/' + questionsRoutes.step2)
    }
  }

  const goToQuiz = () => {
    const showFlow = sessionStorage.getItem('showFlow')
    if (showFlow) {
      props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.quiz1)
    } else {
      props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.quiz1a)
    }
  }

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>Take the Medicare Survey - CoverRight</title>
        <meta name="description" content="Medicare Supplement or Medicare Advantage? Take the CoverRight Medicare Survey now to compare plans." />
      </Helmet>
      <QuoteBlankLeftSide />
      <Box className={classes.contentWrapper}>
        <Typography className={classes.title} color={'textPrimary'}>Are you looking for<br /><strong>Medicare Supplement</strong>?</Typography>
        <div className={tablet ? tiny ? 'h-0' : 'h-15' : 'h-30'} />
        <img className={classes.image} src={'/img/hand-shield.svg'} />
        <div className={tablet ? tiny ? 'h-10' : 'h-20' : 'h-35'} />
        <AscendButton variant={'contained'} onClick={() => onSubmit("MG")}>Get started</AscendButton>
        <div className={classes.quiz}>
          {!phone && <Typography className={classes.quizTitle} color={'inherit'}>Not sure? Take our short quiz to find out.</Typography>}
          {phone && <Typography className={classes.quizTitle} color={'inherit'}>Not sure? Take our short quiz to<br />find out.</Typography>}
          <Box display={'flex'} alignItems={'center'}>
            {!phone && <Typography className={'fs-14'} color={'inherit'}>Take our quiz to see if you’re better suited to Medicare Supplement or Medicare Advantage</Typography>}
            <QuizButton variant={'contained'} onClick={goToQuiz}>Take quiz</QuizButton>
          </Box>
        </div>
        <a href="tel:8889697667"
           className={classes.phoneButton}
        >
          <img src={'/img/phone.svg'} />
        </a>
      </Box>
      <Box className={`${classes.contentWrapper} ${classes.colored}`}>
        <Typography className={classes.title} color={'textPrimary'}>Or a <strong>Medicare Advantage</strong><br />plan?</Typography>
        <div className={tablet ? tiny ? 'h-0' : 'h-15' : 'h-30'} />
        <img className={classes.image} src={'/img/building2.svg'} />
        <div className={tablet ? tiny ? 'h-10' : 'h-20' : 'h-35'} />
        <AscendButton variant={'contained'} onClick={() => onSubmit("MA")}>Get started</AscendButton>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(Home);

// ?utm_source=smart_financial&utm_medium=cpc&utm_campaign=CA_HO3&utm_content=sf001&address=some&zip=10001&state=CA&first_name=Test&last_name=User&dob=11.11.1950&phone=6561113225&email=test@email.com&click_id=14&b2_direct_id1=b2_direct_id1&b2_direct_id2=b2_direct_id2&b2_direct_id1=3=b2_direct_id1=3
const getUserDataFromUrl = (): IStoredUser => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    zip: urlParams.get('zip') || undefined,
    firstName: urlParams.get('first_name') || undefined,
    lastName: urlParams.get('last_name') || undefined,
    birthDate: urlParams.get('dob') || undefined,
    phoneNumber: urlParams.get('phone') || undefined,
    email: urlParams.get('email') || undefined,
  }
}

const QuizButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '4px 22px',
    background: 'rgba(255, 120, 90, 1)',
    height: 38,
    '&:hover': {
      backgroundColor: '#FFB8A8',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FFB8A8',
      boxShadow: 'none',
    },
  },
  label: {
    whiteSpace: 'nowrap',
    color: 'white',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
