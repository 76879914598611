const paths = {
  home: '/',
  enroll: '/enroll',
  personalDetails: '/personal-details',
  personalDetailsExtended: '/personal-details-extended',
  receive: '/confirmation',
  soa: '/soa/',
  createAccount: '/create-account',
};

export default paths;
