import React from 'react';
import {DrugCoverage, ProviderOutput} from "../../../../types";
import {PlanParam} from './PlanParam';
import Benefit from "../../../../shared/components/Benefit";
import {PreferenceLine} from "../../PlanList/HealthcarePreferences";
import {Collapse, Typography} from "@material-ui/core";
import SeeMore from "../../../../shared/components/SeeMore";

function DrugCoverageList({coverage}: { coverage: DrugCoverage[] }) {
  const [expanded, setExpanded] = React.useState(false);
  if(coverage.length === 0) {
    return <Typography className={'fs-12 card-title'} color={'textPrimary'}>You haven’t added any drugs</Typography>;
  }

  return <>
    {coverage.slice(0, 3).map((drug, index) => <PlanParam key={drug.rxcui}
                                                          value={<Benefit value={drug.coverage}
                                                                          label={drug.name || 'Drug is missing'}/>}/>)}
    <Collapse in={expanded}>
      {coverage.slice(3).map((drug, index) => <PlanParam key={drug.rxcui}
                                                        value={<Benefit value={drug.coverage}
                                                                        label={drug.name || 'Drug is missing'}/>}/>)}
    </Collapse>
    {coverage.length > 3 && <SeeMore expanded={expanded} onToggle={value => setExpanded(!value)} />}
  </>
}

export {DrugCoverageList}
