import React from 'react';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {EnrollmentOutput, ScopeOfAppointmentOutput} from "../../enrollment-types";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useScopeOfAppointment() {
  return useLazyQuery<{scopeOfAppointment: ScopeOfAppointmentOutput}, {soaId: string}>(gql(request), {
    client: client
  })
}

const request = `
query($soaId: UUID!) {
  scopeOfAppointment(soaId: $soaId) {
    email
    firstName
    id
    isConfirmed
    lastName
    phoneNumber
  }
}
`
