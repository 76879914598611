import React from "react";
import {Box, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import useUserProfile from "./hooks/useUserProfile";
import {RouteComponentProps, useHistory} from "react-router";
import paths from "./config/router-paths";
import PlanSummary from "../shared/components/PlanSummary";
import AscendCard from "../shared/components/AscendCard";
import CollapsableCard from "../shared/components/CollapsableCard";


export default function DrugCostBreakdown(props: RouteComponentProps<{ planId: string }>) {
  const {planId} = props.match.params;
  const {userProfile} = useUserProfile();
  const history = useHistory();

  return <Box display={'flex'} justifyContent={'center'}>
    <Box maxWidth={800}>
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>My Drugs</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>Your estimated drug cost summary. Update your drugs and pharmacy on the My Healthcare page to see the impact on your costs.</Typography>
      <div className={'h-32'} />
      <AscendButton variant={'contained'} onClick={() => history.push(paths.myHealthcare)}>Update drugs and pharmacy</AscendButton>
      <div className={'h-15'} />
      <CollapsableCard
        className={'fill-width'}
        title={'How are my estimated drug costs calculated?'}
        description={<Typography color={'textPrimary'} className={'p-10 fs-12'}>Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information.</Typography>}
      />
      <div className={'h-10'} />
      {(!!planId && userProfile?.countyName && userProfile?.zip) && <>
        <AscendCard padding={'30px'}>
          <PlanSummary zip={userProfile.zip} countyName={userProfile.countyName} planId={planId}/>
        </AscendCard>
      </>}
      <div className={'h-100'} />
    </Box>
  </Box>
}

