import React from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {ApolloError, gql} from "apollo-boost";
import {PageableMedigapPlanOutput, QueryMedigapPlansArgs} from "../../../../medigap-types";

export default function usePlans(onCompleted?: (response: IPlansOutput) => void, onError?: (error: ApolloError) => void) {

  return useLazyQuery<IPlansOutput, QueryMedigapPlansArgs>(gql(getPlans), {
    onCompleted: onCompleted,
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    onError
  });
}
//   return useLazyQuery(<IPlanSearchOutput>(gql(getPlans), {
//
//   }))
// }

interface IPlansOutput {
  medigapPlans: PageableMedigapPlanOutput
}

export const planFields = `
        amBestRating
        householdDiscount
        key
        monthlyPremium
        orgName
        parentOrgName
        title
        subTitle
        naic
        spRating
        ratingClass
        planName
        showAdditionalBenefitsDescription
`

const getPlans = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!, $sort: [MedigapPlansSortInput!]) {
    medigapPlans(filter: $filter, page: $page, sort: $sort) {
      data {
        ${planFields}
        duplicates {
          ${planFields}
        }
      }
      totalElements
    }
}
`;
