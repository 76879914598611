import MaskedInput from "react-text-mask";
import React from "react";

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export default function MedicareNumberInput(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      autoComplete="off"
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\w/, /\w/, /\w/, /\w/, ' ', '-', ' ', /\w/, /\w/, /\w/, ' ', '-', ' ', /\w/, /\w/, /\w/, /\w/]}
      placeholderChar={'\u200B'}
    />
  );
}
