import React from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedigapPlanOutput} from "../../../../medigap-types";
import {planFields} from "../../PlanList/hooks/usePlans";
import PlanData, {IMedigapPlanDetails} from "../../config/PlanData";

export default function usePlansByBid(keys?: string[]) {
  const [plans, setPlans] = React.useState<MedigapPlanOutputWithDetails[]>([]);
  const [getPlans] = useLazyQuery<{[name: string]: MedigapPlanOutput}>(gql(getPlansQuery(keys)), {
    onCompleted: data => {
      if (data) {
        setPlans(addDetailsToPlanList(Object.values(data).filter(p => !!p)))
      }
    },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    if (keys) {
      getPlans();
    }
  }, [keys])

  return plans;

}

const getPlansQuery = (keys?: string[]) => {
  let query;
  if (!!keys?.length) {
    query = keys.map((val, i: number) => `plan${i}: medigapPlan(key: "${val}") {
      ${planFields}
    }`)
  } else {
    query = `getMyQuoteId`
  }
  return `{${query}}`;
};

export function addDetailsToPlanList(plans: MedigapPlanOutput[]): MedigapPlanOutputWithDetails[] {
  return plans.filter(plan => !!plan?.planName).map(addDetailsToPlan) as MedigapPlanOutputWithDetails[];
}

export function addDetailsToPlan(plan?: MedigapPlanOutput): MedigapPlanOutputWithDetails | undefined {
  return (plan && plan.planName) ? {...plan, ...PlanData[plan.planName as string]} : undefined;
}

export type MedigapPlanOutputWithDetails = MedigapPlanOutput & IMedigapPlanDetails;

