import React from "react";
import {RouteComponentProps} from "react-router";
import QuoteStepper from "../shared/components/QuoteStepper";
import useStepperState from "./hooks/useStepperState";

const MedigapQuoteStepper = (props: RouteComponentProps<{routeName: string}>) => {
  const {medigapState} = useStepperState();
  return <QuoteStepper {...props}
                       title={<>Complete this simple form to get started on finding a personalized Medicare plan for you</>}
                       stepperState={medigapState}
  />
}

export default MedigapQuoteStepper;
