import React from "react";
import {getQuoteId} from "../../shared/QuoteId";
import {
  DrugCostOutput
} from "../../types";
import * as _ from "lodash";
import {usePlanLazy} from "../../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import {Bar} from "react-chartjs-2";
import AscendPreloader from "./AscendPreloader";
import {colors} from "../AppTheme";

const options = {
  legend: {
    labels: {
      fontFamily: 'Sailec',
      fontColor: colors.text.primary
    }
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        offset: true,
        ticks: {
          beginAtZero: true,
          fontFamily: 'Sailec',
          fontColor: colors.text.primary
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          fontFamily: 'Sailec',
          fontColor: colors.text.primary
        }
      },
    ],
    chartArea: {
      backgroundColor: 'rgba(251, 85, 85, 0.4)'
    }
  },
}

const chartColors = ['#1E95A0', '#1DB399', '#C8E8EB', '#7DA8AC', '#84C7BB', '#C3D4D6'];

type PlanSummaryChartProps = {
  zip: string,
  countyName: string,
  planId: string
}

export default function PlanSummaryChart(props: PlanSummaryChartProps) {
  let {zip, planId, countyName} = props;

  const [getPlan, {data}] = usePlanLazy();

  React.useEffect(() => {
    if (zip && planId && countyName) {
      getPlan({variables: {bidId: planId, zip, countyName, quoteId: getQuoteId()}})
    }
  }, [zip, planId, countyName])

  const plan = React.useMemo(() => {
    if (data) {
      return data.plan;
    }
    return null;
  }, [data])

  const getColor = (index: number): string => index >= chartColors.length ? getColor(index - chartColors.length) : chartColors[index];

  const chartdata = React.useMemo(() => {
    /*
    [
        {
          label: '# of Red Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: '# of Blue Votes',
          data: [2, 3, 20, 5, 1, 4],
          backgroundColor: 'rgb(54, 162, 235)',
        },
        {
          label: '# of Green Votes',
          data: [3, 10, 13, 15, 22, 30],
          backgroundColor: 'rgb(75, 192, 192)',
        },
      ]
     */
    function getRandomInt(min: number, max: number) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const costs = Object.values(_.groupBy(plan?.drugCostMonthlyBreakdown?.costs, 'name'));

    if (costs.length) {
      const labels = costs.reduce((prev: any[], curr: any[]) => {
        if (prev.length > curr.length) {
          return prev;
        }
        return curr;
      }, [] as any)
      return {
        labels: labels.map(c => c.month).map(intToMonth),
        datasets: costs.map((costArr: DrugCostOutput[], i: number) => ({
          label: costArr[0].name,
          data: labels.map(c =>
            costArr.find(co => co.month === c.month)?.cost.replace('$', '').replace(',', '') || 0
          ),
          backgroundColor: getColor(i),
        })),
      }
    } else {
      return [];
    }
  }, [plan])

  const plugin = React.useMemo(() => {
    const monthCount = plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length;
    if (monthCount) {
      return {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart: any) => {
          const ctx = chart.ctx;
          const canvas = chart.canvas;
          const chartArea = chart.chartArea;

          // Chart background
          var gradientBack = canvas.getContext("2d").createLinearGradient(chartArea.left, 0, chartArea.right, 0);
          gradientBack.addColorStop(0, "#FEF7EB");
          gradientBack.addColorStop(3 / monthCount, "#FEF7EB");
          gradientBack.addColorStop(3 / monthCount, "#F2FBFF");
          gradientBack.addColorStop(1, "#F2FBFF");

          ctx.fillStyle = gradientBack;
          ctx.fillRect(chartArea.left, chartArea.bottom,
            chartArea.right - chartArea.left, chartArea.top - chartArea.bottom);
        }
      };
    }
    return {};
  }, [plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length])

  const barLabelPlugin = React.useMemo(() => {
    const monthCount = plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length;
    if (monthCount) {
      return {
        id: 'custom_canvas_bar_label',
        afterDraw: (chart: any) => {
          const ctx = chart.ctx;

          ctx.font = 'bold 14px Sailec';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          //console.log(chart.data.datasets);

          chart.data.datasets.forEach(function (dataset: any, index: number, datasets: any[]) {
            for (let i = 0; i < dataset.data.length; i++) {
              const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                left = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model.x;
              let rowHeight = 0;
              const models = _.sortBy(datasets.map(d => d._meta[Object.keys(d._meta)[0]].data[i]._model), 'y');
              for (let j = 0; j < models.length; j++) {
                if (models[j].y === model.y) {
                  if (!!models[j + 1]) {
                    rowHeight = models[j + 1].y - model.y;
                  } else {
                    rowHeight = model.base - model.y;
                  }
                }
              }
              ctx.fillStyle = '#1C434F'; // label color
              ctx.lineWidth = 2;
              var label = '$' + dataset.data[i];
              // ctx.fillText(label, left, model.y + (rowHeight / 2 + 7));
              if (index === datasets.length - 1) {
                ctx.fillStyle = '#1C434F';
                ctx.lineWidth = 2;
                label = '$' + _.sum(datasets.map(d => d.data[i]).map(parseFloat)).toFixed(2);
                ctx.fillText(label, left, model.y - 1)
              }
            }
          });
        }
      };
    }
    return {};
  }, [plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length])

  if (!plan) {
    return <AscendPreloader />;
  }

  return <Bar data={chartdata} options={options} plugins={[barLabelPlugin]} />;
}

function intToMonth(month: number): string {
  switch (month) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
}
