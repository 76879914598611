import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, RadioGroup, Typography, withStyles} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from "clsx";

const formName = 'STEP_9b1';

function Step9b1(props: RouteComponentProps<any> & {classes: any}) {
  const [state, dispatch] = React.useContext(QuestionnaireContext);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step15)
  };

  return <>
    <Box mt={'32px'}>
      <Typography variant={'h3'} color={'textSecondary'} className={'text-align-centered'}>
        You can schedule a free consultation with your support agent, or alternatively book an appointment through the link below.
      </Typography>
    </Box>
    <Box mt={'24px'} display={'flex'} justifyContent={'center'}>
      <img src={'/img/pointer.svg'} />
    </Box>
    <Box mt={'20px'} display={'flex'} justifyContent={'center'}>
      <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={onSubmit}>Book an appointment</AscendButton>
    </Box>
    <Box mt={'12px'} display={'flex'} justifyContent={'center'}>
      <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
    </Box>
  </>;
}


export default withTitle(
  Step9b1,
  `Let’s review your Medicare Supplement and\n Prescription Drug Plan options`,
  'We have received your request details, and an agent will be in\n touch via email to discuss your Medicare Supplement and\n Prescrtipion Drug Plan options.'
);
