import React from "react";
import {Box, RadioGroup} from "@material-ui/core";
import AscendTitle from "../../shared/components/AscendTitle";
import AscendRadio from "../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../shared/useCommonStyles";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {withTitle} from "../../shared/components/withTitle";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {questionsRoutes} from "../hooks/useStepperState";
import CollapsableCard from "../../shared/components/CollapsableCard";
import AscendButton from "../../shared/components/AscendButton";


const useStyles = makeStyles({
  info: {
    width: 656,
    marginTop: 16,
  }
});

const formName = 'STEP_6a';

function Step6a(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [employed, setEmployed] = React.useState<boolean | undefined>(state.STEP_6a?.employed);
  const [intendToKeep, setIntendToKeep] = React.useState<boolean | undefined>(state.STEP_6a?.intendToKeep);
  const [hasMore20, setHasMore20] = React.useState<boolean | undefined>(state.STEP_6a?.hasMore20);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const nextDisabled = React.useMemo(() => {
    if (employed === false) {
      return false;
    }
    if (employed && intendToKeep) {
      return typeof hasMore20 === 'undefined'
    }
    if (employed) {
      return typeof intendToKeep === 'undefined'
    }
    return true;
  }, [employed, hasMore20, intendToKeep])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {employed, hasMore20, form: formName, intendToKeep, stateKey: formName,}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step8);
  };

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'}>
      <AscendTitle title={'Do you currently have health insurance through your (or your spouse’s) employer?'} />
      <div className={'h-8'} />
      <RadioGroup defaultValue="female" classes={{root: commonClasses.rootRadioGroup}} row={true}>
        <Box display={'flex'} flex={1}>
          <AscendRadio checked={employed} onChange={(e:any, checked: boolean) => {
            setEmployed(checked)
            setHasMore20(undefined)
            setIntendToKeep(undefined)
          }} value="yes" label="Yes" />
        </Box>
        <Box className={commonClasses.radio} display={'flex'} flex={1}>
          <AscendRadio checked={employed === false} onChange={(e:any, checked: boolean) => {
            setHasMore20(undefined)
            setIntendToKeep(undefined)
            setEmployed(!checked)
          }} value="no" label="No" />
        </Box>
      </RadioGroup>
    </Box>
    {/*<Collapse in={employed}>
      <Box mt={'40px'}>
        <AscendTitle title={'Do you intend to keep you (or your spouse’s) employer insurance?'} />
        <div className={'h-8'} />
        <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
          <Box display={'flex'} flex={1}>
            <AscendRadio checked={!!intendToKeep} onChange={(e:any, checked: boolean) => setIntendToKeep(checked)} value="yes" label="Yes" />
          </Box>
          <Box className={commonClasses.radio} flex={1}>
            <AscendRadio checked={intendToKeep === false} onChange={(e:any, checked: boolean) => setIntendToKeep(!checked)} value="no" label="No" />
          </Box>
        </RadioGroup>
      </Box>
    </Collapse>
    <Collapse in={employed && intendToKeep}>
      <Box mt={'40px'}>
        <AscendTitle title={'Does the employer have more than 20 employees?'} />
        <div className={'h-8'} />
        <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
          <Box display={'flex'} flex={1}>
            <AscendRadio checked={!!hasMore20} onChange={(e:any, checked: boolean) => setHasMore20(checked)} value="yes" label="Yes" />
          </Box>
          <Box className={commonClasses.radio} flex={1}>
            <AscendRadio checked={hasMore20 === false} onChange={(e:any, checked: boolean) => setHasMore20(!checked)} value="no" label="No" />
          </Box>
        </RadioGroup>
      </Box>
    </Collapse>*/}
    {employed === true && <CollapsableCard
      className={classes.info}
      title={'Unless you’re retiring soon, you may want to stay with employer insurance.'}
      description={<>
        <p>Your employer group coverage may provide more comprehensive coverage at a lower cost than Medicare. It’s best to speak with your company’s benefits manager to help determine if you should delay your enrollment in Medicare.  Depending on your employer size, you may eligible to delay with no penalty:</p>
        <ul>
          <li>If your employer has <strong>more than 20 employees</strong>, you may be able to delay enrollment in Medicare without penalty. You should check with your company’s benefits manager to confirm.</li>
          <li>If your employer has <strong>less than 20 employees</strong>, you should enroll in Medicare during your Initial Enrollment Period to avoid penalties.</li>
        </ul>
      </>}
    />}
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} disabled={typeof employed === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </>
}

export default withTitle(
  Step6a,
  'Do you currently have employer insurance?',
  ''
)
