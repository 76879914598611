import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, RadioGroup, Typography} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../shared/AppTheme";
import useCommonStyles from "../../shared/useCommonStyles";

const formName = 'STEP_5a2';

function Step5a2(props: RouteComponentProps<any>) {
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step6a)
  };

  return <>
    <div className={'h-32'} />
    <Box display={'flex'} flex={1} flexDirection={'column'} alignItems={'center'} mb={2}>
      <img src={'/img/cake.svg'} />
    </Box>
    <AscendInfo open={true}
                description={
                  <p style={{margin: '0'}}>Your <strong>Initial Enrollment Period</strong> is the 7-month enrollment window when you are first eligible for Medicare. It starts 3 months before the month of your 65th birthday.</p>
                }/>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={onSubmit}>Sounds good! Let’s continue</AscendButton>
    </Box>
  </>;
}

export default withTitle(
  Step5a2,
  'It looks like you’re preparing for Medicare early.',
  'You can still search for a plan now and we can help you enroll when\nyou reach your Initial Enrollment Period.'
);
