import React from "react";
import {Collapse} from "@material-ui/core";

interface OptionalCollapseProps {
  inCollapse: boolean;
  in?: boolean;
}

const OptionalCollapse = React.memo((props: React.PropsWithChildren<OptionalCollapseProps>) => {
  if (props.inCollapse) {
    return <Collapse in={props.in}>
      {props.children}
    </Collapse>
  }
  return <>{props.children}</>
})

export default OptionalCollapse;
