import React from "react";
import { usePlanFilterData} from "./hooks/usePlanFilterData";
import {FilterItem, QueryPlanTypesArgs} from "../../../types";
import {QueryLazyOptions} from "@apollo/react-hooks";

interface PlanFilterDataContextState {
  getPlanFilterData: (data?: QueryLazyOptions<QueryPlanTypesArgs>) => void,
  planFilterData: PlanFilterData | undefined,
}

export const PlanFilterDataContext = React.createContext<PlanFilterDataContextState>({
  getPlanFilterData: () => {},
  planFilterData: undefined
});

export function PlanFilterDataProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const [planFilterData, setPlanFilterData] = React.useState<PlanFilterData>();

  const [request, data] = usePlanFilterData();

  React.useEffect(() => {
    if (data.data) {
      setPlanFilterData({
        SNPTypes: transformListItem(data.data.SNPTypes),
        companies: transformListItem(data.data.companies),
        extraBenefits: transformListItem(data.data.extraBenefits),
        maxCostRanges: transformListItem(data.data.maxCostRanges),
        maxOutOfPocketRanges: transformListItem(data.data.maxOutOfPocketRanges),
        partBPremiumReductionCount: data.data.partBPremiumReductionCount,
        planTypes: transformListItem(data.data.planTypes),
      })
    }
  }, [data]);

  return <PlanFilterDataContext.Provider value={{getPlanFilterData: request, planFilterData}}>
    {props.children}
  </PlanFilterDataContext.Provider>

}

export type ListItem = {
  value: any,
  label: any,
  disabled?: boolean
}

interface PlanFilterData {
  companies: ListItem[],
  planTypes: ListItem[],
  SNPTypes: ListItem[],
  extraBenefits: ListItem[],
  maxCostRanges: ListItem[],
  maxOutOfPocketRanges: ListItem[],
  partBPremiumReductionCount: number,
}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}




function transformListItem(collection: FilterItem[]): ListItem[] {
  const replace = (name: string) => name.replace("Over-the-counter benefits", "Over-the-counter Allowance")
  return collection.map((item: any) => ({
      value: item.key,
      label: (item.count > 0) ? `${replace(item.name)} (${item.count})`: replace(item.name),
      disabled: item.count === 0
    })
  )
}
