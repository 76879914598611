import {DrugDeliveryTypeDto, PharmacyLocationDistanceOutput} from "../../types";
import React, {Dispatch, SetStateAction} from "react";
import PharmaciesModal from "../../Questionnaire/components/modals/PharmaciesModal";
import {Box, Collapse, IconButton, RadioGroup, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import * as _ from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import AscendRadio from "./AscendRadio";
import useCommonStyles from "../useCommonStyles";
import {formatPharmacyName} from "../utils";


const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
  },
  linkGray: {
    color: colors.text.secondary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
    marginBottom: 20
  },
  addPharmacyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: `1px dashed ${colors.custom.green.variant2}`,
    background: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 8,
    width: '100%',
    paddingTop: 17,
    paddingBottom: 17,
    color: colors.custom.green.variant2,
    fontWeight: 700,
    fontSize: 16,
  },
  removeButton: {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
  }
});

type PharmaciesSelectionProps = {
  selectedPharmacies?: PharmacyLocationDistanceOutput[],
  setSelectedPharmacies: Dispatch<SetStateAction<PharmacyLocationDistanceOutput[] | undefined>>,
  zip?: string,
  filled?: boolean,
  orderType?: DrugDeliveryTypeDto,
  onOrderTypeChange?: (orderType: DrugDeliveryTypeDto) => void
}

export const PharmaciesSelection = ({selectedPharmacies, setSelectedPharmacies, filled, zip, onOrderTypeChange, orderType: outerOrderType}: PharmaciesSelectionProps) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const commonClasses = useCommonStyles();
  const [orderType, setOrderType] = React.useState<DrugDeliveryTypeDto | undefined>();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (typeof orderType !== 'undefined' && onOrderTypeChange) {
      onOrderTypeChange(orderType);
    }
  }, [orderType])

  React.useEffect(() => {
    if (outerOrderType) {
      setOrderType(outerOrderType)
    }
  }, [outerOrderType])

  const onPopupSubmit = (paharmacies: PharmacyLocationDistanceOutput[]) => {
    setModalOpen(false);
    setSelectedPharmacies(paharmacies)
  };

  const onModalClose = () => {
    setModalOpen(false);
  }

  return <>
    {!!zip && <PharmaciesModal zip={zip}
                               values={selectedPharmacies}
                               open={modalOpen}
                               onClose={onModalClose}
                               onSubmit={onPopupSubmit} />}
    <RadioGroup defaultValue="female" row={!phone}>
      <Box display={'flex'} flex={1}>
        <AscendRadio checked={orderType === DrugDeliveryTypeDto.Pharmacy} onChange={(e, checked) => setOrderType(DrugDeliveryTypeDto.Pharmacy)} value="yes" label="Retail Pharmacy" />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendRadio checked={orderType === DrugDeliveryTypeDto.Mail} onChange={(e, checked) => {
          setOrderType(DrugDeliveryTypeDto.Mail);
        }} value="no" label="Mail Order" />
      </Box>
    </RadioGroup>
    <Collapse in={orderType === DrugDeliveryTypeDto.Pharmacy}>
      <Box display={'flex'} flexDirection={'column'} flex={1} mt={'16px'}>
        {!!selectedPharmacies?.length && <Box mb={'23px'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Your selected pharmacy:</Typography>
        </Box>}
        {selectedPharmacies?.map(pharmacy => <Box key={pharmacy.npi} className={classes.lineWrapper}>
          <PharmacyLine pharmacy={pharmacy} />
          <Box ml={2} minWidth={'123px'}>
            <Typography variant={'body2'} className={classes.linkGray} onClick={() => setModalOpen(true)}>Change pharmacy</Typography>
          </Box>
          <Box ml={'6px'} mr={'-12px'}>
            <IconButton onClick={() => setSelectedPharmacies(pharmacies => {
              const result = _.without(pharmacies, pharmacy);
              return result;
            })}>
              <img src={'/img/remove_round_green_2.svg'} className={classes.removeButton} />
            </IconButton>
          </Box>
        </Box>)}
        {(orderType === DrugDeliveryTypeDto.Pharmacy && !selectedPharmacies?.length) && <Box className={classes.addPharmacyButton} onClick={() => setModalOpen(true)}>
          <Box mr={'10px'} mt={'11px'}>
            <img src={'/img/plus_green_2.svg'} />
          </Box>
          Add pharmacy
        </Box>}
      </Box>
    </Collapse>
  </>
}

const useLineStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  }
});

type PharmacyLineProps = {
  pharmacy: PharmacyLocationDistanceOutput,
  green?: boolean
}

const PharmacyLine = (props: PharmacyLineProps) => {
  const classes = useLineStyles(props);

  return <Box className={classes.root}>
    <Box>
      <Box mb={'10px'}>
        <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>{formatPharmacyName(props.pharmacy.name || '')}</Typography>
      </Box>
      <Typography variant={'body2'} color={'textSecondary'}>{props.pharmacy.address}</Typography>
    </Box>
  </Box>
}


