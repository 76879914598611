import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationCreateMedicareQuoteFromMedigapQuoteArgs} from "../../../../types";


export default function useCreateMedicareQuoteFromMedigapQuote(options?: MutationHookOptions<{createMedicareQuoteFromMedigapQuote: string}, MutationCreateMedicareQuoteFromMedigapQuoteArgs>) {
  return useMutation<{createMedicareQuoteFromMedigapQuote: string}, MutationCreateMedicareQuoteFromMedigapQuoteArgs>(
    gql(request),
    {
      ...options,
    }
  );
}


const request = `
mutation ($medigapQuoteId: UUID!) {
  createMedicareQuoteFromMedigapQuote(medigapQuoteId: $medigapQuoteId)
}
`;
