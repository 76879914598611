import React, {PropsWithChildren} from "react";
import Layout from "../../../shared/Layout";
import {ComparisonContext} from "./ComparisonContext";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  Theme,
  Typography, useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import AscendCard from "../../../shared/components/AscendCard";
import {planStyles, RoundedButton} from "../PlanList/PlanCard";
import {makeStyles} from "@material-ui/core/styles";
import usePlansByBid, {MedigapPlanOutputWithDetails} from "../DetailedPlan/hooks/usePlansByBid";
import {RouteComponentProps, useHistory} from "react-router";
import PlanDetailsContent from "../DetailedPlan/PlanDetailsContent";
import CompareHeaderCard from "./components/CompareHeaderCard";
import {colors} from "../../../shared/AppTheme";
import {AscendSubtitle} from "../DetailedPlan/components/AscendSubtitle";
import {ListPlanDetailText} from "../DetailedPlan/components/ListPlanDetail";
import clsx from "clsx";
import Benefit from "../../../shared/components/Benefit";

import * as Scroll from "react-scroll";
import Footer from "../Footer";
import TooltipedTitle from "../../shared/components/TooltipedTitle";
import {PlanView} from "../DetailedPlan/DetailedPlan";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import BackButton from "../../shared/components/BackButton";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";

const Link = Scroll.Link;
var Element = Scroll.Element;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: 0,
      background: "none"
    },
    bold: {
      fontWeight: 500
    },
    italic: {
      fontStyle: "italic",
    },
    header: {
      position: "sticky",
      top: 43,
      zIndex: 5,
      background: "white",
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    titleTable: {
    },
    list: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingInlineStart: 0,
      listStyle: 'inside',
      '& li': {
        fontSize: 12,
        color: colors.text.primary,
        lineHeight: '20px',
        fontWeight: 400,
      }
    },
    table: {
      tableLayout: "fixed",
      width: "100%",
      background: "white",
      borderCollapse: "collapse",
      "& tr": {
        borderBottom: "1px solid #E8ECED",
        "&:not(:first-child)": {
          borderTop: "1px solid #E8ECED",
        },
        "& td": {
          padding: 20,
          verticalAlign: "top",
          height: 1,
          minHeight: 1,
          "& ul": {
            marginBlockStart: 0,
            marginBlockEnd: 0,
            paddingInlineStart: 0,
            listStyle: 'inside',
            fontSize: 12,
            color: colors.text.primary,
            lineHeight: '20px',
            '& li span': {
              marginLeft: -5
            }
          }
        },
        "& td:not(:last-child)": {
          // borderRight: "1px solid #E8ECED",
        },
        "& td.border:not(:last-child)": {
          borderRight: "1px solid #E8ECED",
        },
        "& td:last-child": {
          background: "rgba(2, 144, 148, 0.05)"
        }
      }
    },
    dataTable: {
      marginLeft: 20,
      marginRight: 20,
      width: "calc(100% - 40px)",
      "& tr": {
        "& td:first-child": {
          padding: '20px 20px 20px 0',
        },
        "& td:last-child": {
          padding: '20px 0px 20px 20px',
        },
      }

    },
    buttonsTable: {tableLayout: "fixed",
      width: "100%",
      background: "white",
      borderCollapse: "collapse",
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "25px"
    },
    iconButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-start",
      "& div": {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    summaryButton: {
      padding: "8px 21px",
      borderRadius: 8,
      "& .MuiButton-label": {
        fontSize: "12px !important",
      }
    },
    sectionTop: {
      background: "white",
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      padding: "24px 0 10px",
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    sectionBottom: {
      background: "white",
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      height: 24,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    sectionMiddle: {
      background: "white",
      padding: "16px 0 10px",
    },
    h3Title: {
      background: "rgba(94, 203, 158, 0.1)!important"
    },
    mobileSelectorWrapper: {
      position: 'fixed',
      width: '100%',
      left: 0,
      height: 70,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: colors.custom.green.variant2,
      color: 'white',
      zIndex: 10,
    },
    icon: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(90deg) !important',
    },
    button: {
      background: 'white',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      '&:hover': {
        background: 'white',
      }
    },
    dot: {
      width: 10,
      height: 10,
      border: '1px solid #A4B4B9',
      borderRadius: 5,
      marginLeft: 7,
      marginRight: 7,
    },
    dotActive: {
      background: '#A4B4B9',
    },
    backButtonWrapper: {
      position: 'sticky',
      top: 0,
      background: '#f5f7f6',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        width: '91%',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      }
    }
  }),
);

interface ViewProps {
  zip: string,
  countyName: string,
  plans: MedigapPlanOutputWithDetails[],
  setPlanId: (value?: string) => void
  setModalOpen: (value: boolean) => void
}

export default function PlanComparison(props: RouteComponentProps<{ zip: string, countyName: string }>) {
  let {zip, countyName} = props.match.params;
  const {list} = React.useContext(ComparisonContext);
  const plans = usePlansByBid(list);
  const [planId, setPlanId] = React.useState();
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const classes = styles();

  if (!plans) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <AscendPreloader />
      <Footer/>
    </Box>
  }

  return <Layout SideComponent={PlanDetailsContent}>
    <Box position={'relative'}>
      <Box className={classes.backButtonWrapper}>
        <div>
          <BackButton
            startIcon={<img src={"/img/arrow-left.svg"}/>}
            onClick={() => history.push(`/plans/${zip}/${countyName}`)}>
            Back to Plans
          </BackButton>
        </div>
      </Box>
      {!!plans && <>
      {phone ?
        <Mobile zip={zip} countyName={countyName} plans={plans} setModalOpen={setModalOpen} setPlanId={setPlanId} /> :
        <Desktop zip={zip} countyName={countyName} plans={plans} setModalOpen={setModalOpen} setPlanId={setPlanId} />}
        </>}
      <Box display={"flex"} flex={1} justifyContent={"center"}>
        <Footer/>
      </Box>
    </Box>
  </Layout>;
}

function Mobile(props: ViewProps) {
  const classes = styles();
  const [index, setIndex] = React.useState(0);

  const handleChangeIndex = (i: number) => {
    setIndex(i);
  };

  const plan = React.useMemo(() => {
      return props.plans[index];
  }, [index, props.plans]);

  return <>
    <style>
      {'#fc_frame{bottom: 70px!important}'}
    </style>
    <div className={'h-15'} />
    {!!plan && <PlanView
      key={plan.key}
      zip={props.zip}
      countyName={props.countyName}
      plan={plan as any}
      setModalOpen={v => {
        props.setPlanId(plan.key);
        props.setModalOpen(v);
      }}
      setEmailOpen={() => {}}
    />}
    {/*<SwipeableViews
      axis={'x'}
      index={index}
      onChangeIndex={handleChangeIndex}
    >
      {props.plans.map(plan => <PlanView
        key={plan.bidId}
        zip={props.zip}
        countyName={props.countyName}
        plan={plan}
        setModalOpen={v => {
          props.setPlanId(plan.bidId);
          props.setModalOpen(v);
        }}
        setEmailOpen={() => {}}
      />)}
    </SwipeableViews>*/}
    <div className={classes.mobileSelectorWrapper}>
      <Box display={'flex'} flex={1} alignItems={'center'}>
        {index > 0 && <Box display={'flex'} alignItems={'center'} onClick={() => setIndex(index - 1)}>
          <IconButton className={classes.button}>
            <img className={clsx(classes.icon, classes.iconOpen)} src={'/img/arrow-green-2.svg'} />
          </IconButton>
          <div className={'w-16'} />
          <Typography className={'bold fs-12'}>{props.plans[index - 1].title}</Typography>
        </Box>}
      </Box>
      <Box display={'flex'} alignItems={'flex-end'} height={'100%'} pb={'3px'}>
        {props.plans.map((p, i) => <div className={clsx({[classes.dot]: true, [classes.dotActive]: index === i})} />)}
      </Box>
      <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'flex-end'}>
        {index < props.plans.length - 1 && <Box display={'flex'} alignItems={'center'} onClick={() => setIndex(index + 1)}>
          <Typography align={'right'} className={'bold fs-12'}>{props.plans[index + 1].title}</Typography>
          <div className={'w-16'} />
          <IconButton className={classes.button}>
            <img className={clsx(classes.icon)} src={'/img/arrow-green-2.svg'} />
          </IconButton>
        </Box>}
      </Box>
    </div>
  </>
}

function Desktop(props: ViewProps) {
  const classes = styles();
  let {plans} = props;
  const planClasses = planStyles();
  const [expandedState, setExpandedState] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getValuesByKey = React.useCallback((key: keyof MedigapPlanOutputWithDetails, lastValue?: any, mapFunction?: (value: any) => any) => {
    let result: any[];
    if (!plans.length) {
      result = [""]
    } else {
      result = plans.map(p => p[key]);
    }
    if (mapFunction) {
      result = result.map(mapFunction);
    }
    if (lastValue) {
      result.push(lastValue)
    }
    return result;
  }, [plans])

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === "undefined" ? true : expandedState[key];

  return <Grid container justify={"center"} spacing={0} alignItems={"center"} className={'mt-15'}>
      <Grid item xs={12} xl={11}>
        <AscendCard className={classes.card}>
          <table className={clsx(classes.table, classes.header)}>
            <tbody>
            <tr>
              <td>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} minWidth={"150px"}
                     minHeight={"175px"}>
                  <Typography color={"textPrimary"} className={classes.title}>Compare plans</Typography>
                  <div>
                    {/*<div className={classes.iconButton}>
                        <img src={'/img/printer.svg'}/>
                        <div>Print</div>
                      </div>*/}
                    <div className={"h-12"}/>
                    <div className={classes.iconButton}>
                      <img src={"/img/message.svg"}/>
                      <div>Email me this comparison</div>
                    </div>
                  </div>
                </Box>
              </td>
              {!!plans.length && plans.map(plan => <td key={plan.key}><CompareHeaderCard zip={props.zip} countyName={props.countyName} plan={plan}/></td>)}
              {!plans.length && <td></td>}
              <td>
                <Box p={"10px"}>
                  <Typography color={"textPrimary"} className={classes.title}>Original Medicare Part A/Part B</Typography>
                </Box>
              </td>
            </tr>
            </tbody>
          </table>

          <div className={"h-35"}/>

          <Element name={"Key Plan Benefits Overview"}>
            <BlockTitle title={"Key Plan Benefits Overview"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Carrier Overview"}
                icon={"/img/medical-insurance.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, carrierOverview: value}))}
                expanded={getExpandedValue("carrierOverview")}
              />
            </div>
            <Collapse in={getExpandedValue("carrierOverview")}>
              <table className={classes.table}>
                <tbody>
                <PlanDetailRow title={"Plan Type"}
                               tooltip={"There are 10 standardized Medigap plans. Each plan offers different levels for how much of your costs they’ll pay for, and as such they also have slightly different cost levels. They’re named by letter, like Plan A, and Plan B. Not all plans are offered in every state.\nIf you live in Massachusetts, Minnesota, or Wisconsin, your state offers different standardized plans."}
                               values={getValuesByKey("planName", "Original Medicare")}/>
                <PlanDetailRow title={"AM Best Company Credit Rating"}
                               tooltip={"An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company's financial strength."}
                               values={getValuesByKey("amBestRating", "Not applicable")}/>
                <PlanDetailRow title={"S&P Company Credit Rating"}
                               tooltip={"An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company's financial strength."}
                               values={getValuesByKey("spRating", "Not applicable")}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Plan Highlights"}
                icon={"/img/medical-record-1.svg"}
                onToggle={value => setExpandedState(prevState => ({...prevState, planHighlights: value}))}
                expanded={getExpandedValue("planHighlights")}
              />
            </div>
            <Collapse in={getExpandedValue("planHighlights")}>
              <table className={classes.table}>
                <tbody>
                <PlanDetailRow title={"Monthly Plan Premium"}
                               tooltip={"This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply."}
                               values={getValuesByKey("monthlyPremium", "Medicare Part B premium from $148.50 (You usually don’t pay a premium for Part A if you or your spouse paid Medicare taxes while working)")}/>
                <PlanDetailRow title={"Household Discount"}
                               tooltip={"A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier."}
                               values={getValuesByKey("householdDiscount", 'Not applicable', v => v || 'n.a.')}/>
                <PlanDetailRow title={"Annual Deductible"}
                               tooltip={'The amount you must pay out-of-pocket for Part A and Part B covered services before the plan\'s coverage starts.'}
                               values={getValuesByKey("deductibles", "Part A $1,484\nPart B $203", value => `Part A: ${value.partA}\nPart B: ${value.partB}`)}/>
                <PlanDetailRow title={"Doctors and Hospitals"}
                               tooltip={"With Original Medicare and a Medigap plan, you have the flexibility to use any doctor or hospital around the country that accepts Medicare. As opposed to Medicare Advantage plans that may require you to use a \"network\" of doctors and hospitals, there is no \"network\" for Medigap plans."}
                               values={getValuesByKey(
                                 "householdDiscount",
                                 'Any doctor that accepts Medicare',
                                 value => "Any Doctor or medical provider that accepts Medicare patients")}/>
                <PlanDetailRow title={"Maximum out-of-pocket cost"}
                               tooltip={"The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes costs for Part A and Part B services. Once you reach this limit, the health plan typically pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan."}
                               values={getValuesByKey(
                                 "outOfPocketLimits",
                                 'Not applicable')}/>

                <MultilinePlanDetailRow title={"Part B Excess Charges"}
                                        tooltip={"An amount a provider can charge over the Medicare-approved amount. Providers can charge up to 15% over the standard Medicare rates."}
                                        values={getValuesByKey("partBExcessCharges", "Not applicable", value => <div>
                                            <Benefit value={value} label={''}/>
                                        </div>)}/>
                <MultilinePlanDetailRow title={"Foreign Travel Emergency"}
                                        tooltip={"Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn't otherwise cover the care."}
                                        values={getValuesByKey("foreignTravelEmergency", "Not applicable", value => <Benefit value={value} label={''}/>)}/>
                <MultilinePlanDetailRow title={"Prescription Drug Coverage"}
                                        tooltip={"Coverage for prescription drugs bundled with hospital and medical coverage."}
                                        values={getValuesByKey("foreignTravelEmergency", "Not covered", value => <div>
                                          <Benefit value={false} label={''}/>
                                          <Typography className={'fs-12 mt-5'} color={'textPrimary'}>Purchase a standalone<br />Prescription Drug Plan</Typography>
                                        </div>)}/>
                <MultilinePlanDetailRow title={<div>
                  <Typography className={'fs-12 bold'} color={"textPrimary"}>Additional Benefits, such as:</Typography>
                  <ul className={classes.list}>
                    <li>
                      <AscendTooltip placement={'top'} arrow title={'Coverage for dental benefits provided at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment.'}>
                        <span>Dental coverage</span>
                      </AscendTooltip>
                    </li>
                    <li>
                      <AscendTooltip placement={'top'} arrow title={'Coverage for vision benefits provided at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}>
                        <span>Vision coverage</span>
                      </AscendTooltip>
                    </li>
                    <li>
                      <AscendTooltip placement={'top'} arrow title={'Coverage for hearing benefits provided at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}>
                        <span>Hearing coverage</span>
                      </AscendTooltip>
                    </li>
                  </ul>
                </div>}
                                        tooltip={""}
                                        values={getValuesByKey("foreignTravelEmergency", "Not covered", value => <div>
                                          <Benefit value={false} label={''}/>
                                          <Typography className={'fs-12 mt-5'} color={'textPrimary'}>Purchase standalone dental,<br />vision and hearing plans</Typography>
                                        </div>)}/>
                </tbody>
              </table>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-35"}/>

          <Element name={"Coverage Details"}>
            <BlockTitle title={"Coverage Details"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Your costs for  Hospital Coverage (Part A)"}
                onToggle={value => setExpandedState(prevState => ({...prevState, hospitalCoverage: value}))}
                expanded={getExpandedValue("hospitalCoverage")}
                icon={"/img/hospital-2.svg"}
              />
            </div>
            <Collapse in={getExpandedValue("hospitalCoverage")}>
              <table className={classes.table}>
                <tbody>
                <PlanDetailRow title={"Part A Deductible"}
                               tooltip={"The amount you must pay out of pocket for your Part A (Hospital) costs before your coverage begins to pay."}
                               values={getValuesByKey("partADeductible", 'Part A $1,484')}/>
                <PlanDetailRow title={"Inpatient Hospital Coverage"}
                                        tooltip={'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'}
                                        values={getValuesByKey("inpatientHospitalCoverage",
                                          <ul>
                                            <li><span>$1,484 deductible for each benefit period</span></li>
                                            <li><span>Days 1-60: $0 coinsurance for each benefit period</span></li>
                                            <li><span>Days 61-90: $371 coinsurance per day of each benefit period</span></li>
                                            <li><span>Days 91 and beyond: $742 coinsurance per each "lifetime reserve day" after day 90 for each benefit period (up to 60 days over your lifetime)</span></li>
                                            <li><span>Beyond lifetime reserve days: all costs</span></li></ul> )}/>
                <PlanDetailRow title={"Skilled Nursing Facilities"}
                                        tooltip={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}
                                        values={getValuesByKey("skilledNursingFacilities",
                                          <ul>
                                            <li><span>Days 1–20: $0 for each benefit period.</span></li>
                                            <li><span>Days 21–100: $185.50 coinsurance per day of each benefit period.</span></li>
                                            <li><span>Days 101 and beyond: All costs.</span></li>
                                          </ul>)}/>
                <PlanDetailRow title={"Blood Transfusion (during a hospital stay)"}
                                        tooltip={'Blood received as part of a procedure during inpatient hospital treatment. Original Medicare does not cover blood transfusions, unless the blood is donated.'}
                                        values={getValuesByKey("bloodTransfusionDuring", "You may have to pay for blood you receive.")}/>
                <PlanDetailRow title={"Hospice Care"}
                                        tooltip={'Hospice care is comprehensive care for people who are terminally ill. Hospice care includes pain management, counseling, respite care, prescription drugs, inpatient care and outpatient care, and services to support the terminally ill person\'s family.'}
                                        values={getValuesByKey("hospiceCareCopay", "Copay / coinsurance on limited services including outpatient drugs and inpatient respite care", value => typeof value === 'boolean' ? <Benefit value={value} label={''}/> : value)}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Your costs for  Medical Coverage (Part B)"}
                onToggle={value => setExpandedState(prevState => ({...prevState, medicalCoverage: value}))}
                expanded={getExpandedValue("medicalCoverage")}
                icon={"/img/man.svg"}
              />
            </div>
            <Collapse in={getExpandedValue("medicalCoverage")}>
              <table className={classes.table}>
                <tbody>
                <PlanDetailRow title={"Part B Deductible"}
                               tooltip={"The amount you must pay out of pocket for your Part B (Medical) costs before your coverage begins to pay."}
                               values={getValuesByKey("partBDeductible", '$203')}/>
                <PlanDetailRow title={<div>
                  <Typography className={'fs-12 bold'} color={"textPrimary"}>Covered Part B services, including:</Typography>
                  <ul className={classes.list}>
                    <li>Primary care doctor office visits</li>
                    <li>Specialist doctor office visits</li>
                    <li>Emergency room</li>
                    <li>Ambulance services</li>
                    <li>Outpatient surgery</li>
                    <li>Outpatient rehabilitation services</li>
                  </ul>
                </div>}
                               tooltip={'Medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}
                               values={getValuesByKey("coveredPartBServices", '20% of the cost after the Part B deductible')}/>
                <PlanDetailRow title={"Preventive Care"}
                               tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'}
                               values={getValuesByKey("preventiveCare", "$0")}/>
                <PlanDetailRow title={"Blood Transfusion (outside a hospital stay)"}
                               tooltip={'Blood received as part of a procedure in an outpatient hospital setting. Original Medicare does not cover blood transfusions, unless the blood is donated.'}
                               values={getValuesByKey("bloodTransfusionOutside", "You may have to pay for blood you receive.")}/>
                <PlanDetailRow title={"Tests for Diagnostic Services"}
                               tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'}
                               values={getValuesByKey("testsForDiagnosticServices", "20% of the cost after the Part B deductible for some services")}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionMiddle}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Your costs for services covered under Parts A and B"}
                onToggle={value => setExpandedState(prevState => ({...prevState, servicesCovered: value}))}
                expanded={getExpandedValue("servicesCovered")}
                icon={"/img/healthcare.svg"}
              />
            </div>
            <Collapse in={getExpandedValue("servicesCovered")}>
              <table className={classes.table}>
                <tbody>
                <PlanDetailRow title={"Home Health Care"}
                               tooltip={'Health care services and supplies that a doctor orders to be received in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'}
                               values={getValuesByKey("homeHealthCare", "$0")}/>
                <PlanDetailRow title={"Durable Medical Equipment"}
                               tooltip={'Certain medical equipment, like a wheelchair, walker or special hospital bed, that is ordered by your doctor for use in the home.'}
                               values={getValuesByKey("durableMedicalEquipment", "20% after the Part B deductible")}/>
                </tbody>
              </table>
            </Collapse>

            <div className={classes.sectionBottom}/>
          </Element>

          <div className={"h-32"}/>

          <Element name={"Plan Brochures"}>
            <BlockTitle title={"Plan Brochures"}/>
            <div className={classes.sectionTop}>
              <SectionTitle
                tableClassName={classes.table}
                title={"Plan and Medicare Brochures"}
                onToggle={value => setExpandedState(prevState => ({...prevState, brochures: value}))}
                expanded={getExpandedValue("brochures")}
                icon={"/img/brochures.svg"}
              />
            </div>

            <Collapse in={getExpandedValue("brochures")}>
              <table className={classes.table}>
                <tbody>
                {/*<MultilinePlanDetailRow title={"Plan Brochures"}
                                        values={getValuesByKey("hospiceCareCopay", "Not applicable",
                                          value => null/*planFiles(value).map(item => <a className={planClasses.link}
                                                                                   target={"_blank"}
                                                                                   href={item.url}>{item.label}</a>)*)
                                        }
                />*/}
                <PlanDetailRow title={"Medicare Brochures"}
                               values={getValuesByKey("hospiceCareCopay", "Not applicable",
                                 v => <a className={planClasses.link}
                                         target={"_blank"}
                                         href={'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'}>Medicare & You
                                   Handbook (2022)</a>)
                               }
                />
                </tbody>
              </table>
            </Collapse>
            <div className={classes.sectionBottom}/>
          </Element>
        </AscendCard>
      </Grid>
    </Grid>
}

type TitleRowProps = {
  title: string,
  subtitle?: string,
  icon?: string,
  classes: any,
  expanded: boolean,
  onToggle: (value: boolean) => void,
  tableClassName?: string,
  wrapperClassName?: string,
}

const BlockTitle = withStyles({
  wrapper: {
    paddingTop: 12,
    backgroundColor: '#f5f7f6',
    zIndex: 1,
    position: 'sticky',
    top: 253,
    marginBottom: '16px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 0',
    borderRadius: 10,
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
  }
})(({title, classes}: { title: string, classes: any }) => <Box className={classes.wrapper}>
  <Box className={classes.title}>
    <Typography variant={"h1"} color={"textPrimary"}><strong>{title}</strong></Typography>
  </Box>
</Box>)

const SectionTitle = withStyles({
  cell: {
    background: "white!important",
    padding: "0!important"
  },
  subtitle: {
    borderRadius: 0,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 21,
    width: '100%',
  },
  row: {
    border: 'none!important',
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={tableClassName}>
    <tbody>
    <tr className={classes.row}>
      <td colSpan={5} className={classes.cell}>
        <AscendSubtitle wrapperClassName={classes.subtitle} title={props.title} icon={props.icon}
                        expanded={props.expanded} onToggleClick={props.onToggle}/>
      </td>
    </tr>
    </tbody>
  </table>
))

const SectionTitleH2 = withStyles({
  cell: {
    padding: "0 11px!important"
  },
  table: {
    background: "white!important",
    borderSpacing: 0,
  },
  wrapper: {
    background: "rgba(232,236,237,0.5)",
    borderRadius: "4px"
  },
  medium: {
    fontWeight: 500,
  },
  icon: {
    transform: "rotate(-180deg)",
    transition: "all 0.3s"
  },
  iconOpen: {
    transform: "rotate(0) !important",
  },
  button: {
    background: "rgba(2, 144, 148, 0.5)",
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: 10,
    marginTop: 5,
    "&:hover": {
      background: "rgba(2, 144, 148, 0.5)",
    }
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={clsx(classes.table, tableClassName)}>
    <tbody>
    <tr>
      <td colSpan={5} className={classes.cell}>
        <Box p={"16px 10px 14px 20px"}
             className={clsx(classes.wrapper, props.wrapperClassName)}
             display={"flex"}
             flexDirection={"row"}
             alignContent={"center"}
             justifyContent={"space-between"}
             width={1}>

          <Box display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"start"}>
            <Typography variant={"h3"} color={"textPrimary"} className={classes.medium}>{props.title}</Typography>
            {props.subtitle &&
            <Typography variant={"h5"} color={"textPrimary"} className={classes.medium}>{props.subtitle}</Typography>}
          </Box>
          <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
            <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={"/img/arrow-white.svg"}/>
          </IconButton>
        </Box>
      </td>
    </tr>
    </tbody>
  </table>
))

const SectionTitleH3 = withStyles({
  cell: {
    padding: "0 11px!important"
  },
  table: {
    background: "white!important",
    borderSpacing: 0,
  },
  wrapper: {
    background: "white!important",
    borderRadius: "4px"
  },
  medium: {
    fontWeight: 500,
  },
  icon: {
    transform: "rotate(0)",
    transition: "all 0.3s"
  },
  iconOpen: {
    transform: "rotate(-180deg) !important",
  },
  button: {
    background: "#E8ECED",
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: 10,
    marginTop: 5,
    "&:hover": {
      background: "#E8ECED",
    }
  }
})(({classes, tableClassName, ...props}: TitleRowProps) => (
  <table className={clsx(classes.table, tableClassName)}>
    <tbody>
    <tr>
      <td colSpan={5} className={classes.cell}>
        <Box p={"16px 40px 14px 20px"}
             className={clsx(classes.wrapper, props.wrapperClassName)}
             display={"flex"}
             flexDirection={"row"}
             alignContent={"center"}
             justifyContent={"space-between"}
             width={1}>

          <Box display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"start"}>
            <Typography variant={"h3"} color={"textPrimary"} className={classes.medium}>{props.title}</Typography>
          </Box>
          <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
            <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={"/img/arrow.svg"}/>
          </IconButton>
        </Box>
      </td>
    </tr>
    </tbody>
  </table>
))

const PlanDetailRow = (props: { title: string | JSX.Element, values: any[], className?: string, tooltip?: string, thin?: boolean }) =>
  <tr>
    <td>
      {!props.tooltip && <>
        {typeof props.title === "string" && <Typography variant={"h5"} color={"textPrimary"}
                                                        className={`${props.thin ? 'thin' : 'bold'} ${props.className}`}>{props.title}</Typography>}
        {typeof props.title !== "string" && props.title}
      </>}
      {props.tooltip && <TooltipedTitle title={props.title} className={`${props.thin ? 'thin' : 'bold'} ${props.className}`} tooltip={props.tooltip}/>}
    </td>
    {props.values.map((value, index) => <td key={index}>
      {typeof value === "string" &&
      <Typography color={"textPrimary"} className={`${props.className} fs-12 pre`}>{value}</Typography>}
      {typeof value !== "string" && value}
    </td>)}
  </tr>

const MultilinePlanDetailRow = (props: { title: string | JSX.Element, values: any[], tooltip?: string }) =>
  <PlanDetailRow title={props.title} tooltip={props.tooltip}
                 values={props.values.map(val => Array.isArray(val) ? <ListPlanDetailText content={val}/> : val)}/>

