import React from "react";
import {ExtraBenefit, FormName, PlansFilterInput, PlanYear, Rating, SnpType} from "../../../types";
import {handleCollectionChange, handleRatingChange} from "./hooks/filterUtils";
import {QuoteContext} from "./QuoteContext";
import {setQuoteId} from "../../../shared/QuoteId";
import {useParams} from "react-router";
import {PlanFilterDataProvider} from "./PlanFilterDataContext";
import * as _ from "lodash";
import useUrlParams from "../../../shared/hooks/useUrlParams";
import {useDebouncedEffect} from "../../../shared/useDebouncedEffect";
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";
import useFiltersUpdating from "./hooks/useFiltersUpdating";
import {convertPreferredDrugInput} from "./EditPreferences";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";
import * as QueryString from "query-string";

export interface IPlansFilter extends PlansFilterInput {
  companies: string[];
  isMA?: boolean;
  isMD?: boolean;
  isMS?: boolean;
  partBPremiumReduction?: boolean;
  planTypes: string[];
  SNPTypes: SnpType[];
  extraBenefits: ExtraBenefit[];
  rating: Rating[];
  zip: string;
  countyName: string;
}

interface PlanFilterContextState {
  values?: IPlansFilter,
  switchZip: (zip: string, countyName: string) => void,
  toggleFavorites: () => void,
  switchCompany: (name: string) => void,
  switchPlanType: (name: string) => void,
  switchSNPType: (type: SnpType) => void,
  switchRating: (rating: Rating) => void,
  switchExtraBenefits: (type: ExtraBenefit) => void,
  switchMaxOutOfPocketRanges: (name: string) => void,
  switchMaxCostRanges: (name: string) => void,
  togglePartB: () => void,
  refresh: () => void,
  reset: () => void,
}

const defaultState: PlanFilterContextState = {
  values: {
    zip: "NOT_DEFINED",
    countyName: "NOT_DEFINED",
    isMA: true,
    partBPremiumReduction: false,
    onlyFavorite: false,
    companies: [],
    planTypes: [],
    rating: [],
    SNPTypes: [],
    extraBenefits: [],
    maxCostRangeNames: [],
    maxOutOfPocketRangeNames: []
  },
  switchZip: (zip: string) => {},
  toggleFavorites: () => {},
  togglePartB: () => {},
  switchCompany: (name: string) => {},
  switchPlanType: (name: string) => {},
  switchSNPType: (type: SnpType) => {},
  switchRating: (rating: Rating) => {},
  switchExtraBenefits: (type: ExtraBenefit) => {},
  switchMaxOutOfPocketRanges: (name: string) => {},
  switchMaxCostRanges: (name: string) => {},
  refresh: () => {},
  reset: () => {},
};

export const PlanFilterContext = React.createContext<PlanFilterContextState>(defaultState);

export function PlanFilterProvider(props: React.PropsWithChildren<PlanFilterProviderProps>) {

  const quote = React.useContext(QuoteContext);
  const params = useUrlParams(['resetFilters', 'zip', 'county', 'planType', 'maxOutOfPocketRangeNames', 'adminQuote']);
  const [filters, setFilters] = React.useState<IPlansFilter>();
  const [prevQuoteId, setPrevQuoteId] = React.useState();
  const {setFiltersUpdating} = useFiltersUpdating();
  const [skipSave, setSkipSave] = React.useState(false);
  const [adminQuote, setAdminQuote] = React.useState<any>({});

  const [getAdminQuote, adminQuoteData] = useQuestionnaireRequest(data => {
    if (data) {
      setFilters({
        zip: data.personalDetails?.zip,
        countyName: data.personalDetails?.countyName,
        ...data.filters
      })
      setAdminQuote({
        preferredDoctors: data.preferredDoctors?.preferredDoctors.map((d: any) => ({
          npi: d.npi,
          addresses: d.addresses
        })),
        preferredDrugs: data.preferredDrugs?.preferredDrugs.map(convertPreferredDrugInput),
        preferredPharmacies: data.preferredPharmacies?.preferredPharmacies?.map((p: any) => p.npi),
        drugDeliveryType: data.drugDeliveryType,
        preferredPharmaciesFilled: data.preferredPharmacies?.preferredPharmaciesFilled,
        doctorVisitsNumber: data.doctorVisitsNumber,
        specialistVisitsNumber: data.specialistVisitsNumber,
        favorites: data.favorites,
      })
    }
  });

  React.useEffect(() => {
    if (filters) {
      localStorage.setItem('filters', JSON.stringify(filters))
    }
    setFiltersUpdating(true);
  }, [filters])

  React.useEffect(() => {
      if (params.resetFilters) {
        localStorage.removeItem('filters');
      } else {
        if (params.zip && params.county) {
          const storedFilters = localStorage.getItem('filters');
          let data: any = props.initFilter;
          if (storedFilters) {
            data = JSON.parse(storedFilters);
          }
          data.zip = params.zip;
          data.countyName = params.county;
          if (params.planType) {
            data.planTypes = [params.planType]
          }
          if (params.maxOutOfPocketRangeNames) {
            data.maxOutOfPocketRangeNames = [params.maxOutOfPocketRangeNames]
          }
          localStorage.setItem('filters', JSON.stringify(data));
        }
      }
      if (params.adminQuote) {
        getAdminQuote({variables: {id: params.adminQuote as string}})
      }
  }, [params]);

  React.useEffect(() => {
    if (quote.pin && prevQuoteId !== quote.id) {
      let result: IPlansFilter = {...props.initFilter, ...quote.filters};
      if (!_.isEqual(filters, result)) {
        setSkipSave(true);
        setFilters(result)
      }
      setPrevQuoteId(quote.id);
    } else if (!quote.id) {
      setFilters(() => {
        const storedFilters = localStorage.getItem('filters');
        if (storedFilters) {
          const parsed = JSON.parse(storedFilters);
          if (parsed.zip !== props.initFilter.zip) {
            parsed.zip = props.initFilter.zip
          }
          if (parsed.countyName !== props.initFilter.countyName) {
            parsed.countyName = props.initFilter.countyName
          }
          return parsed;
        } else {
          return props.initFilter
        }
      })
    }
  }, [quote])

  function switchFilter<T>(key: T, filterKey: keyof IPlansFilter) {
    if ((window as any).track) {
      (window as any).track('MA marketplace - click on filter', {source: 'V1', key: filterKey, value: key});
    }
    setFilters(prev => {
      if (prev) {
        const newInstance = {...prev}
        newInstance[filterKey] = handleCollectionChange<T>(key, prev[filterKey])
        return newInstance
      }
      return prev;
    })
  }

  useDebouncedEffect(() => {
    if (filters && !isInitFilters(filters, props.initFilter) && !skipSave) {
      quote.save({variables: {
          data: {
            form: FormName.Drugs,
            id: quote?.id,
            county: filters.countyName,
            zip: filters.zip,
            planYear: GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023,
            filters: {
              extraBenefits: filters?.extraBenefits,
              SNPTypes: filters?.SNPTypes,
              companies: filters?.companies,
              maxCostRangeNames: filters?.maxCostRangeNames,
              maxOutOfPocketRangeNames: filters?.maxOutOfPocketRangeNames,
              partBPremiumReduction: filters?.partBPremiumReduction,
              planTypes: filters?.planTypes,
              rating: filters?.rating,
            },
            ...adminQuote
          }
        }}).then((data: any) => {
          if (!quote?.id) {
            setQuoteId(data.data.saveMedicareQuote.maQuoteID)
          }
          setFiltersUpdating(false);
        })
    } else {
      setFiltersUpdating(false);
    }
    setSkipSave(false);
  }, 2000, [filters])

  function switchRating(rating: Rating) {
    if ((window as any).track) {
      (window as any).track('MA marketplace - click on filter', {source: 'V1', key: 'rating', value: rating});
    }
    setFilters(prev => {
      if (prev && filters) {
        return {...prev, rating: handleRatingChange(rating, filters.rating)}
      }
      return prev;
    })
  }

  function refresh() {
    setFilters(prev => {
      return prev ? {...prev} : prev;
    })
  }

  const contextValue = React.useMemo<PlanFilterContextState>(() => (
      {
        values: filters,
        reset: () => {
          setFilters(props.initFilter)
        },
        switchZip: (zip: string, countyName: string) => {
          setFilters({...props.initFilter, zip, countyName})
        },
        toggleFavorites: () => {
          setFilters(prev => {
            if (prev) {
              return {...prev, onlyFavorite: !prev.onlyFavorite}
            }
            return prev;
          })
        },
        togglePartB: () => {
          setFilters(prev => {
            if (prev) {
              return {...prev, partBPremiumReduction: !prev.partBPremiumReduction}
            }
            return prev;
          })
        },
        switchCompany: (key: string) => {
          switchFilter<string>(key,'companies')
        },
        switchPlanType: (key: string) => {
          switchFilter<string>(key,'planTypes')
        },
        switchRating,
        switchSNPType: (key: SnpType) => {
          switchFilter<SnpType>(key,'SNPTypes')
        },
        switchExtraBenefits: (key: ExtraBenefit) => {
          switchFilter<ExtraBenefit>(key,'extraBenefits')
        },
        switchMaxOutOfPocketRanges: (key: string) => {
          switchFilter<string>(key,'maxOutOfPocketRangeNames')
        },
        switchMaxCostRanges: (key: string) => {
          switchFilter<string>(key,'maxCostRangeNames')
        },
        refresh
      }
    )
  , [filters]);

  return <PlanFilterContext.Provider value={contextValue}>
    {props.children}
  </PlanFilterContext.Provider>

}

function isInitFilters(filters: any, initFilters: any) {
  const isEqual = (keys: string[]) => {
    return keys.map(k => _.isEqual(filters[k], initFilters[k])).every(Boolean);
  }
  return isEqual(['extraBenefits', 'SNPTypes', 'companies', 'maxCostRangeNames', 'maxOutOfPocketRangeNames', 'partBPremiumReduction', 'planTypes', 'rating', 'zip', 'countyName'])
}

export const withPlanFilterProvider = (WrappedComponent: any) => (props: any) => {
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const params = QueryString.parse(document.location.search);
  const planTypes: any[] = [];
  const SNPTypes: any[] = [];

  if (params.planType) {
    planTypes.push(params.planType)
  }

  if (params.SNPType) {
    SNPTypes.push(params.SNPType)
  }

  return (
    <PlanFilterDataProvider>
      <PlanFilterProvider initFilter={{
        zip,
        countyName,
        maxOutOfPocketRangeNames: ['$0 to $2,000', '$2,001 to $4,000', '$4,001 to $6,000', '$6,001+'],
        maxCostRangeNames: ["$0 to $50", "$51 to $150", "$151 to $250", "$251+"],
        companies: [],
        planTypes,
        rating: [],
        SNPTypes,
        extraBenefits: [ExtraBenefit.DrugCoverage],
      }}>
        <WrappedComponent {...{...props}} />
      </PlanFilterProvider>
    </PlanFilterDataProvider>
  )
}

interface PlanFilterProviderProps {
  initFilter: IPlansFilter
}
/***
 switchMaxOutOfPocketRanges: (name: string) => {},
 switchMaxCostRanges: (name: string) => {},


 maxCostRangeNames: [],
 maxOutOfPocketRangeNames: []
 */
