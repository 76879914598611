import React from "react";
import {Box, Typography, withStyles} from "@material-ui/core";
import {colors} from "../AppTheme";
import AscendStepper from "./AscendStepper";

function Terms({classes}: {classes: any}) {
  return <Box display={'flex'} flex={1} flexDirection={'column'} mt={4} justifyContent={'flex-end'} alignItems={'flex-start'}>
    <Box display={'flex'}>
      <Typography className={classes.link} variant={'body1'} color={'textPrimary'}
                  onClick={() => window.open('https://coverright.com/coverright-terms-of-use/')}>Terms of Use</Typography>
      <Box mx={1}>|</Box>
      <Typography className={classes.link} variant={'body1'} color={'textPrimary'}
                  onClick={() => window.open('https://coverright.com/privacy/')}>Privacy Policy</Typography>
    </Box>
    <Box display={'flex'}>
      <img src={'/img/security_shield_black.svg'} />
      <Box ml={'12px'}>
        <Typography variant={'body1'} color={'textSecondary'}>HIPAA-compliant data storage<br />256-bit encryption</Typography>
      </Box>
    </Box>
  </Box>
}

export default withStyles({
  link: {
    fontSize: 12,
    color: colors.text.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: 16,
    fontWeight: 500,
  },
})(Terms)
