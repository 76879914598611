import React from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  RadioGroup,
  Theme,
  Typography, useMediaQuery,
  useTheme
} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import paths from "../../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import useCommonStyles from "../../../shared/useCommonStyles";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx'
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import BackButton from "../../../Maketplace/MedicareAdvantage/shared/BackButton";
import {colors} from "../../../shared/AppTheme";
import useVideoCategoriesData from "../hooks/useVideoCategories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      transform: "rotate(-180deg)",
      transition: "all 0.3s"
    },
    iconOpen: {
      transform: "rotate(0) !important",
    },
    button: {
      background: colors.text.primary,
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      "&:hover": {
        background: colors.text.primary,
      }
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 4,
      padding: '7px 12px 7px 17px',
      color: colors.text.primary,
      backgroundColor: '#C8E8EB'
    },
    activeHeader: {
      color: 'white',
      backgroundColor: colors.custom.green.variant2
    },
    description: {
      backgroundColor: 'white',
      padding: '12px 20px',
      color: colors.text.primary,
      fontSize: 14,
    },
    player: {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
      overflow: 'hidden',
      '& iframe': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }
    }
  }),
);

const formName = 'QUIZ_1';

function Quiz1(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [, dispatch] = React.useContext(QuoteContext);
  const [url, setUrl] = React.useState();
  const [active, setActive] = React.useState('1');
  const {state, setState} = useVideoCategoriesData();
  
  const [player, setPlayer] = React.useState();
  const [playerReady, setPlayerReady] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  const activeCategoryIndex = React.useMemo(() => {
    if (state.categories) {
      if (state.active) {
        return state.categories.findIndex(c => c.id === state.active);
      } else {
        return 0;
      }
    }
    return 0;
  }, [state.categories, state.active]);

  const isFirst = React.useMemo(() => {
      return activeCategoryIndex === 0;
  }, [activeCategoryIndex]);

  const isLast = React.useMemo(() => {
    if (state.categories) {
      return activeCategoryIndex >= state.categories.length - 1;
    }
    return true;
  }, [activeCategoryIndex, state.categories]);

  const activeCategory = React.useMemo(() => {
    if (state.categories) {
      return state.categories[activeCategoryIndex];
    }
    return undefined;
  }, [activeCategoryIndex, state.categories]);

  const videos = React.useMemo(() => {
    return activeCategory?.videos ? activeCategory.videos : [];
  }, [activeCategory]);

  React.useEffect(() => {
    if (activeCategory) {
      const video = activeCategory.videos[0];
      if (video) {
        setActive(video.id);
        setUrl(video.url)
      }
    }
  }, [activeCategory]);

  React.useEffect(() => {
      if (player?.loadVideoByUrl && playerReady && url) {
        player.loadVideoByUrl(url);
      }
  }, [url, player, playerReady]);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    if (!(window as any).YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = '//www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      (window as any).onYouTubeIframeAPIReady = initPlayer;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      (firstScriptTag as any).parentNode.insertBefore(tag, firstScriptTag);

    } else { // If script is already there, load the video directly
      initPlayer();
    }
  }, [])

  const initPlayer = () => {
    const p = new (window as any).YT.Player('player', {
      height: '375',
      width: '100%',
      playerVars: {
        'playsinline': 1
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
    setPlayer(p);
  }

  const onPlayerReady = (event: any) => {
    setPlayerReady(true);
  }

  const onPlayerStateChange = (event: any) => {
    setPlaying(event.data === 1);
  }

  const onPlayClick = (link: string) => {
    if (link !== url) {
      if ((window as any).track) {
        (window as any).track('Play Video', {video: link, source: 'V1'}, true);
      }
      setUrl(link);
    } else {
      if (playing) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  }

  const goToPrev = React.useCallback(() => {
      if (isFirst) {
        props.history.push(paths.home)
      } else {
        setState({active: state.categories[activeCategoryIndex - 1].id})
      }
  }, [isFirst, activeCategoryIndex, state.categories]);

  const goToNext = React.useCallback(() => {
      if (isLast) {
        if ((window as any).track) {
          (window as any).track('Video course completion', {source: 'V1'});
        }
        skip()
      } else {
        setState({active: state.categories[activeCategoryIndex + 1].id})
        if ((window as any).track) {
          (window as any).track('Next Topic Video', {source: 'V1', section: state.categories[activeCategoryIndex + 1].title}, true);
        }
      }
  }, [isLast, activeCategoryIndex, state.categories]);

  const skip = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName}
    })
    props.history.push(paths.MAquestionnaire + '/' + questionsRoutes.quiz1a)
  }

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800} position={'relative'}>
    <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
      <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={goToPrev}>
        Back
      </BackButton>
    </Box>
    <div className={'h-10'} />
    <div className={classes.player}>
      <div id="player"></div>
    </div>
    <div className={'h-32'} />

    {videos.map((video, i) => <Video key={i} {...video} active={active} playing={playing} currentLink={url} onClick={setActive} onPlayClick={onPlayClick} />)}

    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} mt={'40px'}>
      <AscendButton className={'gtm-step-event'} id={formName} variant={'contained'} onClick={goToNext}>Continue!</AscendButton>
      <Typography variant={'h4'} className={'green pointer underline medium mt-15'} onClick={skip}>Skip to quiz</Typography>
    </Box>
  </Box>;
}

export default withTitle(
  Quiz1,
  () => 'Learn more about Medicare',
  '',
  undefined,
  undefined,
  true,
  657
);

interface VideoProps {
  currentLink?: string,
  active?: string,
  title: string,
  duration: string,
  url: string,
  description: string,
  id?: string,
  onClick: (id: string) => void,
  onPlayClick: (link: string) => void,
  playing: boolean,
}

const Video = (props: VideoProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));

  return <Box className={'mb-16'} boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}>
    <Box onClick={() => props.onClick(props.id as string)} className={clsx({[classes.header]: true, [classes.activeHeader]: props.active === props.id})}>
      <IconButton onClick={() => setOpen(!open)} className={classes.button}>
        <img className={clsx(classes.icon, open && classes.iconOpen)} src={"/img/arrow-white.svg"}/>
      </IconButton>
      <Box display={'flex'} flex={1} className={'pointer'} justifyContent={phone ? 'flex-start' : 'center'} onClick={() => setOpen(!open)}>
        <Typography className={'medium fs-18 ph-16'} align={phone ? 'left' : 'center'}>{props.title}</Typography>
      </Box>
      <Box className={'flex flex-align-center'}>
        <Typography className={'medium fs-14 mr-16'}>{props.duration}</Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} className={'w-20'}>
          <img className={'pointer'} onClick={() => props.onPlayClick(props.url)} src={(props.currentLink === props.url && props.playing) ? "/img/pause.svg" : "/img/play.svg"}/>
        </Box>
      </Box>
    </Box>
    <Collapse in={open}>
      <div className={classes.description} dangerouslySetInnerHTML={{__html: props.description}} />
    </Collapse>
  </Box>
}

interface IVideo {
  title: string,
  url: string,
  description: string,
  duration: string,
  id: string,
}
