import React from 'react';
import {Box, Grid, Divider, Typography, makeStyles, Theme, createStyles, withStyles} from "@material-ui/core";
import * as Scroll from 'react-scroll';
import {colors} from "../../../shared/AppTheme";
import Terms from "../../../shared/SidePanel/Terms";
import {useHistory, useParams} from "react-router";
import {MedigapPlanOutput} from "../../../medigap-types";

const scrollSpy = Scroll.scrollSpy;
const Link = Scroll.Link;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentHeader: {
      fontSize: 16,
      fontWeight: 700,
      padding: '15px 20px',
      marginLeft: '-20px',
      marginRight: '-20px',
      width: '270px',
      color: colors.text.primary,
    },
    content: {
      marginLeft: '-20px',
      marginRight: '-20px',
      width: '270px',
    }
  }),
);

export default function PlanDetailsContent({plan}: {plan?: MedigapPlanOutput}) {
  const params = useParams<{zip: string, countyName: string}>();
  const history = useHistory();

  const classes = useStyles();
  React.useEffect(() => {
    scrollSpy.update();

  }, []);

  const titles = React.useMemo(() => {
    return [
      "Key Plan Benefits Overview",
      "Coverage Details",
      "Plan Brochures",
    ]
  }, [plan]);

  return <>
    <Box className={classes.contentHeader}>
      Table Of Contents
    </Box>
    <Box className={classes.content}>
      {titles.map((title, idx) => <PlanDetailTitle key={idx} title={title} index={idx + 1} />)}
    </Box>
    <div className={'h-200'}/>
    <Terms />
    {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
  </>
}

const PlanDetailTitle = withStyles({
  title: {
    fontSize: '16px',
    margin: 0,
    padding: '10px 0 10px 25px',
    fontWeight: 700,
    cursor: 'pointer',
    color: colors.text.secondary,
  }
})(({title, index, classes}: { title: string, index: number, classes: any}) => {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>

      <div className={classes.title}>
        {index}. {title}
      </div>
    </Link>
  </>)
})

function PlanDetailSubTitle({title}: { title: string}) {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>

      <div style={{fontSize: '16px', margin: '15px 20px', fontWeight: 400, cursor: 'pointer'}} >
        {title}
      </div>
    </Link>
  </>)
}
