import React from "react";


let listeners: any[] = [];

let filtersUpdating = false;

const setFiltersUpdating = (value: boolean) => {
  filtersUpdating = value;
  listeners.forEach((listener) => {
    listener(value);
  });
};

const useFiltersUpdating = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {filtersUpdating, setFiltersUpdating};
};

export default useFiltersUpdating;
