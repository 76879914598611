import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import Terms from "../../../shared/SidePanel/Terms";
import AscendStepper, {IStep} from "../../../shared/SidePanel/AscendStepper";


let steps: IStep[] = [
  {
    label: "Select category",
    firstStep: '',
    completed: true,
    questions: [],
  },
  {
    label: "Confirm plan",
    firstStep: '',
    questions: [],
  },
  {
    label: "Submit application",
    firstStep: '',
    questions: [],
  },
];

export default function CategoryStepper(props: RouteComponentProps<{routeName: string}>) {

  return (
    <>
      <Box marginTop={'24px'} maxWidth={245}>
        <Typography color={'textPrimary'} className={'fs-16 lh-20'}>Use our simple guide to select a category that works best for you.</Typography>
      </Box>
      {/*<AscendStepper {...props} state={{steps, questions: []}} />*/}
      <Terms />
      {/*<img src={'/img/ab-seal-horizontal-large.jpg'} className={'mt-10 w-120'} />*/}
    </>
  )
}
