const paths = {
  home: '/',
  dashboard: '/dashboard',
  personalDetails: '/personal-details',
  myCoverage: '/my-coverage',
  myHealthcare: '/my-healthcare',
  myBenefits: '/my-benefits',
  myPharmacies: '/my-pharmacies',
  accountSettings: '/account-settings',
  drugCostBreakdown: '/drug-cost-breakdown',
};

export default paths;
