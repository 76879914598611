const paths = {
  home: '/',
  enroll: '/enroll',
  personalDetails: '/personal-details',
  personalDetails2: '/personal-details-2',
  personalDetails3: '/personal-details-3',
  personalDetails4: '/personal-details-4',
  personalDetails5: '/personal-details-5',
  receive: '/receive',
  soa: '/soa/',
  createAccount: '/create-account',
};

export default paths;
