import React from "react";
import {Backdrop, Box, createStyles, Modal, ModalProps, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: (props: AscendModalProps) => props.modalScroll ? 'flex-start' : 'center',
      justifyContent: 'center',
      overflowY: (props: AscendModalProps) => props.modalScroll ? 'auto' : undefined,
      paddingLeft: (props: AscendModalProps) => props.disableSidePadding ? 0 : 320,
      [theme.breakpoints.down('md')]: {
        padding: '40px 10px!important',
      },
    },
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      boxShadow: theme.shadows[0],
      padding: (props: AscendModalProps) => typeof props.paperPadding === 'undefined' ? '50px 36px 40px' : props.paperPadding,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: (props: AscendModalProps) => props.modalScroll ? undefined : '90vh',
      overflowY: 'auto',
      width: (props: AscendModalProps) => props.width,
      height: (props: AscendModalProps) => props.height,
      marginTop: (props: AscendModalProps) => props.modalScroll ? 50 : undefined,
      marginBottom: (props: AscendModalProps) => props.modalScroll ? 50 : undefined,
    },
  })
);

interface AscendModalProps extends ModalProps {
  width?: string | number,
  height?: string | number,
  disableSidePadding?: boolean,
  onExited?: () => void,
  paperPadding?: string | number,
  modalScroll?: boolean;
}

export default function AscendModal(props: AscendModalProps) {
  const classes = useStyles(props);

  return <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide direction={props.open ? 'up' : 'down'} in={props.open} onExited={props.onExited}>
        <div className={classes.paper}>
          {props.children}
        </div>
      </Slide>
    </Modal>
}
