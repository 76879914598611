import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { from, split } from 'apollo-link';
import {setContext} from "apollo-link-context";
import {config} from "../Questionnaire/config/config";
import {keycloak} from "./keycloak";

const httpLink = createHttpLink({
  uri: config.graphQL,
  headers: {
    "content-type": "application/json",
  }
});

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .success(() => {
        resolve(keycloak.token);
      })
      .error(() => {
        reject();
      });
  });
}

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(e => {
    throw new Error('Update token failed');
  });
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    }
  };
});

const elink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const authClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
