import React from "react";
import {Box, createStyles, Grid, MenuItem, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../Maketplace/MedicareAdvantage/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendButton from "../shared/components/AscendButton";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import * as _ from "lodash";
import {client, GraphQLErrorType} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import HeaderCard from "../Maketplace/MedicareAdvantage/DetailedPlan/components/HeaderCard";
import BackButton from "../Maketplace/MedicareAdvantage/shared/BackButton";
import {keycloak} from "../shared/keycloak";
import useSaveRequest from "../Questionnaire/hooks/useSaveRequest";
import {PlanYear} from "../types";
import MedigapEnrollmentForm from "./MedigapEnrollmentForm";
import {GetPlansYear} from "../Maketplace/shared/hooks/GetPlansYear";
import {client as client2022} from "../shared/ApolloClient2022";
import { getCId } from "../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    checkbox: {
      marginLeft: -15,
      alignItems:'flex-start'
    },
    checkboxLeft: {
      marginLeft: -15,
    },
    radioLeft: {
      marginLeft: -8,
    }
  })
);
const formName = 'EnrollmentForm';

const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  city: yup.string(),
  aptNumber: yup.string(),
  street: yup.string(),
  phoneNumber: yup.string().required("Enter a valid phone number"),
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
  email: yup.string().required("Enter a valid email address").email('Enter a valid email address'),
});

interface PersonalDetailsFormState {
  firstName: string;
  lastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  phoneNumber: string;
}

type EnrollmentFormProps = RouteComponentProps<{
  planId: string,
  zip: string,
  quoteId: string,
  countyName: string,
}> & {
  year?: string,
  age?: string,
  effectiveDate?: string,
  tobacco?: string,
  gender?: string,
}

function EnrollmentForm({age, effectiveDate, tobacco, gender, year, ...props}: EnrollmentFormProps) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, setState] = React.useState<string>('');
  const [represent, setRepresent] = React.useState();
  const [agreement, setAgreement] = React.useState(false);
  const [partC, setPartC] = React.useState(false);
  const [partD, setPartD] = React.useState(false);
  const [medigap, setMedigap] = React.useState(false);
  const [hospital, setHospital] = React.useState(false);
  const [benefits, setBenefits] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [save] = useSaveEnrollment();
  const [getCounty, countyData] = useGetCounty();
  const [county, setCounty] = React.useState<string | undefined>('');
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated, isAdmin} = React.useContext(KeycloakContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [saveError, setSaveError] = React.useState();
  const [saveQuote] = useSaveRequest();

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  React.useEffect(() => {
    if (zip) {
      getCounty({variables: {zip}})
    }
  }, [zip])

  React.useEffect(() => {
    if (countyData.data?.plans_zip_county_fips && countyData.data?.plans_zip_county_fips.length === 1) {
      setCounty(countyData.data?.plans_zip_county_fips[0].countyname)
    }
  }, [countyData.data]);

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])

  const permanentStates = React.useMemo(() => {
    return _.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [countyData.data?.plans_zip_county_fips])

  React.useEffect(() => {
    if (permanentStates?.length === 1) {
      setState(permanentStates[0]);
    }
  }, [permanentStates])

  const [getPlan, {data}] = usePlanLazy({ client: year === '2021' ? client : client2022 });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          countyName,
          zip,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const values = React.useMemo(() => {
    return {
      quoteId: authenticated && !isAdmin ? userInfoData?.data?.userProfile.quoteId : quoteId,
      bidId: planId,
      email: getValues().email,
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      planYear: year === '2021' ? PlanYear.Year2021 : PlanYear.Year2022,
      permanentAddress: {
        aptNumber: getValues().aptNumber,
        city: getValues().city,
        state: state || '',
        streetName: getValues().street,
        zip: getValues().zip,
      },
      phoneNumber: getValues().phoneNumber,
    }
  }, [getValues(), state, isAdmin, authenticated, year])

  const saveErrorHandler = (error: any) => {
    let text;
    switch (error.graphQLErrors[0]?.extensions?.type) {
      case "INVALID_EMAIL":
      case GraphQLErrorType.ALREADY_EXISTS:
        text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
          keycloak.login({redirectUri: document.location.href})
        }} style={{color: "red"}}>sign in</a></>
        break;
      case GraphQLErrorType.NO_CONNECTION:
        text = "Service is not available";
        break;
      default:
        text = "Unknown error"
    }
    setSaveError(text)
  }

  const onSubmit = React.useCallback(() => {
    /*if (formState.isValid && state) {
      if (values.quoteId) {
        save({variables: {data: values}}).then(data => {
          setStoredUser({
            ...getStoredUser(),
            enrollmentId: data?.data?.saveEnrollment,
          })
          props.history.push(paths.receive)
        }).catch(saveErrorHandler);
      } else {
        saveQuote({variables: {
            data: {
              county: countyName,
              zip: zip,
              form: 'SEARCH_RESULTS',
              cId: getCId(),
              medicarePlanType: 'MA',
              planYear: year === '2021' ? PlanYear.Year2021 : PlanYear.Year2022,
            }
          }}).then(res => save({variables: {data: {...values, quoteId: res.data?.saveMedicareQuote}}}).then(data => {
          setStoredUser({
            ...getStoredUser(),
            enrollmentId: data?.data?.saveEnrollment,
          })
          props.history.push(paths.receive)
        })).catch(saveErrorHandler);
      }
    }*/
    props.history.push(paths.receive)
  }, [values, countyName, state, zip, year]);

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setState(userInfoData.data.userProfile.state || '');
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800} position={'relative'}>
    <Box position={'sticky'} top={'0px'} bgcolor={'#f5f7f6'} zIndex={3}>
      <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={() => history.goBack()}>
        Back
      </BackButton>
    </Box>
    {!phone && <>
      {plan && <ShortPlanCard year={year} plan={plan}/>}
    </>}
    {(plan && phone) && <HeaderCard hideSelectButton plan={plan} zip={zip} countyName={countyName} setEmailOpen={() => {}} setModalOpen={() => {}} />}
    <div className={'h-40'} />
    <Typography color={'textPrimary'} className={'bold fs-26'}>Personal details</Typography>
    <Typography color={'textPrimary'} className={'medium fs-18'}>Please provide some basic information so we can contact you. One of our licensed CoverRight sales agents will then be in touch to help you enroll in your selected plan.<br /><br /> <i>Fields marked with an asterisk (*) are required.</i></Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Personal information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           defaultValue={userInfo?.firstName}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           placeholder={'First name'}
                           label={'First name*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           defaultValue={userInfo?.lastName}
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Last name'}
                           label={'Last name*'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           label={'Email address*'}
                           name={'email'}
                           defaultValue={userInfo?.email}
                           inputProps={{
                             autoCapitalize: 'none'
                           }}
                           placeholder={'Enter your email address'}
                           error={(!!errors.email || saveError) && formState.isSubmitted}
                           helperText={formState.isSubmitted && (errors?.email?.message || saveError)}
                           inputRef={register} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ onChange }) => <PhoneNumberInput label={'Phone number*'}
                                                          defaultValue={userInfo?.phoneNumber}
                                                          isValid={() => formState.isSubmitted ? !errors?.phoneNumber?.message : true}
                                                          placeholder={'+1 (123) 456-7890'} name={'phoneNumber'} onChange={onChange} />}
            />
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'32px'} />
            <Typography className={'bold fs-24'}>Permanent address</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'street'}
                           defaultValue={userInfoData.data?.userProfile?.streetName}
                           error={formState.isSubmitted && !!errors.street}
                           helperText={formState.isSubmitted && errors?.street?.message}
                           inputRef={register}
                           placeholder={'Enter street address'}
                           label={'Street Address (P.O. box not allowed)'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'aptNumber'}
                           defaultValue={userInfoData.data?.userProfile?.aptNumber}
                           error={formState.isSubmitted && !!errors.aptNumber}
                           helperText={formState.isSubmitted && errors?.aptNumber?.message}
                           inputRef={register}
                           placeholder={'Enter apt. / unit'}
                           label={'Apt. / Unit'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'city'}
                           defaultValue={userInfoData.data?.userProfile?.city}
                           error={formState.isSubmitted && !!errors.city}
                           helperText={formState.isSubmitted && errors?.city?.message}
                           inputRef={register}
                           placeholder={'Enter city'}
                           label={'City'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'State'}
              value={state}
              placeholder={'Select state'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setState(event.target.value as string);
              }}
            >
              {permanentStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'County'}
              value={county}
              disabled={!counties.length}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
              }}
            >
              {
                counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
              }
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           fullWidth
                           inputRef={register}
                           label={'Zip'}
                           name={'zip'}
                           value={zip}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
          </Grid>

        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'24px'} mb={'40px'}>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Confirm and submit</AscendButton>
      </Box>
      <Typography className={'fs-13'} color={'textSecondary'}>
        By submitting my email and phone number and clicking ‘Confirm and submit’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
        This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />
        I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
      </Typography>
      <div className={'h-30'} />
      <Typography variant={'h5'} color={'textPrimary'}>
        Last Updated Date: 10/15/22<br/>MULTIPLAN_COVERRIGHT_2023_M<br />MULTIPLAN_COVERRIGHT_2023U2_M
      </Typography>
      <div className={'h-100'} />
    </form>
  </Box>
}

export default (props: EnrollmentFormProps & {isMedigap: boolean}) => {
  return props.isMedigap ? <MedigapEnrollmentForm {...props} /> : <EnrollmentForm {...props} />
};
