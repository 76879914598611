import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MedigapFilterItem} from "../../../../medigap-types";

export function usePlanFilterData() {
  return useLazyQuery<IPlanFilterResponse, {zip: string}>(gql(planFilterQuery))
}

export interface IPlanFilterResponse {
  medigapCompanies: MedigapFilterItem[],
  medigapPlanNames: MedigapFilterItem[],
  medigapMonthlyPlanPremium: MedigapFilterItem[],
  medigapBenefits: MedigapFilterItem[],
}

const planFilterQuery = `
  query($zip: String!) {
    medigapBenefits {
      key
      name
    }
    medigapMonthlyPlanPremium {
      key
      name
    }
    medigapPlanNames(zip: $zip) {
      key
      name
    }
    medigapCompanies {
      key
      name
    }
  }
`;
