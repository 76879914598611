import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import AscendPreloader from "../../../shared/components/AscendPreloader";
import {useLazyQuery} from "@apollo/react-hooks";
import {Field, Sort} from "../../../types";
import {gql} from "apollo-boost";
import {MedigapPlanName, QueryMedigapPlansArgs} from "../../../medigap-types";
import {getStoredUser} from "../../../shared/StoredUser";
import {getAgeByBirthdate, MedigapQuoteContext} from "../MedigapQuoteContextProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 500,
      '& span': {
        color: colors.custom.green.variant1,
      }
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 400,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

type FindPlanModalProps = {
  onClose: () => void,
  open: boolean,
  countyName?: string
  zip?: string,
  onlyMg?: boolean
}

export default function SearchingPlanModal(props: FindPlanModalProps) {
  const classes = useStyles();
  const [timeout, setTimeoutValue] = React.useState();
  const [quote] = React.useContext(MedigapQuoteContext);

  const [getPlans, {loading, data}] = useLazyQuery<IPlansOutput>(gql(planRequest), {
    errorPolicy: "ignore"
  });

  const [getMGPlans, {loading: mgLoading, data: mgData}] = useLazyQuery<IMGPlansOutput>(gql(mgplanRequest), {
    errorPolicy: "ignore"
  });

  React.useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const totalPlans = React.useMemo(() => {
    if (props.onlyMg) {
      return mgData?.medigapPlans.totalElements || 0
    }
    return (data?.plans.totalElements || 0) + (mgData?.medigapPlans.totalElements || 0)
  }, [mgData, data, props.onlyMg])

  const showTotalPlans = React.useMemo(() => {
    return !!data?.plans && !!mgData?.medigapPlans
  }, [mgData, data])

  React.useEffect(() => {
    if (props.zip && props.countyName && props.open) {
      getPlans({
        variables: {
          maFilter: {
            zip: props.zip,
            countyName: quote.STEP_2?.countyName
          },
          page: {page: 0, size: 10},
          sort: [{direction: Sort.Asc, field: Field.Rating}]
        }
      })
      getMGPlans({
        variables: {
          filter: {
            zip: props.zip,
            county: quote.STEP_2?.countyName,
            age: quote.STEP_3?.age || getAgeByBirthdate(getStoredUser()?.birthDate),
            gender: quote.STEP_3?.gender,
            tobacco: quote.STEP_3?.tobacco,
            monthlyPlanPremiumRanges: [],
            companies: [],
            planNames: [MedigapPlanName.N, MedigapPlanName.G, MedigapPlanName.F],
          },
          page: {page: 0, size: 10},
        }
      })
    }
  }, [props.open])

  React.useEffect(() => {
    if (showTotalPlans && props.open) {
      if (timeout) {
        clearTimeout(timeout)
      }
      setTimeoutValue(setTimeout(() => onClick(), 4500))
    }
  }, [props.open, showTotalPlans])

  const onClick = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    props.onClose()
  }

  return <AscendModal
      disableSidePadding
    width={584}
    open={props.open}
    onClose={() => onClick()}
  >
    <>
      {(!loading && !mgLoading && !!totalPlans && showTotalPlans) && <div onClick={() => onClick()}>
        <IconButton className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>
          Congratulations! We’ve found over <span>{totalPlans}</span> Medicare plans in your area.
        </Typography>
        <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
          <img src={'/img/congratulations.svg'} />
        </Box>

        <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>{props.countyName}</Typography>

        <Typography  color={'textPrimary'} className={'fs-18 medium mt-20 lh-22'} align={'center'}>
          Don’t worry! Our platform will help you easily refine your options.
        </Typography>
      </div>}
      {(loading || !showTotalPlans) && <>
        <Box display={'flex'} justifyContent={'center'} mt={'-60px'} mb={'10px'}>
          <AscendPreloader />
        </Box>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>Searching for plans in your area...</Typography>
      </>}

    </>
  </AscendModal>
}

interface IPlansOutput {
  plans: {totalElements: number}
}

interface IMGPlansOutput {
  medigapPlans: {totalElements: number}
}

const planRequest = `
query($maFilter: PlansFilterInput!, $page: PageInput!, $sort: [PlansSortInput!]!) {
    plans(filter: $maFilter, page: $page, sort: $sort) {
      totalElements
    }
}
`;

const mgplanRequest = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!) {
    medigapPlans(filter: $filter, page: $page) {
      totalElements
    }
}
`;