import React from "react";
import {Box, Divider, withStyles} from "@material-ui/core";
import clsx from 'clsx';

type AscendDividerProps = {
  mt?: number | string,
  my?: number | string,
  mb?: number | string,
  classes: any,
  className?: string,
}

const AscendDivider = withStyles({
  divider: {
    color: '#1c434f14',
  }
})((props: AscendDividerProps) => <Box mb={props.my ? props.my : props.mb} mt={props.my ? props.my : props.mt} width={'100%'}>
  <Divider className={clsx(props.classes.divider, props.className)}/>
</Box>)

export default AscendDivider;
