import React from 'react';
import AscendModal from "../../shared/components/AscendModal";
import {Box, createStyles, IconButton, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import {EnrollmentInput} from "../../enrollment-types";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import AscendButton from "../../shared/components/AscendButton";
import useCommonStyles from "../../shared/useCommonStyles";
import * as _ from 'lodash';
import AscendRadio from "../../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    line: {
      display: 'flex',
      borderBottom: '1px solid #E8ECED',
      padding: '8px 0',
    },
    checkboxWrapper: {
      alignItems: 'flex-start',
    }
  })
);

type PersonalDetailsConfirmModalProps = {
  onClose: () => void,
  onConfirm: () => void,
  open: boolean,
  data: EnrollmentInput,
}

export default function PersonalDetailsConfirmModal(props: PersonalDetailsConfirmModalProps) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const {data} = props;
  const [agreement, setAgreement] = React.useState(false)
  const [authorized, setAuthorized] = React.useState()

  return <AscendModal
    width={800}
    open={props.open}
    onClose={() => props.onClose()}
    paperPadding={'32px 50px'}
  >
    <Box>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <Typography color={'textPrimary'} className={'fs-18 bold mb-24'}>Confirm your information and sign Scope of Appointment</Typography>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Personal information</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>First name</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.firstName}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Last name</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.lastName}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Sex</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.gender}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Date of birth</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.birthDate}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Medicare details</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Medicare number</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.medicareNumber}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Hospital (Part A) Date</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.medicarePartAStartedAt}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Medical (Part B) Date</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.medicarePartBStartedAt}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Eligibility attestation</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Moved permanent residential address (MOV)</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Eligibility date</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.medicarePartBStartedAt}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Permanent address</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Street Address</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.permanentAddress?.streetName}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Apt. / Unit</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.permanentAddress?.aptNumber}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>City</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.permanentAddress?.city}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>State</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.permanentAddress?.state}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>County</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>New York</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Zip</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.permanentAddress?.zip}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Mailing address is same as permanent?</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Yes</Typography></Box>
      </div>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Contact information</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Email address</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.email}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Phone number</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{data.phoneNumber}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Preferred language</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{_.startCase(_.lowerCase(data.preferredLanguage || ''))}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Accessible communication preference</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>{_.startCase(_.lowerCase(data.communicationPreference || ''))}</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Plan communications preference</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Go digital</Typography></Box>
      </div>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Current healthcare coverage</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Will you have other prescription drug coverage in addition to this plan for which you are applying?</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Yes</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Name of prescription coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Humana Walmart Value Rx Plan (PDP)</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Telephone number</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>+1 (123) 456-7890</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>ID number for this coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>123-456-7890</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Group number for this coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>XYZ-123</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Effective date of this coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>01/01/2020</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Termination date of coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>3/01/2021</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Once enrolled, will you or your spouse work?</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>No</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Once enrolled, will you have coverage where you are the subscriber or covered as a spouse/dependent?</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Yes</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Carrier name</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>ABC Healthcare</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>ID number for this coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>123-456-7890</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Group number for this coverage</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>123-XYZ</Typography></Box>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14 pl-20'}>Does this coverage provide prescription drug coverage?</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>3/01/2021</Typography></Box>
      </div>
      <div className={classes.line}>
        <Typography color={'textPrimary'} className={'bold fs-14 mr-10'}>Payment information</Typography>
        <EditButton onClick={() => props.onClose()}/>
      </div>
      <div className={classes.line}>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Confirm payment method</Typography></Box>
        <Box display={'flex'} flex={1}><Typography color={'textPrimary'} className={'fs-14'}>Coupon book</Typography></Box>
      </div>
      <Box className={commonClasses.buttonWrapper} mt={'35px'}>
        <AscendButton className={'gtm-step-event'} variant={'contained'} onClick={() => props.onConfirm()}>Back to enrollment</AscendButton>
      </Box>
    </Box>
  </AscendModal>
}

const EditButton = ({onClick}: {onClick: () => void}) => <div className={'flex pointer'} onClick={onClick}>
  <img src={'/img/edit.svg'} />
  <Typography className={'fs-14 green ml-8'}>Edit</Typography>
</div>
