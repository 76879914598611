import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {colors} from "../../../shared/AppTheme";
import {makeStyles} from "@material-ui/core/styles";
import AscendModal from "../../../shared/components/AscendModal";
import moment from "moment";
import {ScrollbarList} from "../../../shared/components/AscendAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    modalContent: {
      paddingRight: '30px',
      paddingLeft: '30px'
    },
  })
);

export default function MedicareDisclaimersModal(props: {open: boolean, onClose: () => void}) {
  const classes = useStyles()

  return <AscendModal
    width={740}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'30px 0px 40px'}
  >
    <>
      <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
      <ScrollbarList height={'80vh'}>
        <Box className={classes.modalContent}>
          <Typography color={"textPrimary"} className={"bold fs-24"}>Medicare Disclaimers</Typography>
          <Typography color={"textPrimary"} className={"fs-14"}>
            <br/><br/>
            <b>General Disclaimer</b>
            <br/><br/>
            Estimated doctor cost is calculated based on your estimated number of Primary Care Physician (PCP) and
            Specialist visits per year, multiplied by the copays or coinsurance for in-network providers as outlined in
            the plan details. The reference price for the coinsurance calculation (for both in-network PCP and
            Specialist office visits) is based on the national average cost for an office consultation for an
            established patient, typically 25 minutes in length. These calculations are estimated based on the
            information you provide and may not accurately represent potential costs under the plan if incomplete
            information is provided.
            <br/><br/>
            Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for
            the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the
            individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference
            price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated
            monthly drug costs can only be calculated if you have entered medication information. These calculations are
            estimated based on the information you provide and may not accurately represent potential costs under the
            plan if incomplete information is provided.
            <br/><br/>

            <b>Medicare Disclaimers</b>
            <br/><br/>
            CoverRight.com is owned and operated by CoverRight Inc. Insurance agency services are provided by CoverRight Insurance Services Inc., a licensed health insurance agency and wholly owned subsidiary of CoverRight Inc. CoverRight Insurances Services Inc. is a licensed and certified representative of Medicare Advantage HMO, PPO and PFFS organizations and stand-alone prescription drug plans with a Medicare contract. Enrollment in any plan depends on contract renewal.
            <br/><br/>
            For a complete list of available plans please contact 1-800-MEDICARE (1-800-633-4227). TTY/RTT users should
            call 1-877-486-2048, 24 hours a day/7 days a week or consult Medicare.gov.
            <br/><br/>
            Medicare beneficiaries can file a complaint with the Centers for Medicare & Medicaid Services by calling
            1-800-MEDICARE 24 hours a day/7 days a week or using the medicare.gov site.
            <br/><br/>
            Every year, Medicare evaluates plans based on a 5-star rating system.
            <br/><br/>
            Enrollees must use network providers for specific services when stated in the evidence of coverage,
            otherwise, covered services received from non-network providers may be a higher cost or not eligible.
            <br/><br/>
            Out-of-network/non-contracted providers are under no obligation to treat Plan members, except in emergency
            situations. Please call our customer service number or see your Evidence of Coverage for more information,
            including the cost-sharing that applies to out-of-network services.
            <br/><br/>
            Enrollees must continue to pay the Medicare Part B premium, and the plan premium plus the optional
            supplemental plan premium, if applicable.
            <br/><br/>
            Benefits, premiums, co-payments/co-insurance may change on January 1 of each year.
            <br/><br/>
            Please call your Plan or refer to your Evidence of Coverage for more information, about eligible benefits
            and cost-sharing applicable to in-network and out-of-network services.
            <br/><br/>
            You must have both Part A and B to enroll in a Medicare Advantage plan. Members may enroll in the plan only
            during specific times of the year. Contact the plan for more information.
            <br/><br/>
            Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and exclusions may apply.
            <br/><br/>
            Part B Premium give-back is not available with all plans. Availability varies by carrier and location. Actual Part B premium reduction could be lower. Deductibles, copays and coinsurance may apply.
            <br/><br/>
            We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your
            area. Please contact Medicare.gov or 1–800–MEDICARE to get information on all of your options.
            <br/><br/><br/><br/>
            <b>Health Plan Disclaimers</b>
            <br/><br/>
            Humana Specific Disclaimers:
            <br/><br/>
            Other Pharmacies/Physicians/Providers are available in Humana’s network.
            <br/><br/>
            The Humana Prescription Drug Plan (PDP) pharmacy network includes limited lower-cost, preferred pharmacies
            in urban areas of CT, DE, IA, MA, MD, ME, MI, MN, MO, MS, MT, ND, NH, NJ, NY, PA, RI, SD, WY; suburban areas
            of CA, CT, DE, HI, IL, MA, MD, ME, MN, MT, ND, NH, NJ, NY, PA, PR, RI, VT, WV; and rural areas of AK, IA,
            MN, MT, ND, NE, SD, VT, WY. There are an extremely limited number of preferred cost share pharmacies in
            urban areas in the following states: DE, MA, ME, MN, MS, ND, NY; suburban areas of: MT and ND; and rural
            areas of: ND. The lower costs advertised in our plan materials for these pharmacies may not be available at
            the pharmacy you use. For up-to-date information about our network pharmacies, including whether there are
            any lower-cost preferred pharmacies in your area, please call Customer Care at 1-800-281-6918 (TTY: 711) or
            consult the online pharmacy directory at <a target={"_blank"} href={"http://humana.com/"}>Humana.com</a>.
            <br/><br/>
            Humana MyOption optional supplemental benefits (OSB) are only available to members of certain Humana
            Medicare Advantage (MA) plans. Members of Humana plans that offer OSBs may enroll in OSBs throughout the
            year. Benefits may change on January 1st each year.
            <br/><br/>
            Enrollees must continue to pay the Medicare Part B premium, their Humana plan premium, and the OSB premium.
            <br/><br/>
            Link to <a target={"_blank"} className={"text-primary"}
                       href={moment().isAfter(new Date(2022, 9, 1)) ? "https://cdn.coverright.com/1557.pdf" : "https://cdn.coverright.com/EN_GCHK9WZEN_P_021320.pdf"}>Nondiscrimation disclaimer</a>.
            <br/><br/>
            Limitations on telehealth services, also referred to as virtual visits or telemedicine, vary by state. These
            services are not a substitute for emergency care and are not intended to replace your primary care provider
            or other providers in your network. Any descriptions of when to use telehealth services are for
            informational purposes only and should not be construed as medical advice. Please refer to your evidence of
            coverage for additional details on what your plan may cover or other rules that may apply.
            <br/><br/>
            Aetna Specific Disclaimers:
            <br/><br/>
            Aetna Medicare is a HMO, PPO plan with a Medicare contract. Our SNPs also have contracts with State Medicaid programs. Enrollment in our plans depends on contract renewal.
            <br/><br/>
            SilverScript is a Prescription Drug Plan with a Medicare contract marketed through Aetna Medicare. Enrollment in SilverScript depends on contract renewal.
            <br/><br/>
            Allina Health | Aetna Medicare is a PPO plan with a Medicare contract. Enrollment in our plans depends on contract renewal.
            <br/><br/>
            Innovation Health Medicare is a HMO, PPO plan with a Medicare contract. Enrollment in our plans depends on contract renewal.
            <br/><br/>
            If referencing preferred pharmacies:
            <br/><br/>
            The Aetna pharmacy network includes limited lower-cost, preferred pharmacies in applicable areas. The lower costs advertised in our plan materials for these pharmacies may not be available at the pharmacy you use. For up-to-date information about our network pharmacies, including whether there are any lower-cost preferred pharmacies in your area, please call 1-866-235-5660 (TTY: 711) or consult your online pharmacy directory at <a target={"_blank"} href={"http://www.aetnamedicare.com/pharmacyhelp"}>http://www.aetnamedicare.com/pharmacyhelp</a>.
            <br/><br/><br/><br/>
            © {moment().format("YYYY")} CoverRight Insurance Services Inc. All rights reserved.
            <br/><br/>
            Last Updated: {moment().format("MM/DD/YYYY")}
          </Typography>
        </Box>
      </ScrollbarList>
    </>
  </AscendModal>
}
